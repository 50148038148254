import { User } from "types/model/User";
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
} from "constants/cookieKeys";
import { ACCESS_TOKEN, LOCAL_KEY_AUTH_USER } from "constants/storageKeys";
import { useState, useEffect } from "react";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";

const useLoadAuth = () => {
  const [loading, setLoading] = useState(true);

  const [user] = useState<null | User>(
    JSON.parse(localStorage.getItem(LOCAL_KEY_AUTH_USER) || JSON.stringify({})),
  );

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const token = cookie.load(COOKIE_ACCESS_TOKEN);
  const refreshToken = cookie.load(COOKIE_REFRESH_TOKEN);

  useEffect(() => {
    if (!token || !refreshToken) {
      setLoading(false);
      setIsAuthenticated(false);
    } else if (token && refreshToken) {
      const user = jwt_decode(token);

      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }

      setLoading(false);
    }
  }, [refreshToken, token]);

  const clearAuthStorages = () => {
    cookie.remove(COOKIE_ACCESS_TOKEN);
    cookie.remove(COOKIE_REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
  };

  const handleLogout = () => {
    clearAuthStorages();
    const authRedirectUrl = process.env.REACT_APP_AUTH_URL as string;
    window.location.href = authRedirectUrl;
  };

  return {
    loading,
    user,
    handleLogout,
    isAuthenticated,
    clearAuthStorages,
  };
};

export default useLoadAuth;
