import Button from "components/common/Button";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch } from "hooks/useRedux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  toggleAgencyModal,
  toggleContactModal,
  toggleCreateLeadModal,
  toggleNetworkModal,
} from "redux/slices/sales_crm/modals";

const CRMCreateButton = () => {
  const { user } = useLoadAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const tabNameMap: Record<string, string> = {
    agencies: "New OPM Agency",
    feed: "New Event",
    home: "New Event",
    accounts: "New Event",
    contacts: "New Program Contact",
    networks: "New Network",
    leads: "New Lead",
  };

  const currentTabName = params.has("tab-id")
    ? tabNameMap[params.get("tab-id") || ""]
    : "";

  if (!currentTabName) {
    return null;
  }

  if (user?.user_role !== USER_ROLE_ADMIN && currentTabName === "New Lead") {
    return null;
  }

  return (
    <Button
      className="my-0"
      onClick={() => {
        //

        if (params.get("tab-id") === "contacts") {
          dispatch(toggleContactModal());
        }

        if (params.get("tab-id") === "agencies") {
          dispatch(toggleAgencyModal());
        }

        if (params.get("tab-id") === "networks") {
          dispatch(toggleNetworkModal());
        }

        if (params.get("tab-id") === "leads") {
          dispatch(toggleCreateLeadModal());
        }

        if (["home", "feed", "accounts"].includes(params.get("tab-id") || "")) {
          navigate("/sales/crm/new-event");
        }
      }}
      text={currentTabName}
    />
  );
};

export default CRMCreateButton;
