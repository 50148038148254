import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import toast from "react-hot-toast";

export default createAsyncThunk(
  "sales_crm/requestProgramOwnership",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.post(
        `/admin/sales-crm/program-owner-requests/`,
        payload
      );
      toast.success("Request was submitted successfully");
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
