import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";
import getAuthUser from "redux/thunks/users/getAuthUser";
import getClients from "redux/thunks/app/getClients";
import router from "routes/config";
import getActiveProgramsAction from "redux/thunks/app/api-clients/getActivePrograms";
import Logo from "logo192.png";
import getApps from "redux/thunks/users/getApps";
import getTodoStats from "redux/thunks/todos/getTodoStats";
import getTodos from "redux/thunks/todos/getTodos";

function App() {
  const dispatch = useAppDispatch();
  const authUser = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!window.location.href.includes("login")) {
      dispatch(getClients({ page: 1 }));
      dispatch(getApps({}));
      dispatch(getTodoStats({ completed: "0" }));
      dispatch(getTodos({ completed: "0" }));
      dispatch(getAffiliateNetworks({ page: 1 }));
      dispatch(getActiveProgramsAction({ page: 1 }));
      if (!authUser.data && !authUser.loading) {
        dispatch(getAuthUser()).then((result) => {
          if (result.meta.requestStatus === "rejected") {
            window.location.href = "/auth/login";
          }
        });
      }
    }
  }, [dispatch, window.location.href]);

  if (
    (!authUser.data || authUser.loading) &&
    !window.location.href.includes("login")
  ) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className=" -top-11">
          <img src={Logo} height={120} width={120} />
          <p id="default-web-app-msg" className="text-lg font-light">
            Please wait...
          </p>
        </div>
      </div>
    );
  }

  return <RouterProvider router={router} />;
}

export default App;
