import { PaginatedActionState } from "types/data";
import { Lead } from "types/model/sales_crm/Lead";
import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import { ASSIGNED_LEAD_COLUMNS } from "constants/tables/headers/sales_crm/leads";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import CreateLeadModal from "./CreateLeadModal";
import { toggleCreateLeadModal } from "redux/slices/sales_crm/modals";
import Dropdown from "components/common/Dropdown";
import { User } from "types/model/User";
import { ChangeEventHandler, useEffect, useState } from "react";
import LeadRow from "./LeadRow";

interface LeadManagementProps {
  leads: PaginatedActionState<Lead>;
  filterValues: Record<string, any>;
  salesUsers: User[];
  onChange: ChangeEventHandler<HTMLInputElement>;
  onPageChange: (page: number) => void;
  onAssign: (lead: Lead) => void;
  isAdmin: boolean;
}

export default function LeadManagement({
  leads,
  filterValues,
  salesUsers,
  onChange,
  onPageChange,
  onAssign,
  isAdmin,
}: LeadManagementProps) {
  const [selectedRow, setSelected] = useState<Lead | null>(null);
  const dispatch = useAppDispatch();
  const createOpen = useAppSelector(
    (state) => state.crmModals.createLeadModalOpen
  );
  const renderRow: RowRenderer<Lead> = (lead, leadIdx) => {
    return (
      <LeadRow
        key={lead.id}
        lead={lead}
        leadIdx={leadIdx || 0}
        onRowClick={() => {
          if (isAdmin) {
            setSelected(lead);
            dispatch(toggleCreateLeadModal());
          }
        }}
        onAssign={() => {
          onAssign(lead);
        }}
      />
    );
  };

  useEffect(() => {
    if (!createOpen) {
      setSelected(null);
    }
  }, [createOpen]);

  return (
    <>
      <CreateLeadModal
        isOpen={createOpen}
        selectedRow={selectedRow}
        salesUsers={salesUsers}
        toggleModal={() => {
          dispatch(toggleCreateLeadModal());
        }}
      />

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <DataTable
              columns={ASSIGNED_LEAD_COLUMNS}
              rows={leads.data.items}
              loading={leads.loading}
              totalCount={leads.data.meta.count}
              header={
                <div className="my-4 flex gap-4 justify-between items-center px-4">
                  <div className="flex gap-4 items-center">
                    <Dropdown
                      className="w-56"
                      options={["All", "Pending", "Accepted"].map((el) => ({
                        label: el,
                        value: el,
                      }))}
                      name="status"
                      value={filterValues.status}
                      onChange={onChange}
                      placeholder="Assignment Status"
                    />

                    {isAdmin && (
                      <Dropdown
                        options={salesUsers.map((el) => ({
                          label: el.first_name + " " + el.last_name,
                          value: el.id,
                        }))}
                        name="assigned_to"
                        className="w-56"
                        value={filterValues.assigned_to}
                        placeholder="Assigned To"
                        onChange={onChange}
                      />
                    )}
                  </div>

                  <span className="text-sm">{leads.data.meta.count} Items</span>
                </div>
              }
              currentPage={filterValues.page ? Number(filterValues.page) : 1}
              onPageChange={onPageChange}
              rowRenderer={renderRow as RowRenderer<Record<string, any>>}
            />
          </div>
        </div>
      </div>
    </>
  );
}
