import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getLinksByProgram from "redux/thunks/links/getLinksByProgram";
import { PaginatedData } from "types/data";
import { Advertiser } from "types/model/Advertiser";

export type ActionStateType = {
  data: PaginatedData<Advertiser>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
      extra: {
        advertiser_count: 0,
      },
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getLinksByProgramSlice = createSlice({
  name: "getLinksByProgram",
  initialState,
  reducers: {
    resetGetLinksByProgram: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLinksByProgram.pending, (state, action) => {
        return {
          ...state,
          error: null,
          data: action.meta.arg.skipCache ? state.data : initialState.data,
          loading: true,
        };
      })
      .addCase(
        getLinksByProgram.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )

      .addCase(
        getLinksByProgram.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});
export const { resetGetLinksByProgram } = getLinksByProgramSlice.actions;
export default getLinksByProgramSlice.reducer;
