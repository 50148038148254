import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

type Payload = {
  start_date: string;
  end_date: string;
  client: string;
  location: string;
  page: string;
  placement_type: string;
  category?: string;
};

export default createAsyncThunk(
  "sales/check-pos-availability",
  async (payload: Payload, ThunkAPI) => {
    try {
      const response = await axios.post(
        `/admin/sales/proposals/check-positions`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
