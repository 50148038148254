import createUpdateDeletePricingPlan from "redux/slices/sales/createUpdateDeletePricingPlan";
import createUpdateDeleteProposal from "redux/slices/sales/createUpdateDeleteProposal";
import createUpdateDeletePayment from "redux/slices/sales/createUpdateDeletePayment";
import getPricingPlan from "redux/slices/sales/getPricingPlan";
import getProposal from "redux/slices/sales/getProposal";
import getPricingPlans from "redux/slices/sales/getPricingPlans";
import getProposals from "redux/slices/sales/getProposals";
import getIos from "redux/slices/sales/getIos";
import getPositionAvailabilityStatuses from "redux/slices/sales/getPositionAvailabilityStatuses";
import getPayments from "redux/slices/sales/getPayments";
import getPayment from "redux/slices/sales/getPayment";
import getProposalStats from "redux/slices/sales/getProposalStats";
import sendSigningRequest from "redux/slices/sales/sendSigningRequest";
import getProposalPeriodReport from "redux/slices/sales/getProposalPeriodReport";
import getProposalTypes from "redux/slices/sales/getProposalTypes";
import getSearchRightsTypes from "redux/slices/sales/getSearchRightsTypes";

const salesReducers = {
  getPricingPlan,
  createUpdateDeletePayment,
  createUpdateDeleteProposal,
  createPricingPlan: createUpdateDeletePricingPlan,
  getProposal,
  getProposals,
  getPricingPlans,
  getPositionAvailabilityStatuses,
  getPayments,
  getPayment,
  sendSigningRequest,
  getIos,
  getProposalStats,
  getProposalPeriodReport,
  getProposalTypes,
  getSearchRightsTypes,
};

export default salesReducers;
