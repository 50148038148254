import { CSVLink } from "react-csv";
import dateFormat from "dateformat";

type ExportBtnProps = {
  onClick?: () => void;
};

export const ArrowUpExportIcon = ({ onClick }: ExportBtnProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    >
      <svg
        className="w-4 h-4 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
        />
      </svg>
    </button>
  );
};

type Props = {
  data: Array<Record<string, any>>;
  fileNamePrefix?: string;
};

const ExportButton = ({ data, fileNamePrefix = "" }: Props) => {
  return (
    <CSVLink
      data={data}
      filename={`${fileNamePrefix}_${dateFormat(new Date(), "dd_mm_yyyy")}`}
    >
      <ArrowUpExportIcon />
    </CSVLink>
  );
};
export default ExportButton;
