import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sales_crm/get-events",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/sales-crm/events/?offset=${payload?.offset || 0}&program=${
          payload.store || ""
        }&user_id=${payload.user_id || ""}&has_pending_followup=${
          payload.has_pending_followup || ""
        }`
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
