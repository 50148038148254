import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

type Params = {
  data: {
    ids: string[];
    resource: string;
  };
};

export default createAsyncThunk(
  "networks/sync-programs",
  async ({ data }: Params, ThunkAPI) => {
    try {
      const response = await axios.post(`/admin/networks/sync-updates`, data);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
