import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import Label from "components/common/Label";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import Layout from "components/layout/Layout";
import MultiStepForm from "components/common/MultiStepForm";
import { ChangeEventHandler, Dispatch, SetStateAction } from "react";
import { ActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";
import { Option } from "types/option";
import { Link } from "react-router-dom";
import newsLetterPageTypeOptions from "constants/pricing-plans/newsLetterPageTypeOptions";
import PriceItem from "./PriceItem";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  handleFormSubmit: () => void;
  form: Record<string, any>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;

  getCategoriesData: ActionState<ProgramCategory[]>;
  prices: Array<Record<string, any>>;
  handleSetPrices: () => void;
  setPrices: Dispatch<SetStateAction<Record<string, any>[]>>;
  createPricingPlanData: ActionState;
  isDeleting: boolean;
  clientPlacementPositionCount: number;
  pageOptions: string[];
  placementTypeOptions: string[];
  editPriceItems: (id: string, updatedObject: Record<string, any>) => void;
};

const CreateUpdateDeletePricingPlanLayout = ({
  handleFormSubmit,
  onChange,
  form,
  setForm,
  pageOptions,
  placementTypeOptions,
  getCategoriesData,
  prices,
  handleSetPrices,
  setPrices,
  createPricingPlanData,
  isDeleting,
  clientPlacementPositionCount,
  editPriceItems,
}: Props) => {
  if (isDeleting) {
    return (
      <Layout>
        <p>Are you sure you want to delete this pricing plan?</p>

        <div className="flex gap-4 py-2 items-center">
          <Link to="/sales">Cancel</Link>
          <Button
            loading={createPricingPlanData.loading}
            className="text-sm"
            onClick={handleFormSubmit}
            text="Continue"
          />
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="relative p-4 w-full h-full md:h-auto">
        <MultiStepForm
          steps={[
            {
              id: 1,
              description: "step 1",
              status: "current",
              title: "Plan settings",
              content: (
                <div>
                  <div className="flex  items-center gap-8">
                    <Input
                      label="Pricing Plan Name"
                      name="name"
                      onChange={onChange}
                      wrapperClassName="w-96"
                      required
                      value={form.name}
                    />
                    <Dropdown
                      label="Location"
                      name="location"
                      wrapperClassName="w-96"
                      onChange={(event) => {
                        setForm((f) => ({
                          ...f,
                          location: event.target.value,
                        }));
                      }}
                      options={form.locations}
                      value={form.location}
                    />
                  </div>
                  <div className="flex my-6   items-center gap-8">
                    <Dropdown
                      label="Client"
                      wrapperClassName="w-96"
                      name="client"
                      onChange={(event) => {
                        setForm((f) => ({
                          ...f,
                          client: event.target.value,
                        }));
                      }}
                      options={form.clients}
                      value={form.client}
                    />
                  </div>
                </div>
              ),
            },

            {
              title: "Position Prices",
              id: 2,
              description: "step 2",
              status: "default",
              content: (
                <div className="mx-4 my-2">
                  <div className="flex my-6   items-center gap-8">
                    <Dropdown
                      label="Page"
                      placeholder="Select Page"
                      name="page"
                      value={form.page}
                      wrapperClassName="w-96"
                      onChange={(event) => {
                        setForm((f) => ({
                          ...f,
                          page: event.target.value,
                        }));
                      }}
                      options={pageOptions.map((el) => ({
                        label: el,
                        value: el,
                      }))}
                    />

                    {form.page === "Newsletters" && (
                      <Dropdown
                        label="Page Type"
                        placeholder="Select Page Type"
                        name="page_type"
                        value={form.page_type}
                        wrapperClassName="w-96"
                        onChange={(event) => {
                          setForm((f) => ({
                            ...f,
                            page_type: event.target.value,
                          }));
                        }}
                        options={newsLetterPageTypeOptions.map((el) => ({
                          label: el,
                          value: el,
                        }))}
                      />
                    )}

                    {(form.page === "Custom" ||
                      form.page === "Seasonal Categories") && (
                      <MultipleSelectionInput
                        options={form.categories}
                        loading={getCategoriesData.loading}
                        wrapperClassName="w-96"
                        name="categories"
                        onChange={(event) => {
                          const value = event.target.value;
                          const checked = event.target.checked;

                          setForm((f) => ({
                            ...f,
                            categories: form.categories.map(
                              (el: Record<string, any>) =>
                                el.value === value
                                  ? { ...el, selected: checked }
                                  : el
                            ),
                          }));
                        }}
                        renderAll
                        label="Categories"
                        placeholder="Choose Categories"
                      />
                    )}
                  </div>

                  <div className="flex my-6   items-center gap-8">
                    <Dropdown
                      label="Placement Type"
                      placeholder="Select placement Type"
                      name="placement_type"
                      wrapperClassName="w-96"
                      value={form.placement_type}
                      onChange={onChange}
                      options={placementTypeOptions
                        .filter((it) => {
                          if (
                            form.page === "Newsletters" &&
                            form.page_type !== "Solo Newsletter"
                          ) {
                            return [
                              "Featured Coupon",
                              "Coupon Listings",
                            ].includes(it);
                          }

                          if (
                            form.page === "Newsletters" &&
                            form.page_type === "Solo Newsletter"
                          ) {
                            return it === "Featured Coupon";
                          }
                          if (form.page !== "Homepage") {
                            return (
                              it !== "Hero Coupons" && it !== "Just Came In"
                            );
                          }
                          return it;
                        })
                        .map((el) => ({ label: el, value: el }))}
                    />
                  </div>

                  <div className=" my-6   items-center gap-8">
                    <Label label="Positions" />
                    <div className="flex gap-4 my-4 flex-wrap w-3/4">
                      {Array(clientPlacementPositionCount)
                        .fill("*")
                        .map((el, idx) => (
                          <Input
                            key={idx}
                            label={`Position ${idx + 1} Price`}
                            name={`position_${idx + 1}_price`}
                            value={
                              form["positions"]?.[
                                `position_${idx + 1}_price`
                              ] || ""
                            }
                            onChange={(e) => {
                              setForm((f) => ({
                                ...f,
                                positions: {
                                  ...f.positions,
                                  [`position_${idx + 1}_price`]: e.target.value,
                                },
                              }));
                            }}
                            wrapperClassName="w-96 my-4"
                            required
                          />
                        ))}
                    </div>
                  </div>
                  <div className=" flex">
                    <button
                      type="submit"
                      onClick={handleSetPrices}
                      className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
                    >
                      Set and add another
                    </button>
                  </div>
                </div>
              ),
            },

            {
              title: "Preview",
              description: "Step3",
              id: 3,
              status: "default",
              content: (
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                  <div>
                    <Label label="Pricing Plan Name" />
                    <small>{form.name}</small>
                  </div>

                  <div>
                    <Label label="Client" />
                    <small>
                      {
                        form.clients.find(
                          (it: Option) => it.value === form.client
                        )?.label
                      }
                    </small>
                  </div>

                  <div>
                    <Label label="Location" />
                    <small>{form.location}</small>
                  </div>

                  <hr className="my-4" />

                  <div>Added Page Prices </div>

                  {prices.map((el) => (
                    <PriceItem
                      setPrices={setPrices}
                      priceItem={el}
                      key={el.id}
                      editPriceItems={editPriceItems}
                    />
                  ))}

                  <Button
                    type="submit"
                    loading={createPricingPlanData.loading}
                    onClick={handleFormSubmit}
                    text={form.id ? "Save Changes" : " Submit Pricing Plan"}
                    className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 my-6"
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    </Layout>
  );
};
export default CreateUpdateDeletePricingPlanLayout;
