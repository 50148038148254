import Checkbox from "components/common/Checkbox";
import DeleteButton from "components/common/DeleteButton";
import { ActionState } from "types/data";
import { ExcludedStore } from "types/model/ExcludedStore";
import formatDate from "utils/dates/formatDate";

type Props = {
  item: ExcludedStore;
  handleDeleteExclusion: (excludedStore: ExcludedStore) => void;
  deleteExcludedStoreData: ActionState;
};

function ExcludedStoreRow({
  item,
  handleDeleteExclusion,
  deleteExcludedStoreData,
}: Props) {
  return (
    <tr className="border-b  hover:bg-gray-100 ">
      <th scope="row" className="px-4 py-2 text-xs font-medium text-gray-900 ">
        {item.store?.name}
      </th>

      <th scope="row" className="px-4 py-2 text-xs font-medium text-gray-900 ">
        {item.store?.primary_network?.name}
      </th>
      <th scope="row" className="px-4 py-2 text-xs font-medium text-gray-900 ">
        {item.store?.primary_network?.name?.toLowerCase?.() == "free"
          ? "Free"
          : "Network"}
      </th>

      <th scope="row" className="px-4 py-2 text-xs font-medium text-gray-900 ">
        {formatDate(item.excluded_at)}
      </th>

      <td className="p-2">
        <div className="">
          <DeleteButton
            deleteLoading={deleteExcludedStoreData.loading}
            onClick={() => {
              handleDeleteExclusion(item);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

export default ExcludedStoreRow;
