import AdvertiserFilters from "components/advertisers/AdvertiserStatusFilters";
import AllAdvertiserRow from "components/advertisers/AllAdvertiserRow";
import Dropdown from "components/common/Dropdown";
import DataTable from "components/common/layout/DataTable";
import countries from "constants/operationCountries";
import storeRanks from "constants/storeRanks";
import OMA_ACTIVE_PROGRAM_COLUMNS from "constants/tables/headers/oma/OMAactivePrograms";
import ACTIVE_PROGRAM_COLUMNS from "constants/tables/headers/bma/activePrograms";
import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
} from "react";
import { ExportResourceAction } from "redux/slices/clients/export-resource";
import { PageNumPaginatedActionState } from "types/data";
import { Option } from "types/option";
import getHostName from "utils/urls/getHostName";

type Props = {
  getActiveProgramsData: PageNumPaginatedActionState<
    Array<Record<string, any>>
  >;
  onPageChange: (page: number) => void;
  goToAdd: () => void;
  networkOptions: Option[];
  onFiltersChange: ChangeEventHandler<HTMLInputElement>;
  network_status: string;
  currentPage: number;
  onViewPage: (arg: Record<string, any>) => void;
  onViewRecentlyDeactivated: () => void;
  onViewCommissions: () => void;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  onItemSelectChange: (item: Record<string, any>) => void;
  selectedItems: Array<Record<string, any>>;
  handleSelectAll: () => void;
  onShowAll: () => void;
  setAllSelected: Dispatch<SetStateAction<boolean>>;
  allSelected: boolean;
  exportResourceData: ExportResourceAction;
  onExportAll: () => void;
  has_open_ppc_policy: string;
  has_no_faqs: string;
  is_free?: string;
  is_ad_center: string;
  is_deactivated: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isOMA: boolean;
  searchQueryValues: Record<string, any>;
  activeTab: string;
  has_ai_faqs: string;
};

const ActivePrograms = ({
  onPageChange,
  onFiltersChange,
  network_status,
  is_ad_center,
  networkOptions,
  currentPage,
  onViewPage,
  onViewRecentlyDeactivated,
  onViewCommissions,
  getActiveProgramsData,
  onSearchChange,
  onSearchSubmit,
  selectedItems,
  handleSelectAll,
  onShowAll,
  onItemSelectChange,
  setAllSelected,
  allSelected,
  exportResourceData,
  onExportAll,
  onChange,
  has_open_ppc_policy,
  has_no_faqs,
  is_free,
  is_deactivated,
  isOMA,
  searchQueryValues,
  activeTab,
  has_ai_faqs,
}: Props) => {
  const {
    data: { results: data, count },
    loading,
  } = getActiveProgramsData;

  return (
    <div>
      <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
        <div className="border-b dark:border-gray-700 mx-4">
          <div className="flex items-center justify-between space-x-4 pt-3"></div>
          <div className="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3">
            <div className="w-full lg:w-2/3 flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center gap-4">
              <div className="w-72">
                <form
                  className="w-full md:max-w-sm flex-1 md:mr-4"
                  onSubmit={onSearchSubmit}
                >
                  <label
                    htmlFor="default-search"
                    className="text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Search..."
                      onChange={onSearchChange}
                    />
                    <button
                      type="submit"
                      className="text-white absolute right-0 bottom-0 top-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-r-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="flex items-center space-x-4">
                <Dropdown
                  options={networkOptions}
                  name="primary_network"
                  placeholder="Select network"
                  onChange={onFiltersChange}
                />
                <Dropdown
                  options={countries.map((el) => ({
                    label: el,
                    value: el.toLowerCase(),
                  }))}
                  name="location"
                  placeholder="Select location"
                  onChange={onFiltersChange}
                />

                <Dropdown
                  options={[{ name: "", value: "" }, ...storeRanks].map(
                    (item) => ({
                      label: item.name,
                      value: item.name,
                    })
                  )}
                  name="rank"
                  placeholder="Rank"
                  value={searchQueryValues.rank}
                  onChange={onChange}
                />

                {isOMA && (
                  <Dropdown
                    options={[0, 1, 2, 3, 4, 5].map((item) => ({
                      label: `${String(item)} Coupons`,
                      value: `${String(item)}`,
                    }))}
                    value={searchQueryValues.coupon_count}
                    name="coupon_count"
                    placeholder="Active Coupons"
                    onChange={onChange}
                  />
                )}
              </div>
            </div>

            {!isOMA && (
              <div
                className="inline-flex flex-col w-full rounded-md shadow-sm md:w-auto md:flex-row"
                role="group"
              >
                <button
                  type="button"
                  onClick={onViewCommissions}
                  className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white"
                >
                  Commission Changes
                </button>
                <button
                  type="button"
                  onClick={onViewRecentlyDeactivated}
                  className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-gray-200 border-x md:border-x-0 md:border-t md:border-b hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white"
                >
                  Recently Deactivated Partnerships
                </button>

                {/* <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-gray-200 border hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white"
                >
                  Deleted Pages
                </button> */}
                {/* <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg md:rounded-bl-none md:rounded-r-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white"
                >
                  Direct Program Suggestions
                </button> */}
              </div>
            )}
          </div>
        </div>
        {isOMA && (
          <div className="flex mx-4 mb-4 mt-2">
            {/* <div className="flex items-center mr-4 mt-3">
              <input
                checked={searchQueryValues.has_no_coupons === "1"}
                name="has_no_coupons"
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  onFiltersChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: "has_no_coupons",
                      value: checked ? "1" : "0",
                    },
                  });
                }}
                className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="completed"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Pages with No Coupons
              </label>
            </div> */}
            <div className="flex items-center mr-4 mt-3">
              <input
                checked={searchQueryValues.has_no_codes === "1"}
                name="has_no_codes"
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  onFiltersChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: "has_no_codes",
                      value: checked ? "1" : "0",
                    },
                  });
                }}
                className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="in-progress"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Pages with No Codes
              </label>
            </div>
          </div>
        )}
        <DataTable
          header={
            <>
              {!isOMA ? (
                <AdvertiserFilters
                  is_ad_center={is_ad_center}
                  is_deactivated={is_deactivated}
                  onChange={onChange}
                  has_open_ppc_policy={has_open_ppc_policy}
                  has_no_faqs={has_no_faqs}
                  is_free={is_free}
                  programType="Active"
                  has_ai_faqs={has_ai_faqs}
                  network_status={network_status}
                  totalCount={count}
                  selectedItems={selectedItems}
                  allSelected={allSelected}
                  setAllSelected={setAllSelected}
                  exportResourceData={exportResourceData}
                  onExportAll={onExportAll}
                  activeTab={activeTab}
                />
              ) : null}
            </>
          }
          columns={isOMA ? OMA_ACTIVE_PROGRAM_COLUMNS : ACTIVE_PROGRAM_COLUMNS}
          rows={data?.map((el: Record<string, any>) => ({
            ...el,
            website: el.website_url,
            website_base: getHostName(el.website_url),
            network: el.primary_network?.name || "",
            commission: {},
            external_id: el.id,
            created_at: el.created,
          }))}
          onShowAll={onShowAll}
          enableShowAll
          columnProps={{
            [ACTIVE_PROGRAM_COLUMNS[0].key]: {
              onChange: handleSelectAll,
              checked: allSelected || selectedItems?.length === count,
            },
          }}
          rowRenderer={(row) => (
            <AllAdvertiserRow
              item={row}
              key={row.id}
              renderCouponCount={isOMA}
              renderCouponCodeCount={isOMA}
              onViewPage={onViewPage}
              onItemSelectChange={onItemSelectChange}
              selectedItems={selectedItems}
              showCommissionRate={false}
              hideCreationDate={isOMA}
            />
          )}
          loading={loading}
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalCount={count}
        />
      </div>
    </div>
  );
};

export default ActivePrograms;
