import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import axios from "helpers/axios";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";

export default createAsyncThunk(
  "sales/create-update-delete-payment",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      if (payload.file) {
        const s3 = new S3({
          ...defaultS3Config,
          dirName: `img/${localStorage.getItem(
            LOCAL_KEY_SELECTED_CLIENT_ID
          )}/payments/proposals/${payload.proposal_id}`,
        });
        const result = await s3.uploadFile(payload.file);
        const { location } = result;
        payload["attachment_url"] = location;
      }

      const response = payload.id
        ? payload.delete
          ? await axios.delete(`/admin/sales/payments/${payload.id}`)
          : await axios.put(`/admin/sales/payments/${payload.id}`, payload)
        : await axios.post(`/admin/sales/payments`, payload);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
