import Spinner from "components/common/layout/Spinner";
import Layout from "components/layout/Layout";
import TrendingStoreRow from "components/campaigns/TrendingStoreRow";
import { ChangeEventHandler } from "react";
import { ActionState } from "types/data";
import SearchPrograms from "containers/oma/SearchPrograms";
import Dropdown from "components/common/Dropdown";
import countries from "constants/operationCountries";
import {
  PLACEMENT_DEST_HOME_NAVIGATION,
  PLACEMENT_DEST_STORE_SIDE_BAR,
} from "constants/placementDestinations";

type Props = {
  onResultClick: (el: Record<string, any>) => void;
  handleRemoveTrendingStore: (el: Record<string, any>) => void;
  trendingResults: ActionState<Record<string, any>[]>;
  addToPaidPlacementsData: ActionState;
  filterValues: Record<string, string | number>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  deleteTrendingStoreData: ActionState;
  storeName: string;
};

export default function TrendingStoresComponent({
  onResultClick,
  handleRemoveTrendingStore,
  trendingResults: { loading, data },
  filterValues,
  onChange,
  deleteTrendingStoreData,
  addToPaidPlacementsData,
  storeName,
}: Props) {
  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <Dropdown
              placeholder="Location"
              name="location"
              value={filterValues.location}
              onChange={onChange}
              options={countries.map((el) => ({
                label: el,
                value: el,
              }))}
              className="py-3"
            />

            <SearchPrograms
              wrapperClassName="mx-0"
              onResultClicked={onResultClick}
              value={storeName}
              searchParams={{
                location: filterValues.location,
              }}
            />
          </div>
        </div>

        <div className="sm:flex sm:items-center mt-6">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Trending Stores
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Trending Stores are located on{" "}
              {filterValues.placement_destination ===
                PLACEMENT_DEST_STORE_SIDE_BAR && "Stores page"}
              {filterValues.placement_destination ===
                PLACEMENT_DEST_HOME_NAVIGATION && "Homepage"}{" "}
              as a{" "}
              {filterValues.placement_destination ===
                PLACEMENT_DEST_STORE_SIDE_BAR && "sidebar Ad Unit"}
              {filterValues.placement_destination ===
                PLACEMENT_DEST_HOME_NAVIGATION && "Main navigation Ad Unit"}
              . Maximum 10 stores can be highlighted.
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          {(loading || addToPaidPlacementsData.loading) && <Spinner />}
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Program
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Network
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((item: Record<string, any>) => (
                      <TrendingStoreRow
                        key={item.id}
                        el={item}
                        deleteTrendingStoreData={deleteTrendingStoreData}
                        handleRemoveTrendingStore={handleRemoveTrendingStore}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
