import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";
import getShortCouponInfo from "./getShortCouponInfo";

type Props = {
  slug: string;
};

const useHeadlines = ({ slug }: Props) => {
  const dispatch = useAppDispatch();

  const [topCoupon, setTopCoupon] = useState<Record<string, any> | null>(null);
  const [headLines, setHeadLines] = useState<string[] | null>(null);
  const { data } = useAppSelector((state) => state.getActiveCoupons);

  useEffect(() => {
    dispatch(getActiveCoupons({ store_slug: slug, page: 1 }));
  }, [slug]);

  useEffect(() => {
    if (data.results.length) {
      const item = data.results.find(
        (it: Record<string, any>) => it.sorting_for_store === "0"
      );

      if (item) {
        setTopCoupon(item);
      } else {
        setTopCoupon(data.results[0]);
      }
    }
  }, [data.results]);

  useEffect(() => {
    if (topCoupon) {
      const shortCouponStr = getShortCouponInfo(topCoupon);

      if (!shortCouponStr) {
        setHeadLines([
          `${topCoupon.store.name} Validated Discounts${
            topCoupon.store.name.length < 6 ? " & Sales" : ""
          }`,
          `${topCoupon.store.name} Exclusive Discounts${
            topCoupon.store.name.length < 6 ? " & Offers" : ""
          }`,
          `Verified ${topCoupon.store.name} Coupons`,
          `Exclusive Offers & Discounts`,
          `Exclusive ${topCoupon.store.name} Voucher${
            topCoupon.store.name.length < 6 ? " Codes" : "s"
          }`,

          "Discount Codes in Real-Time",
          "Free Coupons for You",
          "Save Big with Coupons",
          "Official Coupons & Promo Codes",
          "Today's Top Coupons and Deals",
          `Official Savings ${new Date().getFullYear()}`,
          "Exclusive Savings and Offers",
          "Free Coupons & Discount Codes",
        ]);
      }

      if (topCoupon.store.location !== "uk" && shortCouponStr) {
        setHeadLines([
          `${shortCouponStr} ${topCoupon.store.name} Coupon${
            topCoupon.store.name.length < 6 ? " Code" : ""
          }`,
          `${shortCouponStr} ${topCoupon.store.name} Discount${
            topCoupon.store.name.length < 6 ? " Code" : ""
          }`,
          `${shortCouponStr} ${topCoupon.store.name} Promo${
            topCoupon.store.name.length < 6 ? " Code" : ""
          }`,
          `${shortCouponStr} ${topCoupon.store.name} Promotion${
            topCoupon.store.name.length < 6 ? " Code" : ""
          }`,
          `${topCoupon.store.name} Sitewide Promo${
            topCoupon.store.name.length < 6 ? " Codes & Deals" : "tions"
          }`,

          "Discount Codes in Real-Time",
          "Free Coupons for You",
          "Save Big with Coupons",
          "Official Coupons & Promo Codes",
          "Today's Top Coupons and Deals",
          `Official Savings ${new Date().getFullYear()}`,
          "Exclusive Savings and Offers",
          "Free Coupons & Discount Codes",
        ]);
      }

      if (topCoupon.store.location === "uk" && shortCouponStr) {
        setHeadLines([
          `${shortCouponStr} ${topCoupon.store.name} Discount${
            topCoupon.store.name.length < 6 ? " Code" : ""
          }`,
          `${shortCouponStr} ${topCoupon.store.name} Voucher${
            topCoupon.store.name.length < 6 ? " Code" : ""
          }`,
          `${shortCouponStr} ${topCoupon.store.name} Promo${
            topCoupon.store.name.length < 6 ? " Code" : ""
          }`,
          `Exclusive ${topCoupon.store.name} Voucher${
            topCoupon.store.name.length < 6 ? " Codes" : "s"
          }`,

          "Discount Codes in Real-Time",
          "Free Vouchers for You",
          "Save Big with Vouchers",
          "Official Vouchers & Promo Codes",
          "Today's Top Vouchers and Deals",
          `Official Savings ${new Date().getFullYear()}`,
          "Exclusive Savings and Offers",
          "Free Vouchers & Discount Codes",
        ]);
      }
    }
  }, [topCoupon?.store?.name]);

  return headLines;
};

export default useHeadlines;
