/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCouponOfferTypes from "redux/thunks/app/api-clients/getCouponOfferTypes";
import { ActionState } from "types/data";
import { StoreType } from "types/model/oma/StoreType";

const initialState: ActionState<StoreType[]> = {
  data: [],
  loading: false,
  error: null,
};

const getCouponOfferTypesSlice = createSlice({
  name: "CouponOfferTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCouponOfferTypes.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getCouponOfferTypes.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getCouponOfferTypes.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getCouponOfferTypesSlice.reducer;
