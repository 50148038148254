import SalesCRMTabs from "components/SalesCRMTabs";
import AgenciesContainer from "containers/sales/crm/Agencies";
import SalesCRMContacts from "containers/sales/crm/Contacts";
import SalesCRMNetworks from "containers/sales/crm/Networks";
import NewEvent from "containers/sales/crm/NewEvent";
import Store from "containers/sales/crm/Store";

const sales_crm_routes = [
  { path: "sales/crm/", element: <SalesCRMTabs /> },
  { path: "sales/crm/agencies/", element: <AgenciesContainer /> },
  {
    path: "sales/crm/networks",
    element: <SalesCRMNetworks />,
  },

  {
    path: "sales/crm/contacts",
    element: <SalesCRMContacts />,
  },

  {
    path: "sales/crm/new-event",
    element: <NewEvent />,
  },

  {
    path: "sales/crm/store/:slug",
    element: <Store />,
  },
];

export default sales_crm_routes;
