import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "redux/initial-states/paginatedItemsInitialState";
import updateStoreExtSettings from "redux/thunks/extension/updateStoreExtSettings";

const updateStoreExtSettingsSlice = createSlice({
  name: "updateStoreExtSettingsSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateStoreExtSettings.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(updateStoreExtSettings.fulfilled, (state, action: any) => {
        const { payload } = action;
        if ({ payload }) {
          return {
            ...state,
            data: payload,

            loading: false,
          };
        }
      })

      .addCase(
        updateStoreExtSettings.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default updateStoreExtSettingsSlice.reducer;
