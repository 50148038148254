import Button from "components/common/Button";
import Layout from "components/layout/Layout";
import { ChangeEventHandler, Dispatch, SetStateAction } from "react";
import { ActionState, PaginatedActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";
import { Link } from "react-router-dom";
import ProgramHeader from "containers/bma/ProgramHeader";
import ProposalDetails from "./ProposalDetails";
import { ProposalType, SearchRightsType } from "types/model/sales/Proposal";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  handleFormSubmit: () => void;
  form: Record<string, any>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  getCategoriesData: ActionState<ProgramCategory[]>;
  placements: Array<Record<string, any>>;
  setPlacements: Dispatch<SetStateAction<Record<string, any>[]>>;
  createUpdateDeleteProposalData: ActionState;
  isDeleting: boolean;
  formPage: string;
  lineItemsActive: boolean;
  setLineItemsActive: Dispatch<SetStateAction<boolean>>;
  finalAmount: string | number;
  totalAmount: string | number;
  getPositionAvailabilityStatusesData: ActionState<Record<string, any>>;
  removePaidPlacements: (item: Record<string, any>) => void;
  isViewing: boolean;
  onEditClicked: () => void;
  getProgramData: ActionState;
  handleAddForNewClient: () => void;
  onProgramSearchResultClicked: (result: Record<string, any>) => void;
  duplicateItem: (item: Record<string, any>, idx: number) => void;
  onLineItemAdded: () => void;
  proposalTypes: PaginatedActionState<ProposalType>;
  getSearchRightsTypesData: PaginatedActionState<SearchRightsType>;
  deleteProposal: (
    proposal: Record<string, any>,
    delete_reason: string
  ) => void;
};

const CreateUpdateDeleteProposal = ({
  handleFormSubmit,
  onChange,
  form,
  setForm,
  getCategoriesData,
  placements,
  setPlacements,
  createUpdateDeleteProposalData,
  isDeleting,
  formPage,
  lineItemsActive,
  deleteProposal,
  setLineItemsActive,
  finalAmount,
  totalAmount,
  getPositionAvailabilityStatusesData,
  removePaidPlacements,
  isViewing,
  onEditClicked,
  getProgramData,
  handleAddForNewClient,
  onProgramSearchResultClicked,
  duplicateItem,
  onLineItemAdded,
  getSearchRightsTypesData,
  proposalTypes,
}: Props) => {
  if (!form.id) {
    return (
      <Layout>
        <ProposalDetails
          deleteProposal={deleteProposal}
          proposalTypes={proposalTypes}
          duplicateItem={duplicateItem}
          handleAddForNewClient={handleAddForNewClient}
          createUpdateDeleteProposalData={createUpdateDeleteProposalData}
          finalAmount={finalAmount}
          onChange={onChange}
          handleFormSubmit={handleFormSubmit}
          form={form}
          setForm={setForm}
          getCategoriesData={getCategoriesData}
          placements={placements}
          setPlacements={setPlacements}
          isDeleting={isDeleting}
          formPage={formPage}
          lineItemsActive={lineItemsActive}
          setLineItemsActive={setLineItemsActive}
          totalAmount={totalAmount}
          getPositionAvailabilityStatusesData={
            getPositionAvailabilityStatusesData
          }
          removePaidPlacements={removePaidPlacements}
          isViewing={isViewing}
          onEditClicked={onEditClicked}
          getProgramData={getProgramData}
          onProgramSearchResultClicked={onProgramSearchResultClicked}
          onLineItemAdded={onLineItemAdded}
          getSearchRightsTypesData={getSearchRightsTypesData}
        />
      </Layout>
    );
  }

  if (isDeleting) {
    return (
      <Layout>
        <p>Are you sure you want to delete this proposal?</p>

        <div className="flex gap-4 py-2 items-center">
          <Link to="/sales">Cancel</Link>
          <Button
            loading={createUpdateDeleteProposalData.loading}
            className="text-sm"
            onClick={handleFormSubmit}
            text="Continue"
          />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <ProgramHeader />
      <ProposalDetails
        deleteProposal={deleteProposal}
        proposalTypes={proposalTypes}
        duplicateItem={duplicateItem}
        handleAddForNewClient={handleAddForNewClient}
        createUpdateDeleteProposalData={createUpdateDeleteProposalData}
        finalAmount={finalAmount}
        onChange={onChange}
        handleFormSubmit={handleFormSubmit}
        form={form}
        setForm={setForm}
        getCategoriesData={getCategoriesData}
        placements={placements}
        setPlacements={setPlacements}
        isDeleting={isDeleting}
        formPage={formPage}
        lineItemsActive={lineItemsActive}
        setLineItemsActive={setLineItemsActive}
        totalAmount={totalAmount}
        getPositionAvailabilityStatusesData={
          getPositionAvailabilityStatusesData
        }
        removePaidPlacements={removePaidPlacements}
        isViewing={isViewing}
        onEditClicked={onEditClicked}
        getProgramData={getProgramData}
        onProgramSearchResultClicked={onProgramSearchResultClicked}
        onLineItemAdded={onLineItemAdded}
        getSearchRightsTypesData={getSearchRightsTypesData}
      />
    </Layout>
  );
};
export default CreateUpdateDeleteProposal;
