import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import TodoLayout from "layout/todos/TodoLayout";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import getTodoStats from "redux/thunks/todos/getTodoStats";
import getTodos from "redux/thunks/todos/getTodos";
import setTodoComplete from "redux/thunks/todos/setTodoComplete";
import { TodoItem } from "types/model/Todo";

type Props = {
  todoType?: number;
};

const TodoItems = ({ todoType }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const todoItems = useAppSelector((state) => state.getTodos);

  const { completed = "0" } = Object.fromEntries([...searchParams]);

  useEffect(() => {
    if (todoType) {
      dispatch(
        getTodos({
          completed,
          todo_type: todoType || "",
        })
      );
    }
    dispatch(getTodoStats({ completed: "0" }));
  }, [todoType, completed]);

  const handleComplete = (todo: TodoItem) => {
    dispatch(setTodoComplete(todo)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Item marked as completed");
        dispatch(
          getTodos({
            completed: "0",
            todo_type: todoType || "",
          })
        );
        dispatch(getTodoStats({ completed: "0" }));
      }
    });
  };

  const onFilterChange = (value: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("completed", value);
    navigate(url.search);
  };

  return (
    <TodoLayout
      onFilterChange={onFilterChange}
      todoItems={todoItems}
      handleComplete={handleComplete}
      completed={completed}
    />
  );
};

export default TodoItems;
