import classNames from "classnames";
import React from "react";
import { GrClose, GrMultiple } from "react-icons/gr";

type Props = {
  store: Record<string, any>;
  onStoreClicked?: (item: Record<string, any>) => void;
  deleteClicked?: (item: Record<string, any>) => void;
  showDelete?: boolean;
};

const StoreCard = ({
  store,
  onStoreClicked,
  showDelete,
  deleteClicked,
}: Props) => {
  return (
    <li
      key={store.id}
      onClick={() => {
        onStoreClicked?.(store);
      }}
      className={classNames(
        "relative cursor-pointer list-none select-none py-2 pl-3 pr-9 shadow-md  hover:bg-gray-100 hover:font-semibold text-gray-900 my-4"
      )}
    >
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center">
          <img
            src={store.logo_url}
            height={50}
            width={50}
            className="mr-4"
            alt={store.name + " logo"}
          />
          <div className="end">
            <span className={classNames("block truncate")}>{store.name}</span>

            {<small>{store.network}</small>}
            {<small>{store.number_of_coupons} Coupons</small>}
          </div>
        </div>
        {showDelete && (
          <button onClick={deleteClicked}>
            <GrClose fontSize={18} />
          </button>
        )}
      </div>
    </li>
  );
};

export default StoreCard;
