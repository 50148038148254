import DateRangeSelector from "components/common/DateRange";
import Message from "components/common/Message";
import Spinner from "components/common/layout/Spinner";
import ContributorResult from "layout/contributions/ContributorResult";
import dayjs from "dayjs";
import { ChangeEventHandler } from "react";
import { CouponContributionsActionState } from "redux/slices/clients/performance/getCouponContributors";
import { User } from "types/model/User";
import Layout from "components/layout/Layout";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";

type Props = {
  getCouponContributorsData: CouponContributionsActionState;
  getAllUsersLoading: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  userOptions: User[];
  form: Record<string, any>;
};

const CouponContributions = ({
  getAllUsersLoading,
  onChange,
  userOptions,
  getCouponContributorsData,
  form,
}: Props) => {
  return (
    <Layout>
      <div className="flex gap-4 my-4">
        <MultipleSelectionInput
          wrapperClassName="w-96"
          className="py-3"
          options={userOptions.map((el) => ({
            label: el.first_name + " " + el.last_name,
            value: el.email,
            selected: form.uploaders.includes(el.email),
          }))}
          loading={getAllUsersLoading}
          name="uploaders"
          placeholder="Select uploaders"
          onChange={onChange}
          renderAll
        />

        <DateRangeSelector
          disableConfirm
          form={{
            startDate: dayjs(form.start_date || dayjs().toDate()),
            endDate: dayjs(form.end_date || dayjs().toDate()),
          }}
          onChange={onChange}
        />
      </div>

      <div className="p-4">
        {getCouponContributorsData.error && (
          <Message
            error
            description={JSON.stringify(
              getCouponContributorsData.error
            ).substring(0, 100)}
          />
        )}
        {getCouponContributorsData.loading && <Spinner />}

        {getCouponContributorsData.data && form.uploaders.length > 0 && (
          <ContributorResult
            counts={getCouponContributorsData.data.map(
              (count: Record<string, any>) => ({
                ...count,
                user: userOptions.find(
                  (it) => it.email === count.created_by_username
                ),
              })
            )}
          />
        )}
      </div>
    </Layout>
  );
};

export default CouponContributions;
