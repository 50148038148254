import { Column } from "types/column";

const EMAIL_SUBSCRIBER_COLUMNS = [
  "Joined",
  "Email Address",
  "Device",
  "IP Address",
  "City",
  "State",
  "Country",
  "Source URL",
  "Verification Status",
  "Verification Date",
  "",
].map((el) => ({
  key: el,
  label: el,
})) as Column[];

export default EMAIL_SUBSCRIBER_COLUMNS;
