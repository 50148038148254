import getTransactionReport from "redux/slices/reporting/revenue/getTransactionReport";
import getExtensionReport from "redux/slices/reporting/extension/getExtensionReport";
import getDetailedVisitsDetailReport from "redux/slices/reporting/extension/getVisitsReport";

const reportingReducers = {
  getTransactionReport,
  getExtensionReport,
  getDetailedVisitsDetailReport,
};

export default reportingReducers;
