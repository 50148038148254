import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IgnoreLink from "redux/thunks/links/IgnoreLink";
import getLinks from "redux/thunks/links/getLinks";
import { PaginatedData } from "types/data";
import { Link } from "types/model/Link";

export type ActionStateType = {
  data: PaginatedData<Link>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
      extra: {
        advertiser_count: 0,
      },
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getLinksSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    resetLinks: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLinks.pending, (state, action) => {
        return {
          ...state,
          error: null,
          data: action.meta.arg.skipCache ? state.data : initialState.data,
          loading: true,
        };
      })
      .addCase(getLinks.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if ({ payload }) {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      })
      .addCase(
        IgnoreLink.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                items: state.data.items.filter(
                  (it) =>
                    !payload.ids
                      .map((it: number) => String(it))
                      .includes(String(it.id)),
                ),
              },
              loading: false,
            };
          }
        },
      )

      .addCase(getLinks.rejected, (state, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: false,
        error: payload,
      }));
  },
});
export const { resetLinks } = getLinksSlice.actions;
export default getLinksSlice.reducer;
