export const OFFER_TYPE_SITE_WIDE_AMOUNT = "sitewide amount";
export const OFFER_TYPE_SITE_WIDE_PERCENT = "sitewide percent";
export const OFFER_TYPE_FREE_SHIPPING = "free shipping";
export const OFFER_TYPE_GENERIC_FREE_SHIPPING = "generic free shipping";
export const OFFER_TYPE_PRODUCT_DEAL = "product deal";
export const OFFER_TYPE_SALES = "sales";
export const OFFER_TYPE_CLEARANCE = "clearance";
export const OFFER_TYPE_SALES_DEALS_CLEARANCE = "Sales, Deals and Clearance";
export const OFFER_TYPE_BOGO = "BOGO";
export const OFFER_TYPE_NEWS_LETTER_SIGNUP = "Newsletter Signup";
export const OFFER_TYPE_FREE_PRODUCT = "Free Product";
export const OFFER_TYPE_REFER_A_FRIEND = "Refer a Friend";
export const OFFER_TYPE_OTHER = "Other";

export const offerTypes = [
  OFFER_TYPE_SITE_WIDE_AMOUNT,
  OFFER_TYPE_SITE_WIDE_PERCENT,
  OFFER_TYPE_FREE_SHIPPING,
  OFFER_TYPE_GENERIC_FREE_SHIPPING,
  OFFER_TYPE_SALES_DEALS_CLEARANCE,
  OFFER_TYPE_BOGO,
  OFFER_TYPE_NEWS_LETTER_SIGNUP,
  OFFER_TYPE_FREE_PRODUCT,
  OFFER_TYPE_REFER_A_FRIEND,
  OFFER_TYPE_OTHER,
];
