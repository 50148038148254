import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import TextArea from "components/common/TextArea";
import Checkbox from "components/common/Checkbox";
import Button from "components/common/Button";
import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { twMerge } from "tailwind-merge";
import DeactivateCouponBtn from "components/coupons/DeactivateCoupon";
import { User } from "types/model/User";
import { USER_ROLE_ADMIN } from "constants/user_roles";

type Props = {
  state: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  error: Record<string, any> | null;
  handleSubmit: () => void;
  submitting: boolean;
  deactivateCoupon: () => void;
  disabled: boolean;
  user: User | null;
};

const CouponMainForm = ({
  state,
  error,
  form,
  onChange,
  submitting,
  handleSubmit,
  disabled,
  deactivateCoupon,
  user,
}: Props) => {
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  const wrapperClass = twMerge(
    "p-4 w-full space-y-8 lg:px-8",
    disabled && !form.id && "cursor-not-allowed"
  );

  return (
    <fieldset disabled={disabled && !form.id} className={wrapperClass}>
      {state?.network_advertiser && (
        <div>
          <span className="inline-flex rounded-md shadow-sm ">
            <span className="inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2">
              <label htmlFor="select-all" className="sr-only">
                Select all
              </label>
              <input
                id="select-all"
                type="checkbox"
                name="select-all"
                checked
                className="h-4 w-4  rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </span>
            <Dropdown
              name="apply_default_homepage_url"
              className="border-l-0 rounded-l-none "
              value={
                (form.apply_default_homepage_url && "1") ||
                (!form.apply_default_homepage_url && "2")
              }
              onChange={(e) => {
                onChange({
                  ...e,
                  target: {
                    ...e.target,
                    name: "apply_default_homepage_url",
                    value: String(e.target.value) === "1" ? true : false,
                  },
                } as any);
              }}
              options={[
                "Apply Default Homepage URL",
                "Destination Affiliate URL",
              ].map((it, idx) => ({
                label: it,
                value: idx + 1,
              }))}
              defaultLabel={false}
            />
          </span>
        </div>
      )}
      <div>
        <Input
          label="Landing Page URL"
          placeholder="https://"
          name="custom_url"
          error={error?.custom_url}
          value={form.custom_url}
          disabled={user?.user_role !== USER_ROLE_ADMIN}
          inputContainerClass="bg-white"
          onChange={onChange}
        />
      </div>

      <div>
        <Input
          label="Coupon Headline"
          name="title"
          value={form.title || ""}
          error={error?.title}
          placeholder="e.g. 10% Off Coupon Code"
          inputContainerClass="bg-white"
          onChange={onChange}
        />
      </div>

      <div>
        <TextArea
          id="description"
          rows={4}
          label="Coupon Description"
          maxLength={560}
          name="description"
          value={form.description || ""}
          error={error?.description}
          className="bg-white border-gray-200"
          onChange={(e) => {
            onChange({
              ...e,
              target: {
                name: "description",
                value: e.target.value,
              },
            } as unknown as ChangeEvent<HTMLInputElement>);
          }}
        >
          {form.description || ""}
        </TextArea>
      </div>

      <div className="flex items-center  mr-4">
        <Checkbox
          checked={form.exclusions}
          onChange={(e) => {
            onChange({
              ...e,
              target: {
                name: "exclusions",
                value: e.target.checked,
              },
            } as any);
          }}
          name="exclusions"
          label="Exclusions Apply"
        />
      </div>

      <div className="flex justify-end gap-4 items-center">
        {form.id && (
          <button
            type="button"
            onClick={() => {
              setDeactivateOpen(true);
            }}
            className="text-red-400  inline-flex items-center hover:text-white border border-red-600 hover:bg-red-400 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
          >
            <svg
              className="mr-1 -ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            {form.userAdded ? "Delete" : "Deactivate"}
          </button>
        )}

        <DeactivateCouponBtn
          onConfirm={deactivateCoupon}
          deactivateOpen={deactivateOpen}
          setDeactivateOpen={setDeactivateOpen}
          userAdded={form.userAdded}
        />

        <Button
          onClick={handleSubmit}
          loading={submitting}
          text={
            form.id
              ? form.userAdded
                ? "Activate Coupon"
                : "Save Changes"
              : "Activate Coupon"
          }
          className={twMerge(
            "justify-end  rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto",
            disabled && "opacity-25"
          )}
        ></Button>
      </div>
    </fieldset>
  );
};

export default CouponMainForm;
