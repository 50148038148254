import useLoadAuth from "hooks/useLoadAuth";
import HeaderNavLink from "./HeaderNavLink";
import CouponsDropDown from "components/CouponsDropDown";
import MarketingDropDown from "components/MarketingDropDown";
import SalesDropDown from "components/SalesDropdown";
import ReportingDropDown from "components/ReportingDropDown";
import CRMDropdown from "components/CRMDropdown";

const HeaderNavLinks = () => {
  const { user } = useLoadAuth();

  const hasAccess = (app: string) => {
    if (user?.user_role === "ADMIN") {
      return true;
    }
    if (
      !user?.user_apps
        ?.map((it) => it.name.toLowerCase())
        ?.includes(app.toLowerCase())
    ) {
      return false;
    }
    return true;
  };

  const links = [
    {
      title: "Programs",
      paths: [
        "/?network_id=cj",
        "/",
        "/bma/activate",
        "/bma/programs",
        "/bma/programs?network_id=cj",
        "/bma/deactivated-programs",
      ],
      enabled: hasAccess("programs"),
    },
    {
      title: "Coupons",
      paths: [
        "/oma",
        "/oma/program",
        "/oma/categories",
        "/contributions/coupons",
      ],
      enabled: hasAccess("coupons"),
    },
    {
      title: "Marketing",
      paths: ["/marketing/email", "/marketing/email/members"],
      enabled: hasAccess("marketing"),
    },
    {
      title: "Sales",
      paths: ["/sales", "/sales/add-pricing-plan"],
      enabled: hasAccess("sales"),
    },

    {
      title: "CRM",
      paths: ["/crm"],
      enabled: hasAccess("crm"),
    },
    {
      title: "Reports",
      paths: ["/reporting"],
      enabled: hasAccess("reporting"),
    },
  ];

  const renderItem = (item: Record<string, any>) => {
    if (!item.enabled) {
      return null;
    }

    if (item.title === "Reports") {
      return <ReportingDropDown key={item.title} disabled={!item.enabled} />;
    }

    if (item.title === "Marketing") {
      return <MarketingDropDown key={item.title} disabled={!item.enabled} />;
    }

    if (item.title === "Sales") {
      return (
        <SalesDropDown
          paths={item.paths}
          key={item.title}
          disabled={!item.enabled}
        />
      );
    }

    if (item.title === "CRM") {
      return (
        <CRMDropdown
          paths={item.paths}
          key={item.title}
          disabled={!item.enabled}
        />
      );
    }

    return item.title === "Coupons" ? (
      <CouponsDropDown key={item.title} paths={item.paths} />
    ) : (
      <HeaderNavLink
        disabled={!item.enabled}
        paths={item.paths}
        title={item.title}
        key={item.title}
      />
    );
  };

  return (
    <div className="flex items-center justify-between">
      {links.map((el) => renderItem(el))}
    </div>
  );
};

export default HeaderNavLinks;
