import formatDate from "utils/dates/formatDate";
import { getFilenameFromURL } from "utils/urls/getFilenameFromURL";

type Props = {
  note: Record<string, any>;
};

const NoteItem = ({ note }: Props) => {
  const platforms = [
    ...(note.search_engines || []),
    ...[note.ad_campaign?.search_engine],
  ]
    .filter(Boolean)
    .filter((it) => typeof it === "string");

  return (
    <div>
      <div className="flex gap-4 items-center">
        <h4 className="text-gray-500 font-medium uppercase text-sm">
          {formatDate(note.date || note.created_at, "ddd, mmm d, yyyy")}
        </h4>
        <h5 className="text-sm ">
          {note.user?.first_name} {note.user?.last_name[0]}
        </h5>

        <div className="flex gap-4">
          {platforms.map((el) => (
            <img
              height={16}
              width={16}
              className="object-contain"
              src={
                el.toLowerCase() === "google"
                  ? "/google_ads.png"
                  : "/bing_ads.png"
              }
            ></img>
          ))}
        </div>
      </div>

      <p className="flex-auto py-0.5 text-sm leading-5 text-gray-500">
        {note.details}
      </p>

      {note.file_urls.length > 0 && (
        <ul className="list-disc pl-5 space-y-1 border border-gray-300 rounded-lg p-2 md:w-1/4 w-full">
          {note.file_urls.map((file: string) => (
            <li
              key={file}
              onClick={() => {
                window.open(file, "_blank");
              }}
              className="text-gray-700 border-b cursor-pointer flex text-sm justify-between border-gray-200 last:border-none pb-1"
            >
              <span className="block">{getFilenameFromURL(file)}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NoteItem;
