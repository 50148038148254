import TabLayout from "components/common/TabLayout";
import Layout from "components/layout/Layout";
import { SALES_APP } from "constants/smaAppNames";
import { CREATE_PAID_PLACEMENT_PROP } from "constants/todoTypes";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import ProgramHeader from "containers/bma/ProgramHeader";
import Ios from "containers/sales/IOs";
import Proposals from "containers/sales/Proposals";
import TodoItems from "containers/todos/TodoItems";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppSelector } from "hooks/useRedux";
import { Link } from "react-router-dom";

const ProposalsIOHome = () => {
  const getProgramData = useAppSelector((state) => state.getProgram);

  const { user } = useLoadAuth();
  const hasProgramsAccess = user?.user_apps
    ?.map((it) => it.name.toLowerCase())
    ?.includes(SALES_APP.toLowerCase());

  const {
    data: { items: pendingTodos },
  } = useAppSelector((state) => state.getTodoStats);

  const pendingRequests = pendingTodos.filter(
    (el) => el.todo_type?.id === CREATE_PAID_PLACEMENT_PROP
  );

  const hasPermissions =
    user?.user_role == USER_ROLE_ADMIN || hasProgramsAccess || false;
  return (
    <Layout>
      {getProgramData.data?.name && <ProgramHeader wrapperClassName="mb-8" />}

      <TabLayout
        hasPermissions={hasPermissions}
        globalLayout={false}
        options={[
          { text: "Proposals", content: <Proposals /> },
          { text: "IOS", content: <Ios /> },
          {
            text: "Proposals to Create",
            label: pendingRequests.length,
            content: <TodoItems todoType={CREATE_PAID_PLACEMENT_PROP} />,
          },
        ]}
        rightElement={
          <div className="w-full mt-2 md:w-auto flex flex-col md:flex-row mb-3 md:mb-0 items-end md:items-center justify-end md:space-x-3 flex-shrink-0">
            <Link
              to={`/sales/proposals/add?slug=${
                getProgramData.data?.slug || ""
              }`}
            >
              <button
                type="button"
                onClick={() => {
                  //
                }}
                className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              >
                <svg
                  className="h-3.5 w-3.5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Create Proposal
              </button>
            </Link>
          </div>
        }
      />
    </Layout>
  );
};

export default ProposalsIOHome;
