import Spinner from "components/common/layout/Spinner";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { twMerge } from "tailwind-merge";
import formatNumber from "utils/strings/formatNumber";

export const formatXAxis = (tickItem: number): string => {
  // Add your custom formatting logic here
  return String(formatNumber(tickItem, { prefix: "$" }));
};

export const formatTooltip = (value: any) => {
  return String(formatNumber(value, { prefix: "$", decimals: 2 }));
};

type Props = {
  title: string;
  rangeOptions: Record<string, any>[];
  loading?: boolean;
  data: Record<string, any>[];
  barDataKey: string;
  xDataKey: string;
  amountFormat?: boolean;
};

const SimpleBarChart = ({
  title,
  rangeOptions,
  loading,
  data,
  barDataKey,
  xDataKey,
  amountFormat = true,
}: Props) => {
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h1>{title} </h1>
        <div className="flex gap-2 items-center">
          {loading && <Spinner className="h-6 w-6" />}
          {rangeOptions.map((el) => (
            <button
              key={el.label}
              onClick={el.onClick}
              className={twMerge(
                "border border-gray-200 my-2 inline-flex w-full rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm  mr-2 sm:w-auto",
                el.active && "bg-primary-700 text-white"
              )}
            >
              {el.label}
            </button>
          ))}
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xDataKey} />
          {amountFormat ? <YAxis tickFormatter={formatXAxis} /> : <YAxis />}
          {amountFormat ? <Tooltip formatter={formatTooltip} /> : <Tooltip />}

          <Legend />
          <Bar
            dataKey={barDataKey}
            fill={"#346888"}
            activeBar={<Rectangle fill="#9DC6E0" stroke="#5886A5" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default SimpleBarChart;
