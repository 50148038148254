import dateFormat from "dateformat";
import { truncate } from "lodash";
import React from "react";
import { EmailCampaignSetting } from "types/model/NewsletterSubscriber";

type Props = {
  row: EmailCampaignSetting;
  navigateToSetting: (row: EmailCampaignSetting) => void;
};

const CampaignSettingRow = ({ row, navigateToSetting }: Props) => {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.client.name}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.store_location.toUpperCase()}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.template_type.toUpperCase()}
      </td>
      <td className="whitespace-nowrap pl-4 py-4 text-sm text-gray-500">
        {truncate(row.starter_line, { length: 50 })}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.from_date ? dateFormat(new Date(row.from_date), "dd/mm/yyyy") : ""}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.to_date ? dateFormat(new Date(row.to_date), "dd/mm/yyyy") : ""}
      </td>

      <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
        <button
          onClick={() => {
            navigateToSetting(row);
          }}
          id="dropdown-button-0"
          type="button"
          data-dropdown-toggle="dropdown-0"
          className="inline-flex items-center p-1 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
          </svg>
        </button>
      </td>
    </tr>
  );
};

export default CampaignSettingRow;
