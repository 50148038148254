import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import axios from "helpers/axios";

export default createAsyncThunk(
  "advertisers/link",
  async (linkId: string, ThunkAPI) => {
    const url = new URL(
      `${BACKEND_BASE_URL}/admin/advertisers/links/${linkId}`,
    );

    try {
      const response = await axios.get(
        url.pathname
          .replace("/api", "")
          .replace("/production", "")
          .replace("/dev", "") + url.search,
      );

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
