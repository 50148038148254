import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import updatePaidPlacement from "redux/thunks/app/placements/campaigns/updatePaidPlacement";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const updatePaidPlacementSlice = createSlice({
  name: "updatePaidPlacement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePaidPlacement.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        updatePaidPlacement.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        updatePaidPlacement.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default updatePaidPlacementSlice.reducer;
