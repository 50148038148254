import {
  WEBSITE_CLIENT_NAMES,
  websitesIdNameMap,
} from "./../clientWebsitesIds";

type ClientPlacementTypeMap = {
  [key: string]: Array<string>;
};

const clientPlacementTypeMap: ClientPlacementTypeMap = {};

const defaultPages = [
  "Hero Coupons",
  "Coupon Listings",
  "Coupons from Seasonal Categories",
  "New Stores with Coupons",
  "Navigation Bar - Featured Store Listing",
  "Popular Brands - Store Logo Placement",
  "Trending Coupon Codes and Promo Codes",
  "Featured Coupon",
  "Top Stores - Logo Placement",
];

Object.entries(websitesIdNameMap).forEach(([k, v]) => {
  if (v != WEBSITE_CLIENT_NAMES.DISCOUNT_CODES_COM) {
    clientPlacementTypeMap[k] = defaultPages;
  } else {
    clientPlacementTypeMap[k] = [
      "Hero Coupons",
      "Coupon Listings",
      "Just Came In",
    ];
  }
});

export default clientPlacementTypeMap;
