import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import setTodoComplete from "redux/thunks/todos/setTodoComplete";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const setTodoCompleteSlice = createSlice({
  name: "setTodoComplete",
  initialState: initialState,
  reducers: {
    resetSetTodoComplete: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setTodoComplete.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        setTodoComplete.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        setTodoComplete.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetSetTodoComplete } = setTodoCompleteSlice.actions;
export default setTodoCompleteSlice.reducer;
