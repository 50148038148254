import { EXT_REPORTING_BASE_URL } from "config/env";
import {
  EXT_INSTALLS_COLUMNS,
  EXT_REMOVAL_COLUMNS,
  EXT_STAND_DOWN_COLUMNS,
  EXT_API_STORE_COLUMNS,
  EXT_USER_ACCOUNT_COLUMNS,
  NON_API_STORE_COLUMNS,
  EXT_CLOSING_COLUMNS,
} from "./tables/headers/extension/installationReport";

export const EXT_TYPE_OVERVIEW = "Extension Overview";
export const EXT_TYPE_INSTALLS = "Extension Installs";
export const EXT_TYPE_STORE_URLS = "API Store URLs";
export const EXT_TYPE_NON_API = "Non-API Site URLs";
export const EXT_TYPE_CONVERSION = "Conversion Report";
export const EXT_TYPE_UNINSTALLS = "Install Removals";
export const EXT_TYPE_STAND_DOWN = "Stand-Down Competitor Referrals";
export const EXT_TYPE_SAVED_COUPONS = "Saved Coupons";
export const EXT_TYPE_FOLLOWED_STORES = "Followed Stores";
export const EXT_TYPE_USER_ACCOUNTS = "User Accounts";
export const EXT_TYPE_CLOSING = "Extension Closing";

const extReportTypes = [
  EXT_TYPE_OVERVIEW,
  EXT_TYPE_INSTALLS,
  EXT_TYPE_STORE_URLS,
  EXT_TYPE_NON_API,
  EXT_TYPE_CONVERSION,
  EXT_TYPE_UNINSTALLS,
  EXT_TYPE_STAND_DOWN,
  EXT_TYPE_SAVED_COUPONS,
  EXT_TYPE_FOLLOWED_STORES,
  EXT_TYPE_USER_ACCOUNTS,
  EXT_TYPE_CLOSING,
];

export const extReportTypeConfig = {
  [EXT_TYPE_OVERVIEW]: {
    id: 1,
    title: EXT_TYPE_OVERVIEW,
  },
  [EXT_TYPE_INSTALLS]: {
    id: 2,
    title: "Extension Installs",
    endpoint: `${EXT_REPORTING_BASE_URL}/reporting/installs/?page=1`,
    columns: EXT_INSTALLS_COLUMNS,
  },
  [EXT_TYPE_STORE_URLS]: {
    id: 3,
    title: EXT_TYPE_STORE_URLS,
    columns: EXT_API_STORE_COLUMNS,
    endpoint: `${EXT_REPORTING_BASE_URL}/reporting/visits/?&is_api_store=1&page=1`,
  },
  [EXT_TYPE_NON_API]: {
    id: 4,
    title: EXT_TYPE_NON_API,
    columns: NON_API_STORE_COLUMNS,
    endpoint: `${EXT_REPORTING_BASE_URL}/reporting/visits/?&is_api_store=0&page=1`,
  },
  [EXT_TYPE_CONVERSION]: {
    id: 5,
    title: EXT_TYPE_CONVERSION,
  },
  [EXT_TYPE_UNINSTALLS]: {
    title: "Install Removals",
    columns: EXT_REMOVAL_COLUMNS,
    endpoint: `${EXT_REPORTING_BASE_URL}/reporting/un-installs/?page=1`,
    id: 6,
  },
  [EXT_TYPE_STAND_DOWN]: {
    columns: EXT_STAND_DOWN_COLUMNS,
    title: "Stand-Down Competitor Referrals",
    endpoint: `${EXT_REPORTING_BASE_URL}/reporting/stand-down/?page=1`,
    id: 7,
  },
  [EXT_TYPE_SAVED_COUPONS]: {
    id: 8,
    title: EXT_TYPE_SAVED_COUPONS,
  },
  [EXT_TYPE_FOLLOWED_STORES]: {
    id: 9,
    title: EXT_TYPE_FOLLOWED_STORES,
  },
  [EXT_TYPE_USER_ACCOUNTS]: {
    id: 10,
    title: EXT_TYPE_USER_ACCOUNTS,
    columns: EXT_USER_ACCOUNT_COLUMNS,
    endpoint: `${EXT_REPORTING_BASE_URL}/reporting/user-accounts/?page=1`,
  },

  [EXT_TYPE_CLOSING]: {
    id: 11,
    title: EXT_TYPE_CLOSING,
    columns: EXT_CLOSING_COLUMNS,
    endpoint: `${EXT_REPORTING_BASE_URL}/reporting/ext-close/?page=1`,
  },
} as Record<string, any>;

export default extReportTypes;
