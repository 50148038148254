import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";


export default createAsyncThunk(
  "sales_crm/get-network-types",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      if (payload.file) {
        const s3 = new S3({
          ...defaultS3Config,
          dirName: `img/crm-networks`,
        });
        const result = await s3.uploadFile(payload.file);
        const { location } = result;
        payload["logo_url"] = location;
      }
      const response = await axios.post(`/admin/sales-crm/networks/`, payload);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
