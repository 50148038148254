import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import completeAffiliateNewsletter from "redux/thunks/links/newsletters/completeAffiliateNewsletter";
import getAffiliateNewLetterFeed from "redux/thunks/links/newsletters/getAffiliateNewLetterFeed";
import { PaginatedData } from "types/data";
import { Link } from "types/model/Link";

export type ActionStateType = {
  data: PaginatedData<Link>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
      extra: {
        advertiser_count: 0,
      },
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getAffiliateNewLetterFeedSlice = createSlice({
  name: "getAffiliateNewsletterFeed",
  initialState,
  reducers: {
    resetAffiliateNewsletterFeed: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAffiliateNewLetterFeed.pending, (state, action) => {
        return {
          ...state,
          error: null,
          data: action.meta.arg.skipCache ? state.data : initialState.data,
          loading: true,
        };
      })
      .addCase(
        getAffiliateNewLetterFeed.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...payload,
                items: payload.items,
              },
              loading: false,
            };
          }
        }
      )
      .addCase(
        completeAffiliateNewsletter.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                items: state.data.items.filter(
                  (item: Record<string, any>) =>
                    !payload.items
                      .map((it: Record<string, any>) => it.email_id)
                      .includes(item.email_id)
                ),
              },
              loading: false,
            };
          }
        }
      )
      .addCase(
        getAffiliateNewLetterFeed.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetAffiliateNewsletterFeed } =
  getAffiliateNewLetterFeedSlice.actions;
export default getAffiliateNewLetterFeedSlice.reducer;
