import CategorySelector from "components/common/CategorySelector";
import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import Label from "components/common/Label";
import Message from "components/common/Message";
import Switch from "components/common/Switch";
import Spinner from "components/common/layout/Spinner";
import Layout from "components/layout/Layout";
import ImageDropzone from "components/program/ImageDropzone";
import { ChangeEvent, ChangeEventHandler, FormEventHandler } from "react";
import { ActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";
import toPascalCase from "utils/strings/toPascalCase";

type Props = {
  loading: boolean;
  data: Array<ProgramCategory>;
  form: Record<string, any>;
  error: Record<string, any> | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  getCategoryData: ActionState<ProgramCategory | null>;
  createUpdateCategoryLoading: boolean;
  onFileSelected: (file: Record<string, any>) => void;
  files: Record<string, any>;
  errors: Record<string, any>;
};

export default function OMAAddCategory({
  data,
  error,
  onChange,
  form,
  createUpdateCategoryLoading,
  getCategoryData,
  onSubmit,
  onFileSelected,
  files,
  errors,
}: Props) {
  if (getCategoryData.error) {
    return (
      <Layout>
        <Message
          error
          description={
            getCategoryData.error.detail ||
            JSON.stringify(getCategoryData.error)
          }
        />
      </Layout>
    );
  }
  return (
    <Layout>
      {getCategoryData.loading ? (
        <Spinner />
      ) : (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={onSubmit}
            className="relative bg-white rounded-lg shadow dark:bg-gray-800"
          >
            {/*Modal Header*/}
            <div className="flex justify-between items-center py-4 px-4 rounded-t sm:px-5">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {getCategoryData.data?.slug ? "Edit" : "Add"} Category
              </h3>
            </div>
            <div id="accordion-collapse" data-accordion="collapse">
              <div
                id="accordion-collapse-body-1"
                className=""
                aria-labelledby="accordion-collapse-heading-1"
              >
                <div className="p-4 border-gray-200 sm:p-5 dark:border-gray-700">
                  {/*Inputs*/}
                  <div className="grid gap-4 sm:grid-cols-2">
                    <Input
                      name="name"
                      onChange={onChange}
                      id="name"
                      label="Category Name"
                      value={form.name}
                      error={error?.name || error?.error}
                      required
                    />

                    {form.type !== "main" && (
                      <CategorySelector
                        value={
                          form.parent &&
                          data.find((it) => it.id === form.parent)?.name
                        }
                        onResultClick={(result) => {
                          onChange({
                            target: {
                              name: "parent",
                              value: result.id,
                            },
                          } as ChangeEvent<HTMLInputElement>);
                        }}
                        label="Parent"
                        onFocus={() => {
                          onChange({
                            target: {
                              name: "parent",
                              value: undefined,
                            },
                          } as unknown as ChangeEvent<HTMLInputElement>);
                        }}
                        required={false}
                      />
                    )}

                    <Input
                      name="description"
                      label="H1 Headline"
                      id="description"
                      value={form.description}
                      onChange={onChange}
                    />
                    <Input
                      name="description2"
                      label="H2 Headline"
                      id="description2"
                      value={form.description2}
                      onChange={onChange}
                    />

                    <Input
                      label="Slug"
                      name="slug"
                      error={error?.slug}
                      id="slug"
                      value={form.slug}
                      onChange={onChange}
                    />

                    <Dropdown
                      options={[
                        "Main",
                        "Seasonal",
                        "Homepage",
                        "Sub",
                        "Sub II",
                      ].map((el) => ({
                        label: el,
                        value: el.toLowerCase(),
                      }))}
                      name="type"
                      label="Type"
                      value={form.type}
                      onChange={onChange}
                      wrapperClassName="mb-4"
                    />

                    <Switch
                      name="is_active"
                      checked={form.is_active}
                      onChange={onChange}
                      label="Status"
                      text="Active"
                    />
                  </div>

                  <Label label="Assets" />

                  <div className="flex  items-center w-full gap-4">
                    <ImageDropzone
                      label={
                        files?.icon_image_url?.file?.name || "Icon • 24 x 24"
                      }
                      url={
                        files?.icon_image_url?.temp_url || form.icon_image_url
                      }
                      onFilesDropped={(files) =>
                        onFileSelected({ name: "icon_image_url", files })
                      }
                      // helperText="PNG, JPG (MAX. 24px 24px)"
                      wrapperClassName="w-48 h-48 "
                      helperText={errors.icon_image_url}
                      helperTextStyle="text-red-500"
                    />
                    <ImageDropzone
                      url={
                        files?.thumb_image_url?.temp_url || form.thumb_image_url
                      }
                      label={
                        files?.thumb_image_url?.file?.name ||
                        "Thumb • 235 x 114"
                      }
                      onFilesDropped={(files) =>
                        onFileSelected({ name: "thumb_image_url", files })
                      }
                      //   helperText="PNG, JPG (MAX. 235px 114px)"
                      wrapperClassName="w-48 h-48 "
                      helperText={errors.thumb_image_url}
                      helperTextStyle="text-red-500"
                    />

                    <ImageDropzone
                      label={
                        files?.hero_image_url?.file?.name || "Hero • 1044 x 696"
                      }
                      onFilesDropped={(files) =>
                        onFileSelected({ name: "hero_image_url", files })
                      }
                      url={
                        files?.hero_image_url?.temp_url || form.hero_image_url
                      }
                      wrapperClassName="w-48 h-48 "
                      // helperText="PNG, JPG (MAX. 1044px 696px)"
                      helperText={errors.hero_image_url}
                      helperTextStyle="text-red-500"
                    />

                    <ImageDropzone
                      label={
                        files?.long_form_image_url?.file?.name ||
                        "Longform • 380 x 220"
                      }
                      onFilesDropped={(files) =>
                        onFileSelected({ name: "long_form_image_url", files })
                      }
                      url={
                        files?.long_form_image_url?.temp_url ||
                        form.long_form_image_url
                      }
                      wrapperClassName="w-48 h-48 "
                      helperText={errors.long_form_image_url}
                      helperTextStyle="text-red-500"

                      // helperText="PNG, JPG (MAX. 380px 220px)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center py-4 px-4 space-x-4 sm:py-5 sm:px-5">
              <button
                type="submit"
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {createUpdateCategoryLoading ? "Please wait..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
}
