import ignoreLinks from "redux/slices/links/ignoreLink";
import getAllLinks from "redux/slices/links/getAllLinks";
import getLink from "redux/slices/links/getLink";
import getAllLinksByProgram from "redux/slices/links/getAllLinksByProgram";

const linkReducers = {
  getAllLinks,
  getLink,
  getAllLinksByProgram,
  ignoreLinks,
};

export default linkReducers;
