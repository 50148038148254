import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import loopUpAdvertisers from "redux/thunks/advertisers/loopUpAdvertisers";
import IgnoreAdvertiser from "redux/thunks/advertisers/IgnoreAdvertiser";
import { PaginatedData } from "types/data";
import { Advertiser } from "types/model/Advertiser";

export type ActionStateType = {
  data: PaginatedData<Advertiser>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const loopUpAdvertisersSlice = createSlice({
  name: "loopUpAdvertisers",
  initialState,
  reducers: {
    resetLookUpAdvertisers: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loopUpAdvertisers.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        loopUpAdvertisers.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )

      .addCase(
        IgnoreAdvertiser.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                items: state.data.items.filter(
                  (it) => !payload.ids.includes(String(it.id)),
                ),
              },
              loading: false,
            };
          }
        },
      )

      .addCase(
        loopUpAdvertisers.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});
export const { resetLookUpAdvertisers } = loopUpAdvertisersSlice.actions;
export default loopUpAdvertisersSlice.reducer;
