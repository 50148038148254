import { useNavigate } from "react-router-dom";
import TabLayout from "components/common/TabLayout";
import SEMReport from "containers/marketing/campaign-reporting/SEMReport";
import PausedCampaigns from "containers/marketing/campaign-reporting/PausedCampaigns";
import { MARKETING_APP } from "constants/smaAppNames";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import TodoItems from "containers/todos/TodoItems";
import {
  SEARCH_TAKE_DOWN,
  SEND_PERF_REVIEW_REPORT,
  SEND_SEARCH_PR_KEYWORDS,
} from "constants/todoTypes";
import { useAppSelector } from "hooks/useRedux";
import ProposalEvaluation from "containers/marketing/search/ProposalEvaluation";
import CompetitorWebsitePrograms from "containers/marketing/search/CompetitorWebsitePrograms";

function SEMTabs() {
  const navigate = useNavigate();
  const { user } = useLoadAuth();
  const hasAppAccess = user?.user_apps
    ?.map((it) => it.name.toLowerCase())
    ?.includes(MARKETING_APP.toLowerCase());

  const hasPermissions =
    user?.user_role == USER_ROLE_ADMIN || hasAppAccess || false;

  const {
    data: { items: pendingTodos },
  } = useAppSelector((state) => state.getTodoStats);

  const pendingSearchRequests = pendingTodos.filter(
    (el) => el.todo_type?.id === SEND_SEARCH_PR_KEYWORDS
  );

  const pendingReportRequests = pendingTodos.filter(
    (el) => el.todo_type?.id === SEND_PERF_REVIEW_REPORT
  );

  const pendingTakedownRequests = pendingTodos.filter(
    (el) => el.todo_type?.id === SEARCH_TAKE_DOWN
  );

  const options = [
    {
      text: "Search Requests",
      label: pendingSearchRequests.length,
      onClick: () => {
        navigate("/marketing/search/?tab=1");
      },

      content: <TodoItems todoType={SEND_SEARCH_PR_KEYWORDS} />,
    },
    {
      text: "Report Requests",
      label: pendingReportRequests.length,
      onClick: () => {
        navigate("/marketing/search/?tab=2");
      },

      content: <TodoItems todoType={SEND_PERF_REVIEW_REPORT} />,
    },
    {
      text: "Take down Requests",
      label: pendingTakedownRequests.length,
      onClick: () => {
        navigate("/marketing/search/?tab=3");
      },

      content: <TodoItems todoType={SEARCH_TAKE_DOWN} />,
    },

    {
      text: "Proposal Valuation",
      onClick: () => {
        navigate("/marketing/search/?tab=4");
      },

      content: <ProposalEvaluation />,
    },
    {
      text: "Campaign Report",
      onClick: () => {
        navigate("/marketing/search/?tab=5");
      },

      content: <SEMReport />,
    },

    {
      text: "Auto Paused Campaigns",
      onClick: () => {
        navigate("/marketing/search/?tab=6");
      },
      content: <PausedCampaigns />,
    },
    {
      text: "Competitor Website Programs",
      onClick: () => {
        navigate("/marketing/search/?tab=7");
      },
      content: <CompetitorWebsitePrograms />,
    },
  ];

  return <TabLayout hasPermissions={hasPermissions} options={options} />;
}

export default SEMTabs;
