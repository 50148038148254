import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ProgramCategory, TopProgramCategory } from "types/model/Category";
import FeaturedSubCategoryLayout from "layout/oma/campaigns/FeaturedSubCategoryLayout";
import { Store } from "types/model/oma/Store";
import updateTopCategory from "redux/thunks/app/placements/campaigns/updateTopCategory";
import { clearSaveTopCategories } from "redux/slices/clients/placements/campaigns/saveTopCategories";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";

type Props = {
  topCategory: TopProgramCategory;
};

const FeaturedSubCategory = ({ topCategory }: Props) => {
  const dispatch = useAppDispatch();
  const {
    getCategoryStoreSpotlights: getCategoryStoreSpotlightsData,
    saveTopCategories: saveTopCategoriesData,
    getCategories: getCategoriesData,
  } = useAppSelector(
    ({
      getClients,
      getCategories,
      getCategoryStoreSpotlights,
      saveTopCategories,
    }) => ({
      getClients,
      getCategories,
      getCategoryStoreSpotlights,
      saveTopCategories,
    })
  );

  const [selectedTopCategory, setSelectedTopCategory] =
    useState<TopProgramCategory>(topCategory);

  const [subCategories, setSubCategories] = useState<ProgramCategory[]>([]);
  const [featuredSubs, setFeaturedSubs] = useState<ProgramCategory[]>(
    selectedTopCategory.featured_subs
  );

  useEffect(() => {
    setSelectedTopCategory(topCategory);
  }, [getCategoryStoreSpotlightsData.data]);

  useEffect(() => {
    if (saveTopCategoriesData.data.saved) {
      dispatch(clearSaveTopCategories());
    }
  }, [saveTopCategoriesData.data.saved]);

  const removeSubFromCategory = (category: ProgramCategory) => {
    setSelectedTopCategory((topCategory) => ({
      ...topCategory,
      featured_subs: topCategory.featured_subs.filter(
        (it) => it.id != category.id
      ),
    }));
    setFeaturedSubs((items) => items.filter((el) => el.id !== category.id));
    setSubCategories((items) => [category, ...items]);
  };

  const addCategoryToSub = (category: ProgramCategory) => {
    setFeaturedSubs((subs) => [category, ...subs]);
    setSubCategories((subCategories) =>
      subCategories.filter((it) => it.id != category.id)
    );
  };

  const handlePersistUpdates = () => {
    dispatch(
      updateTopCategory({
        ...selectedTopCategory,
        category: selectedTopCategory.category.id,
        featured_stores: selectedTopCategory.featured_stores.map((el) => el.id),
        featured_subs: featuredSubs.map((el) => el.id),
      })
    );
  };

  useEffect(() => {
    if (getCategoriesData.data.length && selectedTopCategory) {
      const subCategories = getCategoriesData.data.filter(
        (it) =>
          it.parent && Number(it.parent) === selectedTopCategory.category.id
      );

      setSubCategories(
        subCategories.filter(
          (el) =>
            !selectedTopCategory.featured_subs
              .map((it) => it.id)
              .includes(el.id)
        )
      );
    } else {
      dispatch(getCategories({}));
    }
  }, [getCategoriesData.data.length, selectedTopCategory]);

  return (
    <FeaturedSubCategoryLayout
      handlePersistUpdates={handlePersistUpdates}
      topCategory={selectedTopCategory}
      removeSubFromCategory={removeSubFromCategory}
      addCategoryToSub={addCategoryToSub}
      saving={saveTopCategoriesData.loading}
      subCategories={subCategories}
      featuredSubs={featuredSubs}
    />
  );
};

export default FeaturedSubCategory;
