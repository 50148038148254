import TextArea from "components/common/TextArea";
import { ChangeEventHandler } from "react";

type Props = {
  content: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const Content = ({ onChange, content }: Props) => {
  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      <TextArea
        label="Short form"
        onChange={onChange}
        value={content.short_form}
        name={"short_form"}
      >
        {content.short_form}
      </TextArea>
      <TextArea
        label="Long form"
        value={content.long_form}
        name={"long_form"}
        wrapperClassName="my-2"
        onChange={onChange}
      >
        {content.long_form}
      </TextArea>
    </section>
  );
};

export default Content;
