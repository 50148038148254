import dateFormat from "dateformat";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  row: any;
  onReviewClicked: (row: any) => void;
};

const EmailCampaignRow = ({ row, onReviewClicked }: Props) => {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.campaign_info?.created_at
          ? dateFormat(new Date(row.campaign_info.created_at), "dd/mm/yyyy")
          : ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.campaign_info?.campaign_type.toUpperCase()}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.campaign_info?.client?.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {formatNumber(parseFloat(row.commission_value_usd) / 1000, {
          decimals: 2,
          prefix: "$",
        })}
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row?.campaign_info?.campaign_location?.toUpperCase()}
      </td> */}

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.total_receivers}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.opens}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.pending_email_opens}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.open_rate}%
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.clicks}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.pending_email_clicks}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.clicks_rate}%
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.unsubs}
      </td>

      <td>
        <button
          onClick={() => {
            onReviewClicked(row);
          }}
        >
          Review
        </button>
      </td>
    </tr>
  );
};

export default EmailCampaignRow;
