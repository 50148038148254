import { twMerge } from "tailwind-merge";

type Props = {
  name?: string;
  label?: string;
  rightLabel?: JSX.Element | null;
  wrapperClassName?: string;
  className?: string;
  onClick?: () => void;
};

const Label = ({
  rightLabel,
  label,
  name,
  wrapperClassName,
  className,
  onClick,
}: Props) => {
  return (
    <div
      className={twMerge("flex justify-between items-center", wrapperClassName)}
      onClick={onClick}
    >
      <label
        htmlFor={name}
        className={twMerge(
          "block mb-2 text-sm font-medium text-gray-900 dark:text-white",
          className
        )}
      >
        {label}
      </label>
      {rightLabel}
    </div>
  );
};

export default Label;
