import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCampaignSettings from "redux/thunks/marketing/emails/getCampaignSettings";
import { PaginatedData } from "types/data";
import { EmailCampaignSetting } from "types/model/NewsletterSubscriber";

export type ActionStateType = {
  data: PaginatedData<EmailCampaignSetting>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getCampaignSettingsSlice = createSlice({
  name: "getCampaignSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignSettings.pending, (state) => ({
        error: null,
        data: initialState.data,
        loading: true,
      }))
      .addCase(
        getCampaignSettings.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getCampaignSettings.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getCampaignSettingsSlice.reducer;
