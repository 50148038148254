import Button from "components/common/Button";
import StoreCard from "components/common/StoreCard";
import SearchPrograms from "containers/oma/SearchPrograms";
import { TopProgramCategory } from "types/model/Category";
import { Store } from "types/model/oma/Store";

type Props = {
  topCategory: TopProgramCategory;
  removeStoreFromCategory: (store: Store) => void;
  addStoreToCategory: (store: Store) => void;
  handlePersistUpdates: () => void;
  saving: boolean;
};

export default function TopStoresPerCategoryLayout({
  topCategory,
  removeStoreFromCategory,
  addStoreToCategory,
  saving,
  handlePersistUpdates,
}: Props) {
  return (
    <div className="flex justify-between">
      <div className="my-2 h-[500px] overflow-scroll">
        <SearchPrograms
          label={`Search Store to add to ${topCategory.category.name}`}
          wrapperClassName="w-48 mx-0"
          searchParams={{}}
          onResultClicked={(result: Store) => {
            addStoreToCategory(result);
          }}
        />
      </div>

      <div className="flex flex-col items-end">
        <Button
          text="Save changes"
          loading={saving}
          className="bg-indigo-600 text-white"
          onClick={handlePersistUpdates}
        />

        <div className="h-[500px] overflow-scroll">
          {topCategory.featured_stores.map((el) => (
            <StoreCard
              store={el}
              key={el.id}
              showDelete
              onStoreClicked={() => removeStoreFromCategory(el)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
