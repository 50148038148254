import { Dispatch, Fragment, SetStateAction } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import TextArea from "components/common/TextArea";
import Button from "components/common/Button";
import { ActionState } from "types/data";

type Props = {
  navigation: Record<string, any>[];
  handleOpenDialog: (it: Record<string, any>) => void;
  open: boolean;
  handleSubmit: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setRequestDetails: Dispatch<SetStateAction<Record<string, any>>>;
  requestDetails: Record<string, any>;
  createTodoState: ActionState;
};

export default function QuickAccess({
  navigation,
  handleOpenDialog,
  setOpen,
  handleSubmit,
  open,
  requestDetails,
  setRequestDetails,
  createTodoState,
}: Props) {
  return (
    <nav className="flex flex-1 flex-col" aria-label="Sidebar">
      <div className="px-2 py-3">
        <h2 className="text-lg font-bold text-gray-900">Quick Access</h2>
      </div>
      <ul role="list" className="flex flex-1 flex-col px-2 py-3">
        {navigation.map((item) => (
          <li key={item.name} onClick={() => handleOpenDialog(item)}>
            <a
              href="#"
              className="group flex justify-between items-center rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
            >
              <div className="flex items-center gap-x-3">
                <item.icon
                  className="h-6 w-6 text-gray-400 group-hover:text-indigo-600"
                  aria-hidden="true"
                />
                {item.name}
              </div>
              <ChevronRightIcon
                className="h-6 w-6 text-gray-400 group-hover:text-indigo-600"
                aria-hidden="true"
              />
            </a>
          </li>
        ))}
      </ul>

      {/* Confirmation Dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {requestDetails.name}
                      </Dialog.Title>
                      <div className="mt-2">
                        <TextArea
                          placeholder="Enter Notes"
                          rows={7}
                          error={createTodoState.error?.notes}
                          onChange={(e) => {
                            setRequestDetails((all) => ({
                              ...all,
                              notes: e.target.value,
                            }));
                          }}
                          wrapperClassName="w-full my-4"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mx-10 my-4">
                    <Button
                      loading={createTodoState.loading}
                      type="button"
                      text="Submit"
                      className="w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleSubmit}
                    />
                    <button
                      type="button"
                      className="mt-3 w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </nav>
  );
}
