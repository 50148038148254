import {
  PROGRAM_TAB_PENDING_API_COUPONS,
  PROGRAM_TAB_VIEW_OFFERS,
  PROGRAM_TAB_PENDING_NEWSLETTER,
  PROGRAM_TAB_SORT_COUPONS,
  PROGRAM_TAB_ACTIVATE_COUPON,
} from "constants/programTabKeys";
import { useAppSelector } from "hooks/useRedux";
import { ReactElement } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  content: Record<string, ReactElement>;
  onAddCouponClicked: () => void;
};

const LayoutTabs = ({ content, onAddCouponClicked }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { data, loading } = useAppSelector((state) => state.getActiveCoupons);

  const totalUnsorted = data.results.filter((it: Record<string, any>) => {
    return (
      typeof it.sorting_for_store !== "string" ||
      it.sorting_for_store === "1000"
    );
  });

  const sortCouponsClicked = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("tab", PROGRAM_TAB_SORT_COUPONS);
    navigate(url.search, {
      state: location.state,
    });
  };

  const offersClicked = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("tab", PROGRAM_TAB_VIEW_OFFERS);
    navigate(url.search, {
      state: location.state,
    });
  };

  const apiCouponsClicked = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("tab", PROGRAM_TAB_PENDING_API_COUPONS);
    navigate(url.search, {
      state: location.state,
    });
  };

  const newsLetterFeedClicked = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("tab", PROGRAM_TAB_PENDING_NEWSLETTER);
    navigate(url.search, {
      state: location.state,
    });
  };

  const renderContent = () => {
    const tab = params.get("tab");
    if (!tab) {
      return content[PROGRAM_TAB_PENDING_API_COUPONS];
    }
    return content[tab];
  };

  return (
    <>
      <div className="block w-full border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 ">
        <div className=" sm:block">
          <nav className="isolate flex shadow">
            {content[PROGRAM_TAB_PENDING_API_COUPONS] && (
              <button
                onClick={apiCouponsClicked}
                className="text-gray-900   group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              >
                <span>Pending API Coupons </span>
                <span
                  className={twMerge(
                    "absolute inset-x-0 bottom-0 h-1",
                    (params.get("tab") === PROGRAM_TAB_PENDING_API_COUPONS ||
                      !params.has("tab")) &&
                      "bg-indigo-500"
                  )}
                />
              </button>
            )}
            {content[PROGRAM_TAB_PENDING_NEWSLETTER] && (
              <button
                onClick={newsLetterFeedClicked}
                className="text-gray-900   group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              >
                <span>Newsletter Feed </span>
                <span
                  className={twMerge(
                    "absolute inset-x-0 bottom-0 h-1",
                    (params.get("tab") === PROGRAM_TAB_PENDING_NEWSLETTER ||
                      !params.has("tab")) &&
                      "bg-indigo-500"
                  )}
                />
              </button>
            )}
            {content[PROGRAM_TAB_ACTIVATE_COUPON] && (
              <button
                onClick={onAddCouponClicked}
                className="text-gray-900   group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              >
                {/*if selected tab; use this blue underline classname*/}
                {/*absolute inset-x-0 bottom-0 h-0.5*/}

                <span>Add Coupon</span>
                <span
                  className={twMerge(
                    "absolute inset-x-0 bottom-0 h-1",
                    params.get("tab") !== PROGRAM_TAB_SORT_COUPONS &&
                      params.get("tab") !== PROGRAM_TAB_VIEW_OFFERS &&
                      params.get("tab") !== PROGRAM_TAB_PENDING_API_COUPONS &&
                      params.get("tab") !== PROGRAM_TAB_PENDING_NEWSLETTER &&
                      "bg-indigo-500"
                  )}
                />
              </button>
            )}

            {content[PROGRAM_TAB_SORT_COUPONS] && (
              <button
                onClick={sortCouponsClicked}
                className=" text-gray-900 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10  "
              >
                {totalUnsorted.length > 0 && (
                  <small className="w-5 h-5 bg-red-500 text-white rounded-full p-2 items-center flex justify-center text-xs absolute right-20">
                    {!loading ? totalUnsorted.length : ""}
                  </small>
                )}
                <span>Sort </span>
                <span
                  aria-hidden="true"
                  className={twMerge(
                    "absolute inset-x-0 bottom-0 h-1",
                    params.get("tab") === PROGRAM_TAB_SORT_COUPONS &&
                      "bg-indigo-500"
                  )}
                />
              </button>
            )}

            {content[PROGRAM_TAB_VIEW_OFFERS] && (
              <button
                onClick={offersClicked}
                className=" text-gray-900 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 rounded-r-lg"
              >
                <span>View Offers</span>
                <span aria-hidden="true" className="bg-indigo-500" />

                <span
                  className={twMerge(
                    "absolute inset-x-0 bottom-0 h-1",
                    params.get("tab") === PROGRAM_TAB_VIEW_OFFERS &&
                      "bg-indigo-500"
                  )}
                />
              </button>
            )}
          </nav>
        </div>
      </div>

      {renderContent()}
    </>
  );
};

export default LayoutTabs;
