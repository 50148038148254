import { ChangeEventHandler, FocusEventHandler } from "react";
import SearchResults from "./SearchResults";
import { twMerge } from "tailwind-merge";
import Input from "components/common/Input";
import OutsideClickHandler from "react-outside-click-handler";
import Spinner from "components/common/layout/Spinner";
import { Store } from "types/model/oma/Store";
import { ProgramCategory } from "types/model/Category";
import { Advertiser } from "types/model/Advertiser";

type Props<T> = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSearchSubmit: () => void;
  results: T[];
  onResultClick: (res: Store) => void;
  onResultsOutsideClick: () => void;
  label?: string;
  value?: string;
  error?: string;
  placeholder?: string;
  wrapperClass?: string;
  searchLoading?: boolean;
  submitButtonActive?: boolean;
  rightLabel?: JSX.Element;
  defaultFocus?: boolean;
  renderLocation?: boolean;
  renderResults?: (results: Array<Record<string, any>>) => void;
  required?: boolean;
  onFocus?: FocusEventHandler;
  renderNetwork?: boolean;
  inputClassName?: string;
  inputContainerClass?: string;
  renderClientName?: boolean;
};

const SearchBar = ({
  onChange,
  onSearchSubmit,
  results,
  onResultClick,
  onResultsOutsideClick,
  label,
  placeholder,
  error,
  rightLabel,
  wrapperClass,
  renderNetwork,
  defaultFocus,
  searchLoading,
  submitButtonActive = false,
  renderLocation,
  value,
  renderResults,
  required,
  onFocus,
  inputClassName,
  inputContainerClass,
  renderClientName,
}: Props<Store | ProgramCategory | Advertiser | Record<string, any>>) => {
  const wrapperCls = twMerge("min-w-[168px]", wrapperClass);

  return (
    <OutsideClickHandler onOutsideClick={onResultsOutsideClick}>
      <div className={wrapperCls}>
        <div className="relative">
          <Input
            error={error}
            leftIcon={
              <svg
                aria-hidden="true"
                className="w-4 h-4 text-gray-500 dark:text-gray-400 "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            }
            id="default-search"
            label={label}
            rightLabel={rightLabel}
            onChange={onChange}
            defaultFocus={defaultFocus}
            required={required}
            onFocus={onFocus}
            className={inputClassName}
            inputContainerClass={inputContainerClass}
            value={value}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearchSubmit?.();
              }
            }}
            placeholder={placeholder || "Search Program"}
            rightIcon={searchLoading ? <Spinner className="h-4 w-4" /> : null}
          />
          {submitButtonActive && (
            <button
              type="submit"
              className="text-white absolute right-0 bottom-0 top-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-r-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Search
            </button>
          )}
        </div>
        <>
          {typeof renderResults === "function" && results.length > 0 ? (
            renderResults(results)
          ) : (
            <SearchResults
              onResultsOutsideClick={onResultsOutsideClick}
              onResultClick={onResultClick}
              results={results as Store[]}
              renderNetwork={renderNetwork}
              renderLocation={renderLocation}
              renderClientName={renderClientName}
            />
          )}
        </>
      </div>
    </OutsideClickHandler>
  );
};

export default SearchBar;
