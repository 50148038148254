import React from "react";
import TableColumnHeader from "./TableColumnHeader";
import { Column } from "types/column";
import components from "..";

type Props = {
  columns: Column[];
  sortTable?: (col: Column) => void;
  columnProps?: Record<string, any>;
};

const Thead = ({ columns, columnProps, sortTable }: Props) => {
  return (
    <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 p-4">
      <tr>
        {columns?.map((column, idx) => {
          if (column.component) {
            return (
              <th scope="col" className="px-4 py-3 " key={column.key + idx}>
                {React.createElement(components[column.component], {
                  ...columnProps?.[column.key],
                })}
              </th>
            );
          } else {
            return (
              <TableColumnHeader
                sortTable={sortTable}
                key={column.key + idx}
                column={column}
              />
            );
          }
        })}
      </tr>
    </thead>
  );
};

export default Thead;
