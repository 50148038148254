import { get, truncate } from "lodash";
import React from "react";
import { twMerge } from "tailwind-merge";
import formatNumber from "utils/strings/formatNumber";
import { Column } from "types/column";
import { Link } from "react-router-dom";
import TotalSaleAmounts from "./TotalSaleAmounts";
import TotalCommissionAmounts from "./TotalCommissionAmounts";

type Props = {
  item: Record<string, any>;
  index: number;
  totals: Record<string, any>;
  visibleColumns: Column[];
  additionalColumns: Column[];
};

function TransactionSummaryReportRow({
  item,
  index,
  totals,
  additionalColumns,
}: Props) {
  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 cursor-pointer",
    index === 0 && "bg-green-100 font-bold"
  );

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          <Link to={item.network_advertiser?.website || "#"} target="_blank">
            {truncate(item.network_advertiser?.name, { length: 24 })}
          </Link>
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.client?.name}
        </td>

        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.network_advertiser?.network}
        </th>

        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {index == 0
            ? formatNumber(totals.total_sale_transactions, {})
            : formatNumber(item.total_sale_transactions, {})}
        </th>
        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {index === 0 ? (
            <TotalSaleAmounts totals={totals} />
          ) : (
            <TotalSaleAmounts totals={item} />
          )}
        </th>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals?.total_sale_amount_usd / 1000, {
                decimals: 2,
                prefix: "$",
              })
            : formatNumber(item?.total_sale_amount / 1000, {
                decimals: 2,
                prefix: "$",
              })}
        </td>
        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {index == 0
            ? formatNumber(totals?.returned_transactions_count, {
                decimals: 0,
                prefix: "",
              })
            : formatNumber(item?.returned_transactions_count, {
                decimals: 0,
                prefix: "",
              })}
        </th>

        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {index == 0
            ? formatNumber(totals?.total_returns_sale_amount / 1000, {
                decimals: 2,
                prefix: "$",
              })
            : formatNumber(item?.total_returns_sale_amount / 1000, {
                decimals: 2,
                prefix: "$",
              })}
        </th>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals.total_returns_commission_amount / 1000, {
                decimals: 2,
                prefix: "$",
              })
            : formatNumber(item?.total_returns_commission_amount / 1000, {
                decimals: 2,
                prefix: "$",
              })}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(
                totals.total_sale_amount_usd /
                  totals.total_sale_transactions /
                  1000,
                {
                  decimals: 2,
                  prefix: "$",
                }
              )
            : formatNumber(
                item.total_sale_amount / item.total_sale_transactions / 1000,
                {
                  decimals: 2,
                  prefix: "$",
                }
              )}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0 ? (
            <TotalCommissionAmounts totals={totals} />
          ) : (
            <TotalCommissionAmounts totals={item} />
          )}
        </td>

        <td className="px-4 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals.total_commission_usd / 1000, {
                decimals: 2,
                prefix: "$",
              })
            : formatNumber(item?.total_commission_amount / 1000, {
                decimals: 2,
                prefix: "$",
              })}
        </td>
        {additionalColumns.map((col, index) => (
          <td
            key={`additional-data-${index}`}
            className={twMerge(
              "px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs",
              col.key == "client_location" && "uppercase"
            )}
          >
            {get(item, col.key)}
          </td>
        ))}
      </tr>
    </React.Fragment>
  );
}

export default TransactionSummaryReportRow;
