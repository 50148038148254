import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import BrowserExtNetworkAutoOpenLayout from "layout/marketing/extension/BrowserExtNetworkAutoOpenLayout";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import updateNetworkExtSettings from "redux/thunks/extension/updateNetworkExtSettings";
import createNetworkExtSetting from "redux/thunks/extension/createNetworkExtSetting";
import getNetworkExtSettings from "redux/thunks/extension/getNetworkExtSettings";
import { AffiliateNetwork } from "types/model/AffiliateNetwork";
import { ExcludedNetwork } from "types/model/ExcludedNetwork";

const BrowserExtNetworkAutoOpen = () => {
  const [initialSearchTxt, setInitialSearchTxt] = useState("");
  const dispatch = useAppDispatch();

  const {
    getNetworkExtSettings: getExcludedNetworksData,
    createNetworkExtSetting: excludeNetworkData,
    updateNetworkExtSettings: deleteExcludedNetworkData,
    affiliateNetworks,
  } = useAppSelector(
    ({
      getNetworkExtSettings,
      createNetworkExtSetting,
      updateNetworkExtSettings,
      affiliateNetworks,
    }) => ({
      getNetworkExtSettings,
      createNetworkExtSetting,
      updateNetworkExtSettings,
      affiliateNetworks,
    })
  );

  useEffect(() => {
    dispatch(getNetworkExtSettings({ auto_open: "1" }));
  }, []);

  const handleAddExclusion = (network: AffiliateNetwork) => {
    dispatch(clearProgramSearchResults());
    dispatch(
      createNetworkExtSetting({
        network,
        auto_open: true,
        excluded: null,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
        setInitialSearchTxt("   ");
        setTimeout(() => {
          setInitialSearchTxt("");
        }, 10);
      }
    });
  };

  const handleDeleteExclusion = (createNetworkExtSetting: ExcludedNetwork) => {
    dispatch(updateNetworkExtSettings({ ...createNetworkExtSetting })).then(
      (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Changes saved");
        } else {
          toast.error("Something went wrong");
        }
      }
    );
  };

  return (
    <BrowserExtNetworkAutoOpenLayout
      getExcludedNetworksData={getExcludedNetworksData}
      handleAddExclusion={handleAddExclusion}
      handleDeleteExclusion={handleDeleteExclusion}
      excludeNetworkData={excludeNetworkData}
      deleteExcludedNetworkData={deleteExcludedNetworkData}
      initialSearchTxt={initialSearchTxt}
      affiliateNetworks={affiliateNetworks}
    />
  );
};

export default BrowserExtNetworkAutoOpen;
