import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { toast } from "react-hot-toast";

export default createAsyncThunk(
  "sem/update-campaign-ad-group",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const url =
        payload.action === "update-ad"
          ? `/admin/sem/ads?ad_group_id=${payload.ad_group_id}&search_engine=${
              payload.search_engine
            }&resource=${payload.resource || "ads"}`
          : `/admin/sem/ad-campaign`;
      const response = await axios.patch(url, payload);
      toast.success("Changes saved");

      // ThunkAPI.dispatch(getCampaigns({ program_slug: payload.program_slug }));
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
