import SearchBar from "components/program/SearchBar";
import OutsideClickHandler from "react-outside-click-handler";
import LocationSuggestionItem from "./LocationSuggestionItem";
import classNames from "classnames";
import { ChangeEventHandler } from "react";
import { Store } from "types/model/oma/Store";

type Props = {
  searchResults: Array<Record<string, any>>;
  form: Record<string, any>;
  errors: Record<string, any>;
  resetResults: () => void;
  onResultClick: (formKey: string, it: Record<string, any>) => void;
  updateResultLocationMode: (
    formKey: string,
    it: Record<string, any>,
    mode: "include" | "exclude"
  ) => void;

  removeSelectedLocation: (formKey: string, it: Record<string, any>) => void;
  onSearchSubmit: (formKey: string) => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  searchLoading: boolean;
  label?: string;
  formKey: string;
};

const AddLocationsForm = ({
  searchResults,
  form,
  resetResults,
  onResultClick,
  updateResultLocationMode,
  removeSelectedLocation,
  onChange,
  searchLoading,
  onSearchSubmit,
  errors,
  formKey,
  label = "Search locations",
}: Props) => {
  return (
    <div>
      <SearchBar
        results={searchResults || []}
        label={label}
        error={errors.locations?.[formKey]}
        placeholder="Search locations"
        renderResults={(results: any[]) => (
          <OutsideClickHandler onOutsideClick={resetResults}>
            <ul className="absolute z-10 mt-1 max-h-60  w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {results.map((result) => (
                <LocationSuggestionItem
                  onResultClick={() => onResultClick(formKey, result)}
                  result={result}
                  key={result.name}
                />
              ))}
            </ul>
          </OutsideClickHandler>
        )}
        onChange={(e: any) => {
          onChange({
            ...e,
            target: {
              ...e.target,
              name: `${formKey}SearchText`,
              value: e.target.value,
            },
          });
        }}
        onResultClick={() => {
          //
        }}
        onSearchSubmit={() => {
          onSearchSubmit(formKey);
        }}
        searchLoading={searchLoading}
        onResultsOutsideClick={function (): void {
          //
        }}
      />
      {form.locations?.[formKey]?.length > 0 && (
        <ul className=" mt-1 rounded-md  text-base  sm:text-sm ">
          {form.locations[formKey]?.map((result: Record<string, any>) => (
            <div
              key={result.location.id + formKey}
              className="my-2 pr-2 bg-primary-100"
            >
              <div className="flex justify-between">
                <div
                  className={classNames(
                    "relative cursor-pointer select-none py-2 pl-3"
                  )}
                >
                  <span className={classNames("block ")}>
                    {result.location.name}{" "}
                    <small>{result.location.country}</small>
                  </span>

                  {<small>{result.location.type}</small>}
                </div>

                <button
                  onClick={() => {
                    removeSelectedLocation(formKey, result);
                  }}
                >
                  X
                </button>
              </div>

              <form action="">
                <fieldset className="flex items-center pb-4">
                  <div className="flex items-center mr-4 mt-3">
                    <input
                      id="completed"
                      type="radio"
                      checked={result.mode === "include"}
                      name="show-only"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => {
                        updateResultLocationMode(formKey, result, "include");
                      }}
                    />
                    <label
                      htmlFor="completed"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Include
                    </label>
                  </div>
                  <div className="flex items-center mr-4 mt-3">
                    <input
                      id="in-progress"
                      type="radio"
                      value=""
                      checked={result.mode === "exclude"}
                      name="show-only"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => {
                        updateResultLocationMode(formKey, result, "exclude");
                      }}
                    />
                    <label
                      htmlFor="in-progress"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Exclude
                    </label>
                  </div>
                </fieldset>
              </form>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddLocationsForm;
