import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCommissionChanges from "redux/thunks/advertisers/getCommissionChanges";
import { ActionState } from "types/data";

const initialState = {
  data: [],
  loading: false,
  error: null,
} as ActionState<Record<string, any>[]>;

const getCommissionChangesSlice = createSlice({
  name: "deactivatedAdvertisers",
  initialState,
  reducers: {
    resetCommissionRates: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommissionChanges.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getCommissionChanges.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )

      .addCase(
        getCommissionChanges.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});
export const { resetCommissionRates } = getCommissionChangesSlice.actions;
export default getCommissionChangesSlice.reducer;
