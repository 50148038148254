import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { toast } from "react-hot-toast";
import getPaidPlacements from "redux/thunks/app/placements/campaigns/getPaidPlacements";
import { setPaidPlacements } from "redux/slices/clients/placements/campaigns/getPaidPlacements";
import PaidPlacementsLayout from "layout/oma/campaigns/PaidPlacements";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import { clearSearchCouponResults } from "redux/slices/clients/coupons/searchActiveCoupons";
import updatePaidPlacement from "redux/thunks/app/placements/campaigns/updatePaidPlacement";
import checkPlacementPosAvailability from "redux/thunks/app/placements/campaigns/checkPlacementPosAvailability";
import addToPaidPlacements from "redux/thunks/app/placements/campaigns/addToPaidPlacements";
import dateformat from "dateformat";
import deletePaidPlacement from "redux/thunks/app/placements/campaigns/deletePaidPlacement";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import getPlacementTypes from "redux/thunks/app/placements/campaigns/getPlacementTypes";
import {
  PLACEMENT_DEST_CATEGORIES,
  PLACEMENT_DEST_HOME_PAGE,
} from "constants/placementDestinations";
import { PLACEMENT_TYPE_COUPON_LISTINGS } from "constants/placementTypeIds";
import getPlacementDestinations from "redux/thunks/app/placements/campaigns/getPlacementDestinations";

const PaidPlacements = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [addForm, setAddForm] = useState<Record<string, any>>({
    location: "US",
    placement_destination: PLACEMENT_DEST_HOME_PAGE,
  });

  const [categorySelectionActive, setCategorySelectionActive] = useState(false);

  const {
    addToPaidPlacements: addToPaidPlacementsData,
    deletePaidPlacement: deletePaidPlacementData,
    getPaidPlacements: getPaidPlacementsData,
    updatePaidPlacement: updatePaidPlacementData,
    checkPlacementPosAvailability: checkPlacementPosAvailabilityData,
    getPlacementTypes: getPlacementTypesData,
    getPlacementDestinations: getPlacementDestinationsData,
  } = useAppSelector(
    ({
      getClients,
      getPaidPlacements,
      getPlacementTypes,
      getPlacementDestinations,
      checkPlacementPosAvailability,
      updatePaidPlacement,
      deletePaidPlacement,
      addToPaidPlacements,
    }) => ({
      getClients,
      getPaidPlacements,
      addToPaidPlacements,
      updatePaidPlacement,
      deletePaidPlacement,
      checkPlacementPosAvailability,
      getPlacementTypes,
      getPlacementDestinations,
    })
  );

  const [sortedItems, setSortedItems] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    dispatch(getPlacementTypes({}));
    dispatch(getPlacementDestinations({}));
  }, []);

  useEffect(() => {
    if (Array.from(params).length) {
      if (
        params.get("placement_destination") ===
          String(PLACEMENT_DEST_CATEGORIES) &&
        !params.get("category")
      ) {
        return;
      }
      dispatch(
        getPaidPlacements({
          paid: "1",
          location: params.get("location") || "",
          placement_destination: params.get("placement_destination") || "",
          placement_type: params.get("placement_type") || "",
          status: params.get("status") || "",
          category: params.get("category") || "",
          client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
        })
      );
    }
  }, [
    params.get("location"),
    params.get("placement_destination"),
    params.get("placement_type"),
    params.get("status"),
    params.get("category"),
    params.get("client_id"),
  ]);

  useEffect(() => {
    const paramsForm = Array.from(params)
      .map(([k, v]) => {
        return { [k]: v };
      })
      .reduce((result, currentObj) => {
        for (const key in currentObj) {
          result[key] = currentObj[key];
        }
        return result;
      }, {});

    setAddForm((f) => ({ ...f, ...paramsForm }));
  }, [params]);

  const filterBy = (status: string) => {
    onChange({
      target: { name: "status", value: status },
    } as ChangeEvent<HTMLInputElement>);
  };

  const handleAddNewItem = () => {
    if (!addForm.store) {
      toast.error("Please choose a program");
      return;
    }

    if (!addForm.placement_destination) {
      toast.error("Please choose a placement destination");
      return;
    }

    if (!addForm.placement_type) {
      toast.error("Please choose a placement type");
      return;
    }
    if (
      addForm.placement_type !== PLACEMENT_TYPE_COUPON_LISTINGS &&
      !addForm.position
    ) {
      toast.error("Please choose a position");
      return;
    }

    if (checkPlacementPosAvailabilityData.data.exists) {
      toast.error("Position is already assigned");
      return;
    }

    const newItem = {
      from_date: dateformat(dayjs(new Date()).toDate(), "yyyy-mm-dd"),
      to_date: null,
      coupon: addForm.coupon,
      store: addForm.store.id,
      position: addForm.position || null,
      placement_destination: addForm.placement_destination,
      category: addForm.categoryId || null,
      placement_type: addForm.placement_type,
      slogan: "",
      location: addForm.location?.toLowerCase(),
      client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
    };

    dispatch(addToPaidPlacements(newItem)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Placement added successfully");
        dispatch(
          getPaidPlacements({
            location: params.get("location") || "",
            placement_destination: params.get("placement_destination") || "",
            placement_type: params.get("placement_type") || "",
            status: params.get("status") || "",
            category: params.get("category") || "",
            skipCache: true,
            paid: "1",
            client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
          })
        );

        setAddForm((values) => ({
          ...values,
          position: "",
          store: null,
          coupon: null,
        }));
      }
    });
  };

  const handleRemoveSpotlight = (result: Record<string, any>) => {
    dispatch(deletePaidPlacement(result.id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Placement was deleted successfully");
        const updatedItems = getPaidPlacementsData.data.filter(
          (it) => it.id !== result.id
        );
        dispatch(setPaidPlacements(updatedItems));
      }
    });
  };

  useEffect(() => {
    setSortedItems(getPaidPlacementsData.data);
  }, [getPaidPlacementsData.data]);

  const handleUpdateItem = (
    row: Record<string, any>,
    updatedItem: Record<string, any>
  ) => {
    const updatedItems = sortedItems.map((item) =>
      item.id === row.id ? updatedItem : item
    );

    dispatch(
      updatePaidPlacement({
        id: updatedItem.id,
        slogan: updatedItem.slogan,
        file: updatedItem.file,
        from_date: updatedItem.from_date,
        to_date: updatedItem.to_date,
        position: updatedItem.position,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
        dispatch(setPaidPlacements(updatedItems));
        setAddForm((f) => ({ ...f, location: addForm.location }));
      }
    });
  };

  const onSearchMerchantSelected = (merchant: Record<string, any>) => {
    setAddForm((values) => ({ ...values, store: merchant }));
    dispatch(clearProgramSearchResults());
  };

  const onSearchCouponSelected = (coupon: Record<string, any>) => {
    setAddForm((values) => ({ ...values, coupon }));
    dispatch(clearSearchCouponResults());
  };

  const addNewFormItem = (name: string, value: string) => {
    setAddForm((prev) => ({ ...prev, [name]: value }));
  };

  const checkAvailability = (form: Record<string, any>) => {
    dispatch(checkPlacementPosAvailability(form));
  };

  useEffect(() => {
    if (
      addForm.placement_destination &&
      addForm.position &&
      addForm.location &&
      addForm.placement_type
    ) {
      checkAvailability({
        category: addForm.category,
        placement_destination: addForm.placement_destination,
        position: addForm.position,
        placement_type: addForm.placement_type,
        location: addForm.location,
        client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
      });
    }
  }, [
    addForm.category,
    addForm.placement_destination,
    addForm.position,
    addForm.location,
  ]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setAddForm((f) => ({ ...f, [name]: value }));

    const url = new URL(window.location.href);
    url.searchParams.set(name, value);
    navigate(url.search);
  };

  return (
    <PaidPlacementsLayout
      filterBy={filterBy}
      data={sortedItems}
      onSearchMerchantSelected={onSearchMerchantSelected}
      form={addForm}
      updatePaidPlacementData={updatePaidPlacementData}
      loading={getPaidPlacementsData.loading}
      checkAvailability={checkAvailability}
      handleAddNewItem={handleAddNewItem}
      onSearchCouponSelected={onSearchCouponSelected}
      handleUpdateItem={handleUpdateItem}
      handleRemoveSpotlight={handleRemoveSpotlight}
      categorySelectionActive={categorySelectionActive}
      setCategorySelectionActive={setCategorySelectionActive}
      addNewFormItem={addNewFormItem}
      setAddForm={setAddForm}
      onChange={onChange}
      checkPlacementPosAvailabilityData={checkPlacementPosAvailabilityData}
      addToPaidPlacementsData={addToPaidPlacementsData}
      deletePaidPlacementData={deletePaidPlacementData}
      getPlacementTypesData={getPlacementTypesData}
      getPlacementDestinationsData={getPlacementDestinationsData}
    />
  );
};

export default PaidPlacements;
