import React from "react";
import { capitalize, upperCase } from "lodash";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dates/formatDate";

type Props = {
  item: Record<string, any>;
};

function RemovedCampaignProgramRow({ item }: Props) {
  return (
    <React.Fragment>
      <tr className="border-b  hover:bg-gray-100">
        <th
          scope="row"
          className="px-2 py-2 text-xs font-medium text-gray-900 "
        >
          {formatDateTime(item.created_at, "mm/dd/yyyy h:MM TT")}
        </th>
        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.campaign.client.name}
        </td>
        <th
          scope="row"
          className="px-2 py-2 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {capitalize(item.campaign?.search_engine)}
        </th>
        <th
          scope="row"
          className="px-2 py-2 text-xs font-medium text-gray-900 "
        >
          <Link to={`/bma/programs?slug=${item.campaign?.program_slug}`}>
            {item.campaign?.name || item.campaign?.program_slug}
          </Link>
        </th>

        <th
          scope="row"
          className="px-2 py-2 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {upperCase(item.campaign.location)}
        </th>
      </tr>
    </React.Fragment>
  );
}

export default RemovedCampaignProgramRow;
