import dateFormat from "dateformat";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import TabCreateCoupon from "containers/oma/CreateUpdateCoupon";
import formatDate from "utils/dates/formatDate";
import DeactivateCouponBtn from "./DeactivateCoupon";

type Props = {
  item: Record<string, any>;
  deactivateCoupon: () => void;

  onItemSelectChange: (item: Record<string, any>) => void;
  selectedItems: Array<Record<string, any>>;
};

const UserAddedCouponRow = ({
  item,
  deactivateCoupon,
  onItemSelectChange,
  selectedItems,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 "
  );

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <td className="pl-2 py-2 w-4">
          <input
            id="checkbox-table-search-1"
            type="checkbox"
            checked={selectedItems.map((it) => it.id).includes(item.id)}
            onChange={() => onItemSelectChange(item)}
            className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label htmlFor="checkbox-table-search-1" className="sr-only">
            checkbox
          </label>
        </td>
        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {formatDate(item.date_created) || ""}
        </td>

        <th
          scope="row"
          className="py-2 pl-4  font-medium text-gray-900 whitespace-nowrap max-w-xs"
        >
          {item.user_app?.name || ""}
        </th>

        <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs">
          {item?.store.name || ""}
        </td>

        <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs">
          {item?.store.primary_network.name}
        </td>

        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          {item.description}
        </th>
        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          <span className="relative block">
            {item.discount_code}
            {item.tags.map((el: string) => (
              <span
                key={el}
                className="bg-green-100 
                text-xs -top-2 mx-1 
                 text-green-800
               
                 p-0.5
                rounded
                 dark:bg-green-900
                dark:text-green-300"
              >
                {el}
              </span>
            ))}
          </span>
        </th>
        <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
          <p
            className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
            style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
          >
            {item.expiration_date ? formatDate(item.expiration_date) : null}
          </p>
        </td>

        <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
          <p
            className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
            style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
          >
            {item.user_app_install_id}
          </p>
        </td>
        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.start_date
            ? dateFormat(new Date(item.start_date), "d/mm/yyyy, h:MM TT")
            : ""}
        </td>

        <td>
          <div className="flex gap-x-1 ml-4">
            <DeactivateCouponBtn
              onConfirm={() => {
                deactivateCoupon();
              }}
              deactivateOpen={deactivateOpen}
              setDeactivateOpen={setDeactivateOpen}
              userAdded
            />

            <button
              type="button"
              onClick={() => {
                setDeactivateOpen(true);
                // setIsEditing((isEditing) => !isEditing);
              }}
              className="inline-flex mr-4 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              Delete
            </button>
            <button
              type="button"
              onClick={() => {
                setIsEditing((isEditing) => !isEditing);
              }}
              className="inline-flex mr-4 items-center rounded-md border  bg-primary-500 px-4 py-2 text-sm font-medium text-white
             shadow-sm"
            >
              Activate
            </button>
          </div>
        </td>
      </tr>

      {isEditing && (
        <tr>
          <td colSpan={8}>
            <TabCreateCoupon
              initialForm={{ ...item, cancelFn: setIsEditing, userAdded: true }}
            />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default UserAddedCouponRow;
