import {
  REPORT_TYPE_DAILY_REVENUE,
  REPORT_TYPE_WEEKLY_REVENUE,
  REPORT_TYPE_MONTHLY_REVENUE,
} from "constants/revenueReportTypes";
import formatDate from "utils/dates/formatDate";

export const getReportName = (el: Record<string, any>, report_type: string) => {
  const key = el.key;
  if (report_type == REPORT_TYPE_DAILY_REVENUE && key) {
    return formatDate(key, "mm/dd/yy");
  }
  if (
    key &&
    (report_type == REPORT_TYPE_WEEKLY_REVENUE ||
      report_type == REPORT_TYPE_MONTHLY_REVENUE)
  ) {
    const startingDate = key.split("_")[0];
    const endingDate = key.split("_")[1];

    return `${formatDate(startingDate, "mm/dd/yy")}-${formatDate(
      endingDate,
      "mm/dd/yy"
    )}`;
  }
};
const mergeRows = (
  networkTransactionsRevenueData: Record<string, any>,
  ioRevenueData: Record<string, any>
): Record<string, any>[] => {
  if (Array.isArray(networkTransactionsRevenueData)) {
    const validTransactions = networkTransactionsRevenueData
      .filter((el: Record<string, any>) => el.data.amt)
      .map((el) => ({
        key: el.data.name.replace("comm_", ""),
        value: el.data.amt,
        ioRevenue: 0,
      }));

    const validIoTransactions = Object.entries(ioRevenueData)
      .map(([k, v]) => ({ key: k.replace("io_totals_", ""), value: v }))
      .filter((it) => it.value > 0);

    for (let index = 0; index < validTransactions.length; index++) {
      const element = validTransactions[index];

      for (let index = 0; index < validIoTransactions.length; index++) {
        const ioElement = validIoTransactions[index];

        if (element.key == ioElement.key) {
          const matchedItemIndex = validTransactions.findIndex(
            (it) => it.key == ioElement.key
          );

          if (!isNaN(matchedItemIndex)) {
            validTransactions[matchedItemIndex]["ioRevenue"] = ioElement.value;
          }
        }
      }
    }
    return validTransactions;
  }

  return [];
};
export const getPeriodChartData = (
  networkTransactionsRevenueData: Record<string, any>,
  ioRevenueData: Record<string, any>,
  report_type: string
) => {
  const rows = mergeRows(networkTransactionsRevenueData, ioRevenueData);

  return rows.map((el) => ({
    Name: getReportName(el, report_type),
    Commission: el.value / 1000,
    "IO Revenue": el.ioRevenue || 0,
    total: el.value / 1000 + (el.ioRevenue || 0),
  }));
};
