import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createUpdateDeleteProposal from "redux/thunks/sales/createUpdateDeleteProposal";
import getIos from "redux/thunks/sales/getIos";
import sendSigningRequest from "redux/thunks/sales/sendSigningRequest";
import { PaginatedData } from "types/data";
import { Proposal } from "types/model/sales/Proposal";

export type ActionStateType = {
  data: PaginatedData<Proposal>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getIosSlice = createSlice({
  name: "Ios",
  initialState,
  reducers: {
    resetIos: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIos.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(getIos.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if ({ payload }) {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      })
      .addCase(
        createUpdateDeleteProposal.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                items: state.data.items.map((el) =>
                  el.proposal_id === payload.proposal_id
                    ? { ...el, ...payload, payment_status: "hola" }
                    : el
                ),
              },
              loading: false,
            };
          }
        }
      )

      .addCase(
        sendSigningRequest.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                items: state.data.items.map((el) =>
                  el.id === payload.proposal_id
                    ? { ...el, signing_status: payload.status }
                    : el
                ),
              },
              loading: false,
            };
          }
        }
      )

      .addCase(getIos.rejected, (state, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: false,
        error: payload,
      }));
  },
});
export const { resetIos } = getIosSlice.actions;
export default getIosSlice.reducer;
