import Button from "components/common/Button";
import { LOCAL_KEY_AUTH_USER } from "constants/storageKeys";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useState, ChangeEvent, useRef, Ref } from "react";
import toast from "react-hot-toast";
import createUser from "redux/thunks/users/createUser";
import { User } from "types/model/User";

type Props = {
  user: User | null;
};

export default function AccountComponent({ user }: Props) {
  const dispatch = useAppDispatch();
  const updateUserData = useAppSelector((state) => state.createUser);
  const [profilePhoto, setProfilePhoto] = useState<string>(
    user?.image_url || ""
  );
  const ref = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | null>(null);

  const saveFile = () => {
    if (user && user.clients && user.user_apps && user.user_sub_apps) {
      dispatch(
        createUser({
          data: {
            file,
            ...user,
            clients: user.clients.map((client) => client.id),
            locations: user.locations.map((location) => location),
            user_apps: user.user_apps.map((app: Record<string, any>) => app.id),

            user_sub_apps: user.user_sub_apps.map(
              (app: Record<string, any>) => app.id
            ),
            password: "_",
          },
        })
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.success("Photo updated successfully");
          localStorage.setItem(
            LOCAL_KEY_AUTH_USER,
            JSON.stringify(response.payload.item)
          );
          setFile(null);
        }
      });
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
      setProfilePhoto(URL.createObjectURL(file));
    }
  };

  const openImage = () => {
    ref.current?.click();
  };

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl">
      {/* <CropImage
        open={open}
        setOpen={setOpen}
        aspectRatio={1}
        file={file}
        updateUserData={updateUserData}
        chooseImage={openImage}
        // onImageCropDone={(res) => {
         
        // }}
      /> */}
      <div className="flex flex-col items-center">
        <div className="relative">
          <div className="text-center">
            <img
              src={profilePhoto}
              alt="Profile"
              className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 rounded-full object-cover"
            />

            <Button
              text={file ? "Upload" : "Change"}
              disabled={updateUserData.loading}
              loading={updateUserData.loading}
              onClick={() => {
                if (!file) {
                  openImage();
                } else {
                  saveFile();
                }
              }}
            />
          </div>
          <input
            type="file"
            accept="image/*"
            ref={ref}
            onChange={handleImageChange}
            className="hidden"
          />
        </div>
        <div className="mt-6 w-full text-center">
          <div className="mb-4 flex items-center space-x-2">
            <h3 className=" font-semibold text-gray-800">Name:</h3>
            <p className="text-gray-600">
              {user?.first_name + " " + user?.last_name}
            </p>
          </div>
          <div className="mb-4 flex items-center space-x-2">
            <h3 className=" font-semibold text-gray-800">Email:</h3>
            <p className="text-gray-600">{user?.email}</p>
          </div>
          <div className="mb-4 flex items-center space-x-2">
            <h3 className=" font-semibold text-gray-800">Role:</h3>
            <p className="text-gray-600">{user?.user_role}</p>
          </div>
          <div className="mb-4 flex items-center space-x-2">
            <h3 className=" font-semibold text-gray-800">Locations:</h3>
            <p className="text-gray-600">{user?.locations.join(",")}</p>
          </div>
          <div className="mb-4 flex items-center space-x-2">
            <h3 className=" font-semibold text-gray-800">Apps:</h3>
            <p className="text-gray-600">
              {user?.user_apps?.map((it) => it.name)?.join(", ")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
