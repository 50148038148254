import classNames from "classnames";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import PageSettings from "./PageSettings";
import ProgramImagesComponent from "./ProgramImages";
import ProgramCategoriesComponent from "components/program/ProgramCategories";
import Mapping from "containers/bma/BMAMerchantMapping";
import { ActionStateType } from "redux/slices/clients/getProgram";
import Contact from "./Contact";
import Button from "components/common/Button";
import { ActionState, PageNumPaginatedActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";
import Stats from "./Stats";
import SimilarStores from "./SimilarStores";
import Content from "./Content";
import Faqs from "./Faqs";
import { StoreType } from "types/model/oma/StoreType";
import { CouponPolicy } from "types/model/oma/CouponPolicy";

const RenderComp = ({
  activeTab: { key },
  form,
  onChange,
  getCategoriesData,
  createUpdateStore,
  getStoreTypesData,
  getCouponPolicyData,
  setForm,
}: {
  activeTab: Record<string, any>;
  form: Record<string, any>;
  getActivePrograms: PageNumPaginatedActionState<Array<Record<string, any>>>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  getCategoriesData: ActionState<ProgramCategory[]>;
  createUpdateStore: ActionState;
  getStoreTypesData: ActionState<StoreType[]>;
  getCouponPolicyData: ActionState<CouponPolicy[]>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
}) => {
  switch (key) {
    case "1":
      return (
        <PageSettings
          createUpdateStore={createUpdateStore}
          form={form}
          onChange={onChange}
          getStoreTypesData={getStoreTypesData}
          getCouponPolicyData={getCouponPolicyData}
          setForm={setForm}
        />
      );

    case "2":
      return <ProgramImagesComponent onChange={onChange} form={form} />;

    case "3":
      return (
        <ProgramCategoriesComponent
          getCategoriesData={getCategoriesData}
          form={form}
          onChange={onChange}
        />
      );
    case "4":
      return <SimilarStores onChange={onChange} form={form} />;

    case "5":
      return <Stats onChange={onChange} form={form} />;
    case "6":
      return <Contact onChange={onChange} form={form} />;
    case "7":
      return <Faqs faqs={form.faqs} onChange={onChange} />;

    case "9":
      return <Content content={form.content} onChange={onChange} />;

    case "8":
      return <Mapping />;

    default:
      return (
        <PageSettings
          createUpdateStore={createUpdateStore}
          form={form}
          onChange={onChange}
          getStoreTypesData={getStoreTypesData}
          getCouponPolicyData={getCouponPolicyData}
          setForm={setForm}
        />
      );
  }
};

type Props = {
  programDetails: ActionStateType;
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onFormSubmit: () => void;
  getCategoriesData: ActionState<ProgramCategory[]>;
  getActivePrograms: PageNumPaginatedActionState<Array<Record<string, any>>>;
  createUpdateStore: ActionState;
  onTabChange: (index: number) => void;
  getStoreTypesData: ActionState<StoreType[]>;
  getCouponPolicyData: ActionState<CouponPolicy[]>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
};

const Tabs = ({
  programDetails: { data },
  form,
  onChange,
  onFormSubmit,
  getCategoriesData,
  getActivePrograms,
  createUpdateStore,
  onTabChange,
  getStoreTypesData,
  getCouponPolicyData,
  setForm,
}: Props) => {
  if (!form.id) {
    return null;
  }

  useEffect(() => {
    if (form.primary_category && getCategoriesData.data.length) {
      const categoryId = getCategoriesData.data.find(
        (it) => it.name === String(form.primary_category?.name)
      )?.id;

      if (categoryId) {
        onChange({
          target: {
            name: "primary_category",
            value: categoryId,
          },
        } as ChangeEvent<HTMLInputElement>);
      }
    }
  }, [getCategoriesData.data.length]);

  const tabs = [
    {
      key: "1",
      title: "Page Settings",
      visible: true,
      extraText: null,
      component: (
        <PageSettings
          createUpdateStore={createUpdateStore}
          onChange={onChange}
          getStoreTypesData={getStoreTypesData}
          form={form}
          getCouponPolicyData={getCouponPolicyData}
          setForm={setForm}
        />
      ),
    },
    {
      key: "2",
      title: "Image Assets",
      extraText: null,
      visible: true,
      component: <ProgramImagesComponent onChange={onChange} form={form} />,
    },
    {
      key: "3",
      title: "Categories and Tags",
      extraText: null,
      visible: true,
      component: (
        <ProgramCategoriesComponent
          getCategoriesData={getCategoriesData}
          form={form}
          onChange={onChange}
        />
      ),
    },
    {
      key: "4",
      title: "Similar Stores",
      extraText: null,
      visible: true,
      component: <SimilarStores onChange={onChange} form={form} />,
    },
    {
      key: "5",
      title: "Stats",
      extraText: null,
      visible: true,
      component: (
        <PageSettings
          getCouponPolicyData={getCouponPolicyData}
          setForm={setForm}
          createUpdateStore={createUpdateStore}
          onChange={onChange}
          getStoreTypesData={getStoreTypesData}
          form={form}
        />
      ),
    },
    {
      title: "Contact",
      extraText: null,
      key: "6",
      visible: true,
      component: <Contact onChange={onChange} form={form} />,
    },

    {
      title: "Faq",
      extraText: null,
      key: "7",
      visible: true,
      component: <Faqs onChange={onChange} faqs={data.faqs} />,
    },

    {
      title: "Content",
      extraText: null,
      key: "9",
      visible: true,
      component: <Content content={form.content} onChange={onChange} />,
    },
    {
      title: "Mapping",
      extraText: null,
      key: "8",
      component: <Mapping />,
      visible: true,
    },
  ];

  const activeTab = tabs[Number(form.tab)];

  return (
    <div>
      <main className="pt-4 pb-8">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="sm:block">
            <div className="border-b border-gray-200">
              <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                {tabs
                  .filter((it) => it.visible)
                  .map((tab, idx) => (
                    <button
                      key={tab.title}
                      onClick={() => {
                        onTabChange(idx);
                      }}
                      className={classNames(
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ",
                        {
                          "border-purple-500 text-purple-600":
                            activeTab.title === tab.title,
                        }
                      )}
                    >
                      {tab.title}
                      {tab.extraText && (
                        <span
                          className={classNames(
                            "ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block bg-purple-100 ",
                            {
                              "text-purple-600": activeTab.title === tab.title,
                            }
                          )}
                        >
                          {tab.extraText}{" "}
                        </span>
                      )}
                    </button>
                  ))}

                <span className="sm:block ml-auto mb-4">
                  <Button
                    text="Save"
                    loading={createUpdateStore.loading}
                    onClick={onFormSubmit}
                  />
                </span>
              </nav>
            </div>
          </div>
        </div>
      </main>

      <RenderComp
        onChange={onChange}
        activeTab={activeTab}
        form={form}
        getCouponPolicyData={getCouponPolicyData}
        setForm={setForm}
        createUpdateStore={createUpdateStore}
        getCategoriesData={getCategoriesData}
        getActivePrograms={getActivePrograms}
        getStoreTypesData={getStoreTypesData}
      />
    </div>
  );
};

export default Tabs;
