import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "emails/delete-news-letter-email",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      if (payload.id) {
        const response = await axios.delete(
          `/admin/emails/news-letter/${payload.id}`
        );
        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
