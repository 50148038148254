import SimpleBarChart from "components/charts/Bar/SimpleBarChart";
import { ActionState } from "types/data";
import formatDate from "utils/dates/formatDate";
import { Dispatch, SetStateAction } from "react";

type Props = {
  filterValues: Record<string, any>;
  subsReportData: ActionState;
  setReportType: Dispatch<SetStateAction<string>>;
};

function EmailSubsReportComponent({
  filterValues,
  subsReportData,
  setReportType,
}: Props) {
  const getYAxisKeyName = (k: string) => {
    if (!k.includes("subs_for_")) {
      return "";
    }
    if (filterValues.report_type === "daily") {
      const day = k.replace("subs_for_", "");
      if (day.includes("_")) {
        return "";
      }
      return formatDate(day);
    }

    if (["weekly", "monthly"].includes(filterValues.report_type)) {
      const [start, end] = k.replace("subs_for_", "").split("_");

      return `${formatDate(start)}-${formatDate(end)}`;
    }

    return k.replace("subs_for_", "");
  };
  const chartData = Object.entries(subsReportData.data).map(([k, v]) => {
    return {
      name: getYAxisKeyName(k),
      Count: v,
    };
  });

  return (
    <div style={{ width: "100%", height: 500 }}>
      <SimpleBarChart
        data={chartData}
        amountFormat={false}
        loading={subsReportData.loading}
        rangeOptions={[
          {
            label: "Day",
            onClick: () => {
              setReportType("daily");
            },
            active: filterValues.report_type === "daily",
          },
          {
            label: "Week",
            active: filterValues.report_type === "weekly",
            onClick: () => {
              setReportType("weekly");
            },
          },

          {
            label: "Month",
            active: filterValues.report_type === "monthly",
            onClick: () => {
              setReportType("monthly");
            },
          },
        ]}
        title="Total Subscribers"
        xDataKey="name"
        barDataKey="Count"
      />
    </div>
  );
}

export default EmailSubsReportComponent;
