import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getPositionAvailabilityStatuses from "redux/thunks/sales/getPositionAvailabilityStatuses";
import { PricingPlan } from "types/model/PricingPlan";

export type ActionStateType = {
  data: Array<PricingPlan>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getPositionAvailabilityStatusesSlice = createSlice({
  name: "getPositionAvailabilityStatuses",
  initialState,
  reducers: {
    resetGetPositionAvailabilityStatuses: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPositionAvailabilityStatuses.pending, (state) => {
        return {
          ...state,
          error: null,
          data: [],
          loading: true,
        };
      })
      .addCase(
        getPositionAvailabilityStatuses.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getPositionAvailabilityStatuses.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetGetPositionAvailabilityStatuses } =
  getPositionAvailabilityStatusesSlice.actions;
export default getPositionAvailabilityStatusesSlice.reducer;
