/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getAuthUser from "redux/thunks/users/getAuthUser";
import { User } from "types/model/User";

export type ActionStateType = {
  data: null | User;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: null,
  loading: false,
  error: null,
};

const getAuthUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthUser.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
        data: initialState.data,
      }))
      .addCase(
        getAuthUser.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload.user,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getAuthUser.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getAuthUserSlice.reducer;
