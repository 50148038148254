import { useAppDispatch } from "hooks/useRedux";
import { isString } from "lodash";
import { useEffect, useState } from "react";
import getProgramById from "redux/thunks/app/api-clients/getProgramById";
import { twMerge } from "tailwind-merge";
import { Lead } from "types/model/sales_crm/Lead";
import formatDate from "utils/dates/formatDate";

type Props = {
  lead: Lead;
  leadIdx: number;
  wrapperClass: string;
};

export default function LeadCard({ lead, wrapperClass }: Props) {
  const dispatch = useAppDispatch();
  const program = lead.program;
  const [store, setStore] = useState<Record<string, any>>({
    name: program.name,
    slug: program.slug,
    client: program.client,
    id: program.id,
    logo_url: "",
    primary_network: {},
  });

  useEffect(() => {
    if (program.id) {
      dispatch(
        getProgramById({
          client_id: program.client,
          id: isString(program.slug) ? program.slug : (program.id as string),
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setStore(res.payload);
        }
      });
    }
  }, [program.id]);

  return (
    <div
      className={twMerge(
        " my-4  rounded-xl border border-gray-200",
        wrapperClass
      )}
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6 ">
        {store.logo_url && (
          <img
            src={store.logo_url}
            alt={store.name}
            className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
          />
        )}
        <div className="text-sm font-medium leading-6 text-gray-900">
          {store.name}
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="py-3 grid grid-cols-2 gap-x-4">
          <dt className="text-gray-500"> Created at:</dt>
          <dd className="text-gray-900">{formatDate(lead.created_at)}</dd>
          <dt className="text-gray-500"> Assigned at: </dt>
          <dd className="text-gray-700">
            <time dateTime={lead.assigned_at}>
              {formatDate(lead.assigned_at)}
            </time>
          </dd>
          <dt className="text-gray-500">Website URL:</dt>
          <dd className="text-gray-900">
            <span className="w-24">
              <a
                href={lead.program?.website_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline text-sm"
              >
                {new URL(lead.program?.website_url).hostname.replace(
                  "www.",
                  ""
                )}
              </a>
            </span>
          </dd>
          <dt className="text-gray-500">Tier</dt>
          <dd className="text-gray-900 flex items-center">{store.rank}</dd>
          <dt className="text-gray-500">Topic:</dt>
          <dd className="text-gray-900">{lead.topics?.[0]?.name}</dd>
          <dt className="text-gray-500">Assigned by: </dt>
          <dd className="text-gray-900">
            {(lead.assigned_by?.first_name || "") +
              " " +
              (lead.assigned_by?.last_name || "")}
          </dd>

          <dt className="text-gray-500">Notes</dt>
          <dd className="text-gray-900 w-32">{lead.notes}</dd>
        </div>
      </dl>
    </div>
  );
}
