// Define constants
export const REPORT_TYPE_BIG_PICTURE = "big_picture";
export const REPORT_TYPE_CLIENT_REVENUE = "client_revenue";
export const REPORT_TYPE_AD_CENTER_REVENUE = "ad-center-revenue";
export const REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER =
  "advertiser_txn_detail";
export const REPORT_TYPE_PPC = "ppc";
export const REPORT_TYPE_TX_SUMMARY = "txn_summary";
export const REPORT_TYPE_DAILY_REVENUE = "daily_revenue";
export const REPORT_TYPE_WEEKLY_REVENUE = "weekly_revenue";
export const REPORT_TYPE_MONTHLY_REVENUE = "monthly_revenue";
export const REPORT_TYPE_PAYMENTS = "payment_list";
export const REPORT_TYPE_RETURNED_TRANSACTIONS = "returned_transactions";
export const REPORT_TYPE_CLIENT_ACQUISITION = "client_acquisition";
export const REPORT_TYPE_CLIENT_ACQUISITION_DAILY = "client_acquisition_daily";
export const REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY =
  "client_acquisition_weekly";
export const REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY =
  "client_acquisition_monthly";

// Define types for the constants
export type ReportType =
  | typeof REPORT_TYPE_BIG_PICTURE
  | typeof REPORT_TYPE_CLIENT_REVENUE
  | typeof REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER
  | typeof REPORT_TYPE_PPC
  | typeof REPORT_TYPE_TX_SUMMARY
  | typeof REPORT_TYPE_DAILY_REVENUE
  | typeof REPORT_TYPE_PAYMENTS
  | typeof REPORT_TYPE_RETURNED_TRANSACTIONS
  | typeof REPORT_TYPE_WEEKLY_REVENUE
  | typeof REPORT_TYPE_MONTHLY_REVENUE
  | typeof REPORT_TYPE_AD_CENTER_REVENUE
  | typeof REPORT_TYPE_CLIENT_ACQUISITION
  | typeof REPORT_TYPE_CLIENT_ACQUISITION_DAILY
  | typeof REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY
  | typeof REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY;

// Define types for the report types object
export interface ReportTypes {
  [key: string]: string;
}

// Define report types object
const reportTypes: ReportTypes = {
  [REPORT_TYPE_BIG_PICTURE]: "Big Picture (Summary) Report",
  [REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER]:
    "Transaction Detail by Advertiser",
  [REPORT_TYPE_TX_SUMMARY]: "Transaction Summary",
  [REPORT_TYPE_RETURNED_TRANSACTIONS]: "Returned / Cancelled Transactions",
  [REPORT_TYPE_CLIENT_REVENUE]: "Revenue Report by Client",
  [REPORT_TYPE_CLIENT_ACQUISITION]: "Acquisition Revenue Report by Client",
  [REPORT_TYPE_PAYMENTS]: "Payments",
  [REPORT_TYPE_AD_CENTER_REVENUE]: "AdCenter Revenue",
};

const defaultFilterKeys = [
  "report_type",
  "client",
  "network",
  "client_location",
  "page",
  "start_date",
];

const acquisitionFilterCols = [
  "report_type",
  "client",
  "start_date",
  "acquisition_type",
];

export const reportFilterKeys = {
  [REPORT_TYPE_BIG_PICTURE]: defaultFilterKeys,
  [REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER]: [
    ...defaultFilterKeys,
    "search_text",
    "filter_date_type",
    "start_date",
    "end_date",
    "txn_type",
    "txn_status",
    "commission_type",
    "website_id",
    "acquisition_type",
    "network_id",
    "tz_str",
  ],
  [REPORT_TYPE_TX_SUMMARY]: [...defaultFilterKeys, "search_text"],
  [REPORT_TYPE_RETURNED_TRANSACTIONS]: defaultFilterKeys,
  [REPORT_TYPE_DAILY_REVENUE]: defaultFilterKeys,
  [REPORT_TYPE_PAYMENTS]: defaultFilterKeys,
  [REPORT_TYPE_WEEKLY_REVENUE]: defaultFilterKeys,
  [REPORT_TYPE_MONTHLY_REVENUE]: defaultFilterKeys,
  [REPORT_TYPE_AD_CENTER_REVENUE]: [
    "report_type",
    "client",
    "txn_status",
    "start_date",
  ],
  [REPORT_TYPE_CLIENT_REVENUE]: ["report_type", "client", "start_date"],
  [REPORT_TYPE_PPC]: ["report_type", "client", "start_date"],
  [REPORT_TYPE_CLIENT_ACQUISITION]: acquisitionFilterCols,

  [REPORT_TYPE_CLIENT_ACQUISITION_DAILY]: acquisitionFilterCols,
  [REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY]: acquisitionFilterCols,
  [REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY]: acquisitionFilterCols,
} as Record<string, string[]>;

export const reportTypeOptions = Object.entries(reportTypes).map(([k, v]) => ({
  label: v,
  value: k,
  selected: false,
})) as any[];

export default reportTypes;
