import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import Switch from "components/common/Switch";
import Layout from "components/layout/Layout";
import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
} from "react";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  actionState: any;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  form: Record<string, any>;
};

const UserCreateUpdateComponent = ({
  onChange,
  onSubmit,
  form,
  setForm,
  actionState: { loading },
}: Props) => {
  return (
    <Layout>
      <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
        {/*Modal Content*/}
        <form
          onSubmit={onSubmit}
          className="relative bg-white rounded-lg shadow dark:bg-gray-800"
        >
          {/*Modal Header*/}
          <div className="flex justify-between items-center py-4 px-4 rounded-t sm:px-5">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {form.id ? "User Details" : "Create User"}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="updateUserModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div id="accordion-collapse" data-accordion="collapse">
            <div
              id="accordion-collapse-body-1"
              className=""
              aria-labelledby="accordion-collapse-heading-1"
            >
              <div className="p-4 border-gray-200 sm:p-5 dark:border-gray-700">
                {/*Inputs*/}
                <div className="grid gap-4 sm:grid-cols-2">
                  <div className="sm:col-span-2">
                    {form.id && form.image_url && !form.tempImage && (
                      <img
                        className="mb-4 w-20 h-20 rounded-full sm:mr-4 sm:mb-0 object-cover"
                        src={form.image_url}
                        alt="Helene avatar"
                      />
                    )}
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      htmlFor="file_input"
                    >
                      Upload avatar
                    </label>
                    <div className="items-center w-full sm:flex">
                      {form.tempImage && (
                        <img
                          className="mb-4 w-20 h-20 rounded-full sm:mr-4 sm:mb-0 object-cover"
                          src={form.tempImage || form.image_url}
                          alt="Helene avatar"
                        />
                      )}
                      <div className="w-full">
                        <input
                          className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                          aria-describedby="file_input_help"
                          id="file_input"
                          type="file"
                          name="file"
                          accept="image/png,image/jpg,image/jpeg"
                          onChange={(e) => {
                            setForm((pre) => ({
                              ...pre,
                              file: e.target.files?.[0] as any,
                              tempImage: URL.createObjectURL(
                                e.target.files?.[0] as any
                              ),
                            }));
                          }}
                        />
                        <p
                          className="mt-1 mb-3 text-xs font-normal text-gray-500 dark:text-gray-300"
                          id="file_input_help"
                        >
                          PNG, JPG (MAX. 800x400px).
                        </p>
                      </div>
                    </div>
                  </div>
                  <Input
                    name="first_name"
                    onChange={onChange}
                    id="first-name"
                    label="First Name"
                    placeholder="John"
                    value={form.first_name}
                    required
                  />

                  <Input
                    label="Last Name"
                    name="last_name"
                    id="last_name"
                    value={form.last_name}
                    onChange={onChange}
                    placeholder="Doe"
                    required
                  />

                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    id="email"
                    value={form.email}
                    placeholder="john@company.com"
                    required
                    onChange={onChange}
                  />

                  <MultipleSelectionInput
                    label="User Apps"
                    name="user_apps"
                    onChange={(event) => {
                      setForm((f) => ({
                        ...f,
                        user_apps: f.user_apps.map((v: Record<string, any>) =>
                          v.id === event.target.value
                            ? { ...v, selected: event.target.checked }
                            : v
                        ),
                      }));
                    }}
                    renderAll
                    options={form.user_apps.map((el: Record<string, any>) => ({
                      label: el.name,
                      value: el.id,
                      selected: el.selected,
                    }))}
                  />

                  <MultipleSelectionInput
                    label="User Sub Apps"
                    name="sub_apps"
                    onChange={(event) => {
                      setForm((f) => ({
                        ...f,
                        sub_apps: f.sub_apps.map((v: Record<string, any>) =>
                          v.id === event.target.value
                            ? { ...v, selected: event.target.checked }
                            : v
                        ),
                      }));
                    }}
                    renderAll
                    options={form.sub_apps.map((el: Record<string, any>) => ({
                      label: `${el.name} (${el.parent?.name})`,

                      value: el.id,
                      selected: el.selected,
                    }))}
                  />
                  <MultipleSelectionInput
                    label="Locations"
                    name="locations"
                    onChange={(item) => {
                      setForm((f) => ({
                        ...f,
                        locations: f.locations.map((v: Record<string, any>) =>
                          v.value === item.target.value
                            ? { ...v, selected: item.target.checked }
                            : v
                        ),
                      }));
                    }}
                    renderAll
                    options={form.locations}
                  />

                  <Dropdown
                    label="Default App"
                    name="default_app"
                    onChange={onChange}
                    value={form.default_app}
                    options={form.user_apps.map((el: Record<string, any>) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                  />

                  <Input
                    label="Job Title"
                    name="job_title"
                    id="job_title"
                    placeholder="Ex. React Developer"
                    required
                    value={form.job_title}
                    onChange={onChange}
                  />

                  <Input
                    label="Slack Member ID"
                    name="slack_member_id"
                    id="slack_member_id"
                    value={form.slack_member_id}
                    onChange={onChange}
                  />

                  <MultipleSelectionInput
                    label="Client Accounts"
                    name="clients"
                    onChange={(item) => {
                      setForm((f) => ({
                        ...f,
                        clients: f.clients.map((v: Record<string, any>) =>
                          v.value === item.target.value
                            ? { ...v, selected: item.target.checked }
                            : v
                        ),
                      }));
                    }}
                    renderAll
                    options={form.clients}
                  />

                  <Dropdown
                    label="User Role"
                    name="user_role"
                    onChange={onChange}
                    value={form.user_role}
                    options={[
                      { label: "Standard User", value: "STANDARD" },
                      { label: "Admin User", value: "ADMIN" },
                    ]}
                  />
                  <Input
                    label="Password"
                    name="password"
                    type="password"
                    onChange={onChange}
                  />

                  <Input
                    label="Confirm Password"
                    name="confirm_password"
                    type="password"
                    onChange={onChange}
                  />

                  <Switch
                    name="is_active"
                    checked={form.is_active}
                    onChange={onChange}
                    label="Status"
                    text="Active"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center py-4 px-4 space-x-4 sm:py-5 sm:px-5">
            <button
              type="submit"
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {loading ? "Please wait..." : "Update user"}
            </button>
            <button
              type="button"
              className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
            >
              <svg
                className="mr-1 -ml-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Delete
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default UserCreateUpdateComponent;
