import axios from "axios";
type UploadResult = {
  url?: string | null;
};

export const uploadFile = async (file: File): Promise<UploadResult> => {
  if (!file) {
    return { url: null };
  }

  const cloudName = "discountcodes";

  const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;

  const formData = new FormData();

  formData.append("file", file);
  formData.append("upload_preset", "mtguzd9s");

  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data) {
      return Promise.resolve({ url: response.data.url });
    } else {
      return Promise.reject({
        url: null,
        message: "Something went wrong",
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
