import { ChangeEventHandler, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import getPricingPlans from "redux/thunks/sales/getPricingPlans";
import { resetProgram } from "redux/slices/clients/getProgram";
import { Proposal } from "types/model/sales/Proposal";
import createUpdateDeleteProposal from "redux/thunks/sales/createUpdateDeleteProposal";
import { toast } from "react-hot-toast";
import IOLayout from "layout/sales/IOLayout";
import sendSigningRequest from "redux/thunks/sales/sendSigningRequest";
import { resetSendSigningRequest } from "redux/slices/sales/sendSigningRequest";
import getSignedIOPDFURL from "redux/thunks/sales/getSignedIOPDFURL";
import { User } from "types/model/User";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import { SALES_APP } from "constants/smaAppNames";
import getIos from "redux/thunks/sales/getIos";
import dayjs from "dayjs";
import { debounce } from "lodash";
import getProposalTypes from "redux/thunks/sales/getProposalTypes";

const Ios = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const {
    proposal_status = "Approved",
    proposal_type,
    location = "",
    page = "",
    created_by,
    slug: program_slug,
    payment_status,
    start_date = null,
    end_date = null,
  } = Object.fromEntries([...params]);

  const filterValues = {
    proposal_status,
    location,
    proposal_type,
    created_by,
    program_slug,
    payment_status,
    page,
    start_date: start_date || null,
    end_date: end_date || null,
  };

  const [signedIOLoadConfig, setSignedIOLoadConfig] = useState<
    Record<string, any>
  >({
    proposalId: null,
    loading: false,
  });

  const {
    getIos: getIosData,
    createUpdateDeleteProposal: createUpdateDeleteProposalData,
    sendSigningRequest: sendSigningRequestData,
    getProposalStats: getProposalStatsData,
    getAllUsers,
    getProposalTypes: proposalTypesData,
  } = useAppSelector(
    ({
      getIos,
      getAllUsers,
      getProposal,
      createUpdateDeleteProposal,
      sendSigningRequest,
      getProposalStats,
      getProposalTypes,
    }) => ({
      getIos,
      createUpdateDeleteProposal,
      getProposal,
      sendSigningRequest,
      getAllUsers,
      getProposalStats,
      getProposalTypes,
    })
  );

  useEffect(() => {
    if (params.has("slug")) {
      dispatch(getProgram({ query: params.get("slug") || "" }));
    } else {
      dispatch(resetProgram());
    }
  }, [params]);

  useEffect(() => {
    dispatch(getIos(filterValues));
  }, [params]);

  useEffect(() => {
    dispatch(getProposalTypes());
  }, []);

  const updateUsers = () => {
    const filteredUsers: User[] = [];

    getAllUsers.data.items.forEach((user) => {
      if (user.user_role === USER_ROLE_ADMIN.toUpperCase()) {
        filteredUsers.push(user);
      } else if (
        user.user_apps
          ?.map((el) => el.name.toLowerCase())
          ?.includes(SALES_APP.toLowerCase())
      ) {
        filteredUsers.push(user);
      }
    });

    setUsers(filteredUsers);
  };

  useEffect(() => {
    if (getAllUsers.data.meta.count > 0) {
      updateUsers();
    }
  }, [getAllUsers.data.meta.count]);

  useEffect(() => {
    dispatch(getPricingPlans({}));
  }, []);

  const updateStatus = (proposal: Proposal, newStatus: string) => {
    dispatch(
      createUpdateDeleteProposal({
        ...proposal,
        proposal_type: proposal.proposal_type.id,
        client: proposal.client.id,
        proposal_status: newStatus,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  const handleSendSigningRequest = (
    proposalId: string,
    file_url: string,
    form: Record<string, any>
  ) => {
    dispatch(
      sendSigningRequest({ proposal_id: proposalId, file_url, signer: form })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(resetSendSigningRequest());
      }
    });
  };

  const handleDownloadSignedPDF = (proposalId: string) => {
    setSignedIOLoadConfig({
      proposalId,
      loading: true,
    });
    dispatch(getSignedIOPDFURL(proposalId))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          window.open(res.payload.signed_pdf_url, "_blank");
        } else {
          toast.error(res.payload.message || "Something went wrong");
        }
      })
      .finally(() => {
        setSignedIOLoadConfig({
          proposalId: null,
          loading: false,
        });
      });
  };

  const debouncedOnSearchChange = debounce((value) => {
    dispatch(
      getIos({
        ...filterValues,
        search_text: value.replace("IO-", "").trim(),
      })
    );
  }, 3000);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;

    if (name === "search_text") {
      setSearchText(value);
      debouncedOnSearchChange(value);
      return;
    }

    if (name === "dates") {
      const url = new URL(window.location.href);
      const start = JSON.parse(value)[0];
      url.searchParams.set(
        "start_date",
        start ? dayjs(start).format("YYYY-MM-DD") : ""
      );
      const end = JSON.parse(value)[1];
      url.searchParams.set(
        "end_date",
        end ? dayjs(end).format("YYYY-MM-DD") : ""
      );

      url.searchParams.set("page", "1");

      navigate(url.search);

      return;
    }

    const url = new URL(window.location.href);
    url.searchParams.set(name, value);
    url.searchParams.set("page", "1");

    navigate(url.search);
  };
  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);

    url.searchParams.set("page", String(page));

    navigate(url.search);
  };

  const handleUploadManualIOFile = (
    file: Record<string, any>,
    row: Proposal
  ): void => {
    dispatch(
      createUpdateDeleteProposal({
        ...row,
        proposal_type: row.proposal_type.id,
        search_rights_type:
          row.search_rights_type?.id || row.search_rights_type || null,
        manualIOFile: file,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  return (
    <IOLayout
      proposalTypes={proposalTypesData}
      handleUploadManualIOFile={handleUploadManualIOFile}
      sendSigningRequestData={sendSigningRequestData}
      updateStatus={updateStatus}
      handleDownloadSignedPDF={handleDownloadSignedPDF}
      handleSendSigningRequest={handleSendSigningRequest}
      createUpdateDeleteProposalData={createUpdateDeleteProposalData}
      signedIOLoadConfig={signedIOLoadConfig}
      filterValues={filterValues}
      onChange={onChange}
      users={users}
      onPageChange={onPageChange}
      getIosData={getIosData}
      getProposalStatsData={getProposalStatsData}
      debouncedOnSearchChange={debouncedOnSearchChange}
      searchText={searchText}
    />
  );
};
export default Ios;
