import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import {
  REPORT_TYPE_CLIENT_REVENUE,
  REPORT_TYPE_DAILY_REVENUE,
  REPORT_TYPE_MONTHLY_REVENUE,
  REPORT_TYPE_WEEKLY_REVENUE,
} from "constants/revenueReportTypes";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";

export default createAsyncThunk(
  "sales/get-proposals-payment-stats",
  async (params: Record<string, any>, ThunkAPI) => {
    try {
      const urlPath = [
        REPORT_TYPE_DAILY_REVENUE,
        REPORT_TYPE_MONTHLY_REVENUE,
        REPORT_TYPE_WEEKLY_REVENUE,
        REPORT_TYPE_CLIENT_REVENUE,
      ].includes(params["report_type"])
        ? "/admin/sales/proposals/reporting/"
        : "/admin/sales/proposals/payment-stats/";

      const url = new URL(BACKEND_BASE_URL + urlPath);

      if (params["proposal_type"]) {
        url.searchParams.set("proposal_type", params["proposal_type"]);
      }
      if (params["search_text"]) {
        url.searchParams.set("search_text", params["search_text"]);
      }
      if (params["start_date"]) {
        url.searchParams.set("start_date", params["start_date"]);
      }
      if (params["end_date"]) {
        url.searchParams.set("end_date", params["end_date"]);
      }

      if (params["location"]) {
        url.searchParams.set("location", params["location"]);
      }

      if (params["program_slug"]) {
        url.searchParams.set("program_slug", params["program_slug"]);
      }

      if (params["proposal_status"]) {
        url.searchParams.set("proposal_status", params["proposal_status"]);
      }

      if (params["payment_status"]) {
        url.searchParams.set("payment_status", params["payment_status"]);
      }
      if (params["created_by"]) {
        url.searchParams.set("created_by", params["created_by"]);
      }

      if (params["report_type"]) {
        url.searchParams.set("report_type", params["report_type"]);
      }

       if (params["client"]) {
         url.searchParams.set("client", params["client"]);
       }

      if (params.page) {
        url.searchParams.set(
          "offset",
          String(getOffsetForPage(Number(params.page)))
        );
      }

      const response = await axios.get(
        url.pathname
          .replace("/api", "")
          .replace("/production", "")
          .replace("/dev", "") + url.search
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
