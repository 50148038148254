import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EmailCampaignsSummaryComponent from "layout/marketing/email/EmailCampaignsSummary";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getEmailCampaigns from "redux/thunks/marketing/emails/getEmailCampaigns";

function EmailCampaignSummary() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { page } = Object.fromEntries([...searchParams]);

  const getEmailCampaignsData = useAppSelector(
    (state) => state.getEmailCampaigns
  );

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  useEffect(() => {
    dispatch(getEmailCampaigns({ show_summary: "1", page: Number(page) || 1 }));
  }, [searchParams]);

  const onReviewClicked = (item: Record<string,any>) => {
      if (item.campaign_info.campaign_type === "Generic") {
        return navigate(
          `/marketing/email/email-campaigns/${item.campaign_info.uuid}`,
          {
            state: {
              item,
            },
          }
        );
      }
    navigate(
      `/marketing/email/email-campaigns?email_drop_id=${item.email_drop_id}`,
      {
        state: {
          item,
        },
      }
    );
  };

  return (
    <EmailCampaignsSummaryComponent
      onPageChange={getNewPage}
      onReviewClicked={onReviewClicked}
      currentPage={Number(page) || 1}
      getEmailCampaignsData={getEmailCampaignsData}
    />
  );
}

export default EmailCampaignSummary;
