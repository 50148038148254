import exportResource from "redux/slices/clients/export-resource";
import campaignReducers from "./campaigns";
import couponReducers from "./coupons";
import marketingReducers from "../marketing";
import storeReducers from "./stores";
import categoryReducers from "./categories";
import contributionsReducers from "./contributions";

const clientReducers = {
  exportResource,
  ...campaignReducers,
  ...couponReducers,
  ...marketingReducers,
  ...storeReducers,
  ...categoryReducers,
  ...contributionsReducers,
};

export default clientReducers;
