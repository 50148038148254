import { MEDIUM_EMAIL } from "constants/contactMediums";
import { LOCAL_KEY_USER_PENDING_FOLLOW_UPS } from "constants/storageKeys";
import instance from "helpers/axios";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import NewEventLayout from "layout/sales_crm/NewEvent";
import { ChangeEventHandler, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetEvent } from "redux/slices/sales_crm/getEvent";
import { toggleContactModal } from "redux/slices/sales_crm/modals";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import createEvent from "redux/thunks/sales_crm/createEvent";
import editDeleteEvent from "redux/thunks/sales_crm/editDeleteEvent";
import getCRMNetworks from "redux/thunks/sales_crm/getCRMNetworks";
import getContacts from "redux/thunks/sales_crm/getContacts";
import getEntityTypes from "redux/thunks/sales_crm/getEntityTypes";
import getEvent from "redux/thunks/sales_crm/getEvent";
import getEventActionItems from "redux/thunks/sales_crm/getEventActionItems";
import getEventContactMediums from "redux/thunks/sales_crm/getEventContactMediums";
import getEventTypes from "redux/thunks/sales_crm/getEventTypes";
import getEvents from "redux/thunks/sales_crm/getEvents";
import getFollowups from "redux/thunks/sales_crm/getFollowups";
import getLeadTopics from "redux/thunks/sales_crm/getLeadTopics";
import { Contact } from "types/model/sales_crm/Contact";
import { today } from "utils/dates/today";

const NewEvent = () => {
  const [todoForm, setTodoForm] = useState<Record<string, any>>({});
  const [multiplePrograms, setMultiplePrograms] = useState(false);

  const { user } = useLoadAuth();
  const [form, setForm] = useState<Record<string, any>>({
    event_types: [],
    topics: [],
    action_items: [],
    event_date: today,
    medium: MEDIUM_EMAIL,
    has_pending_followup: false,
    linked_programs: [],
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const {
    getContactMediums: getContactMediumsData,
    getEventTypes: getEventTypesData,
    getEventActionItems: getEventActionItemsData,
    getLeadTopics: getLeadTopicsData,
    getContacts: {
      data: { items: programContacts },
      loading: programContactsLoading,
    },
    createEvent: createEventData,
    getEvent: getEventData,
    editDeleteEvent: editDeleteEventData,
    getEntityTypes: getEntityTypesData,
    getCRMNetworks: getCRMNetworksData,
  } = useAppSelector(
    ({
      getContactMediums,
      getEventTypes,
      getEventActionItems,
      getContacts,
      getLeadTopics,
      createEvent,
      getEvent,
      editDeleteEvent,
      getCRMNetworks,
      getEntityTypes,
    }) => ({
      getContactMediums,
      getEventTypes,
      getLeadTopics,
      getEventActionItems,
      getContacts,
      createEvent,
      getEvent,
      editDeleteEvent,
      getEntityTypes,
      getCRMNetworks,
    })
  );

  const { page, create_sub, slug, is_sub, createdContact, client_id } =
    Object.fromEntries([...params]);

  const currentProgram = form.linked_programs?.[0] || form.program;

  const nextUrl = `/sales/crm/store/${currentProgram?.slug}?client_id=${
    currentProgram?.client || client_id || ""
  }`;

  useEffect(() => {
    dispatch(getEntityTypes());
    dispatch(getCRMNetworks());
    dispatch(getEventActionItems());
    dispatch(getEventTypes());
    dispatch(getEventContactMediums());
    dispatch(getLeadTopics());
  }, []);

  useEffect(() => {
    if (page) {
      dispatch(
        getContacts({
          page,
          program: slug || "",
        })
      );
    }
  }, [page]);

  useEffect(() => {
    const eventId = params.get("event-id");
    if (eventId) {
      dispatch(getEvent({ id: eventId }));
    }
  }, []);

  const handleShowContactsModal = () => {
    dispatch(toggleContactModal());
  };

  const handleDeleteEvent = () => {
    dispatch(editDeleteEvent({ ...form, delete: true })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(getEvents({ offset: 0, store: currentProgram?.slug || "" }));
        dispatch(resetEvent());
        navigate(-1);
      }
    });
  };

  const handleProgramSelected = (res: Record<string, any>) => {
    setForm((f) => ({
      ...f,
      program: {
        name: res.name,
        logo_url: res.logo_url,
        id: res.id,
        slug: res.slug,
        client: res.client,
        network: res.primary_network?.name || "",
      },
    }));
  };

  const handleFormSubmit = () => {
    if (create_sub === "1") {
      dispatch(
        editDeleteEvent({ event_id: form.id, id: form.id, sub_update: form })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(resetEvent());
          dispatch(getEvents({ offset: 0, store: currentProgram.slug }));
          navigate(nextUrl);
          toast.success("Sub update added successfully");
        }
      });

      return;
    }
    if (form.id) {
      dispatch(
        editDeleteEvent({
          ...form,
          program: form.program
            ? {
                name: form.program.name,
                slug: form.program.slug,
                id: form.program.id,
                logo_url: form.program.logo_url,
                client: form.program.client,
                network: form.program.network,
              }
            : {},
        })
      ).then(() => {
        navigate(nextUrl);
        dispatch(getEvents({ offset: 0, store: currentProgram.slug }));
      });
      return;
    }

    submitTodo();
    dispatch(
      createEvent({
        ...form,
        program: form.program
          ? {
              name: form.program.name,
              slug: form.program.slug,
              id: form.program.id,
              client: form.program.client,
              network: form.program.network,
              logo_url: form.program.logo_url,
            }
          : {},
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Event Created Successfully");
        navigate(nextUrl);
        dispatch(resetEvent());
        dispatch(
          getEvents({
            store: slug,
          })
        );
      }
    });
    dispatch(
      getFollowups({
        has_pending_followup: "1",
        user_id: user?.id || "",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        localStorage.setItem(
          LOCAL_KEY_USER_PENDING_FOLLOW_UPS,
          res.payload.meta.count
        );
      }
    });
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  const handleItemChange = (name: string, itemId: number) => {
    let newValue: number[];
    const added = form[name]?.includes(itemId) || false;
    if (added) {
      newValue = form[name].filter((it: number) => it !== itemId);
    } else {
      newValue = [...form[name], itemId];
    }

    setForm((f) => ({ ...f, [name]: newValue }));
  };

  useEffect(() => {
    if (getEventData.data?.id && create_sub !== "1") {
      setForm((f) => ({
        ...f,
        ...getEventData.data,
        entity_id: getEventData.data.entity?.id || null,
        contact: getEventData.data?.contact.id,
        topics: getEventData.data?.topics.map((el: Contact) => el.id),
        medium: getEventData.data?.medium.id,
        action_items: getEventData.data?.action_items.map(
          (el: Contact) => el.id
        ),
      }));
    } else {
      setForm((f) => ({
        ...f,
        linked_programs: getEventData.data.linked_programs,
        entity: getEventData.data.entity,
        entity_id: getEventData.data.entity?.id || null,
        entity_type: getEventData.data.entity_type || null,
        program: getEventData.data?.program,
        id: getEventData.data?.id,
        contact: getEventData.data?.contact?.id,
      }));
    }

    if (getEventData.data.linked_programs?.length > 0) {
      setMultiplePrograms(true);
    }
  }, [getEventData.data?.id]);

  useEffect(() => {
    if (form.program) {
      dispatch(
        getContacts({
          program: form.program.slug,
          page: 1,
        })
      );
    } else if (!create_sub) {
      dispatch(getContacts({ page: 1, program: slug || "" }));
    }
  }, [form.program]);

  useEffect(() => {
    if (slug) {
      dispatch(
        getProgram({
          query: slug,
          client_id: client_id,
          useDis: "1",
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setForm((f) => ({
            ...f,
            program: res.payload,
          }));
        }
      });
    }
  }, [slug]);

  useEffect(() => {
    if (createdContact) {
      setForm((f) => ({ ...f, contact: createdContact }));
    }
  }, [createdContact]);

  useEffect(() => {
    if (form.entity_type && form.entity_id) {
      const isAgency = getEntityTypesData.data
        .find((el) => el.id === form.entity_type)
        ?.description?.toLowerCase()
        .includes("agenc");

      const isNetwork = getEntityTypesData.data
        .find((el) => el.id === form.entity_type)
        ?.description?.toLowerCase()
        .includes("netw");

      if (isAgency && form.entity_id) {
        dispatch(
          getContacts({
            agency_id: form.entity_id,
            entity_type_id: form.entity_type,
          })
        );
      }

      if (isNetwork && form.entity_id) {
        dispatch(
          getContacts({
            network_id: form.entity_id,
            entity_type_id: form.entity_type,
          })
        );
      }
    }
  }, [form.entity_id, form.entity_type]);

  const submitTodo = (): void => {
    const todos = Object.entries(todoForm)
      .map(([key, value]) => {
        if (value?.trim()) {
          const actionItemId = key[0];
          const app =
            getEventActionItemsData.data.find(
              (it) => it.id === Number(actionItemId)
            )?.default_app || "";
          return {
            todo_type: actionItemId,
            app: app,
            notes: value,
            program: form.program
              ? {
                  name: form.program.name,
                  slug: form.program.slug,
                  id: form.program.id,
                  client: form.program.client,
                  network: form.program.network,
                }
              : form.linked_programs,
          };
        }
        return undefined;
      })
      .filter((todo) => todo !== undefined); // Filter out undefined values

    const requests = todos.map((todo) =>
      instance.post("/admin/sales-crm/todos/", todo)
    );

    Promise.all(requests)
      .then(() => {
        //
      })
      .catch((error) => {
        console.error("Error submitting todos", error);
      });
  };

  return (
    <NewEventLayout
      programContactsLoading={programContactsLoading}
      isSubUpdate={!!create_sub}
      getCRMNetworksData={getCRMNetworksData}
      handleProgramSelected={handleProgramSelected}
      handleShowContactsModal={handleShowContactsModal}
      getLeadTopicsData={getLeadTopicsData}
      getContactMediumsData={getContactMediumsData}
      getEventTypesData={getEventTypesData}
      getEventActionItemsData={getEventActionItemsData}
      programContacts={programContacts}
      onChange={onChange}
      handleFormSubmit={handleFormSubmit}
      handleItemChange={handleItemChange}
      form={form}
      createEventData={createEventData}
      handleDeleteEvent={handleDeleteEvent}
      editDeleteEventData={editDeleteEventData}
      setForm={setForm}
      todoForm={todoForm}
      setTodoForm={setTodoForm}
      getEntityTypesData={getEntityTypesData}
      multiplePrograms={multiplePrograms}
      setMultiplePrograms={setMultiplePrograms}
      isSub={is_sub === "1"}
    />
  );
};

export default NewEvent;
