import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import createNetwork from "redux/thunks/networks/createUpdateNetwork";
import { Client } from "types/model/Client";
import { Option } from "types/option";
import AddNewNetworkComponent from "layout/AddNewNetwork";

const AddNewNetwork = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState<Record<string, any>>({});
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [clientOptions, setClientOptions] = useState<Option[]>([]);

  const {
    data: clients,
    affiliateNetworks,
    createNetwork: createNetworkState,
  } = useAppSelector(
    ({
      getClients: { data },
      affiliateNetworks: {
        data: { items: affiliateNetworks },
      },
      createNetwork,
    }) => ({
      data,
      affiliateNetworks,
      createNetwork,
    }),
  );

  const onSelectedClientChange:ChangeEventHandler<HTMLInputElement> = e => {
    const { checked, name, value } = e.target;
    setClientOptions((opts) =>
      opts.map((v) =>
        v.value === value ? { ...v, selected: checked } : v,
      ),
    );

    const updatedClients = checked
      ? [
          ...form.clients,
          ...clients.items.filter(
            (client: Client) => String(client.id) === String(name),
          ),
        ]
      : form.clients.filter(
          (client: Client) => String(client.id) !== String(name),
        );

    setForm((form) => ({ ...form, clients: updatedClients }));
  };

  const onChange = ({ target }: any) => {
    const { name, value } = target;

    if (name === "pids") {
      return setForm((form) => ({
        ...form,
        pids: { ...form.pids, ...value },
      }));
    }

    if (name === "network") {
      const matchedNetwork = affiliateNetworks.find(
        (it) => it?.network_id === value,
      );

      if (matchedNetwork) {
        setRequiredFields(matchedNetwork?.required_fields);
      }
    }
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    const selectedNetwork = affiliateNetworks.find(
      (network) => network?.network_id === form.network,
    );

    if (
      selectedNetwork?.required_fields.every(
        (it) => Object.hasOwn(form, it) && !!form[it],
      )
    ) {
      dispatch(
        createNetwork({
          data: {
            ...form,
            clients: form.clients.map((it: Client) => it.id),
          },
        }),
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          toast.success("Network added successfully");
          navigate(`/settings/networks/clients`);
        }
      });
    }
  };

  useEffect(() => {
    if (affiliateNetworks.length) {
      onChange({
        target: {
          name: "network",
          value: affiliateNetworks[0]?.network_id,
        },
      });
    }
  }, [affiliateNetworks.length]);

  useEffect(() => {
    if (clients.items.length) {
      const allClients = clients.items.map((item) => ({
        label: item.name,
        value: item.id,
        selected: false,
      }));
      setClientOptions(allClients);
    }
  }, [clients.items.length]);

  useEffect(() => {
    if (clients.items.length) {
      onChange({
        target: {
          name: "clients",
          value: [clients.items[0]],
        },
      });

      const allClients = clients.items.map((item, idx) => ({
        label: item.name,
        value: item.id,
        selected: idx === 0,
      }));
      setClientOptions(allClients);
    }
  }, [clients.items.length]);

  return (
    <AddNewNetworkComponent
      clientOptions={clientOptions}
      affiliateNetworks={affiliateNetworks}
      onSubmit={onSubmit}
      onChange={onChange}
      onSelectedClientChange={onSelectedClientChange}
      requiredFields={requiredFields}
      createNetworkState={createNetworkState}
      form={form}
    />
  );
};

export default AddNewNetwork;
