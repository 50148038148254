import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCategoryStoreSpotlights from "redux/thunks/app/placements/campaigns/getCategoryStoreSpotlights";

export type ActionStateType = {
  data: Record<string, any>[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getCategoryStoreSpotlightsSlice = createSlice({
  name: "getCategoryStoreSpotlights",
  initialState,
  reducers: {
    clearSpotlightResults: () => {
      return initialState;
    },

    updateCategoryStoreSpotlight: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((el) => (el.id === payload.id ? payload : el)),
      };
    },

    removeCategoryStoreSpotlight: (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter((el) => el.category.id !== payload.category.id),
      };
    },

    setCategoryStoreSpotlights: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },

    addToCategoryStoreSpotlights: (state, { payload }) => {
      return {
        ...state,
        data: [payload, ...state.data],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryStoreSpotlights.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        getCategoryStoreSpotlights.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getCategoryStoreSpotlights.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const {
  clearSpotlightResults,
  updateCategoryStoreSpotlight,
  addToCategoryStoreSpotlights,
  setCategoryStoreSpotlights,
  removeCategoryStoreSpotlight,
} = getCategoryStoreSpotlightsSlice.actions;
export default getCategoryStoreSpotlightsSlice.reducer;
