import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { FormEventHandler, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import getClients from "redux/thunks/app/getClients";
import CampaignSettingCreateUpdateComponent from "layout/marketing/email/CampaignSettingCreateUpdateComponent";
import { toast } from "react-hot-toast";
import createEmailCampaignSetting from "redux/thunks/marketing/emails/createEmailCampaignSetting";
import getCampaignSetting from "redux/thunks/marketing/emails/getCampaignSetting";

const CampaignSettingCreateUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const location = useLocation();
  const initialState = location.state?.setting;

  const actionState = useAppSelector(
    (state) => state.createEmailCampaignSetting
  );
  const getClientsData = useAppSelector((state) => state.getClients);
  const [form, setForm] = useState<Record<string, any>>({
    client: null,
    location: null,
  });

  const onChange = ({ target }: any) => {
    const { name, value } = target;
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    dispatch(
      createEmailCampaignSetting({
        ...form,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (form.id) {
          toast.success("Changes saved");
        } else {
          toast.success("Setting has been created.");
          navigate("/marketing/email?tab=4");
        }
      }
    });
  };

  useEffect(() => {
    dispatch(getClients({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (initialState) {
      setForm((f) => ({
        ...f,
        ...initialState,
        client: initialState.client.id,
      }));
    }
  }, []);

  useEffect(() => {
    if (params.has("setting")) {
      dispatch(getCampaignSetting(params.get("setting") || "")).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setForm((f) => ({
            ...f,
            ...res.payload,
            client: res.payload.client.id,
          }));
        }
      });
    }
  }, [params]);

  const handleDeleteSetting = () => {
    dispatch(
      createEmailCampaignSetting({
        ...form,
        delete: true,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Setting has been deleted.");
        navigate("/marketing/email?tab=4");
      }
    });
  };

  return (
    <CampaignSettingCreateUpdateComponent
      actionState={actionState}
      onSubmit={onSubmit}
      onChange={onChange}
      setForm={setForm}
      handleDeleteSetting={handleDeleteSetting}
      form={form}
      getClientsData={getClientsData}
    />
  );
};

export default CampaignSettingCreateUpdate;
