import BrowserExtStoreExclusions from "containers/marketing/extension/BrowserExtStoreExclusions";
import BrowserExtNetworkExclusions from "containers/marketing/extension/BrowserExtNetworkExclusions";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

const BrowserExtExclusionsLayout = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const options = [
    {
      text: "Stores",
      content: <h1>Stores</h1>,
      onClick: () => {
        setSelectedTab(1);
      },
    },

    {
      text: "Networks",
      content: <h1>Networks</h1>,
      onClick: () => {
        setSelectedTab(2);
      },
    },
  ];

  return (
    <div className="py-4">
      <div className="flex  justify-center">
        <div>
          {options.map((el, idx) => (
            <button
              onClick={el.onClick}
              key={el.text}
              className={twMerge(
                "text-sm px-4 py-2 hover:bg-gray-200  bg-gray-100 mx-2 rounded-full ",
                selectedTab == idx + 1 && "text-primary-700",
                selectedTab == idx + 1 &&
                  "ring-primary-700 text-primary-700 bg-gray-100  focus:z-10 focus:ring-2   dark:border-gray-600"
              )}
            >
              {el.text}
            </button>
          ))}
        </div>
      </div>

      {selectedTab === 1 && <BrowserExtStoreExclusions />}
      {selectedTab === 2 && <BrowserExtNetworkExclusions />}
    </div>
  );
};

export default BrowserExtExclusionsLayout;
