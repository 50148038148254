import React from "react";
import "./index.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "config/i18n";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "App";
import { store } from "redux/store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import TimeAgo from "javascript-time-ago";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// English.
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
export const timeAgo = new TimeAgo("en-US");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <App />
        <Toaster
          toastOptions={{
            duration: 5000,
            position: "bottom-center",
          }}
        />
      </DndProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
