import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import Label from "components/common/Label";
import DataTable from "components/common/layout/DataTable";
import Layout from "components/layout/Layout";
import countries from "constants/operationCountries";
import SearchCoupons from "containers/oma/SearchCoupons";
import SearchPrograms from "containers/oma/SearchPrograms";
import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { ActionState } from "types/data";
import { Client } from "types/model/Client";
import SimpleCouponRow from "./SimpleCouponRow";
import Modal from "components/common/Modal";
import DraggableRow from "components/common/DraggableRow";
import Thead from "components/common/layout/Thead";
import { SIMPLE_COUPON_COLUMNS } from "constants/tables/headers/oma/coupons";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  handleFormSubmit: FormEventHandler<HTMLFormElement>;
  form: Record<string, any>;
  error: Record<string, any>;
  clients: Client[];
  onFeaturedStoreResultClicked: (item: Record<string, any>) => void;
  onFeatureCouponResultClicked: (item: Record<string, any>) => void;
  addCoupon: (item: Record<string, any>) => void;
  createEmailCampaignData: ActionState;
  removeCoupon: (k: string, coupon: Record<string, any>) => void;
  updateCoupon: (coupon: Record<string, any>) => void;
  moveDraggableRow: (dragIndex: number, hoverIndex: number) => void;
  sortedItems: Record<string, any>[];
};

const CreateEmailCampaignComponent = ({
  onChange,
  handleFormSubmit,
  form,
  error,
  clients,
  onFeaturedStoreResultClicked,
  onFeatureCouponResultClicked,
  createEmailCampaignData,
  addCoupon,
  removeCoupon,
  updateCoupon,
  moveDraggableRow,
  sortedItems,
}: Props) => {
  const [open, setAddOpen] = useState(false);
  const [addCouponIntent, setAddCouponIntent] = useState("featured_coupon");
  const [couponResult, setCouponResult] = useState<null | Record<string, any>>(
    null
  );

  return (
    <>
      <Modal
        open={open}
        variant="default"
        onConfirm={() => {
          if (couponResult) {
            if (addCouponIntent === "featured_coupon") {
              onFeatureCouponResultClicked(couponResult);
            } else {
              addCoupon(couponResult);
            }
          }

          setAddOpen(false);
        }}
        doneText="Save"
        setOpen={setAddOpen}
      >
        <div className="my-4 ">
          <SearchPrograms
            wrapperClassName="mx-0"
            client_id={form.client}
            searchParams={
              form.location && {
                location: form.location,
              }
            }
            onResultClicked={(rs) => {
              onFeaturedStoreResultClicked(rs);
            }}
          />

          <SearchCoupons
            wrapperClassName=" mx-0 my-4 "
            store={form.featured_store}
            value=""
            onResultClicked={(result) => {
              setCouponResult(result);
            }}
          />
        </div>
      </Modal>
      <Layout>
        <div className="relative p-4  dark:bg-gray-800 sm:p-5">
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {form.id ? "Edit Campaign" : "Create Campaign"}
            </h3>

            <Button
              text={form.id ? "Save Changes" : "Save Campaign"}
              onClick={handleFormSubmit}
              disabled={createEmailCampaignData.loading}
              loading={createEmailCampaignData.loading}
            />
          </div>

          <div>
            <div className="flex flex-wrap gap-4 pb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <div className="flex-1">
                <Input
                  label="Date"
                  name="date"
                  // id="date"
                  type="date"
                  value={form.date}
                  onChange={onChange}
                  // required
                  error={error?.date}
                />
              </div>
              <div className="flex-1">
                <Dropdown
                  label="Client"
                  name="client"
                  options={clients.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  onChange={onChange}
                  required
                  value={form.client}
                />
              </div>

              <div className="flex-1">
                <Dropdown
                  label="Location"
                  options={countries.map((el) => ({
                    label: el,
                    value: el.toLowerCase(),
                  }))}
                  onChange={onChange}
                  name="location"
                  value={form.location}
                  error={error?.location}
                />
              </div>
            </div>

            <div className="my-4 border-b pb-5 dark:border-gray-600">
              <Label label="Campaign Content" />

              <div className="flex gap-4">
                <div className="mb-4 flex-1">
                  <Input
                    label="Email Subject"
                    name="email_subject"
                    onChange={onChange}
                    required
                    value={form.email_subject}
                  />
                </div>
                <div className="mb-4 flex-1">
                  <Input
                    label="Email Preview Text"
                    name="email_preview_text"
                    onChange={onChange}
                    required
                    value={form.email_preview_text}
                  />
                </div>
              </div>
            </div>

            <div className="my-4 border-b pb-5 dark:border-gray-600">
              <div className="flex gap-8">
                <div className="flex-1">
                  <DataTable
                    header={
                      <div className="flex justify-between my-2 border-b dark:border-gray-600 px-4">
                        <Label label="Featured Coupon" />

                        <Button
                          text="Add coupon"
                          className="text-xs"
                          onClick={() => {
                            setAddCouponIntent("featured_coupon");
                            setAddOpen(true);
                            // setFeaturedCouponSubmitted(true);
                          }}
                        />
                      </div>
                    }
                    rows={[form.coupon_info]}
                    rowRenderer={(coupon) => (
                      <SimpleCouponRow
                        coupon={coupon}
                        shoulDrag={false}
                        removeItemHandler={() => {
                          removeCoupon("coupon_info", coupon);
                        }}
                      />
                    )}
                    columns={SIMPLE_COUPON_COLUMNS}
                  />

                  <Input
                    label="Headline"
                    name="custom_coupon_headline"
                    onChange={onChange}
                    required
                    wrapperClassName="mb-4"
                    value={form.custom_coupon_headline}
                  />

                  <Input
                    label="Description"
                    name="custom_coupon_description"
                    onChange={onChange}
                    required
                    value={form.custom_coupon_description}
                  />
                </div>
              </div>
            </div>

            <div className="my-4 border-b pb-5 dark:border-gray-600">
              <div className="flex gap-5">
                <div className="flex-1">
                  <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
                    <div className="flex justify-between my-2 border-b dark:border-gray-600 px-4">
                      <Label label="Coupons" />

                      <Button
                        text="Add coupon"
                        className="text-xs"
                        onClick={() => {
                          setAddCouponIntent("coupons");
                          setAddOpen(true);
                        }}
                      />
                    </div>
                    <div className="overflow-x-auto">
                      <table className="w-full text-sm text-left text-gray-500">
                        <Thead
                          columns={SIMPLE_COUPON_COLUMNS}
                          columnProps={{}}
                        />

                        <tbody>
                          {sortedItems?.map(
                            (item: Record<string, any>, idx: number) => (
                              <DraggableRow
                                key={idx}
                                index={idx}
                                id={idx}
                                row={item}
                                rowClassName="border-none "
                                renderRow={(row) => (
                                  <SimpleCouponRow
                                    allowEdit
                                    shoulDrag
                                    coupon={row}
                                    updateCoupon={updateCoupon}
                                    removeItemHandler={() => {
                                      removeCoupon("coupons", row);
                                    }}
                                  />
                                )}
                                moveDraggableRow={moveDraggableRow}
                              />
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CreateEmailCampaignComponent;
