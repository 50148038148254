import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createEvent from "redux/thunks/sales_crm/createEvent";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const createEventSlice = createSlice({
  name: "createEvent",
  initialState,
  reducers: {
    resetCreateEvent: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        createEvent.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        createEvent.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetCreateEvent } = createEventSlice.actions;
export default createEventSlice.reducer;
