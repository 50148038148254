import { ChangeEvent, ChangeEventHandler, useState } from "react";
import Input from "./Input";
import OutsideClickHandler from "react-outside-click-handler";
import { ArrowDownCircleIcon, ArrowDownIcon } from "@heroicons/react/20/solid";
import { DownChevron } from "react-select/dist/declarations/src/components/indicators";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

type Props = {
  label?: string;
  name: string;
  value?: string;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  options: {
    label: string;
    value: string;
    selected?: boolean;
  }[];
  renderAll?: boolean;
  className?: string;
  wrapperClassName?: string;
  loading?: boolean;
  error?: string;
};

function MultipleSelectionInput({
  renderAll,
  label,
  name,
  onChange,
  className,
  wrapperClassName,
  placeholder,
  options,
  loading,
  error,
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const [searchText, setSearchText] = useState("");

  const value = renderAll
    ? options
        .filter((it) => it.selected)
        .map((it) => it.label)
        .join(",")
    : options.find((s) => s.selected)?.label;

  return (
    <div className={wrapperClassName}>
      <Input
        name={name}
        inputContainerClass={className}
        className={className}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        itemsLoading={loading}
        onFocus={() => {
          setIsVisible((val) => !val);
        }}
        onBlur={() => {
          // setIsVisible(false);
        }}
        label={label}
        error={error}
        rightIcon={<ChevronDownIcon height={12} />}
      />
      {isVisible && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsVisible(false);
          }}
        >
          <div
            className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200
          absolute
          bg-white
          shadow-lg
          z-10"
          >
            <Input
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              inputContainerClass="h-10"
              placeholder="Filter options"
            />
            <ul aria-labelledby="dropdownHelperButton">
              <div className="max-h-96 overflow-scroll">
                {options
                  .filter((it) => it.label.toLowerCase().startsWith(searchText))
                  .map((item) => (
                    <li key={item.value}>
                      <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <div className="flex items-center h-5">
                          <input
                            onChange={(e) => {
                              onChange({
                                ...e,
                                target: {
                                  ...e.target,
                                  name: name,
                                  value: item.value,
                                  checked: e.target.checked,
                                },
                                currentTarget: {
                                  name: name,
                                  value: item.value,
                                  checked: e.target.checked,
                                },
                              } as ChangeEvent<HTMLInputElement>);
                            }}
                            id="helper-checkbox-3"
                            aria-describedby="helper-checkbox-text-3"
                            type="checkbox"
                            checked={item.selected}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                        </div>
                        <div className="ml-2 text-sm">
                          <span className="font-medium text-gray-900 dark:text-gray-300">
                            <p className="text-xs font-normal text-gray-500 dark:text-gray-300">
                              {item.label}
                            </p>
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
              </div>

              <p
                className="cursor-pointer text-center"
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                close
              </p>
            </ul>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
}

export default MultipleSelectionInput;
