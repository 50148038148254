import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import paginatedItemsInitialState from "redux/initial-states/paginatedItemsInitialState";
import getNetworks from "redux/thunks/networks/getNetworks";
import { PaginatedActionState } from "types/data";
import { Network } from "types/model/Network";

const initialState: PaginatedActionState<Network> = paginatedItemsInitialState;

const getNetworksSlice = createSlice({
  name: "networks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNetworks.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getNetworks.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              loading: false,
              data: payload,
            };
          }
        },
      )
      .addCase(
        getNetworks.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});

export default getNetworksSlice.reducer;
