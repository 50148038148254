import { Proposal } from "types/model/sales/Proposal";
import { GrDocumentPdf } from "react-icons/gr";
import { BACKEND_BASE_URL } from "config/env";
import { Link } from "react-router-dom";
import dateformat from "dateformat";
import formatNumber from "utils/strings/formatNumber";
import PopUnder from "components/common/PopUnder";
import DeleteButton from "components/common/DeleteButton";
import { ActionState } from "types/data";

type Props = {
  proposal: Proposal;
  updateStatus: (p: Proposal, status: string) => void;
  deleteProposal: (proposal: Proposal, delete_reason: string) => void;
  createUpdateDeleteProposalData: ActionState;
};

const ProposalRow = ({
  proposal,
  updateStatus,
  deleteProposal,
  createUpdateDeleteProposalData,
}: Props) => {
  return (
    <tr key={proposal.id}>
      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
        {dateformat(proposal.created_at, "mm/dd/yyyy")}
      </td>{" "}
      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
        {proposal.proposal_id}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
        {proposal.created_by.first_name + " " + proposal.created_by.last_name}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {proposal.client_merchant_config?.[0]?.program_name}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {proposal.locations.map((el) => el.toUpperCase()).join(",")}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {proposal.proposal_type?.name}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        <PopUnder
          publishingOptions={[
            {
              title: "Pending",
              current: proposal.proposal_status === "Pending",
              displayValue: "Pending",
              visible: true,
              onClick: () => {
                updateStatus(proposal, "Pending");
              },
            },

            {
              title: "Approved",
              current: proposal.proposal_status === "Approved",
              displayValue: "Approved",
              visible: true,
              onClick: () => {
                updateStatus(proposal, "Approved");
              },
            },

            {
              title: "Declined",
              current: proposal.proposal_status === "Declined",
              displayValue: "Declined",

              visible: true,
              onClick: () => {
                updateStatus(proposal, "Declined");
              },
            },
          ]}
        />
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {proposal.proposal_status === "Approved" ? proposal.io_id : ""}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {proposal.currency_symbol}
        {formatNumber(proposal.final_amount, { decimals: 2 })}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-xs font-medium text-gray-900 ">
        <div className="flex items-center gap-4">
          <Link
            target="_blank"
            to={`${BACKEND_BASE_URL}/admin/sales/proposals/pdf-download?id=${proposal.id}&resource=proposal`}
          >
            <GrDocumentPdf />
          </Link>
          <Link
            to={`/sales/proposals/add?slug=${proposal.program_slug || ""}&id=${
              proposal.id
            }&viewing=true`}
          >
            View
          </Link>
          <DeleteButton
            deleteLoading={createUpdateDeleteProposalData.loading}
            text="X"
            allowReason
            onClick={(reason) => {
              deleteProposal(proposal, reason || "");
            }}
          />
        </div>
      </td>
    </tr>
  );
};

export default ProposalRow;
