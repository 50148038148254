import SearchBar from "components/program/SearchBar";
import { Card } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { debounce } from "lodash";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { clearSearchCouponResults } from "redux/slices/clients/coupons/searchActiveCoupons";
import { clearStoreSearchResults } from "redux/slices/clients/searchActivePrograms";
import searchActiveCoupons from "redux/thunks/app/api-clients/coupons/searchActiveCoupons";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";
import { twMerge } from "tailwind-merge";

type Props = {
  onResultClicked?: (result: Record<string, any>) => void;
  wrapperClassName?: string;
  label?: string;
  value?: string;
  store: Record<string, any>;
};

const SearchCoupons = ({
  onResultClicked,
  label,
  value,
  wrapperClassName,
  store,
}: Props) => {
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState("");
  const [suggestedCoupons, setSuggestedCoupons] = useState<
    Array<Record<string, any>>
  >([]);
  const {
    searchActiveCoupons: { data },
    getActiveCoupons: getActiveCouponsData,
  } = useAppSelector(({ searchActiveCoupons, getActiveCoupons }) => ({
    searchActiveCoupons,
    getActiveCoupons,
  }));

  const debounceFn = useCallback(
    debounce((e) => {
      if (e.target.value.length) {
        dispatch(searchActiveCoupons({ id_search_text: e.target.value }));
      } else {
        dispatch(clearStoreSearchResults());
      }
    }, 1000),
    []
  );

  const onResultClick = (res: Record<string, any>) => {
    setSearchText(res.title);
    if (typeof onResultClicked == "function") {
      return onResultClicked(res);
    }
  };

  const onResultsOutsideClick = () => {
    dispatch(clearSearchCouponResults());
  };

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
    debounceFn(e);
  };
  const onSearchSubmit = () => {
    dispatch(searchActiveCoupons({ query: searchText }));
  };

  useEffect(() => {
    if (!value) {
      setSearchText("");
    }
  }, [value]);

  useEffect(() => {
    if (store?.slug) {
      dispatch(getActiveCoupons({ store_slug: store.slug, page: 1 }));
    }
  }, [store]);

  useEffect(() => {
    if (getActiveCouponsData.data.results) {
      const firstCoupon = getActiveCouponsData.data.results.reduce(
        (firstSorted, current) => {
          const firstSortedCoupon = parseInt(firstSorted.sorting_for_store, 10);
          const sortedCoupon = parseInt(current.sorting_for_store, 10);

          if (!isNaN(sortedCoupon) && sortedCoupon < firstSortedCoupon) {
            return current;
          }

          return firstSorted;
        },
        getActiveCouponsData.data.results[0]
      );

      if (firstCoupon) {
        onResultClick(firstCoupon);
      }
    }
  }, [getActiveCouponsData.data.results]);

  useEffect(() => {
    dispatch(clearSearchCouponResults());
    setSearchText("");
  }, []);

  return (
    <SearchBar
      onResultClick={onResultClick}
      onResultsOutsideClick={onResultsOutsideClick}
      results={suggestedCoupons.length ? suggestedCoupons : data.results}
      searchLoading={getActiveCouponsData.loading}
      placeholder="Search coupons"
      onChange={onSearchChange}
      onFocus={() => {
        setSuggestedCoupons(getActiveCouponsData.data.results);
      }}
      value={searchText}
      wrapperClass={twMerge("mx-4", wrapperClassName)}
      onSearchSubmit={onSearchSubmit}
      label={label}
      renderResults={(results: Array<Record<string, any>>) => (
        <div className="absolute z-10 mt-1 max-h-60  w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {results.map((el: Record<string, any>) => (
            <Card
              key={el.id}
              onClick={() => {
                onResultClick(el);
                onResultsOutsideClick();
                setSuggestedCoupons([]);
              }}
            >
              <h5>{el.title}</h5>
            </Card>
          ))}
        </div>
      )}
      renderLocation
    />
  );
};

export default SearchCoupons;
