import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getDeviceTypes from "redux/thunks/marketing/sem/getDeviceTypes";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getDeviceTypesSlice = createSlice({
  name: "getDeviceTypes",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceTypes.pending, (state) => {
        return {
          ...state,
          error: null,
          loading: true,
        };
      })
      .addCase(
        getDeviceTypes.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      )

      .addCase(
        getDeviceTypes.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export default getDeviceTypesSlice.reducer;
