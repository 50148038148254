import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import CampaignSettingRow from "components/marketing/CampaignSettingRow";
import EMAIL_CAMPAIGN_SETTINGS_COLUMNS from "constants/tables/headers/marketing/campaignSettings";
import { PaginatedActionState } from "types/data";
import { EmailCampaignSetting } from "types/model/NewsletterSubscriber";

type Props = {
  getCampaignSettingsData: PaginatedActionState<EmailCampaignSetting>;
  onPageChange: (page: number) => void;
  currentPage: number;
  navigateToCreateSetting: () => void;
  navigateToSetting: (item: EmailCampaignSetting) => void;
};

const CampaignSettings = ({
  getCampaignSettingsData: { loading, data },
  onPageChange,
  currentPage,
  navigateToCreateSetting,
  navigateToSetting,
}: Props) => {
  const renderRow: RowRenderer<EmailCampaignSetting> = (row) => {
    return (
      <CampaignSettingRow row={row} navigateToSetting={navigateToSetting} />
    );
  };

  return (
    <DataTable
      header={
        <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
          <div className="flex items-center flex-1 space-x-4 justify-between px-4 my-4">
            <h5>
              {/* <span className="text-gray-500">All Items:</span>
              <span className="text-sm font-medium">{data.meta.count}</span> */}
            </h5>
            <button
              type="button"
              onClick={navigateToCreateSetting}
              className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              <svg
                className="h-3.5 w-3.5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                />
              </svg>
              Create Campaign Setting
            </button>
          </div>
        </div>
      }
      columns={EMAIL_CAMPAIGN_SETTINGS_COLUMNS}
      rows={data.items}
      currentPage={currentPage}
      loading={loading}
      totalCount={data.meta.count}
      onPageChange={onPageChange}
      rowRenderer={renderRow as RowRenderer<Record<string, any>>}
    />
  );
};

export default CampaignSettings;
