import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Input from "./Input";
import LabelBadge from "components/program/LabelBadge";
import FaqTextArea from "./FaqTextArea";

type Props = {
  faqs?: Record<string, any>[];
  onFormChange: ChangeEventHandler<HTMLInputElement>;
  onFormSubmit?: () => void;
  saveBtnLoading?: boolean;
  saveInitiator?: string;
  setSaveInitiator?: Dispatch<SetStateAction<string>>;
  renderInputOptions?: (form: Record<string, any>, input: any) => JSX.Element;
  regeneratedAnswer?: Record<string, any>;
  faqsForm?: Record<string, any>;
};
function DynamicFAQInput({
  onFormChange,
  faqs,
  onFormSubmit,
  saveBtnLoading,
  saveInitiator,
  renderInputOptions,
  setSaveInitiator,
  regeneratedAnswer,
  faqsForm,
}: Props) {
  const initialState: Record<string, any> = {};

  if (faqs?.length) {
    faqs.forEach((it, idx) => {
      initialState[`Question ${idx + 1}`] = it.question;
      initialState[`id ${idx + 1}`] = it.id;
      initialState[`is_ai_generated ${idx + 1}`] = it.is_ai_generated;
      initialState[`Answer ${idx + 1}`] = it.answer;
    });
  }

  const inputInitialState = {
    name: "0",
    qnValue: "",
    ansValue: "",
    id: new Date().getTime().toString(),
    is_ai_generated: false,
  };

  const [fieldsForm, setFieldsForm] = useState({
    inputs: [inputInitialState],
    form: initialState,
  });
  useEffect(() => {
    onFormChange({
      target: {
        name: "faqsForm",
        value: fieldsForm.form,
      },
    } as ChangeEvent<any>);
  }, [fieldsForm.form]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setFieldsForm((prev) => ({
      ...prev,
      form: { ...prev.form, [name]: value },
    }));
  };

  const handleAddInput = () => {
    setFieldsForm((prev) => ({
      ...prev,
      inputs: [
        ...prev.inputs,
        {
          is_ai_generated: false,
          name: String(fieldsForm.inputs.length),
          qnValue: "",
          ansValue: "",
          id: new Date().getTime().toString(),
        },
      ],
    }));
  };

  const handleRemoveInput = (idx: number) => {
    const {
      [`Question ${idx}`]: matched,
      [`Answer ${idx}`]: matchedAns,
      ...rest
    } = fieldsForm.form;

    setFieldsForm((prev) => ({
      ...prev,
      form: rest,
      inputs: prev.inputs.filter((input, index) => index + 1 !== idx),
    }));
  };

  useEffect(() => {
    if (faqs?.length) {
      setFieldsForm((prev) => ({
        ...prev,
        inputs: faqs.map((faq, index) => ({
          ...faq,
          is_ai_generated: faq.is_ai_generated,
          name: String(index),
          qnValue: faq.question,
          ansValue: faq.answer,
          id: faq.question + new Date().getTime().toString(),
        })),
      }));
    }
  }, []);

  useEffect(() => {
    if (regeneratedAnswer && Object.keys(regeneratedAnswer).length == 1) {
      const key = Object.keys(regeneratedAnswer)[0];
      const value = Object.values(regeneratedAnswer)[0];
      setFieldsForm((f) => ({
        ...f,
        form: {
          ...f.form,
          [`Answer ${key}`]: value,
        },
      }));
    }
  }, [regeneratedAnswer]);
  const faqKeyCount = (faqsForm && Object.keys(faqsForm).length) || 0;
  useEffect(() => {
    if (faqKeyCount > 0 && faqsForm) {
      setFieldsForm((f) => ({
        ...f,
        inputs: Object.keys(faqsForm)
          .filter((el) => el.includes("Question"))
          .map((faq, index) => ({
            is_ai_generated: false,
            name: String(index),
            qnValue: "",
            ansValue: "",
            id: index + new Date().getTime().toString(),
          })),
        form: faqsForm,
      }));
    }
  }, [faqKeyCount]);

  console.log("faqsForm", faqsForm);

  return (
    <div>
      {fieldsForm.inputs.map((input, index) => (
        // <div className="flex items-center gap-4 mb-8" key={input.id}>
        <div className="flex flex-col gap-4 mb-8" key={input.id}>
          <div className="flex-1">
            <Input
              label={`Question ${index + 1}`}
              rightLabel={
                <>
                  {input.is_ai_generated ? (
                    <div className="ml-2 mb-2">
                      <LabelBadge text="AI Generated" />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              }
              onChange={onChange}
              name={`Question ${index + 1}`}
              value={fieldsForm.form[`Question ${index + 1}`]}
            />
          </div>

          <div className="flex-1">
            <FaqTextArea
              onChange={onChange}
              fieldsForm={fieldsForm}
              index={index}
              saveBtnLoading={saveBtnLoading && saveInitiator == String(index)}
              onFormSubmit={() => {
                onFormSubmit?.();
                setSaveInitiator?.(index.toString());
              }}
            />
          </div>
          {fieldsForm.inputs.length > 0 && (
            <div className="flex items-center">
              <small
                className="cursor-pointer"
                onClick={() => handleRemoveInput(index + 1)}
              >
                Remove
              </small>
              {renderInputOptions?.(fieldsForm, { ...input, index: index + 1 })}
            </div>
          )}
        </div>
      ))}

      {
        <small className="cursor-pointer block mb-4" onClick={handleAddInput}>
          Add {fieldsForm.inputs.length ? "another question" : "a question"}
        </small>
      }
    </div>
  );
}

export default DynamicFAQInput;
