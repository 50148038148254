import { useState } from "react";
import { RiCheckboxMultipleBlankLine } from "react-icons/ri";
import OutsideClickHandler from "react-outside-click-handler";
import { twMerge } from "tailwind-merge";
import SubBrands from "./program/SubBrands";
import { useNavigate } from "react-router-dom";

type Props = {
  data: Record<string, any>;
};

const SubBrandsDropDown = ({ data }: Props) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const onItemClick = (item: Record<string, any>) => {
    const url = new URL(window.location.href);
    url.searchParams.set("slug", item.slug);

    navigate(url.search);

    setOpen(false);
  };

  return (
    <div className="relative">
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        <div
          className={twMerge("relative cursor-pointer")}
          onClick={() => {
            setOpen((value) => !value);
          }}
        >
          <RiCheckboxMultipleBlankLine size={36} />
          <span className="absolute left-2.5 top-3 z-10 text-sm">
            {data.related_mapping_count}
          </span>
        </div>
        {open && (
          <div className="absolute right-0 left-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20">
            <SubBrands
              onItemClick={(res) => {
                onItemClick(res);
              }}
            />
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default SubBrandsDropDown;
