import Spinner from "components/common/layout/Spinner";
import CategorySelector from "components/common/CategorySelector";
import newsLetterPageTypeOptions from "constants/pricing-plans/newsLetterPageTypeOptions";
import Label from "components/common/Label";
import Dropdown from "components/common/Dropdown";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import { PricingPlan } from "types/model/PricingPlan";
import getPriceItems from "./getPriceItems";
import getAvailablePlacementTypes from "./getAvailablePlacementTypes";
import { ActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";
import DateRangeSelector from "components/common/DateRange";
import clientPlacementTypeMap from "constants/pricing-plans/placementTypeOptions";
import clientPagesMap from "constants/pricing-plans/pageOptions";
import { excludedCreateProposalPages } from "constants/pricing-plans/excludedPlacementTypes";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getPositionAvailabilityStatuses from "redux/thunks/sales/getPositionAvailabilityStatuses";
import PositionItems from "./PositionItems";
import toast from "react-hot-toast";
import { Option } from "types/option";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";

type Props = {
  form: Record<string, any>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  getCategoriesData: ActionState<ProgramCategory[]>;
  setPlacements: Dispatch<SetStateAction<Record<string, any>[]>>;
  placements: Array<Record<string, any>>;
  onPlacementAdded?: () => void;
};

const LineItemsSelector = ({
  form,
  setForm,
  onChange,
  getCategoriesData,
  setPlacements,
  placements,
  onPlacementAdded,
}: Props) => {
  const [formPage, setFormPage] = useState(form.page);
  const dispatch = useAppDispatch();

  const startDate = dayjs(form.start_date || dayjs().toDate()).format(
    "YYYY-MM-DD"
  );
  const endDate = dayjs(form.end_date || dayjs().toDate()).format("YYYY-MM-DD");

  const placementTypeOptions =
    clientPlacementTypeMap[String(form.client)] || [];

  const start_date = dayjs(form.start_date).format("YYYY-MM-DD");
  const end_date = dayjs(form.end_date).format("YYYY-MM-DD");
  const getPositionAvailabilityStatusesData = useAppSelector(
    (state) => state.getPositionAvailabilityStatuses
  );
  const getClientsData = useAppSelector((state) => state.getClients);

  const priceItem = getPositionAvailabilityStatusesData.data?.find(
    (it: PricingPlan) =>
      String(it.client.id) === String(form.client) &&
      it.location.toLowerCase() === form.location?.toLowerCase()
  ) as PricingPlan;

  const availablePlacementTypes = getAvailablePlacementTypes(
    placementTypeOptions,
    placements,
    form,
    formPage
  );
  const itemsWpositionStatuses = getPriceItems(
    priceItem?.prices,
    form,
    formPage,
    getCategoriesData?.data?.find((it) => it.slug === form.category)?.level || 0
  );

  const pageOptions = form.client
    ? clientPagesMap[String(form.client)]?.filter(
        (it) => !excludedCreateProposalPages.includes(it)
      ) || []
    : [];

  useEffect(() => {
    if (form.client) {
      dispatch(getCategories({ client: form.client }));
    }
  }, [form.client]);

  useEffect(() => {
    if (form.category && getCategoriesData.data.length > 0) {
      const pageName = getCategoriesData.data?.find(
        (el) => el.slug === form.category
      )?.name;
      if (pageName) {
        setFormPage(pageName);
      }
    }
  }, [form.category && getCategoriesData.data.length]);

  useEffect(() => {
    if (
      formPage &&
      form.placement_type &&
      form.client &&
      form.start_date &&
      form.location &&
      form.end_date
    ) {
      dispatch(
        getPositionAvailabilityStatuses({
          page: formPage,
          placement_type: form.placement_type,
          client: form.client,
          start_date: startDate,
          end_date: endDate,
          location: form.location?.toLowerCase(),
        })
      );
    }
  }, [
    formPage,
    form.location,
    form.placement_type,
    form.client,
    form.start_date,
    form.end_date,
    form.category,
  ]);

  useEffect(() => {
    if (form.category && form.page !== "Custom") {
      setForm((form) => ({ ...form, page: "Custom" }));
    }
  }, [form.category, form.page]);

  useEffect(() => {
    if (!formPage && form.page) {
      setFormPage(form.page);
    }
  }, [formPage, form.page]);

  const handleSelectPosition = (element: Record<string, any>) => {
    setForm((f) => ({
      ...f,
      position: element.position,
      amount: element.price,
      price_id: `${start_date}_${end_date}_${element.page}_${element.price_id}`,
    }));
  };
  const resetForm = () => {
    setForm((f) => ({
      ...f,
      page: null,
      placement_type: null,
      position: null,
      amount: null,
      price_id: null,
      positions: [],
      categories: form.categories.map((it: Option) => ({
        ...it,
        selected: false,
      })),
    }));
  };

  const handleSetPlacements = () => {
    const selectedClient = getClientsData.data.items?.find(
      (it) => String(it.id) === String(form.client)
    );

    if (
      form.placement_type &&
      form.position &&
      form.page &&
      selectedClient &&
      form.program &&
      form.location
    ) {
      const lineItem = {
        id: form.placement_item_id || new Date().getTime(),
        price_id: form.price_id,
        start_date: startDate,
        end_date: endDate,
        position: form.position,
        amount: form.amount,
        page: formPage,
        placement_type: form.placement_type,
        page_type: form.page_type,
        positions: form.positions,
        category: form.category,
        client: selectedClient,
        program: form.program,
        location: form.location,
      };

      if (form.placement_item_id) {
        setPlacements((values) =>
          values.map((placement) =>
            placement.id === form.placement_item_id ? lineItem : placement
          )
        );
      } else {
        setPlacements((values) => [...values, lineItem]);
      }

      onPlacementAdded?.();

      resetForm();
    } else {
      toast.error("All fields are required");
    }
  };

  return (
    <>
      <div className="mt-4">
        <div className="flex mb-6 items-center gap-8">
          <div>
            <Label label="Select Run Dates" />

            <DateRangeSelector
              onChange={onChange}
              staticRanges={[]}
              inputRanges={[]}
              inputWrapperClassName="w-96"
              form={{ startDate: form.start_date, endDate: form.end_date }}
            />
          </div>
        </div>
      </div>

      <div className="my-2">
        <Label label="Add Line Items" wrapperClassName="mt-6" />
        <div className="flex mb-6 items-center gap-8">
          <Dropdown
            label="Page"
            placeholder="Select Page"
            name="page"
            value={form.page}
            wrapperClassName="w-96"
            onChange={(event) => {
              setForm((f) => ({
                ...f,
                page: event.target.value,
                category: null,
                placement_type:
                  event.target.value === "Custom" ? "Coupon Listings" : null,
              }));

              onChange({
                ...event,
                target: {
                  name: "page",
                  value: event.target.value,
                },
              } as ChangeEvent<HTMLInputElement>);
            }}
            options={pageOptions.map((el) => ({
              label: el,
              value: el,
            }))}
          />
          {form.page === "Newsletters" && (
            <Dropdown
              label="Page Type"
              placeholder="Select Page Type"
              name="page_type"
              value={form.page_type}
              wrapperClassName="w-96"
              onChange={(event) => {
                setForm((f) => ({
                  ...f,
                  page_type: event.target.value,
                  placement_type:
                    event.target.value === "Solo Newsletter"
                      ? "Featured Coupon"
                      : "Coupon Listings",
                }));
              }}
              options={newsLetterPageTypeOptions.map((el) => ({
                label: el,
                value: el,
              }))}
            />
          )}

          {form.page === "Custom" && (
            <CategorySelector
              className="w-96"
              value={form.category}
              label="Category"
              onResultClick={(result) => {
                setForm((f) => ({ ...f, category: result.slug }));
              }}
              onFocus={() => {
                setForm((f) => ({ ...f, category: null }));
              }}
              loading={getCategoriesData.loading}
              placeholder="Choose Category"
            />
          )}
        </div>

        <div className="flex my-6   items-center gap-8">
          <Dropdown
            label="Placement Type"
            placeholder="Select placement Type"
            name="placement_type"
            wrapperClassName="w-96"
            value={form.placement_type}
            onChange={onChange}
            options={availablePlacementTypes}
          />
        </div>

        {getPositionAvailabilityStatusesData.loading && <Spinner />}

        <div className=" my-6 items-center gap-8">
          <Label label="Positions" />

          {itemsWpositionStatuses
            .filter((it) => !it.title.startsWith("Custom"))
            ?.map((el) => {
              return (
                <div key={el.title}>
                  <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    {el.title}
                    <div className="flex gap-4 flex-wrap max-w-md">
                      <PositionItems
                        positions={el.positions}
                        placements={placements}
                        form={form}
                        handleSelectPosition={handleSelectPosition}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    onClick={handleSetPlacements}
                    className="text-white my-4 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
                  >
                    {form.placement_item_id ? "Update" : "Set"}
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default LineItemsSelector;
