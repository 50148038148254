import { PencilSquareIcon, PlusIcon } from "@heroicons/react/20/solid";
import { PaginatedData } from "types/data";
import { Contact } from "types/model/sales_crm/Contact";
import { useAppDispatch } from "hooks/useRedux";
import { setShowContactModal } from "redux/slices/sales_crm/modals";
import ContactEntryCard from "./ContactEntryCard";
import { useState } from "react";

type Props = {
  programContacts: PaginatedData<Contact>;
  store: Record<string, any>;
};

const ProgramContactsCard = ({ programContacts, store }: Props) => {
  const dispatch = useAppDispatch();
  const [contact, setContact] = useState({});

  return (
    <>
      <ContactEntryCard
        currentContact={
          {
            entity: store,
            entity_type: {
              description: "an In-House Program",
              id: 206,
              name: "In House Program",
            },
            ...contact,
          } as Contact
        }
      />

      <div className="mt-4 bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 w-full">
        <h3 className="text-sm font-medium text-gray-500 border-b border-gray-200">
          Program Contacts
        </h3>
        <ul
          role="list"
          className="mt-4 divide-y divide-gray-200 border-gray-200"
        >
          {programContacts.items.map((el) => (
            <li
              key={el.id}
              className="flex items-center justify-between space-x-3 py-2"
            >
              <div className="flex-shrink-0">
                <p className="truncate text-sm font-medium text-gray-900">
                  {el.first_name} {el.last_name}
                </p>
                <p className="truncate text-xs font-medium text-gray-500">
                  {el.title}
                </p>
              </div>
              <div className="flex-shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setContact(el);
                    dispatch(setShowContactModal(true));
                  }}
                  className="inline-flex items-center gap-x-1.5 text-sm font-semibold leading-6 text-gray-900"
                >
                  <PencilSquareIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </li>
          ))}

          <div className="flex justify-end pt-2">
            <button
              type="button"
              onClick={() => {
                dispatch(setShowContactModal(true));
              }}
              className="flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              </span>
              <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
                Add New Contact
              </span>
            </button>
          </div>
        </ul>
      </div>
    </>
  );
};

export default ProgramContactsCard;
