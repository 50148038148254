import { PaginatedActionState } from "types/data";
import { Event } from "types/model/sales_crm/Event";
import { flattenEvents } from "../components/getLastUpdatedDate";
import { Proposal } from "types/model/sales/Proposal";
import daysAgoDate from "utils/dates/daysAgoDate";
import dayjs from "dayjs";
import formatNumber from "utils/strings/formatNumber";
import {
  LOCAL_KEY_USER_EVENTS,
  LOCAL_KEY_USER_IOS,
} from "constants/storageKeys";

const filterByYesterday = (items: Event[], dateType = "yesterday") => {
  const date = new Date();

  if (dateType === "yesterday") {
    date.setDate(date.getDate() - 1);
  }

  // Format the date to YYYY-MM-DD
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;

  return items.filter((item) => {
    const itemDate = item.event_date?.split("T")[0];
    return itemDate === formattedDate;
  });
};

const getWelcomeMessage = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let yestDayOfWeek = yesterday.getDay();

  const wasWorkingDay = yestDayOfWeek !== 0 && yestDayOfWeek !== 6;

  const userEvents: Event[] = JSON.parse(
    localStorage.getItem(LOCAL_KEY_USER_EVENTS) || "[]"
  );

  const allEvents = flattenEvents(userEvents);

  const yesterdayEvents = filterByYesterday(allEvents);
  const todaysEvents = filterByYesterday(allEvents, "today");

  const ioData: Proposal[] = JSON.parse(
    localStorage.getItem(LOCAL_KEY_USER_IOS) || "[]"
  );

  const topPlacementSale =
    ioData
      .filter(
        (it) =>
          dayjs(it.approved_at) > daysAgoDate(2) &&
          parseFloat(it.final_amount) >= 10000
      )
      .sort((a, b) => {
        if (b.approved_at > a?.approved_at) {
          return 1;
        }
        return -1;
      })?.[0] || null;

  const fixedPlacementSale =
    ioData
      .filter(
        (it) =>
          dayjs(it.approved_at) > daysAgoDate(2) &&
          parseFloat(it.final_amount) < 10000
      )
      .sort((a, b) => {
        if (b.approved_at > a?.approved_at) {
          return 1;
        }
        return -1;
      })?.[0] || null;

  if (topPlacementSale) {
    return `${formatNumber(parseFloat(topPlacementSale.final_amount), {
      prefix: topPlacementSale.currency_symbol,
    })} Sale with ${
      topPlacementSale.client_merchant_config[0].program_name
    }!!! Outstanding job!  Keep the momentum going!`;
  } else if (fixedPlacementSale) {
    return `Good job with the IO for ${fixedPlacementSale.client_merchant_config[0].program_name}! Bravo!!`;
  } else if (
    allEvents.length > 0 &&
    yesterdayEvents.length === 0 &&
    todaysEvents.length === 0 &&
    wasWorkingDay
  ) {
    return "You didn’t add any events yesterday. Don’t forget to add events. Start shining!";
  } else if (dayOfWeek === 1) {
    return "I hope you had a great weekend. Let’s get the week started strong! Add lots of events and make a difference!";
  } else if (dayOfWeek === 5) {
    return "Last day of the week! Let’s finish strong and make sure to start planning for next week.";
  } else {
    return "Today Is A Good Day To Reach Your Revenue Goals!";
  }
};

export default getWelcomeMessage;
