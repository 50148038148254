export const excludedPlacementsPerPage = {
  "1": {
    Homepage: [],
    Newsletters: [],
  },
};

export const excludedCreateProposalPages = [
  "Parent Category",
  "Sub Category",
  "Sub 2 Category",
  "Seasonal Categories",
];

export default excludedPlacementsPerPage;
