import { createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import toast from "react-hot-toast";

interface OperationFunction {
  (data: Record<string, any>): Promise<any>;
}

function Thunk(
  thunkName: string,
  operation: OperationFunction
): AsyncThunk<any, Record<string, any>, { rejectValue: any }> {
  return createAsyncThunk(thunkName, async (data, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );

      if (!selectedClientId) {
        toast.error("Please select a client");
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }

      const response = await operation(data);

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue({
        message:
          error.response?.data?.detail ||
          error.response?.data?.message ||
          error.response.data?.error ||
          (error && JSON.stringify(error)) ||
          "Could not fetch",
      });
    }
  });
}
export default Thunk;
