import StoreCard from "./components/ProposalCard";
import ProgramContactsCard from "./components/ProgramContactsCard";
import StoreSummary from "./components/StoreSummary";
import Layout from "components/layout/Layout";
import { PaginatedData } from "types/data";
import { Contact } from "types/model/sales_crm/Contact";
import Feed from "containers/sales/crm/Feed";
import { Proposal } from "types/model/sales/Proposal";
import StoreQuickAccess from "containers/sales/crm/StoreQuickAccess";
import SalesCRMTabs from "components/SalesCRMTabs";
import Message from "components/common/Message";

type Props = {
  programContacts: PaginatedData<Contact>;
  store: Record<string, any>;
  closedDeals: Proposal[];
  saveProgramOwner: (userId: string) => void;
  programOwner: Record<string, any>;
  saveOwnerLoading: boolean;
  hasSalesCRMAccess: boolean;
  requests: Record<string, any>[];
};

const StoreLayout = ({
  programContacts,
  store,
  closedDeals,
  programOwner,
  saveProgramOwner,
  saveOwnerLoading,
  hasSalesCRMAccess,
  requests,
}: Props) => {
  if (!hasSalesCRMAccess) {
    return (
      <Layout>
        <Message warn description="Not authorized" />
      </Layout>
    );
  }

  return (
    <Layout>
      <SalesCRMTabs globalLayout={false} hideContent />
      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="lg:col-span-2 lg:col-start-1">
          {store && <Feed programOwner={programOwner} />}
          <div className="mt-8 rounded-md">
            <h2 className="text-lg font-medium text-gray-900 border-b-2 pb-2 border-gray-200 my-2">
              Closed Deals
            </h2>
            {closedDeals.length ? (
              <>
                <div className="flex flex-wrap -mx-4">
                  {closedDeals.map((el) => (
                    <StoreCard wrapperClass="w-1/3" proposal={el} key={el.id} />
                  ))}
                </div>
              </>
            ) : (
              <p className="text-sm">No closed deals yet</p>
            )}
          </div>
        </div>
        <div className="lg:col-span-1 lg:col-start-3">
          <StoreSummary
            programOwner={programOwner}
            saveOwnerLoading={saveOwnerLoading}
            saveProgramOwner={saveProgramOwner}
            requests={requests}
          />
          <ProgramContactsCard
            store={store}
            programContacts={programContacts}
          />
          <StoreQuickAccess />
        </div>
      </div>
    </Layout>
  );
};

export default StoreLayout;
