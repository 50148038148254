import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import Agencies from "layout/sales_crm/Agencies";
import { debounce } from "lodash";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getAgencies from "redux/thunks/sales_crm/getAgencies";

const AgenciesContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const agenciesData = useAppSelector((state) => state.getAgencies);

  const { page, search } = Object.fromEntries([...params]);

  useEffect(() => {
    dispatch(getAgencies({ page: 1 }));
  }, []);

  useEffect(() => {
    if (page) {
      dispatch(
        getAgencies({
          page,
        })
      );
    }
  }, [page]);

  useEffect(() => {
    if (typeof search === "string") {
      dispatch(
        getAgencies({
          search,
          page: 1,
        })
      );
    }
  }, [search]);

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const debouncedOnChange = debounce((e) => {
    const url = new URL(window.location.href);
    url.searchParams.set("search", String(e.target.value));
    navigate(url.search);
  }, 2000);

  return (
    <Agencies
      debouncedOnChange={debouncedOnChange}
      onPageChange={onPageChange}
      currentPage={Number(page || 1)}
      agenciesData={agenciesData}
    />
  );
};

export default AgenciesContainer;
