import { useState, useEffect } from "react";
import SalesHomeLayout from "layout/sales/SalesHomeLayout";
import axios from "helpers/axios";


const SalesHome = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/admin/sales/pricing-plans")
      .then((res) => {
        setData(res.data.items);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return <SalesHomeLayout data={data} loading={loading} />;
};
export default SalesHome;
