import paginatedItemsInitialState from "redux/initial-states/paginatedItemsInitialState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCompetitorPrograms from "redux/thunks/marketing/sem/getCompetitorPrograms";
import { PaginatedActionState, PaginatedData } from "types/data";

const initialState: PaginatedActionState<Record<string, any>> =
  paginatedItemsInitialState;

export type ActionStateType = {
  data: PaginatedData<Record<string, any>>;
  loading: boolean;
  error: Record<string, any> | null;
};

const getCompetitorProgramsSlice = createSlice({
  name: "getCompetitorPrograms",
  initialState,
  reducers: {
    resetCompetitorPrograms: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompetitorPrograms.pending, (state) => {
        return {
          ...state,
          data: initialState.data,
          error: null,
          loading: true,
        };
      })
      .addCase(
        getCompetitorPrograms.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if (payload) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getCompetitorPrograms.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetCompetitorPrograms } = getCompetitorProgramsSlice.actions;
export default getCompetitorProgramsSlice.reducer;
