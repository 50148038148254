import {
  REPORT_TYPE_CLIENT_REVENUE,
  REPORT_TYPE_DAILY_REVENUE,
  REPORT_TYPE_MONTHLY_REVENUE,
  REPORT_TYPE_WEEKLY_REVENUE,
  REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER,
} from "constants/revenueReportTypes";
import { REPORTING_APP } from "constants/smaAppNames";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import dayjs from "dayjs";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import ReportingHomeComponent from "layout/reporting/revenue/ReportingHome";
import { debounce } from "lodash";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetTransactionsReport } from "redux/slices/reporting/revenue/getTransactionReport";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";
import getTransactionsReport from "redux/thunks/reporting/revenue/getTransactionsReport";
import getProposalsPaymentStats from "redux/thunks/sales/getProposalsPaymentStats";

const ReportingHome = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useLoadAuth();
  const dispatch = useAppDispatch();
  const [pids, setPids] = useState<Record<string, any>[]>([]);

  const {
    getClients: getClientsData,
    affiliateNetworks,
    getTransactionReport,
    getProposalPeriodReport: getProposalPeriodReportData,
  } = useAppSelector(
    ({
      getClients,
      affiliateNetworks,
      getTransactionReport,
      getProposalPeriodReport,
    }) => ({
      getClients,
      affiliateNetworks,
      getTransactionReport,
      getProposalPeriodReport,
    })
  );

  const {
    report_type = REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER,
    search_text,
    client,
    filter_date_type,
    start_date,
    end_date,
    network,
    client_location,
    txn_type,
    txn_status,
    page = "1",
    commission_type,
    website_id,
    acquisition_type,
    network_id,
    tz_str = "utc",
    ordering,
  } = Object.fromEntries([...searchParams]);

  const filterValues = {
    report_type,
    search_text,
    client,
    filter_date_type,
    start_date,
    end_date,
    network,
    client_location,
    txn_type,
    txn_status,
    page,
    ordering,
    commission_type,
    website_id,
    acquisition_type,
    network_id,
    tz_str,
  };

  const hasAppAccess = user?.user_apps
    ?.map((it) => it.name.toLowerCase())
    ?.includes(REPORTING_APP.toLowerCase());

  const hasPermissions =
    user?.user_role == USER_ROLE_ADMIN || hasAppAccess || false;

  useEffect(() => {
    if (affiliateNetworks.data.meta.count === 0) {
      dispatch(getAffiliateNetworks({ page: 1 }));
    }
  }, [affiliateNetworks.data.meta.count]);

  const showPerfView = (report_type: string) => {
    dispatch(resetTransactionsReport());
    const url = new URL(window.location.href);
    url.searchParams.set("report_type", report_type);
    navigate(url.search);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value = "" },
  }) => {
    if (name === "report_type") {
      dispatch(resetTransactionsReport());
    }
    if (name === "dates") {
      const url = new URL(window.location.href);
      url.searchParams.set(
        "start_date",
        JSON.parse(value)[0]
          ? dayjs(JSON.parse(value)[0]).format("YYYY-MM-DD")
          : ""
      );
      url.searchParams.set(
        "end_date",
        JSON.parse(value)[1]
          ? dayjs(JSON.parse(value)[1]).format("YYYY-MM-DD")
          : ""
      );

      url.searchParams.set("page", "1");

      navigate(url.search);

      return;
    } else {
      const url = new URL(window.location.href);

      url.searchParams.set(name, value);
      url.searchParams.set("page", "1");

      navigate(url.search);
    }
  };
  const onSearchChange: ChangeEventHandler<HTMLInputElement> = debounce((e) => {
    const { name, value } = e.target;
    if (name === "search_text") {
      const url = new URL(window.location.href);

      url.searchParams.set(name, value);
      navigate(url.search);

      dispatch(
        getTransactionsReport({
          ...filterValues,
          search_text: value,
        })
      );
    }
  }, 2000);

  const getReport = () => {
    dispatch(getTransactionsReport(filterValues));

    if (
      [
        REPORT_TYPE_DAILY_REVENUE,
        REPORT_TYPE_MONTHLY_REVENUE,
        REPORT_TYPE_WEEKLY_REVENUE,
        REPORT_TYPE_CLIENT_REVENUE,
      ].includes(filterValues.report_type)
    ) {
      dispatch(getProposalsPaymentStats(filterValues));
    }
  };

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
    dispatch(getTransactionsReport({ ...filterValues, page: String(page) }));
  };

  useEffect(() => {
    if (ordering) {
      dispatch(getTransactionsReport({ ...filterValues, ordering }));
    }
  }, [ordering]);

  useEffect(() => {
    if (filterValues.client && filterValues.network) {
      const clientPids = getClientsData.data.items.find(
        (it) => String(it.id) == filterValues.client
      );

      if (filterValues.network && clientPids?.pids) {
        const networkPids = clientPids?.pids?.filter(
          (it) => it.network == filterValues.network
        );
        setPids(networkPids || []);
      } else {
        setPids(clientPids?.pids || []);
      }
    }
  }, [filterValues.client, filterValues.network, getClientsData.data.items]);

  useEffect(() => {
    if (filterValues.report_type) {
      if (filterValues.start_date && filterValues.end_date) {
        getReport();
      }
    }
  }, [filterValues.report_type]);

  return (
    <ReportingHomeComponent
      getTransactionReport={getTransactionReport}
      hasPermissions={hasPermissions}
      getClientsData={getClientsData}
      affiliateNetworks={affiliateNetworks}
      onChange={onChange}
      filterValues={filterValues}
      onSearchChange={onSearchChange}
      getReport={getReport}
      onPageChange={getNewPage}
      pids={pids}
      showPerfView={showPerfView}
      getProposalPeriodReportData={getProposalPeriodReportData}
    />
  );
};
export default ReportingHome;
