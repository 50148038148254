import Layout from "components/layout/Layout";
import { Link } from "react-router-dom";
import dateformat from "dateformat";

type Props = {
  data: Array<Record<string, any>>;
  loading: boolean;
};

const SalesHomeLayout = ({ data, loading }: Props) => {
  return (
    <Layout loading={loading}>
      <div className="flex gap-8 items-start">
        <div className=" flex-1">
          <div className="flex justify-between items-center">
            <h1 className="text-base font-medium">Pricing Plans</h1>
            <Link
              to={"/sales/add-pricing-plan"}
              type="button"
              className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              <svg
                className="h-3.5 w-3.5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                />
              </svg>
              Add New Pricing Plan
            </Link>
          </div>

          <table className="w-full text-sm text-left text-gray-500 mt-8">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-2 py-4">
                  Creation Date
                </th>
                <th scope="col" className="px-2 py-3">
                  Clients
                </th>

                <th scope="col" className="px-2 py-3">
                  Location
                </th>
                <th scope="col" className="px-2 py-3">
                  Pricing Plan
                </th>
              </tr>
            </thead>

            <tbody>
              {data.map((el) => (
                <tr key={el.id}>
                  <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
                    {dateformat(el.created_at, "mm/dd/yyyy")}
                  </td>
                  <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
                    {el.client.name}
                  </td>
                  <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
                    {el.location?.toUpperCase()}
                  </td>
                  <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
                    {el.name}
                  </td>
                  <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
                    <Link to={`/sales/pricing-plans/${el.id}`}>View</Link>
                  </td>

                  <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
                    <Link
                      to={`/sales/add-pricing-plan?id=${el.id}&default-index=2`}
                    >
                      Edit
                    </Link>
                  </td>

                  <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
                    <Link
                      to={`/sales/add-pricing-plan?id=${el.id}&deleting=true`}
                    >
                      X
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default SalesHomeLayout;
