import { Column } from "types/column";

export const EXT_INSTALLS_COLUMNS = [
  "Install Date",
  "Install ID",
  "Extension Install Source",
  "Source URL",
  "Store",
  "IP Address",
  "City",
  "State",
  "Country",
].map((el) => ({
  label: el,
  key: el,
})) as Column[];

export const EXT_CLOSING_COLUMNS = [
  "Date",
  "Domain",
  "Manual Count",
  "Auto Count",
].map((el) => ({
  label: el,
  key: el,
})) as Column[];

export const EXT_REMOVAL_COLUMNS = [
  "Removal Date",
  "Install ID/ User ID",
  "Latest Typed-in Store Visit",
  // "Extension Open",
  // "Click-to-Copy Engagement",
  "Code Available",
].map((el) => ({
  label: el,
  key: el,
})) as Column[];

export const EXT_STAND_DOWN_COLUMNS = [
  "Date",
  "Store URL",
  "Network",
  "Last Referred Affiliate Site",
  "Install/User ID",
].map((el) => ({
  label: el,
  key: el,
})) as Column[];

export const EXT_USER_ACCOUNT_COLUMNS = [
  "Account Creation Date",
  "Account ID",
  "Extension ID",
  "Account Setup",
  "Account Email",
  "Email Address Verified",
  "Source URL",
  "Program",
  "Extension Download Date",
  "IP Address",
  "City",
  "State",
  "Country",
].map((el) => ({
  label: el,
  key: el,
})) as Column[];

export const EXT_API_STORE_COLUMNS = [
  "Date of Visit",
  "Typed-in Store URL in Browser",
  "# of Visits",
  "Unique Install Visits",
  "Extension Auto-Open",
  "Extension Manual Click Open",
  "Unique Manual Click Open",
  "Coupon Clicks",
  "Unique Coupon Clicks",
  "Unique Open to Click Rate",
  "Code Available",
  "",
].map((el) => ({
  label: el,
  key: el,
})) as Column[];

export const NON_API_STORE_COLUMNS = [
  "Date of Visit",
  "# of visits",
  "Website",
  "Opens",
  "Unique Opens",
  "",
].map((el) => ({
  label: el,
  key: el,
})) as Column[];
