import Message from "components/common/Message";
import DataTable from "components/common/layout/DataTable";
import AdCampaignRow from "components/sem/AdCampaignRow";
import SEM_CAMPAIGN_COLUMNS from "constants/tables/headers/marketing/sem-ad-campaign";
import { Fragment } from "react";
import { ActionState } from "types/data";

type Props = {
  goToAddCampaign: () => void;
  getAdCampaignsState: ActionState<Array<Record<string, any>>>;
  onEditPressed: (item: Record<string, any>) => void;
  onEditAdGroupPressed: (item: Record<string, any>) => void;
  onEditLocationTargetsPressed: (item: Record<string, any>) => void;
  storeMidParam?: string | null;
};

const SEMProgramHomeComponent = ({
  goToAddCampaign,
  onEditPressed,
  onEditAdGroupPressed,
  onEditLocationTargetsPressed,
  getAdCampaignsState: { loading, data },
  storeMidParam,
}: Props) => {
  return (
    <div>
      {!storeMidParam ? (
        <Message
          info
          title="Info"
          description="Please select a program to view active campaigns"
        />
      ) : (
        <DataTable
          currentPage={1}
          rows={data}
          onPageChange={() => {
            //
          }}
          loading={loading}
          totalCount={3}
          renderHeader
          onEmptyActionClick={goToAddCampaign}
          header={
            <Fragment>
              <div className="border-b dark:border-gray-700 mx-4">
                <div className="flex flex-col-reverse md:flex-row items-center justify-end md:space-x-4 py-3">
                  <button
                    type="button"
                    onClick={goToAddCampaign}
                    className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    <svg
                      className="h-3.5 w-3.5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      />
                    </svg>
                    Add New Campaign
                  </button>
                </div>
              </div>
            </Fragment>
          }
          columns={SEM_CAMPAIGN_COLUMNS}
          rowRenderer={(row: any) => (
            <AdCampaignRow
              item={row}
              onEditPressed={onEditPressed}
              onEditAdGroupPressed={onEditAdGroupPressed}
              onEditLocationTargetsPressed={onEditLocationTargetsPressed}
            />
          )}
        />
      )}
    </div>
  );
};

export default SEMProgramHomeComponent;
