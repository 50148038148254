import { Column } from "types/column";

const AFFILIATE_NEWSLETTER_COLUMNS = [
  {
    key: "checkbox",
    label: "",
    component: "components/common/Checkbox",
  },

  {
    key: "Sender",
    label: "Sender",
    isSortable: false,
  },

  {
    key: "Network",
    label: "Network",
    isSortable: false,
  },
  {
    key: "Reply To/Sender",
    label: "Reply To/Sender",
    isSortable: false,
  },

  { key: "subject", label: "Subject Line", isSortable: false },

  { key: "created_at", label: "Received", isSortable: false },

  { key: "Actions", label: "Action", isSortable: false },
] as Column[];

export default AFFILIATE_NEWSLETTER_COLUMNS;
