import dateFormat from "dateformat";
import { capitalize } from "lodash";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  item: Record<string, any>;
  onEditPressed: (item: Record<string, any>) => void;
  onEditAdGroupPressed: (item: Record<string, any>) => void;
  onEditLocationTargetsPressed: (item: Record<string, any>) => void;
};

function AdCampaignRow({
  item,
  onEditPressed,
  onEditAdGroupPressed,
  onEditLocationTargetsPressed,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const wrapperCls = twMerge(
    "border-b opacity-100 transition-all hover:bg-gray-100 cursor-pointer",
  );

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <th
          className="px-2 py-2 text-xs font-medium text-gray-900 "
          onClick={() => {
            setExpanded((exp) => !exp);
          }}
        >
          {item.id}{" "}
        </th>

        <th
          className="px-2 py-2 text-xs font-medium text-gray-900 "
          onClick={() => {
            setExpanded((exp) => !exp);
          }}
        >
          {item.search_engine}{" "}
        </th>
        <td className="px-2 py-2 font-medium text-xs text-gray-900 whitespace-nowrap">
          {item.name}
        </td>
        <td className="px-2 py-2 font-medium text-xs text-gray-900 whitespace-nowrap">
          {item.status === "ELIGIBLE" ? "Active" : capitalize(item.status)}
        </td>
        <td className="px-2 py-2 font-medium text-xs text-gray-900 whitespace-nowrap">
          {item.campaign_budget}
        </td>

        <td className="px-2 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.start_date}
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.end_date}
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          <button
            className="text-blue-700"
            onClick={() => {
              onEditPressed(item);
            }}
          >
            Details
          </button>
        </td>
      </tr>
      <tr
        hidden={!expanded}
        className="flex-1 overflow-x-auto w-full"
        id="table-column-body-0"
        aria-labelledby="table-column-header-0"
      >
        <td className="p-4 border-b dark:border-gray-700" colSpan={9}>
          <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="border-t border-gray-200 px-4 py-2 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {" "}
                    {item.name}
                  </dd>
                </div>
                <div className="flex justify-between items-center">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Locations
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {" "}
                      {item.locations?.map((el: Record<string, any>) => (
                        <div key={el.id}>
                          {el.mode}{" "}
                          <p>
                            {el.text}({el.type})
                          </p>
                        </div>
                      ))}
                    </dd>
                  </div>

                  <button
                    onClick={() => {
                      onEditLocationTargetsPressed(item);
                    }}
                    className="flex items-center justify-center mr-4 text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 max-h-12"
                  >
                    Edit Locations
                  </button>
                </div>

                <div className="flex justify-between items-center">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Ad group
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <div>
                        <p>Name</p>
                        {item.ad_group_name}
                      </div>

                      <div className="my-4">
                        <p>Default CPC Bid</p>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {item.ad_group_default_cpc}
                        </dd>
                      </div>

                      <div className="my-4">
                        <p>Status</p>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {item.ad_group_status}
                        </dd>
                      </div>
                    </dd>
                  </div>

                  <button
                    onClick={() => {
                      onEditAdGroupPressed(item);
                    }}
                    className="flex items-center justify-center mr-4 text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 max-h-12"
                  >
                    Edit Ad group
                  </button>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Creation Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {dateFormat(item.created_at, "yyyy-mm-dd")}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Start Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {item.start_date}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    End Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {item.end_date}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default AdCampaignRow;
