import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  status: "pending" | "sold" | "available";
};

const PositionStatusIcon = ({ status }: Props) => {
  return (
    <span
      className={twMerge(
        "flex w-3 h-3  rounded-full",
        status === "pending" && "bg-yellow-300",
        status === "sold" && "bg-red-500",
        status === "available" && "bg-green-300"
      )}
    ></span>
  );
};

export default PositionStatusIcon;
