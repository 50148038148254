import { Column } from "types/column";

const NETWORK_BY_CLIENTS_COLUMNS = [
  {
    key: "",
    label: "",
    component: "components/common/Checkbox",
  },
  {
    key: "network",
    label: "Network",
    isSortable: true,
    beforeCell: "components/common/GoTo",
    classNames: {
      head: "text-green-400",
      body: "text-blue-700 cursor-pointer",
    },
  },
  {
    key: "cid",
    label: "Account ID",
    isSortable: true,
  },

  {
    key: "client.name",
    label: "Client Websites",
    isSortable: true,
    classNames: {
      body: "bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300",
    },
  },

  { key: "programs_mapped", label: "Programs API", isSortable: true },
  { key: "coupons_api", label: "Coupons API", isSortable: true },

  { key: "reporting_api", label: "Reporting API", isSortable: true },

  { key: "", label: "", isSortable: false },
] as Column[];

export default NETWORK_BY_CLIENTS_COLUMNS;
