import { capitalize } from "lodash";
import countries from "constants/operationCountries";

const storeLocationNameMap: Record<string, string> = {};

countries
  .map((el) => el.toLowerCase())
  .map((el) => {
    if (el === "us") {
      storeLocationNameMap[el] = el.toUpperCase();
    }

    if (el === "uk") {
      storeLocationNameMap[el] = el.toUpperCase();
    }

    if (el === "canada") {
      storeLocationNameMap[el] = capitalize(el);
    }

    if (el === "australia") {
      storeLocationNameMap[el] = capitalize(el);
    }

    if (el === "nl") {
      storeLocationNameMap[el] = el.toUpperCase();
    }

    if (el === "de") {
      storeLocationNameMap[el] = el.toUpperCase();
    }
    if (el === "fr") {
      storeLocationNameMap[el] = el.toUpperCase();
    }
  });
export default storeLocationNameMap;
