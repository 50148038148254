import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EmailCampaignsComponent from "layout/marketing/email/EmailCampaigns";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getEmailCampaigns from "redux/thunks/marketing/emails/getEmailCampaigns";
import { EmailCampaign } from "types/model/NewsletterSubscriber";

function EmailCampaigns() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { page, email_drop_id } = Object.fromEntries([...searchParams]);

  const getEmailCampaignsData = useAppSelector(
    (state) => state.getEmailCampaigns
  );

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  useEffect(() => {
    dispatch(
      getEmailCampaigns({
        show_summary: "0",
        email_drop_id: email_drop_id,
        page: Number(page) || 1,
      })
    );
  }, [searchParams]);

  const onReviewClicked = (item: EmailCampaign) => {
  
    navigate(`/marketing/email/email-campaigns/${item.uuid}`, {
      state: {
        item,
      },
    });
  };

  return (
    <EmailCampaignsComponent
      onPageChange={getNewPage}
      onReviewClicked={onReviewClicked}
      currentPage={Number(page) || 1}
      getEmailCampaignsData={getEmailCampaignsData}
    />
  );
}

export default EmailCampaigns;
