import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { TopProgramCategory } from "types/model/Category";
import TopStoresPerCategoryLayout from "layout/oma/campaigns/TopStoresPerCategoryLayout";
import { Store } from "types/model/oma/Store";
import updateTopCategory from "redux/thunks/app/placements/campaigns/updateTopCategory";
import { clearSaveTopCategories } from "redux/slices/clients/placements/campaigns/saveTopCategories";

type Props = {
  topCategory: TopProgramCategory;
};

const TopStoresPerCategory = ({ topCategory }: Props) => {
  const dispatch = useAppDispatch();
  const {
    getCategoryStoreSpotlights: getCategoryStoreSpotlightsData,
    saveTopCategories: saveTopCategoriesData,
  } = useAppSelector(
    ({
      getClients,
      getCategories,
      getCategoryStoreSpotlights,
      saveTopCategories,
    }) => ({
      getClients,
      getCategories,
      getCategoryStoreSpotlights,
      saveTopCategories,
    })
  );

  const [selectedTopCategory, setSelectedTopCategory] =
    useState<TopProgramCategory>(topCategory);

  useEffect(() => {
    setSelectedTopCategory(topCategory);
  }, [getCategoryStoreSpotlightsData.data]);

  useEffect(() => {
    if (saveTopCategoriesData.data.saved) {
      dispatch(clearSaveTopCategories());
    }
  }, [saveTopCategoriesData.data.saved]);

  const removeStoreFromCategory = (store: Store) => {
    setSelectedTopCategory((topCategory) => ({
      ...topCategory,
      featured_stores: topCategory.featured_stores.filter(
        (it) => it.id != store.id
      ),
    }));
  };

  const addStoreToCategory = (store: Store) => {
    setSelectedTopCategory((topCategory) => ({
      ...topCategory,
      featured_stores: [store, ...topCategory.featured_stores],
    }));
  };

  const handlePersistUpdates = () => {
    dispatch(
      updateTopCategory({
        ...selectedTopCategory,
        category: selectedTopCategory.category.id,
        featured_stores: selectedTopCategory.featured_stores.map((el) => el.id),
        featured_subs: selectedTopCategory.featured_subs.map((el) => el.id),
      })
    );
  };

  return (
    <TopStoresPerCategoryLayout
      handlePersistUpdates={handlePersistUpdates}
      topCategory={selectedTopCategory}
      removeStoreFromCategory={removeStoreFromCategory}
      addStoreToCategory={addStoreToCategory}
      saving={saveTopCategoriesData.loading}
    />
  );
};

export default TopStoresPerCategory;
