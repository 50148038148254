import createUpdateCategory from "redux/slices/clients/createUpdateCategory";
import getCategories from "redux/slices/clients/getCategories";
import getCategory from "redux/slices/clients/getCategory";

const categoryReducers = {
  getCategories,
  createUpdateCategory,
  getCategory,
};

export default categoryReducers;
