import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import BMAMerchantMappingComponent from "layout/bma/BMAMerchantMapping";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import createUpdateStore from "redux/thunks/app/api-clients/createUpdateStore";
import getAdvertisers from "redux/thunks/advertisers/getAdvertisers";
import IgnoreAdvertiser from "redux/thunks/advertisers/IgnoreAdvertiser";
import { Advertiser } from "types/model/Advertiser";
import getProgramAction from "redux/thunks/app/api-clients/getProgram";
import {
  addAdvertiser,
  resetAdvertisers,
} from "redux/slices/advertisers/getAdvertisers";
import { debounce } from "lodash";
import loopUpAdvertisers from "redux/thunks/advertisers/loopUpAdvertisers";
import { resetLookUpAdvertisers } from "redux/slices/advertisers/lookUpAdvertisers";
import { resetProgram } from "redux/slices/clients/getProgram";

function BMAMerchantMapping() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const mid = searchParams.get("slug");
  const isProgramsPage = pathname.includes("/bma/programs");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loadingCardId, setLoadingCardId] = useState<null | string | number>(
    null
  );

  const { getAdvertisers: getAdvertisersData, getProgram } = useAppSelector(
    ({ getAdvertisers, getProgram }) => ({
      getAdvertisers,
      getProgram,
    })
  );

  const [searchText, setSearchText] = useState("");
  const { loopUpAdvertisers: loopUpAdvertisersData } = useAppSelector(
    (state) => state
  );
  const { name, website_url, linked_networks, network_mid } = getProgram.data;
  const debounceFn = useCallback(
    debounce((e) => {
      if (e.target.value.length) {
        dispatch(
          loopUpAdvertisers({
            page: 1,
            search_text: encodeURIComponent(e.target.value),
          })
        );
      } else {
        dispatch(resetLookUpAdvertisers());
      }
    }, 1000),
    []
  );

  const onResultClick = (res: Record<string, any>) => {
    dispatch(addAdvertiser(res));
    dispatch(resetLookUpAdvertisers());
    setSearchText("");
  };

  const onResultsOutsideClick = () => {
    dispatch(resetLookUpAdvertisers());
  };

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
    debounceFn(e);
  };
  const onSearchSubmit = () => {
    dispatch(
      loopUpAdvertisers({
        page: 1,
        search_text: encodeURIComponent(searchText),
        search_intent: "mapping",
      })
    );
  };

  const getSuggestedAdvertisers = () => {
    const connectedIds = [
      ...linked_networks.map((el: Record<string, any>) => el.network_mid),
      network_mid,
    ].filter((it) => it);

    dispatch(
      getAdvertisers({
        page: 1,
        suggest_for_name: encodeURIComponent(name),
        suggest_for_website: website_url,
        ignoreForClient: true,
        include_mids: connectedIds,
        search_intent: "mapping",
      })
    );
  };

  useEffect(() => {
    if (getProgram.data.id) {
      getSuggestedAdvertisers();
    }
  }, [getProgram.data.id]);

  useEffect(() => {
    if (mid) {
      dispatch(
        getProgramAction({
          query: mid,
        })
      );
    }
  }, [mid]);

  const setStoreNetwork = (advertiser: Advertiser) => {
    dispatch(
      createUpdateStore({
        data: {
          id: getProgram.data.id,
          setNetwork: true,
          network_mid: advertiser.external_id,
          network_advertiser_affiliate_website_url:
            advertiser.affiliate_website_url,
          name: advertiser.network,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("update successful");
      }
    });
  };

  const onLinkNetwork = (advertiser: Advertiser, args: Record<string, any>) => {
    dispatch(
      createUpdateStore({
        data: {
          slug: getProgram.data.slug,
          id: getProgram.data.id,
          primary_network: getProgram.data.primary_network,
          // primary_network_primary_network_affiliate_url:
          //   advertiser,
          linked_networks: args.unLink
            ? getProgram.data.linked_networks.filter(
                (it: Record<string, any>) =>
                  it.network_mid !== advertiser.external_id
              )
            : [
                ...getProgram.data.linked_networks,
                {
                  network_mid: advertiser.external_id,
                  name: advertiser.network,
                },
              ],
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("update successful");
      }
    });
  };

  const handleIgnoreAdvertiser = (advertiser: Advertiser) => {
    setLoadingCardId(advertiser.id);
    dispatch(
      IgnoreAdvertiser({
        data: {
          ids: [String(advertiser.id)],
          action: "mapping",
        },
      })
    )
      .then((res) => {
        setLoadingCardId(null);
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Update successful");
        }
      })
      .catch((err) => {
        setLoadingCardId(null);
      });
  };

  const removePrimaryNetwork = (advertiser: Advertiser) => {
    dispatch(
      createUpdateStore({
        data: {
          id: getProgram.data.id,
          removePrimaryNetwork: true,
        },
      })
    );
  };

  const onBackPressed = () => {
    dispatch(resetProgram());
    dispatch(resetAdvertisers());
    navigate(-1);
  };

  const setFree = (adv: Record<string, any>) => {
    dispatch(
      createUpdateStore({
        data: {
          id: getProgram.data.id,
          setNetwork: true,
          network_mid: null,
          name: "Free",
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("update successful");
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(resetAdvertisers());
    };
  }, []);

  return (
    <BMAMerchantMappingComponent
      setFree={setFree}
      loading={getProgram.loading || getAdvertisersData.loading}
      merchant={getProgram.data}
      isProgramsPage={isProgramsPage}
      removePrimaryNetwork={removePrimaryNetwork}
      onMakePrimary={setStoreNetwork}
      getAdvertisersData={getAdvertisersData}
      onLinkNetwork={onLinkNetwork}
      handleIgnoreAdvertiser={handleIgnoreAdvertiser}
      loadingCardId={loadingCardId}
      onBackPressed={onBackPressed}
      onLookupResultClick={onResultClick}
      onLookupResultClickOutside={onResultsOutsideClick}
      lookUpResults={loopUpAdvertisersData.data.items}
      onLookupChange={onSearchChange}
      searchLoading={loopUpAdvertisersData.loading}
      onLookupSubmit={onSearchSubmit}
    />
  );
}

export default BMAMerchantMapping;
