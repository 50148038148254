import formatNumber from "utils/strings/formatNumber";

type Props = {
  totals: Record<string, any>;
};

const TotalCommissionAmounts = ({ totals = {} }: Props) => {
  return (
    <div>
      {totals?.usd_total_commission_amount ? (
        <p>
          {formatNumber(totals?.usd_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "$",
          })}
        </p>
      ) : null}
      {totals?.gbp_total_commission_amount ? (
        <p>
          {formatNumber(totals?.gbp_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "£",
          })}
        </p>
      ) : null}
      {totals?.dkk_total_commission_amount ? (
        <p>
          {formatNumber(totals?.dkk_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "kr.",
          })}
        </p>
      ) : null}
      {totals?.eur_total_commission_amount ? (
        <p>
          {formatNumber(totals?.eur_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "€",
          })}
        </p>
      ) : null}
      {totals?.aud_total_commission_amount ? (
        <p>
          {formatNumber(totals?.aud_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "AU$",
          })}
        </p>
      ) : null}

      {totals?.cad_total_commission_amount ? (
        <p>
          {formatNumber(totals?.cad_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "CA$",
          })}
        </p>
      ) : null}

      {totals?.brl_total_commission_amount ? (
        <p>
          {formatNumber(totals?.brl_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "R$",
          })}
        </p>
      ) : null}
      {totals?.sek_total_commission_amount ? (
        <p>
          {formatNumber(totals?.sek_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "kr",
          })}
        </p>
      ) : null}

      {totals?.czk_total_commission_amount ? (
        <p>
          {formatNumber(totals?.czk_total_commission_amount / 1000, {
            decimals: 2,
            prefix: "Kč",
          })}
        </p>
      ) : null}
    </div>
  );
};

export default TotalCommissionAmounts;
