import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import PricingPlanLayout from "layout/sales/PricingPlanLayout";
import { useParams } from "react-router-dom";
import getPricingPlan from "redux/thunks/sales/getPricingPlan";
import { useEffect } from "react";


const PricingPlan = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { getPricingPlan: getPricingPlanData } = useAppSelector(
    ({ getPricingPlan }) => ({ getPricingPlan })
  );

  useEffect(() => {
    if (params["id"]) {
      dispatch(getPricingPlan(params["id"] || ""));
    }
  }, []);
  return <PricingPlanLayout getPricingPlanData={getPricingPlanData} />;
};
export default PricingPlan;
