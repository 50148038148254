import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  networkModalOpen: false,
  contactModalOpen: false,
  agencyModalOpen: false,
  createLeadModalOpen: false,
};

const crmModalsSlice = createSlice({
  name: "ModalOpen",
  initialState: initialState,
  reducers: {
    toggleNetworkModal: (state) => ({
      ...state,
      networkModalOpen: !state.networkModalOpen,
    }),

    toggleAgencyModal: (state) => ({
      ...state,
      agencyModalOpen: !state.agencyModalOpen,
    }),

    toggleContactModal: (state) => ({
      ...state,
      contactModalOpen: !state.contactModalOpen,
    }),

    toggleCreateLeadModal: (state) => ({
      ...state,
      createLeadModalOpen: !state.createLeadModalOpen,
    }),

    setShowContactModal: (state, { payload }) => ({
      ...state,
      contactModalOpen: payload,
    }),

    setShowAgencyModal: (state, { payload }) => ({
      ...state,
      agencyModalOpen: payload,
    }),

    setShowNetworkModal: (state, { payload }) => ({
      ...state,
      networkModalOpen: payload,
    }),

    setShowCreateLeadModal: (state, { payload }) => ({
      ...state,
      createLeadModalOpen: payload,
    }),
  },
});
export const {
  toggleNetworkModal,
  setShowContactModal,
  toggleContactModal,
  toggleAgencyModal,
  setShowNetworkModal,
  setShowAgencyModal,
  toggleCreateLeadModal,
  setShowCreateLeadModal,
} = crmModalsSlice.actions;
export default crmModalsSlice.reducer;
