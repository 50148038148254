import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EditAdComponent from "layout/marketing/search/EditAd";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import updateAd from "redux/thunks/marketing/sem/updateCampaign";

const EditAd = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [params] = useSearchParams();

  const {
    getAd: { data: getAdData, loading: getAdsLoading },
    updateAdGroup: { loading: updateAdGroupLoading },
  } = useAppSelector(({ getAd, updateAdGroup }) => ({ getAd, updateAdGroup }));

  const initialForm = {
    ...state,
    action: "update-ad",
    campaign_id: params.get("campaign_id"),
    search_engine: params.get("search_engine"),
    display_path1: "",
    display_path2: "",
    ad_group_id: params.get("ad_group_id"),
    final_url: "",
    head_line1: "",
    head_line2: "",
    head_line3: "",
    head_line4: "",
    head_line5: "",
    head_line6: "",
    head_line7: "",
    head_line8: "",
    head_line9: "",
    head_line10: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
  };

  const [form, setForm] = useState<Record<string, any>>(initialForm);
  // const [errors, setErrors] = useState<Record<string, any>>({});

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = () => {
    dispatch(updateAd(form));
  };

  useEffect(() => {
    if (getAdData.length) {
      setForm((f) => ({
        ...f,
        ...getAdData[0],
      }));
    }
  }, [getAdData]);

  return (
    <EditAdComponent
      errors={{}}
      onSubmit={onSubmit}
      form={form}
      onChange={onChange}
      getAdsLoading={getAdsLoading}
      loading={updateAdGroupLoading}
    />
  );
};

export default EditAd;
