import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import CRMNetworksLayout from "layout/sales_crm/SalesCRMNetworks";
import React, { useEffect } from "react";
import getCRMNetworks from "redux/thunks/sales_crm/getCRMNetworks";

const SalesCRMNetworks = () => {
  const dispatch = useAppDispatch();
  const networksData = useAppSelector((state) => state.getCRMNetworks);

  useEffect(() => {
    dispatch(getCRMNetworks());
  }, []);
  return <CRMNetworksLayout networks={networksData} />;
};

export default SalesCRMNetworks;
