import Activate from "containers/bma/Activate";
import ProgramsComponent from "containers/bma/Program";
import BMAMappingAll from "containers/bma/BMAMappingAll";
import BMAMerchantMapping from "containers/bma/BMAMerchantMapping";
import DeactivatedPrograms from "containers/bma/DeactivatedPrograms";
import CommissionRateChanges from "containers/bma/CommisionRateChanges";
import CommissionRateChangeHistory from "containers/bma/CommisionRateChangeHistory";
import BMATabs from "layout/bma/BMATabs";
import BMACRMMappingAll from "containers/bma/BMACRMMappingAll";

const bmaRoutes = [
  {
    path: "/bma",
    element: <BMATabs />,
  },
  {
    path: "/bma/activate",
    element: <Activate />,
  },
  {
    path: "/",
    element: <BMATabs />,
  },
  {
    path: "/bma/programs",
    element: <ProgramsComponent />,
  },

  {
    path: "/bma/mapping",
    element: <BMAMappingAll />,
  },

  {
    path: "/bma/crm-mapping",
    element: <BMACRMMappingAll />,
  },
  {
    path: "/bma/mapping/:id",
    element: <BMAMerchantMapping />,
  },

  { path: "/bma/deactivated-programs", element: <DeactivatedPrograms /> },
  { path: "/bma/commission-rate-changes", element: <CommissionRateChanges /> },
  {
    path: "/bma/commission-rate-changes/:id",
    element: <CommissionRateChangeHistory />,
  },
];
export default bmaRoutes;
