import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import Label from "./Label";
import Spinner from "./layout/Spinner";

export interface AppTextInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  id?: string;
  value?: string;
  required?: boolean;
  className?: string;
  maxLength?: number;
  error?: string;
  rightLabel?: JSX.Element | null;
  leftIcon?: JSX.Element | null;
  rightIcon?: JSX.Element | null;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyUp?: any;
  inputContainerClass?: string;
  wrapperClassName?: string;
  defaultFocus?: boolean;
  renderEmptyLabel?: boolean;
  itemsLoading?: boolean;
}

function Input({
  label,
  name,
  className,
  placeholder,
  error,
  leftIcon,
  rightLabel,
  inputContainerClass,
  onChange,
  wrapperClassName,
  defaultFocus,
  maxLength,
  renderEmptyLabel,
  itemsLoading,
  onKeyUp,
  rightIcon,
  ...props
}: AppTextInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);

  const inputClassName = twMerge(
    "bg-gray-50  text-gray-900 text-sm  block w-full flex-1  border-0 outline-none focus:outline-none focus:border-gray-50 ring-0 focus:ring-0",
    className,
    leftIcon && "px-2.5"
  );

  const inputContainerClassName = twMerge(
    "flex h-12 border border-gray-300 items-center bg-gray-50 rounded-lg p-2.5",
    leftIcon && "px-4",
    inputContainerClass,
    error && "border-2 border-red-700 ",
    focused && "border-2 border-primary-600 "
  );
  const wrapperClass = twMerge("w-full ", wrapperClassName);

  useEffect(() => {
    if (defaultFocus) {
      inputRef.current?.focus();
      setFocused(true);
    }
  }, []);

  return (
    <div className={wrapperClass}>
      <div className="flex justify-between items-center">
        {label && <Label label={label} name={name} rightLabel={rightLabel} />}

        {renderEmptyLabel && <Label label="" />}
        {itemsLoading && (
          <div className="relative">
            <Spinner className="h-4 w-4" />
          </div>
        )}
      </div>

      <div className={inputContainerClassName}>
        {leftIcon && leftIcon}
        <input
          name={name}
          id={name}
          ref={inputRef}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          className={inputClassName}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={maxLength}
          onWheelCapture={(e) => {
            if (props.type === "number") {
              e.currentTarget?.blur();
            }
          }}
          onKeyUp={onKeyUp}
          {...props}
        />
        {rightIcon && rightIcon}
        {maxLength && (
          <small>
            {props.value?.length || 0} / {maxLength}
          </small>
        )}
      </div>
      {error && <small className="text-xs  text-red-700">{error}</small>}
    </div>
  );
}

export default Input;
