import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sem/get-ads",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/sem/ads?ad_group_id=${payload.ad_group_id}&search_engine=${payload.search_engine}&resource=ads`,
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
