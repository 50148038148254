import Input from "components/common/Input";
import Modal from "components/common/Modal";
import SearchPrograms from "containers/oma/SearchPrograms";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import SpotlightStoreRow from "./SpotlightStore";
import { useSearchParams } from "react-router-dom";

type Props = {
  row: Record<string, any>;
  handleRemoveCategory: (row: Record<string, any>) => void;
  saveSelectedStores: (
    row: Record<string, any>,
    items: Record<string, any>
  ) => void;
};

const CategoryStoreSpotlightRow = ({
  row,
  saveSelectedStores,
  handleRemoveCategory,
}: Props) => {
  const [manageOpen, setManageOpen] = useState(false);
  const [params] = useSearchParams();

  const [form, setForm] = useState<Record<string, any>>({});

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (manageOpen) {
      setForm(row);
    }
  }, [row, manageOpen]);

  return (
    <React.Fragment>
      <Modal
        doneText="Save"
        doneBtnClass="bg-blue-500 hover:bg-blue-600"
        onConfirm={() => {
          saveSelectedStores(row, form);
          setForm(row);
          setManageOpen(false);
        }}
        open={manageOpen}
        setOpen={setManageOpen}
      >
        <h1>Manage campaign</h1>

        <div className="my-2">
          <SearchPrograms
            label="Search Store to add"
            wrapperClassName="w-48 mx-0"
            searchParams={{ location: params.get("location") }}
            onResultClicked={(result) => {
              setForm((f) => ({ ...f, stores: [...f.stores, result] }));
            }}
          />

          {form.stores?.map((store: Record<string, any>) => (
            <SpotlightStoreRow
              onChange={onChange}
              key={store.id}
              form={form}
              store={store}
            />
          ))}
        </div>

        <Input
          wrapperClassName="my-2"
          name="slogan"
          label="slogan"
          value={form.slogan}
          onChange={onChange}
        />

        <div className="mt-2">
          <Input
            type="date"
            onChange={onChange}
            label="From date"
            value={form.from_date}
            name={"from_date"}
          />
          <Input
            type="date"
            name="to_date"
            label="To date"
            onChange={onChange}
            value={form.to_date}
          />
        </div>
      </Modal>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {row.category.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
          {row.slogan}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {row.stores
            ?.map((store: Record<string, any>) => store.name)
            .join(",")}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {/* {row.top_coupons
            .map((coupon: Record<string, any>) => coupon.id)
            .join(",")} */}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {/* {row.top_coupons
            .map((coupon: Record<string, any>) => coupon.title)
            .join(",")} */}
        </td>
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {row.from_date || "Not set"} - {row.to_date || "Not set"}
        </th>

        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 items-center">
          <button
            onClick={() => {
              setManageOpen(true);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Manage<span className="sr-only"></span>
          </button>

          <button
            className="mx-4"
            onClick={() => {
              handleRemoveCategory(row);
            }}
          >
            X
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default CategoryStoreSpotlightRow;
