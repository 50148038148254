import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getEventTypes from "redux/thunks/sales_crm/getEventTypes";
import { EventType } from "types/model/sales_crm/Event";

export type ActionStateType = {
  data: EventType[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getEventTypesSlice = createSlice({
  name: "EventTypes",
  initialState,
  reducers: {
    resetEventTypes: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventTypes.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getEventTypes.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getEventTypes.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetEventTypes } = getEventTypesSlice.actions;
export default getEventTypesSlice.reducer;
