import dateformat from "dateformat";
import { HiPencil } from "react-icons/hi";
import dayjs from "dayjs";
import formatNumber from "utils/strings/formatNumber";
import { ChangeEventHandler, Dispatch, SetStateAction, useState } from "react";
import Modal from "components/common/Modal";
import LineItemsSelector from "./LineItemsSelector";
import { AddLineItemsProps } from "./ProposalDetails";

type Props = {
  removePaidPlacements: (item: Record<string, any>) => void;
  item: Record<string, any>;
  duplicateItem: (item: Record<string, any>, idx: number) => void;
  currency_symbol: string;
  addLineItemsProps: AddLineItemsProps;
  idx: number;
};

const PlacementsListItem = ({
  item,
  removePaidPlacements,
  duplicateItem,
  currency_symbol,
  addLineItemsProps,
  idx,
}: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [initialUpdateItems, setUpdatedItems] = useState<AddLineItemsProps>({
    ...addLineItemsProps,
    form: {
      ...addLineItemsProps.form,
      ...item,
      placement_item_id: item.id,
      location: item.location,
      client: item.client.id,
      startDate: dateformat(dayjs(item.start_date).toDate()),
      endDate: dateformat(dayjs(item.end_date).toDate()),
    },
  });

  const onPlacementItemChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.name === "dates") {
      const start_date = dayjs(JSON.parse(e.target.value)[0]);
      const end_date = dayjs(JSON.parse(e.target.value)[1]);
      setUpdatedItems((values) => ({
        ...values,
        form: {
          ...values.form,
          start_date: start_date,
          end_date: end_date,
        },
      }));
    } else {
      setUpdatedItems((values) => ({
        ...values,
        form: {
          ...values.form,
          [e.target.name]: e.target.value,
        },
      }));
    }
  };

  return (
    <tr>
      {
        <Modal
          setOpen={setEditModalOpen}
          showDone={false}
          open={editModalOpen}
          wrapperClassName="min-h-screen min-h-[75vh] w-1/3"
        >
          <LineItemsSelector
            onPlacementAdded={() => {
              setEditModalOpen(false);
            }}
            {...initialUpdateItems}
            setForm={(fn: any) => {
              const newValues = fn();
              setUpdatedItems((values) => ({
                ...values,
                form: { ...values.form, ...newValues },
              }));
            }}
            onChange={onPlacementItemChange}
          />
        </Modal>
      }
      <td className="py-2 text-xs font-medium text-gray-900 ">
        {dateformat(dayjs(item.start_date).toDate(), "mm/dd/yyyy")}-{" "}
        {dateformat(dayjs(item.end_date).toDate(), "mm/dd/yyyy")}
      </td>

      <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
        {item.client.name}
      </td>

      <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
        {item.page}
        {item.page_type && <small className="block">{item.page_type}</small>}
      </td>
      <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
        {item.placement_type}
      </td>
      <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
        {item.position}
      </td>
      <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
        {currency_symbol}
        {formatNumber(item.amount, {})}
      </td>
      <td className="px-2 py-2 text-xs font-medium text-blue-600 ">
        <button
          onClick={() => {
            duplicateItem(item, idx);
          }}
        >
          Clone item
        </button>
      </td>
      <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
        <button
          onClick={() => {
            setEditModalOpen(true);
          }}
        >
          <HiPencil />
        </button>
      </td>

      <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
        <button
          onClick={() => {
            removePaidPlacements(item);
          }}
        >
          X
        </button>
      </td>
    </tr>
  );
};

export default PlacementsListItem;
