import axios from "helpers/axios";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import ActivateComponent from "layout/bma/Activate";
import { capitalize, debounce } from "lodash";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetLookUpAdvertisers } from "redux/slices/advertisers/lookUpAdvertisers";
import loopUpAdvertisers from "redux/thunks/advertisers/loopUpAdvertisers";
import createUpdateStore from "redux/thunks/app/api-clients/createUpdateStore";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import slugify from "utils/strings/slugify";
import addHttp from "utils/urls/addHttp";
import getStoreTypes from "redux/thunks/app/api-clients/getStoreTypes";
import { STORE_TYPE_FREE, STORE_TYPE_MAPPED } from "constants/storeTypeIds";
import getCouponPolicies from "redux/thunks/app/api-clients/getCouponPolicies";
import { Option } from "types/option";

const Activate = () => {
  const [params] = useSearchParams();
  const [form, setForm] = useState<Record<string, any>>({
    store_type: STORE_TYPE_FREE,
    coupon_policies: [],
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchVisible, setSearchVisible] = useState(false);

  const [searchText, setSearchText] = useState("");

  const advertiserId = params.get("mid");
  const {
    loopUpAdvertisers: loopUpAdvertisersData,
    getStoreTypes: getStoreTypesData,
  } = useAppSelector(({ loopUpAdvertisers, getStoreTypes }) => ({
    loopUpAdvertisers,
    getStoreTypes,
  }));

  const {
    getCategories: { data: categoryList },
    getCouponPolicies: getCouponPolicyData,
    createStore,
  } = useAppSelector(({ getCategories, createStore, getCouponPolicies }) => ({
    getCategories,
    createStore,
    getCouponPolicies,
  }));

  useEffect(() => {
    dispatch(getCouponPolicies({})).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setForm((f) => ({
          ...f,
          coupon_policies: res.payload.map((it: Record<string, any>) => ({
            selected: false,
            label: it.name,
            value: it.id,
          })),
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (!params.get("mid")) {
      setSearchVisible(true);
    }
  }, []);

  const getAdvertiserDetails = async () => {
    if (advertiserId) {
      const {
        data: { item: data },
      } = await axios.get(
        `/admin/advertisers/network-advertisers/${advertiserId}?client_id=${localStorage.getItem(
          LOCAL_KEY_SELECTED_CLIENT_ID
        )}`
      );

      setForm((f) => ({
        ...f,
        ...{
          name: data.name,
          primary_network: data.network || "",
          network_mid: data.external_id || "",
          ppc_policy: capitalize(data.ppc_policy || "") || "",
          website_url: addHttp(data.website),
          description: "",
          info: "",
          is_active: true,
          affiliate_website_url:
            addHttp(data.affiliate_website_url) || addHttp(data.website),
          slug: slugify(data.name || ""),
          location: data?.country_code?.toLowerCase?.() || "us",
          affiliate_website_url_acquire_mode:
            data.affiliate_website_url_acquire_mode,
        },
      }));
    }
  };

  useEffect(() => {
    getAdvertiserDetails();
  }, []);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    if (name === "name") {
      setForm((f) => ({ ...f, slug: slugify(value) }));
    }
    setForm((f) => ({ ...f, [name]: value }));
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    dispatch(
      createUpdateStore({
        data: {
          ...form,
          coupon_policies: form.coupon_policies
            .filter((it: Option) => it.selected)
            .map((el: Option) => el.value),
          activateNew: true,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Activated successfully");
        navigate(`/bma/programs?slug=${res.payload.slug}`);
      }
    });
  };

  const debounceFn = useCallback(
    debounce((e) => {
      if (e.target.value.length) {
        dispatch(
          loopUpAdvertisers({
            page: 1,
            search_text: encodeURIComponent(e.target.value),
            search_intent: "mapping",
          })
        );
      } else {
        dispatch(resetLookUpAdvertisers());
      }
    }, 1000),
    []
  );

  const onResultClick = (res: Record<string, any>) => {
    setForm((f) => ({
      ...f,
      name: res.name,
      primary_network: res.network || "",
      network_mid: res.external_id || "",
      ppc_policy: capitalize(res.ppc_policy || "") || "",
      website_url: addHttp(res.website),
      description: "",
      info: "",
      is_active: true,
      affiliate_website_url:
        addHttp(res.affiliate_website_url) || addHttp(res.website),
      slug: slugify(res.name || ""),
      location: res?.country_code?.toLowerCase?.() || "us",
      affiliate_website_url_acquire_mode:
        res.affiliate_website_url_acquire_mode,
    }));
    dispatch(resetLookUpAdvertisers());
    setSearchText("");

    setSearchVisible(false);
  };

  const onResultsOutsideClick = () => {
    dispatch(resetLookUpAdvertisers());
  };

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
    debounceFn(e);
  };
  const onSearchSubmit = () => {
    dispatch(
      loopUpAdvertisers({
        page: 1,
        search_text: encodeURIComponent(searchText),
        search_intent: "mapping",
      })
    );
  };

  useEffect(() => {
    if (!categoryList.length) {
      dispatch(getCategories({}));
    }
  }, []);

  useEffect(() => {
    if (form.primary_network) {
      setForm((f) => ({ ...f, store_type: STORE_TYPE_MAPPED }));
    }
  }, [form.primary_network]);

  useEffect(() => {
    dispatch(getStoreTypes({}));
  }, []);

  return (
    <ActivateComponent
      onChange={onChange}
      categoryList={categoryList}
      form={form}
      setForm={setForm}
      createStore={createStore}
      setSearchVisible={setSearchVisible}
      searchVisible={searchVisible}
      handleFormSubmit={handleFormSubmit}
      onLookupResultClick={onResultClick}
      onLookupResultClickOutside={onResultsOutsideClick}
      lookUpResults={loopUpAdvertisersData.data.items}
      onLookupChange={onSearchChange}
      searchLoading={loopUpAdvertisersData.loading}
      onLookupSubmit={onSearchSubmit}
      getStoreTypesData={getStoreTypesData}
      getCouponPolicyData={getCouponPolicyData}
    />
  );
};

export default Activate;
