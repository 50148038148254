import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EmailSubsReportComponent from "layout/marketing/email/EmailSubsReport";
import { useEffect, useState } from "react";
import { resetEmailReport } from "redux/slices/marketing/email/getEmailSubsReport";
import getEmailSubscribersReport from "redux/thunks/marketing/emails/getEmailSubscribersReport";
import formatDate from "utils/dates/formatDate";
import monthsAgoDate from "utils/dates/monthsAgoDate";

type Props = {
  filterValues: Record<string, any>;
};
function EmailMembersReport({ filterValues }: Props) {
  const dispatch = useAppDispatch();
  const subsReportData = useAppSelector((state) => state.getEmailSubsReport);
  const startDate = monthsAgoDate(3);
  const endDate = dayjs(new Date());
  const {
    page,
    search,
    verified,
    program_location,
    is_home_page,
    is_distinct,
    report_view,
    dateFilterDone,
  } = filterValues;

  const [reportType, setReportType] = useState("daily");

  const getData = () => {
    dispatch(
      getEmailSubscribersReport({
        ...filterValues,
        start_date:
          filterValues.start_date ||
          formatDate(startDate.toString(), "yyyy-mm-d"),
        end_date:
          filterValues.end_date || formatDate(endDate.toString(), "yyyy-mm-dd"),
        report_type: reportType,
      })
    );
  };

  useEffect(() => {
    dispatch(resetEmailReport());
    getData();
  }, [
    page,
    search,
    verified,
    program_location,
    is_home_page,
    is_distinct,
    report_view,
    ,
    reportType,
  ]);

  useEffect(() => {
    if (dateFilterDone) {
      getData();
    }
  }, [dateFilterDone]);

  return (
    <EmailSubsReportComponent
      filterValues={{ ...filterValues, report_type: reportType }}
      subsReportData={subsReportData}
      setReportType={setReportType}
    />
  );
}

export default EmailMembersReport;
