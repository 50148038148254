import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getLeadTopics from "redux/thunks/sales_crm/getLeadTopics";
import { LeadTopic } from "types/model/sales_crm/Lead";

export type ActionStateType = {
  data: LeadTopic[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getLeadTopicsSlice = createSlice({
  name: "LeadTopics",
  initialState,
  reducers: {
    resetLeadTopics: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeadTopics.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getLeadTopics.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getLeadTopics.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetLeadTopics } = getLeadTopicsSlice.actions;
export default getLeadTopicsSlice.reducer;
