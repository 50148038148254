import DynamicFAQInput from "components/common/DynamicFAQInput";
import { ChangeEventHandler, Dispatch, SetStateAction } from "react";

type Props = {
  faqs: Record<string, any>[];
  onChange: ChangeEventHandler<HTMLInputElement>;
  onFormSubmit?: () => void;
  saveBtnLoading?: boolean;
  saveInitiator?: string;
  setSaveInitiator?: Dispatch<SetStateAction<string>>;
  renderInputOptions?: (form: Record<string, any>, input: any) => JSX.Element;
  regeneratedAnswer?: Record<string, any>;
  faqsForm?: Record<string, any>;
};

const Faqs = ({
  faqs,
  onChange,
  onFormSubmit,
  saveBtnLoading,
  saveInitiator,
  setSaveInitiator,
  renderInputOptions,
  regeneratedAnswer,
  faqsForm,
}: Props) => {
  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      <DynamicFAQInput
        key={faqs.length}
        saveBtnLoading={saveBtnLoading}
        onFormSubmit={onFormSubmit}
        faqs={faqs}
        onFormChange={onChange}
        saveInitiator={saveInitiator}
        setSaveInitiator={setSaveInitiator}
        renderInputOptions={renderInputOptions}
        regeneratedAnswer={regeneratedAnswer}
        faqsForm={faqsForm}
      />
    </section>
  );
};

export default Faqs;
