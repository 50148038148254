import { combineReducers } from "@reduxjs/toolkit";
import ActionType from "types/ActionType";
import getClients from "redux/slices/app/getClients";
import getCurrencies from "redux/slices/app/getCurrencies";
import clientReducers from "./website_clients/index";
import userReducers from "./users";
import networkReducers from "./networks";
import advertiserReducers from "./advertisers";
import linkReducers from "./links";
import semReducers from "./sem";
import salesReducers from "./sales";
import newsLetterFeedReducers from "./newsletterFeed";
import reportingReducers from "./reporting";
import extensionReducers from "./extension";
import salesCRMReducers from "./sales_crm";

const reducers = combineReducers({
  getClients,
  getCurrencies,
  ...advertiserReducers,
  ...networkReducers,
  ...userReducers,
  ...clientReducers,
  ...linkReducers,
  ...semReducers,
  ...salesReducers,
  ...newsLetterFeedReducers,
  ...reportingReducers,
  ...extensionReducers,
  ...salesCRMReducers,
});

const rootReducer = (state: any, action: ActionType) => {
  return reducers(state, action);
};

export default rootReducer;
