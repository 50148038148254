import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import searchActivePrograms from "redux/thunks/app/api-clients/searchActivePrograms";

export type ActionStateType = {
  data: Record<string, any>[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const searchActiveProgramsSlice = createSlice({
  name: "searchActivePrograms",
  initialState,
  reducers: {
    clearStoreSearchResults: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchActivePrograms.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(getProgram.pending, () => ({
        data: [],
        error: null,
        loading: true,
      }))
      .addCase(
        searchActivePrograms.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        searchActivePrograms.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const { clearStoreSearchResults } = searchActiveProgramsSlice.actions;
export default searchActiveProgramsSlice.reducer;
