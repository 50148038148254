import React, { RefObject, useCallback } from "react";
import { Accept, useDropzone } from "react-dropzone";

type Props = {
  accept?: Accept;
  onFilesDropped: (files: Array<File>) => void;
  ref?: RefObject<HTMLInputElement>;
  children: React.ReactNode;
};
function MyDropzone({ accept, onFilesDropped, ref, children }: Props) {
  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    onFilesDropped(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} ref={ref} />
      {/* {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )} */}

      {children}
    </div>
  );
}

export default MyDropzone;
