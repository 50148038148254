import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
} from "react";
import { debounce } from "lodash";
import Input from "./Input";
import { AxiosInstance } from "axios";

interface SearchInputComponentProps {
  endpoint: string;
  onResults: (results: any[]) => void;
  placeholder: string;
  client: AxiosInstance;
  label?: string;
  value?: string;
  wrapperClassName?: string;
}

const SearchInputComponent: React.FC<SearchInputComponentProps> = ({
  endpoint,
  onResults,
  placeholder,
  wrapperClassName,
  client,
  label,
  value,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (value) {
      setSearchTerm(value);
    }
  }, [value]);

  const fetchResults = async (query: string) => {
    if (!query) {
      onResults([]);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await client.get<any[]>(
        `${endpoint}?q=${query}&search_text=${query}&search=${query}&isCRM=1`
      );
      onResults(response.data);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Debounced fetch function
  const debouncedFetchResults = useCallback(debounce(fetchResults, 1000), []);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    debouncedFetchResults(newValue);
  };

  return (
    <div>
      <Input
        type="text"
        value={searchTerm}
        label={label}
        onChange={onChange}
        placeholder={placeholder || "Search..."}
        wrapperClassName={wrapperClassName}
      />
      {loading && <p className="text-blue-500 mt-2">Loading...</p>}
      {error && <p className="text-red-500 mt-2">Error: {error}</p>}
    </div>
  );
};

export default SearchInputComponent;
