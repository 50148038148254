/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getSubBrands from "redux/thunks/app/api-clients/getSubBrands";

import { PageNumPaginatedActionState } from "types/data";

const initialState: PageNumPaginatedActionState<Record<string, any>> = {
  data: {
    results: [],
    count: 0,
    page: 1,
    next: null,
    previous: null,
  },
  loading: false,
  error: null,
};

const getSubBrandsSlice = createSlice({
  name: "SubBrands",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubBrands.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getSubBrands.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )

      .addCase(
        getSubBrands.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});

export default getSubBrandsSlice.reducer;
