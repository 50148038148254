import React, { ChangeEventHandler } from "react";
import { twMerge } from "tailwind-merge";
import SaveButton from "./SaveFab";

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  id?: string;
  value?: string;
  allowSave?: boolean;
  required?: boolean;
  className?: string;
  wrapperClassName?: string;
  error?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onSave?: () => void;
  saveBtnLoading?: boolean;
}

function TextArea({
  label,
  name,
  value,
  className,
  placeholder,
  onChange,
  wrapperClassName,
  allowSave = false,
  onSave,
  error,
  saveBtnLoading = false,
  ...props
}: Props) {
  const cls = twMerge(
    "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500",
    error && " border-2 border-red-700 ",
    className
  );

  return (
    <div className={wrapperClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
        </label>
      )}
      <textarea
        rows={4}
        className={cls}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      >
        {value}
      </textarea>
      <div className="relative">
        <SaveButton
          visible={allowSave}
          onSave={onSave}
          loading={saveBtnLoading}
        />
      </div>
      {error && <small className="text-xs  text-red-700">{error}</small>}
    </div>
  );
}

export default TextArea;
