import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import Thunk from "../app/Thunk";

export default Thunk(
  "updateStoreExtSettings",
  async (payload: Record<string, any>) => {
    const requestPayload = {
      ...payload,
      store: payload.store.id,
      excluded: false,
    };
    delete requestPayload.store;

    return instance.patch(
      DISCOUNT_CODES_API + `/extension/store-extension-settings/${payload.id}`,
      requestPayload
    );
  }
);
