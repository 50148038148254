import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import Layout from "components/layout/Layout";
import countries from "constants/operationCountries";
import storeRanks from "constants/storeRanks";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
} from "react";
import { ProgramCategory } from "types/model/Category";
import ProgramImages from "components/program/ProgramImages";
import { ActionState } from "types/data";
import SearchBar from "components/program/SearchBar";
import { Advertiser } from "types/model/Advertiser";
import { MODE_MANUAL } from "constants/affiliate_site_url_modes";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import useLoadAuth from "hooks/useLoadAuth";
import CategorySelector from "components/common/CategorySelector";
import StoreTypeSelector from "components/program/StoreTypeSelector";
import { StoreType } from "types/model/oma/StoreType";
import { CouponPolicy } from "types/model/oma/CouponPolicy";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  handleFormSubmit: FormEventHandler<HTMLFormElement>;
  categoryList: ProgramCategory[];
  form: Record<string, any>;
  createStore: ActionState;
  onLookupResultClick: (res: Record<string, any>) => void;
  onLookupResultClickOutside: () => void;
  lookUpResults: Array<Advertiser>;
  onLookupChange: ChangeEventHandler<HTMLInputElement>;
  onLookupSubmit: () => void;
  searchLoading: boolean;
  searchVisible: boolean;
  setSearchVisible: Dispatch<SetStateAction<boolean>>;
  getStoreTypesData: ActionState<StoreType[]>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  getCouponPolicyData: ActionState<CouponPolicy[]>;
};

const ActivateComponent = ({
  onChange,
  categoryList,
  form,
  handleFormSubmit,
  createStore: { loading, error },
  onLookupResultClick,
  onLookupResultClickOutside,
  lookUpResults,
  searchVisible,
  onLookupChange,
  onLookupSubmit,
  searchLoading,
  setSearchVisible,
  getCouponPolicyData,
  getStoreTypesData,
  setForm,
}: Props) => {
  const { user } = useLoadAuth();

  return (
    <Layout>
      <div className="relative mx-auto p-4 w-full max-w-3xl h-full md:h-auto">
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Activate Program
            </h3>
          </div>

          <form onSubmit={handleFormSubmit}>
            <div className="grid gap-4 mb-4 sm:grid-cols-2">
              <div>
                <Input
                  label="Business Name"
                  name="name"
                  onChange={onChange}
                  required
                  value={form.name}
                />
              </div>

              {!searchVisible && (
                <div>
                  <Input
                    label="Primary Network"
                    onChange={onChange}
                    disabled
                    rightLabel={
                      <button
                        className="block mb-2 text-sm font-medium text-indigo-600 underline cursor-pointer dark:text-white"
                        onClick={() => {
                          setSearchVisible(true);
                        }}
                      >
                        Change
                      </button>
                    }
                    name="primary_network"
                    value={
                      (form.primary_network || "") +
                      "-" +
                      (form.network_mid || "")
                    }
                  />
                </div>
              )}

              {searchVisible && (
                <SearchBar
                  label="Search program to map"
                  onResultClick={onLookupResultClick}
                  submitButtonActive={false}
                  onResultsOutsideClick={onLookupResultClickOutside}
                  results={lookUpResults}
                  onChange={onLookupChange}
                  onSearchSubmit={onLookupSubmit}
                  placeholder="Search."
                  renderNetwork
                  defaultFocus
                  searchLoading={searchLoading}
                  rightLabel={
                    <button
                      className="block mb-2 text-sm font-medium text-indigo-600 underline cursor-pointer dark:text-white"
                      onClick={() => {
                        setSearchVisible(false);
                      }}
                    >
                      Cancel
                    </button>
                  }
                />
              )}

              <div>
                <Dropdown
                  label="Location"
                  options={countries.map((el) => ({
                    label: el,
                    value: el.toLowerCase(),
                  }))}
                  onChange={onChange}
                  name="location"
                  value={form.location}
                  error={error?.location}
                />
              </div>

              <div>
                <Input
                  //
                  label="Homepage URL"
                  name="website_url"
                  // id="website_url"
                  value={form.website_url}
                  onChange={onChange}
                  placeholder="https://"
                  // required
                  error={error?.website_url}
                />
              </div>
              <div>
                <Input
                  name="affiliate_website_url"
                  onChange={onChange}
                  label="Business Affiliate Homepage URL"
                  id="affiliate_website_url"
                  value={form.affiliate_website_url}
                  disabled={user?.user_role !== "ADMIN"}
                  placeholder="https://"
                  required
                  rightLabel={
                    form.affiliate_website_url_acquire_mode === MODE_MANUAL ? (
                      <InformationCircleIcon
                        height={18}
                        className="text-gray-400 cursor-pointer"
                        title="URL Acquired manually"
                      />
                    ) : null
                  }
                />
              </div>

              <div>
                <Input
                  label="Slug"
                  onChange={onChange}
                  name="slug"
                  id="slug"
                  value={form.slug}
                  required
                  error={error?.slug?.[0]}
                />
              </div>

              <div>
                <Dropdown
                  options={[{ name: "", value: "" }, ...storeRanks].map(
                    (item) => ({
                      label: item.name,
                      value: item.name,
                    })
                  )}
                  name="rank"
                  label="Rank"
                  value={form.rank}
                  onChange={onChange}
                />
              </div>

              <CategorySelector
                value={
                  form.primary_category &&
                  categoryList.find((it) => it.id === form.primary_category)
                    ?.name
                }
                onResultClick={(result) => {
                  onChange({
                    target: {
                      name: "primary_category",
                      value: result.id,
                    },
                  } as ChangeEvent<HTMLInputElement>);
                }}
                onFocus={() => {
                  onChange({
                    target: {
                      name: "primary_category",
                      value: undefined,
                    },
                  } as unknown as ChangeEvent<HTMLInputElement>);
                }}
                required
              />

              <div>
                <Dropdown
                  options={["Open", "Closed"].map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  label="PPC Policy"
                  name="ppc_policy"
                  value={form.ppc_policy}
                  onChange={onChange}
                />
              </div>

              <div>
                <MultipleSelectionInput
                  label="Similar Categories"
                  renderAll
                  options={categoryList.map((it) => ({
                    label: String(it.name),
                    value: String(it.id),
                    selected: form.similar_categories
                      ? form.similar_categories
                          .map((it: ProgramCategory) => it.id)
                          ?.includes(it.id)
                      : false,
                  }))}
                  onChange={(e) => {
                    const item = e.target.value;
                    const checked = e.target.checked;

                    const new_similar = checked
                      ? [
                          ...(form.similar_categories || []),
                          categoryList.find((it) => it.id === Number(item)),
                        ]
                      : form.similar_categories.filter(
                          (it: ProgramCategory) => it.id !== Number(item)
                        );

                    onChange({
                      target: {
                        name: "similar_categories",
                        value: new_similar,
                      },
                    } as unknown as ChangeEvent<HTMLInputElement>);
                  }}
                  name="similar_categories"
                  value={form.similar_categories}
                />
              </div>
            </div>

            <div className="mb-4">
              <MultipleSelectionInput
                wrapperClassName="w-80"
                options={form.coupon_policies}
                label="Coupon Policies"
                name="coupon_policies"
                value={form?.coupon_policies}
                renderAll
                loading={getCouponPolicyData.loading}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setForm((f) => ({
                      ...f,
                      coupon_policies: form.coupon_policies.map(
                        (el: Record<string, any>) =>
                          el.value == e.target.value
                            ? { ...el, selected: false }
                            : el
                      ),
                    }));
                  } else {
                    setForm((f) => ({
                      ...f,
                      coupon_policies: form.coupon_policies.map(
                        (el: Record<string, any>) =>
                          el.value == e.target.value
                            ? { ...el, selected: true }
                            : el
                      ),
                    }));
                  }
                }}
              />
            </div>

            <StoreTypeSelector
              form={form}
              getStoreTypesData={getStoreTypesData}
              onChange={onChange}
            />
            <div className="mb-4">
              <ProgramImages
                form={form}
                onChange={onChange}
                wrapperCls="mx-auto max-w-7xl sm:px-0 lg:px-0 mb-8"
              />
            </div>
            <div className="flex items-center space-x-4">
              <button
                disabled={loading}
                type="submit"
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {loading ? "Please wait..." : "Activate Business"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ActivateComponent;
