import { ActionState, PaginatedActionState } from "types/data";
import { Proposal, ProposalType } from "types/model/sales/Proposal";
import IORow from "./IORow";
import ProposalFilters from "./ProposalFilters";
import { ChangeEventHandler } from "react";
import { User } from "types/model/User";
import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import IO_COLUMNS from "constants/tables/headers/sales/ios";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  updateStatus: (record: Proposal, status: string) => void;
  createUpdateDeleteProposalData: ActionState;
  handleSendSigningRequest: (
    proposal_id: string,
    file_url: string,
    form: Record<string, any>
  ) => void;
  sendSigningRequestData: ActionState<Record<string, any> | null>;
  handleDownloadSignedPDF: (proposalId: string) => void;
  signedIOLoadConfig: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  filterValues: Record<string, any>;
  users: User[];
  getIosData: PaginatedActionState<Proposal>;
  onPageChange: (page: number) => void;
  handleUploadManualIOFile: (
    file: Record<string, any>,
    record: Proposal
  ) => void;
  getProposalStatsData: ActionState<Record<string, any>>;
  debouncedOnSearchChange: ChangeEventHandler<HTMLInputElement>;
  searchText: string;
  proposalTypes: PaginatedActionState<ProposalType>;
};

const IOLayout = ({
  handleSendSigningRequest,
  sendSigningRequestData,
  handleDownloadSignedPDF,
  signedIOLoadConfig,
  onChange,
  filterValues,
  users,
  onPageChange,
  getIosData: { data, loading },
  handleUploadManualIOFile,
  createUpdateDeleteProposalData,
  getProposalStatsData,
  debouncedOnSearchChange,
  searchText,
  proposalTypes,
}: Props) => {
  const renderRow: RowRenderer<Proposal> = (proposal) => {
    return (
      <IORow
        submitLoading={sendSigningRequestData.loading}
        handleSendSigningRequest={handleSendSigningRequest}
        proposal={proposal}
        key={proposal.id}
        createUpdateDeleteProposalData={createUpdateDeleteProposalData}
        handleUploadManualIOFile={handleUploadManualIOFile}
        handleDownloadSignedPDF={handleDownloadSignedPDF}
        sendSigningRequestData={sendSigningRequestData}
        signedIOLoadConfig={signedIOLoadConfig}
      />
    );
  };
  return (
    <DataTable
      columns={IO_COLUMNS}
      rows={data.items}
      loading={loading}
      totalCount={data.meta.count}
      onPageChange={onPageChange}
      currentPage={filterValues.page}
      header={
        <div>
          <ProposalFilters
            filterValues={filterValues}
            onChange={onChange}
            isIO
            users={users}
            searchText={searchText}
            proposalTypes={proposalTypes}
            debouncedOnSearchChange={debouncedOnSearchChange}
          />
        </div>
      }
      rowRenderer={renderRow as RowRenderer<Record<string, any>>}
      footer={
        data.meta.count ? (
          <div className="flex justify-between pl-2 w-full">
            <div></div>
            <div>
              {Object.entries(getProposalStatsData.data)
                .filter(([_, v]) => v > 0)
                .map(([key, value]) => {
                  return (
                    <span className="text-xs" key={key}>
                      {key} : {formatNumber(value, {})}{" "}
                    </span>
                  );
                })}
            </div>
          </div>
        ) : null
      }
    />
  );
};
export default IOLayout;
