import { ActionState } from "types/data";
import Layout from "components/layout/Layout";
import { Card } from "flowbite-react";
import Stepper from "./Stepper";

type Props = {
  getExtReportData: ActionState;
};

const VisitsDetailReport = ({ getExtReportData: { data, loading } }: Props) => {
  return (
    <Layout loading={loading}>
      {data.results?.map((el: Record<string, any>) => {
        return (
          <Card
            key={el.domain}
            className="my-2 items-start justify-start overflow-x-clip"
          >
            <h2 className="text-base">{el.domain}</h2>
            <Stepper steps={el.fullUrls} />
          </Card>
        );
      })}
    </Layout>
  );
};

export default VisitsDetailReport;
