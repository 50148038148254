import { Proposal, ProposalType } from "types/model/sales/Proposal";
import { ActionState, PaginatedActionState } from "types/data";
import ProposalFilters from "./ProposalFilters";
import { ChangeEventHandler } from "react";
import { User } from "types/model/User";
import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import PROPOSAL_COLUMNS from "constants/tables/headers/sales/proposals";
import ProposalRow from "./ProposalRow";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  getProposalsData: PaginatedActionState<Proposal>;
  getProgramData: ActionState;
  updateStatus: (record: Proposal, status: string) => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  filterValues: Record<string, any>;
  users: User[];
  onPageChange: (page: number) => void;
  getProposalStatsData: ActionState<Record<string, any>>;
  searchText: string;
  proposalTypes: PaginatedActionState<ProposalType>;
  deleteProposal: (proposal: Proposal, delete_reason: string) => void;
  createUpdateDeleteProposalData: ActionState;
};

const ProposalsLayout = ({
  updateStatus,
  onChange,
  filterValues,
  users,
  getProposalsData: { data, loading },
  getProposalStatsData,
  onPageChange,
  searchText,
  proposalTypes,
  deleteProposal,
  createUpdateDeleteProposalData,
}: Props) => {
  const renderRow: RowRenderer<Proposal> = (proposal) => {
    return (
      <ProposalRow
        updateStatus={updateStatus}
        proposal={proposal}
        key={proposal.id}
        deleteProposal={deleteProposal}
        createUpdateDeleteProposalData={createUpdateDeleteProposalData}
      />
    );
  };

  return (
    <DataTable
      rows={data.items}
      columns={PROPOSAL_COLUMNS}
      loading={loading}
      header={
        <ProposalFilters
          filterValues={filterValues}
          onChange={onChange}
          proposalTypes={proposalTypes}
          debouncedOnSearchChange={onChange}
          isIO={false}
          users={users}
          searchText={searchText}
        />
      }
      rowRenderer={renderRow as RowRenderer<Record<string, any>>}
      currentPage={filterValues.page}
      onPageChange={onPageChange}
      totalCount={data.meta.count}
      footer={
        data.meta.count ? (
          <div className="flex justify-between pl-2 w-full">
            <div></div>
            <div>
              {Object.entries(getProposalStatsData.data)
                .filter(([_, v]) => v > 0)
                .map(([key, value]) => {
                  return (
                    <span className="text-xs" key={key}>
                      {key} : {formatNumber(value, {})}{" "}
                    </span>
                  );
                })}
            </div>
          </div>
        ) : null
      }
    />
  );
};
export default ProposalsLayout;
