import Input from "components/common/Input";
import { ChangeEventHandler } from "react";
import { HiCheck } from "react-icons/hi";
import { AdKeyword } from "types/data";
import Spinner from "./layout/Spinner";

type Props = {
  item: AdKeyword;
  form: Record<string, any>;
  updateKeyword: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  loading: boolean;
};

function KeywordInput({ item, form, onChange, updateKeyword, loading }: Props) {
  return (
    <div className="my-2 flex gap-4" key={item.id}>
      <Input value={item.text} />

      <div className="flex relative items-center">
        <Input
          value={form.bid_amount}
          wrapperClassName="min-w-24"
          onChange={onChange}
          name="bid_amount"
        />
        {form.bid_amount !== item.bid_amount && !loading && (
          <button className="absolute -right-10" onClick={updateKeyword}>
            <HiCheck color="green" size={24} />
          </button>
        )}

        {form.bid_amount !== item.bid_amount && loading && (
          <div className="absolute -right-12">
            <Spinner className="w-6 h-6" />
          </div>
        )}
      </div>
    </div>
  );
}

export default KeywordInput;
