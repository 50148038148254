import React from "react";

interface ThumbnailProps {
  name: string;
}

const getInitials = (name: string): string => {
  const nameArray = name.split(" ");
  const initials = nameArray.map((word) => word[0]).join("");
  return initials.toUpperCase();
};

const getRandomColor = (): string => {
  const colors = [
    "bg-red-500",
    "bg-green-500",
    "bg-blue-500",
    "bg-yellow-500",
    "bg-purple-500",
    "bg-pink-500",
    "bg-indigo-500",
    "bg-gray-500",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const Thumbnail: React.FC<ThumbnailProps> = ({ name }) => {
  const initials = getInitials(name);
  const bgColor = getRandomColor();

  return (
    <div
      className={`w-16 h-16 rounded-full flex items-center justify-center ${bgColor}`}
    >
      <span className="text-white text-xl font-bold">
        {initials.substring(0, 2)}
      </span>
    </div>
  );
};

export default Thumbnail;
