const getFinalAmount = (totalAmount: number, form: Record<string, any>) => {
  if (form.discount_type === "Percent") {
    return totalAmount - totalAmount * (form.offer_discount / 100);
  }

  if (form.discount_type === "Amount") {
    return totalAmount - form.offer_discount;
  }

  return totalAmount;
};
export default getFinalAmount;
