import { Column } from "types/column";

const SEM_CAMPAIGN_COLUMNS = [
  {
    key: "id",
    label: "id",
  },
  {
    key: "search_engine",
    label: "Search Engine",
  },
  {
    key: "name",
    label: "Name",
    isSortable: false,
    classNames: {
      head: "text-green-400",
      body: "text-blue-700 cursor-pointer",
    },
  },
  {
    key: "website_base",
    label: "STATUS",
    isSortable: false,
  },

  {
    key: "network",
    label: "DAILY BUDGET",
    isSortable: false,
  },

  { key: "external_id", label: "START DATE", isSortable: false },
  { key: "commission_summary", label: "END DATE", isSortable: false },
] as Column[];

export default SEM_CAMPAIGN_COLUMNS;
