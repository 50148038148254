import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import generateCouponInfo from "redux/thunks/links/newsletters/generateCouponInfo";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const generateCouponInfoSlice = createSlice({
  name: "generateCouponInfo",
  initialState,
  reducers: {
    resetGenerateCouponInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateCouponInfo.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        generateCouponInfo.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        generateCouponInfo.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetGenerateCouponInfo } = generateCouponInfoSlice.actions;
export default generateCouponInfoSlice.reducer;
