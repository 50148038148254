import Layout from "components/layout/Layout";
import Input from "components/common/Input";
import TextArea from "components/common/TextArea";
import { ActionState } from "types/data";
import LayoutTabs from "./LayoutTabs";
import TabCreateCoupon from "containers/oma/CreateUpdateCoupon";
import StoreCoupons from "containers/oma/StoreCoupons";
import PendingProgramCoupons from "containers/oma/PendingProgramCoupons";
import { useSearchParams } from "react-router-dom";
import ProgramHeader from "containers/bma/ProgramHeader";
import Modal from "components/common/Modal";
import { twMerge } from "tailwind-merge";
import StoreCard from "components/common/StoreCard";
import PendingStoreNewsletters from "containers/oma/PendingStoreNewsletters";
import {
  PROGRAM_TAB_ACTIVATE_COUPON,
  PROGRAM_TAB_PENDING_API_COUPONS,
  PROGRAM_TAB_PENDING_NEWSLETTER,
  PROGRAM_TAB_VIEW_OFFERS,
} from "constants/programTabKeys";
import formatDate from "utils/dates/formatDate";

type Props = {
  link: Record<string, any>;
  linkId: string | null;
  getProgramData: ActionState<Record<string, any>>;
  createUpdateCouponData: ActionState;
  onAddCouponClicked: () => void;
  setSelectedProgram: (item: Record<string, any>) => void;
};

const OMAProgram = ({
  getProgramData: { data },
  link,
  linkId,
  onAddCouponClicked,
  setSelectedProgram,
}: Props) => {
  const [params] = useSearchParams();

  if (Array.isArray(data.results) && data.results.length) {
    return (
      <Layout>
        <Modal
          showCancel={false}
          showDone={false}
          open={true}
          setOpen={() => {
            //
          }}
          onConfirm={() => {
            //
          }}
        >
          <div className=" p-4">
            <h1 className="text-lg">Multiple Programs Found</h1>

            <p className="text-sm py-2">
              This program has multiple sub-brands. Please Select the right
              sub-brand page to activate this offer.
            </p>
            {data.results.length > 0 && (
              <ul
                className={twMerge(
                  " z-10 mt-4  w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm "
                )}
              >
                {data.results.map((result) => (
                  <StoreCard
                    store={result}
                    key={result.id}
                    onStoreClicked={(result) => {
                      setSelectedProgram(result);
                    }}
                  />
                ))}
              </ul>
            )}
          </div>
        </Modal>
      </Layout>
    );
  }
  return (
    <Layout>
      <ProgramHeader />
      {linkId &&
        link &&
        params.get("tab") === "create-coupon" &&
        params.get("activate") !== "0" && (
          <div className="flex flex-col md:flex-row gap-2 sm:gap-4 divide-x-2 justify-between bg-[#eceef8]">
            <div className="flex flex-col w-full sm:w-full md:w-1/2 lg:w-1/3 gap-4 p-4 sm:px-2">
              <div>
                <p className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200">
                  ID: {data.primary_network?.network_mid}
                </p>
              </div>

              <div className="flex items-center gap-2">
                <Input
                  label="Date Created"
                  name={"offer_type"}
                  value={formatDate(link.created_at)}
                />

                <Input
                  label="Modified since"
                  name={"offer_type"}
                  value={formatDate(link.updated_at)}
                />
              </div>
              <div>
                <Input
                  label="Promo Code"
                  name={"discount_code"}
                  disabled
                  value={link.coupon_code}
                />
              </div>
              <div>
                <Input
                  type="date"
                  label="Start Date"
                  name="start_date"
                  value={link.start_date && formatDate(link.start_date)}
                  disabled
                />
              </div>
              <div>
                <Input
                  type="date"
                  label="Expiration Date"
                  name="expiration_date"
                  value={link.end_date && formatDate(link.end_date)}
                  disabled
                />
              </div>
            </div>

            <div className="p-4 w-full space-y-8 lg:px-8">
              <div>
                <Input
                  label="Destination Coupon URL"
                  placeholder="https://"
                  name="destination_url"
                  disabled
                  value={link.sharing?.destination_url}
                />
              </div>
              <div className="">
                <Input
                  label="Destination Affiliate URL"
                  placeholder="https://"
                  name="store_url"
                  disabled
                  value={link.sharing?.tracking_url}
                />
              </div>
              <div>
                <Input
                  name="title"
                  label=" Coupon Headline"
                  placeholder="e.g. 10% Off Coupon Code"
                  value={link.name}
                />
              </div>

              <div>
                <TextArea
                  id="description"
                  rows={2}
                  label="Coupon Description"
                  maxLength={560}
                  name="description"
                  value={link.description}
                />
              </div>
            </div>
          </div>
        )}

      <LayoutTabs
        onAddCouponClicked={onAddCouponClicked}
        content={{
          [PROGRAM_TAB_PENDING_API_COUPONS]: <PendingProgramCoupons />,
          [PROGRAM_TAB_ACTIVATE_COUPON]: <TabCreateCoupon />,
          [PROGRAM_TAB_VIEW_OFFERS]: <StoreCoupons />,
          [PROGRAM_TAB_PENDING_NEWSLETTER]: <PendingStoreNewsletters />,
        }}
      />
    </Layout>
  );
};

export default OMAProgram;
