import RevenueSnapshot, {
  QuarterSalesRevenue,
} from "../components/RevenueSnapshot";
import RevenueChart from "../components/RevenueChart";
import ActivityFeedTable from "../components/ActivitySummary";
import ManagerHeader from "../components/ManagerHeader";
import { Dispatch, SetStateAction } from "react";

type Props = {
  quarterNumbers: QuarterSalesRevenue;
  setUserId: Dispatch<SetStateAction<string>>;
  isAdmin: boolean;
  loading: boolean;
};

export default function SalesDashboard({
  quarterNumbers,
  isAdmin,
  setUserId,
  loading,
}: Props) {
  return (
    <>
      <div className="mx-auto px-4 py-6 grid ">
        <div className="flex flex-col space-y-4">
          <ManagerHeader />
          <div className="flex flex-row justify-between gap-4">
            <div className="w-full">
              <RevenueSnapshot
                isAdmin={isAdmin}
                setUserId={setUserId}
                quarterNumbers={quarterNumbers}
                loading={loading}
              />
            </div>
            <div>
              <ActivityFeedTable />
            </div>
          </div>
          <RevenueChart />
        </div>
      </div>
    </>
  );
}
