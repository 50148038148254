export const appRoutes = [
  { pk: 1, name: "Coupons", path: "/oma" },

  { pk: 2, name: "Programs", path: "/bma?network_id=cj" },

  { pk: 3, name: "Marketing", path: "/marketing/search" },

  { pk: 4, name: "Reporting", path: "/reporting/revenue" },

  { pk: 5, name: "Sales", path: "/sales/crm/?tab=1&tab-id=home" },

  { pk: 6, name: "CRM", path: "/crm" },
];
