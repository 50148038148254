import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import AIFaqsLayout from "layout/marketing/content/AIFaqsLayout";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import getAIFaqs from "redux/thunks/marketing/content/getAIFaqs";
import refreshStoreAIFaq from "redux/thunks/marketing/content/refreshStoreAIFaq";

const AIFaqs = () => {
  const [params] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { page } = Object.fromEntries(params);
  const aiFaqs = useAppSelector((state) => state.getAIFaqs);
  const saveStoreFaqsData = useAppSelector((state) => state.saveStoreFaqs);

  const refreshStoreFaqData = useAppSelector(
    (state) => state.regenerateStoreAIFaqs
  );

  const refreshStoreFaq = (storeId: string) => {
    dispatch(refreshStoreAIFaq({ store_id: storeId })).then((res) => {
      if (res.meta.requestStatus !== "fulfilled") {
        toast.error("An error occurred");
      } else {
        toast.success("Faqs Regenerated");
      }
    });
  };

  useEffect(() => {
    dispatch(getAIFaqs({}));
  }, []);

  useEffect(() => {
    if (page) {
      dispatch(
        getAIFaqs({
          page,
        })
      );
    }
  }, [page]);

  useEffect(() => {
    if (typeof searchText === "string") {
      dispatch(
        getAIFaqs({
          search: searchText,
          page: 1,
        })
      );
    }
  }, [searchText]);

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const debouncedOnChange = debounce((e) => {
    setSearchText(String(e.target.value));
  }, 1000);

  return (
    <AIFaqsLayout
      saveStoreFaqsData={saveStoreFaqsData}
      refreshStoreFaq={refreshStoreFaq}
      aiFaqs={aiFaqs}
      refreshStoreFaqData={refreshStoreFaqData}
      searchText={searchText}
      page={Number(page) || 1}
      debouncedOnChange={debouncedOnChange}
      onPageChange={onPageChange}
    />
  );
};

export default AIFaqs;
