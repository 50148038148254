import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import { ITEMS_PER_PAGE } from "constants/misc";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import axios from "helpers/axios";

type Params = {
  page: number;
  search_text?: string;
  search_intent?: "mapping" | "pending-programs";
};

export default createAsyncThunk(
  "advertisers/lookup-network-advertisers",
  async ({ page, search_text, search_intent }: Params, ThunkAPI) => {
    const url = new URL(
      `${BACKEND_BASE_URL}/admin/advertisers/network-advertisers/`,
    );
    const client_id = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID);
    if (client_id) {
      url.searchParams.set("client_id", client_id);
    }
    url.searchParams.set(
      "offset",
      String(page * (page === 1 ? 0 : ITEMS_PER_PAGE)),
    );

    if (search_text) {
      url.searchParams.set("search_text", search_text);
    }

    if (search_intent) {
      url.searchParams.set("search_intent", search_intent);
    }

    try {
      const response = await axios.get(
        url.pathname
          .replace("/api", "")
          .replace("/production", "")
          .replace("/dev", "") + url.search,
      );

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
