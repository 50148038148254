import NetworkStatusRow from "components/advertisers/NetworkStatusRow";
import Spinner from "components/common/layout/Spinner";
import Layout from "components/layout/Layout";
import { PaginatedActionState } from "types/data";
import { Advertiser } from "types/model/Advertiser";
import getHostName from "utils/urls/getHostName";

type Props = {
  getADeactivatedAdvertisersData: PaginatedActionState<Advertiser>;
};

const DeactivatedPrograms = ({
  getADeactivatedAdvertisersData: { data, loading },
}: Props) => {
  return (
    <Layout>
      <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-2 py-4">
                  Program Name
                </th>
                <th scope="col" className="px-2 py-3">
                  Network
                </th>

                <th scope="col" className="px-2 py-3">
                  Status
                </th>
                <th scope="col" className="px-2 py-3">
                  Last update
                </th>
              </tr>
            </thead>
            {loading && <Spinner />}
            <tbody>
              {data.items
                .map((el: Record<string, any>) => ({
                  ...el,
                  website: el.website_url,
                  website_base: getHostName(el.website_url),
                  commission: {},
                  created_at: el.created,
                }))
                .map((item: Record<string, any>) => (
                  <NetworkStatusRow item={item} key={item.id} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default DeactivatedPrograms;
