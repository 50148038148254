import {
  WEBSITE_CLIENT_NAMES,
  websitesIdNameMap,
} from "./../clientWebsitesIds";

type ClientPageMap = {
  [key: string]: Array<string>;
};

const defaultPages = [
  "Homepage",
  "Parent Category",
  "Sub Category",
  "Sub 2 Category",
  "Custom",
  "Seasonal Categories",
  "Newsletters",
];

const clientPagesMap: ClientPageMap = {};

Object.entries(websitesIdNameMap).forEach(([k, v]) => {
  if (v != WEBSITE_CLIENT_NAMES.DISCOUNT_CODES_COM) {
    clientPagesMap[k] = defaultPages;
  } else {
    clientPagesMap[k] = [
      "Homepage",
      "Parent Category",
      "Sub Category",
      "Sub 2 Category",
      "Custom",
    ];
  }
});

export default clientPagesMap;
