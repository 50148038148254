import { Column } from "types/column";

const COUPON_COLUMNS = [
  {
    key: "",
    label: "",
    component: "components/common/Checkbox",
  },
  {
    key: "",
    label: "Program Name",
  },

  {
    key: "",
    label: "Coupon ID",
  },

  {
    key: "uploader",
    label: "Uploader",
  },

  {
    key: "",
    label: "Type",
  },
  {
    key: "",
    label: "Code",
  },
  {
    key: "",
    label: "Headline",
  },

  {
    key: "",
    label: "Description",
  },

  {
    key: "",
    label: "Start",
  },

  {
    key: "",
    label: "Expiration",
  },
  {
    key: "",
    label: "Created",
  },
  {
    key: "",
    label: "Action",
  },
] as Column[];

export const AI_COUPON_COLUMNS = [
  {
    key: "",
    label: "Created",
  },
  {
    key: "",
    label: "Program Name",
  },

  {
    key: "",
    label: "Type",
  },
  {
    key: "",
    label: "Code",
  },
  {
    key: "",
    label: "Headline",
  },

  {
    key: "",
    label: "Description",
  },

  {
    key: "",
    label: "AI Description",
  },

  {
    key: "",
    label: "Expiration",
  },

  {
    key: "",
    label: "Action",
  },
] as Column[];

export const SIMPLE_COUPON_COLUMNS = [
  "Merchant",
  "Headline",
  "Description",
  "",
  "",
].map((el) => ({
  key: el,
  label: el,
  isSelectAll: false,
  isSortable: false,
}));

export default COUPON_COLUMNS;
