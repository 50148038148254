const storeRanks = [
  {
    name: "Super",
    description: "stores with over 1M visits",
  },
  { name: "Runner-up", description: "stores with between 500K and 1M visits" },
  {
    name: "Rising Star",
    description: "stores with between 100K and },500K visits",
  },
  { name: "Starter", description: "less than 100K visits" },
  { name: "Sys1", description: "Tier1 from old system hub" },
  { name: "Sys2", description: "Tier2 from old system hub" },
  { name: "Sys3", description: "Tier3 from old system hub" },

  // SYS_1 = 'Sys1'
  // SYS_2 = 'Sys2'
  // SYS_3 = 'Sys3
];

export default storeRanks;
