import Checkbox from "components/common/Checkbox";
import Switch from "components/common/Switch";
import GoTo from "components/common/GoTo";

const components = {
  "components/common/Checkbox": Checkbox,
  "components/common/Switch": Switch,
  "components/common/GoTo": GoTo,
} as Record<string, any>;

export default components;
