import Button from "components/common/Button";
import BidAdjustmentsForm from "components/sem/BidAdjustmentsForm";
import { ChangeEventHandler } from "react";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  onSubmit: () => void;
  updateAdGroupLoading: boolean;
};

const EditDevicesComponent = ({
  updateAdGroupLoading,
  onChange,
  onSubmit,
  form,
}: Props) => {
  return (
    <div className="flex">
      <div className="w-1/2">
        <div className="mb-4">
          <h2>Edit Device Bid adjustments</h2>
        </div>
        <BidAdjustmentsForm
          form={form}
          formKey={form.search_engine}
          onChange={onChange}
          label="Device bid adjustments"
        />

        <Button
          type="button"
          onClick={onSubmit}
          loading={updateAdGroupLoading}
          text="Save changes"
        />
      </div>
      <div className="extra"></div>
    </div>
  );
};

export default EditDevicesComponent;
