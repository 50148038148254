import { Column } from "types/column";

const TODO_COLUMNS = [
  "Requested Date",
  "Request type",
  "Requested by",
  "Program Name (s)",
  "Network",
  "Notes",
  "Completed Date",
  "",
].map((it) => ({ isSortable: false, key: it, label: it })) as Column[];

export default TODO_COLUMNS;
