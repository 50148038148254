import { AiOutlineCheck, AiOutlineLoading3Quarters } from "react-icons/ai"; // Import icons from react-icons

interface SaveButtonProps {
  onSave?: () => void; // Updated to handle async save actions
  visible: boolean; // Control visibility of the button
  loading: boolean;
}

function SaveButton({ onSave, visible, loading = false }: SaveButtonProps) {
  const handleClick = () => {
    onSave?.();
  };

  if (!visible) return null;

  return (
    <button
      onClick={handleClick}
      className="absolute bottom-2 right-2 bg-primary-500 hover:bg-primary-700 text-white font-bold p-3 rounded-full shadow-md flex items-center justify-center"
      disabled={loading}
    >
      {loading ? (
        <AiOutlineLoading3Quarters width={16} className="animate-spin" />
      ) : (
        <AiOutlineCheck width={16} />
      )}
    </button>
  );
}

export default SaveButton;
