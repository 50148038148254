import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";

type Params = {
  page: number;
  headers?: any;
};

export default createAsyncThunk(
  "networks/created-networks",
  async ({ page }: Params, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/networks?offset=${getOffsetForPage(page)}`,
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
