import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCouponContributions from "redux/thunks/app/api-clients/performance/getCouponContributors";

export type CouponContributionsActionState = {
  data: Record<string, any> | null;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: CouponContributionsActionState = {
  data: null,
  loading: false,
  error: null,
};

const getCouponContributionsSlice = createSlice({
  name: "getCouponContributions",
  initialState,
  reducers: {
    resetGetCouponContributions: () => initialState,
    setCouponContributionDetails: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCouponContributions.pending, (state) => ({
        ...state,
        error: null,
        data: null,
        loading: true,
      }))
      .addCase(
        getCouponContributions.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )

      .addCase(
        getCouponContributions.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});
export const { resetGetCouponContributions, setCouponContributionDetails } =
  getCouponContributionsSlice.actions;
export default getCouponContributionsSlice.reducer;
