import { Column } from "types/column";

const PROPOSAL_COLUMNS = [
  {
    key: "Created At",
    label: "Created At",
    isSortable: true,
  },
  {
    key: "proposal_id",
    label: "Proposal ID",
    isSortable: true,
  },

  {
    key: "Account Manager",
    label: "Account Manager",
    isSortable: true,
  },

  { key: "Brand", label: "Brand", isSortable: true },
  { key: "Location", label: "Location", isSortable: true },

  { key: "Proposal Type", label: "Proposal Type", isSortable: true },

  { key: "Status", label: "Status", isSortable: false },
  { key: "Assigned IO", label: "Assigned IO", isSortable: false },
  { key: "Total Amount", label: "Total Amount", isSortable: false },

  { key: "Actions", label: "Actions", isSortable: false },
] as Column[];

export default PROPOSAL_COLUMNS;
