import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import Thunk from "../app/Thunk";

export default Thunk(
  "getNetworkExtSettings",
  async (payload: Record<string, any>) => {
    const url = new URL(
      DISCOUNT_CODES_API + "/extension/network-extension-settings/"
    );
    Object.entries(payload).map(([key, value]) => {
      url.searchParams.set(key, value);
    });
    return instance.get(url.href);
  }
);
