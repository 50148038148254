import Layout from "components/layout/Layout";
import ProgramHeader from "containers/bma/ProgramHeader";
import Tabs from "components/program/Tabs";
import { ChangeEventHandler, Dispatch, SetStateAction } from "react";
import { ActionStateType } from "redux/slices/clients/getProgram";
import { ActionState, PageNumPaginatedActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";
import { StoreType } from "types/model/oma/StoreType";
import { CouponPolicy } from "types/model/oma/CouponPolicy";

export type UpdateStoreOptions = { toggleIsActive: boolean };

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onFormSubmit: () => void;
  programDetails: ActionStateType;
  form: Record<string, any>;
  handleUpdateStore: (options: UpdateStoreOptions) => void;
  createUpdateStore: ActionState;
  getCategoriesData: ActionState<ProgramCategory[]>;
  getStoreTypesData: ActionState<StoreType[]>;
  getActivePrograms: PageNumPaginatedActionState<Array<Record<string, any>>>;
  onTabChange: (index: number) => void;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  getCouponPolicyData: ActionState<CouponPolicy[]>;
};

const Program = ({
  programDetails,
  form,
  handleUpdateStore,
  createUpdateStore,
  onChange,
  onFormSubmit,
  getCategoriesData,
  getActivePrograms,
  onTabChange,
  getStoreTypesData,
  setForm,
  getCouponPolicyData,
}: Props) => {
  return (
    <Layout>
      {programDetails.data.id && (
        <>
          <ProgramHeader handleUpdateStore={handleUpdateStore} />

          <Tabs
            onChange={onChange}
            onTabChange={onTabChange}
            programDetails={programDetails}
            form={form}
            getCategoriesData={getCategoriesData}
            onFormSubmit={onFormSubmit}
            getActivePrograms={getActivePrograms}
            createUpdateStore={createUpdateStore}
            getStoreTypesData={getStoreTypesData}
            getCouponPolicyData={getCouponPolicyData}
            setForm={setForm}
          />
        </>
      )}
    </Layout>
  );
};

export default Program;
