/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCategory from "redux/thunks/app/api-clients/categories/getCategory";
import { ProgramCategory } from "types/model/Category";

export type ActionStateType = {
  data: ProgramCategory | null;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: null,
  loading: false,
  error: null,
};

const getCategorySlice = createSlice({
  name: "getCategory",
  initialState,
  reducers: {
    resetCategory: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getCategory.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getCategory.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetCategory } = getCategorySlice.actions;
export default getCategorySlice.reducer;
