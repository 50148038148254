import Input from "components/common/Input";
import Label from "components/common/Label";
import { ChangeEvent, ChangeEventHandler } from "react";

type Props = {
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  formKey: string;
};

const BidAdjustmentsForm = ({
  form,
  formKey,
  label = "Bid adjustments",
  onChange,
}: Props) => {
  return (
    <div>
      <Label label={label} />
      <div className="flex gap-4">
        {form.bidAdjustments[formKey].map((el: Record<string, any>) => (
          <div key={el.name} className="mt-2">
            <Input
              label={el.label}
              name={el.name}
              inputContainerClass="bg-white border-gray-200"
              className="bg-white"
              type="number"
              value={el.value}
              onChange={(e) => {
                const newBidAdjustMents = {
                  ...form.bidAdjustments,
                  [formKey]: form.bidAdjustments[formKey].map(
                    (el: { name: string }) =>
                      el.name === e.target.name
                        ? { ...el, value: e.target.value }
                        : el
                  ),
                };

                onChange({
                  ...e,
                  target: {
                    name: "bidAdjustments",
                    value: newBidAdjustMents,
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
              rightLabel={<small>%</small>}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BidAdjustmentsForm;
