import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getProposalStats from "redux/thunks/sales/getProposalsPaymentStats";

export type ActionStateType = {
  data: {
    "Total( USD)": 0;
    "Total Paid( USD)": 0;
    "Total (CAD)": 0;
    "Total Paid (CAD)": 0;
    "Total (EUR)": 0;
    "Total Paid (EUR)": 0;
    "Total (AUD)": 0;
    "Total Paid (AUD)": 0;
  };
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    "Total( USD)": 0,
    "Total Paid( USD)": 0,
    "Total (CAD)": 0,
    "Total Paid (CAD)": 0,
    "Total (EUR)": 0,
    "Total Paid (EUR)": 0,
    "Total (AUD)": 0,
    "Total Paid (AUD)": 0,
  },
  loading: false,
  error: null,
};

const getProposalStatsSlice = createSlice({
  name: "getProposalStats",
  initialState,
  reducers: {
    resetProposalStats: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProposalStats.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getProposalStats.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getProposalStats.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetProposalStats } = getProposalStatsSlice.actions;
export default getProposalStatsSlice.reducer;
