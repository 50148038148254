export const MODE_DEFAULT = "DEFAULT";
export const MODE_MANUAL = "MANUAL";

export const affiliateNetworksMap: Record<string, string> = {
  cj: "CJ",
  rakuten: "Rakuten",
  impact: "Impact",
  awin: "AWIN",
  Partnerize: "Partnerize",
  shareasale: "Shareasale",
  pepperjam: "Pepperjam",
  avantlink: "Avantlink",
  tradedoubler: "Tradedoubler",
  webgains: "Webgains",
  tradetracker: "TradeTracker",
  free: "Free",
};
