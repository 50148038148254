import Modal from "components/common/Modal";
import dateFormat from "dateformat";
import { truncate } from "lodash";
import { useEffect, useState } from "react";
import { ActionState } from "types/data";
import { EmailSubscriber } from "types/model/NewsletterSubscriber";

type Props = {
  row: EmailSubscriber;
  handleDeleteSubscriber: () => void;
  deleteEmailSubscriberData: ActionState<Record<string, any> | null>;
};

const EmailSubscriberRow = ({
  row,
  handleDeleteSubscriber,
  deleteEmailSubscriberData,
}: Props) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  useEffect(() => {
    if (deleteEmailSubscriberData.data) {
      setDeleteConfirmOpen(false);
    }
  }, [deleteEmailSubscriberData.data]);
  return (
    <tr>
      <Modal
        open={deleteConfirmOpen}
        setOpen={setDeleteConfirmOpen}
        onConfirm={handleDeleteSubscriber}
        doneText="Proceed"
        variant="sm"
        loading={deleteEmailSubscriberData.loading}
        doneBtnClass="bg-red-400 hover:bg-red-700"
      >
        <h1>Are you sure you want to delete this subscriber</h1>
      </Modal>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.created_at
          ? dateFormat(new Date(row.created_at), "dd/mm/yyyy")
          : ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.email}
      </td>

      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.phone_number}
      </td> */}
      <td className="whitespace-nowrap pl-4 py-4 text-sm text-gray-500">
        {row.device}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.ip_address}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.city}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.state}
      </td>

      <td className="whitespace-nowrap pl-4 py-4 text-sm text-gray-500">
        {row.country}
      </td>

      <td className="whitespace-nowrap pl-4 py-4 text-sm text-gray-500">
        {truncate(row.from_route, { length: 30 })}
      </td>

      <td className="whitespace-nowrap pl-4 py-4 text-sm text-gray-500">
        {row.verified ? "Verified" : "Pending"}
      </td>

      <td className="whitespace-nowrap pl-4 py-4 text-sm text-gray-500">
        {row.verified_at
          ? dateFormat(new Date(row.verified_at), "mm/dd/yyyy")
          : ""}
      </td>

      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
        <button
          onClick={() => {
            setDeleteConfirmOpen(true);
          }}
        >
          X
        </button>
      </td>
    </tr>
  );
};

export default EmailSubscriberRow;
