import { Column } from "types/column";

const NEWSLETTER_COLUMNS = [
  {
    key: "checkbox",
    label: "",
    component: "components/common/Checkbox",
  },

  {
    key: "advertiser.name",
    label: "Program Name",
    isSortable: false,
  },

  {
    key: "advertiser.name",
    label: "Network",
    isSortable: false,
  },

  { key: "subject", label: "Subject Line", isSortable: false },

  { key: "created_at", label: "Received", isSortable: false },

  { key: "Actions", label: "Action", isSortable: false },
] as Column[];

export default NEWSLETTER_COLUMNS;
