import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import axios from "helpers/axios";

type Params = {
  data: Record<string, any>;
};

export default createAsyncThunk(
  "api-clients/createUpdateCoupon",
  async ({ data }: Params, ThunkAPI) => {
    const payload = { ...data };
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );

      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }

      const clientWebsite = new ClientAPI();

      const response = await clientWebsite.createUpdateCoupon(payload);

      if (payload.activateNew) {
        const prev =
          localStorage.getItem("recently_activated_links") ||
          JSON.stringify([]);
        const items = [
          ...JSON.parse(prev),
          {
            link_id: payload.link_id,
            created: new Date(),
          },
        ];

        localStorage.setItem("recently_activated_links", JSON.stringify(items));
      }

      // update the pending links on operations hub only on api coupon activation for the website client

      if (response.data.link_id && !data.id) {
        axios.post(
          `/admin/advertisers/links/refresh-pending-programs?client_id=${selectedClientId}`,
          { resource: "links" }
        );
      }

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          }
      );
    }
  }
);
