import Modal from "components/common/Modal";
import dateFormat from "dateformat";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import DOMPurify from "dompurify";
import TabCreateCoupon from "containers/oma/CreateUpdateCoupon";
import Spinner from "components/common/layout/Spinner";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ActionState } from "types/data";
import { resetCreateUpdateCoupon } from "redux/slices/clients/coupons/createUpdateCoupon";
import replaceStringChars from "utils/strings/replaceCharacters";
import SearchPrograms from "containers/oma/SearchPrograms";
import { setProgramDetails } from "redux/slices/clients/getProgram";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import StoreCoupon from "containers/oma/StoreCoupons";
import {
  PROGRAM_TAB_ACTIVATE_COUPON,
  PROGRAM_TAB_VIEW_OFFERS,
} from "constants/programTabKeys";

type Props = {
  item: Record<string, any>;
  onReviewPressed: (link: Record<string, any>) => void;
  checked?: boolean;
  onItemSelectChange?: () => void;
  completeNewsLetterData: ActionState;
  skippableFields?: string[];
  completeNewsletter: (item: Record<string, any>) => void;
  createCouponData: ActionState;
  generateCouponInfo: (item: Record<string, any>) => void;
  generateCouponInfoData: ActionState;
  currentOffer: Record<string, any>;
  setCurrentOffer: Dispatch<SetStateAction<Record<string, any>>>;
  setMultiOfferMatchOpen: Dispatch<SetStateAction<boolean>>;
  pendingOffers: Record<string, any>[];
};

const AffiliateNewsLetterFeedRow = ({
  item,
  completeNewsletter,
  generateCouponInfo,
  checked,
  onItemSelectChange,
  completeNewsLetterData,
  skippableFields,
  createCouponData,
  generateCouponInfoData,
  currentOffer,
  setCurrentOffer,
  setMultiOfferMatchOpen,
  pendingOffers,
}: Props) => {
  const dispatch = useAppDispatch();
  const getProgramData = useAppSelector((state) => state.getProgram.data);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(PROGRAM_TAB_ACTIVATE_COUPON);

  const wrapperCls = twMerge(
    "border-b opacity-100 transition-all hover:bg-gray-100 ",
    completeNewsLetterData.loading && checked && "opacity-50"
  );

  useEffect(() => {
    if (completeNewsLetterData.data.items) {
      setModalOpen(false);
    }
  }, [completeNewsLetterData.data.items]);

  useEffect(() => {
    if (createCouponData.data.id) {
      setTimeout(() => {
        dispatch(resetCreateUpdateCoupon());
      }, 2000);
    }
  }, [createCouponData.data.id]);

  const onProgramResultClicked = (result: Record<string, any>) => {
    dispatch(setProgramDetails(result));
    dispatch(clearProgramSearchResults());
  };

  const programName = item.advertiser?.name || getProgramData.name;

  return (
    <React.Fragment>
      <Modal
        showDone={false}
        onOpen={() => {
          dispatch(resetCreateUpdateCoupon());
          dispatch(setProgramDetails({}));
          setCurrentOffer({});
          if (item.advertiser) {
            dispatch(
              getProgram({
                query: {
                  mid: item.advertiser?.external_id || item.advertiser?.id,
                  network: item.advertiser?.network_name || "",
                },
              })
            );
          }
        }}
        onConfirm={() => {
          //
        }}
        open={modalOpen}
        setOpen={setModalOpen}
        wrapperClassName="w-3/4"
      >
        <>
          <div className="flex justify-between gap-4">
            {
              <div className="text-sm">
                <>
                  <h1>
                    Create Offers {programName && "for"} {programName}
                  </h1>
                  {dateFormat(new Date(item.created_at), "mmm dS, yyyy")}
                </>
              </div>
            }
            {completeNewsLetterData.loading ||
            generateCouponInfoData.loading ? (
              <Spinner />
            ) : (
              <div className="flex gap-3">
                <button
                  className="text-sm font-medium text-blue-700"
                  onClick={() => generateCouponInfo(item)}
                >
                  Fill coupon info
                </button>

                <button
                  className="text-sm font-medium text-blue-700"
                  onClick={() => completeNewsletter(item)}
                >
                  Complete Newsletter{" "}
                </button>
              </div>
            )}
          </div>

          <div className="flex gap-8">
            <div className="w-1/2 h-[700px] overflow-scroll">
              <div>
                {
                  <div
                    onClick={(e) => {
                      if (e.type === "click") {
                        e.preventDefault();
                        const element = e.target as any;
                        if (element.href) {
                          window.open(element.href, "_blank");
                        }
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.email_body),
                    }}
                  />
                }
              </div>
            </div>
            <div className="flex-1 w-1/2 h-[700px] overflow-scroll">
              <SearchPrograms
                label="Program"
                onResultClicked={onProgramResultClicked}
                value={programName}
              />

              <nav className="isolate flex shadow">
                <button
                  onClick={() => {
                    setSelectedTab(PROGRAM_TAB_ACTIVATE_COUPON);
                  }}
                  className="text-gray-900   group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                >
                  <span>Create Coupon </span>
                  <span
                    className={twMerge(
                      "absolute inset-x-0 bottom-0 h-1",
                      selectedTab === PROGRAM_TAB_ACTIVATE_COUPON &&
                        "bg-indigo-500"
                    )}
                  />
                </button>

                <button
                  onClick={() => {
                    setSelectedTab(PROGRAM_TAB_VIEW_OFFERS);
                  }}
                  className="text-gray-900   group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                >
                  <span>Offers </span>
                  <span
                    className={twMerge(
                      "absolute inset-x-0 bottom-0 h-1",
                      selectedTab === PROGRAM_TAB_VIEW_OFFERS && "bg-indigo-500"
                    )}
                  />
                </button>
              </nav>

              {selectedTab === PROGRAM_TAB_ACTIVATE_COUPON && (
                <TabCreateCoupon
                  hiddenFormFields={["offer_source"]}
                  programMissing={!getProgramData.name}
                  setMultiOfferMatchOpen={setMultiOfferMatchOpen}
                  hasPendingOffers={pendingOffers?.length > 0}
                  initialForm={{
                    offer_source: "affiliate_newsletter",
                    tags: ["AFF NL"],
                    ...currentOffer,
                  }}
                />
              )}
              {selectedTab === PROGRAM_TAB_VIEW_OFFERS && <StoreCoupon />}
            </div>
          </div>
        </>
      </Modal>
      <tr className={wrapperCls}>
        {!skippableFields?.includes("checkbox") && (
          <td className="pl-4 py-2 w-4">
            <div className="flex items-center">
              <input
                id="checkbox-table-search-1"
                type="checkbox"
                checked={checked}
                onChange={onItemSelectChange}
                className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500  focus:ring-2"
              />
              <label htmlFor="checkbox-table-search-1" className="sr-only">
                checkbox
              </label>
            </div>
          </td>
        )}
        <th
          scope="row"
          className="py-2 font-medium text-gray-900 whitespace-nowrap"
        >
          {replaceStringChars(item.from_email, ["<", ">", '"'], "")}
        </th>
        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          {item.affiliate_network?.network_id}
        </th>
        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          {item.reply_to}
        </th>{" "}
        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          {item.subject}
        </th>
        <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
          <p
            className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
            style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
          >
            {dateFormat(new Date(item.received), "mm/d/yyyy, h:MM TT")}
          </p>
        </td>
        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
          <button
            type="button"
            onClick={() => {
              setModalOpen(true);
            }}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
            Review
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default AffiliateNewsLetterFeedRow;
