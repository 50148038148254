import classNames from "classnames";
import PopUnder, { PopUnderOption } from "components/common/PopUnder";
import { Advertiser } from "types/model/Advertiser";
import CommissionDisplay from "./CommissionDisplay";

type Props = {
  advertiser: Advertiser;
  store?: any;
  onMakePrimary: (adv: Advertiser) => void;
  handleIgnoreAdvertiser: (adv: Advertiser) => void;
  removePrimaryNetwork: (adv: Advertiser) => void;
  onLinkNetwork: (adv: Advertiser, args: Record<string, any>) => void;
  loadingCardId: null | string | number;
  ignored?: boolean;
};

function MappMerchantCard({
  advertiser,
  store,
  handleIgnoreAdvertiser,
  onMakePrimary,
  onLinkNetwork,
  loadingCardId,
  removePrimaryNetwork,
  ignored,
}: Props) {
  if (!Array.isArray(store.linked_networks)) {
    return null;
  }

  const isPrimary =
    (advertiser.external_id === store.primary_network?.network_mid &&
      advertiser.network === store.primary_network?.name) ||
    false;

  const linkedIds = store.linked_networks.map(
    (it: Record<string, any>) => it.network_mid,
  );
  const linkedNames = store.linked_networks.map(
    (it: Record<string, any>) => it.name,
  );

  const isLinked =
    linkedIds.includes(advertiser.external_id) &&
    linkedNames.includes(advertiser.network) &&
    !isPrimary;

  // eslint-disable-next-line eqeqeq
  const isLoading = advertiser.id == loadingCardId;

  const wrapperClassName = classNames(
    "col-span-1 divide-y divide-gray-200 rounded-lg  shadow",
    {
      "bg-green-300": isPrimary,
      "bg-yellow-400": isLinked,
      "bg-red-400": isLoading,
    },
  );

  const publishingOptions: PopUnderOption[] = [
    {
      title: isPrimary ? "Un Link" : "Make Primary",
      current: true,
      onClick: () =>
        isPrimary
          ? removePrimaryNetwork(advertiser)
          : onMakePrimary(advertiser),
      visible: !ignored,
    },
    {
      title: isLinked ? "Un Link" : "Link",
      current: false,
      visible: !isPrimary && !ignored,
      onClick: () =>
        onLinkNetwork(advertiser, {
          unLink: isLinked,
        }),
    },

    {
      title: ignored ? "Undo ignore" : "Ignore",
      current: false,
      visible: !isPrimary,
      onClick: () => handleIgnoreAdvertiser(advertiser),
    },
  ];

  return (
    <li className={wrapperClassName}>
      <div className="flex w-full items-center justify-between space-x-6 p-6 ">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-gray-900">
              {advertiser.name}
            </h3>

            <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
              {advertiser.network} Affiliate Network
            </span>
          </div>
          <p className="mt-1 truncate text-xs text-gray-500">
            MID: {advertiser.external_id}
          </p>
        </div>
      </div>
      <div>
        <div className="flex border-b">
          <div className="flex w-0 flex-1">
            <a
              href="/"
              className="p-2 inline-flex w-0 flex-1 items-center justify-start rounded-bl-lg border border-transparent text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              <span className="ml-1">{advertiser.website}</span>
            </a>
          </div>
        </div>

        <div className="flex border-b">
          <div className="flex w-0 flex-1">
            <a
              href="/"
              className="p-2 inline-flex w-0 flex-1 items-center justify-start rounded-bl-lg border border-transparent text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              <svg
                role="img"
                focusable="false"
                aria-hidden="true"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                data-testid="USD-icon"
                className="h-6"
              >
                <path
                  d="M16 0C7.17576 0 0 7.17576 0 16C0 24.8242 7.17576 32 16 32C24.8242 32 32 24.8242 32 16C32 7.17576 24.8242 0 16 0ZM16 30.0606C8.24242 30.0606 1.93939 23.7576 1.93939 16C1.93939 8.24242 8.24242 1.93939 16 1.93939C23.7576 1.93939 30.0606 8.24242 30.0606 16C30.0606 23.7576 23.7576 30.0606 16 30.0606Z"
                  fill="#1652F0"
                ></path>
                <path
                  d="M16.291 14.9139C14.3225 14.6327 13.9443 14.1673 13.9443 13.2461C13.9443 12.3733 14.6134 11.7527 15.8934 11.7527C17.057 11.7527 17.7261 12.16 17.9977 13.0909C18.0558 13.3042 18.2498 13.4497 18.4631 13.4497H19.4813C19.7625 13.4497 19.9855 13.1879 19.9274 12.9067C19.6074 11.4327 18.6086 10.5406 17.0474 10.2594V8.72728C17.0474 8.45576 16.834 8.24243 16.5625 8.24243H15.5928C15.3213 8.24243 15.108 8.45576 15.108 8.72728V10.2303C13.188 10.5018 11.9467 11.7818 11.9467 13.4206C11.9467 15.5443 13.2267 16.3685 15.9419 16.737C17.7843 17.0376 18.308 17.4352 18.308 18.4824C18.308 19.5297 17.4158 20.2279 16.1649 20.2279C14.468 20.2279 13.9152 19.4909 13.7116 18.5309C13.6631 18.3079 13.4692 18.1333 13.2364 18.1333H12.1213C11.8401 18.1333 11.6267 18.3855 11.6752 18.657C11.9564 20.2667 12.994 21.4594 15.108 21.7406V23.2727C15.108 23.5443 15.3213 23.7576 15.5928 23.7576H16.5625C16.834 23.7576 17.0474 23.5443 17.0474 23.2727V21.7406C19.0546 21.4206 20.3249 20.0242 20.3249 18.3079C20.3346 16.0388 18.948 15.2824 16.291 14.9139Z"
                  fill="#1652F0"
                ></path>
              </svg>

              <p className="ml-3">
                {advertiser.commission_config?.[0] ? (
                  <CommissionDisplay
                    firstCommissionConfig={advertiser.commission_config?.[0]}
                  />
                ) : (
                  "_"
                )}
              </p>
            </a>
          </div>
        </div>

        <div className="-mt-px flex divide-x divide-gray-200 justify-between px-2 items-center">
          <div className="flex ">
            <a
              href="/"
              className="relative -mr-px inline-flex  items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              <span className="ml-3">
                <small>Network status: </small>
                {advertiser.network_status}
              </span>

              <span className="ml-3">
                <small>Program status: </small>
                {advertiser.program_status || "_"}
              </span>
            </a>
          </div>
          <div className="-ml-px flex ">
            <PopUnder publishingOptions={publishingOptions} />
          </div>
        </div>
      </div>
    </li>
  );
}

export default MappMerchantCard;
