export const status = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  ACTIVE: 2,
  PAUSED: 3,
  REMOVED: 4,
  ENDED: 5,
  PENDING: 6,
  MISCONFIGURED: 7,
  LIMITED: 8,
  LEARNING: 9,
  NOT_ELIGIBLE: 10,
} as { [k: string]: number };
