const COMPETITOR_PROGRAM_COLUMNS = [
  {
    key: "",
    isSortable: false,
    label: "Last Update",
  },
  {
    key: "",
    isSortable: false,
    label: "Program Name",
  },
  {
    key: "",
    isSortable: false,
    label: "Competitor",
  },
  {
    key: "",
    isSortable: false,
    label: "Store",
  },
  {
    key: "",
    isSortable: false,
    label: "Has Campaign",
  },
];

export default COMPETITOR_PROGRAM_COLUMNS;
