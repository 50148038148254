/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import paginatedItemsInitialState from "redux/initial-states/paginatedItemsInitialState";
import getClients from "redux/thunks/app/getClients";
import { PaginatedActionState } from "types/data";
import { Client } from "types/model/Client";

const initialState: PaginatedActionState<Client> = paginatedItemsInitialState;

const getClientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getClients.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )
      .addCase(
        getClients.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});

export default getClientsSlice.reducer;
