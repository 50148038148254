import DataTable from "components/common/layout/DataTable";
import LinkRow from "components/links/LinkRow";
import TableHeader from "components/links/TableHeader";
import { Option } from "types/option";
import LINK_COLUMNS from "constants/tables/headers/oma/links";
import { ChangeEventHandler, FormEventHandler } from "react";
import { ActionState, PaginatedActionState, PaginatedData } from "types/data";
import { Link } from "types/model/Link";
import Spinner from "components/common/layout/Spinner";
import GroupedProgramLinks from "./GroupedProgramLinks";
import Message from "components/common/Message";
import { Advertiser } from "types/model/Advertiser";
import Pagination from "components/common/layout/Pagination";
import { ITEMS_PER_PAGE } from "constants/misc";

type Props = {
  getAllLinksState: PaginatedActionState<Link>;
  onActivatePressed: (arg: Record<string, any>) => void;
  onItemSelectChange: (adv: Link) => void;
  handleIgnoreLinks: (link?: Record<string, any>) => void;
  selectedItems: Record<string, any>[];
  ignoreLoading: boolean;
  onPageChange: (page: number) => void;
  currentPage: number;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  onFilterChange: ChangeEventHandler<HTMLInputElement>;
  has_coupon_code?: string;
  networkOptions: Option[];
  onSelectedNetworkChange: ChangeEventHandler<HTMLInputElement>;
  onSelectedRankChange: ChangeEventHandler<HTMLInputElement>;
  toggleIsProgramView: () => void;
  isProgramView: boolean;
  getAllLinksByProgramData: ActionState<PaginatedData<Advertiser>>;
  store_location?: string;
  rank?: string;
  network: string;
  program_has_no_codes?: string;
  program_has_no_coupons?: string;
  program_has_search_campaign: string;
  handleExpandItem: (arg: Record<string, any>) => void;
  expandedItemId: string | number;
  has_un_matched_emails?: boolean;
  has_no_end_date: string;
};

const OmaHome = ({
  onActivatePressed,
  toggleIsProgramView,
  getAllLinksState: { loading, data, error },
  onPageChange,
  currentPage,
  onSearchSubmit,
  onFilterChange,
  has_coupon_code,
  onItemSelectChange,
  selectedItems,
  ignoreLoading,
  handleIgnoreLinks,
  networkOptions,
  onSelectedNetworkChange,
  onSelectedRankChange,
  isProgramView,
  getAllLinksByProgramData,
  store_location,
  rank,
  network,
  program_has_no_codes,
  program_has_no_coupons,
  program_has_search_campaign,
  expandedItemId,
  has_no_end_date,
  handleExpandItem,
}: Props) => {
  const totalCouponCount = getAllLinksByProgramData.data.items.reduce(
    (acc, n) => acc + (n.network_advertiser_link_count || 0),
    0
  );

  if (error?.detail) {
    return <Message title="Error!" info description={error?.detail} />;
  }

  return (
    <div>
      {isProgramView ? (
        <div>
          <TableHeader
            program_has_no_codes={program_has_no_codes}
            program_has_no_coupons={program_has_no_coupons}
            total_items={totalCouponCount}
            updateDate={data.items?.[0]?.updated_at}
            onSearchSubmit={onSearchSubmit}
            rank={rank}
            network={network}
            has_coupon_code={has_coupon_code}
            onFilterChange={onFilterChange}
            store_location={store_location}
            advertiser_count={getAllLinksByProgramData.data.meta.count}
            selectedItems={selectedItems}
            handleIgnoreLinks={handleIgnoreLinks}
            networkOptions={networkOptions}
            onSelectedNetworkChange={onSelectedNetworkChange}
            onSelectedRankChange={onSelectedRankChange}
            toggleIsProgramView={toggleIsProgramView}
            program_has_search_campaign={program_has_search_campaign}
            isProgramView={isProgramView}
            has_no_end_date={has_no_end_date}
          />
          {getAllLinksByProgramData.loading && <Spinner />}

          <div>
            {getAllLinksByProgramData.data.items.map((el) => (
              <GroupedProgramLinks
                handleIgnoreLinks={handleIgnoreLinks}
                ignoreLoading={ignoreLoading}
                onActivatePressed={onActivatePressed}
                onItemSelectChange={onItemSelectChange}
                selectedItems={selectedItems}
                item={el}
                key={el.id}
                handleExpandItem={handleExpandItem}
                expandedItemId={expandedItemId}
              />
            ))}

            <Pagination
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={ITEMS_PER_PAGE}
              totalCount={getAllLinksByProgramData.data.meta.count}
            />
          </div>
        </div>
      ) : (
        <DataTable
          totalCount={data.meta.count}
          onPageChange={onPageChange}
          columns={LINK_COLUMNS}
          rows={data.items}
          currentPage={currentPage}
          rowRenderer={(row: any) => (
            <LinkRow
              item={row}
              onActivatePressed={onActivatePressed}
              handleIgnoreLinks={handleIgnoreLinks}
              ignoreLoading={ignoreLoading}
              checked={selectedItems.map((el) => el.id).includes(row.id)}
              onItemSelectChange={() => {
                onItemSelectChange(row);
              }}
            />
          )}
          header={
            <TableHeader
              has_no_end_date={has_no_end_date}
              program_has_no_codes={program_has_no_codes}
              program_has_no_coupons={program_has_no_coupons}
              program_has_search_campaign={program_has_search_campaign}
              updateDate={data.items?.[0]?.updated_at}
              total_items={data.meta.count}
              onSearchSubmit={onSearchSubmit}
              has_coupon_code={has_coupon_code}
              onFilterChange={onFilterChange}
              advertiser_count={data.meta.extra?.advertiser_count || 0}
              selectedItems={selectedItems}
              handleIgnoreLinks={handleIgnoreLinks}
              store_location={store_location}
              networkOptions={networkOptions}
              onSelectedNetworkChange={onSelectedNetworkChange}
              onSelectedRankChange={onSelectedRankChange}
              toggleIsProgramView={toggleIsProgramView}
              isProgramView={isProgramView}
              rank={rank}
              network={network}
            />
          }
          loading={loading}
        />
      )}
    </div>
  );
};

export default OmaHome;
