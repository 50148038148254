import Dropdown from "components/common/Dropdown";
import SearchForm from "components/common/SearchForm";
import countries from "constants/operationCountries";
import storeRanks from "constants/storeRanks";
import { timeAgo } from "index";
import { ChangeEventHandler, FormEventHandler } from "react";
import { Link } from "react-router-dom";
import { Option } from "types/option";

type Props = {
  onFilterChange: ChangeEventHandler<HTMLInputElement>;
  has_coupon_code?: string;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  advertiser_count?: number;
  total_items: number;
  selectedItems?: Record<string, any>[];
  handleIgnoreLinks?: () => void;
  networkOptions: Option[];
  onSelectedNetworkChange: ChangeEventHandler<HTMLInputElement>;
  onSelectedRankChange: ChangeEventHandler<HTMLInputElement>;
  toggleIsProgramView?: () => void;
  isProgramView?: boolean;
  updateDate?: string;
  store_location?: string;
  rank?: string;
  email_account?: string;
  network: string;
  program_has_no_codes?: string;
  program_has_no_coupons?: string;
  program_has_search_campaign?: string;
  has_un_matched_emails?: string;
  title?: string;
  isNewsletter?: boolean;
  isAffiliateNewsletter?: boolean;
  emailAccountOptions?: Option[];
  has_no_end_date?: string;
  completeNewsletter?: () => void;
};

const TableHeader = ({
  onFilterChange,
  onSearchSubmit,
  has_coupon_code,
  advertiser_count,
  total_items,
  selectedItems,
  handleIgnoreLinks,
  onSelectedNetworkChange,
  onSelectedRankChange,
  networkOptions,
  toggleIsProgramView,
  isProgramView,
  store_location,
  updateDate,
  rank,
  network,
  program_has_no_codes,
  program_has_no_coupons,
  program_has_search_campaign,
  title,
  email_account,
  isNewsletter,
  has_un_matched_emails,
  isAffiliateNewsletter,
  emailAccountOptions,
  has_no_end_date,
  completeNewsletter,
}: Props) => {
  return (
    <>
      <div className="border-b dark:border-gray-700 mx-4 my-4">
        <div className="flex items-center justify-between space-x-4 pt-3">
          <div className="flex-1 flex items-center space-x-3">
            <h5 className="dark:text-white font-semibold">
              {title || "API Coupon Offers"}
            </h5>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3">
          <div className="w-full  flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
            {!isAffiliateNewsletter ? (
              <SearchForm
                wrapperClassName="w-56 mr-4"
                onChange={onFilterChange}
                onSearchSubmit={onSearchSubmit}
              />
            ) : (
              <div></div>
            )}
            <div className="flex items-center space-x-4">
              <div>
                <Dropdown
                  options={networkOptions}
                  name="search"
                  value={network}
                  placeholder="Select networks"
                  onChange={onSelectedNetworkChange}
                />
              </div>

              <div>
                {!isAffiliateNewsletter ? (
                  <Dropdown
                    options={storeRanks.map((el) => ({
                      label: el.name,
                      value: el.name,
                    }))}
                    value={rank}
                    name="rank"
                    placeholder="Select rank"
                    onChange={onSelectedRankChange}
                  />
                ) : (
                  <Dropdown
                    options={emailAccountOptions || []}
                    value={email_account}
                    name="email_account"
                    placeholder="Select Account"
                    onChange={onSelectedRankChange}
                  />
                )}
              </div>
              {!isAffiliateNewsletter && (
                <div>
                  <Dropdown
                    options={countries.map((el) => ({
                      label: el,
                      value: el.toLowerCase(),
                    }))}
                    name="store_location"
                    placeholder="Location"
                    value={store_location}
                    onChange={onSelectedRankChange}
                  />
                </div>
              )}
              <div>
                {!isNewsletter && (
                  <button
                    id="configurationDropdownButton"
                    data-dropdown-toggle="configurationDropdown"
                    type="button"
                    onClick={toggleIsProgramView}
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4 mr-2 text-gray-400"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 01-.517.608 7.45 7.45 0 00-.478.198.798.798 0 01-.796-.064l-.453-.324a1.875 1.875 0 00-2.416.2l-.243.243a1.875 1.875 0 00-.2 2.416l.324.453a.798.798 0 01.064.796 7.448 7.448 0 00-.198.478.798.798 0 01-.608.517l-.55.092a1.875 1.875 0 00-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 01-.064.796l-.324.453a1.875 1.875 0 00.2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 01.796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 01.517-.608 7.52 7.52 0 00.478-.198.798.798 0 01.796.064l.453.324a1.875 1.875 0 002.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 01-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 001.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 01-.608-.517 7.507 7.507 0 00-.198-.478.798.798 0 01.064-.796l.324-.453a1.875 1.875 0 00-.2-2.416l-.243-.243a1.875 1.875 0 00-2.416-.2l-.453.324a.798.798 0 01-.796.064 7.462 7.462 0 00-.478-.198.798.798 0 01-.517-.608l-.091-.55a1.875 1.875 0 00-1.85-1.566h-.344zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {isProgramView ? "Default view" : "Program View"}
                  </button>
                )}
              </div>
              <div className="flex py-1 items-center ">
                <Link
                  to={isAffiliateNewsletter ? "/oma?tab=3" : "/oma"}
                  className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Reset
                </Link>
              </div>
            </div>
            {isAffiliateNewsletter && (
              <div className="flex items-center mt-3 ">
                {!!selectedItems?.length && (
                  <button
                    type="button"
                    className="inline-flex items-center
                px-2 py-1 text-sm font-medium
               text-blue-700  ring-inset underline
                ring-gray-300  disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    onClick={() => completeNewsletter?.()}
                  >
                    Bulk Complete ({selectedItems.length})
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-4 pb-3 flex flex-wrap items-center justify-between">
        {!isNewsletter ? (
          <div className="flex flex-wrap ">
            {!isProgramView && (
              <div className="flex items-center mt-3 mr-4">
                <input
                  id="all-tasks"
                  type="checkbox"
                  checked={has_coupon_code === "1"}
                  name="has_coupon_code"
                  onChange={onFilterChange}
                  className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="all-tasks"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Promo Code
                </label>
              </div>
            )}

            {!isNewsletter && !isProgramView && (
              <div className="flex items-center mr-4 mt-3">
                <input
                  checked={has_no_end_date === "1"}
                  name="has_no_end_date"
                  type="checkbox"
                  onChange={onFilterChange}
                  className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="in-progress"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  No End Date
                </label>
              </div>
            )}

            <div className="flex items-center mr-4 mt-3">
              <input
                checked={program_has_no_coupons === "1"}
                name="program_has_no_coupons"
                type="checkbox"
                onChange={onFilterChange}
                className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="completed"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Pages with No Coupons
              </label>
            </div>
            <div className="flex items-center mr-4 mt-3">
              <input
                checked={program_has_no_codes === "1"}
                name="program_has_no_codes"
                type="checkbox"
                onChange={onFilterChange}
                className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="in-progress"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Pages with No Codes
              </label>
            </div>

            <div className="flex items-center mr-4 mt-3">
              <input
                checked={program_has_search_campaign === "1"}
                name="program_has_search_campaign"
                type="checkbox"
                onChange={onFilterChange}
                className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="in-progress"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Pages with Search Campaigns
              </label>
            </div>

            {isNewsletter && !isAffiliateNewsletter && (
              <div className="flex items-center mr-4 mt-3">
                <input
                  checked={has_un_matched_emails === "1"}
                  name="has_un_matched_emails"
                  type="checkbox"
                  onChange={onFilterChange}
                  className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="in-progress"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Un matched Emails
                </label>
              </div>
            )}

            <div className="flex items-center mt-3 ">
              {!!selectedItems?.length && (
                <button
                  type="button"
                  className="inline-flex items-center
                px-2 py-1 text-sm font-medium
               text-blue-700  ring-inset underline
                ring-gray-300  disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={handleIgnoreLinks}
                >
                  Bulk Ignore ({selectedItems.length})
                </button>
              )}
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div className="flex items-center gap-4 align-baseline">
          {!isNewsletter && (
            <p className="font-medium text-gray-900 text-xs">
              {advertiser_count} Merchant{advertiser_count === 1 ? "" : "s"}
            </p>
          )}
          <p className="font-medium text-gray-900 text-xs">
            {total_items} Coupons
          </p>
          <div className="flex">
            <svg
              aria-hidden="true"
              className="w-4 h-4 mr-1 -ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            {updateDate && (
              <p className="font-medium text-gray-900 text-xs">
                {timeAgo.format(new Date(updateDate))}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableHeader;
