import { Column } from "types/column";

const EMAIL_CAMPAIGN_SETTINGS_COLUMNS = [
  "Site",
  "Location",
  "Template Type",
  "Starter Line",
  "From Date",
  "To Date",
].map((el) => ({
  key: el,
  label: el,
})) as Column[];

export default EMAIL_CAMPAIGN_SETTINGS_COLUMNS;
