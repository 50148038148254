import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { BACKEND_BASE_URL } from "config/env";

export default createAsyncThunk(
  "clients/emails/email-subscribers-report",
  async (filters: Record<string, string | number>, ThunkAPI) => {
    try {
      const url = new URL(`${BACKEND_BASE_URL}/admin/emails/subs-reporting/`);

      Object.entries(filters).forEach(([k, v]) => {
        url.searchParams.set(k, String(v));
      });

      const response = await axios.get(url.href);

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
