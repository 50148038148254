import searchActivePrograms from "redux/slices/clients/searchActivePrograms";
import getActivePrograms from "redux/slices/clients/getActivePrograms";
import getProgram from "redux/slices/clients/getProgram";
import createUpdateStore from "redux/slices/clients/createUpdateStore";
import getSubBrands from "redux/slices/clients/getSubBrands";
import getStoreTypes from "redux/slices/clients/getStoreTypes";
import getCouponPolicies from "redux/slices/clients/getCouponPolicies";

const storeReducers = {
  searchActivePrograms,
  getActivePrograms,
  getProgram,
  createStore: createUpdateStore,
  getSubBrands,
  getStoreTypes,
  getCouponPolicies,
};

export default storeReducers;
