import { Column } from "types/column";

const DEFAuLT_COLUMNS = [
  {
    key: "tx_count",
    label: "# Transactions",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "sale_amount_total",
    label: "Transaction Value",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "sale_amount_total_usd",
    label: "Transaction Value(USD)",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "commission_total",
    label: "Commission",
    defaultVisible: true,
  },
  {
    key: "commission_total",
    label: "Commission(USD)",
    defaultVisible: true,
    isSortable: true,
  },
];

const BIG_PICTURE_REPORT_COLUMNS = [
  { key: "network_advertiser.name", label: "Network", defaultVisible: true },
  { key: "Website", label: "Client", defaultVisible: true },
  {
    key: "network_advertiser.network",
    label: "Location",
    defaultVisible: true,
  },
  ...DEFAuLT_COLUMNS,
] as Column[];

export const CLIENT_ACQUISITION_REPORT_COLUMNS = [
  { key: "Website", label: "Client", defaultVisible: true },
  { key: "source.name", label: "Source", defaultVisible: true },
  ...DEFAuLT_COLUMNS,
] as Column[];

export const AD_CENTER_REPORT_COLUMNS = [
  {
    key: "Date",
    label: "Date",
    defaultVisible: true,
    isSortable: true,
  },
  { key: "Advertiser Name", label: "Advertiser Name", defaultVisible: true },
  {
    key: "Client",
    label: "Client",
    defaultVisible: true,
  },
  { key: "Billing Email", label: "Billing Email", defaultVisible: true },
  { key: "Customer ID", label: "Customer ID", defaultVisible: true },

  {
    key: "Transaction Amount",
    label: "Transaction Amount",
    defaultVisible: true,
    isSortable: true,
  },

  { key: "Status", label: "Status", defaultVisible: true },
  { key: "Failure Code", label: "Failure Code", defaultVisible: true },
] as Column[];

export const PPC_REPORT_COLUMNS = [
  { isSortable: false, label: "Client", key: "client" },
  { isSortable: false, label: "Campaign", key: "campaign" },
  { isSortable: false, label: "Network", key: "Network" },
  { isSortable: false, label: "Location", key: "location" },
  { isSortable: false, label: "Search Engine", key: "search_engine" },
  { isSortable: true, label: "Ad Impressions", key: "total_impressions" },
  { isSortable: true, label: "Ad Clicks", key: "total_clicks" },
  { isSortable: true, label: "Ctr", key: "total_ctr" },
  { isSortable: true, label: "Avg Cpc", key: "total_avg_cpc" },
  { isSortable: true, label: "Cost", key: "total_cost" },
  { isSortable: true, label: "PPC Revenue", key: "total_commission_amount" },
  { isSortable: true, label: "Margin", key: "margin" },
  { isSortable: true, label: "Sales Amount", key: "total_sale_amount" },
  { isSortable: true, label: "Number Of Sales", key: "tx_count" },
  { isSortable: false, label: "PPC CR", key: "PPC CR" },
  { isSortable: false, label: "Roi", key: "Roi" },
  { isSortable: false, label: "Aov", key: "Aov" },
].map((el) => ({
  ...el,
  key: el.key,
  label: el.label,
  defaultVisible: true,
})) as Column[];

export const CLIENT_REVENUE_REPORT_COLUMNS = [
  { key: "Website", label: "Client", defaultVisible: true },

  {
    key: "sale_amount_total",
    label: "Transaction Value",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "sale_amount_total",
    label: "Transaction Value(USD)",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "commission_total",
    label: "Commission",
    defaultVisible: true,
  },
  {
    key: "commission_total",
    label: "Commission(USD)",
    defaultVisible: true,
    isSortable: true,
  },
] as Column[];

export const TRANSACTION_SUMMARY_REPORT_COLUMNS = [
  {
    key: "network_advertiser.name",
    label: "Advertiser Name",
    defaultVisible: true,
  },
  {
    key: "client",
    label: "Client",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "network_advertiser.network",
    label: "Network",
    defaultVisible: true,
  },
  {
    key: "total_sale_transactions",
    label: "# of Sales",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "total_sale_amount",
    label: "Transaction Value",
    defaultVisible: true,
  },
  {
    key: "total_sale_amount",
    label: "Transaction Value(USD)",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "returned_transactions_count",
    label: "# of Returns/Cancelled Transactions",
    defaultVisible: true,
    isSortable: true,
  },

  {
    key: "total_returns_sale_amount",
    label: "Returns/Cancelled Sales Amount",
    defaultVisible: true,
    isSortable: true,
  },
  {
    key: "total_returns_commission_amount",
    label: "Returned/Cancelled Commission",
    defaultVisible: true,
    isSortable: true,
  },

  {
    key: "aov",
    label: "AOV",
    defaultVisible: true,
    isSortable: true,
  },

  {
    key: "total_commission_amount",
    label: "Total Commission",
    defaultVisible: true,
  },

  {
    key: "total_commission_amount",
    label: "Commission(USD)",
    defaultVisible: true,
    isSortable: true,
  },
] as Column[];

export const ADVERTISER_TRANSACTION_DETAIL_COLUMNS = [
  { key: "client", label: "Client Name", defaultVisible: true },
  { key: "website_id", label: "Property", defaultVisible: false },
  { key: "client_location", label: "Client Location", isSortable: true },
  {
    key: "action_date",
    label: "Event Date",
    isSortable: true,
    defaultVisible: true,
  },
  {
    key: "sale_date",
    label: "Posting Date",
    isSortable: true,
    defaultVisible: true,
  },
  { key: "external_id", label: "Transaction ID", defaultVisible: false },
  {
    key: "link__network_advertiser_name",
    label: "Advertiser Name",
    defaultVisible: true,
  },
  { key: "Network", label: "Network", defaultVisible: true },
  {
    key: "commission_type",
    label: "Action Type",
    isSortable: true,
    defaultVisible: false,
  },
  {
    key: "status",
    label: "Action Status",
    isSortable: true,
    defaultVisible: true,
  },
  {
    key: "sale_amount",
    label: "Transaction Amount",
    isSortable: true,
    defaultVisible: true,
  },
  {
    key: "totals.txn_total_usd",
    label: "Transaction Amount(USD)",
    defaultVisible: true,
  },
  {
    key: "discount_amount",
    label: "Discount Amount",
    isSortable: true,
    defaultVisible: false,
  },
  {
    key: "commission_amount",
    label: "Commission",
    isSortable: true,
    defaultVisible: true,
  },
  {
    key: "totals.txn_total_usd",
    label: "Commission(USD)",
    defaultVisible: true,
  },
  { key: "coupon_code", label: "Coupon Code", defaultVisible: false },
  {
    key: "device_type",
    label: "Device",
    defaultVisible: false,
  },
  { key: "country", label: "Country", isSortable: true, defaultVisible: false },
  {
    key: "acquisition.medium",
    label: "Acquisition Type",
    defaultVisible: false,
  },
  { key: "search_engine", label: "Search Engine", defaultVisible: false },
  { key: "Sid", label: "Sid", defaultVisible: true },
] as Column[];

export default BIG_PICTURE_REPORT_COLUMNS;
