import {
  formatTooltip,
  formatXAxis,
} from "components/charts/Bar/SimpleBarChart";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

type Props = {
  data: Array<Record<string, any>>;
  checkedType: string;
};

const RevenueBarChat = ({ data, checkedType }: Props) => {
  const barKeys: string[] = [];

  data.forEach((el) => {
    Object.entries(el).map(([k, v]) => {
      if (typeof v === "number") {
        if (!barKeys.includes(k)) {
          barKeys.push(k);
        }
      }
    });
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(tick) => {
            if (checkedType === "Paid Placements") {
              return formatXAxis(tick);
            }
            return tick;
          }}
        />
        <Tooltip
          formatter={
            checkedType === "Paid Placements" ? formatTooltip : undefined
          }
        />
        <Legend />

        {barKeys?.map((el) => (
          <Bar
            key={el}
            dataKey={el}
            fill={"#346888"}
            activeBar={<Rectangle fill="#9DC6E0" stroke="#5886A5" />}
          ></Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RevenueBarChat;
