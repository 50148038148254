import classNames from "classnames";

type Props = {
  result: Record<string, any>;
  onResultClick: (res: Record<string, any>) => void;
};

function LocationSuggestionItem({ result, onResultClick }: Props) {
  return (
    <li
      key={result.id}
      onClick={() => {
        onResultClick(result);
      }}
      className={classNames(
        "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 hover:font-semibold",
        false ? "bg-indigo-600 text-white" : "text-gray-900",
      )}
    >
      <span className={classNames("block truncate")}>
        {result.name} <small>{result.country}</small>
      </span>

      {<small>{result.type}</small>}
    </li>
  );
}

export default LocationSuggestionItem;
