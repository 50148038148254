import Label from "components/common/Label";
import React, { ChangeEvent, ChangeEventHandler } from "react";
import { ActionState } from "types/data";
import { StoreType } from "types/model/oma/StoreType";

type Props = {
  getStoreTypesData: ActionState<StoreType[]>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  wrapperClass?: string;
};

const StoreTypeSelector = ({
  getStoreTypesData,
  form,
  onChange,
  wrapperClass,
}: Props) => {
  return (
    <>
      <div className="mb-4">
        <Label label="Store Type" />
        <div className={wrapperClass}>
          {getStoreTypesData.data.map((it) => (
            <div className="items-center  mt-3 mr-4" key={it.name}>
              <input
                type="radio"
                checked={String(it.id) === String(form.store_type)}
                onChange={(e) => {
                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: "store_type",
                      value: it.id,
                    },
                  }) as unknown as ChangeEvent<HTMLInputElement>;
                }}
                name="store_type"
                className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="all-tasks"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                onClick={(e) => {
                  onChange({
                    target: {
                      ...e.target,
                      name: "store_type",
                      value: it.id,
                    },
                  } as any);
                }}
              >
                {it.name}
              </label>
            </div>
          ))}
        </div>
        <div></div>
      </div>
    </>
  );
};

export default StoreTypeSelector;
