import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { toast } from "react-hot-toast";
import getCouponSpotlights from "redux/thunks/app/placements/campaigns/getCouponSpotlights";
import { addToCouponSpotlights } from "redux/slices/clients/placements/campaigns/getCouponSpotlights";
import { setCouponSpotlights } from "redux/slices/clients/placements/campaigns/getCouponSpotlights";
import CouponSpotlightsLayout from "layout/oma/campaigns/CampaignsHomeHeroes";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import { clearSearchCouponResults } from "redux/slices/clients/coupons/searchActiveCoupons";
import saveCouponSpotlights from "redux/thunks/app/placements/campaigns/saveCouponSpotlights";
import { useSearchParams } from "react-router-dom";

const CouponSpotlight = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const [addForm, setAddForm] = useState<Record<string, any>>({});

  const {
    getCouponSpotlights: getCouponSpotlightsData,
    saveCouponSpotlights: { loading: saving },
  } = useAppSelector(
    ({ getClients, getCouponSpotlights, saveCouponSpotlights }) => ({
      getClients,
      getCouponSpotlights,
      saveCouponSpotlights,
    })
  );

  const [sortedItems, setSortedItems] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    dispatch(getCouponSpotlights());
  }, [params.get("client_id")]);

  const handlePersistUpdates = () => {
    dispatch(
      saveCouponSpotlights(
        getCouponSpotlightsData.data.map((el, idx) => ({
          from_date: el.from_date,
          to_date: el.to_date,
          position: idx + 1,
          slogan: el.slogan,
          store: el.store.id,
          image_url: el.image_url,
          coupon: el.coupon.id,
          file: el.file,
        }))
      )
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  const handleAddNewItem = () => {
    const newItem = {
      from_date: null,
      to_date: null,
      coupon: addForm.coupon,
      store: addForm.store,
      slogan: "",
      id: new Date().getTime(),
    };
    setSortedItems((items) => [newItem, ...items]);

    dispatch(addToCouponSpotlights(newItem));

    setAddForm({});
  };

  const handleRemoveSpotlight = (result: Record<string, any>) => {
    const updatedItems = getCouponSpotlightsData.data.filter(
      (it) => it.id !== result.id
    );

    dispatch(setCouponSpotlights(updatedItems));
  };

  useEffect(() => {
    setSortedItems(getCouponSpotlightsData.data);
  }, [getCouponSpotlightsData.data]);

  const handleUpdateItem = (
    row: Record<string, any>,
    updatedItem: Record<string, any>
  ) => {
    const updatedItems = sortedItems.map((item) =>
      item.id === row.id ? updatedItem : item
    );

    dispatch(setCouponSpotlights(updatedItems));
    setAddForm({});
  };

  const onSearchMerchantSelected = (merchant: Record<string, any>) => {
    setAddForm((values) => ({ ...values, store: merchant }));
    dispatch(clearProgramSearchResults());
  };

  const onSearchCouponSelected = (coupon: Record<string, any>) => {
    setAddForm((values) => ({ ...values, coupon }));
    dispatch(clearSearchCouponResults());
  };

  return (
    <CouponSpotlightsLayout
      data={sortedItems}
      onSearchMerchantSelected={onSearchMerchantSelected}
      form={addForm}
      saving={saving}
      handleAddNewItem={handleAddNewItem}
      onSearchCouponSelected={onSearchCouponSelected}
      handleUpdateItem={handleUpdateItem}
      handlePersistUpdates={handlePersistUpdates}
      handleRemoveSpotlight={handleRemoveSpotlight}
    />
  );
};

export default CouponSpotlight;
