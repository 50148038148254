import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getTopCategories from "redux/thunks/app/placements/campaigns/getTopCategories";
import updateTopCategory from "redux/thunks/app/placements/campaigns/updateTopCategory";
import { ProgramCategory, TopProgramCategory } from "types/model/Category";

export type ActionStateType = {
  data: TopProgramCategory[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getTopCategoriesSlice = createSlice({
  name: "getTopCategories",
  initialState,
  reducers: {
    removeCategory: (state, { payload }: PayloadAction<TopProgramCategory>) => {
      return {
        ...state,
        data: state.data.filter((el) => el.category.id !== payload.category.id),
      };
    },

    setTopCategories: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },

    addNewTopCategory: (state, { payload }: PayloadAction<ProgramCategory>) => {
      return {
        ...state,
        data: [
          {
            featured_stores: [],
            featured_subs: [],
            id: "",
            position: 1,
            from_date: null,
            to_date: null,
            category: payload,
          },
          ...state.data,
        ],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopCategories.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        getTopCategories.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        updateTopCategory.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: state.data.map((el) =>
                el.id == payload.id ? payload : el
              ),
              loading: false,
            };
          }
        }
      )

      .addCase(
        getTopCategories.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const { addNewTopCategory, setTopCategories, removeCategory } =
  getTopCategoriesSlice.actions;
export default getTopCategoriesSlice.reducer;
