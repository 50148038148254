import { useEffect } from "react";

const useOrdering = (
  defaultOrderKey: string,
  navigate: (search: string) => void
) => {
  const updateUrl = (colKey: string, searchParams: URLSearchParams) => {
    const url = new URL(window.location.href);

    if (searchParams.has("page")) {
      url.searchParams.set("page", "1");
    }
    if (searchParams.has("ordering")) {
      const keyExists = searchParams.get("ordering")?.includes(colKey);

      if (keyExists) {
        const currString = searchParams.get("ordering") || "";
        if (currString.startsWith("-")) {
          const reversed = currString?.replace("-", "");
          url.searchParams.set("ordering", reversed || "");
        } else {
          const reversed = currString?.replace(colKey, "-" + colKey);
          url.searchParams.set("ordering", reversed || "");
        }
      } else {
        url.searchParams.set("ordering", colKey);
      }
    } else {
      url.searchParams.set("ordering", colKey);
    }

    return url.search;
  };

  const navigateWithOrdering = (colKey: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const newSearch = updateUrl(colKey, searchParams);
    navigate(newSearch);
  };

  useEffect(() => {
    // Use the defaultOrderKey when the component mounts

    if (defaultOrderKey) {
      navigateWithOrdering(defaultOrderKey);
    }
  }, [defaultOrderKey, navigate]);

  return navigateWithOrdering;
};

export default useOrdering;
