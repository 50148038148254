import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import syncPrograms from "redux/thunks/advertisers/syncPrograms";

const syncProgramsSlice = createSlice({
  name: "syncPrograms",
  initialState: {
    data: null,
    errors: null,
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(syncPrograms.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        syncPrograms.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )
      .addCase(
        syncPrograms.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});

export default syncProgramsSlice.reducer;
