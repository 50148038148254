import { USER_ROLE_ADMIN } from "constants/user_roles";
import instance from "helpers/axios";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import StoreLayout from "layout/sales_crm/StoreLayout";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { resetEvent } from "redux/slices/sales_crm/getEvent";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import getProposals from "redux/thunks/sales/getProposals";
import getContacts from "redux/thunks/sales_crm/getContacts";
import getEntityTypes from "redux/thunks/sales_crm/getEntityTypes";
import getOwnerShipRequests from "redux/thunks/sales_crm/getOwnerShipRequests";
import { Proposal } from "types/model/sales/Proposal";

const Store = () => {
  const { slug } = useParams();
  const { user } = useLoadAuth();
  const [params] = useSearchParams();
  const [searchParams] = useSearchParams();
  const { client_id } = Object.fromEntries([...searchParams]);
  const [closedDeals, setClosedDeals] = useState<Proposal[]>([]);
  const [saveOwnerLoading, setSaveOwnerLoading] = useState(false);
  const [requests, setRequests] = useState<Record<string, any>[]>([]);
  const [programOwner, setProgramOwner] = useState<Record<string, any>>({});
  const { data: programContacts } = useAppSelector(
    (state) => state.getContacts
  );
  const { data: program } = useAppSelector((state) => state.getProgram);
  const {
    data: { items },
  } = useAppSelector((state) => state.getAllUsers);

  const dispatch = useAppDispatch();

  const { slug: storeSlug = "" } = Object.fromEntries([...params]);

  const activeSlug = storeSlug || slug;

  const getData = () => {
    if (slug) {
      instance
        .get(`/admin/sales-crm/program-owner/?program_slug=${slug}`)
        .then((res) => {
          setProgramOwner(res.data);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            setProgramOwner({});
          }
        });
    }

    if (slug) {
      dispatch(
        getProposals({
          proposal_status: "Approved",
          created_by: user?.id || "",
          program_slug: slug,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setClosedDeals(res.payload.items);
        }
      });
    } else {
      setClosedDeals([]);
    }
  };

  useEffect(() => {
    getData();
  }, [activeSlug]);

  useEffect(() => {
    dispatch(getEntityTypes());
    if (activeSlug) {
      dispatch(
        getProgram({
          query: activeSlug,
          client_id: client_id,
          useDis: "1",
        })
      );
    }
  }, [activeSlug]);

  useEffect(() => {
    if (activeSlug) {
      dispatch(
        getContacts({
          program: activeSlug,
        })
      );
    }
  }, [activeSlug]);

  const saveProgramOwner = (userId: string) => {
    setSaveOwnerLoading(true);
    instance
      .post(`admin/sales-crm/program-owners/`, {
        program: {
          slug: slug,
          id: program.id,
          client: program.client,
          name: program.name,
        },
        user: userId,
      })
      .then(() => {
        toast.success("Changes saved");
        setProgramOwner({
          user: items.find((it) => String(it.id) === String(userId)) || {},
        });
      })
      .finally(() => {
        setSaveOwnerLoading(false);
      });
  };

  const hasSalesCRMAccess =
    user?.user_role === USER_ROLE_ADMIN ||
    !!user?.user_sub_apps?.find((it) => it.name === "Sales CRM");

  useEffect(() => {
    dispatch(
      getOwnerShipRequests({
        program_slug: activeSlug,
        status: "pending",
      })
    ).then((res) => {
      setRequests(res.payload);
    });
  }, [activeSlug]);

  useEffect(() => {
    dispatch(resetEvent());
  }, []);

  return (
    <StoreLayout
      hasSalesCRMAccess={hasSalesCRMAccess}
      closedDeals={closedDeals}
      store={program}
      programContacts={programContacts}
      saveProgramOwner={saveProgramOwner}
      programOwner={programOwner}
      saveOwnerLoading={saveOwnerLoading}
      requests={requests}
    />
  );
};

export default Store;
