import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  text: string;
  className?: string;
};

const LabelBadge = ({ text, className }: Props) => {
  return (
    <span
      className={twMerge(
        "bg-green-100 block text-green-800 text-xs p-1 font-medium  rounded-full dark:bg-green-900 dark:text-green-300 my-auto px-2",
        className
      )}
    >
      {text}
    </span>
  );
};

export default LabelBadge;
