import Layout from "components/layout/Layout";
import RenderCommissionRates from "components/advertisers/RenderCommissionRates";
import dateFormat from "dateformat";

type Props = {
  state: Record<string, any>;
};

const CommissionRateHistory = ({ state }: Props) => {
  return (
    <Layout>
      <div className="overflow-x-auto ">
        <ol className="flex w-full text-sm font-medium text-center text-gray-500 sm:text-base gap-12 py-12 ">
          {state.changes.map((el: Record<string, any>) => (
            <div className="max-w-sm rounded shadow-lg flex-1" key={el.id}>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">
                  {" "}
                  {dateFormat(el.created_at, "dS mmmm, yyyy")}
                </div>
              </div>
              <div className="px-6 pt-4 pb-2">
                <RenderCommissionRates item={el} />
              </div>
            </div>
          ))}
        </ol>
      </div>
    </Layout>
  );
};

export default CommissionRateHistory;
