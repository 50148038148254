import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

type Params = {
  page: number;
  search?: string;
  sort_by?: string;
  primary_network?: string;
  location?: string;
  rank?: string;
  size?: number;
  has_open_ppc_policy?: string | null;
  has_no_coupons?: string | null;
  has_no_codes?: string | null;
  a_win_soft_click?: string;
};

export default createAsyncThunk(
  "clients/active-programmes",
  async (params: Params, ThunkAPI) => {
    if (params.primary_network === "awin-softclick") {
      delete params.primary_network;
      params["a_win_soft_click"] = "1";
    }
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }
      const clientWebsite = new ClientAPI();

      const response = await clientWebsite.getActivePrograms({
        client: selectedClientId,
        ...params,
      });

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
