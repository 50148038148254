import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import Thunk from "../app/Thunk";

export default Thunk(
  "createNetworkExtSetting",
  async (payload: Record<string, any>) => {
    return instance.post(
      DISCOUNT_CODES_API + "/extension/network-extension-settings/",
      {
        network_id: payload.network.network_id,
        ...payload,
      }
    );
  }
);
