import {
  formatTooltip,
  formatXAxis,
} from "components/charts/Bar/SimpleBarChart";
import Spinner from "components/common/layout/Spinner";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { twMerge } from "tailwind-merge";

type RechartBar = {
  dataKey: string;
  fill: string;
  activeBar: {
    fill: string;
    stroke: string;
  };
};

type Props = {
  data: Array<Record<string, any>>;
  title: string;
  rangeOptions: Record<string, any>[];
  loading?: boolean;
  xDataKey: string;
  bars: Array<RechartBar>;
};

const BiBarChart = ({
  data,
  title,
  rangeOptions,
  loading,
  xDataKey = "Name",
  bars,
}: Props) => {
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h1>{title} </h1>
        <div className="flex gap-2 items-center">
          {loading && <Spinner className="h-6 w-6" />}
          {rangeOptions.map((el) => (
            <button
              key={el.label}
              onClick={el.onClick}
              className={twMerge(
                "border border-gray-200 my-2 inline-flex w-full rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm  mr-2 sm:w-auto",
                el.active && "bg-primary-700 text-white"
              )}
            >
              {el.label}
            </button>
          ))}
        </div>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xDataKey} />

          <YAxis tickFormatter={formatXAxis} />
          <Tooltip formatter={formatTooltip} />
          <Legend />

          {/* {bars.map(el=>)} */}

          {bars.map((el) => (
            <Bar
              dataKey={el.dataKey}
              fill={el.fill}
              activeBar={
                <Rectangle
                  fill={el.activeBar.fill}
                  stroke={el.activeBar.stroke}
                />
              }
            />
          ))}
          {/* <Bar
            dataKey="Commission"
            fill="#8884d8"
            activeBar={<Rectangle fill="pink" stroke="blue" />}
          />
          <Bar
            dataKey="Margin"
            fill="#82ca9d"
            activeBar={<Rectangle fill="gold" stroke="purple" />}
          /> */}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
export default BiBarChart;
