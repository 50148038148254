import { useAppSelector } from "hooks/useRedux";
import EditKeywordsComponent from "layout/marketing/search/EditAdKeywords";

const EditKeywords = () => {
  const getKeywordsData = useAppSelector((state) => state.getAdKeywords);

  return <EditKeywordsComponent getKeywordsData={getKeywordsData} />;
};

export default EditKeywords;
