import { Dispatch, SetStateAction, useState } from "react";
import ProposalCard from "./components/ProposalCard";
import StoreCardInCommunication from "./components/InCommunication";
import { Proposal } from "types/model/sales/Proposal";
import LeadCard from "./components/LeadCard";
import { Event } from "types/model/sales_crm/Event";
import SalesTeamFilter from "./components/SalesTeamFilter";
import Button from "components/common/Button";
import { PagingMeta } from "redux/slices/sales/getProposals";

interface Invoice {
  date: string;
  dateTime: string;
  amount: string;
  status: "Paid" | "Overdue" | "Withdraw";
}

interface StoreDetails {
  network: string;
  currentCommissionRate: string;
  newCommissionRate: string;
  exclusiveCode: string;
  tmPlusSearchRights: string;
  manager: string;
  duration: string;
}

interface Store {
  id: number;
  name: string;
  imageUrl: string;
  lastInvoice: Invoice;
  details: StoreDetails;
  stage:
    | "Closed Deals"
    | "Pending Proposals"
    | "In Communication"
    | "Leads in (No Contact yet)";
}

const generateStores = (): Store[] => {
  const stores: Store[] = [];
  return stores;
};

const stores = generateStores();

const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};

type Props = {
  closedDeals: Array<Proposal>;
  pendingProposals: Array<Proposal>;
  pendingLeads: Record<string, any>[];
  in_communication: Array<Event>;
  setUserId: Dispatch<SetStateAction<string>>;
  isAdmin: boolean;
  handleLoadMore: (selectedTab: string) => void;
  loadingMore: boolean;
  closedDealsMeta: PagingMeta;
  pendingProposalsMeta: PagingMeta;
};

const DealPipeline = ({
  closedDeals,
  pendingProposals,
  pendingLeads,
  in_communication,
  setUserId,
  isAdmin,
  handleLoadMore,
  loadingMore,
  closedDealsMeta,
  pendingProposalsMeta,
}: Props) => {
  const [selectedTab, setSelectedTab] =
    useState<Store["stage"]>("Closed Deals");

  const filterStoresByStage = (stage: Store["stage"]) => {
    if (stage === "Closed Deals") {
      return closedDeals;
    }

    if (stage === "Pending Proposals") {
      return pendingProposals;
    }

    if (stage === "Leads in (No Contact yet)") {
      return pendingLeads;
    }

    if (stage == "In Communication") {
      return in_communication;
    }
    return stores.filter((store) => store.stage === stage);
  };

  const tabs = [
    { name: "Closed Deals", count: closedDeals?.length || 0 },
    {
      name: "Pending Proposals",
      count: filterStoresByStage("Pending Proposals")?.length || 0,
    },
    {
      name: "In Communication",
      count: filterStoresByStage("In Communication")?.length || 0,
    },
    {
      name: "Leads in (No Contact yet)",
      count: filterStoresByStage("Leads in (No Contact yet)")?.length || 0,
    },
  ];

  const getCount = (tabName: string, defaultCount: number) => {
    if (tabName === "Closed Deals") {
      return closedDealsMeta.count;
    }

    if (tabName === "Pending Proposals") {
      return pendingProposalsMeta.count;
    }
    return defaultCount;
  };

  const shouldLoadMore = () => {
    if (selectedTab === "Closed Deals") {
      return closedDealsMeta.count > closedDeals.length;
    }

    if (selectedTab === "Pending Proposals") {
      return pendingProposalsMeta.count > pendingProposals.length;
    }

    return false;
  };

  return (
    <>
      <div className="container mx-auto">
        <div className="border-b border-gray-200 mt-4">
          <nav
            className="-mb-px flex items-center justify-between"
            aria-label="Tabs"
          >
            <div className="flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  title={
                    tab.name === "In Communication"
                      ? "Active Communications in the last 3 months"
                      : ""
                  }
                  onClick={() => setSelectedTab(tab.name as Store["stage"])}
                  className={classNames(
                    tab.name === selectedTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.name === selectedTab ? "page" : undefined}
                >
                  {tab.name} {getCount(tab.name, tab.count)}
                </button>
              ))}
            </div>

            {isAdmin && (
              <div className="mr-4 ">
                <SalesTeamFilter
                  onChange={(e) => {
                    setUserId(e.target.value);
                  }}
                />
              </div>
            )}
          </nav>
        </div>
        <div className="flex flex-wrap gap-4">
          {filterStoresByStage(selectedTab).map((el) => {
            return selectedTab === "Leads in (No Contact yet)" ? (
              <LeadCard lead={el as any} wrapperClass="w-[30%]" leadIdx={0} />
            ) : selectedTab === "In Communication" ? (
              <StoreCardInCommunication
                wrapperClass="w-[30%]"
                event={el as Event}
              />
            ) : (
              <ProposalCard wrapperClass="w-[30%]" proposal={el} />
            );
          })}
        </div>
        {shouldLoadMore() && (
          <Button
            loading={loadingMore}
            text="Load more"
            onClick={() => handleLoadMore(selectedTab)}
          />
        )}
      </div>
    </>
  );
};

export default DealPipeline;
