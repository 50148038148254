import { Event } from "types/model/sales_crm/Event";

export const flattenEvents = (events: Event[]) => {
  const allEvents: Event[] = [];

  events.forEach((el) => {
    allEvents.push(el);

    if (el.sub_updates.length > 0) {
      el.sub_updates.forEach((it) => {
        allEvents.push(it);
      });
    }
  });

  return allEvents;
};

const getLastUpdatedDate = (events: Event[]) => {
  const allEventDates: string[] = [];

  events.forEach((el) => {
    allEventDates.push(el.event_date);

    if (el.sub_updates.length > 0) {
      el.sub_updates.forEach((it) => {
        allEventDates.push(it.event_date);
      });
    }
  });

  return allEventDates.sort((a, b) => b.localeCompare(a))[0];
};

export default getLastUpdatedDate;
