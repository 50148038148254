import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import completeAffiliateNewsletter from "redux/thunks/links/newsletters/completeAffiliateNewsletter";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const completeAffiliateNewsletterSlice = createSlice({
  name: "completeAffiliateNewsletter",
  initialState,
  reducers: {
    resetcompleteAffiliateNewsletter: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(completeAffiliateNewsletter.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        completeAffiliateNewsletter.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        completeAffiliateNewsletter.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetcompleteAffiliateNewsletter } =
  completeAffiliateNewsletterSlice.actions;
export default completeAffiliateNewsletterSlice.reducer;
