import Input from "components/common/Input";
import Modal from "components/common/Modal";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState,
  useRef,
} from "react";
import { Proposal } from "types/model/sales/Proposal";
import dateformat from "dateformat";
import formatNumber from "utils/strings/formatNumber";
import { BACKEND_BASE_URL } from "config/env";
import { Link } from "react-router-dom";
import isValidEmail from "utils/strings/isValidEmail";
import { toast } from "react-hot-toast";
import { ActionState } from "types/data";
import Spinner from "components/common/layout/Spinner";
import TextArea from "components/common/TextArea";
import { LuDownload, LuFileStack } from "react-icons/lu";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { truncate } from "lodash";
import DocumentStatus from "./DocumentStatus";
import { currencyNameSymbolMap } from "constants/storeCurrencies";

type Props = {
  proposal: Proposal;
  submitLoading?: boolean;
  sendSigningRequestData?: ActionState<Record<string, any> | null>;
  handleSendSigningRequest?: (
    proposal_id: string,
    url: string,
    form: Record<string, any>
  ) => void;
  handleDownloadSignedPDF?: (proposal_id: string) => void;
  signedIOLoadConfig?: Record<string, any>;
  handleUploadManualIOFile?: (
    file: Record<string, any>,
    record: Proposal
  ) => void;
  createUpdateDeleteProposalData?: ActionState;
};

const IORow = ({
  proposal,
  submitLoading,
  sendSigningRequestData,
  handleDownloadSignedPDF,
  handleSendSigningRequest,
  signedIOLoadConfig,
  handleUploadManualIOFile,
  createUpdateDeleteProposalData,
}: Props) => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const [manageOpen, setManageOpen] = useState(false);
  const [manualFilesOpen, setManualFilesOpen] = useState(false);
  const [tempFileURL, setTempFileURL] = useState("");
  const [form, setForm] = useState<Record<string, any>>({});

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (sendSigningRequestData?.data) {
      setManageOpen(false);
    }
  }, [sendSigningRequestData?.data]);

  useEffect(() => {
    if (createUpdateDeleteProposalData?.data?.id) {
      setManualFilesOpen(false);
    }
  }, [createUpdateDeleteProposalData?.data]);

  useEffect(() => {
    if (proposal.partner_contact) {
      setForm((f) => ({ ...f, ...proposal.partner_contact }));
    }
  }, [proposal.partner_contact]);

  return (
    <React.Fragment>
      <Modal
        doneText="Save"
        doneBtnClass="bg-blue-500 hover:bg-blue-600 sm"
        onConfirm={() => {
          if (!form.file) {
            setManualFilesOpen(false);
          } else {
            handleUploadManualIOFile?.(form.file, proposal);
          }
        }}
        open={manualFilesOpen}
        setOpen={setManualFilesOpen}
        loading={createUpdateDeleteProposalData?.loading}
        variant="sm"
      >
        <h1 className=" text-gray-600  text-sm font-medium">
          Files uploaded Manually
        </h1>

        {proposal.manual_signed_io_file && !form.file && (
          <div className="text-xs bg-white shadow-lg px-4 my-2 py-4 flex justify-between items-center">
            <p>
              {truncate(proposal.manual_signed_io_file || "", { length: 50 })}
            </p>

            <Link to={proposal.manual_signed_io_file} target="_blank">
              <LuDownload />
            </Link>
          </div>
        )}

        {form.file && (
          <div className="text-xs bg-white shadow-lg px-4 my-2 py-4 flex justify-between items-center">
            {form.file?.name}

            <Link to={tempFileURL} target="_blank">
              <LuDownload />
            </Link>
          </div>
        )}
        <button
          className="text-xs text-blue-500 underline"
          onClick={() => {
            uploadRef.current?.click();
          }}
        >
          Upload a new file
        </button>

        <form>
          <input
            type="file"
            hidden
            ref={uploadRef}
            accept="application/*"
            onChange={(event) => {
              setForm((f) => ({ ...f, file: event.target.files?.[0] }));
              if (event.target.files) {
                setTempFileURL(URL.createObjectURL(event.target.files[0]));
              }
            }}
          />
        </form>
      </Modal>
      <Modal
        doneText="Send"
        doneBtnClass="bg-blue-500 hover:bg-blue-600"
        onConfirm={() => {
          if (
            !form.first_name?.trim()?.length ||
            !form.first_name?.trim()?.length ||
            !form.email
          ) {
            toast.error("All fields are required");
            return;
          }

          if (!isValidEmail(form.email)) {
            toast.error("Email is invalid");
            return;
          }

          handleSendSigningRequest?.(
            proposal.id,
            `${BACKEND_BASE_URL}/admin/sales/proposals/pdf-download?id=${proposal.id}&resource=io`,
            form
          );
        }}
        open={manageOpen}
        setOpen={setManageOpen}
        loading={submitLoading}
      >
        <h1>Enter Signer Details</h1>

        <Input
          wrapperClassName="mb-2 mt-4"
          name="first_name"
          label="First Name"
          value={form.first_name}
          onChange={onChange}
        />

        <div className="my-2">
          <Input
            onChange={onChange}
            label="Last Name"
            value={form.last_name}
            name={"last_name"}
          />
          <Input
            type="email"
            name="email"
            label="Email"
            wrapperClassName="my-2"
            onChange={onChange}
            value={form.email}
          />

          <TextArea
            label="Company Address (optional)"
            wrapperClassName="my-2"
            name="address"
            value={form.address}
            onChange={(event) => {
              onChange({
                target: {
                  name: "address",
                  value: event.target.value,
                },
              } as ChangeEvent<HTMLInputElement>);
            }}
          />
        </div>
      </Modal>

      <tr key={proposal.id}>
        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
          {dateformat(proposal.created_at, "mm/dd/yyyy")}
        </td>{" "}
        <td className="whitespace-nowrap py-2  pl-4 text-xs text-blue-600 font-medium">
          <Link
            target="_blank"
            to={`${BACKEND_BASE_URL}/admin/sales/proposals/pdf-download?id=${proposal.id}&resource=io`}
          >
            {" "}
            {proposal.io_id}
          </Link>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
          {proposal.created_by.first_name + " " + proposal.created_by.last_name}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.client_merchant_config?.[0].program_name}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.locations.map((el) => el.toUpperCase()).join(",")}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.payment_status}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.currency_symbol}
          {formatNumber(proposal.final_amount, { decimals: 2 })}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {currencyNameSymbolMap[proposal.payment_currency] ||
            proposal.currency_symbol}
          {formatNumber(proposal.amount_paid, { decimals: 2 })}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.currency_symbol}
          {formatNumber(proposal.remaining_balance, { decimals: 2 })}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          <DocumentStatus
            proposal={proposal}
            onRequest={() => {
              setManageOpen(true);
            }}
          />
        </td>
        <td className="pl-2 py-2 underline text-xs font-medium text-blue-500">
          {(proposal.signed_pdf_url ||
            (proposal.manual_signed_io_file?.length || 0) > 0) && (
            <button
              onClick={() => {
                if ((proposal.manual_signed_io_file?.length || 0) > 0) {
                  window.open(proposal.manual_signed_io_file || "", "_blank");
                } else {
                  handleDownloadSignedPDF?.(proposal.id);
                }
              }}
            >
              {signedIOLoadConfig?.loading &&
              signedIOLoadConfig?.proposalId === proposal.id ? (
                <Spinner className="h-6 w-6" />
              ) : (proposal.manual_signed_io_file?.length || 0) > 0 ? (
                "m-signed"
              ) : (
                "Signed pdf"
              )}
            </button>
          )}
        </td>
        <td className="py-2 underline text-sm font-medium">
          <button
            onClick={() => {
              setManualFilesOpen(true);
            }}
          >
            <LuFileStack />
          </button>
        </td>
        <td className="py-2 underline text-sm font-medium">
          {proposal.invoice && (
            <Link
              title="Download Invoice"
              to={`${BACKEND_BASE_URL}/admin/sales/proposals/pdf-download?id=${proposal.id}&resource=invoice`}
              target="_blank"
            >
              <LiaFileInvoiceDollarSolid />
            </Link>
          )}
        </td>
        <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
          <Link
            title="View IO"
            to={`${BACKEND_BASE_URL}/admin/sales/proposals/pdf-print?id=${proposal.id}&resource=io`}
          >
            View
          </Link>
        </td>
        <td className="px-2 py-2 text-xs font-medium text-primary-600 ">
          <Link
            to={`/sales/proposals/payments?proposal_id=${
              proposal.id
            }&client_id=${proposal.client_merchant_config[0].client}&slug=${
              proposal.client_merchant_config[0].program_slug || ""
            }`}
          >
            Payments
          </Link>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default IORow;
