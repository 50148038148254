import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "components/layout/Layout";
import { useAppSelector } from "hooks/useRedux";
import Message from "components/common/Message";
import { twMerge } from "tailwind-merge";
import EditKeywords from "containers/marketing/search/EditKeywords";
import EditLocationTargets from "containers/marketing/search/EditLocationTargets";
import EditAd from "containers/marketing/search/EditAd";
import EditAdGroup from "containers/marketing/search/EditAdgroup";
import Input from "components/common/Input";
import Dropdown from "components/common/Dropdown";
import { ChangeEventHandler } from "react";
import EditDevices from "containers/marketing/search/EditDevices";
import { capitalize } from "lodash";

type Props = {
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const CampaignDetailsTabLayout = ({ form, onChange }: Props) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const error = useAppSelector((state) => state.getAdvertisers.error);

  const selectedTab = params.get("tab") || "ad-group";

  if (error?.detail) {
    return (
      <Layout>
        <Message title="Error!" info description={error?.detail} />
      </Layout>
    );
  }

  const renderContent = () => {
    if (selectedTab === "keywords") {
      return <EditKeywords />;
    }

    if (selectedTab === "locations") {
      return <EditLocationTargets />;
    }

    if (selectedTab === "ads") {
      return <EditAd />;
    }

    if (selectedTab === "ad-group") {
      return <EditAdGroup />;
    }

    if (selectedTab === "devices") {
      return <EditDevices />;
    }
  };

  return (
    <Layout>
      {form.campaign_name && (
        <div className="flex">
          <div className="w-1/2">
            <div className="mb-8">
              <div className="mb-2">
                <Input
                  disabled
                  label="Campaign Name"
                  name="campaign_name"
                  value={form.campaign_name}
                />
              </div>
              <Dropdown
                label="Campaign Status"
                value={capitalize(form.campaign_status)}
                name={"campaign_status"}
                onChange={onChange}
                options={["Active", "Paused"].map((el) => ({
                  label: el,
                  value: el,
                }))}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className="inline-flex flex-col w-full rounded-md shadow-sm md:w-auto md:flex-row"
        role="group"
      >
        <button
          type="button"
          onClick={() => {
            const url = new URL(window.location.href);
            url.searchParams.set("tab", "ad-group");
            navigate(url.search);
          }}
          className={twMerge(
            "px-4 py-2 mr-4 text-sm font-medium  border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg bg-gray-100  focus:z-10 focus:ring-2 ",
            selectedTab === "ad-group" && "ring-primary-700 text-primary-700",
          )}
        >
          Ad group
        </button>

        <button
          type="button"
          onClick={() => {
            const url = new URL(window.location.href);
            url.searchParams.set("tab", "locations");
            navigate(url.search);
          }}
          className={twMerge(
            "px-4 py-2 mx-4 text-sm font-medium   border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg bg-gray-100  focus:z-10 focus:ring-2 ",
            selectedTab === "locations" && "ring-primary-700 text-primary-700",
          )}
        >
          Locations
        </button>
        <button
          type="button"
          onClick={() => {
            const url = new URL(window.location.href);
            url.searchParams.set("tab", "ads");
            navigate(url.search);
          }}
          className={twMerge(
            "px-4 py-2 mx-4 text-sm font-medium   border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg bg-gray-100  focus:z-10 focus:ring-2 ",
            selectedTab === "ads" && "ring-primary-700 text-primary-700",
          )}
        >
          Ads
        </button>

        <button
          type="button"
          onClick={() => {
            const url = new URL(window.location.href);
            url.searchParams.set("tab", "keywords");
            navigate(url.search);
          }}
          className={twMerge(
            "px-4 py-2 mx-4 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg md:rounded-bl-none md:rounded-r-lg ",
            selectedTab === "keywords" &&
              "bg-gray-100  focus:z-10 focus:ring-2 ring-primary-700 text-primary-700  dark:border-gray-600   ",
          )}
        >
          Keywords
        </button>

        <button
          type="button"
          onClick={() => {
            const url = new URL(window.location.href);
            url.searchParams.set("tab", "devices");
            navigate(url.search);
          }}
          className={twMerge(
            "px-4 py-2 mx-4 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg md:rounded-bl-none md:rounded-r-lg ",
            selectedTab === "devices" &&
              "bg-gray-100  focus:z-10 focus:ring-2 ring-primary-700 text-primary-700  dark:border-gray-600   ",
          )}
        >
          Devices
        </button>
      </div>

      <div className="my-5">{renderContent()}</div>
    </Layout>
  );
};

export default CampaignDetailsTabLayout;
