/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import updateDeleteAutoCouponTemplate from "redux/thunks/app/api-clients/updateDeleteAutoCouponTemplate";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const updateDeleteAutoCouponTemplateSlice = createSlice({
  name: "updateDeleteAutoCouponTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateDeleteAutoCouponTemplate.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        updateDeleteAutoCouponTemplate.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        updateDeleteAutoCouponTemplate.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default updateDeleteAutoCouponTemplateSlice.reducer;
