import { Column } from "types/column";

const CONTACTS_COLUMNS = [
  "Last Update",
  "Name",
  "Company Name",
  "Company Type",
  "Company Email",
  "Title",
  "",
].map((it) => ({ isSortable: false, key: it, label: it })) as Column[];

export default CONTACTS_COLUMNS;
