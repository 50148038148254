import useLoadAuth from "hooks/useLoadAuth";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const SettingsDropDown = () => {
  const [open, setOpen] = useState(false);
  const { handleLogout, user } = useLoadAuth();
  const location = useLocation();

  return (
    <div className="relative">
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        <button
          onClick={() => {
            setOpen((value) => !value);
          }}
          className={twMerge(
            "font-semibold rounded-full bg-gray-100 text-sm px-4 py-2 lg:px-5 lg:py-2.5 mx-2",
            (["/bma/mapping"].includes(location.pathname) ||
              location.pathname.includes("/settings")) &&
              "text-white bg-primary-700"
          )}
        >
          Settings
        </button>
        {open && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <Link
              to={"/settings/users/account"}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Account
            </Link>
            {user?.user_role === "ADMIN" && (
              <Link
                to={"/settings/users"}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Users
              </Link>
            )}

            {user?.user_role === "ADMIN" && (
              <Link
                to={"/settings/networks/clients"}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Networks
              </Link>
            )}

            {user?.user_role === "ADMIN" && (
              <Link
                to={"/bma/mapping"}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Mapping
              </Link>
            )}

            {user?.user_role === "ADMIN" && (
              <Link
                to={"/bma/crm-mapping"}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                CRM Mapping
              </Link>
            )}
            <button
              onClick={() => {
                handleLogout();
              }}
              className="block px-4  w-full text-left py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Logout
            </button>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default SettingsDropDown;
