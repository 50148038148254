import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createEmailCampaign from "redux/thunks/marketing/emails/createEmailCampaign";
import sendEmailCampaign from "redux/thunks/marketing/emails/sendEmailCampaign";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const createEmailCampaignSlice = createSlice({
  name: "createEmailCampaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEmailCampaign.pending, (state) => {
        return {
          ...state,
          error: null,
          data: [],
          loading: true,
        };
      })
      .addCase(
        createEmailCampaign.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        sendEmailCampaign.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        createEmailCampaign.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const {} = createEmailCampaignSlice.actions;
export default createEmailCampaignSlice.reducer;
