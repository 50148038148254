import { PROMO_CODES_COM } from "./../constants/clientWebsites";
import { DISCOUNT_CODES_COM } from "constants/clientWebsites";
import { LOCAL_KEY_CLIENT_WEBSITES } from "constants/storageKeys";
import DiscountCodes, { GetStoreByNetworkPayload } from "./discountcodes";
import PromoCodes from "./promocodes";
import { ExportResourceParams } from "redux/thunks/app/api-clients/exportCSVResource";

const clientWebsites =
  localStorage.getItem(LOCAL_KEY_CLIENT_WEBSITES) ||
  ({
    items: [],
  } as any);

export interface IClient {
  searchPrograms: (
    q: string,
    client_site_id?: string,
    extraParams?: Record<string, any>
  ) => unknown;
  getProgramDetails: (look_up_key: string, client_id: string) => unknown;
  getAllCategories: (payload?: Record<string, any>) => void;
}

class ClientAPI {
  clientId?: string;
  apiClient: any;

  constructor(client_id?: string) {
    if (client_id) {
      this.clientId = client_id;

      const clients = JSON.parse(clientWebsites) as any;

      const clientList: Record<string, any> = {};

      clients.items.forEach((element: any) => {
        clientList[element.id] = this.getClassFromName(element.name);
      });
      this.apiClient = new clientList[Number(client_id)]();

      return;
    } else {
      this.apiClient = new DiscountCodes();
    }
  }

  getClassFromName(name: string) {
    switch (name) {
      case DISCOUNT_CODES_COM:
        return DiscountCodes;
      case PROMO_CODES_COM:
        return PromoCodes;

      default:
        break;
    }
    return PromoCodes;
  }

  searchPrograms(
    query: string,
    client_id?: string,
    extraParams?: Record<string, any>
  ) {
    return this.apiClient.searchPrograms(query, client_id, extraParams);
  }
  createUpdateCategory(payload: Record<string, any>) {
    return this.apiClient.createUpdateCategory(payload);
  }
  getProgramDetails(look_up_key: string, client_id: string) {
    return this.apiClient.getProgramDetails(look_up_key, client_id);
  }

  getActivePrograms(payload: Record<string, any>) {
    return this.apiClient.getActivePrograms(payload);
  }

  getStoreCoupons(payload: Record<string, any>) {
    return this.apiClient.getStoreCoupons(payload);
  }

  getCategoryCoupons(payload: Record<string, any>) {
    return this.apiClient.getCategoryCoupons(payload);
  }

  createUpdateProgram(payload: any) {
    return this.apiClient.createUpdateProgram(payload);
  }

  createUpdateCoupon(payload: any) {
    return this.apiClient.createUpdateCoupon(payload);
  }

  getCategories(payload?: Record<string, any>) {
    return this.apiClient.getAllCategories(payload);
  }

  getProgramByNetworkId(payload: GetStoreByNetworkPayload) {
    return this.apiClient.getProgramByNetworkId(payload);
  }

  saveSortOrder(payload: Record<string, any>) {
    return this.apiClient.saveSortOrder(payload);
  }

  createFaq(payload: Record<string, any>) {
    return this.apiClient.createFaq(payload);
  }

  getCategory(payload: Record<string, any>) {
    return this.apiClient.getCategory(payload);
  }

  getTrendingStores(args: Record<string, any>) {
    return this.apiClient.getTrendingStores(args);
  }

  saveTrendingStores(payload: Record<string, any>) {
    return this.apiClient.saveTrendingStores(payload);
  }

  getTopCategories() {
    return this.apiClient.getTopCategories();
  }

  saveTopCategories(payload: Record<string, any>) {
    return this.apiClient.saveTopCategories(payload);
  }

  getCategoryStoreSpotlights(location: string) {
    return this.apiClient.getCategoryStoreSpotlights(location);
  }

  saveCategoryStoreSpotlights(payload: Record<string, any>) {
    return this.apiClient.saveCategoryStoreSpotlights(payload);
  }

  searchActiveCoupons(payload: Record<string, any>) {
    return this.apiClient.searchActiveCoupons(payload);
  }

  getCouponSpotlights() {
    return this.apiClient.getCouponSpotlights();
  }
  saveCouponSpotlights(payload: Record<string, any>) {
    return this.apiClient.saveCouponSpotlights(payload);
  }

  deletePaidPlacement(id: string) {
    return this.apiClient.deletePaidPlacement(id);
  }

  addToPaidPlacements(payload: Record<string, any>) {
    return this.apiClient.addToPaidPlacements(payload);
  }

  getHomeCoupons() {
    return this.apiClient.getHomeCoupons();
  }

  saveHomeCoupons(payload: Record<string, any>) {
    return this.apiClient.saveHomeCoupons(payload);
  }

  getEmailSubscribers(payload: Record<string, any>) {
    return this.apiClient.getEmailSubscribers(payload);
  }

  exportResource(payload: ExportResourceParams) {
    return this.apiClient.exportResource(payload);
  }

  getCouponContributors(payload: Record<string, any>) {
    return this.apiClient.getCouponContributors(payload);
  }
  updatePaidPlacement(payload: Record<string, any>) {
    return this.apiClient.updatePaidPlacement(payload);
  }
  getPaidPlacements(payload: Record<string, any>) {
    return this.apiClient.getPaidPlacements(payload);
  }

  checkPlacementPosAvailability(payload: Record<string, any>) {
    return this.apiClient.checkPlacementPosAvailability(payload);
  }

  updateTopCategory(payload: Record<string, any>) {
    return this.apiClient.updateTopCategory(payload);
  }
}

export default ClientAPI;
