import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sales_crm/setTodoComplete",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.post(
        `/admin/sales-crm/todo-items/${payload.id}/complete/`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
