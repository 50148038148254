import DataTable from "components/common/layout/DataTable";
import ActiveCouponRow from "components/coupons/ActiveCouponRow";
import ActiveCouponsHeader from "components/coupons/ActiveCouponsHeader";
import COUPON_COLUMNS, {
  AI_COUPON_COLUMNS,
} from "constants/tables/headers/oma/coupons";
import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
} from "react";
import { ExportResourceAction } from "redux/slices/clients/export-resource";
import { ActionState, PageNumPaginatedData } from "types/data";
import { User } from "types/model/User";
import { Option } from "types/option";

type Props = {
  loading: boolean;
  data: PageNumPaginatedData<Record<string, any>>;
  onPageChange: (p: number) => void;
  page: number;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  onItemSelectChange: (item: Record<string, any>) => void;
  selectedItems: Array<Record<string, any>>;
  handleSelectAll: () => void;
  onShowAll: () => void;
  setAllSelected: Dispatch<SetStateAction<boolean>>;
  allSelected: boolean;
  exportResourceData: ExportResourceAction;
  onExportAll: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  userOptions: User[];
  networkOptions: Option[];
  couponFilters: Record<string, any>;
  isAutoCoupons: boolean;
  getCouponOfferTypesData: ActionState;
};

const ActiveCouponsLayout = ({
  data,
  loading,
  page,
  onPageChange,
  onSearchChange,
  onSearchSubmit,
  onItemSelectChange,
  selectedItems,
  handleSelectAll,
  onShowAll,
  setAllSelected,
  allSelected,
  exportResourceData,
  onExportAll,
  form,
  networkOptions,
  onChange,
  userOptions,
  couponFilters,
  isAutoCoupons,
  getCouponOfferTypesData,
}: Props) => {
  return (
    <div className="w-full mt-4">
      <DataTable
        loading={loading}
        columns={isAutoCoupons ? AI_COUPON_COLUMNS : COUPON_COLUMNS}
        rows={data.results}
        columnProps={{
          [COUPON_COLUMNS[0].key]: {
            onChange: handleSelectAll,
            checked:
              allSelected ||
              // ITEMS_PER_PAGE === selectedItems.length ||
              selectedItems.length === data.count,
          },
        }}
        rowRenderer={(row) => (
          <ActiveCouponRow
            item={row}
            isAutoCoupons={isAutoCoupons}
            onItemSelectChange={onItemSelectChange}
            selectedItems={selectedItems}
          />
        )}
        currentPage={page}
        onPageChange={onPageChange}
        onShowAll={onShowAll}
        enableShowAll
        totalCount={data.count}
        header={
          <ActiveCouponsHeader
            setAllSelected={setAllSelected}
            total_items={data.count}
            userOptions={userOptions}
            onSearchSubmit={onSearchSubmit}
            onFilterChange={onSearchChange}
            selectedItems={selectedItems}
            allSelected={allSelected}
            getCouponOfferTypesData={getCouponOfferTypesData}
            exportResourceData={exportResourceData}
            onExportAll={onExportAll}
            onChange={onChange}
            form={form}
            title={(isAutoCoupons && "AI Coupon Offers") || ""}
            isAutoCoupons={isAutoCoupons}
            networkOptions={networkOptions}
            couponFilters={couponFilters}
          />
        }
      />
    </div>
  );
};

export default ActiveCouponsLayout;
