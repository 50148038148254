import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EditAdGroupComponent from "layout/marketing/search/EditAdgroup";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import updateAdGroup from "redux/thunks/marketing/sem/updateCampaign";
import { status } from "constants/googleAdCampaignStatuses";
import getCampaigns from "redux/thunks/marketing/sem/getCampaigns";

const EditAdGroup = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [params] = useSearchParams();
  const {
    getAdCampaigns: { data: getAdCampaignsData },
    updateAdGroup: { loading: updateAdGroupLoading },
  } = useAppSelector(({ getAdCampaigns, updateAdGroup }) => ({
    getAdCampaigns,
    updateAdGroup,
  }));

  const initialForm = {
    ...state,
    ad_group_name: "",
    default_bid: "",
    ad_group_status: "",
    campaign_id: "",
    action: "update-ad-group",
    search_engine: params.get("search_engine"),
  };

  const [form, setForm] = useState<Record<string, any>>(initialForm);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = () => {
    dispatch(
      updateAdGroup({
        ...form,
        ad_group_status:
          form.search_engine === "google"
            ? status[form.ad_group_status.toUpperCase()]
            : form.ad_group_status,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(getCampaigns({ program_slug: params.get("mid") }));
      }
    });
  };

  useEffect(() => {
    const campaign = getAdCampaignsData.find(
      (it) => String(it.id) === params.get("campaign_id")
    );

    if (campaign) {
      setForm((f) => ({
        ...f,
        ad_group_name: campaign.ad_group_name,
        default_bid: campaign.ad_group_default_cpc,
        ad_group_status: campaign.ad_group_status,
        ad_group_id: campaign.ad_group_id,
        campaign_id: campaign.id,
        search_engine: campaign.search_engine,
      }));
    }
  }, [getAdCampaignsData]);

  return (
    <EditAdGroupComponent
      updateAdGroupLoading={updateAdGroupLoading}
      onSubmit={onSubmit}
      form={form}
      onChange={onChange}
    />
  );
};

export default EditAdGroup;
