import { get, isObject } from "lodash";
import React from "react";
import { Column } from "types/column";
import components from "..";

type Props = {
  row: any;
  column: Column;
};

const DefaultCell = ({ row, column }: Props) => {
  const value = get(row, column.key);

  const singleItemClass = column.classNames?.body || "";
  const multipleItemClass = `inline-flex items-center ${
    column.classNames?.body || ""
  }`;

  const renderCell = () => {
    if (column.beforeCell) {
      return (
        <>
          {React.createElement(components[column.beforeCell])}
          {value}
        </>
      );
    }

    if (isObject(value)) {
      return JSON.stringify(value);
    }
    return value;
  };

  return (
    <span className={column.beforeCell ? multipleItemClass : singleItemClass}>
      {renderCell()}
    </span>
  );
};
export default DefaultCell;
