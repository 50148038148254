import { createBrowserRouter } from "react-router-dom";
import Login from "containers/auth/Login";
import MarketingHome from "containers/marketing/email/EmailHome";
import marketing from "./marketing";
import omaRoutes from "./oma";
import contributionRoutes from "./contributions";
import salesRoutes from "./sales";
import bmaRoutes from "./bma";
import crmRoutes from "./crm/crmRoutes";
import reportingRoutes from "./reporting/routes";
import settingsRoutes from "./settings/routes";
import sales_crm_routes from "./sales_crm/sales_crm_routes";

const router = createBrowserRouter([
  {
    path: "/auth/login",
    element: <Login />,
  },

  {
    path: "/marketing",
    element: <MarketingHome />,
  },

  ...omaRoutes,
  ...marketing,
  ...salesRoutes,
  ...contributionRoutes,
  ...bmaRoutes,
  ...crmRoutes,
  ...reportingRoutes,
  ...settingsRoutes,
  ...sales_crm_routes,
]);
export default router;
