import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";

export default createAsyncThunk(
  "sem/export-campaign-reports",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const reportUrl = new URL(`${BACKEND_BASE_URL}/admin/sem/export-report/`);

      Object.entries(payload).forEach(([k, v]) => {
        reportUrl.searchParams.set(k, v);
      });

      reportUrl.searchParams.set(
        "offset",
        String(getOffsetForPage(Number(payload.page || 1)))
      );

      // const response = await axios.get(reportUrl.href);

      const response = await axios.get(reportUrl.href, {
        responseType: "blob", // This tells Axios to treat the response as a binary object
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      let filename = `ad_campaign_performance_${new Date().getTime()}.csv`; // Provide a default filename
      link.setAttribute("download", filename); // The file name
      document.body.appendChild(link);
      link.click();

      return "0K";
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
