import DiscountInput from "components/common/DiscountInput";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from "react";
import Dropdown from "components/common/Dropdown";
import Label from "components/common/Label";
import Button from "components/common/Button";
import { ActionState, PaginatedActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";
import SearchPrograms from "containers/oma/SearchPrograms";
// import { proposalTypeOptions } from "constants/proposal/proposalTypes";
import PlacementsList from "./PlacementsList";
import LineItemsSelector from "./LineItemsSelector";
import countries from "constants/operationCountries";
import { ProposalType, SearchRightsType } from "types/model/sales/Proposal";
import DeleteButton from "components/common/DeleteButton";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  handleFormSubmit: () => void;
  form: Record<string, any>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  getCategoriesData: ActionState<ProgramCategory[]>;
  setPlacements: Dispatch<SetStateAction<Record<string, any>[]>>;
  placements: Array<Record<string, any>>;
  createUpdateDeleteProposalData: ActionState;
  isDeleting: boolean;
  formPage: string;
  lineItemsActive: boolean;
  setLineItemsActive: Dispatch<SetStateAction<boolean>>;
  finalAmount: string | number;
  totalAmount: string | number;
  getPositionAvailabilityStatusesData: ActionState<Record<string, any>>;
  removePaidPlacements: (item: Record<string, any>) => void;
  duplicateItem: (item: Record<string, any>, idx: number) => void;
  isViewing: boolean;
  onEditClicked: () => void;
  getProgramData: ActionState;
  handleAddForNewClient: () => void;
  onLineItemAdded: () => void;
  proposalTypes: PaginatedActionState<ProposalType>;
  getSearchRightsTypesData: PaginatedActionState<SearchRightsType>;
  deleteProposal: (
    proposal: Record<string, any>,
    delete_reason: string
  ) => void;
  onProgramSearchResultClicked: (result: Record<string, any>) => void;
};

export type AddLineItemsProps = Pick<
  Props,
  | "onChange"
  | "onLineItemAdded"
  | "formPage"
  | "getPositionAvailabilityStatusesData"
  | "getCategoriesData"
  | "setPlacements"
  | "form"
  | "setForm"
  | "placements"
  | "isViewing"
>;

const ProposalDetails = ({
  isViewing,
  setForm,
  form,
  onChange,
  lineItemsActive,
  setLineItemsActive,
  onLineItemAdded,
  formPage,
  getPositionAvailabilityStatusesData,
  getCategoriesData,
  setPlacements,
  placements,
  removePaidPlacements,
  totalAmount,
  finalAmount,
  createUpdateDeleteProposalData,
  onEditClicked,
  handleFormSubmit,
  onProgramSearchResultClicked,
  duplicateItem,
  proposalTypes,
  deleteProposal,
  getSearchRightsTypesData,
}: Props) => {
  const addLineItemsProps: AddLineItemsProps = {
    onChange: onChange,
    formPage: formPage,
    getPositionAvailabilityStatusesData: getPositionAvailabilityStatusesData,
    getCategoriesData: getCategoriesData,
    setPlacements: setPlacements,
    form: form,
    setForm: setForm,
    placements: placements,
    isViewing: isViewing,
    onLineItemAdded: onLineItemAdded,
  };

  const proposalName = proposalTypes.data.items.find(
    (it) => String(it.id) === String(form.proposal_type)
  )?.name;

  const isCPAi =
    form.proposal_type?.name?.includes("CPA") || proposalName?.includes("CPA");

  const isSearchRights =
    form.proposal_type?.name?.includes("Search") ||
    proposalName?.includes("Search");

  return (
    <div className="relative p-4 w-full h-full md:h-auto">
      <fieldset disabled={isViewing}>
        <div>
          {isViewing && (
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <div className="my-2">
                <Label label="Proposal ID" />
                <small>{form.proposal_id}</small>
              </div>
              <div className="my-2">
                <Label label="Program" />
                <small>{form.client_merchant_config?.[0]?.program_name}</small>
              </div>
              <div className="my-2">
                <Label label="Proposal Type" />
                <small>{form.proposal_type?.name || proposalName}</small>
              </div>
            </div>
          )}
          {!isViewing && (
            <>
              <div className="flex my-6   items-center gap-4">
                <Dropdown
                  label="Client"
                  wrapperClassName="w-96"
                  name="client"
                  onChange={(event) => {
                    setForm((f) => ({
                      ...f,
                      client: event.target.value,
                    }));
                  }}
                  options={form.clients}
                  value={form.client}
                />

                <SearchPrograms
                  label="Program"
                  client_id={form.client}
                  value={form.program?.name}
                  placeholder={form.program?.name}
                  onResultClicked={onProgramSearchResultClicked}
                />

                <Dropdown
                  label="Location"
                  name="location"
                  wrapperClassName="w-28"
                  onChange={(event) => {
                    setForm((f) => ({
                      ...f,
                      location: event.target.value,
                    }));
                  }}
                  options={countries.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  value={form.location}
                />
              </div>

              <div className="flex items-center gap-8">
                <Dropdown
                  label="Proposal Type"
                  name="proposal_type"
                  wrapperClassName="w-96"
                  onChange={(event) => {
                    setForm((f) => ({
                      ...f,
                      proposal_type: event.target.value,
                    }));
                  }}
                  options={proposalTypes.data.items.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  value={form.proposal_type?.id || form.proposal_type}
                />

                {isSearchRights && (
                  <Dropdown
                    options={getSearchRightsTypesData.data.items.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                    label="Type of Rights"
                    onChange={onChange}
                    name="search_rights_type"
                    loading={getSearchRightsTypesData.loading}
                    value={form.search_rights_type}
                  />
                )}

                {isCPAi && (
                  <DiscountInput
                    typeLabel="Type of increase"
                    onChange={onChange}
                    name="commission_increase"
                    value={form.commission_increase}
                    discount_type={form.commission_increase_type}
                    onTypeChange={(e) => {
                      onChange?.({
                        ...e,
                        target: {
                          ...e.target,
                          name: "commission_increase_type",
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>

        {!lineItemsActive && !isViewing && (
          <button
            className="my-4"
            onClick={() => setLineItemsActive((v: boolean) => !v)}
          >
            Add Line Items
          </button>
        )}

        {lineItemsActive && (
          <LineItemsSelector
            {...addLineItemsProps}
            onPlacementAdded={onLineItemAdded}
          />
        )}

        {!isViewing && <hr className="my-4" />}

        {placements.length > 0 && (
          <PlacementsList
            placements={placements}
            removePaidPlacements={removePaidPlacements}
            totalAmount={totalAmount}
            finalAmount={finalAmount}
            duplicateItem={duplicateItem}
            form={form}
            onChange={onChange}
            addLineItemsProps={addLineItemsProps}
          />
        )}

        {placements.length > 0 && !isViewing && (
          <Button
            type="submit"
            loading={createUpdateDeleteProposalData.loading}
            onClick={handleFormSubmit}
            text={form.id ? "Save Changes" : "Create Proposal"}
            className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 my-6"
          />
        )}
      </fieldset>

      {isViewing && (
        <div className="flex items-center py-4 px-4 space-x-4 sm:py-5 sm:px-5">
          <button
            onClick={onEditClicked}
            type="submit"
            className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Edit{" "}
          </button>
          <DeleteButton
            allowReason
            text="Delete"
            deleteLoading={createUpdateDeleteProposalData.loading}
            onClick={(reason) => {
              deleteProposal(form, reason || "");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProposalDetails;
