import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getDetailedVisitsDetailReport from "redux/thunks/reporting/extension/getVisitsDetailReport";
import { PageNumPaginatedActionState } from "types/data";

const initialState = {
  data: {},
  loading: false,
  error: null,
} as PageNumPaginatedActionState<any>;

const getDetailedVisitsReportSlice = createSlice({
  name: "getDetailedVisitsReportSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDetailedVisitsDetailReport.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getDetailedVisitsDetailReport.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getDetailedVisitsDetailReport.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const {} = getDetailedVisitsReportSlice.actions;
export default getDetailedVisitsReportSlice.reducer;
