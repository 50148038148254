import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getDeactivatedAdvertisers from "redux/thunks/getDeactivatedAdvertisers";
import { PaginatedActionState } from "types/data";
import { Advertiser } from "types/model/Advertiser";

const initialState: PaginatedActionState<Advertiser> = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getDeactivatedAdvertisersSlice = createSlice({
  name: "deactivatedAdvertisers",
  initialState,
  reducers: {
    resetAdvertisers: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeactivatedAdvertisers.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getDeactivatedAdvertisers.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )

      .addCase(
        getDeactivatedAdvertisers.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});
export const { resetAdvertisers } = getDeactivatedAdvertisersSlice.actions;
export default getDeactivatedAdvertisersSlice.reducer;
