import { LOCAL_KEY_CLIENT_WEBSITES } from "constants/storageKeys";
import React from "react";
import { twMerge } from "tailwind-merge";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  item: Record<string, any>;
  index: number;
  totals: Record<string, any>;
};

function PPCReportRow({ item, index, totals }: Props) {
  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 cursor-pointer",
    index === 0 && "bg-green-100 font-bold"
  );

  const clients = JSON.parse(
    localStorage.getItem(LOCAL_KEY_CLIENT_WEBSITES) || "[]"
  );

  const client =
    clients.items.find(
      (cl: Record<string, any>) =>
        String(cl.id) === String(item.ad_campaign?.client?.id || "")
    ) || {};

  const visitPage = () => {
    if (item.ad_campaign?.program_slug) {
      window.open(client.website_url + "/" + item.ad_campaign?.program_slug);
    }
  };

  const isClientView = totals.is_client == "1";

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <td
          className={twMerge(
            "px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs",
            item.ad_campaign?.program_slug && "cursor-pointer"
          )}
          onClick={visitPage}
        >
          {item.ad_campaign?.client?.name || item?.client?.name}
        </td>

        {!isClientView && (
          <>
            <td
              className={twMerge(
                "px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs",
                item.ad_campaign?.program_slug && "cursor-pointer"
              )}
              onClick={visitPage}
            >
              {item.ad_campaign?.name || item.ad_campaign?.program_slug}
            </td>
            <td
              className={twMerge(
                "px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs"
              )}
            >
              {item.affiliate_network}
            </td>

            <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
              {item.ad_campaign?.location?.toUpperCase()}
            </td>

            <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
              {item.ad_campaign?.search_engine}
            </td>
          </>
        )}

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals.total_impressions, {})
            : formatNumber(item.total_impressions, {})}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index == 0
            ? formatNumber(totals.total_clicks, {})
            : formatNumber(item.total_clicks, {})}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index == 0
            ? `${formatNumber(
                (totals.total_clicks / totals.total_impressions) * 100,
                { decimals: 2 }
              )}%`
            : `${formatNumber(
                (item.total_clicks / item.total_impressions) * 100,
                { decimals: 2 }
              )}%`}
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals.total_avg_cpc / 100000, {
                decimals: 2,
              })
            : formatNumber(item.total_avg_cpc / 100000, {
                decimals: 2,
              })}
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals.total_cost / 1000000, {
                prefix: "$",
                decimals: 2,
              })
            : formatNumber(item.total_cost / 1000000, {
                prefix: "$",
                decimals: 2,
              })}
        </td>

        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {index === 0
            ? formatNumber(totals.total_revenue / 1000, {
                prefix: "$",
                decimals: 2,
              })
            : formatNumber(item.total_commission_amount / 1000, {
                prefix: "$",
                decimals: 2,
              })}
        </th>

        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {index === 0
            ? formatNumber(
                totals.total_revenue / 1000 - totals.total_cost / 1000000,
                {
                  prefix: "$",
                  decimals: 2,
                }
              )
            : formatNumber(
                item.total_commission_amount / 1000 - item.total_cost / 1000000,
                {
                  prefix: "$",
                  decimals: 2,
                }
              )}
        </th>

        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {index === 0
            ? formatNumber(totals.total_sale_amount / 1000, {
                prefix: "$",
                decimals: 2,
              })
            : formatNumber(item.total_sale_amount / 1000, {
                prefix: "$",
                decimals: 2,
              })}
        </th>

        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {index === 0
            ? formatNumber(totals.total_transactions, {})
            : formatNumber(item.tx_count, {})}
        </th>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(
                (totals.total_transactions / (totals.total_clicks || 1)) * 100,
                { decimals: 2 }
              ) + "%"
            : formatNumber((item.tx_count / (item.total_clicks || 1)) * 100, {
                decimals: 2,
              }) + "%"}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(
                totals.total_revenue / 1000 / totals.total_cost / 1000000,
                {
                  prefix: "$",
                  decimals: 2,
                }
              )
            : formatNumber(
                item.total_commission_amount / 1000 / item.total_cost / 1000000,
                {
                  prefix: "$",
                  decimals: 2,
                }
              )}
        </td>

        <td className="px-4 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(
                totals.total_sale_amount / 1000 / totals.total_transactions,
                {
                  decimals: 2,
                  prefix: "$",
                }
              )
            : formatNumber(item.total_sale_amount / item.tx_count / 1000, {
                decimals: 2,
                prefix: "$",
              })}
        </td>
      </tr>
    </React.Fragment>
  );
}

export default PPCReportRow;
