import { timeAgo } from "index";
import React, { useState } from "react";
import { Advertiser } from "types/model/Advertiser";
import CommissionDisplay from "./CommissionDisplay";
import Status from "./Status";
import RenderCommissionRates from "./RenderCommissionRates";
import { twMerge } from "tailwind-merge";
import addHttp from "utils/urls/addHttp";

type Props = {
  item: Advertiser;
  onActivatePressed: (advertiser: Advertiser) => void;
  checked?: boolean;
  onItemSelectChange?: () => void;
  ignoreLoading?: boolean;
  handleIgnoreAdvertisers: (item?: Advertiser) => void;
};

function AdvertiserRow({
  item,
  onActivatePressed,
  checked,
  onItemSelectChange,
  ignoreLoading,
  handleIgnoreAdvertisers,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const firstCommissionConfig = item.commission_config?.[0] || {};

  const wrapperCls = twMerge(
    "border-b opacity-100 transition-all hover:bg-gray-100 cursor-pointer",
    ignoreLoading && checked && "opacity-50"
  );

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <td className="pl-2 py-2 w-4">
          <input
            id="checkbox-table-search-1"
            type="checkbox"
            checked={checked}
            onChange={onItemSelectChange}
            className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label htmlFor="checkbox-table-search-1" className="sr-only">
            checkbox
          </label>
        </td>
        <th
          scope="row"
          className="px-2 py-2 text-xs font-medium text-gray-900 "
          onClick={() => {
            setExpanded((exp) => !exp);
          }}
        >
          {item.name}{" "}
        </th>

        <td className="px-2 truncate max-w-sm py-2 font-medium text-xs text-gray-900 whitespace-nowrap ">
          <a
            href={addHttp(item.website)}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-primary-600 hover:underline inline-flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
            {item.website_base || item.website}
          </a>
        </td>

        <th
          scope="row"
          className="px-2 py-2 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.network}
        </th>

        <td className="px-2 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.external_id}
        </td>

        <td className="px-4 py-2 font-medium whitespace-nowrap">
          <div className="flex justify-end mb-1">
            <span className="text-xs font-medium w-40 overflow-auto text-gray-500 dark:text-gray-400">
              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <CommissionDisplay
                  firstCommissionConfig={firstCommissionConfig}
                />
              </li>
            </span>
          </div>
        </td>

        <td className="px-4 py-2 whitespace-nowrap text-xs">
          <Status status={item.network_status} />
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {timeAgo.format(new Date(item.updated_at))}
        </td>
        <td className="p-2">
          <button className="flex items-center gap-3">
            <button onClick={() => handleIgnoreAdvertisers(item)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4 text-red-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => {
                onActivatePressed(item);
              }}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              {/*<LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg>
              Activate
            </button>
          </button>
        </td>
      </tr>
      <tr
        hidden={!expanded}
        className="flex-1 overflow-x-auto w-full"
        id="table-column-body-0"
        aria-labelledby="table-column-header-0"
      >
        <td className="p-4 border-b dark:border-gray-700" colSpan={9}>
          <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="border-t border-gray-200 px-4 py-2 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Full name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {" "}
                    {item.contact_name}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Email address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {" "}
                    {item.contact_email}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Network Joined Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {item.join_date}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Our Partnership Approval Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {item.join_date}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Page Creation Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    Page Creation Date
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Description
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {item.description}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Commission Terms
                  </dt>
                  <div className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <RenderCommissionRates item={item} />
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default AdvertiserRow;
