import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getTodos from "redux/thunks/todos/getTodos";
import { TodoItem } from "types/model/Todo";

export interface PaginatedData {
  meta: {
    count: number;
    limit: number;
    offset: number;
  };
  items: Array<TodoItem>;
}

export type ActionStateType = {
  data: PaginatedData;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getTodosSlice = createSlice({
  name: "Todos",
  initialState: initialState,
  reducers: {
    resetTodos: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTodos.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(getTodos.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if ({ payload }) {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      })

      .addCase(getTodos.rejected, (state, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: false,
        error: payload,
      }));
  },
});
export const { resetTodos } = getTodosSlice.actions;
export default getTodosSlice.reducer;
