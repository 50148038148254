import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

type Params = {
  data: Record<string, any>;
};

export default createAsyncThunk(
  "networks/create-update-network",
  async ({ data }: Params, ThunkAPI) => {
    const payload = {
      ...data,
    };
    try {
      const config = {
        url: payload.id ? `/admin/networks/${payload.id}` : `/admin/networks/`,
        method: payload.id ? "PATCH" : "POST",
        data: payload,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          },
      );
    }
  },
);
