import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";

export default createAsyncThunk(
  "api-clients/updatePaidPlacement",
  async (data: Record<string, any>, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );

      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }

      if (data.file) {
        const s3 = new S3({
          ...defaultS3Config,
          dirName: `img/${selectedClientId}/placements/${data.id}`,
        });

        const result = await s3.uploadFile(data.file);
        const { location } = result;
        data["image_url"] = location;
      }

      const clientWebsite = new ClientAPI();

      const response = await clientWebsite.updatePaidPlacement(data);

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          }
      );
    }
  }
);
