import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "components/layout/Layout";
import { twMerge } from "tailwind-merge";
import { Fragment } from "react";
import classNames from "classnames";

type TabItem = {
  text: string;
  onClick?: () => void;
  content: JSX.Element;
  label?: number;
  active?: boolean;
};

type Props = {
  options: Array<TabItem>;
  loading?: boolean;
  globalLayout?: boolean;
  rightElement?: JSX.Element | null;
  hasPermissions?: boolean;
  hideContent?: boolean;
  variant?: "simple" | "default";
};

const TabLayout = ({
  options,
  loading = false,
  globalLayout = true,
  hideContent = false,
  hasPermissions,
  rightElement,
  variant = "default",
}: Props) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const selectedTab = params.get("tab") || "1";

  const handleSelectTab = (idx: number) => {
    const url = new URL(window.location.origin + window.location.pathname);
    if (params.has("slug")) {
      url.searchParams.set("slug", params.get("slug") || "");
    }
    url.searchParams.set("tab", String(idx + 1));

    navigate(url.search);
  };

  const content = (
    <Fragment>
      <div className="flex justify-between mb-4">
        {" "}
        <div
          className="flex gap-6 flex-col w-full rounded-md shadow-sm md:w-auto md:flex-row"
          role="group"
        >
          {options.map((el, index) => (
            <>
              <div className="relative" key={el.text}>
                <button
                  type="button"
                  onClick={() => {
                    if (typeof el.onClick !== "function") {
                      handleSelectTab(index);
                    } else {
                      el.onClick();
                    }
                  }}
                  className={
                    variant == "simple"
                      ? classNames(
                          String(index) === selectedTab || el.active
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                          "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        )
                      : twMerge(
                          (Number(selectedTab) + 1) % 2 !== 0 &&
                            "px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg rounded-bl-none rounded-r-lg",

                          (Number(selectedTab) + 1) % 2 === 0 &&
                            "px-4 py-2 text-sm font-medium   border border-gray-200 rounded-t-lg rounded-tr-none rounded-l-lg   focus:z-10 focus:ring-2 ",

                          (el.active || selectedTab === String(index + 1)) &&
                            "ring-primary-700 text-primary-700 bg-gray-100  focus:z-10 focus:ring-2   dark:border-gray-600"
                        )
                  }
                >
                  {el.text}
                </button>
                {Number.isInteger(el.label) && (el?.label || 0) > 0 && (
                  <span className="absolute top-1 right-0 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-full transform translate-x-1/2 -translate-y-1/2">
                    {el.label}
                  </span>
                )}
              </div>
            </>
          ))}
        </div>
        {rightElement}
      </div>

      {!hideContent &&
        options.map((el, idx) =>
          String(idx + 1) === selectedTab ? el.content : null
        )}
    </Fragment>
  );

  if (!globalLayout) {
    return content;
  }

  return (
    <Layout loading={loading} hasPermissions={hasPermissions}>
      {content}
    </Layout>
  );
};

export default TabLayout;
