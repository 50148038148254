import Checkbox from "components/common/Checkbox";
import Dropdown from "components/common/Dropdown";
import DataTable from "components/common/layout/DataTable";
import SearchForm from "components/common/SearchForm";
import CompetitorProgramRow from "components/CompetitorProgramRow";
import COMPETITOR_PROGRAM_COLUMNS from "constants/tables/headers/bma/CompetitorPrograms";
import { ChangeEventHandler } from "react";
import { PaginatedActionState } from "types/data";

type Props = {
  getCompetitorProgramsData: PaginatedActionState<Record<string, any>>;
  onPageChange: (page: Number) => void;
  page: Number;
  filterValues: Record<string, any>;
  onHasAdCampaignChange: ChangeEventHandler<HTMLInputElement>;
  getCompetitorData: PaginatedActionState<Record<string, any>>;
  handleCompetitorChange: ChangeEventHandler<HTMLInputElement>;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
};

const CompetitorProgramsLayout = ({
  getCompetitorProgramsData,
  onPageChange,
  page,
  filterValues,
  onHasAdCampaignChange,
  getCompetitorData,
  handleCompetitorChange,
  onSearchChange,
}: Props) => {
  return (
    <div>
      <div className="px-4 pb-2">
        <div className="flex items-center gap-4 my-8">
          <Dropdown
            loading={getCompetitorData.loading}
            options={getCompetitorData.data.items.map((it) => ({
              label: it.name,
              value: it.id,
            }))}
            placeholder="Competitor"
            wrapperClassName="w-56"
            value={filterValues.competitor}
            onChange={handleCompetitorChange}
          />
          <SearchForm onChange={onSearchChange} placeholder="Program Name" />
        </div>
        <div className="flex justify-between items-center">
          <div className="flex">
            <Checkbox
              checked={filterValues.program_match == "1"}
              label="Program Match"
              name="program_match"
              onChange={onHasAdCampaignChange}
            />
            <Checkbox
              checked={filterValues.has_campaign == "1"}
              label="Has Ad Campaign"
              name="has_campaign"
              onChange={onHasAdCampaignChange}
            />
          </div>
          <small>{getCompetitorProgramsData.data.meta.count} Items</small>
        </div>
      </div>
      <DataTable
        loading={getCompetitorProgramsData.loading}
        columns={COMPETITOR_PROGRAM_COLUMNS}
        rows={getCompetitorProgramsData.data.items}
        onPageChange={onPageChange}
        totalCount={getCompetitorProgramsData.data.meta.count}
        currentPage={Number(page)}
        rowRenderer={(item) => {
          return <CompetitorProgramRow item={item} />;
        }}
      />
    </div>
  );
};

export default CompetitorProgramsLayout;
