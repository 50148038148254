const storeCurrencyMap: Record<string, string> = {
  uk: "£",
  canada: "CA$",
  australia: "AU$",
  us: "$",
  nl: "€",
  fr: "€",
  de: "€",
};

export const currencyNameSymbolMap: Record<string, string> = {
  GBP: "£",
  CAD: "CA$",
  AUD: "AU$",
  USD: "$",
  EUR: "€",
  BRL: "R$",
  SEK: "kr",
  DKK: "kr.",
  CZK: "Kč",
};

export default storeCurrencyMap;
