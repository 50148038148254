import Dropdown from "components/common/Dropdown";
import DynamicInput from "components/common/DynamicInput";
import Input from "components/common/Input";
import Label from "components/common/Label";
import countries from "constants/operationCountries";
import storeRanks from "constants/storeRanks";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from "react";
import { ActionState } from "types/data";
import { StoreType } from "types/model/oma/StoreType";
import StoreTypeSelector from "./StoreTypeSelector";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import { CouponPolicy } from "types/model/oma/CouponPolicy";
import Checkbox from "components/common/Checkbox";

type Props = {
  form: Record<string, any>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  createUpdateStore: ActionState;
  getStoreTypesData: ActionState<StoreType[]>;
  getCouponPolicyData: ActionState<CouponPolicy[]>;
};

const PageSettings = ({
  onChange,
  form,
  createUpdateStore: { error },
  getStoreTypesData,
  getCouponPolicyData,
  setForm,
}: Props) => {
  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      <div className="space-y-6 sm:space-y-4 mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between gap-4">
          <div className="w-full sm:w-1/3">
            <Input
              label="Business Name"
              onChange={onChange}
              value={form?.name}
              name="name"
            />
          </div>

          <div className="w-full sm:w-1/3">
            <Input
              label="Slug"
              name="slug"
              onChange={onChange}
              value={form?.slug}
              error={error?.slug}
            />
          </div>
          <div className="w-full sm:w-1/3">
            <Input
              label="Page Headline"
              onChange={onChange}
              value={form?.page_headline}
              name="page_headline"
            />
          </div>
        </div>

        {/* <div className="flex flex-col sm:flex-row gap-4 ">
          <div className="w-full sm:w-1/3">
            <Checkbox
              label="AI Enabled"
              name="is_ai_enabled"
              checked={form?.is_ai_enabled}
              onChange={(e) => {
                setForm((f) => ({ ...f, is_ai_enabled: e.target.checked }));
              }}
            />
          </div>
        </div> */}
        <div className="flex flex-col sm:flex-row gap-4 ">
          <div className="w-full sm:w-1/3">
            <Input
              label="Website Homepage URL"
              onChange={onChange}
              name="website_url"
              value={form?.website_url}
            />
          </div>

          <div className="w-full sm:w-2/3">
            <Input
              onChange={onChange}
              label="Affiliate Website Homepage URL"
              name="affiliate_website_url"
              value={form?.affiliate_website_url}
              error={error?.affiliate_website_url}
            />
          </div>
        </div>
        <div className="mb-4">
          <StoreTypeSelector
            form={form}
            wrapperClass="flex"
            getStoreTypesData={getStoreTypesData}
            onChange={onChange}
          />
          <div className="w-full sm:w-1/3">
            <Input
              label="Stripe Customer ID"
              onChange={onChange}
              value={form?.stripe_customer_id}
              name="stripe_customer_id"
            />
          </div>
        </div>
        <div className="flex gap-4">
          <Dropdown
            className="w-full "
            value={form?.rank}
            options={
              !form?.rank
                ? [{ name: "", value: "" }, ...storeRanks].map((item) => ({
                    label: item.name,
                    value: item.name,
                  }))
                : storeRanks.map((item) => ({
                    label: item.name,
                    value: item.name,
                  }))
            }
            name="rank"
            label="Store Traffic Rank"
            onChange={onChange}
          />

          <Dropdown
            className="w-full "
            value={form?.location}
            options={
              !form?.location
                ? ["", ...countries].map((item) => ({
                    label: item,
                    value: item.toLowerCase(),
                  }))
                : countries.map((item) => ({
                    label: item,
                    value: item.toLowerCase(),
                  }))
            }
            name="location"
            label="Store Location"
            onChange={onChange}
          />

          <div>
            <Dropdown
              options={["Open", "Closed"].map((el) => ({
                label: el,
                value: el,
              }))}
              label="PPC Policy"
              name="ppc_policy"
              value={form?.ppc_policy}
              onChange={onChange}
            />
          </div>

          <div>
            <MultipleSelectionInput
              wrapperClassName="w-80"
              options={form.coupon_policies}
              label="Coupon Policies"
              name="coupon_policies"
              value={form?.coupon_policies}
              renderAll
              loading={getCouponPolicyData.loading}
              onChange={(e) => {
                if (!e.target.checked) {
                  setForm((f) => ({
                    ...f,
                    coupon_policies: form.coupon_policies.map(
                      (el: Record<string, any>) =>
                        el.value == e.target.value
                          ? { ...el, selected: false }
                          : el
                    ),
                  }));
                } else {
                  setForm((f) => ({
                    ...f,
                    coupon_policies: form.coupon_policies.map(
                      (el: Record<string, any>) =>
                        el.value == e.target.value
                          ? { ...el, selected: true }
                          : el
                    ),
                  }));
                }
              }}
            />
          </div>
        </div>

        <div className="my-4 ">
          <Input
            label="Meta Title"
            onChange={onChange}
            name="meta_title"
            wrapperClassName="mt-4"
            value={form?.meta_title || ""}
          />
          <Input
            label="Meta Description"
            onChange={onChange}
            name="meta_description"
            wrapperClassName="mt-4"
            value={form?.meta_description || ""}
          />
        </div>

        <div
          className="
        bg-gray-50 text-gray-900 text-sm block w-full flex-1 border-0
        outline-none focus:outline-none focus:border-gray-50 ring-0 focus:ring-0
         py-4 px-2 "
        >
          <Label label="Additional domains" wrapperClassName="mt-4" />

          <DynamicInput
            label=""
            id="additional_urls"
            name="additionalURLS"
            urlPrefix="URL"
            values={form.additional_domains}
            onChange={(e) => {
              const values = Object.values(e.target.value)[0];
              onChange({
                target: {
                  name: "additional_domains",
                  value: values,
                },
              } as unknown as ChangeEvent<HTMLInputElement>);
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default PageSettings;
