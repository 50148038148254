import React, {
  useState,
  FormEvent,
  ChangeEventHandler,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getNetworkTypes from "redux/thunks/sales_crm/getNetworkTypes";
import Dropdown from "components/common/Dropdown";
import createCrmNetwork from "redux/thunks/sales_crm/createCrmNetwork";
import Button from "components/common/Button";
import toast from "react-hot-toast";
import getCRMNetworks from "redux/thunks/sales_crm/getCRMNetworks";
import Input from "components/common/Input";
import { CRMNetwork } from "types/model/sales_crm/Network";
import editDeleteCrmNetwork from "redux/thunks/sales_crm/editDeleteCrmNetwork";
import DeleteButton from "components/common/DeleteButton";
import Modal from "components/common/Modal";

interface NetworkEntryCardProps {
  setShowNetworkModal: Dispatch<SetStateAction<boolean>>;
  activeNetwork?: CRMNetwork;
  showNetworkModal: boolean;
}

const NetworkEntryCard: React.FC<NetworkEntryCardProps> = ({
  setShowNetworkModal,
  activeNetwork,
  showNetworkModal,
}) => {
  const dispatch = useAppDispatch();
  const networkTypes = useAppSelector((state) => state.getCRMNetworkTypes);
  const createCRMNetworkData = useAppSelector(
    (state) => state.createCRMNetwork
  );
  const editDeleteNetworkData = useAppSelector(
    (state) => state.editDeleteRMNetwork
  );
  const [form, setForm] = useState<Record<string, any>>({ ...activeNetwork });

  useEffect(() => {
    dispatch(getNetworkTypes());
  }, []);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  const successHandler = (res: any) => {
    if (res.meta.requestStatus === "fulfilled") {
      toast.success("Network updated successfully");
      dispatch(getCRMNetworks());
      setForm({});
      setShowNetworkModal(false);
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (form.id && form.network_type && form.name) {
      dispatch(
        editDeleteCrmNetwork({
          ...form,
          network_type: form.network_type?.id || form.network_type,
        })
      ).then(successHandler);
    } else if (form.network_type && form.name) {
      dispatch(createCrmNetwork(form)).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Network created successfully");
          dispatch(getCRMNetworks());
          setForm({});
          setShowNetworkModal(false);
        }
      });
    }
  };

  return (
    <Modal
      wrapperClassName="max-w-2xl h-full "
      open={showNetworkModal}
      variant="lg"
      showCancel={false}
      showDone={false}
      setOpen={setShowNetworkModal}
    >
      <div className="flex items-center justify-between p-5 border-b dark:border-gray-600">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          {form.id ? "Network Details" : "Add New Network"}
        </h3>
        <button
          type="button"
          onClick={() => {
            setShowNetworkModal(false);
          }}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div className="p-6 space-y-6">
        <div className="grid grid-cols-1 gap-6">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900"></legend>

            <div className="sm:col-span-2">
              {form.id && form.logo_url && !form.tempImage && (
                <img
                  className="mb-4 w-20 h-20 rounded-full sm:mr-4 sm:mb-0 object-cover"
                  src={form.logo_url}
                  alt="Helene avatar"
                />
              )}
              <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                htmlFor="file_input"
              >
                Upload Logo
              </label>
              <div className="items-center w-full sm:flex">
                {form.tempImage && (
                  <img
                    className="mb-4 w-20 h-20 rounded-full sm:mr-4 sm:mb-0 object-cover"
                    src={form.tempImage || form.logo_url}
                    alt="Helene avatar"
                  />
                )}
                <div className="w-full">
                  <input
                    className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    id="file_input"
                    type="file"
                    name="file"
                    accept="image/png,image/jpg,image/jpeg"
                    onChange={(e) => {
                      setForm((pre) => ({
                        ...pre,
                        file: e.target.files?.[0] as any,
                        tempImage: URL.createObjectURL(
                          e.target.files?.[0] as any
                        ),
                      }));
                    }}
                  />
                  <p
                    className="mt-1 mb-3 text-xs font-normal text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    PNG, JPG (MAX. 800x400px).
                  </p>
                </div>
              </div>
            </div>

            <Dropdown
              label=" Network Type"
              name="network_type"
              loading={networkTypes.loading}
              value={form.network_type?.id || form.network_type}
              error={createCRMNetworkData.error?.network_type}
              onChange={onChange}
              options={
                Array.isArray(networkTypes.data)
                  ? networkTypes.data?.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))
                  : []
              }
            />
          </fieldset>

          <div>
            <Input
              label="Network Name"
              type="text"
              name="name"
              id="name"
              value={form.name}
              onChange={onChange}
              placeholder="Enter network name"
              error={createCRMNetworkData.error?.name}
            />
          </div>

          <div>
            <Input
              type="url"
              label=" Website URL"
              name="website_url"
              id="website_url"
              value={form.website_url}
              onChange={onChange}
              placeholder="http://example.com"
              error={createCRMNetworkData.error?.website_url}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 ">
        {form.id && (
          <>
            <DeleteButton
              deleteLoading={editDeleteNetworkData.loading}
              text="Delete"
              onClick={() => {
                dispatch(
                  editDeleteCrmNetwork({ delete: true, id: form.id })
                ).then(successHandler);
              }}
            />
          </>
        )}
        <Button
          loading={
            createCRMNetworkData.loading || editDeleteNetworkData.loading
          }
          type="submit"
          onClick={handleSubmit}
          text={form.id ? "Save" : "Add Network"}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        />
      </div>
    </Modal>
  );
};

export default NetworkEntryCard;
