import ActivePrograms from "containers/bma/ActivePrograms";
import BMAHome from "containers/bma/BMAHome";
import { Link, useNavigate } from "react-router-dom";
import TabLayout from "../../components/common/TabLayout";
import { COUPONS_APP, PROGRAMS_APP } from "constants/smaAppNames";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import { useEffect } from "react";

function BMATabs() {
  const navigate = useNavigate();
  const { user } = useLoadAuth();

  const hasProgramsAccess = user?.user_apps
    ?.map((it) => it.name.toLowerCase())
    ?.includes(PROGRAMS_APP.toLowerCase());

  const hasPermissions =
    user?.user_role == USER_ROLE_ADMIN || hasProgramsAccess || false;

  useEffect(() => {
    if (
      !hasPermissions &&
      user?.user_apps
        ?.map((it) => it.name?.toLowerCase())
        ?.includes(COUPONS_APP.toLocaleLowerCase())
    ) {
      navigate("/oma");
    }
  }, [user?.user_apps, hasPermissions]);

  const options = [
    {
      text: "New Programs",
      onClick: () => {
        navigate("/?tab=1&network_id=cj");
      },

      content: <BMAHome />,
    },

    {
      text: "All Active Programs",
      onClick: () => {
        navigate("/?tab=2");
      },

      content: <ActivePrograms />,
    },
  ];

  return (
    <TabLayout
      hasPermissions={hasPermissions}
      options={options}
      rightElement={
        <div className="w-full md:w-auto flex flex-col md:flex-row mb-3 mr-4 md:mb-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
          <Link
            to={"/bma/activate"}
            type="button"
            className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            <svg
              className="h-3.5 w-3.5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              />
            </svg>
            Add New Program
          </Link>
        </div>
      }
    />
  );
}

export default BMATabs;
