import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pageNumItemsInitialState from "redux/initial-states/pageNumItemsInitialState";
import getPlacementDestinations from "redux/thunks/app/placements/campaigns/getPlacementDestinations";
import { PageNumPaginatedActionState } from "types/data";
import PlacementDestination from "types/model/PlacementDestination";

export type ActionStateType = {
  data: PageNumPaginatedActionState<PlacementDestination>;
  loading: boolean;
  error: Record<string, any> | null;
};

const getPlacementDestinationsSlice = createSlice({
  name: "getPlacementDestinations",
  initialState: pageNumItemsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlacementDestinations.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        getPlacementDestinations.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getPlacementDestinations.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getPlacementDestinationsSlice.reducer;
