import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { ActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";
import SearchBar from "./SearchBar";
import CategorySelector from "components/common/CategorySelector";

type Props = {
  form: Record<string, any>;
  getCategoriesData: ActionState<ProgramCategory[]>;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const ProgramCategories = ({ form, onChange, getCategoriesData }: Props) => {
  const sortedOptions = getCategoriesData.data;

  const [similarCategoriesResults, setSimilarCategoriesResults] = useState<
    ProgramCategory[]
  >([]);
  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      <div className="space-y-6 sm:space-y-4 mx-auto px-4">
        <CategorySelector
          className="block w-full sm:w-2/3  "
          value={
            form.primary_category &&
            sortedOptions.find(
              (it) =>
                it.id === (form.primary_category.id || form.primary_category)
            )?.name
          }
          onResultClick={(result) => {
            onChange({
              target: {
                name: "primary_category",
                value: result.id,
              },
            } as ChangeEvent<HTMLInputElement>);
          }}
          onFocus={() => {
            onChange({
              target: {
                name: "primary_category",
                value: undefined,
              },
            } as unknown as ChangeEvent<HTMLInputElement>);
          }}
        />

        <div className="mt-8 w-full sm:w-2/3">
          <SearchBar
            placeholder="Search..."
            onChange={(e) => {
              setSimilarCategoriesResults(
                getCategoriesData.data
                  .filter(
                    (it) =>
                      !form.similar_categories
                        .map((el: ProgramCategory) => el.name)
                        .includes(it.name)
                  )
                  .filter((el: ProgramCategory) => {
                    return el.name.toLowerCase()?.startsWith(e.target.value);
                  })
              );
            }}
            onResultClick={(result) => {
              onChange({
                target: {
                  name: "similar_categories",
                  value: [...form.similar_categories, result],
                },
              } as unknown as ChangeEvent<HTMLInputElement>);

              setSimilarCategoriesResults([]);
            }}
            onResultsOutsideClick={() => {
              setSimilarCategoriesResults([]);
            }}
            onSearchSubmit={() => {
              //
            }}
            results={similarCategoriesResults}
            label="Choose Related Categories"
          />
        </div>

        <div className="w-full sm:w-2/3">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Category Type
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {form.similar_categories.map((el: ProgramCategory) => (
                    <tr key={el.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {el.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {el.parent ? "Sub-Category" : "Parent"}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          onClick={(e) => {
                            onChange({
                              target: {
                                name: "similar_categories",
                                value: form.similar_categories.filter(
                                  (it: ProgramCategory) => it.id !== el.id
                                ),
                              },
                            } as ChangeEvent<HTMLInputElement>);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Remove<span className="sr-only">Remove item</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgramCategories;
