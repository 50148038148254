import { timeAgo } from "index";
import { ChangeEventHandler } from "react";
import formatNumber from "utils/strings/formatNumber";
import ReportFilters from "./ReportFilters";
import { PaginatedActionState } from "types/data";
import { Client } from "types/model/Client";
import { AffiliateNetwork } from "types/model/AffiliateNetwork";
import Button from "components/common/Button";
import { TransactionReportActionStateType } from "redux/slices/reporting/revenue/getTransactionReport";
import {
  REPORT_TYPE_CLIENT_ACQUISITION_DAILY,
  REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY,
  REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY,
  REPORT_TYPE_DAILY_REVENUE,
  REPORT_TYPE_MONTHLY_REVENUE,
  REPORT_TYPE_WEEKLY_REVENUE,
} from "constants/revenueReportTypes";
import { GoGraph } from "react-icons/go";

type Props = {
  getReport: () => void;
  filterValues: Record<string, string>;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  getClientsData: PaginatedActionState<Client>;
  affiliateNetworks: PaginatedActionState<AffiliateNetwork>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  pids: Record<string, string>[];
  getTransactionReport: TransactionReportActionStateType;
  openAddColumns: () => void;
  showAddColumns?: boolean;
  showPerfView: () => void;
};

const ReportTableHeader = ({
  filterValues,
  getClientsData,
  onChange,
  affiliateNetworks,
  pids,
  onSearchChange,
  getTransactionReport,
  getReport,
  openAddColumns,
  showAddColumns,
  showPerfView,
}: Props) => {
  const isChartView =
    filterValues.report_type === REPORT_TYPE_DAILY_REVENUE ||
    filterValues.report_type == REPORT_TYPE_WEEKLY_REVENUE ||
    filterValues.report_type == REPORT_TYPE_MONTHLY_REVENUE ||
    filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION_DAILY ||
    filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY ||
    filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY;

  if (isChartView) {
    return (
      <div className="flex items-center gap-8 mb-8">
        <ReportFilters
          filterValues={filterValues}
          getClientsData={getClientsData}
          onChange={onChange}
          affiliateNetworks={affiliateNetworks}
          onSearchChange={onSearchChange}
          pids={pids}
          isChartView={isChartView}
        />
        {filterValues.report_type && (
          <Button
            text="Run Report"
            spinnerClassName="h-8 w-8 mr-0"
            loading={getTransactionReport.loading}
            onClick={getReport}
          />
        )}
      </div>
    );
  }

  const updateDate =
    getTransactionReport.data.report?.items?.[0]?.updated_at || null;

  return (
    <div>
      <ReportFilters
        filterValues={filterValues}
        getClientsData={getClientsData}
        onChange={onChange}
        affiliateNetworks={affiliateNetworks}
        onSearchChange={onSearchChange}
        pids={pids}
      />
      {filterValues.report_type && (
        <div className="flex items-center justify-between px-4">
          <Button
            text="Run Report"
            loading={getTransactionReport.loading}
            onClick={getReport}
            showSpinner
          />

          <div className="flex items-center gap-4">
            <p className="block text-xs font-medium">
              {formatNumber(
                getTransactionReport.data.report?.meta?.count || 0,
                {}
              )}{" "}
              Items
            </p>
            {updateDate ? (
              <p className="font-medium text-gray-900 text-xs">
                {timeAgo.format(new Date(updateDate))}
              </p>
            ) : null}
          </div>
        </div>
      )}

      {!isChartView && (
        <div className="flex justify-between">
          <p></p>

          {showAddColumns ? (
            <button
              className="block py-2 px-4  text-xs text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-whi mb-2"
              onClick={openAddColumns}
            >
              Add Columns
            </button>
          ) : (
            <button
              disabled={!filterValues.report_type}
              className="block py-2 px-4  text-xs text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white mb-2"
              onClick={showPerfView}
            >
              {filterValues.report_type && <GoGraph size={16} />}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportTableHeader;
