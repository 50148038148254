import {
  LOCAL_KEY_USER_EVENTS,
  LOCAL_KEY_USER_IOS,
} from "constants/storageKeys";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import instance from "helpers/axios";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { QuarterSalesRevenue } from "layout/sales_crm/components/RevenueSnapshot";
import SalesDashboard from "layout/sales_crm/SalesDashboard/SalesDashboard";
import { useEffect, useState } from "react";
import getIos from "redux/thunks/sales/getIos";
import getEvents from "redux/thunks/sales_crm/getEvents";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const eventsData = useAppSelector((state) => state.getEvents);
  const ioData = useAppSelector((state) => state.getIos);
  const [quarterNumbers, setQuarterNumbers] = useState<QuarterSalesRevenue>({
    quarter_totals: {},
    year_totals: {},
  });
  const [loading, setLoading] = useState(false);

  const { user } = useLoadAuth();
  const [userId, setUserId] = useState<string>("");

  const isAdmin = user?.user_role === USER_ROLE_ADMIN;

  const adminUserFilter = isAdmin && !userId ? "All" : user?.id || "";

  useEffect(() => {
    setLoading(true);
    instance
      .get(
        `admin/sales/proposals/quarter-performance/?user_id=${
          userId || adminUserFilter || ""
        }`
      )
      .then((res) => {
        setQuarterNumbers(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    dispatch(
      getEvents({
        user_id: user?.id || "",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        localStorage.setItem(
          LOCAL_KEY_USER_EVENTS,
          JSON.stringify(res.payload.items)
        );
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      getIos({
        created_by: user?.id || "",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        localStorage.setItem(
          LOCAL_KEY_USER_IOS,
          JSON.stringify(res.payload.items)
        );
      }
    });
  }, []);

  return (
    <SalesDashboard
      setUserId={setUserId}
      isAdmin={isAdmin}
      quarterNumbers={quarterNumbers}
      loading={loading}
    />
  );
};
export default Dashboard;
