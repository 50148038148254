import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getPayments from "redux/thunks/sales/getPayments";
import { PaginatedData } from "types/data";
import { IOPayment } from "types/model/sales/IOPayment";

export type ActionStateType = {
  data: PaginatedData<IOPayment>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getPaymentsSlice = createSlice({
  name: "getPayments",
  initialState,
  reducers: {
    resetGetPayments: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getPayments.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getPayments.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetGetPayments } = getPaymentsSlice.actions;
export default getPaymentsSlice.reducer;
