import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk("sem/get-device-types", async (_, ThunkAPI) => {
  try {
    const response = await axios.get(`/admin/sem/device-types/`);
    return response.data;
  } catch (error: any) {
    return ThunkAPI.rejectWithValue(
      error.response?.data || {
        message: "Could not fetch",
      }
    );
  }
});
