import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { capitalize } from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { resetQueryCampaignsResults } from "redux/slices/marketing/sem/searchAdCampaigns";
import searchAdCampaigns from "redux/thunks/marketing/sem/searchAdCampaigns";
import { twMerge } from "tailwind-merge";

const ProgramCampaignsLabel = () => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const {
    searchAdCampaigns: { data: searchAdCampaignResult },
  } = useAppSelector(({ getProgram, getClients, searchAdCampaigns }) => ({
    getProgram,
    getClients,
    searchAdCampaigns,
  }));

  useEffect(() => {
    if (slug) {
      dispatch(resetQueryCampaignsResults());
      dispatch(
        searchAdCampaigns({
          search_text: slug,
          add_meta_data: true,
        })
      );
    }
  }, [slug]);

  const googleAdCampaign: Record<string, any> =
    searchAdCampaignResult.items
      .filter((el: Record<string, any>) => el.campaign?.status)
      .find((el: Record<string, any>) => el.search_engine === "google") || {};

  const bingAdCampaign: Record<string, any> =
    searchAdCampaignResult.items
      .filter((el: Record<string, any>) => el.campaign?.status)
      .find((el: Record<string, any>) => el.search_engine === "bing") || {};

  return (
    <>
      {searchAdCampaignResult.items.length > 0 && (
        <div className="flex gap-4">
          {googleAdCampaign?.search_engine && (
            <span
              key={googleAdCampaign.id}
              className={twMerge(
                "bg-green-100 block text-green-800 text-xs p-1 font-medium  rounded-full dark:bg-green-900 dark:text-green-300 my-auto",
                googleAdCampaign.campaign?.status !== "Active" &&
                  "bg-yellow-300"
              )}
            >
              {capitalize(googleAdCampaign?.search_engine)} Search{" "}
              {googleAdCampaign.campaign?.status}
            </span>
          )}

          {bingAdCampaign?.search_engine && (
            <span
              key={bingAdCampaign.id}
              className={twMerge(
                "bg-green-100 block text-green-800 text-xs p-1 font-medium  rounded-full dark:bg-green-900 dark:text-green-300 my-auto",
                bingAdCampaign.campaign?.status !== "Active" && "bg-yellow-300"
              )}
            >
              {capitalize(bingAdCampaign?.search_engine)} Search{" "}
              {bingAdCampaign.campaign?.status}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default ProgramCampaignsLabel;
