import EmailMembers from "containers/marketing/email/EmailMembers";
import MarketingHome from "containers/marketing/email/EmailHome";
import SearchMarketingAdd from "containers/marketing/search/SearchMarketingAdd";
import EditLocationTargets from "containers/marketing/search/EditLocationTargets";
import CampaignDetail from "containers/marketing/search/CampaignDetail";
import SEMProgramTabs from "layout/marketing/SEMProgramTabs";
import SEMTabs from "layout/marketing/search/SEMTabs";
import CampaignSettingCreateUpdate from "containers/marketing/email/CampaignSettingCreateUpdate";
import EmailCampaign from "containers/marketing/email/EmailCampaign";
import EmailCampaigns from "containers/marketing/email/EmailCampaigns";
import CreateEmailCampaign from "containers/marketing/email/CreateEmailCampaign";
import BrowserExtension from "containers/marketing/extension/BrowserExtension";
import TodoItems from "containers/todos/TodoItems";
import ContentMarketingTabs from "layout/marketing/search/ContentMarketingTabs";

const routes = [
  {
    path: "marketing/email/",
    element: <MarketingHome />,
  },
  // {
  //   path: "marketing/email-subs-report",
  //   element: <EmailMembersReport />,
  // },
  {
    path: "marketing/email/members",
    element: <EmailMembers />,
  },
  {
    path: "marketing/email/campaign-settings/add",
    element: <CampaignSettingCreateUpdate />,
  },

  {
    path: "marketing/email/create-campaign",
    element: <CreateEmailCampaign />,
  },
  {
    path: "marketing/email/email-campaigns/:uuid",
    element: <EmailCampaign />,
  },
  {
    path: "marketing/email/email-campaigns",
    element: <EmailCampaigns />,
  },
  {
    path: "marketing/search",
    element: <SEMTabs />,
  },

  {
    path: "marketing/search/program",
    element: <SEMProgramTabs />,
  },

  {
    path: "marketing/search/add",
    element: <SearchMarketingAdd />,
  },

  {
    path: "marketing/search/ads/",
    element: <CampaignDetail />,
  },

  {
    path: "marketing/search/ad-location-targets/:id/edit",
    element: <EditLocationTargets />,
  },

  {
    path: "marketing/browser-extension",
    element: <BrowserExtension />,
  },

  {
    path: "marketing/content",
    element: <ContentMarketingTabs />,
  },

  {
    path: "/todo",
    element: <TodoItems />,
  },
];

export default routes;
