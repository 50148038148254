import getNewsletterFeed from "redux/slices/links/newsletter-feed/getStoreNewsletterFeed";
import completeNewsLetter from "redux/slices/links/newsletter-feed/completeNewsletter";
import getAffiliateEmailAccounts from "redux/slices/links/newsletter-feed/getAffiliateEmailAccounts";
import getAffiliateNewsletterFeed from "redux/slices/links/newsletter-feed/getAffiliateNewsletterFeed";
import completeAffiliateNewsLetter from "redux/slices/links/newsletter-feed/completeAffiliateNewsletter";
import generateCouponInfo from "redux/slices/links/newsletter-feed/generateCouponInfo";

const newsLetterFeedReducers = {
  getNewsletterFeed,
  getAffiliateNewsletterFeed,
  completeNewsLetter,
  completeAffiliateNewsLetter,
  getAffiliateEmailAccounts,
  generateCouponInfo,
};
export default newsLetterFeedReducers;
