import TopCategoryRow from "components/campaigns/TopCategoryRow";
import Button from "components/common/Button";
import DraggableRow from "components/common/DraggableRow";
import { Dispatch, SetStateAction } from "react";
import { TopProgramCategory } from "types/model/Category";
import { RowRenderer } from "components/common/layout/DataTable";
import Layout from "components/layout/Layout";

type Props = {
  loading: boolean;
  data: Array<TopProgramCategory>;
  navigateToClientCategory: (slug: string) => void;
  saving: boolean;
  handlePersistUpdates: () => void;
  handleRemoveCategory: (item: TopProgramCategory) => void;
  moveDraggableRow: (dragIndex: number, hoverIndex: number) => void;
  setSortedItems: Dispatch<SetStateAction<TopProgramCategory[]>>;
};

export default function TopCategoriesCampaigns({
  data,
  navigateToClientCategory,
  moveDraggableRow,
  handlePersistUpdates,
  saving,
  handleRemoveCategory,
}: Props) {
  const renderRow: RowRenderer<TopProgramCategory> = (row) => (
    <TopCategoryRow
      handleRemoveCategory={handleRemoveCategory}
      row={row}
      key={row.id}
      navigateToClientCategory={navigateToClientCategory}
    />
  );

  return (
    <>
      <div className="w-full flex items-end justify-end mt-4">
        <Button
          text="Save changes"
          loading={saving}
          className="bg-indigo-600 text-white "
          onClick={handlePersistUpdates}
        />
      </div>
      <div className="mt-8 flow-root">
        <div className=" overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Category Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Top Stores
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Sub categories
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    ></th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    ></th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((item: Record<string, any>, idx: number) => (
                    <DraggableRow
                      key={item.id}
                      index={idx}
                      id={item.id}
                      row={item}
                      rowClassName="border-1   border-gray-300"
                      renderRow={renderRow as RowRenderer<Record<string, any>>}
                      moveDraggableRow={moveDraggableRow}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
