import getFirstInt from "utils/strings/getFirstInt";

const getPriceItems = (
  prices: Array<Record<string, any>>,
  form: Record<string, any>,
  formPage: string,
  categoryPageLevel?: number
) => {
  const categoryLevelsMap: Record<string, string> = {
    "0": "Parent Category",
    "1": "Sub Category",
    "2": "Sub 2 Category",
  };

  if (!prices) {
    return [];
  }

  let currentPrices =
    prices
      ?.filter(
        (el) =>
          // String(el.client.id) === form.client &&
          el.placement_type === form.placement_type && el.page === formPage
      )
      .filter((it) => {
        if (form.page_type) {
          return it.page_type === form.page_type;
        }
        return true;
      }) || [];

  if (currentPrices.length === 0 && typeof categoryPageLevel === "number") {
    const page = categoryLevelsMap[String(categoryPageLevel)];

    currentPrices =
      prices?.filter(
        (el) =>
          // String(el.client.id) === form.client &&
          el.placement_type === form.placement_type && el.page === page
      ) || [];
  }

  const pItems = currentPrices.map((el) => {
    // const title = ` ${el.page} ${
    const title = `${
      !Object.values(categoryLevelsMap).includes(el.page)
        ? formPage === "Newsletters"
          ? el.page_type
          : formPage
        : ""
    } ${
      (Object.values(categoryLevelsMap).includes(el.page) && formPage) || ""
    } ${el.placement_type}`;

    const positions = Object.entries(el.positions).map(([k, v]) => {
      const availability = `${getFirstInt(String(k))}_${v}`;
      return { availability };
    });

    const combined: any = [];
    const allPosAmounts = positions.map((el) => el.availability);

    for (let index = 0; index < positions.length; index++) {
      const element = positions[index];

      const key = element.availability.split("_")[0];
      const price = element.availability.split("_")[1];

      if (!combined.map((el: any) => el.position).includes(key)) {
        const obj = {
          position: key,
          price: price,
          price_id: el.id + key,
          status: allPosAmounts
            .find((item) => /[a-zA-Z]/.test(item) && item.startsWith(key))
            ?.split("_")[1],
        };
        combined.push(obj);
      }
    }

    return { title, positions: combined };
  });

  if (formPage === "Custom") {
    return [];
  }

  return pItems;
};
export default getPriceItems;
