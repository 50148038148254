import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import getEvents from "redux/thunks/sales_crm/getEvents";
import ActivityFeed from "./ActivityFeed";
import { useParams } from "react-router-dom";
import useLoadAuth from "hooks/useLoadAuth";
import { LOCAL_KEY_USER_PENDING_FOLLOW_UPS } from "constants/storageKeys";

const Followups = () => {
  const { slug } = useParams();
  const { user } = useLoadAuth();
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentMeta, setCurrentMeta] = useState<Record<string, any>>({});
  const eventsData = useAppSelector((state) => state.getEvents);
  const [currentItems, setCurrentItems] = useState<Array<any>>(
    eventsData.data.items
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      getEvents({
        has_pending_followup: "1",
        user_id: user?.id || "",
      })
    )
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setCurrentItems(res.payload.items);
          setCurrentMeta(res.payload.meta);
          localStorage.setItem(
            LOCAL_KEY_USER_PENDING_FOLLOW_UPS,
            res.payload.meta.count
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug, userId]);

  const loadMoreEvents = () => {
    dispatch(
      getEvents({
        offset: currentMeta.offset + 50,
        has_pending_followup: "1",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setCurrentItems((curr) => [...curr, ...res.payload.items]);
        setCurrentMeta(res.payload.meta);
      }
    });
  };
  return (
    <ActivityFeed
      loading={loading}
      loadMoreEvents={loadMoreEvents}
      currentItems={currentItems}
      eventsData={eventsData}
      setUserId={setUserId}
    />
  );
};

export default Followups;
