import Button from "components/common/Button";
import { Card } from "flowbite-react";
import { GrAdd, GrSubtract } from "react-icons/gr";
import { ProgramCategory, TopProgramCategory } from "types/model/Category";

type Props = {
  topCategory: TopProgramCategory;
  removeSubFromCategory: (store: ProgramCategory) => void;
  addCategoryToSub: (store: ProgramCategory) => void;
  handlePersistUpdates: () => void;
  saving: boolean;
  subCategories: ProgramCategory[];
  featuredSubs: ProgramCategory[];
};

export default function FeaturedSubCategoryLayout({
  topCategory,
  addCategoryToSub,
  saving,
  handlePersistUpdates,
  subCategories,
  featuredSubs,
  removeSubFromCategory,
}: Props) {
  return (
    <div>
      <div className="flex justify-between items-center">
        <h1>Set subcategories for {topCategory.category.name}</h1>
        <Button
          text="Save changes"
          loading={saving}
          className="bg-indigo-600 text-white"
          onClick={handlePersistUpdates}
        />
      </div>

      <div className="">
        <div className="flex justify-between items-center mt-6 mb-3">
          <h2>Sub Categories</h2>
          <h2>Featured</h2>
        </div>

        <div className="h-[500px] overflow-scroll flex justify-between">
          <div>
            {subCategories.map((el) => (
              <Card key={el.id} title={el.name} className="my-2 ">
                <div className="flex justify-between items-center">
                  <h4>{el.name}</h4>
                  <button
                    onClick={() => {
                      addCategoryToSub(el);
                    }}
                  >
                    <GrAdd color="red" fontSize={18} />
                  </button>
                </div>
              </Card>
            ))}
          </div>

          <div>
            {featuredSubs.map((el) => (
              <Card key={el.id} title={el.name} className="my-2">
                <div className="flex justify-between items-center">
                  <h4>{el.name}</h4>
                  <button
                    className="font-extrabold text-red-600"
                    onClick={() => {
                      removeSubFromCategory(el);
                    }}
                  >
                    {" "}
                    <GrSubtract color="red" fontSize={18} />
                  </button>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
