import Label from "components/common/Label";
import Layout from "components/layout/Layout";
import { ActionState } from "types/data";
import {
  EmailCampaign,
  EmailCampaignStatus,
} from "types/model/NewsletterSubscriber";
import EmailCampaignStats from "./EmailCampaignStats";
import DataTable from "components/common/layout/DataTable";
import formatDate from "utils/dates/formatDate";
import Button from "components/common/Button";
import { Link } from "react-router-dom";
import SimpleCouponRow from "./SimpleCouponRow";
import { twMerge } from "tailwind-merge";
import { Dispatch, SetStateAction } from "react";
import Modal from "components/common/Modal";
import { SIMPLE_COUPON_COLUMNS } from "constants/tables/headers/oma/coupons";

type Props = {
  getEmailCampaignData: ActionState<EmailCampaign | null>;
  sendingPreview: boolean;
  sendPreview: () => void;
  sendingNow: boolean;
  sendNow: () => void;
  handlePublishUnpublish: (status: string) => void;
  createEmailCampaignData: ActionState;
  sendConfirmOpen: boolean;
  setSendConfirmOpen: Dispatch<SetStateAction<boolean>>;
};

const EmailCampaignComponent = ({
  getEmailCampaignData: { data },
  sendingPreview,
  sendPreview,
  sendingNow,
  sendNow,
  handlePublishUnpublish,
  createEmailCampaignData,
  sendConfirmOpen,
  setSendConfirmOpen,
}: Props) => {
  if (data?.campaign_type === "Generic") {
    return (
      <Layout>
        <Modal
          variant="sm"
          onConfirm={sendNow}
          open={sendConfirmOpen}
          loading={sendingNow}
          setOpen={setSendConfirmOpen}
          doneText="Proceed"
        >
          <p className="text-sm">
            Are you you want to send this compaign out to all{" "}
            {data.location?.toUpperCase()} subscribers?
          </p>
        </Modal>
        <div className="flex justify-between p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 items-center mb-4">
          <div>
            <div className="my-2">
              <p className="text-sm font-medium text-gray-500">
                {" "}
                {data?.client?.name}
              </p>

              <p className="text-sm font-medium text-gray-500">
                {" "}
                {data.location?.toUpperCase()}
              </p>
              <p className="text-sm font-medium text-gray-500">
                {data?.campaign_type}
              </p>

              {data?.date && (
                <small className="block">{formatDate(data?.date)}</small>
              )}
            </div>
          </div>

          <div className="flex gap-4 items-center">
            {data.status == EmailCampaignStatus.DRAFT && (
              <button className="text-sm text-gray-700" onClick={sendPreview}>
                {sendingPreview ? "Please wait..." : "Send Preview Email"}
              </button>
            )}

            {data.status != EmailCampaignStatus.EXECUTED && (
              <div>
                {!data.send_status ? (
                  <button
                    className="text-sm text-gray-700"
                    onClick={() => {
                      setSendConfirmOpen(true);
                    }}
                  >
                    {sendingNow ? "Please wait..." : "Send Now"}
                  </button>
                ) : (
                  <p className="text-sm lowercase">Send:{data.send_status}</p>
                )}
              </div>
            )}
            <Button
              loading={createEmailCampaignData.loading}
              className={twMerge(
                data.status == EmailCampaignStatus.PUBLISHED && "bg-gray-400",
                data.status == EmailCampaignStatus.EXECUTED &&
                  "bg-green-700 hover:bg-green-500"
              )}
              disabled={
                EmailCampaignStatus.EXECUTED == data.status ||
                createEmailCampaignData.loading
              }
              text={
                data.status == EmailCampaignStatus.PUBLISHED
                  ? "Unpublish"
                  : data.status == EmailCampaignStatus.EXECUTED
                  ? "Executed"
                  : "Publish"
              }
              onClick={() => {
                if (data.status == EmailCampaignStatus.DRAFT) {
                  handlePublishUnpublish(EmailCampaignStatus.PUBLISHED);
                }
                if (data.status == EmailCampaignStatus.PUBLISHED) {
                  handlePublishUnpublish(EmailCampaignStatus.DRAFT);
                }
              }}
            />
            {data.status != EmailCampaignStatus.EXECUTED && (
              <Link to={`/marketing/email/create-campaign?id=${data?.uuid}`}>
                Edit
              </Link>
            )}
          </div>
        </div>
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <div className="my-2">
            <Label label="Email subject" />
            <small>{data?.email_subject}</small>
          </div>

          <div className="my-2">
            <Label label="Email preview text" />
            <small>{data?.email_preview_text}</small>
          </div>

          <div className="my-2">
            <Label label="Featured coupon" />

            <DataTable
              rows={[data.coupon_info]}
              rowRenderer={(coupon) => (
                <SimpleCouponRow
                  coupon={{
                    ...coupon,
                    click_count: data.coupon_stats?.[coupon.id] || 0,
                  }}
                />
              )}
              columns={SIMPLE_COUPON_COLUMNS}
            />
          </div>

          <div className="my-2">
            <Label label="Headline" />
            <small>{data?.custom_coupon_headline}</small>
          </div>

          <div className="my-2">
            <Label label="Description" />
            <small>{data?.custom_coupon_description}</small>
          </div>

          <div className="my-2">
            <Label label="Coupons" />

            <DataTable
              rows={data.coupons}
              rowRenderer={(coupon) => (
                <SimpleCouponRow
                  coupon={{
                    ...coupon,
                    click_count: data.coupon_stats?.[coupon.id] || 0,
                  }}
                />
              )}
              columns={SIMPLE_COUPON_COLUMNS}
            />
          </div>
          <div className="p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 items-center mb-4">
            <EmailCampaignStats
              receiver_count={data?.receiver_count}
              stats={data?.stats}
            />
          </div>
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
        <div className="my-2">
          <Label label="Email Campaign ID" />
          <small>{data?.uuid}</small>
        </div>
        <div className="my-2">
          <Label label="Site" />
          <small> {data?.client?.name}</small>
        </div>
        <div className="my-2">
          <Label label="Campaign Type" />
          <small>{data?.campaign_type}</small>
        </div>
        <div className="my-2">
          <Label label="Starter Line" />
          <small>{data?.starter_line}</small>
        </div>
        <div className="my-2">
          <Label label="Store Name" />
          <small>{data?.program_info?.name}</small>
        </div>
        <div className="my-2">
          <Label label="Coupon ID" />
          <small>{data?.coupon_id}</small>
        </div>

        <div className="my-2">
          <Label label="Coupon Headline" />
          <small>{data?.coupon_info.title}</small>
        </div>

        <div className="my-2">
          <Label label="Coupon Description" />
          <small>{data?.coupon_info.description}</small>
        </div>

        <div className="my-2">
          <Label label="Coupon Has Code" />
          <small>{data?.coupon_info.discount_code ? "YES" : "NO"}</small>
        </div>

        <EmailCampaignStats
          receiver_count={data?.receiver_count}
          stats={data?.stats}
        />
      </div>
    </Layout>
  );
};

export default EmailCampaignComponent;
