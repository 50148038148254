import { ChangeEventHandler } from "react";
import { twMerge } from "tailwind-merge";
import Spinner from "./layout/Spinner";

type Props = {
  label?: string;
  value?: string | number;
  placeholder?: string;
  className?: string;
  wrapperClassName?: string;
  loading?: boolean;
  options: {
    label: string;
    value: string | number;
  }[];

  onChange?: ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  name?: string;
  defaultLabel?: boolean;
  error?: string;
  required?: boolean;
};

function Dropdown({
  value,
  placeholder,
  label,
  name,
  className,
  loading,
  onChange,
  options,
  defaultLabel = true,
  error,
  wrapperClassName,
  required,
}: Props) {
  const selectClassName = twMerge(
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500",
    className,
    error && "border-2 border-red-700 "
  );
  return (
    <div className={wrapperClassName}>
      {loading && (
        <div className="relative">
          <Spinner className="h-4 w-4 absolute right-1 top-3" />
        </div>
      )}
      {label && (
        <label
          htmlFor="user-role"
          className="inline-flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
          <button
            type="button"
            data-tooltip-target="tooltip-user-role"
            data-tooltip-style="dark"
            className="ml-1"
          >
            <span className="sr-only">User role details</span>
          </button>
        </label>
      )}

      <select
        onChange={onChange}
        name={name}
        value={value || undefined}
        placeholder={placeholder}
        className={selectClassName}
        required={required}
      >
        {/* {[
          ...(!value && defaultLabel
            ? [{ label: placeholder || "Select", value: "" }]
            : []),
          ...options,
        ] */}
        {[...[{ label: placeholder || "Select", value: "" }], ...options]
          .filter((it) => it.label)
          .map(({ value: optionValue, label }, idx) => (
            <option value={optionValue} key={`${value || ""}-${idx}`}>
              {label}
            </option>
          ))}
      </select>

      {error && <small className="text-xs  text-red-700">{error}</small>}
    </div>
  );
}

export default Dropdown;
