type Props = {
  item: Record<string, any>;
};

const RenderCommissionRates = ({ item }: Props) => {
  if (!item.commission_config) {
    return null;
  }
  const finalCommissions: Array<Record<string, any>> =
    item.commission_config.filter((el: any) => el);

  if (!finalCommissions.length) {
    return <p>No data</p>;
  }
  return (
    <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
      {finalCommissions?.map((item) => (
        <li
          className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
          key={item.name}
        >
          <div className="flex w-0 flex-1 items-center">
            {/*<PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />*/}
            <span className="ml-2 w-0 flex-1 truncate">
              {item.subname?.replace(/[^\w\s]/gi, " ")}{" "}
              {item.name?.replace(/[^\w\s]/gi, " ")}
            </span>
            {item.flat && (
              <span className="ml-2 w-0 flex-1 truncate">
                {item.currency} {item.flat}
              </span>
            )}
            {item.percentage && (
              <span className="ml-2 w-0 flex-1 truncate">
                {item.percentage?.replace?.("%", "")}%
              </span>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default RenderCommissionRates;
