import TabLayout from "./common/TabLayout";
import { useNavigate } from "react-router-dom";
import CRMCreateButton from "layout/sales_crm/components/CRMCreateButton";
import Leads from "containers/sales/crm/Leads";
import Feed from "containers/sales/crm/Feed";
import Dashboard from "containers/sales/crm/Dashboard";
import Pipeline from "containers/sales/crm/Pipeline";
import useLoadAuth from "hooks/useLoadAuth";
import ContactTabs from "layout/sales_crm/ContactTabs";
import Message from "./common/Message";
import Layout from "./layout/Layout";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import RequestedLeads from "containers/sales/crm/RequestedLeads";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import getOwnerShipRequests from "redux/thunks/sales_crm/getOwnerShipRequests";
import FeedSubTabs from "layout/sales_crm/FeedSubTabs";

type Props = {
  globalLayout?: boolean;
  hideContent?: boolean;
  variant?: "simple" | "default";
};

export default function SalesCRMTabs({
  globalLayout = true,
  hideContent = false,
  variant = "default",
}: Props) {
  const { user } = useLoadAuth();
  const dispatch = useAppDispatch();
  const leads = useAppSelector((state) => state.getOwnerShipRequests);
  const isAdmin = user?.user_role === USER_ROLE_ADMIN;
  const hasSalesCRMAccess =
    isAdmin || !!user?.user_sub_apps?.find((it) => it.name === "Sales CRM");

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getOwnerShipRequests({
        status: "pending",
      })
    );
  }, []);

  const tabs = [
    {
      text: "Home",
      onClick: () => navigate("/sales/crm/?tab=1&tab-id=home"),
      url: "/sales/crm/?tab=1&tab-id=home",
      content: <Dashboard />,
    },
    {
      text: "Activity Feed",
      onClick: () => navigate("/sales/crm/?tab=2&tab-id=feed"),
      url: "/sales/crm/?tab=2&tab-id=feed",

      content: <FeedSubTabs />,
    },
    {
      text: "Deal Pipeline",
      onClick: () => navigate("/sales/crm/?tab=3&tab-id=accounts"),
      url: "/sales/crm/?tab=3&tab-id=accounts",
      content: <Pipeline />,
    },

    {
      text: "New Leads",
      onClick: () => navigate("/sales/crm/?tab=4&tab-id=leads"),
      url: "/sales/crm/?tab=4&tab-id=leads",
      content: <Leads />,
    },

    {
      text: "Requested Leads",
      onClick: () => navigate("/sales/crm/?tab=5&tab-id=requested-leads"),
      url: "/sales/crm/?tab=5&tab-id=requested-leads",
      content: <RequestedLeads />,
      label: leads.data.length,
    },

    {
      text: "Contacts",
      onClick: () => navigate("/sales/crm/?tab=6&tab-id=contacts"),
      url: "/sales/crm/?tab=6&tab-id=contacts",
      content: <ContactTabs />,
    },
  ];

  if (!hasSalesCRMAccess) {
    return (
      <Layout>
        <Message warn description="Not authorized" />
      </Layout>
    );
  }

  return (
    <TabLayout
      variant={variant}
      globalLayout={globalLayout}
      hideContent={hideContent}
      options={tabs
        .filter((t) => {
          if (!isAdmin && t.text === "Requested Leads") {
            return false;
          }
          return true;
        })
        .map((tab, idx) => {
          const updatedPath = tab.url.replace(/tab=\d+/, `tab=${idx + 1}`);
          const handle = () => navigate(updatedPath);
          return {
            ...tab,
            url: updatedPath,
            onClick: handle,
          };
        })}
      rightElement={
        <div className="w-full md:w-auto flex flex-col md:flex-row mb-3 mr-4 md:mb-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
          <CRMCreateButton />
        </div>
      }
    />
  );
}
