import UsersCreate from "containers/users/UsersCreate";
import UsersComponent from "containers/users/Users";
import NetworksComponent from "containers/networks/Networks";
import NetworksbyclientComponent from "containers/networks/Networksbyclient";
import AddComponent from "containers/networks/AddNewNetwork";
import NetworkDetails from "containers/networks/NetworkDetails";
import MyAccount from "containers/users/MyAccount";
import User from "containers/users/User";

const settingsRoutes = [
  {
    path: "/settings/users/create",
    element: <UsersCreate />,
  },

  {
    path: "/settings/users/",
    element: <UsersComponent />,
  },
  {
    path: "/settings/users/:id",
    element: <User />,
  },

  {
    path: "/settings/users/account",
    element: <MyAccount />,
  },
  {
    path: "/settings/networks",
    element: <NetworksComponent />,
  },

  {
    path: "/settings/networks/add",
    element: <AddComponent />,
  },
  {
    path: "/settings/networks/:id",
    element: <NetworkDetails />,
  },
  {
    path: "/settings/networks/clients",
    element: <NetworksbyclientComponent />,
  },
];

export default settingsRoutes;
