import Layout from "components/layout/Layout";
import { currencyNameSymbolMap } from "constants/storeCurrencies";
import ProgramHeader from "containers/bma/ProgramHeader";
import dateformat from "dateformat";
import { GrDocumentPdf } from "react-icons/gr";
import { Link } from "react-router-dom";
import { PaginatedActionState } from "types/data";
import { IOPayment } from "types/model/sales/IOPayment";
import { Proposal } from "types/model/sales/Proposal";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  getPaymentsData: PaginatedActionState<IOPayment>;
  proposal: Proposal | null;
};

const PaymentsLayout = ({ getPaymentsData, proposal }: Props) => {
  return (
    <Layout loading={getPaymentsData.loading}>
      <ProgramHeader />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-screen">
            <div className=" ">
              <div className="px-2 py-2 text-base font-medium text-primary-600 ">
                <Link
                  to={`/sales/ios/payments/add?id=${proposal?.id}&client_id=${
                    proposal?.client_merchant_config[0].client
                  }&slug=${
                    proposal?.client_merchant_config[0].program_slug || ""
                  }`}
                >
                  Add payment
                </Link>
              </div>{" "}
            </div>
            {getPaymentsData.data.meta.count > 0 && (
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Date
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Amount
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Type
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    ></th>
                  </tr>
                </thead>

                <tbody>
                  {getPaymentsData.data.items.map((element) => (
                    <tr key={element.id}>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        {dateformat(element.date, "mm/dd/yyyy")}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        {currencyNameSymbolMap[element.currency || ""] ||
                          element.proposal.currency_symbol}
                        {formatNumber(element.amount, { decimals: 2 })}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        {element.payment_type}
                      </td>

                      <td className="px-2 py-2 text-xs font-medium text-primary-600 ">
                        {element.attachment_url ? (
                          <Link
                            to={element.attachment_url || ""}
                            target="_blank"
                          >
                            <GrDocumentPdf />
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="px-2 py-2 text-xs font-medium text-primary-600 ">
                        <Link
                          to={`/sales/ios/payments/add?id=${element.id}&slug=${
                            element.proposal.program_slug || ""
                          }&proposal_id=${element.proposal.id}`}
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentsLayout;
