import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";
import { useNavigate, useSearchParams } from "react-router-dom";
import ActiveCouponsLayout from "layout/oma/ActiveCoupons";
import exportCSVResource from "redux/thunks/app/api-clients/exportCSVResource";
import { resetExportResource } from "redux/slices/clients/export-resource";
import getUsers from "redux/thunks/users/getUsers";
import { User } from "types/model/User";
import dayjs from "dayjs";
import { Option } from "types/option";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";
import getCouponOfferTypes from "redux/thunks/app/api-clients/getCouponOfferTypes";

type Props = {
  autoCoupons?: boolean;
};

const ActiveCoupons = ({ autoCoupons }: Props) => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [networkOptions, setNetworkOptions] = useState<Option[]>([]);
  const form = {
    startDate: dayjs().toDate(),
    endDate: dayjs().toDate(),
  };

  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState<Record<string, any>[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const {
    getProgram: getProgramData,
    getActiveCoupons: { loading, data },
    getAllUsers: { data: getAllUserData },
    exportResource,
    getCouponOfferTypes: getCouponOfferTypesData,
    affiliateNetworks,
  } = useAppSelector(
    ({
      getProgram,
      getActiveCoupons,
      affiliateNetworks,
      exportResource,
      getAllUsers,
      getCouponOfferTypes,
    }) => ({
      getProgram,
      getActiveCoupons,
      getAllUsers,
      affiliateNetworks,
      exportResource,
      getCouponOfferTypes,
    })
  );

  const page = params.has("page") ? Number(params.get("page")) : 1;
  const search_text = params.get("search_text") || "";
  const location = params.get("location") || "";
  const rank = params.get("rank") || "";
  const network = params.get("network") || "";
  const uploader = params.get("uploader") || "";
  const offer_type = params.get("offer_type") || "";
  const has_code = params.get("has_code") || "";
  const startDate = dayjs(params.get("start_date") || form.startDate).format(
    "YYYY-MM-DD"
  );
  const endDate = dayjs(params.get("end_date") || form.endDate).format(
    "YYYY-MM-DD"
  );

  const couponFilters = {
    filter: "all",
    auto_coupons: autoCoupons ? "1" : "0",
    page: search_text ? 1 : page,
    size: 50,
    search_text: search_text,
    rank: rank,
    location: location,
    network: network,
    allCoupons: true,
    start_date: startDate,
    end_date: endDate,
    uploader: uploader,
    offer_type: offer_type,
    has_code,
  };

  useEffect(() => {
    dispatch(getCouponOfferTypes({}));
  }, []);

  useEffect(() => {
    dispatch(getActiveCoupons(couponFilters));
  }, [getProgramData.data.slug, params, search_text]);

  useEffect(() => {
    if (allSelected) {
      setSelectedItems(data.results);
    } else {
      setSelectedItems([]);
    }
  }, [allSelected]);

  useEffect(() => {
    if (affiliateNetworks.data.items.length) {
      const allAffiliateNetworks = affiliateNetworks.data.items.map((item) => ({
        label: item.name,
        value: item.network_id,
        selected: network === item.network_id,
      }));
      setNetworkOptions(allAffiliateNetworks);
    } else {
      dispatch(getAffiliateNetworks({ page: 1 }));
    }
  }, [affiliateNetworks.data.items.length]);

  useEffect(() => {
    if (getAllUserData.meta.count > 0) {
      setUserOptions(getAllUserData.items);
    } else {
      dispatch(getUsers({ page: 1 }));
    }
  }, [getAllUserData.meta.count]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value = "" },
  }) => {
    if (name === "dates") {
      const url = new URL(window.location.href);
      url.searchParams.set(
        "start_date",
        dayjs(JSON.parse(value)[0]).format("YYYY-MM-DD")
      );
      url.searchParams.set(
        "end_date",
        dayjs(JSON.parse(value)[1]).format("YYYY-MM-DD")
      );

      url.searchParams.set("page", "1");

      navigate(url.search);

      return;
    } else {
      const url = new URL(window.location.href);

      url.searchParams.set(name, value);
      url.searchParams.set("page", "1");

      navigate(url.search);
    }
  };

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);

    url.searchParams.set("page", String(page));

    navigate(url.search);
  };

  const onSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const url = new URL(window.location.href);
    url.searchParams.set("search_text", searchText);
    url.searchParams.set("page", "1");
    navigate(url.search);
    setSelectedItems([]);
  };
  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
  };

  const onItemSelectChange = (coupon: Record<string, any>) => {
    const isChecked = selectedItems.map((el) => el.id).includes(coupon.id);

    if (isChecked) {
      setSelectedItems((all) => all.filter((it) => it.id !== coupon.id));
    } else {
      setSelectedItems((all) => [coupon, ...all]);
    }
  };

  const handleSelectAll = () => {
    const allChecked = selectedItems.length === data.count || allSelected;

    if (allChecked) {
      setSelectedItems([]);
      setAllSelected(false);
    } else {
      setSelectedItems((curr) => [...curr, ...data.results]);
    }
  };

  const onShowAll = () => {
    setSelectedItems([]);
    dispatch(
      getActiveCoupons({
        filter: "all",
        page: 1,
        size: data.count,
      })
    );
  };

  const onExportAll = () => {
    dispatch(
      exportCSVResource({
        resource: "offers",
        start_date: startDate,
        end_date: endDate,
        uploader: uploader,
        fields: [],
      })
    );
  };

  useEffect(() => {
    if (!!exportResource.data) {
      dispatch(resetExportResource());
      setAllSelected(false);
    }
  }, [exportResource]);

  return (
    <ActiveCouponsLayout
      page={page}
      onPageChange={onPageChange}
      loading={loading}
      data={data}
      getCouponOfferTypesData={getCouponOfferTypesData}
      onSearchChange={onSearchChange}
      onSearchSubmit={onSearchSubmit}
      onShowAll={onShowAll}
      onItemSelectChange={onItemSelectChange}
      handleSelectAll={handleSelectAll}
      selectedItems={selectedItems}
      allSelected={allSelected}
      setAllSelected={setAllSelected}
      exportResourceData={exportResource}
      onExportAll={onExportAll}
      onChange={onChange}
      userOptions={userOptions}
      form={{
        startDate: dayjs(params.get("start_date") || form.startDate),
        endDate: dayjs(params.get("end_date") || form.endDate),
      }}
      networkOptions={networkOptions}
      couponFilters={couponFilters}
      isAutoCoupons={autoCoupons || false}
    />
  );
};

export default ActiveCoupons;
