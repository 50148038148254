import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import axios from "helpers/axios";
import { Advertiser } from "types/model/Advertiser";
import getOffsetForPage from "utils/arrays/getOffset";

const isNotRecentlyActivated = (adv: Record<string, any>) => {
  const recently_activated =
    localStorage.getItem("recently_activated") || JSON.stringify([]);

  return !JSON.parse(recently_activated)
    .map((item: Record<string, any>) => item.network_mid)
    .includes(adv.external_id);
};

type Params = {
  page: number;
  network_id?: string;
  network_status?: string;
  search_text?: string;
  search_intent?: string | "mapping" | "pending-programs";
  name?: string;
  website?: string;
  client?: null | string;
  suggest_for_name?: string;
  suggest_for_website?: string;
  ignoreForClient?: boolean;
  has_open_ppc_policy?: string;
  skipCache?: boolean;
  include_mids?: Array<string>;
};

export default createAsyncThunk(
  "advertisers/network-advertisers",
  async (
    {
      page,
      network_id,
      network_status,
      search_text,
      name,
      website,
      suggest_for_website,
      suggest_for_name,
      ignoreForClient,
      skipCache,
      search_intent,
      include_mids,
      has_open_ppc_policy,
    }: Params,
    ThunkAPI
  ) => {
    const url = new URL(
      `${BACKEND_BASE_URL}/admin/advertisers/network-advertisers/`
    );
    const client_id = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID);

    if (client_id && !ignoreForClient) {
      url.searchParams.set("client_id", client_id);
    }
    url.searchParams.set("offset", String(getOffsetForPage(Number(page))));
    if (network_id) {
      url.searchParams.set("network_id", network_id);
    }
    if (network_status) {
      url.searchParams.set("network_status", network_status);
    }

    if (has_open_ppc_policy) {
      url.searchParams.set("has_open_ppc_policy", has_open_ppc_policy);
    }
    if (search_text) {
      url.searchParams.set("search_text", search_text);
    }

    if (search_intent) {
      url.searchParams.set("search_intent", search_intent);
    }
    if (name) {
      url.searchParams.set("name", name);
    }
    if (website) {
      url.searchParams.set("website", website);
    }

    if (suggest_for_name && client_id) {
      url.searchParams.set("ignored_client_id", client_id);
      url.searchParams.set("suggest_for_name", suggest_for_name);
    }

    if (suggest_for_website && client_id) {
      url.searchParams.set("ignored_client_id", client_id);
      url.searchParams.set("suggest_for_website", suggest_for_website);
    }
    if (include_mids?.length) {
      url.searchParams.set("included_mids", include_mids?.join(","));
    }

    try {
      const response = await axios.get(
        url.pathname
          .replace("/api", "")
          .replace("/production", "")
          .replace("/dev", "") + url.search,

        {
          headers: skipCache
            ? {
                "cache-control": "no-cache",
              }
            : {},
        }
      );

      if (suggest_for_name?.length) {
        const sortedByName = {
          ...response.data,
          items: response.data.items.sort((a: Advertiser) => {
            if (
              a.name
                .toLowerCase()
                .startsWith(suggest_for_name?.toLowerCase()?.substr(0, 3))
            ) {
              return -1;
            } else {
              return 1;
            }
          }),
        };

        return sortedByName;
      }
      const originalData = {
        ...response.data,
        items: response.data.items.filter((el: Record<string, any>) =>
          isNotRecentlyActivated(el)
        ),
      };

      return originalData;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
