import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";

export default createAsyncThunk(
  "sem/get-campaign-reports",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const reportUrl = new URL(
        `${BACKEND_BASE_URL}/admin/sem/perf-txn-report/`
      );
      const reportTotalsUrl = new URL(
        `${BACKEND_BASE_URL}/admin/sem/perf-txn-report-totals/`
      );

      Object.entries(payload).forEach(([k, v]) => {
        reportUrl.searchParams.set(k, v);
        reportTotalsUrl.searchParams.set(k, v);
      });

      reportUrl.searchParams.set(
        "offset",
        String(getOffsetForPage(Number(payload.page || 1)))
      );

      const promise1 = axios.get(reportTotalsUrl.href);
      const promise2 = axios.get(reportUrl.href);

      const response = await Promise.all([promise2, promise1]);

      return [response[0].data, (await promise1).data];
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
