import dateFormat from "dateformat";
import dayjs from "dayjs";

const formatDate = (date_str: string, format = "mm/dd/yyyy") => {
  if (!date_str) {
    return "";
  }
  const formatted = dateFormat(dayjs(date_str).toDate(), format);

  return formatted;
};

export const formatDateTime = (
  date_str: string,
  format = "yyyy, mmmm dS, dddd, h:MM:ss TT"
) => {
  if (!date_str) {
    return "";
  }
  const formatted = dateFormat(dayjs(date_str).toDate(), format);

  return formatted;
};
export default formatDate;
