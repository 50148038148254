import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EmailCampaignComponent from "layout/marketing/email/EmailCampaign";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import createEmailCampaign from "redux/thunks/marketing/emails/createEmailCampaign";
import getEmailCampaign from "redux/thunks/marketing/emails/getEmailCampaign";
import sendEmailCampaign from "redux/thunks/marketing/emails/sendEmailCampaign";

function EmailCampaign() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [sendConfirmOpen, setSendConfirmOpen] = useState(false);

  const { uuid } = useParams();

  const {
    getEmailCampaign: getEmailCampaignData,
    createEmailCampaign: createEmailCampaignData,
    sendEmailCampaign: { loading: sendEmailCampaignLoading },
  } = useAppSelector(
    ({ getEmailCampaign, createEmailCampaign, sendEmailCampaign }) => ({
      getEmailCampaign,
      createEmailCampaign,
      sendEmailCampaign,
    })
  );

  useEffect(() => {
    if (uuid) {
      dispatch(getEmailCampaign(uuid));
    }
  }, [searchParams]);

  const sendPreview = async () => {
    dispatch(
      sendEmailCampaign({
        campaign_id: uuid,
        preview: true,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Email Sent");
      } else {
        toast.error("An error occurred");
      }
    });
  };

  const sendNow = async () => {
    dispatch(
      sendEmailCampaign({
        campaign_id: uuid,
      })
    )
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Send has been initiated successfully");
        } else {
          toast.error("An error occurred");
        }
      })
      .finally(() => {
        setSendConfirmOpen(false);
      });
  };

  const handlePublishUnpublish = (status: string) => {
    dispatch(
      createEmailCampaign({
        ...getEmailCampaignData.data,
        status,
        client: getEmailCampaignData.data?.client.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (getEmailCampaignData.data?.id) {
          toast.success("Email campaign updated successfully");
        }
      }
    });
  };

  return (
    <EmailCampaignComponent
      getEmailCampaignData={getEmailCampaignData}
      sendingPreview={sendEmailCampaignLoading}
      sendPreview={sendPreview}
      sendingNow={sendEmailCampaignLoading}
      sendNow={sendNow}
      handlePublishUnpublish={handlePublishUnpublish}
      createEmailCampaignData={createEmailCampaignData}
      sendConfirmOpen={sendConfirmOpen}
      setSendConfirmOpen={setSendConfirmOpen}
    />
  );
}

export default EmailCampaign;
