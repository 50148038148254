import { timeAgo } from "index";
import React from "react";
import CommissionDisplay from "./CommissionDisplay";

type Props = {
  item: Record<string, any>;
  onClick: () => void;
};

function CommissionRateChangeRow({ item, onClick }: Props) {
  return (
    <React.Fragment>
      <tr
        className="border-b cursor-pointer  hover:bg-gray-100"
        onClick={onClick}
      >
        <th
          scope="row"
          className="px-2 py-2 text-xs font-medium text-gray-900 "
        >
          {item.advertiser?.name}
        </th>

        <th
          scope="row"
          className="px-2 py-2 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.advertiser?.network}
        </th>

        <td className="px-4 py-2 whitespace-nowrap text-xs">
          <CommissionDisplay
            firstCommissionConfig={item.changes[0].commission_config[0]}
          />
        </td>

        <td className="px-4 py-2 whitespace-nowrap text-xs">
          <CommissionDisplay
            firstCommissionConfig={item.changes[1].commission_config[0]}
          />
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {/* {timeAgo.format(new Date(item.created_at))} */}
          {timeAgo.format(new Date(item.changes[0].created_at))}
        </td>
      </tr>
    </React.Fragment>
  );
}

export default CommissionRateChangeRow;
