import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import Modal from "components/common/Modal";
import TextArea from "components/common/TextArea";
import Layout from "components/layout/Layout";
import { emailCampaignTypes } from "constants/emailCampaignTypes";
import countries from "constants/operationCountries";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useState,
} from "react";
import { ActionState, PaginatedActionState } from "types/data";
import { Client } from "types/model/Client";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  actionState: ActionState;
  getClientsData: PaginatedActionState<Client>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  form: Record<string, any>;
  handleDeleteSetting: () => void;
};

const CampaignSettingCreateUpdateComponent = ({
  onChange,
  onSubmit,
  form,
  handleDeleteSetting,
  actionState: { loading },
  getClientsData: {
    data: { items: clientItems },
    loading: clientsLoading,
  },
}: Props) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  return (
    <Layout>
      <div className="relative p-4 w-full max-w-3xl h-full md:h-auto">
        <Modal
          open={deleteConfirmOpen}
          setOpen={setDeleteConfirmOpen}
          doneText="Proceed"
          onConfirm={handleDeleteSetting}
          variant="sm"
          doneBtnClass="bg-red-400 hover:bg-red-700"
          loading={loading}
        >
          <h1>Are you sure you want to delete this campaign setting</h1>
        </Modal>
        <form
          onSubmit={onSubmit}
          className="relative bg-white rounded-lg shadow dark:bg-gray-800"
        >
          {/*Modal Header*/}
          <div className="flex justify-between items-center py-4 px-4 rounded-t sm:px-5">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {form.id ? "Edit Campaign Setting" : "Create Campaign Setting"}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="updateUserModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div id="accordion-collapse" data-accordion="collapse">
            <div
              id="accordion-collapse-body-1"
              className=""
              aria-labelledby="accordion-collapse-heading-1"
            >
              <div className="p-4 border-gray-200 sm:p-5 dark:border-gray-700">
                {/*Inputs*/}
                <div className="grid gap-4 sm:grid-cols-1 ">
                  <Dropdown
                    label="Site"
                    name="client"
                    onChange={onChange}
                    value={form.client}
                    options={clientItems.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                    loading={clientsLoading}
                  />

                  <Dropdown
                    label="Location"
                    name="store_location"
                    onChange={onChange}
                    value={form.store_location}
                    options={countries.map((el) => ({
                      label: el,
                      value: el.toLowerCase(),
                    }))}
                  />

                  <TextArea
                    name="starter_line"
                    onChange={(e) => {
                      onChange({
                        target: {
                          name: "starter_line",
                          value: e.target.value,
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                    id="starter_line"
                    label="Starter Line"
                    placeholder=""
                    value={form.starter_line}
                    required
                  />

                  <Dropdown
                    options={emailCampaignTypes.map((el: string) => ({
                      label: el + " Newsletter",
                      value: el,
                    }))}
                    label="Template Type"
                    name="template_type"
                    value={form.template_type}
                    onChange={onChange}
                    required
                  />
                  <Input
                    type="date"
                    name="from_date"
                    label="From Date"
                    id="from_date"
                    value={form.from_date}
                    required
                    onChange={onChange}
                  />

                  <Input
                    type="date"
                    name="to_date"
                    label="To Date"
                    id="to_date"
                    value={form.to_date}
                    required
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center py-4 px-4 space-x-4 sm:py-5 sm:px-5">
            <button
              type="submit"
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {loading ? "Please wait..." : form.id ? "Save Changes" : "Submit"}
            </button>
            {form.id && (
              <button
                onClick={() => {
                  setDeleteConfirmOpen(true);
                }}
                type="button"
                className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                <svg
                  className="mr-1 -ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Delete
              </button>
            )}
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CampaignSettingCreateUpdateComponent;
