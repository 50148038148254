import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import AddPaymentLayout from "layout/sales/AddPaymentLayout";
import { ChangeEventHandler, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { resetGetPayment } from "redux/slices/sales/getPayment";
import getCurrencies from "redux/thunks/app/getCurrencies";
import createUpdateDeletePayment from "redux/thunks/sales/createUpdateDeletePayment";
import getPayment from "redux/thunks/sales/getPayment";

const AddPayment = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const {
    createUpdateDeletePayment: createData,
    getPayment: getPaymentData,
    getCurrencies: getCurrenciesData,
  } = useAppSelector(
    ({ createUpdateDeletePayment, getPayment, getCurrencies }) => ({
      createUpdateDeletePayment,
      getPayment,
      getCurrencies,
    })
  );
  const [form, setForm] = useState<Record<string, any>>({});

  const handleFormSubmit = () => {
    dispatch(
      createUpdateDeletePayment({ ...form, proposal_id: params.get("id") })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(form.id ? "Changes saved" : "Payment saved");
      }
    });
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (params.has("id")) {
      dispatch(getPayment(params.get("id") || ""));
    } else {
      dispatch(resetGetPayment());
    }
  }, []);

  useEffect(() => {
    dispatch(getCurrencies({ page: 1 }));
  }, []);

  useEffect(() => {
    dispatch(resetGetPayment());
  }, []);

  useEffect(() => {
    if (getPaymentData.data) {
      setForm((f) => ({ ...f, ...getPaymentData.data }));
    } else {
      setForm((f) => ({}));
    }
  }, [getPaymentData.data]);

  return (
    <AddPaymentLayout
      handleFormSubmit={handleFormSubmit}
      form={form}
      setForm={setForm}
      onChange={onChange}
      createData={createData}
      getCurrenciesData={getCurrenciesData}
    />
  );
};

export default AddPayment;
