import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { Advertiser } from "types/model/Advertiser";
import { ProgramCategory } from "types/model/Category";
import SearchPrograms from "containers/oma/SearchPrograms";
import { useAppDispatch } from "hooks/useRedux";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";

type Props = {
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const SimilarStores = ({ form, onChange }: Props) => {
  const dispatch = useAppDispatch();
  const [clearInput, setClearInput] = useState(false);

  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      <div className="space-y-6 sm:space-y-4 mx-auto px-4">
        <div className="mt-4 flex flex-col sm:flex-row w-full sm:w-2/3">
          <SearchPrograms
            value={clearInput ? "" : undefined}
            onResultClicked={(result) => {
              onChange({
                target: {
                  name: "similar_stores",
                  value: [...form.similar_stores, result],
                },
              } as unknown as ChangeEvent<HTMLInputElement>);
              dispatch(clearProgramSearchResults());
              setClearInput(true);

              setTimeout(() => {
                setClearInput(false);
              }, 2000);
            }}
          />
        </div>

        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4 w-1/2 mx-auto">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>

                {/* {form.similar_stores.map((el: Advertiser) => (<MappMerchantCard />)} */}

                <tbody className="divide-y divide-gray-200 bg-white">
                  {form.similar_stores.map((el: Advertiser) => (
                    <tr key={el.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div className="flex flex-row items-center">
                          <img
                            src={el.logo_url || ""}
                            alt="Store logo"
                            className="w-4 mr-2"
                          />
                          {el.name}
                        </div>
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          onClick={(e) => {
                            onChange({
                              target: {
                                name: "similar_stores",
                                value: form.similar_stores.filter(
                                  (it: ProgramCategory) => it.id !== el.id
                                ),
                              },
                            } as ChangeEvent<HTMLInputElement>);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Remove<span className="sr-only">Remove item</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimilarStores;
