import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createUpdateDeleteProposal from "redux/thunks/sales/createUpdateDeleteProposal";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const createUpdateDeleteProposalSlice = createSlice({
  name: "createUpdateDeleteProposal",
  initialState,
  reducers: {
    resetCreateUpdateDeleteProposal: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUpdateDeleteProposal.pending, (state) => {
        return {
          ...state,
          error: null,
          data: {},
          loading: true,
        };
      })
      .addCase(
        createUpdateDeleteProposal.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        createUpdateDeleteProposal.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetCreateUpdateDeleteProposal } =
  createUpdateDeleteProposalSlice.actions;
export default createUpdateDeleteProposalSlice.reducer;
