import { ChangeEvent, ChangeEventHandler, FormEventHandler } from "react";
import Checkbox from "components/common/Checkbox";
import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import Label from "components/common/Label";
import Layout from "components/layout/Layout";
import ProgramHeader from "containers/bma/ProgramHeader";
import { twMerge } from "tailwind-merge";
import dateFormat from "dateformat";
import BidAdjustmentsForm from "components/sem/BidAdjustmentsForm";
import AddLocationsForm from "components/sem/AddLocationsForm";
import { ActionState, SEMLocationSuggestion } from "types/data";
import Message from "components/common/Message";
import Button from "components/common/Button";

type Props = {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  error: Record<string, any>;
  updateResultLocationMode: (
    formKey: string,
    it: Record<string, any>,
    mode: "include" | "exclude"
  ) => void;

  removeSelectedLocation: (formKey: string, it: Record<string, any>) => void;

  onLocationSearchSubmit: (formKey: string) => void;
  onResultSearchResultsClick: (
    formKey: string,
    it: Record<string, any>
  ) => void;

  resetSearchResults: () => void;
  searchLoading: boolean;
  searchResults: {
    [key: string]: Array<SEMLocationSuggestion>;
  };
  createSearchCampaignData: ActionState;
};

const AddSearchCampaignComponent = ({
  handleSubmit,
  onChange,
  form,
  updateResultLocationMode,
  removeSelectedLocation,
  onLocationSearchSubmit,
  searchLoading,
  onResultSearchResultsClick,
  resetSearchResults,
  searchResults,
  createSearchCampaignData: { loading, error: errors },
  error,
}: Props) => {
  return (
    <Layout>
      <ProgramHeader />

      <div className="flex justify-center">
        {/* <div className="flex flex-col shadow-md w-full sm:w-full md:w-1/2 lg:w-1/3 gap-4 p-4 sm:px-2 h-full"> */}
        <form
          onSubmit={handleSubmit}
          className={twMerge(
            "p-4 w-2/4 mt-4  shadow-md bg-white space-y-8 lg:px-8"
          )}
        >
          <h1 className="text-2xl">Create a new Campaign</h1>
          <div>
            <Input
              name={"name"}
              value={form.name}
              label="Campaign Name"
              inputContainerClass="bg-white border-gray-200"
              className="bg-white"
              onChange={onChange}
              required
            />

            <Dropdown
              name={"campaign_type"}
              options={[
                "Exclusive",
                "Semi Exclusive",
                "Open",
                "Gray",
                "Unknown",
              ].map((el) => ({ label: el, value: el }))}
              label="Campaign Type"
              wrapperClassName="my-4"
              className="bg-white border-gray-200 "
              onChange={onChange}
              required
              value={form.campaign_type || ""}
            />
          </div>
          <div>
            <Label label="Search Engine" />

            {["Google", "Bing"].map((el) => (
              <Checkbox
                key={el}
                onChange={(e) => {
                  if (!e.target.checked) {
                    onChange({
                      ...e,
                      target: {
                        ...e.target,
                        name: "search_engines",
                        value: form.search_engines.filter(
                          (it: string) => it !== el
                        ),
                      },
                    });
                  } else {
                    onChange({
                      ...e,
                      target: {
                        ...e.target,
                        name: "search_engines",
                        value: [el, ...form.search_engines],
                      },
                    } as ChangeEvent<any>);
                  }
                }}
                checked={form.search_engines.includes(el)}
                label={el}
              />
            ))}
          </div>
          {form.search_engines?.includes("Google") && (
            <AddLocationsForm
              searchResults={searchResults["google"]}
              form={form}
              label="Add Google Location Targeting"
              resetResults={resetSearchResults}
              onResultClick={onResultSearchResultsClick}
              updateResultLocationMode={updateResultLocationMode}
              removeSelectedLocation={removeSelectedLocation}
              onSearchSubmit={onLocationSearchSubmit}
              onChange={onChange}
              searchLoading={searchLoading}
              errors={error}
              formKey="google"
            />
          )}
          {form.search_engines?.includes("Bing") && (
            <AddLocationsForm
              searchResults={searchResults["bing"]}
              form={form}
              formKey="bing"
              label="Add Bing Location Targeting"
              resetResults={resetSearchResults}
              onResultClick={onResultSearchResultsClick}
              updateResultLocationMode={updateResultLocationMode}
              removeSelectedLocation={removeSelectedLocation}
              onSearchSubmit={onLocationSearchSubmit}
              onChange={onChange}
              searchLoading={searchLoading}
              errors={error}
            />
          )}
          <Input
            name={"daily_budget"}
            label="Budget Per Day"
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            type="number"
            required
            value={form.daily_budget}
            onChange={onChange}
          />
          {form.search_engines?.includes("Google") && (
            <BidAdjustmentsForm
              formKey="google"
              form={form}
              onChange={onChange}
              label="Google device bid adjustments"
            />
          )}
          {form.search_engines?.includes("Bing") && (
            <BidAdjustmentsForm
              form={form}
              formKey="bing"
              onChange={onChange}
              label="Bing device bid adjustments"
            />
          )}
          <div>
            <Input
              type="date"
              label="Campaign Start Date"
              value={
                form.start_date ? dateFormat(form.start_date, "yyyy-mm-dd") : ""
              }
              inputContainerClass="bg-white border-gray-200"
              className="bg-white"
              name="start_date"
              onChange={onChange}
              error={error?.start_date}
            />
          </div>
          <div>
            <Input
              label="Campaign End Date"
              type="date"
              name="end_date"
              value={
                form.end_date ? dateFormat(form.end_date, "yyyy-mm-dd") : ""
              }
              onChange={onChange}
              error={error?.end_date}
              placeholder=""
              inputContainerClass="bg-white border-gray-200"
              className="bg-white"
            />
          </div>
          {/* </div> */}
          <Input
            name={"ad_group_name"}
            label="Ad group Name"
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            value={form.ad_group_name}
            required
          />
          {form.search_engines?.includes("Google") && (
            <Input
              name={"default_bid"}
              label="Google ads Default Bid"
              inputContainerClass="bg-white border-gray-200"
              className="bg-white"
              onChange={(e) => {
                const new_default_bids = {
                  ...form.default_bid,
                  google: e.target.value,
                };

                onChange({
                  ...e,
                  target: {
                    name: "default_bid",
                    value: new_default_bids,
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
              value={form.default_bid["google"]}
              required
            />
          )}
          {form.search_engines?.includes("Bing") && (
            <Input
              name={"default_bid"}
              label="Bing ads Default Bid"
              inputContainerClass="bg-white border-gray-200"
              className="bg-white"
              onChange={(e) => {
                const new_default_bids = {
                  ...form.default_bid,
                  bing: e.target.value,
                };

                onChange({
                  ...e,
                  target: {
                    name: "default_bid",
                    value: new_default_bids,
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
              value={form.default_bid["bing"]}
              required
            />
          )}
          <Input
            name={"final_url"}
            value={form.final_url}
            label="Final URL"
            type="url"
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
          />
          <div>
            <Label label="Display Paths" />

            <div className="flex gap-4">
              <Input
                name={"display_path1"}
                value={form.display_path1}
                inputContainerClass="bg-white border-gray-200"
                className="bg-white"
                onChange={onChange}
                maxLength={15}
                required
              />

              <Input
                name={"display_path2"}
                value={form.display_path2}
                inputContainerClass="bg-white border-gray-200"
                className="bg-white"
                onChange={onChange}
                maxLength={15}
                required
              />
            </div>
          </div>
          <Input
            name={"head_line1"}
            label="Head lines (4)"
            value={form.head_line1}
            error={error.head_line1}
            maxLength={30}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            required
          />
          <Input
            name={"head_line2"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line2}
            error={error.head_line2}
            required
          />
          <Input
            name={"head_line3"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line3}
            error={error.head_line3}
            required
          />
          <Input
            name={"head_line4"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line4}
            error={error.head_line4}
            required
          />
          <Input
            name={"head_line5"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line5}
            error={error.head_line5}
            required
          />{" "}
          <Input
            name={"head_line6"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line6}
            error={error.head_line6}
            required
          />{" "}
          <Input
            name={"head_line7"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line7}
            error={error.head_line7}
            required
          />{" "}
          <Input
            name={"head_line8"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line8}
            error={error.head_line8}
            required
          />{" "}
          <Input
            name={"head_line9"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line9}
            error={error.head_line9}
            required
          />{" "}
          <Input
            name={"head_line10"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line10}
            error={error.head_line10}
            required
          />
          <Input
            name={"description1"}
            label="Descriptions (4)"
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={90}
            value={form.description1}
            error={error.description1}
            required
          />
          <Input
            name={"description2"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={90}
            value={form.description2}
            error={error.description2}
          />
          <Input
            name={"description3"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            value={form.description3}
            error={error.description3}
            maxLength={90}
            required
          />
          <Input
            name={"description4"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={90}
            value={form.description4}
            error={error.description4}
            required
          />
          <div>
            <Label label={"Site Link 1"} />

            <Input
              name={"site_link_1_text"}
              error={error?.site_link_1_text}
              label={"Site Link Text"}
              maxLength={25}
              value={form.site_link_1_text}
              required
              className="bg-white"
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_1_description1"}
              value={form.site_link_1_description1}
              label={"Site Link Description 1"}
              error={error?.site_link_1_description1}
              maxLength={35}
              className="bg-white"
              required
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_1_description2"}
              error={error?.site_link_1_description2}
              value={form.site_link_1_description2}
              label={"Site Link Description 2"}
              className="bg-white"
              placeholder="Recommended"
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_1_final_url"}
              required
              label={"Site Link Final URL"}
              className="bg-white"
              value={form.site_link_1_final_url}
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
            />
          </div>
          <div>
            <Label label={"Site Link 2"} />

            <Input
              name={"site_link_2_text"}
              label={"Site Link Text"}
              error={error?.site_link_2_text}
              className="bg-white"
              required
              value={form.site_link_2_text}
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              maxLength={25}
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_2_description1"}
              label={"Site Link Description 1"}
              error={error?.site_link_2_description1}
              value={form.site_link_2_description1}
              className="bg-white"
              maxLength={35}
              required
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_2_description2"}
              error={error?.site_link_2_description2}
              label={"Site Link Description 2"}
              className="bg-white"
              placeholder="Recommended"
              value={form.site_link_2_description2}
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_2_final_url"}
              label={"Site Link Final URL"}
              className="bg-white"
              value={form.site_link_2_final_url}
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
              type="url"
              required
            />
          </div>{" "}
          <div>
            <Label label={"Site Link 3"} />

            <Input
              name={"site_link_3_text"}
              value={form.site_link_3_text}
              error={error?.site_link_3_text}
              label={"Site Link Text"}
              className="bg-white"
              required
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
              maxLength={25}
            />

            <Input
              name={"site_link_3_description1"}
              error={error?.site_link_3_description1}
              value={form.site_link_3_description1}
              label={"Site Link Description 1"}
              className="bg-white"
              required
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              maxLength={35}
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_3_description2"}
              value={form.site_link_3_description2}
              error={error?.site_link_3_description2}
              label={"Site Link Description 2"}
              className="bg-white"
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              placeholder="Recommended"
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_3_final_url"}
              label={"Site Link Final URL"}
              className="bg-white"
              type="url"
              required
              value={form.site_link_3_final_url}
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
            />
          </div>{" "}
          <div>
            <Label label={"Site Link 4"} />

            <Input
              name={"site_link_4_text"}
              label={"Site Link Text"}
              error={error?.site_link_4_text}
              className="bg-white"
              required
              value={form.site_link_4_text}
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
              maxLength={25}
            />

            <Input
              name={"site_link_4_description1"}
              label={"Site Link Description 1"}
              maxLength={35}
              error={error?.site_link_4_description1}
              required
              value={form.site_link_4_description1}
              className="bg-white"
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_4_description2"}
              label={"Site Link Description 2"}
              error={error?.site_link_4_description2}
              className="bg-white"
              value={form.site_link_4_description2}
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              placeholder="Recommended"
              wrapperClassName="my-2"
            />

            <Input
              name={"site_link_4_final_url"}
              label={"Site Link Final URL"}
              value={form.site_link_4_final_url}
              className="bg-white"
              inputContainerClass="bg-white border-gray-200"
              onChange={onChange}
              required
              wrapperClassName="my-2"
              type="url"
            />
          </div>
          {errors && <Message error description={JSON.stringify(errors)} />}
          {Object.values(error).filter(Boolean).length > 0 && (
            <Message error description="Please fix the errors on the page" />
          )}
          <Button
            type="submit"
            loading={loading}
            // onClick={handleSubmit}
            text={"Continue"}
            className="inline-flex  rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white shadow-sm bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          />
        </form>
      </div>
      {/* </div> */}
    </Layout>
  );
};

export default AddSearchCampaignComponent;
