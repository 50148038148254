import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  LOCAL_CLIENT_ACCESS_EXTRA_KEYS,
  LOCAL_KEY_AUTH_USER,
} from "constants/storageKeys";
import axios from "helpers/axios";

type Params = {
  data: {
    email: string;
    password: string;
  };
};

export default createAsyncThunk(
  "users/login-user",
  async ({ data }: Params, ThunkAPI) => {
    const payload = { ...data };
    try {
      const response = await axios.post(`/admin/users/admin/login`, payload);
      localStorage.setItem(
        LOCAL_KEY_AUTH_USER,
        JSON.stringify(response.data.user)
      );

      localStorage.setItem(
        LOCAL_CLIENT_ACCESS_EXTRA_KEYS,
        JSON.stringify(response.data.extra_tokens)
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          }
      );
    }
  }
);
