import axios from "axios";
import Thunk from "redux/thunks/app/Thunk";
import getOffsetForPage from "utils/arrays/getOffset";

export default Thunk(
  "reporting/ext-reporting-report",
  async (params: Record<string, any>) => {
    const url = new URL(params.reportTypeConfig.endpoint);

    Object.entries(params).forEach(([k, v]) => {
      if (v) {
        if (typeof v === "string" || typeof v === "number")
          url.searchParams.set(k, String(v));
      }
    });

    if (params.page) {
      url.searchParams.set(
        "offset",
        String(getOffsetForPage(Number(params.page)))
      );
    }

    return axios.get(url.href);
  }
);
