import axios from "axios";
import { EXT_REPORTING_BASE_URL } from "config/env";
import Thunk from "redux/thunks/app/Thunk";
import getOffsetForPage from "utils/arrays/getOffset";

export default Thunk(
  "reporting/ext-visits-report",
  async (params: Record<string, any>) => {
    const url = new URL(`${EXT_REPORTING_BASE_URL}/reporting/detailed-visits/`);

    Object.entries(params).forEach(([k, v]) => {
      if (v) {
        if (
          (v !== "undefined" && typeof v === "string") ||
          typeof v === "number"
        )
          url.searchParams.set(k, String(v));
      }
    });

    if (params.page) {
      url.searchParams.set(
        "offset",
        String(getOffsetForPage(Number(params.page)))
      );
    }

    return axios.get(url.href);
  }
);
