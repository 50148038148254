import Dropdown from "components/common/Dropdown";
import { SALES_APP } from "constants/smaAppNames";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useEffect, useState } from "react";
import getUsers from "redux/thunks/users/getUsers";
import { User } from "types/model/User";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  loading?: boolean;
  name?: string;
  value?: string;
  placeholder?: string;
  enableAll?: boolean;
};

const SalesTeamFilter = ({
  onChange,
  loading,
  name,
  value,
  placeholder,
  enableAll,
}: Props) => {
  const dispatch = useAppDispatch();
  const [users, setUsers] = useState<User[]>([]);

  const { getAllUsers } = useAppSelector(({ getAllUsers }) => ({
    getAllUsers,
  }));

  useEffect(() => {
    if (getAllUsers.data.meta.count === 0) {
      dispatch(getUsers({ page: 1 }));
    }
  }, [getAllUsers.data.meta.count]);

  const updateUsers = () => {
    const filteredUsers: User[] = [];

    getAllUsers.data.items.forEach((user) => {
      if (user.user_role === USER_ROLE_ADMIN.toUpperCase()) {
        filteredUsers.push(user);
      } else if (
        user.user_apps
          ?.map((el) => el.name.toLowerCase())
          ?.includes(SALES_APP.toLowerCase())
      ) {
        filteredUsers.push(user);
      }
    });

    setUsers(filteredUsers);
  };

  useEffect(() => {
    if (getAllUsers.data.meta.count > 0) {
      updateUsers();
    }
  }, [getAllUsers.data.meta.count]);

  const userOptions = enableAll
    ? [{ first_name: "All", last_name: "", id: "All" }, ...users]
    : [...users];

  return (
    <Dropdown
      loading={loading}
      options={userOptions.map((el) => ({
        value: el.id,
        label: el.first_name + " " + el.last_name,
      }))}
      name={name || "users"}
      value={value}
      placeholder={placeholder || "Account Manager"}
      onChange={onChange}
    />
  );
};

export default SalesTeamFilter;
