import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import { ChangeEventHandler } from "react";

import dateFormat from "dateformat";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  onSubmit: () => void;
  updateAdGroupLoading: boolean;
};

const EditAdGroupComponent = ({
  updateAdGroupLoading,
  onChange,
  onSubmit,
  form,
}: Props) => {
  return (
    <div className="flex">
      <div className="w-1/2">
        <div className="mb-4">
          <h2>Edit Ad group</h2>
        </div>
        <Input
          name={"ad_group_name"}
          label="Ad group Name"
          inputContainerClass="bg-white border-gray-200 mb-4"
          className="bg-white"
          onChange={onChange}
          value={form.ad_group_name}
          required
        />

        <Input
          name={"default_bid"}
          label="Default Bid"
          inputContainerClass="bg-white border-gray-200"
          className="bg-white"
          onChange={onChange}
          value={form.default_bid}
          required
        />

        <Dropdown
          name={"ad_group_status"}
          options={["Paused", "Active"].map((el) => ({
            label: el,
            value: el,
          }))}
          label="Ad group Status"
          wrapperClassName="my-4"
          className="bg-white border-gray-200 "
          onChange={onChange}
          required
          value={form.ad_group_status || ""}
        />

        <div>
          <Input
            type="date"
            label="Start Date"
            value={
              form.start_date ? dateFormat(form.start_date, "yyyy-mm-dd") : ""
            }
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            name="start_date"
            wrapperClassName="my-4"
            onChange={onChange}
            // error={error?.start_date}
          />
        </div>
        <div>
          <Input
            label="End Date"
            type="date"
            name="end_date"
            value={form.end_date ? dateFormat(form.end_date, "yyyy-mm-dd") : ""}
            onChange={onChange}
            // error={error?.end_date}
            placeholder=""
            wrapperClassName="my-4"
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
          />
        </div>

        <Button
          type="button"
          onClick={onSubmit}
          loading={updateAdGroupLoading}
          text="Save changes"
        />
      </div>
      <div className="extra"></div>
    </div>
  );
};

export default EditAdGroupComponent;
