import React from "react";

type Props = {
  row: Record<string, any>;
  handleRemoveSpotlight: (it: Record<string, any>) => void;
};

const HomeCouponRow = ({ row, handleRemoveSpotlight }: Props) => {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <img
          className="mb-4 w-10 h-10 rounded-md sm:mr-4 sm:mb-0 object-cover"
          src={row.store.logo_url}
          alt={row.store.name}
        />
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.store.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.store.primary_network?.name || "_"}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.store.top_coupon?.id}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.store.top_coupon?.offer_type}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.store.top_coupon?.title}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.store.top_coupon?.discount_code}
      </td>
      <th
        scope="col"
        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
      ></th>

      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button
          className="mx-4"
          onClick={() => {
            handleRemoveSpotlight(row);
          }}
        >
          X
        </button>
      </td>
    </tr>
  );
};

export default HomeCouponRow;
