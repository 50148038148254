import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import CreateUpdateDeleteProposalLayout from "layout/sales/CreateUpdateDeleteProposal";
import { ChangeEventHandler, useState, useEffect } from "react";
import { ProgramCategory } from "types/model/Category";
import { Option } from "types/option";
import { useNavigate } from "react-router-dom";
import getPricingPlan from "redux/thunks/sales/getPricingPlan";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import getClients from "redux/thunks/app/getClients";
import createUpdateDeleteProposal from "redux/thunks/sales/createUpdateDeleteProposal";
import getFinalAmount from "utils/getFullAmount";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import { toast } from "react-hot-toast";
import getProposal from "redux/thunks/sales/getProposal";
import { resetProposal } from "redux/slices/sales/getProposal";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import getDistinctObjects from "utils/arrays/getDistinctObjects";
import storeLocationNameMap from "utils/objects/storeLocationNameMap";
import getProposalTypes from "redux/thunks/sales/getProposalTypes";
import getSearchRightsTypes from "redux/thunks/sales/getSearchRightsTypes";

const CreateUpdateDeleteProposal = () => {
  const [params] = useSearchParams();
  const isDeleting = params.has("deleting");
  const isViewing = params.has("viewing");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [placements, setPlacements] = useState<Record<string, any>[]>([]);

  const initialFormState = {
    program_id: ``,
    clients: [],
    locations: [],
    placement_type: null,
    categories: [],
    start_date: dayjs().toDate(),
    end_date: dayjs().toDate(),
  };

  const [form, setForm] = useState<Record<string, any>>(initialFormState);

  const [lineItemsActive, setLineItemsActive] = useState<boolean>(false);

  const totalAmount = placements.reduce(
    (acc, n) => acc + (Number(n.amount) || 0),
    0
  );

  const finalAmount = getFinalAmount(totalAmount, form);

  const updateProposalForm = (payload: Record<string, any>) => {
    setForm((f) => ({
      ...f,
      ...payload,
      client: String(payload?.client_merchant_config[0].client),
      location: String(payload?.client_merchant_config[0].location),
      search_rights_type:
        payload.search_rights_type?.id || payload.search_rights_type || null,

      program: {
        name: payload?.client_merchant_config[0].program_name,
      },
      offer_discount: payload?.discount,
    }));
  };

  const {
    getCategories: getCategoriesData,
    createUpdateDeleteProposal: createUpdateDeleteProposalData,
    getClients: getClientsData,
    getProgram: getProgramData,
    getProposal: getProposalData,
    getPositionAvailabilityStatuses: getPositionAvailabilityStatusesData,
    getProposalTypes: getProposalTypesData,
    getSearchRightsTypes: getSearchRightsTypesData,
  } = useAppSelector(
    ({
      getCategories,
      createUpdateDeleteProposal,
      getClients,
      getProgram,
      getProposal,
      getPositionAvailabilityStatuses,
      getSearchRightsTypes,
      getProposalTypes,
    }) => ({
      getCategories,
      getSearchRightsTypes,
      getProposal,
      createUpdateDeleteProposal,
      getClients,
      getProgram,
      getPositionAvailabilityStatuses,
      getProposalTypes,
    })
  );

  const formPage =
    form.category && getCategoriesData.data
      ? getCategoriesData.data?.find((el) => el.slug === form.category)?.name
      : form.page;

  useEffect(() => {
    if (params.has("slug")) {
      dispatch(getProgram({ query: params.get("slug") || "" }));
    }
  }, []);

  useEffect(() => {
    if (params.has("plan")) {
      dispatch(getPricingPlan(params.get("plan") || ""));
    }
  }, []);

  useEffect(() => {
    if (params.has("id")) {
      dispatch(getProposal(params.get("id") || ""));
    } else {
      dispatch(resetProposal());
    }

    return () => {
      dispatch(resetProposal());
    };
  }, []);

  useEffect(() => {
    if (getClientsData.data.items?.length) {
      const defaultClient = getClientsData.data.items?.find(
        (el) =>
          String(el.id) === localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)
      );

      setForm((f) => ({
        ...f,
        clients: getClientsData.data.items.map((el) => ({
          label: el.name,
          value: el.id,
          selected: false,
        })),

        client: String(defaultClient?.id),
      }));
    } else {
      dispatch(getClients({ page: 1 }));
    }
  }, [getClientsData.data.items?.length]);

  useEffect(() => {
    if (getCategoriesData.data) {
      setForm((f) => ({
        ...f,
        categories: getCategoriesData.data.map((el: ProgramCategory) => ({
          label: el.name,
          value: el.slug,
          selected: false,
        })),
      }));
    }
  }, [getCategoriesData.data]);

  useEffect(() => {
    if (getProposalData.data?.items) {
      updateProposalForm(getProposalData.data);
      setPlacements(getProposalData.data?.items);
    } else {
      // setPlacements([]);
      // setForm((f) => ({ ...f, ...initialFormState }));
    }
  }, [getProposalData.data]);

  useEffect(() => {
    if (params.has("id")) {
      setForm((f) => ({ ...f, id: params.get("id") }));
    }
  }, [params.get("id")]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    if (name === "dates") {
      const start_date = dayjs(JSON.parse(value)[0]);
      const end_date = dayjs(JSON.parse(value)[1]);

      setForm((f) => ({ ...f, start_date, end_date }));

      return;
    }

    setForm((f) => ({ ...f, [name]: value }));
  };

  const hasUniquePlacements = () => {
    const allKeyIds = placements.map(
      (el) =>
        el.client.id +
        "_" +
        el.start_date +
        "_" +
        el.end_date +
        "_" +
        el.placement_type +
        el.page
    );

    return (
      placements.length > 1 &&
      allKeyIds.some(
        (element) =>
          allKeyIds.indexOf(element) !== allKeyIds.lastIndexOf(element)
      )
    );
  };
  const handleFormSubmit = () => {
    setLineItemsActive(false);

    if (hasUniquePlacements()) {
      toast.error("Placements should be unique");
      return;
    }

    const client_merchant_config = getDistinctObjects(
      placements.map((placement) => ({
        program_slug: placement.program.slug || "",
        program_id: placement.program.id || "",
        program_name: placement.program.name,
        client: placement.client.id,
        location: placement.location,
      }))
    );
    const payload = {
      id: params.get("id"),
      proposal_type: form.proposal_type,
      location: form.location?.toLowerCase(),
      program_slug: form.program_slug || params.get("slug"),
      items: placements,
      discount_notes: form.discount_notes,
      final_amount: finalAmount.toFixed(2),
      discount: form.offer_discount,
      discount_type: form.discount_type,
      search_rights_type: form.search_rights_type || null,
      commission_increase_type: form.commission_increase_type,
      commission_increase: form.commission_increase,
      order_total: totalAmount.toFixed(2),
      client_merchant_config,
      client_merchant_config_str: client_merchant_config
        .map((it) => it.program_slug + "_" + it.client + "_" + it.location)
        .join("_"),
      locations: Array.from(
        new Set(placements.map((el) => el.location.toUpperCase()))
      ),
    };

    if (isDeleting) {
      dispatch(
        createUpdateDeleteProposal({
          ...payload,
          proposal_type: payload.proposal_type.id || payload.proposal_type,
          search_rights_type:
            payload.search_rights_type?.id ||
            payload.search_rights_type ||
            null,
          delete: true,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Proposal removed successfully");
          if (getProgramData.data.slug) {
            navigate(`/sales/proposals?slug=${getProgramData.data.slug || ""}`);
          } else {
            navigate(`/sales/proposals`);
          }
        } else {
          toast.error("An error occurred!");
        }
      });
      return;
    }
    dispatch(
      createUpdateDeleteProposal({
        ...payload,
        proposal_type: payload.proposal_type.id || payload.proposal_type,
        search_rights_type:
          payload.search_rights_type?.id || payload.search_rights_type || null,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (params.has("id")) {
          toast.success("Changes saved");
        } else {
          toast.success("Proposal created successfully");
        }
        updateProposalForm(res.payload);
        navigate(`/sales/proposals/add?id=${res.payload.id}&viewing=true`);
      }
    });
  };

  const resetForm = () => {
    setForm((f) => ({
      ...f,
      page: null,
      placement_type: null,
      position: null,
      amount: null,
      price_id: null,
      positions: [],
      categories: form.categories.map((it: Option) => ({
        ...it,
        selected: false,
      })),
    }));
  };

  const removePaidPlacements = (el: Record<string, any>) => {
    setPlacements((values) =>
      values.filter((it) => it.price_id !== el.price_id)
    );
  };

  const duplicateItem = (el: Record<string, any>, idx: number) => {
    const allItems = [...placements];
    const newItem = {
      ...el,
      id: new Date().getTime(),
    };

    allItems.splice(idx, 0, newItem);

    setPlacements(allItems);
  };

  useEffect(() => {
    if (form.client) {
      setForm((f) => ({ ...f, program: null }));
    }
  }, [form.client]);

  const onEditClicked = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("viewing");
    navigate(url.search);
  };

  const handleAddForNewClient = () => {
    resetForm();
    setLineItemsActive(false);
  };

  useEffect(() => {
    dispatch(getProposalTypes());
    dispatch(getSearchRightsTypes());
  }, []);

  const onProgramSearchResultClicked = (result: Record<string, any>) => {
    const location = storeLocationNameMap[result.location.toLowerCase()];

    setForm((f) => ({
      ...f,
      location: location,
      program: {
        id: result.id,
        name: result.name,
        slug: result.slug,
      },
    }));
    dispatch(clearProgramSearchResults());
  };

  const onLineItemAdded = () => {
    handleAddForNewClient();
  };

  const deleteProposal = (
    proposal: Record<string, any>,
    delete_reason: string
  ) => {
    dispatch(
      createUpdateDeleteProposal({
        ...proposal,
        proposal_type: proposal.proposal_type.id,
        search_rights_type: null,
        deleted: true,
        delete_reason: delete_reason,
        deleted_at: new Date(),
        delete: true,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Proposal removed successfully");
        navigate(`/sales/proposals`);
      } else {
        const payload = res.payload as Array<Record<string, any>>;
        toast.error(Object.values(payload)?.[0]?.[0] || "Something went wrong");
      }
    });
  };

  return (
    <CreateUpdateDeleteProposalLayout
      duplicateItem={duplicateItem}
      deleteProposal={deleteProposal}
      proposalTypes={getProposalTypesData}
      removePaidPlacements={removePaidPlacements}
      handleAddForNewClient={handleAddForNewClient}
      handleFormSubmit={handleFormSubmit}
      form={form}
      isDeleting={isDeleting}
      lineItemsActive={lineItemsActive}
      setLineItemsActive={setLineItemsActive}
      setForm={setForm}
      onChange={onChange}
      getCategoriesData={getCategoriesData}
      placements={placements}
      setPlacements={setPlacements}
      createUpdateDeleteProposalData={createUpdateDeleteProposalData}
      finalAmount={finalAmount}
      totalAmount={totalAmount}
      formPage={formPage}
      getPositionAvailabilityStatusesData={getPositionAvailabilityStatusesData}
      isViewing={isViewing}
      onEditClicked={onEditClicked}
      getProgramData={getProgramData}
      onProgramSearchResultClicked={onProgramSearchResultClicked}
      onLineItemAdded={onLineItemAdded}
      getSearchRightsTypesData={getSearchRightsTypesData}
    />
  );
};
export default CreateUpdateDeleteProposal;
