import AddPayment from "containers/sales/AddPayment";
import AddPricingPlan from "containers/sales/CreateUpdateDeletePricingPlan";
import CreateUpdateDeleteProposal from "containers/sales/CreateUpdateDeleteProposal";
import Payments from "containers/sales/Payments";
import PricingPlan from "containers/sales/PricingPlan";
import Proposal from "containers/sales/Proposal";
import SalesHome from "containers/sales/SalesHome";
import ProposalsIOHome from "layout/sales/ProposalsIOHome";

const routes = [
  {
    path: "sales/",
    element: <SalesHome />,
  },
  {
    path: "sales/add-pricing-plan",
    element: <AddPricingPlan />,
  },

  {
    path: "sales/proposals",
    element: <ProposalsIOHome />,
  },
  {
    path: "sales/proposals/:id",
    element: <Proposal />,
  },
  {
    path: "sales/ios/payments/add",
    element: <AddPayment />,
  },

  {
    path: "sales/proposals/add",
    element: <CreateUpdateDeleteProposal />,
  },

  {
    path: "sales/pricing-plans",
    element: <SalesHome />,
  },

  {
    path: "sales/pricing-plans/:id",
    element: <PricingPlan />,
  },

  {
    path: "sales/proposals/payments",
    element: <Payments />,
  },
];

export default routes;
