import Button from "components/common/Button";
import {
  MEDIUM_CONFERENCE,
  MEDIUM_EMAIL,
  MEDIUM_ZOOM_PHONE,
} from "constants/contactMediumIds";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import SalesTeamFilter from "layout/sales_crm/components/SalesTeamFilter";
import { Dispatch, SetStateAction } from "react";
import { FaEnvelope, FaPhone, FaUsers } from "react-icons/fa";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { PaginatedActionState } from "types/data";
import { Event } from "types/model/sales_crm/Event";
import { LeadTopic } from "types/model/sales_crm/Lead";
import formatDate from "utils/dates/formatDate";
import FeedProgramImage from "./FeedProgramImage";

const topicColors: Record<string, string> = {
  "Paid Placement": "#f87171", // Red
  "TM+ Search": "#3b82f6", // Blue
  Commission: "#10b981", // Green
  "Exclusive Code": "#fbbf24", // Yellow
  "Program Approval": "#8b5cf6", // Purple
  "Payment Status": "#fc063a", // Teal
  "Extension Approval": "#ec4899", // Pink
};

interface Props {
  eventsData: PaginatedActionState<Event>;
  loadMoreEvents: () => void;
  currentItems: Array<Event>;
  setUserId: Dispatch<SetStateAction<string>>;
  loading: boolean;
  programOwner?: Record<string, any>;
}

const ActivityFeed = ({
  eventsData,
  loading,
  currentItems,
  setUserId,
  loadMoreEvents,
  programOwner,
}: Props) => {
  const { user } = useLoadAuth();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const { client_id, subTab } = Object.fromEntries([...searchParams]);

  const isAdmin = user?.user_role === USER_ROLE_ADMIN;

  return (
    <>
      <div className="border border-gray-200 rounded-md my-4">
        <section className="bg-white sm:rounded-lg px-4 pb-4 h-[720px] overflow-y-scroll">
          <div className="mx-auto max-w-4xl">
            <main className="py-4">
              {slug && (
                <div className="flex justify-between items-center">
                  <div>
                    <h2
                      id="updates-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Updates
                    </h2>
                  </div>
                  <Button
                    disabled={programOwner?.user?.id !== user?.id && !isAdmin}
                    title={
                      !isAdmin && programOwner?.user?.id !== user?.id
                        ? "You are not the program owner, please contact your administrator"
                        : ""
                    }
                    onClick={() => {
                      navigate(
                        `/sales/crm/new-event?slug=${slug}&client_id=${
                          client_id ||
                          localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID) ||
                          ""
                        }`
                      );
                    }}
                    text="New Event"
                  />
                </div>
              )}
              <div className="py-2">
                {!slug && subTab !== "follow-ups" && (
                  <div className="flex justify-between items-center">
                    <div className=" pb-2 mb-2">
                      <h2 className="text-base font-semibold leading-6 text-gray-900 pl-3">
                        Today&apos;s Update
                      </h2>
                    </div>

                    {isAdmin && (
                      <SalesTeamFilter
                        onChange={(e) => {
                          setUserId(e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {currentItems.length === 0 && !loading && (
                  <p className={twMerge("text-sm", !slug && "pl-3")}>
                    No events yet
                  </p>
                )}
                {loading && <p className="text-sm pl-3">Loading updates...</p>}
                <ol
                  className={twMerge(
                    "relative p-4 ",
                    slug &&
                      currentItems.length > 0 &&
                      "border-l border-gray-700"
                  )}
                >
                  {currentItems.map((update) => (
                    <li
                      key={update.id}
                      className={twMerge(
                        "mb-4  p-3",
                        !slug && "border-l-2 border-gray-200"
                      )}
                    >
                      {slug && currentItems.length > 0 && (
                        <div className="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-gray-900 bg-gray-700"></div>
                      )}
                      <time className="mb-1 text-sm font-normal leading-none  text-gray-500">
                        {formatDate(update.event_date)}
                      </time>
                      <div className="flex justify-between items-center">
                        {slug && (
                          <h3 className="text-lg font-semibold  text-gray-900 py-2">
                            {update.headline}
                          </h3>
                        )}
                        {slug && (
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => {
                                navigate(
                                  `/sales/crm/new-event?event-id=${update.id}&create_sub=1&client_id=${client_id}`
                                );
                              }}
                              className="text-slate-500 text-sm hover:bg-gray-200 hover:rounded-md p-2 hover:font-semibold"
                            >
                              + Add sub update
                            </button>

                            {slug && update.created_by.id === user?.id && (
                              <button
                                className="text-slate-500 text-sm hover:bg-gray-200 hover:rounded-md p-2 hover:font-semibold"
                                onClick={() => {
                                  navigate(
                                    `/sales/crm/new-event?event-id=${update.id}&client_id=${client_id}`
                                  );
                                }}
                              >
                                Edit
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <div className="flex items-center space-x-4">
                          {!slug && <FeedProgramImage update={update} />}
                          <div>
                            {!slug && (
                              <h3 className="text-lg font-semibold  text-gray-900 py-2">
                                {update.headline}
                              </h3>
                            )}

                            <p className="text-base font-normal  text-gray-400">
                              {update.notes}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex flex-wrap gap-2 mb-4 mt-2">
                          {update.topics.map((topic: LeadTopic) => (
                            <div
                              key={topic.id}
                              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                            >
                              <svg
                                className="w-2.5 h-2.5"
                                viewBox="0 0 6 6"
                                aria-hidden="true"
                              >
                                <circle
                                  cx={3}
                                  cy={3}
                                  r={3}
                                  fill={topicColors[topic.name]}
                                />
                              </svg>
                              {topic.name}
                            </div>
                          ))}
                        </div>

                        <div className="flex items-center justify-end">
                          <span className=" text-black text-sm font-semibold py-0.5 rounded-md">
                            {(update.created_by?.first_name || "") +
                              " " +
                              (update.created_by?.last_name || "")}
                          </span>
                          {update.medium.id === MEDIUM_EMAIL && (
                            <FaEnvelope className="text-gray-500 mx-2 h-3 w-3" />
                          )}
                          {update.medium.id === MEDIUM_ZOOM_PHONE && (
                            <FaPhone className="text-gray-500 mx-2 h-3 w-3" />
                          )}
                          {update.medium.id === MEDIUM_CONFERENCE && (
                            <FaUsers className="text-gray-500 mx-2 h-3 w-3" />
                          )}
                          <span className="text-black text-xs font-semibold rounded-md">
                            {update.contact.first_name ||
                              "" + " " + update.contact.last_name ||
                              ""}
                          </span>
                        </div>
                      </div>
                      {update.sub_updates &&
                        update.sub_updates.map((sub: Record<string, any>) => (
                          <div
                            key={sub.id}
                            className="ml-4 pl-4 relative border-l border-blue-800 "
                          >
                            <div className=" border border-white "></div>
                            <div className="flex justify-between">
                              <div>
                                <time className="mb-1 text-sm font-normal leading-none  text-gray-500">
                                  {formatDate(sub.event_date)}
                                </time>
                                <p className="text-base font-normal  text-gray-400">
                                  {sub.notes}
                                </p>
                              </div>
                              {slug && update.created_by.id === user?.id && (
                                <button
                                  className="text-slate-500 text-sm hover:bg-gray-200 hover:rounded-md p-2 hover:font-semibold"
                                  onClick={() => {
                                    navigate(
                                      `/sales/crm/new-event?event-id=${sub.id}&client_id=${client_id}&is_sub=1`
                                    );
                                  }}
                                >
                                  Edit
                                </button>
                              )}
                            </div>

                            <div className="flex flex-wrap gap-2 mb-4">
                              {sub.topics &&
                                sub.topics.map((topic: LeadTopic) => (
                                  <div
                                    key={topic.id}
                                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                  >
                                    <svg
                                      className="w-2.5 h-2.5"
                                      viewBox="0 0 6 6"
                                      aria-hidden="true"
                                    >
                                      <circle
                                        cx={3}
                                        cy={3}
                                        r={3}
                                        fill={topicColors[topic.name]}
                                      />
                                    </svg>
                                    {topic.name}
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}

                      {update.linked_programs.filter((el) => el.slug !== slug)
                        .length > 0 && (
                        <p className="mt-2 text-sm  text-gray-600">
                          This event was {slug ? "also" : ""} added for{" "}
                          {update.linked_programs
                            .filter((el) => el.slug !== slug)
                            .map((el, idx) => (
                              <Link
                                key={el.id}
                                to={`/sales/crm/store/${el.slug}?client_id=${el.client}`}
                              >
                                <a className="text-blue-700 hover:text-indigo-900 hover:font-semibold">
                                  {el.name}
                                  <span className="sr-only">{el.name}</span>
                                  {idx + 1 ==
                                  update.linked_programs.filter(
                                    (el) => el.slug !== slug
                                  ).length
                                    ? "."
                                    : ", "}
                                </a>
                              </Link>
                            ))}
                        </p>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            </main>
            {eventsData.loading ||
            eventsData.data.meta.offset + 50 >=
              eventsData.data.meta.count ? null : (
              <Button
                onClick={loadMoreEvents}
                text={eventsData.loading ? "Loading" : "Load more events"}
              />
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default ActivityFeed;
