import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import getOffsetForPage from "utils/arrays/getOffset";

type Params = {
  page: number;
};

export default createAsyncThunk(
  "clients/marketing/emails/email-campaign-settings",
  async ({ page = 1 }: Params, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }
      const response = await axios.get(
        `/admin/emails/email-campaign-settings?client=${selectedClientId}&offset=${getOffsetForPage(
          page
        )}`
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
