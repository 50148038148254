import { CheckIcon, NoSymbolIcon } from "@heroicons/react/20/solid";
import { timeAgo } from "index";
import { GrClose } from "react-icons/gr";
import { RiCloseCircleFill, RiCloseCircleLine } from "react-icons/ri";
import { Client } from "types/model/Client";

interface Props<T = Record<string, any>> {
  item: T;
  onItemClick?: (network: Record<string, any>) => void;
  onItemCheckChanged?: (network: Record<string, any>) => void;
  isChecked?: (network: Record<string, any>) => void;
}

function NetworkRow({
  item,
  onItemClick,
  isChecked,
  onItemCheckChanged,
}: Props) {
  const checked = isChecked?.(item) || false;

  const programSync = item.sync_logs.find(
    (it: Record<string, any>) => it.resource == "advertisers"
  );

  const linksSync = item.sync_logs.find(
    (it: Record<string, any>) => it.resource == "links"
  );
  const transactionsSync = item.sync_logs.find(
    (it: Record<string, any>) => it.resource == "transactions"
  );
  return (
    <tr className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
      <td className="px-4 py-2 w-4">
        <div className="flex items-center">
          <input
            id="checkbox-table-search-1"
            type="checkbox"
            onChange={(e) => {
              onItemCheckChanged?.(item);
            }}
            checked={checked}
            className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label htmlFor="checkbox-table-search-1" className="sr-only">
            checkbox
          </label>
        </div>
      </td>

      <td
        className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        onClick={() => {
          onItemClick?.(item);
        }}
      >
        <button className="font-medium text-primary-600 dark:text-primary-500 hover:underline inline-flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
          </svg>
          {item.network}
        </button>
      </td>

      <th
        scope="row"
        className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {item.cid || "_"}
      </th>

      <td className="px-4 py-2 whitespace-nowrap">
        <div className="w-56   overflow-hidden">
          {item.clients.map((client: Client) => (
            <span
              key={client.id}
              className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 mr-1"
            >
              {client.name}
            </span>
          ))}
        </div>
      </td>

      <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
        {programSync ? (
          <div>
            <span>
              {" "}
              {timeAgo.format(new Date(programSync.updated_at), "mini")}
            </span>{" "}
            <div>
              {" "}
              {programSync.status == "FINISHED" && (
                <CheckIcon className="h-6" color="green" />
              )}
              {programSync.status == "ERRORED" && (
                <NoSymbolIcon
                  className="h-6"
                  color="red"
                  title={programSync.notes}
                />
              )}
            </div>
          </div>
        ) : (
          "_"
        )}
      </td>

      <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
        {linksSync ? (
          <>
            <span>
              {" "}
              {timeAgo.format(new Date(linksSync.updated_at), "mini")}
            </span>{" "}
            <div>
              {" "}
              {linksSync.status == "FINISHED" && (
                <CheckIcon className="h-6" color="green" />
              )}
              {linksSync.status == "ERRORED" && (
                <NoSymbolIcon
                  className="h-6"
                  color="red"
                  title={linksSync.notes}
                />
              )}
            </div>
          </>
        ) : (
          "_"
        )}
      </td>

      <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
        {transactionsSync ? (
          <div>
            <span>
              {" "}
              {timeAgo.format(new Date(transactionsSync.updated_at), "twitter")}
            </span>{" "}
            {transactionsSync.status == "FINISHED" && (
              <CheckIcon className="h-6" color="green" />
            )}
            {transactionsSync.status == "ERRORED" && (
              <NoSymbolIcon
                className="h-6"
                color="red"
                title={transactionsSync.notes}
              />
            )}
          </div>
        ) : (
          "_"
        )}
      </td>
    </tr>
  );
}

export default NetworkRow;
