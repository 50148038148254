import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getAIFaqs from "redux/thunks/marketing/content/getAIFaqs";
import { PageNumPaginatedActionState } from "types/data";
import { StoreFaqType } from "types/model/oma/Store";

const initialState: PageNumPaginatedActionState<StoreFaqType> = {
  data: {
    results: [],
    count: 0,
    page: 1,
    next: null,
    previous: null,
  },
  loading: false,
  error: null,
};

const getAIFaqsSlice = createSlice({
  name: "getAIFaqs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAIFaqs.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getAIFaqs.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      )

      // .addCase(refreshStoreAIFaq.fulfilled, (state, action) => {
      //   return {
      //     ...state,
      //     data: action.payload,
      //     loading: false,
      //   };
      // })

      .addCase(
        getAIFaqs.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getAIFaqsSlice.reducer;
