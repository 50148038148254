import { ChangeEventHandler } from "react";
import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import SearchForm from "components/common/SearchForm";
import IO_COLUMNS from "constants/tables/headers/sales/ios";
import IORevenueRow from "layout/sales/IORevenueRow";
import { Proposal } from "types/model/sales/Proposal";
import { ActionState, PaginatedData } from "types/data";

type Props = {
  proposalActionData: ActionState;
  handleSetSearchRevenue: (proposal: Proposal, revenue: string) => void;
  debouncedOnChange: ChangeEventHandler<HTMLInputElement>;
  data: PaginatedData<Proposal>;
  onFiltersChange: (v: string) => void;
  onPageChange: (v: number) => void;
  filters: Record<string, any>;
  has_earned_revenue: string;
  onReset: () => void;
  loading: boolean;
};

const ProposalEvaluationLayout = ({
  proposalActionData,
  handleSetSearchRevenue,
  debouncedOnChange,
  data,
  onFiltersChange,
  onPageChange,
  filters,
  has_earned_revenue,
  onReset,
  loading,
}: Props) => {
  const renderRow: RowRenderer<Proposal> = (proposal) => {
    return (
      <IORevenueRow
        proposalActionData={proposalActionData}
        handleSetSearchRevenue={handleSetSearchRevenue}
        proposal={proposal}
        key={proposal.id}
      />
    );
  };
  return (
    <div className="my-8">
      <div className="flex justify-between items-center my-4 mx-4">
        <SearchForm wrapperClassName="w-64" onChange={debouncedOnChange} />
        <div>
          <small className="inline-block">{data.meta.count} Items </small>
        </div>
      </div>
      <DataTable
        header={
          <div className="flex gap-4 items-center my-4 mx-4">
            <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
              Show only:{" "}
            </p>
            {["Has Earned Revenue", "No Earned Revenue"].map((it, idx) => (
              <div className="flex items-center  mr-4" key={it}>
                <input
                  id={String(idx)}
                  type="radio"
                  value={it}
                  checked={has_earned_revenue === String(idx)}
                  onChange={(e) => {
                    onFiltersChange(e.target.checked && idx == 0 ? "0" : "1");
                  }}
                  name="has_earned_revenue"
                  className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor={String(idx)}
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {it}
                </label>
              </div>
            ))}
            <button className="text-sm" onClick={onReset}>
              Reset
            </button>
          </div>
        }
        columns={IO_COLUMNS.map((el) =>
          el.key === "Signature"
            ? {
                ...el,
                key: "Search Revenue",
                label: "Search Revenue",
              }
            : el
        )}
        rows={data.items}
        loading={loading}
        totalCount={data.meta.count}
        onPageChange={onPageChange}
        currentPage={Number(filters.page) || 1}
        rowRenderer={renderRow as RowRenderer<Record<string, any>>}
      />
    </div>
  );
};

export default ProposalEvaluationLayout;
