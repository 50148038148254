import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

export default createAsyncThunk(
  "clients/getSeasonalLinks",
  async (_, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }
      const response = await instance.get(
        `${DISCOUNT_CODES_API}/campaigns/seasonal-links/`
      );

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
