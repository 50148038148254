import Layout from "components/layout/Layout";
import { Card } from "flowbite-react";
import useLoadAuth from "hooks/useLoadAuth";
import AccountComponent from "./Account";

const MyAccount = () => {
  const { user } = useLoadAuth();
  return (
    <Layout>
      <AccountComponent user={user} />
      {/* <Card className="text-sm">
        <p>Name: {user?.first_name + " " + user?.last_name}</p>
        <p>Email: {user?.email}</p>
        <p>Role: {user?.user_role}</p>
        <p>Locations: {user?.locations.join(",")}</p>
        <p>
          Apps:{" "}
          {user?.user_apps?.map((el, index) => (
            <span key={el.id}>
              {el.name}
              {index < (user.user_apps?.length || 0) ? "," : ""}
            </span>
          ))}
        </p>
      </Card> */}
    </Layout>
  );
};

export default MyAccount;
