import Categories from "containers/oma/OMACategories";
import OMAAddCategory from "containers/oma/OMAAddCategories";
import CategoryCoupons from "containers/oma/CategoryCoupons";
import CategoryContent from "containers/oma/OMACategoryContent";

const routes = [
  {
    path: "/oma/categories",
    element: <Categories />,
  },

  {
    path: "/oma/categories/:coupons", // TODO:: CHECK PAGE
    element: <CategoryCoupons />,
  },

  {
    path: "/oma/categories/category",
    element: <OMAAddCategory />,
  },

  {
    path: "/oma/categories/:id/content",
    element: <CategoryContent />,
  },
];

export default routes;
