import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { toast } from "react-hot-toast";

export default createAsyncThunk(
  "sales/sendSigningRequest",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.post(
        `/admin/sales/proposals/send-doc-signing-request`,
        payload
      );
      toast.success("Signing request has been sent!")
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
