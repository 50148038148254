import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";
import getShortCouponInfo from "./getShortCouponInfo";

type Props = {
  slug: string;
};

const useDescriptions = ({ slug }: Props) => {
  const dispatch = useAppDispatch();

  const [topCoupon, setTopCoupon] = useState<Record<string, any> | null>(null);
  const [descriptions, setDescriptions] = useState<string[] | null>(null);
  const { data } = useAppSelector((state) => state.getActiveCoupons);

  useEffect(() => {
    dispatch(getActiveCoupons({ store_slug: slug, page: 1 }));
  }, [slug]);

  useEffect(() => {
    if (data.results.length) {
      const item = data.results.find(
        (it: Record<string, any>) => it.sorting_for_store === "0",
      );

      if (item) {
        setTopCoupon(item);
      } else {
        setTopCoupon(data.results[0]);
      }
    }
  }, [data.results]);

  useEffect(() => {
    if (topCoupon) {
      const shortCouponStr = getShortCouponInfo(topCoupon);

      if (!shortCouponStr) {
        setDescriptions([
          `Limited-Time Discounts. Every ${topCoupon.store.name} Coupon Codes verified by us.`,
          `${topCoupon.store.name} Exclusive Discounts${
            topCoupon.store.name.length < 6 ? " & Offers" : ""
          }`,
          `Find Exclusive Discounts and Sitewide Promo Codes for extra savings on every purchase.`,
          `Redeem these active discount codes at checkout for huge savings on your orders today.`,
          `Save now with ${topCoupon.store.name} promo codes and coupons. Find extra savings on every purchase.`,
        ]);
      }

      if (topCoupon.store.location !== "uk" && shortCouponStr) {
        setDescriptions([
          `Get ${shortCouponStr} ${topCoupon.store.name} Coupon Code and Save on your entire order. Ends soon.`,
          `Use our ${shortCouponStr} ${topCoupon.store.name} Coupon Code and Save on all orders today.`,
          `Get ${shortCouponStr} ${topCoupon.store.name} Promo Code and Save on all orders. Hurry up. Limited-time offer.`,

          `Get ${shortCouponStr} ${topCoupon.store.name} Discount Code and Save on your entire order. Limited-time offer.`,
        ]);
      }

      if (topCoupon.store.location === "uk" && shortCouponStr) {
        setDescriptions([
          `Get ${shortCouponStr} ${topCoupon.store.name} Promo Code and Save on all orders. Limited-time offer.`,
          `Get ${shortCouponStr} ${topCoupon.store.name} Discount Code and Save on your entire order. Ends soon.`,
          `Use our ${shortCouponStr} ${topCoupon.store.name} Discount Code and Save on all orders today.`,
          `Top ${topCoupon.store.name} Promo Code - ${shortCouponStr}. Stop paying the full price.`,
        ]);
      }
    }
  }, [topCoupon?.store?.name]);

  return descriptions;
};

export default useDescriptions;
