import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";
import getProposalsPaymentStats from "./getProposalsPaymentStats";

export default createAsyncThunk(
  "sales/get-ios",
  async (params: Record<string, any>, ThunkAPI) => {
    try {
      const url = new URL(
        BACKEND_BASE_URL + "/admin/sales/proposals?proposal_status=Approved"
      );

      if (params["proposal_type"]) {
        url.searchParams.set("proposal_type", params["proposal_type"]);
      }
      if (params["has_earned_revenue"]) {
        url.searchParams.set(
          "has_earned_revenue",
          params["has_earned_revenue"]
        );
      }
      if (params["search_only"]) {
        url.searchParams.set("search_only", params["search_only"]);
      }

      if (params["start_date"]) {
        url.searchParams.set("start_date", params["start_date"]);
      }
      if (params["search_text"]) {
        url.searchParams.set("search_text", params["search_text"]);
      }
      if (params["end_date"]) {
        url.searchParams.set("end_date", params["end_date"]);
      }
      if (params["location"]) {
        url.searchParams.set("location", params["location"]);
      }

      if (params["program_slug"]) {
        url.searchParams.set("program_slug", params["program_slug"]);
      }

      if (params["proposal_status"]) {
        url.searchParams.set("proposal_status", params["proposal_status"]);
      }

      if (params["payment_status"]) {
        url.searchParams.set("payment_status", params["payment_status"]);
      }
      if (params["created_by"]) {
        url.searchParams.set("created_by", params["created_by"]);
      }
      if (params["page"]) {
        url.searchParams.set(
          "offset",
          String(getOffsetForPage(params["page"]))
        );
      }

      ThunkAPI.dispatch(getProposalsPaymentStats(params));

      const response = await axios.get(
        url.pathname
          .replace("/api", "")
          .replace("/production", "")
          .replace("/dev", "") + url.search
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
