import BrowserExtensionReports from "containers/reporting/BrowserExtensionReports";
import ExtendedVisitsReport from "containers/reporting/ExtendedVisitsReport";
import ReportingHome from "containers/reporting/ReportingHome";

const reportingRoutes = [
  {
    path: "/reporting/revenue",
    element: <ReportingHome />,
  },

  {
    path: "/reporting/browser-extension",
    element: <BrowserExtensionReports />,
  },

  {
    path: "/reporting/browser-extension/visits",
    element: <ExtendedVisitsReport />,
  },
];

export default reportingRoutes;
