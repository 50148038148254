import ClientAPI from "client-apis";
import updateOperationThunk from "../../Thunk";

export default updateOperationThunk(
  "clients/category",
  async (payload: Record<string, any>) => {
    const clientWebsite = new ClientAPI();
    return await clientWebsite.getCategory({
      client: clientWebsite,
      ...payload,
    });
  }
);
