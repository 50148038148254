import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  title: string;
  paths: string[];
  disabled: boolean;
};

const HeaderNavLink = ({ title, paths, disabled }: Props) => {
  if (disabled) {
    return null;
  }
  const location = useLocation();

  const cls = twMerge(
    `   font-semibold rounded-full bg-gray-100 text-sm px-4 py-2 lg:px-5 lg:py-2.5 mx-2`,
    paths.includes(location.pathname) && "text-white bg-primary-700"
  );

  return (
    <Link to={disabled ? "#" : paths[0]} className={cls} aria-current="page">
      {title}
    </Link>
  );
};

export default HeaderNavLink;
