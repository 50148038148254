import TabLayout from "components/common/TabLayout";
import BrowserExtExclusions from "containers/marketing/extension/BrowserExtExclusions";
import BrowserExtNetworkAutoOpen from "containers/marketing/extension/BrowserExtNetworkAutoOpen";
import BrowserExtStoreAutoOpen from "containers/marketing/extension/BrowserExtStoreAutoOpen";

const BrowserExtensionLayout = () => {
  const tabOptions = [
    {
      content: <BrowserExtExclusions />,
      text: "Excluded Networks",
    },

    {
      content: <BrowserExtNetworkAutoOpen />,
      text: "Extension Open",
    },
    {
      content: <BrowserExtStoreAutoOpen />,
      text: "Auto-Open Enabled Stores From Manual-Open Networks",
    },
  ];
  return <TabLayout hasPermissions options={tabOptions} />;
};

export default BrowserExtensionLayout;
