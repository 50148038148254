function greet(): string {
  const time: number = new Date().getHours();
  let greeting: string;
  const today = new Date();
  const dayOfWeek = today.getDay();
  const isMorning = time >= 5 && time < 12;

  if (dayOfWeek === 1 && isMorning) {
    greeting = "Happy Monday";
  }

  if (isMorning) {
    greeting = "Good morning";
  } else if (time >= 12 && time < 18) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }

  return greeting;
}

export default greet;
