import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getTrendingStores from "redux/thunks/app/placements/campaigns/getTrendingStores";

export type ActionStateType = {
  data: Record<string, any>[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getTrendingStoresSlice = createSlice({
  name: "getTrendingStores",
  initialState,
  reducers: {
    clearTrendingStoreResults: () => {
      return initialState;
    },

    updateTrendingStore: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((el) => (el.id === payload.id ? payload : el)),
      };
    },

    removeTrendingStore: (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter((el) => el.store.id !== payload.store.id),
      };
    },

    setTrendingStores: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },

    addToTrendingStores: (state, { payload }) => {
      return {
        ...state,
        data: [
          {
            from_date: null,
            to_date: null,
            store: payload,
          },
          ...state.data,
        ],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrendingStores.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        getTrendingStores.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getTrendingStores.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const {
  clearTrendingStoreResults,
  updateTrendingStore,
  addToTrendingStores,
  setTrendingStores,
  removeTrendingStore,
} = getTrendingStoresSlice.actions;
export default getTrendingStoresSlice.reducer;
