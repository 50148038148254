import { MapPinIcon } from "@heroicons/react/20/solid";
import PendingProgramCoupons from "containers/oma/PendingProgramCoupons";
import { Advertiser } from "types/model/Advertiser";
import { Link } from "types/model/Link";

type Props = {
  item: Advertiser;
  onActivatePressed: (arg: Record<string, any>) => void;
  onItemSelectChange: (adv: Link) => void;
  handleIgnoreLinks: (link?: Record<string, any>) => void;
  selectedItems: Record<string, any>[];
  ignoreLoading: boolean;
  handleExpandItem: (arg: Record<string, any>) => void;
  expandedItemId: string | number;
};

const GroupedProgramLinks = ({
  item,
  handleExpandItem,
  expandedItemId,
}: Props) => {
  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => {
          handleExpandItem(item);
        }}
      >
        <header className="bg-gray-50 py-4 my-4 w-full">
          <div className="flex flex-col w-full gap-8 sm:flex-row sm:flex-wrap  sm:items-center justify-between max-w-7xl px-4 sm:px-6">
            <div className="flex gap-2 sm:4 sm:flex-row items-center w-full sm:w-auto">
              <h1 className="mt-2 text-lg sm:text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
                {item.name}
              </h1>
            </div>

            <div className="flex gap-4 items-center">
              <div className="flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <MapPinIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Network: {item.network || "_"}
                </div>
              </div>

              <div className="flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <MapPinIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Pending coupons: {item.network_advertiser_link_count || "_"}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      {String(expandedItemId) === String(item.id) && (
        <PendingProgramCoupons
          page={"1"}
          network={item.network}
          program_id={item.external_id}
          ordering={"coupon_code"}
        />
      )}
    </div>
  );
};

export default GroupedProgramLinks;
