import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import getNetworks from "redux/thunks/networks/getNetworks";
import syncPrograms from "redux/thunks/advertisers/syncPrograms";
import { Network } from "types/model/Network";
import NetworksbyclientComponent from "layout/networks/Networksbyclient";

const NetworksByClient = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { networks: networksData } = useAppSelector(({ networks }) => ({
    networks,
  }));

  const [currentItems, setCurrentItems] = useState<Network[]>([]);
  const [selectedRows, setSelectedRows] = useState<Record<string, any>[]>([]);

  const filterByMapStatus = (onlyUnmapped: boolean) => {
    if (onlyUnmapped) {
      setCurrentItems((currentItems) =>
        currentItems.filter((item) => !item.programs_mapped)
      );
    } else {
      setCurrentItems(networksData.data.items);
    }
  };

  useEffect(() => {
    if (networksData.data.items.length) {
      setCurrentItems(networksData.data.items);
    }
  }, [networksData.data.items.length]);

  useEffect(() => {
    dispatch(
      getNetworks({
        page: 1,
      })
    );
  }, []);

  const onItemClick = (item: Record<string, any>) => {
    navigate(`/settings/networks/${item.id}`, { state: item });
  };
  const onItemCheckChanged = (row: Record<string, any>) => {
    const exists = selectedRows.map((it) => it.id).includes(row.id);
    setSelectedRows((prev) =>
      !exists ? [...prev, row] : prev.filter((it) => it.id !== row.id)
    );
  };

  const isChecked = (row: Record<string, any>) =>
    selectedRows.map((it) => it.id).includes(row.id);

  const handleSubmit = (resource: string) => {
    dispatch(
      syncPrograms({
        data: { resource, ids: selectedRows.map((it) => it.id) },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setSelectedRows([]);
        toast.success("API sync started");
      }
    });
  };

  return (
    <NetworksbyclientComponent
      filterByMapStatus={filterByMapStatus}
      networksData={{
        ...networksData,
        data: { ...networksData.data, items: currentItems },
      }}
      onItemClick={onItemClick}
      onItemCheckChanged={onItemCheckChanged}
      isChecked={isChecked}
      selectedRows={selectedRows}
      handleSubmit={handleSubmit}
    />
  );
};

export default NetworksByClient;
