import { twMerge } from "tailwind-merge";
import SalesCRMContacts from "containers/sales/crm/Contacts";

import AgenciesContainer from "containers/sales/crm/Agencies";
import SalesCRMNetworks from "containers/sales/crm/Networks";
import { useNavigate, useSearchParams } from "react-router-dom";

const ContactTabs = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { "tab-id": tabId = "contacts" } = Object.fromEntries([...params]);
  const tabs = [
    { id: 1, name: "Contacts", key: "contacts" },
    { id: 2, name: "Agencies", key: "agencies" },
    { id: 3, name: "Networks", key: "networks" },
  ];

  return (
    <div className="border-b border-gray-200 mt-4">
      <nav className="flex space-x-8" aria-label="Tabs">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            onClick={() => {
              const url = new URL(window.location.href);
              url.searchParams.set("tab-id", tab.key);
              navigate(url.search);
            }}
            className={twMerge(
              "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",
              tabId === tab.key && "border-indigo-500 text-indigo-600"
            )}
            aria-current={tab.key === tabId ? "page" : undefined}
          >
            {tab.name}
          </button>
        ))}
      </nav>

      {tabId === "contacts" && <SalesCRMContacts />}
      {tabId === "agencies" && <AgenciesContainer />}
      {tabId === "networks" && <SalesCRMNetworks />}
    </div>
  );
};

export default ContactTabs;
