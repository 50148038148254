import getAffiliateNetworks from "redux/slices/app/getAffiliateNetworks";
import createNetwork from "redux/slices/app/createNetwork";
import getNetworks from "redux/slices/app/getNetworks";
import syncPrograms from "redux/slices/app/syncPrograms";

const networkReducers = {
  affiliateNetworks: getAffiliateNetworks,
  createNetwork: createNetwork,
  networks: getNetworks,
  syncPrograms,
};

export default networkReducers;
