import { Column } from "types/column";
import DefaultCell from "./DefaultCell";
import React, { Fragment } from "react";
import components from "..";
import classNames from "classnames";
import Spinner from "./Spinner";
import { ITEMS_PER_PAGE } from "constants/misc";
import Pagination from "./Pagination";
import EmptyState from "../EmptyState";
import Thead from "./Thead";
import { useNavigate } from "react-router-dom";
import useOrdering from "hooks/useOrdering";

export interface TableRow<T = Record<string, any>> {
  row: T;
}
export type RowRenderer<T = Record<string, any>> = (
  r: T,
  index?: number
) => JSX.Element;

type Props = {
  columns: Column[];
  rows?: any;
  header?: JSX.Element;
  rowRenderer?: RowRenderer;
  footer?: JSX.Element | null;
  loading?: boolean;
  onEmptyActionClick?: () => void;
  emptyStateDescription?: string;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  totalCount?: number;
  pageSize?: number;
  renderHeader?: boolean;
  columnProps?: Record<string, any>;
  enableShowAll?: boolean;
  onShowAll?: () => void;
  currentPageCount?: number;
  disableHScroll?: boolean;
};

function DataTable({
  columns,
  rows = [],
  loading,
  header,
  footer,
  onShowAll,
  rowRenderer,
  emptyStateDescription,
  currentPage,
  totalCount,
  onEmptyActionClick,
  onPageChange,
  pageSize = ITEMS_PER_PAGE,
  renderHeader,
  columnProps,
  enableShowAll,
  currentPageCount = rows.length,
  disableHScroll,
}: Props) {
  const navigate = useNavigate();
  const handleOrdering = useOrdering("", navigate);

  const sortTable = (col: Column) => {
    handleOrdering(col.key);
  };

  const isEmpty =
    (!loading && rows.length === 0) ||
    (!loading && Object.values(rows[0]).length === 0);

  const isLoading = loading && rows?.[0] && Object.values(rows[0]).length === 0;

  if ((loading && !rows.length) || isLoading)
    return (
      <div className="mt-8">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
          {header}
          <Spinner />
        </div>
      </div>
    );

  return (
    <Fragment>
      <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
        {header}
        {isEmpty && !renderHeader && (
          <div className="w-full flex  h-48 items-center justify-between">
            <EmptyState
              title="No items"
              actionText={emptyStateDescription}
              onActionClick={onEmptyActionClick}
            />
          </div>
        )}

        <div className={!disableHScroll ? "overflow-y-auto" : ""}>
          <table className="w-full text-sm text-left text-gray-500">
            {(rows.length > 0 || renderHeader) && (
              <Thead
                columns={columns}
                sortTable={sortTable}
                columnProps={columnProps}
              />
            )}
            <tbody>
              {rows?.map((item: Record<string, any>, index: number) => {
                if (typeof rowRenderer === "function" && item) {
                  return rowRenderer(item, index);
                }
                const renderColumn = (column: Column) => (
                  <DefaultCell
                    key={column.key + index}
                    row={item}
                    column={column}
                  />
                );

                return (
                  <tr
                    className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                    key={index}
                  >
                    {columns.map((col) => {
                      const pageNumClass = classNames({
                        "px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white":
                          true,
                      });

                      return (
                        <td className={pageNumClass} key={col.key}>
                          {col.component
                            ? React.createElement(components[col.component])
                            : renderColumn(col)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {!loading && (
        <Fragment>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <Pagination
              siblingCount={3}
              onShowAll={onShowAll}
              currentPageCount={currentPageCount || rows.length}
              className="pagination-bar"
              currentPage={currentPage || 1}
              totalCount={totalCount || 1}
              pageSize={pageSize}
              enableShowAll={enableShowAll}
              onPageChange={(page) => {
                onPageChange?.(page);
              }}
            />
            {footer}
          </nav>
        </Fragment>
      )}
    </Fragment>
  );
}

export default DataTable;
