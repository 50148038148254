import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getLocationSuggestions from "redux/thunks/marketing/sem/getLocationSuggestions";
import { SEMLocationSuggestion } from "types/data";

export type ActionStateType = {
  data: { [k: string]: Array<SEMLocationSuggestion> };
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    google: [],
    bing: [],
  },
  loading: false,
  error: null,
};

const getSEMLocationSuggestions = createSlice({
  name: "links",
  initialState,
  reducers: {
    resetLocationSuggestions: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocationSuggestions.pending, (state) => {
        return {
          ...state,
          error: null,
          data: {
            google: [],
            bing: [],
          },
          loading: true,
        };
      })
      .addCase(
        getLocationSuggestions.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                ...payload,
              },
              loading: false,
            };
          }
        }
      )

      .addCase(
        getLocationSuggestions.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetLocationSuggestions } = getSEMLocationSuggestions.actions;
export default getSEMLocationSuggestions.reducer;
