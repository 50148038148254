/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getAutoCouponTemplates from "redux/thunks/app/api-clients/getAutoCouponTemplates";
import { ActionState } from "types/data";
import { CouponTemplate } from "types/model/oma/CouponTemplate";

const initialState: ActionState<CouponTemplate[]> = {
  data: [],
  loading: false,
  error: null,
};

const getAutoCouponTemplatesSlice = createSlice({
  name: "autoCouponTemplates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAutoCouponTemplates.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getAutoCouponTemplates.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getAutoCouponTemplates.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getAutoCouponTemplatesSlice.reducer;
