import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import axios from "helpers/axios";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";

export default createAsyncThunk(
  "sem-postCampaignNotes",
  async (payload: Record<string, any>, ThunkAPI) => {
    const uploaded = [];
    try {
      for (const v of payload.files) {
        const s3 = new S3({
          ...defaultS3Config,
          dirName: `img/${localStorage.getItem(
            LOCAL_KEY_SELECTED_CLIENT_ID
          )}/campaign_notes`,
        });
        const result = await s3.uploadFile(v);
        const { location } = result;
        uploaded.push(location);
      }

      const response = await axios.post(`/admin/sem/activity-log/`, {
        ...payload,
        file_urls: uploaded,
      });
      return response.data;
    } catch (error: any) {
      console.log("error", error);
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
