import { BuildingOfficeIcon } from "@heroicons/react/20/solid";
import useLoadAuth from "hooks/useLoadAuth";
import greet from "utils/dates/greet";
import Thumbnail from "./Initials";
import getWelcomeMessage from "../utils/getMessages";

function ManagerHeader() {
  const { user } = useLoadAuth();

  return (
    <div className="bg-white shadow-xl">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex items-center">
              {user?.image_url ? (
                <img
                  className="hidden h-16 w-16 rounded-full sm:block"
                  src={user?.image_url}
                  alt=""
                />
              ) : (
                <Thumbnail name={`${user?.first_name} ${user?.last_name}`} />
              )}
              <div>
                <div className="flex items-center">
                  <img
                    className="h-16 w-16 rounded-full sm:hidden"
                    src={user?.image_url}
                    alt=""
                  />
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    {greet()}, {user?.first_name}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                    <BuildingOfficeIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400 "
                      aria-hidden="true"
                    />
                    {getWelcomeMessage()}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagerHeader;
