import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sem/searchAdCampaigns",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/sem/search-ad-campaigns?search_text=${payload.search_text}&add_meta_data=${payload.add_meta_data}`,
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
