import getActiveCoupons from "redux/slices/clients/coupons/getActiveCoupons";
import createUpdateCoupon from "redux/slices/clients/coupons/createUpdateCoupon";
import searchActiveCoupons from "redux/slices/clients/coupons/searchActiveCoupons";
import deleteCoupons from "redux/slices/clients/deleteCoupons";
import getCouponOfferTypes from "redux/slices/clients/getCouponOfferTypes";
import createAutoCouponTemplate from "redux/slices/clients/createAutoCouponTemplate";
import getAutoCouponTemplates from "redux/slices/clients/getAutoCouponTemplates";
import updateDeleteAutoCouponTemplate from "redux/slices/clients/updateDeleteAutoCouponTemplate";

const couponReducers = {
  createUpdateCoupon,
  getActiveCoupons,
  searchActiveCoupons,
  deleteCoupons,
  getCouponOfferTypes,
  createAutoCouponTemplate,
  getAutoCouponTemplates,
  updateDeleteAutoCouponTemplate,
};

export default couponReducers;
