import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import completeNewsLetter from "redux/thunks/links/newsletters/completeStoreNewsletter";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const completeNewsLetterSlice = createSlice({
  name: "completeNewsLetter",
  initialState,
  reducers: {
    resetCompleteNewsLetter: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(completeNewsLetter.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        completeNewsLetter.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        completeNewsLetter.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetCompleteNewsLetter } = completeNewsLetterSlice.actions;
export default completeNewsLetterSlice.reducer;
