import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";

type Params = {
  page: number;
  client?: null | string;
  ignoreForClient?: boolean;
};

export default createAsyncThunk(
  "advertisers/-deactivated-network-advertisers",
  async ({ page, ignoreForClient }: Params, ThunkAPI) => {
    const url = new URL(
      `${BACKEND_BASE_URL}/admin/advertisers/network-advertisers/status-changes`,
    );
    const client_id = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID);
    if (client_id && !ignoreForClient) {
      url.searchParams.set("client_id", client_id);
    }
    url.searchParams.set("offset", String(getOffsetForPage(Number(page))));

    try {
      const response = await axios.get(
        url.pathname
          .replace("/api", "")
          .replace("/production", "")
          .replace("/dev", "") + url.search,
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
