import { FormEventHandler } from "react";
import { twMerge } from "tailwind-merge";
import Spinner from "./layout/Spinner";

type Props = {
  className?: string;
  icon?: JSX.Element;
  text?: string;
  onClick?: FormEventHandler;
  loading?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  showSpinner?: boolean;
  spinnerClassName?: string;
  title?: string;
  variant?: "primary" | "danger" | "success" | "warn" | "default";
};

const Button = ({
  className,
  onClick,
  disabled,
  loading,
  icon,
  text,
  type,
  showSpinner,
  spinnerClassName,
  title,
  variant = "primary",
}: Props) => {
  const wrapperClass = twMerge(
    `my-4 items-center justify-center text-white  focus:ring-4  font-medium rounded-lg text-sm px-4 py-2  focus:outline-none"
       `,
    disabled && "opacity-50 cursor-not-allowed",
    variant == "primary" &&
      "bg-primary-700 hover:bg-primary-800 focus:ring-primary-300",
    variant == "success" &&
      "bg-green-400 hover:bg-green-500 focus:ring-green-300",
    variant == "danger" && "bg-red-700 hover:bg-red-800 focus:ring-red-300",
    variant == "default" &&
      "bg-gray-300 hover:bg-gray-400 focus:ring-gray-300 text-black",
    variant == "warn" &&
      "bg-yellow-700 hover:bg-yellow-800 focus:ring-yellow-300",
    loading && "opacity-50 cursor-not-allowed",
    className
  );

  if (showSpinner && loading) {
    return (
      <div
        className={twMerge(
          "my-4 items-center justify-center text-white focus:ring-4focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2  focus:outline-none",
          className
        )}
      >
        <Spinner className={spinnerClassName} />;
      </div>
    );
  }
  return (
    <button
      title={title}
      type={type || "button"}
      disabled={disabled || loading}
      className={wrapperClass}
      onClick={onClick}
    >
      {icon && icon}
      {!loading ? text : ""}
      {loading && <Spinner className={"h-6 w-6"} />}
    </button>
  );
};

export default Button;
