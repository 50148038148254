import React from "react";
import { twMerge } from "tailwind-merge";
import formatNumber from "utils/strings/formatNumber";
import TotalSaleAmounts from "./TotalSaleAmounts";
import TotalCommissionAmounts from "./TotalCommissionAmounts";

type Props = {
  item: Record<string, any>;
  index: number;
  totals: Record<string, any>;
};

function TransactionReportRow({ item, index, totals }: Props) {
  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 cursor-pointer",
    index === 0 && "bg-green-100 font-bold"
  );
  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {item.network?.network}
        </th>
        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.client?.name}
        </th>
        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.client_location?.toUpperCase()}
        </th>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals.total_transactions, {})
            : formatNumber(item.tx_count, {})}
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0 ? (
            <TotalSaleAmounts totals={totals} />
          ) : (
            <TotalSaleAmounts totals={item} />
          )}
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index == 0
            ? formatNumber(totals?.total_sale_amount_usd / 1000, {
                prefix: "$",
                decimals: 2,
              })
            : formatNumber(item?.sale_amount_total / 1000, {
                prefix: "$",
                decimals: 2,
              })}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0 ? (
            <TotalCommissionAmounts totals={totals} />
          ) : (
            <TotalCommissionAmounts totals={item} />
          )}
        </td>
        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {index == 0
            ? formatNumber(totals?.total_commission_usd / 1000, {
                prefix: "$",
                decimals: 2,
              })
            : formatNumber(item?.commission_total / 1000, {
                decimals: 2,
                prefix: "$",
              })}
        </th>
      </tr>
    </React.Fragment>
  );
}

export default TransactionReportRow;
