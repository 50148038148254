import { Column } from "types/column";

const EMAIL_CAMPAIGN_COLUMNS = [
  "Date",
  "Type",
  "Commission Value (USD)",
  "Store Name",
  "#Receivers",
  "Opens",
  "Opens(Unverified)",
  "Open Rate",
  "Clicks",
  "Clicks(Unverified)",
  "ClickRate",
  "#Unsubscribes",
  "Coupon ID",
  "Coupon Headline",
  "Has Code",
  "",
].map((el) => ({
  key: el,
  label: el,
})) as Column[];

export default EMAIL_CAMPAIGN_COLUMNS;
