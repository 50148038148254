import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sem/get-campaigns",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/sem/ad-campaign?program_slug=${payload.program_slug}`,
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
