import paginatedItemsInitialState from "redux/initial-states/paginatedItemsInitialState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCompetitors from "redux/thunks/marketing/sem/getCompetitors";
import { PaginatedActionState, PaginatedData } from "types/data";

const initialState: PaginatedActionState<Record<string, any>> =
  paginatedItemsInitialState;

export type ActionStateType = {
  data: PaginatedData<Record<string, any>>;
  loading: boolean;
  error: Record<string, any> | null;
};

const getCompetitorsSlice = createSlice({
  name: "getCompetitors",
  initialState,
  reducers: {
    resetCompetitors: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompetitors.pending, (state) => {
        return {
          ...state,
          data: initialState.data,
          error: null,
          loading: true,
        };
      })
      .addCase(
        getCompetitors.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if (payload) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getCompetitors.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetCompetitors } = getCompetitorsSlice.actions;
export default getCompetitorsSlice.reducer;
