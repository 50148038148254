import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getTransactionsReport from "redux/thunks/reporting/revenue/getTransactionsReport";
import { PaginatedData } from "types/data";

export type TransactionReportActionStateType = {
  data: {
    report: PaginatedData<Record<string, any>>;
    totals: Record<string, any>;
  };
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: TransactionReportActionStateType = {
  data: {
    report: {
      meta: {
        count: 0,
        limit: 0,
        offset: 0,
      },
      items: [],
    },
    totals: {
      commission: 0,
      txn_total: 0,
      sale_amount: 0,
      total_sale_transactions: 0,
      total_returns: 0,
    },
  },
  loading: false,
  error: null,
};

const getTransactionsReportSlice = createSlice({
  name: "getTransactionsReportSlice",
  initialState,
  reducers: {
    resetTransactionsReport: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsReport.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getTransactionsReport.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                report: payload["report"],
                totals: payload["totals"],
              },
              loading: false,
            };
          }
        }
      )
      .addCase(
        getTransactionsReport.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetTransactionsReport } = getTransactionsReportSlice.actions;
export default getTransactionsReportSlice.reducer;
