import { ChangeEventHandler, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import { useLocation, useParams } from "react-router-dom";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import OMACategoryContentLayout from "layout/oma/OMACategoryContent";
import getCategory from "redux/thunks/app/api-clients/categories/getCategory";
import createUpdateCategory from "redux/thunks/app/api-clients/categories/createUpdateCategory";
import toast from "react-hot-toast";

const CategoryContent = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const [form, setForm] = useState<Record<string, any>>({});

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  const onSubmit = () => {
    dispatch(
      createUpdateCategory({
        data: {
          ...form.category,
          content: {
            short_form: form.short_form,
            long_form: form.long_form,
          },
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus == "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  const {
    getCategory: { loading, data, error },
    createUpdateCategory: { loading: saving },
  } = useAppSelector(({ getCategory, createUpdateCategory }) => ({
    getCategory,
    createUpdateCategory,
  }));

  useEffect(() => {
    dispatch(getCategories({}));
  }, [localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)]);

  useEffect(() => {
    if (state?.content) {
      setForm(state.content);
    } else {
      dispatch(
        getCategory({
          id,
        })
      );
    }
  }, [state]);

  useEffect(() => {
    if (data?.id) {
      setForm((form) => ({ ...form, ...data.content }));
    }
  }, [data?.id]);

  return (
    <OMACategoryContentLayout
      onChange={onChange}
      loading={loading}
      form={form}
      error={error}
      onSubmit={onSubmit}
      saving={saving}
    />
  );
};

export default CategoryContent;
