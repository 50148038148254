import Spinner from "components/common/layout/Spinner";
import AdKeywordContainer from "containers/marketing/search/AdKeywordContainer";
import { ActionState, AdKeyword } from "types/data";

type Props = {
  getKeywordsData: ActionState<AdKeyword[]>;
};

const EditAdKeywords = ({ getKeywordsData }: Props) => {
  return (
    <div className="flex">
      <div className="w-1/2">
        {!getKeywordsData.loading && getKeywordsData.data.length > 0 && (
          <div className="mb-4">
            <h2>Edit Keywords</h2>
          </div>
        )}

        {getKeywordsData.loading && getKeywordsData.data.length === 0 && (
          <Spinner />
        )}
        {getKeywordsData.data.map((el) => (
          <AdKeywordContainer item={el} key={el.id} />
        ))}
      </div>
      <div className="extra"></div>
    </div>
  );
};

export default EditAdKeywords;
