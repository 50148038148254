import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import TODO_COLUMNS from "constants/tables/headers/todos/todos";
import { PaginatedActionState } from "types/data";
import { TodoItem } from "types/model/Todo";
import formatDate from "utils/dates/formatDate";

type Props = {
  handleComplete: (item: TodoItem) => void;
  todoItems: PaginatedActionState<TodoItem>;
  onFilterChange: (value: string) => void;
  completed: string;
};

const TodoLayout = ({
  handleComplete,
  onFilterChange,
  todoItems,
  completed,
}: Props) => {
  const renderTodos: RowRenderer<TodoItem> = (todo) => {
    return (
      <tr key={todo.id}>
        <td className="py-2 px-4">{formatDate(todo.created_at)}</td>
        <td>{todo.todo_type.name}</td>
        <td className="py-2 px-4">
          {(todo.created_by?.first_name || "") +
            " " +
            (todo.created_by?.last_name || "")}
        </td>
        <td className="py-2 px-4">
          {todo.program?.name ||
            todo.program?.map((el: Record<string, any>) => el.name).join(", ")}
        </td>
        <td className="py-2 px-4">
          {todo.program?.network ||
            todo.program?.[0]?.primary_network?.name ||
            ""}
        </td>
        <td className="py-2 px-4">{todo.notes}</td>
        <td className="py-2 px-4">
          {todo.completed_at ? formatDate(todo.completed_at) : ""}
        </td>
        <td className="py-2 px-4">
          {todo.completed ? null : (
            <div className="relative">
              <div className=" mt-2 w-32 bg-white border rounded shadow-md">
                <button
                  onClick={() => handleComplete(todo)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Mark as Completed
                </button>
              </div>
            </div>
          )}
        </td>
      </tr>
    );
  };

  return (
    // <Layout>
    <div className="container mx-auto my-8">
      {/* <h1 className="text-2xl font-bold mb-4">Todo List</h1> */}
      <div className="overflow-x-auto">
        <DataTable
          columns={TODO_COLUMNS}
          loading={todoItems.loading}
          currentPage={1}
          header={
            <div className="flex gap-4 items-center my-4">
              <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
                Show only:{" "}
              </p>
              {["Pending", "Completed"].map((it, idx) => (
                <div className="flex items-center  mr-4" key={it}>
                  <input
                    id="all-tasks"
                    type="radio"
                    value={it}
                    defaultChecked={completed === String(idx)}
                    onChange={(e) => {
                      onFilterChange(
                        e.target.checked && it === "Completed" ? "1" : "0"
                      );
                    }}
                    name="network_status"
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="all-tasks"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {it}
                  </label>
                </div>
              ))}
            </div>
          }
          totalCount={todoItems.data.meta.count}
          rows={todoItems.data.items}
          rowRenderer={renderTodos as RowRenderer<Record<string, any>>}
        />
      </div>
    </div>
    // </Layout>
  );
};

export default TodoLayout;
