import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";

export default createAsyncThunk(
  "sem/getCompetitorPrograms",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/sem/competitor-programs/?offset=${getOffsetForPage(
          payload.page || 1
        )}&has_campaign=${payload.has_campaign}&competitor=${
          payload.competitor || ""
        }&search=${payload.search}&program_match=${payload.program_match || ""}`
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
