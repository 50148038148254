import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk("users/platform-user", async (_, ThunkAPI) => {
  try {
    const response = await axios.get(`/admin/users/admin/me`);
    return response.data;
  } catch (error: any) {
    return ThunkAPI.rejectWithValue(
      error.response?.data || {
        message: "Could not fetch",
      },
    );
  }
});
