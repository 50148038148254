type Props = {
  row: Record<string, any>;
};

const CategoryCouponRow = ({ row }: Props) => {
  return (
    <tr>
      <td className="">
        <img
          src={row.store.logo_url}
          className="ml-2"
          alt="store logo"
          height={59}
          width={70}
        />
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.store.name}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.store.primary_network?.name}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.id}
      </td>

      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.title}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.description}
      </td>

      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.discount_code}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.expiration_date}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <button
          onClick={() => {
            //
          }}
          className="text-indigo-600 hover:text-indigo-900"
        >
          Manage
        </button>
      </td>
    </tr>
  );
};

export default CategoryCouponRow;
