import React, { ChangeEvent, ChangeEventHandler, useState } from "react";
import Input, { AppTextInputProps } from "./Input";
import Dropdown from "./Dropdown";

interface Props extends AppTextInputProps {
  // options: Option[];
  typeLabel?: string;
  onTypeChange: ChangeEventHandler<HTMLInputElement>;
  discount_type?: string;
}

const DiscountInput = ({
  typeLabel,
  discount_type,
  onTypeChange,
  ...props
}: Props) => {
  const [form, setForm] = useState<Record<string, any>>({ type: "Percent" });

  return (
    <div className="flex items-center gap-4 w-96">
      <Dropdown
        wrapperClassName="flex-1"
        label={typeLabel || "Discount Type"}
        options={["Percent", "Amount"].map((el) => ({ label: el, value: el }))}
        name="type"
        value={discount_type}
        onChange={(e) => {
          setForm((f) => ({ ...f, type: e.target.value }));

          onTypeChange({
            ...e,
            target: {
              ...e.target,
              name: "discount_type",
              value: e.target.value,
            },
          } as ChangeEvent<HTMLInputElement>);

          props?.onChange?.({
            ...e,
            target: {
              ...e.target,
              name: "offer_discount",
              value: "0",
            },
          } as ChangeEvent<HTMLInputElement>);
        }}
      />
      <div className="flex-1">
        {form.type === "Percent" ? (
          <Input label="Percent" name="discount_value" {...props} />
        ) : (
          <Input
            label="Amount"
            name="discount_value"
            type="number"
            className="px-5 bg"
            {...props}
          />
        )}
      </div>
    </div>
  );
};

export default DiscountInput;
