import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sem/getAutoPausedCampaigns",
  async (_, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/sem/auto-paused-campaign-programs`
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
