const reportItem = {
  id: "1x18267",
  network_advertiser: {
    name: "",
    network: "",
    website_url: "",
  },
  aquisition: {
    medium: "",
    search_engine: "",
    query_str: "",
  },
  client: {
    id: 8,
    name: "Totals",
    website_url: null,
  },
  external_id: "",
  sid: "",
  client_location: "",
  device_type: "",
  action_date: "",
  sale_date: "",
  sale_exchange_rate: "",
  commission_currency: "",

  sale_currency: "",
  order_id: "",
  coupon_code: "",
  status: "",
  transaction_type: "",
  commission_type: "",
  commission_key: "",
  reason: "",
  created_at: "",
  updated_at: "",
  website_id: "",
  website_name: "",
  link: "",
  country: "",
};

export default reportItem;
