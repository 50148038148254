import { status } from "constants/googleAdCampaignStatuses";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import CampaignDetailsTabLayout from "layout/marketing/CampaignDetailsTabLayout";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import getAdsByAdgroup from "redux/thunks/marketing/sem/getAdsByAdgroup";
import getCampaigns from "redux/thunks/marketing/sem/getCampaigns";
import getKeywordsByAdgroup from "redux/thunks/marketing/sem/getKeywordsByAdgroup";
import updateCampaign from "redux/thunks/marketing/sem/updateCampaign";

const CampaignDetail = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const { data: getAdCampaignsData } = useAppSelector(
    (state) => state.getAdCampaigns,
  );

  const [form, setForm] = useState<Record<string, any>>({});

  useEffect(() => {
    if (getAdCampaignsData.length === 0 && params.get("slug")) {
      dispatch(getCampaigns({ program_slug: params.get("slug") }));
    }
  }, []);

  useEffect(() => {
    dispatch(
      getAdsByAdgroup({
        ad_group_id: params.get("ad_group_id"),
        search_engine: params.get("search_engine"),
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      getKeywordsByAdgroup({
        ad_group_id: params.get("ad_group_id"),
        search_engine: params.get("search_engine"),
      }),
    );
  }, []);

  useEffect(() => {
    if (getAdCampaignsData.length) {
      const campaign = getAdCampaignsData.find(
        (it) => String(it.id) === params.get("campaign_id"),
      );

      if (campaign) {
        setForm({
          ...campaign,
          campaign_name: campaign.name,
          campaign_status: campaign.status,
          campaign_id: campaign.id,
          campaign_ad_group_id: campaign.ad_group_id,
        });
      }
    }
  }, [getAdCampaignsData]);

  const debounceFn = (v: string) => {
    dispatch(
      updateCampaign({
        ...form,
        campaign_status:
          form.search_engine === "google" ? status[v.toUpperCase()] : v,
        action: "update-campaign-status",
      }),
    );
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({
      ...f,
      [e.target.name]: e.target.value,
    }));

    debounceFn(e.target.value);
  };

  return <CampaignDetailsTabLayout form={form} onChange={onChange} />;
};

export default CampaignDetail;
