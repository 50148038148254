import { Column } from "types/column";

export const PENDING_LEAD_COLUMNS = [
  "Date",
  "Program Name",
  "Program Website URL",
  "Network	Tier Group",
  "Primary Action Requested",
  "Additional Notes",
].map((el) => ({ isSortable: false, key: el, label: el })) as Column[];

export const ASSIGNED_LEAD_COLUMNS = [
  "Date",
  "Assigned Date",
  "Program Name",
  "Program Website URL",
  "Network",
  "Network	Tier Group",
  "Primary Action Requested",
  "Lead Created by",
  "Lead Taken by",
  "Additional Notes",
  "",
  "",
].map((el) => ({ isSortable: false, key: el, label: el })) as Column[];

export const LEAD_REQUEST_COLUMNS = [
  "Requested Date",
  "Program Name",
  "Program Website URL",
  "Network",
  "Network	Tier Group",
  "Requested by",
  "Additional Notes",
  "",
].map((el) => ({ isSortable: false, key: el, label: el })) as Column[];
