import {
  WEBSITE_CLIENT_NAMES,
  websitesIdNameMap,
} from "./../clientWebsitesIds";

type ClientPlacementPositionCountMap = {
  [key: string]: {
    [key: string]: number;
  };
};

const clientPlacementPositionCountMap: ClientPlacementPositionCountMap = {};

const defaultPositionCounts = {
  "Hero Coupons": 9,
  "Coupon Listings": 30,
  "Coupons from Seasonal Categories": 12,
  "New Stores with Coupons": 9,
  "Navigation Bar - Featured Store Listing": 8,
  "Popular Brands - Store Logo Placement": 6,
  "Trending Coupon Codes and Promo Codes": 12,
  "Featured Coupon": 1,
  "Top Stores - Logo Placement": 5,
};

Object.entries(websitesIdNameMap).forEach(([k, v]) => {
  if (v != WEBSITE_CLIENT_NAMES.DISCOUNT_CODES_COM) {
    clientPlacementPositionCountMap[k] = defaultPositionCounts;
  } else {
    clientPlacementPositionCountMap[k] = {
      "Hero Coupons": 4,
      "Coupon Listings": 10,
      "Just Came In": 1,
    };
  }
});

export default clientPlacementPositionCountMap;
