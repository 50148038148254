import { capitalize } from "lodash";
import React from "react";
import toPascalCase from "utils/strings/toPascalCase";

type Props = {
  title: string;
  rows: Record<string, any>;
};

const CardResultSection = ({ title, rows }: Props) => {
  return (
    <div className="px-4 py-2">
      <h1>{title}</h1>

      {Object.entries(rows)
        .filter(([_, v]) => v > 0)
        .map(([k, v]) => (
          <dl
            className=" divide-y divide-gray-100 px-2 text-sm leading-6"
            key={k}
          >
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">{toPascalCase(capitalize(k))}</dt>
              <span>{String(v)}</span>
            </div>
          </dl>
        ))}
    </div>
  );
};

export default CardResultSection;
