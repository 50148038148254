import React, { useEffect } from "react";
import OMACategoryDetail from "layout/oma/OMACategorydetail";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getCategory from "redux/thunks/app/api-clients/categories/getCategory";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";

function Category() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const categoryData = useAppSelector((state) => state.getCategory);
  const couponsData = useAppSelector((state) => state.getActiveCoupons);

  useEffect(() => {
    if (!state && params["id"]) {
      dispatch(getCategory({ id: params["id"] }));
    }
  }, []);

  useEffect(() => {
    if (params["slug"]) {
      dispatch(
        getActiveCoupons({
          filter: "category",
          page: 1,
          store_slug: params["slug"],
        })
      );
    }
  }, [params]);

  return (
    <OMACategoryDetail
      couponsData={couponsData}
      categoryData={{
        ...categoryData,
        data: {
          ...state,
          ...categoryData.data,
        },
      }}
    />
  );
}

export default Category;
