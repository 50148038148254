import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

type Params = {
  search_text: string;
  search_engine: string;
};

export default createAsyncThunk(
  "sem/location-suggestions",
  async ({ search_text, search_engine }: Params, ThunkAPI) => {
    try {
      const response =
        await axios.get(`/admin/sem/location-search/${search_text}?search_engine=${search_engine}
`);
      return { [search_engine]: response.data };
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
