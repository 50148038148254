import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import Spinner from "./layout/Spinner";
import { twMerge } from "tailwind-merge";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export type PopUnderOption = {
  title: string;
  current: boolean;
  visible: boolean;
  displayValue?: string;
  onClick: () => void;
};

type Props = {
  publishingOptions: Array<PopUnderOption>;
  isLoading?: boolean;
};
export default function PopUnder({ publishingOptions, isLoading }: Props) {
  const [selected, setSelected] = useState(publishingOptions[0]);

  useEffect(() => {
    if (publishingOptions.length) {
      setSelected(
        publishingOptions.find((it) => it.current) || publishingOptions[0]
      );
    }
  }, [publishingOptions.length]);
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }: { open: boolean }) => (
        <>
          <div className="relative ">
            <div className="inline-flex divide-x border border-gray-100 divide-gray-100 rounded-md shadow-sm">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-white py-2 px-3  shadow-sm">
                {isLoading && <Spinner className="h-4 w-4" />}
                {!isLoading && (
                  <p className="text-sm font-semibold">
                    {selected?.displayValue || "Action"}
                  </p>
                )}
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-white p-2  focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-gray-50">
                <span className="sr-only">Change published status</span>
                <ChevronDownIcon className="h-5 w-5 " aria-hidden="true" />
              </Listbox.Button>
            </div>
            {!isLoading && (
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="relative">
                  <Listbox.Options className="absolute right-0 z-50 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {publishingOptions
                      .filter((it) => !!it.visible)
                      .map((option) => (
                        <Listbox.Option
                          key={option.title}
                          onClick={option.onClick}
                          className={({ active }: any) =>
                            classNames(
                              active ? "bg-white " : "text-gray-900",
                              "cursor-default select-none p-4 text-sm"
                            )
                          }
                          value={option}
                        >
                          {({ active }: any) => (
                            <div className="flex flex-col">
                              <div className="flex justify-between">
                                <p
                                  className={twMerge(
                                    "cursor-pointer font-normal",
                                    selected && "font-semibold"
                                  )}
                                >
                                  {option.title}
                                </p>
                                {selected.title === option.title ? (
                                  <span
                                    className={twMerge(
                                      selected.title === option.title &&
                                        "text-indigo-600"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </div>
              </Transition>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
}
