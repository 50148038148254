import React from "react";

type Props = {
  firstCommissionConfig: Record<string, any>;
};

function CommissionDisplay({ firstCommissionConfig }: Props) {
  return (
    <div className="flex  flex-1 items-center">
      {/*<PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />*/}

      {firstCommissionConfig?.flat && (
        <span className="">
          {firstCommissionConfig?.currency} {firstCommissionConfig?.flat}
        </span>
      )}
      {firstCommissionConfig?.percentage && (
        <span className="">
          {firstCommissionConfig?.percentage?.replace?.("%", "")}%
        </span>
      )}
      <span className="ml-1 flex-1">
        {firstCommissionConfig?.name?.replace?.(/[^\w\s]/gi, " ")}
      </span>
    </div>
  );
}

export default CommissionDisplay;
