import CouponSpotlightsRow from "components/campaigns/CouponSpotlightsRow";
import Button from "components/common/Button";
import Layout from "components/layout/Layout";
import SearchCoupons from "containers/oma/SearchCoupons";
import SearchPrograms from "containers/oma/SearchPrograms";
import { twMerge } from "tailwind-merge";

type Props = {
  data: Array<Record<string, any>>;
  form: Record<string, any>;
  onSearchMerchantSelected: (result: Record<string, any>) => void;
  onSearchCouponSelected: (result: Record<string, any>) => void;
  handleRemoveSpotlight: (result: Record<string, any>) => void;
  handleAddNewItem: () => void;
  handlePersistUpdates: () => void;
  saving: boolean;
  handleUpdateItem: (
    oldRow: Record<string, any>,
    newForm: Record<string, any>,
  ) => void;
};

export default function CampaignsHomeHeroes({
  data,
  form,
  onSearchMerchantSelected,
  onSearchCouponSelected,
  handleAddNewItem,
  saving,
  handleUpdateItem,
  handleRemoveSpotlight,
  handlePersistUpdates,
}: Props) {
  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Paid Campaigns
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage the paid placement campaigns across all available Ad
              Placements.
            </p>

            <div className="flex items-center gap-4 mt-8">
              <div>
                <SearchPrograms
                  wrapperClassName="mx-0"
                  value={form.store?.name}
                  onResultClicked={onSearchMerchantSelected}
                />
              </div>
              <SearchCoupons
                wrapperClassName="mx-0"
                value={form.coupon?.title}
                store={form.store}
                onResultClicked={onSearchCouponSelected}
              />

              <Button
                disabled={!form.store && !form.coupon}
                onClick={handleAddNewItem}
                text="Add"
                className={twMerge(
                  "",
                  form.store &&
                    form.coupon &&
                    "bg-blue-600 text-white hover:bg-blue-700",
                )}
              />
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Program Name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Affiliate Network
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Coupon ID
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Image
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Coupon Title
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Ad Slogan
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Run Dates
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Manage</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((el) => (
                      <CouponSpotlightsRow
                        handleUpdateItem={handleUpdateItem}
                        row={el}
                        key={el.id}
                        handleRemoveSpotlight={handleRemoveSpotlight}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full flex items-end justify-end mt-4">
                <Button
                  text="Save changes"
                  loading={saving}
                  className="bg-indigo-600 text-white"
                  onClick={handlePersistUpdates}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
