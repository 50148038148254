import getLocationSuggestions from "redux/slices/marketing/sem/getLocationSuggestions";
import createCampaign from "redux/slices/marketing/sem/createCampaign";
import createCampaignNote from "redux/slices/marketing/sem/createCampaignNote";
import getAdCampaigns from "redux/slices/marketing/sem/getCampaigns";
import updateAdGroup from "redux/slices/marketing/sem/updateAdgroup";
import getAd from "redux/slices/marketing/sem/getAd";
import getAdKeywords from "redux/slices/marketing/sem/getAdKeywords";
import getCampaignReports from "redux/slices/marketing/sem/getCampaignReports";
import getAdCampaignNotes from "redux/slices/marketing/sem/getCampaignNotes";
import searchAdCampaigns from "redux/slices/marketing/sem/searchAdCampaigns";
import getAutoPausedCampaigns from "redux/slices/marketing/sem/getAutoPausedCampaigns";
import getPPCMarginRevenueTrend from "redux/slices/marketing/sem/getPPCMarginRevenueTrend";

const semReducers = {
  createSearchCampaign: createCampaign,
  createCampaignNote,
  getLocationSuggestions,
  updateAdGroup,
  getAdCampaigns,
  getAd,
  getAdKeywords,
  getCampaignReports,
  getAdCampaignNotes,
  searchAdCampaigns,
  getAutoPausedCampaigns,
  getPPCMarginRevenueTrend,
};
export default semReducers;
