import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import React, { ChangeEventHandler } from "react";

type Props = {
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const Stats = ({ form, onChange }: Props) => {
  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      <div className="flex flex-col sm:flex-row items-center gap-2 ">
        <div className="w-full sm:w-1/3">
          <Input
            label="Average order size"
            onChange={onChange}
            name="average_order_size"
            type="number"
            value={form?.average_order_size}
          />
        </div>

        <div className="w-full sm:w-1/4">
          <Dropdown
            label="Average savings type"
            onChange={onChange}
            name="average_savings_type"
            value={form?.average_savings_type}
            options={["$", "%"].map((it) => ({ label: it, value: it }))}
          />
        </div>

        <div className="w-full sm:w-1/3">
          <Input
            onChange={onChange}
            // error={errors.description}
            label="Average savings"
            name="average_savings"
            type="number"
            value={form?.average_savings}
          />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-center gap-2 mt-8 ">
        <div className="w-full sm:w-1/4">
          <Input
            label="Average rating"
            onChange={onChange}
            name="average_rating"
            type="number"
            value={form?.average_rating}
          />
        </div>

        <div className="w-full sm:w-1/4">
          <Input
            onChange={onChange}
            // error={errors.description}
            label="Number of Votes"
            type="number"
            name="voters_count"
            value={form?.voters_count}
          />
        </div>

        <div className="w-full sm:w-1/4">
          <Input
            onChange={onChange}
            // error={errors.description}
            label="Real Votes"
            name="real_voters_count"
            type="number"
            value={form?.real_voters_count}
          />
        </div>

        <div className="w-full sm:w-1/4">
          <Input
            label="Real Average rating"
            onChange={onChange}
            type="number"
            name="real_average_rating"
            value={form?.real_average_rating}
          />
        </div>
      </div>
    </section>
  );
};

export default Stats;
