import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createLead from "redux/thunks/sales_crm/createLead";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const createLeadSlice = createSlice({
  name: "createLead",
  initialState,
  reducers: {
    resetLeads: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLead.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        createLead.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        createLead.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetLeads } = createLeadSlice.actions;
export default createLeadSlice.reducer;
