import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";
import { useNavigate, useSearchParams } from "react-router-dom";
import getUsers from "redux/thunks/users/getUsers";
import { User } from "types/model/User";
import { Option } from "types/option";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";
import OMAUserAddedComponent from "layout/oma/OMAUserAddedComponent";
import createUpdateCoupon from "redux/thunks/app/api-clients/createUpdateCoupon";
import toast from "react-hot-toast";
import deleteCoupons from "redux/thunks/app/api-clients/deleteCoupons";

const OMAUserAddedCoupons = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [networkOptions, setNetworkOptions] = useState<Option[]>([]);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState<Record<string, any>[]>([]);

  const {
    getActiveCoupons: { loading, data },
    getAllUsers: { data: getAllUserData },
    deleteCoupons: deleteCouponsData,
    affiliateNetworks,
    createUpdateCoupon: createUpdateCouponData,
  } = useAppSelector(
    ({
      getActiveCoupons,
      affiliateNetworks,
      deleteCoupons,
      getAllUsers,
      createUpdateCoupon,
    }) => ({
      getActiveCoupons,
      createUpdateCoupon,
      getAllUsers,
      affiliateNetworks,
      deleteCoupons,
    })
  );

  const page = params.has("page") ? Number(params.get("page")) : 1;
  const search_text = params.get("search_text") || "";
  const location = params.get("location") || "";
  const rank = params.get("rank") || "";
  const network = params.get("network") || "";

  const couponFilters = {
    page: search_text ? 1 : page,
    size: 50,
    search_text: search_text,
    rank: rank,
    location: location,
    network: network,
    user_added: "1",
    include_scheduled: "0",
  };

  useEffect(() => {
    dispatch(getActiveCoupons(couponFilters));
  }, [params, search_text]);

  useEffect(() => {
    if (createUpdateCouponData.data?.id) {
      dispatch(getActiveCoupons(couponFilters));
    }
  }, [createUpdateCouponData.data?.id]);

  useEffect(() => {
    if (affiliateNetworks.data.items.length) {
      const allAffiliateNetworks = affiliateNetworks.data.items.map((item) => ({
        label: item.name,
        value: item.network_id,
        selected: network === item.network_id,
      }));
      setNetworkOptions(allAffiliateNetworks);
    } else {
      dispatch(getAffiliateNetworks({ page: 1 }));
    }
  }, [affiliateNetworks.data.items.length]);

  useEffect(() => {
    if (getAllUserData.meta.count > 0) {
      setUserOptions(getAllUserData.items);
    } else {
      dispatch(getUsers({ page: 1 }));
    }
  }, [getAllUserData.meta.count]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value = "" },
  }) => {
    const url = new URL(window.location.href);

    url.searchParams.set(name, value);
    url.searchParams.set("page", "1");

    navigate(url.search);
  };

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);

    url.searchParams.set("page", String(page));

    navigate(url.search);
  };

  const onSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const url = new URL(window.location.href);
    url.searchParams.set("search_text", searchText);
    url.searchParams.set("page", "1");
    navigate(url.search);
    setSelectedItems([]);
  };
  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
  };

  const onItemSelectChange = (coupon: Record<string, any>) => {
    const isChecked = selectedItems.map((el) => el.id).includes(coupon.id);

    if (isChecked) {
      setSelectedItems((all) => all.filter((it) => it.id !== coupon.id));
    } else {
      setSelectedItems((all) => [coupon, ...all]);
    }
  };

  const deleteAll = () => {
    dispatch(
      deleteCoupons({
        items: selectedItems.map((it) => it.id),
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(getActiveCoupons(couponFilters));
      }
    });
  };

  const deactivateCoupon = (coupon: Record<string, any>) => {
    dispatch(
      createUpdateCoupon({
        data: {
          id: coupon.id,
          offer_type: "sales", // added to skip required validation
          activateNew: false,
          deactivated: true,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  return (
    <OMAUserAddedComponent
      page={page}
      onPageChange={onPageChange}
      loading={loading}
      data={data}
      onSearchChange={onSearchChange}
      onSearchSubmit={onSearchSubmit}
      onItemSelectChange={onItemSelectChange}
      selectedItems={selectedItems}
      deleteCouponsData={deleteCouponsData}
      deleteAll={deleteAll}
      onChange={onChange}
      userOptions={userOptions}
      networkOptions={networkOptions}
      couponFilters={couponFilters}
      deactivateCoupon={deactivateCoupon}
    />
  );
};

export default OMAUserAddedCoupons;
