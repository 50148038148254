import Thunk from "../Thunk";
import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";

export default Thunk("clients/auto-coupon-templates", async (args) => {
  const url = `${DISCOUNT_CODES_API}/coupons/auto-coupon-templates/?offer_type=${
    args.offer_type || ""
  }&template_type=${args.template_type || ""}&is_description=${
    args.is_description
  }`;
  return await instance.get(url);
});
