import { ChangeEventHandler, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import { ProgramCategory } from "types/model/Category";
import TopCategoryStoreSpotlightLayout from "layout/oma/campaigns/CampaignsHomeCategorySpotlight";
import { toast } from "react-hot-toast";
import getCategoryStoreSpotlights from "redux/thunks/app/placements/campaigns/getCategoryStoreSpotlights";
import {
  addToCategoryStoreSpotlights,
  removeCategoryStoreSpotlight,
} from "redux/slices/clients/placements/campaigns/getCategoryStoreSpotlights";
import { setCategoryStoreSpotlights } from "redux/slices/clients/placements/campaigns/getCategoryStoreSpotlights";
import saveCategoryStoreSpotlights from "redux/thunks/app/placements/campaigns/saveCategoryStoreSpotlights";
import { useNavigate, useSearchParams } from "react-router-dom";

const TopCategoryStoreSpotlight = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [categoryResults, setCategoryResults] = useState<ProgramCategory[]>([]);
  const {
    getCategories: getCategoriesData,
    getCategoryStoreSpotlights: getCategoryStoreSpotlightsData,
    saveTopCategories: saveTopCategoriesData,
  } = useAppSelector(
    ({
      getClients,
      getCategories,
      getCategoryStoreSpotlights,
      saveTopCategories,
    }) => ({
      getClients,
      getCategories,
      getCategoryStoreSpotlights,
      saveTopCategories,
    })
  );

  const [sortedItems, setSortedItems] = useState<Record<string, any>[]>([]);

  const { loading } = useAppSelector((state) => state.getCategories);

  useEffect(() => {
    dispatch(
      getCategoryStoreSpotlights({ location: params.get("location") || "US" })
    );
  }, [params]);

  useEffect(() => {
    dispatch(getCategories({}));
  }, []);

  const handlePersistUpdates = () => {
    dispatch(
      saveCategoryStoreSpotlights(
        getCategoryStoreSpotlightsData.data.map((el, idx) => ({
          from_date: el.from_date,
          to_date: el.to_date,
          position: idx + 1,
          category: el.category.id,
          slogan: el.slogan,
          location: params.get("location"),
          stores: el.stores.map((el: Record<string, any>) => el.id),
        }))
      )
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  const onSearchCategoriesChange: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const topCategoryIds = getCategoryStoreSpotlightsData.data.map(
      (el: Record<string, any>) => el.category.id
    );
    setCategoryResults(
      getCategoriesData.data
        .filter((it) => !topCategoryIds.includes(it.id))
        .filter((el: ProgramCategory) => {
          return el.name.toLowerCase()?.startsWith(e.target.value);
        })
    );
  };
  const closeSearch = () => {
    setCategoryResults([]);
  };

  const addCategory = (result: Record<string, any>) => {
    const newItem = {
      from_date: null,
      to_date: null,
      id: result.category.id,
      slogan: "",
      category: result,
      stores: [],
    };

    setSortedItems((items) => [newItem, ...items]);

    dispatch(addToCategoryStoreSpotlights(newItem));

    setCategoryResults([]);
  };

  const handleRemoveCategory = (result: Record<string, any>) => {
    dispatch(removeCategoryStoreSpotlight(result));
  };

  useEffect(() => {
    setSortedItems(getCategoryStoreSpotlightsData.data);
  }, [getCategoryStoreSpotlightsData.data]);

  const saveSelectedStores = (
    row: Record<string, any>,
    updatedItem: Record<string, any>
  ) => {
    const updatedItems = sortedItems.map((item) =>
      item.category.id === row.category.id ? { ...updatedItem } : item
    );

    dispatch(setCategoryStoreSpotlights(updatedItems));
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    const url = new URL(window.location.href);
    url.searchParams.set(name, value);
    navigate(url.search);
  };

  return (
    <TopCategoryStoreSpotlightLayout
      loading={loading}
      closeSearch={closeSearch}
      data={sortedItems}
      onSearchCategoriesChange={onSearchCategoriesChange}
      categoryResults={categoryResults}
      onResultClicked={addCategory}
      saving={saveTopCategoriesData.loading}
      handlePersistUpdates={handlePersistUpdates}
      handleRemoveCategory={handleRemoveCategory}
      saveSelectedStores={saveSelectedStores}
      onChange={onChange}
    />
  );
};

export default TopCategoryStoreSpotlight;
