import React from "react";
import Header from "./Header";
import Spinner from "components/common/layout/Spinner";
import Message from "components/common/Message";

type Props = {
  loading?: boolean;
  children: React.ReactNode;
  hasPermissions?: boolean;
  error?: string;
};

function Layout({ loading, hasPermissions, error, children }: Props) {
  if (typeof hasPermissions === "boolean" && !hasPermissions) {
    return (
      <div>
        <Header />
        <section className="py-3 sm:py-5 px-28">
          <div className="mx-auto max-w-screen-2xl px-4 lg:px-12">
            <Message description="No authorized to view this page" />
          </div>
        </section>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Header />
        <section className="py-3 sm:py-5 px-28">
          <Message description={error} />
        </section>
      </div>
    );
  }
  return (
    <div>
      <Header />
      <section className="py-3 sm:py-5 px-28">
        {loading ? (
          <div
            className="w-full  flex items-center justify-center
          "
          >
            <Spinner className="mt-40" />
          </div>
        ) : (
          <div className="mx-auto max-w-screen-2xl px-4 lg:px-12">
            {children}
          </div>
        )}
      </section>
    </div>
  );
}

export default Layout;
