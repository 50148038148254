import classNames from "classnames";
import { usePagination, DOTS } from "hooks/usePagination";

type Props = {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
  enableShowAll?: boolean;
  onShowAll?: () => void;
  currentPageCount?: number;
};

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  enableShowAll,
  onShowAll,
  currentPageCount = 0,
}: Props) => {
  const paginationRange = usePagination({
    currentPage: Number(currentPage),
    totalCount,
    siblingCount,
    pageSize,
  });
  if (currentPageCount > pageSize) {
    return null;
  }

  // If there are less than 2 times in pagination range we shall not render the component
  if (Number(currentPage) === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage === lastPage) {
      return;
    }
    onPageChange(Number(currentPage) + 1);
  };

  const onPrevious = () => {
    if (currentPage === 1) {
      return;
    }
    onPageChange(Number(currentPage) - 1);
  };

  const lastPage = paginationRange?.[paginationRange?.length - 1] || 0;

  return (
    <div className="flex gap-2 items-center">
      <ul className="inline-flex items-stretch -space-x-px">
        <li>
          <button
            onClick={onPrevious}
            className="flex items-center cursor-pointer justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </li>

        {paginationRange?.map((pageNumber: any) => {
          const pageNumClass = classNames({
            "flex items-center cursor-pointer justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white":
              true,
            "text-primary-600": pageNumber === Number(currentPage),
          });

          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <li
                key={pageNumber}
                className="flex items-center cursor-pointer justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                ...
              </li>
            );
          }

          // Render our Page Pills
          return (
            <li
              className={pageNumClass}
              onClick={() => onPageChange(pageNumber)}
              key={pageNumber}
            >
              {pageNumber}
            </li>
          );
        })}

        <li
          className={classNames({
            disabled: currentPage == lastPage,
          })}
          onClick={onNext}
        >
          <button className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <span className="sr-only">Next</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </li>
      </ul>
      {enableShowAll && (
        <button
          onClick={onShowAll}
          className="flex items-center justify-center h-full py-1.5 px-3 leading-tight bg-white  text-primary-600  hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-xs font-medium"
        >
          show all
        </button>
      )}
    </div>
  );
};

export default Pagination;
