import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import axios from "helpers/axios";

export default createAsyncThunk(
  "admin/advertisers/newsletters/complete/",
  async (data: Record<string, any>, ThunkAPI) => {
    const client_id = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID);

    const payload = {
      ...data,
      client_id,
    };
    try {
      const response = await axios.post(
        `/admin/newsletter-feed/store-newsletters/complete`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          }
      );
    }
  }
);
