import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import SeasonalLinksLayout from "layout/oma/campaigns/SeasonalLinksLayout";
import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import getSeasonalLinks from "redux/thunks/app/placements/campaigns/getSeasonalLinks";
import { SeasonalLink } from "types/model/SeasonalLink";
import updateSeasonalLinks from "redux/thunks/app/placements/campaigns/updateSeasonalLinks";
import { useSearchParams } from "react-router-dom";

const SeasonalLinks = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const {
    getSeasonalLinks: getSeasonalLinksData,
    updateSeasonalLinks: updateSeasonalLinksData,
  } = useAppSelector(({ getSeasonalLinks, updateSeasonalLinks }) => ({
    getSeasonalLinks,
    updateSeasonalLinks,
  }));
  const [sortedItems, setSortedItems] = useState<SeasonalLink[]>(
    getSeasonalLinksData.data
  );

  useEffect(() => {
    dispatch(getSeasonalLinks());
  }, [params]);

  const updateSeasonalLink = (result: SeasonalLink) => {
    dispatch(
      updateSeasonalLinks({
        ...result,
        category: result.category.id,
      })
    );
  };

  const moveDraggableRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSortedItems((order) =>
        update(order, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, order[dragIndex]],
          ],
        })
      );
    },
    []
  );

  useEffect(() => {
    setSortedItems(getSeasonalLinksData.data);
  }, [getSeasonalLinksData.data]);

  const saveSortOrder = () => {
    dispatch(
      updateSeasonalLinks({
        id: sortedItems[0].id,
        data: sortedItems.map((el, idx) => {
          return {
            id: el.id,
            position: idx + 1,
          };
        }),
      })
    );
  };

  return (
    <SeasonalLinksLayout
      loading={getSeasonalLinksData.loading}
      updateSeasonalLinksData={updateSeasonalLinksData}
      data={sortedItems}
      saveSortOrder={saveSortOrder}
      moveDraggableRow={moveDraggableRow}
      setSortedItems={setSortedItems}
      updateSeasonalLink={updateSeasonalLink}
    />
  );
};

export default SeasonalLinks;
