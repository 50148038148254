import React from "react";
import { twMerge } from "tailwind-merge";
import formatNumber from "utils/strings/formatNumber";
import TotalSaleAmounts from "./TotalSaleAmounts";
import TotalCommissionAmounts from "./TotalCommissionAmounts";
import { ActionState } from "types/data";

type Props = {
  item: Record<string, any>;
  index: number;
  totals: Record<string, any>;
  getProposalPeriodReportData: ActionState;
};

function ClientRevenueRow({
  item,
  index,
  totals,
  getProposalPeriodReportData,
}: Props) {
  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 cursor-pointer",
    index === 0 && "bg-green-100 font-bold"
  );

  const placementRevenue = Object.entries(
    getProposalPeriodReportData.data
  ).find(([k, v]) => k === "final_usd_placement_revenue")?.[1] as number

   const adCenterRevenue = Object.entries(
    getProposalPeriodReportData.data
  ).find(([k, v]) => k === "adcenter_revenue")?.[1] as number;


  const totalEarnings =
    totals.total_commission_usd +
    (placementRevenue * 1000) +
    (adCenterRevenue*10);


  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {index === 0 && "Total Earnings"}
          {index === 1 && "Placement Sales"}
          {index === 2 && "AdCenter Revenue"}
          {index === 3 && "Total Commissions"}

          {item.client?.name || ""}
        </th>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          
          {index === 3 && <TotalSaleAmounts totals={totals} />}
          {index === 1 && (
            <span className="text-xs">
              {formatNumber(placementRevenue, {
                prefix: "$",
                decimals: 2,
              })}{" "}
            </span>
          )}
          {index === 2 && (
            <span className="text-xs">
              {formatNumber(adCenterRevenue / 100, {
                prefix: "$",
                decimals: 2,
              })}{" "}
            </span>
          )}
          {index > 3 && <TotalSaleAmounts totals={item} />}
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index == 3 &&
            formatNumber(totals?.total_sale_amount_usd / 1000, {
              prefix: "$",
              decimals: 2,
            })}

          {index > 3 &&
            formatNumber(item?.sale_amount_total / 1000, {
              prefix: "$",
              decimals: 2,
            })}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 3 && <TotalCommissionAmounts totals={totals} />}

          {index > 3 && <TotalCommissionAmounts totals={item} />}
        </td>
        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {index == 3 &&
            formatNumber(totals?.total_commission_usd / 1000, {
              prefix: "$",
              decimals: 2,
            })}
          {index === 1 && (
            <span className="text-xs">
              {formatNumber(placementRevenue, {
                prefix: "$",
                decimals: 2,
              })}{" "}
            </span>
          )}
          {index === 2 && (
            <span className="text-xs">
              {formatNumber(adCenterRevenue / 100, {
                prefix: "$",
                decimals: 2,
              })}{" "}
            </span>
          )}
          {index > 3 &&
            formatNumber(item?.commission_total / 1000, {
              decimals: 2,
              prefix: "$",
            })}

          {index === 0 && (
            <span>
              {formatNumber(totalEarnings / 1000, {
                decimals: 2,
                prefix: "$",
              })}
            </span>
          )}
        </th>
      </tr>
    </React.Fragment>
  );
}

export default ClientRevenueRow;
