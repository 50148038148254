import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { GetStoreByNetworkPayload } from "client-apis/discountcodes";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

type Params = {
  query: string | Record<string, any> | GetStoreByNetworkPayload;
};

export default createAsyncThunk(
  "clients/get-sub-brands",
  async ({ query }: Params, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }
      const clientWebsite = new ClientAPI();

      if (typeof query == "string") {
        const response = await clientWebsite.getProgramDetails(
          query,
          selectedClientId
        );

        return response.data;
      } else {
        const response = await clientWebsite.getProgramByNetworkId(query);

        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
