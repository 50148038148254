import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getNewLetterFeed from "redux/thunks/links/newsletters/getStoreNewLetterFeed";
import { PaginatedData } from "types/data";
import { Link } from "types/model/Link";
import { NewsLetterItem } from "types/model/NewsLetterItem";
import extractTextBetweenAngleBrackets from "utils/strings/extractTextBetweenAngleBrackets";

export type ActionStateType = {
  data: PaginatedData<Link>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
      extra: {
        advertiser_count: 0,
      },
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getNewLetterFeedSlice = createSlice({
  name: "getStoreNewsletterFeed",
  initialState,
  reducers: {
    resetNewsletterFeed: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewLetterFeed.pending, (state, action) => {
        return {
          ...state,
          error: null,
          data: action.meta.arg.skipCache ? state.data : initialState.data,
          loading: true,
        };
      })
      .addCase(
        getNewLetterFeed.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...payload,
                items: payload.items.map((el: NewsLetterItem) => {
                  if (!el.advertiser) {
                    return {
                      ...el,
                      advertiser: {
                        name: extractTextBetweenAngleBrackets(el.from_email),
                        network_name: extractTextBetweenAngleBrackets(
                          el.from_email
                        ),
                      },
                    };
                  } else {
                    return el;
                  }
                }),
              },
              loading: false,
            };
          }
        }
      )

      .addCase(
        getNewLetterFeed.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetNewsletterFeed } = getNewLetterFeedSlice.actions;
export default getNewLetterFeedSlice.reducer;
