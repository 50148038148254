import dateFormat from "dateformat";
import { timeAgo } from "index";
import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  item: Record<string, any>;
  onActivatePressed: (link: Record<string, any>) => void;
  checked?: boolean;
  onItemSelectChange?: () => void;
  ignoreLoading: boolean;
  handleIgnoreLinks: (link: Record<string, any>) => void;
  skippableFields?: string[];
};

const LinkRow = ({
  item,
  onActivatePressed,
  checked,
  onItemSelectChange,
  ignoreLoading,
  handleIgnoreLinks,
  skippableFields,
}: Props) => {
  const wrapperCls = twMerge(
    "border-b opacity-100 transition-all hover:bg-gray-100 ",
    ignoreLoading && checked && "opacity-50"
  );

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        {!skippableFields?.includes("checkbox") && (
          <td className="pl-4 py-2 w-4">
            <div className="flex items-center">
              <input
                id="checkbox-table-search-1"
                type="checkbox"
                checked={checked}
                onChange={onItemSelectChange}
                className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500  focus:ring-2"
              />
              <label htmlFor="checkbox-table-search-1" className="sr-only">
                checkbox
              </label>
            </div>
          </td>
        )}

        {!skippableFields?.includes("programName") && (
          <th
            scope="row"
            className="py-2 font-medium text-gray-900 whitespace-nowrap max-w-xs"
          >
            {
              <Link
                to={`/oma/program?&mid=${item?.network_advertiser?.external_id}&program_id=${item?.network_advertiser?.id}&network=${item?.network_advertiser?.network}&tab=offers`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.network_advertiser?.name?.substring(0, 30)}
                {item.network_advertiser?.name?.length > 30 ? "..." : ""}
              </Link>
            }
          </th>
        )}

        {!skippableFields?.includes("network") && (
          <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs">
            {item.network_advertiser?.network || ""}
          </td>
        )}

        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          {item.name}
        </th>

        <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
          <p
            className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
            style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
          >
            {item.description}
          </p>
        </td>

        <td className="px-4 py-2 font-medium whitespace-nowrap">
          <p className="text-xs font-medium text-gray-500 dark:text-gray-400">
            {item.coupon_code}
          </p>
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.end_date
            ? dateFormat(new Date(item.end_date), "d/mm/yyyy, h:MM TT")
            : ""}
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
          <div className="flex items-center justify-between gap-2">
            <button
              onClick={() => {
                handleIgnoreLinks(item);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4 text-red-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => {
                onActivatePressed(item);
              }}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              {/*<LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg>
              Activate
            </button>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default LinkRow;
