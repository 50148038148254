import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import PaymentsLayout from "layout/sales/PaymentsLayout";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import getPayments from "redux/thunks/sales/getPayments";
import getProposal from "redux/thunks/sales/getProposal";

const Payments = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();

  const getPaymentsData = useAppSelector((state) => state.getPayments);
  const getProposalData = useAppSelector((state) => state.getProposal);

  useEffect(() => {
    dispatch(getPayments({ proposal_id: params.get("proposal_id") }));
  }, []);

  useEffect(() => {
    dispatch(getProposal(params.get("proposal_id") || ""));
  }, []);

  return (
    <PaymentsLayout
      proposal={getProposalData.data}
      getPaymentsData={getPaymentsData}
    />
  );
};

export default Payments;
