import { Column } from "types/column";

const EXCLUDED_STORE_COLUMNS = [
  {
    key: "Store Name ",
    isSortable: false,
    label: "Store Name ",
  },
  {
    key: "Network",
    isSortable: false,
    label: "Network",
  },
  {
    key: "Status",
    isSortable: false,
    label: "Status",
  },

  {
    key: "Exclusion Date",
    isSortable: false,
    label: "Exclusion Date",
  },

  {
    key: "Action",
    isSortable: false,
    label: "",
  },
] as Column[];

export default EXCLUDED_STORE_COLUMNS;
