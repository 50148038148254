import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isBetween from "dayjs/plugin/isBetween";
import weekStart from "dayjs/plugin/weekday";

// Extend dayjs with required plugins
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(weekStart);

// Set Monday as the start of the week
dayjs.locale('en', {
  weekStart: 1,
});

const getLastWeekDateRanges = () => {
  const currentDate = dayjs();
  const lastWeekStart = currentDate.subtract(1, "week").startOf("week");
  const lastWeekEnd = currentDate.subtract(1, "week").endOf("week");

  const formattedLastWeekStart = lastWeekStart.format("YYYY-MM-DD");
  const formattedLastWeekEnd = lastWeekEnd.format("YYYY-MM-DD");

  return {
    lastWeekStart: formattedLastWeekStart,
    lastWeekEnd: formattedLastWeekEnd,
  };
};

export default getLastWeekDateRanges;
