import reportItem from "constants/reportItem";
import {
  REPORT_TYPE_CLIENT_REVENUE,
  REPORT_TYPE_DAILY_REVENUE,
  REPORT_TYPE_MONTHLY_REVENUE,
  REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER,
  REPORT_TYPE_WEEKLY_REVENUE,
} from "constants/revenueReportTypes";
import { TransactionReportActionStateType } from "redux/slices/reporting/revenue/getTransactionReport";

const getRowsByReportId = (
  filterValues: Record<string, string>,
  getTransactionReport: TransactionReportActionStateType
) => {
  if (!filterValues.report_type) {
    return [];
  }

  if (!getTransactionReport.data?.report?.items) {
    return [];
  }

  if (
    filterValues.report_type === REPORT_TYPE_DAILY_REVENUE ||
    filterValues.report_type == REPORT_TYPE_WEEKLY_REVENUE ||
    filterValues.report_type == REPORT_TYPE_MONTHLY_REVENUE
  ) {
    return [];
  }

  if (
    filterValues.report_type === REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER
  ) {
    return [
      {
        ...reportItem,
        sale_amount: getTransactionReport.data.totals.sale_amount,
        commission_amount: getTransactionReport.data.totals.commission,
        discount_amount: getTransactionReport.data.totals.total_discount_amount,
      },
      ...getTransactionReport.data.report.items,
    ];
  }

  const topRow  = {
      id: "idx",
      network: {
        network: "Totals",
      },
      website: "",
      Network: "",
      totals: {
        commission: getTransactionReport.data.totals.commission,
        sale_amount: getTransactionReport.data.totals.sale_amount,
        total_sale_transactions:
          getTransactionReport.data.totals.total_sale_transactions,
        total_returns:
          getTransactionReport.data.totals.returned_transactions_count,
        txn_total: getTransactionReport.data.totals.txn_total,
      },
    }


    if (
      filterValues.report_type ===  REPORT_TYPE_CLIENT_REVENUE
    )
    {
      return [
        topRow,
        topRow,
        topRow,
        topRow,
        ...getTransactionReport.data.report.items,
      ];
    }
      return [topRow, ...getTransactionReport.data.report.items];
};

export default getRowsByReportId;
