import SearchBar from "components/program/SearchBar";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { debounce } from "lodash";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import searchPrograms from "redux/thunks/app/placements/campaigns/searchPrograms";
import { twMerge } from "tailwind-merge";
import { Store } from "types/model/oma/Store";

type Props = {
  onResultClicked?: (result: Store) => void;
  wrapperClassName?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  client_id?: string;
  searchParams?: Record<string, any>;
  renderNetwork?: boolean;
  closeOnSelect?: boolean;
  inputClassName?: string;
  inputContainerClass?: string;
  renderResults?: (results: Array<Record<string, any>>) => void;
  error?: string;
  isCRM?: boolean;
  rightLabel?: JSX.Element;
};

const SearchPrograms = ({
  onResultClicked,
  label,
  wrapperClassName,
  placeholder,
  value,
  client_id,
  searchParams,
  renderNetwork,
  closeOnSelect,
  inputClassName,
  inputContainerClass,
  renderResults,
  isCRM,
  error,
  rightLabel,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchText, setSearchText] = useState("");
  const { data, loading } = useAppSelector((state) => state.searchPrograms);

  const debounceFn = useCallback(
    debounce((e, client) => {
      if (e.target.value.length) {
        dispatch(
          searchPrograms({
            query: e.target.value,
            client_id: client,
            isCRM: isCRM ? "1" : "0",
            ...searchParams,
          })
        );
      } else {
        dispatch(clearProgramSearchResults());
      }
    }, 1000),
    [searchParams]
  );

  const onResultClick = (res: Store) => {
    if (typeof onResultClicked == "function") {
      if (closeOnSelect) {
        onResultClicked(res);
        return onResultsOutsideClick();
      } else {
        return onResultClicked(res);
      }
    }
    if (location.pathname.includes("/oma")) {
      navigate("/oma/program?ref=manual", {
        state: res,
      });
    } else {
      navigate(`/bma/programs?slug=${res.slug}`);
    }
  };

  const onResultsOutsideClick = () => {
    dispatch(clearProgramSearchResults());
  };

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
    debounceFn(e, client_id);
  };
  const onSearchSubmit = () => {
    dispatch(
      searchPrograms({
        query: searchText || "",
        client_id,
        isCRM: isCRM ? "1" : "0",
        ...searchParams,
      })
    );
  };

  useEffect(() => {
    if (!value) {
      setSearchText("");
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      setSearchText(value);
    }
  }, [value]);

  return (
    <SearchBar
      onResultClick={onResultClick}
      onResultsOutsideClick={onResultsOutsideClick}
      results={data}
      renderClientName={isCRM}
      onChange={onSearchChange}
      wrapperClass={twMerge("mx-4", wrapperClassName)}
      onSearchSubmit={onSearchSubmit}
      label={label}
      placeholder={placeholder}
      value={searchText}
      renderResults={renderResults}
      renderLocation
      renderNetwork={renderNetwork}
      searchLoading={loading}
      inputClassName={inputClassName}
      inputContainerClass={inputContainerClass}
      error={error}
      rightLabel={rightLabel}
    />
  );
};

export default SearchPrograms;
