import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pageNumItemsInitialState from "redux/initial-states/pageNumItemsInitialState";
import getPlacementTypes from "redux/thunks/app/placements/campaigns/getPlacementTypes";
import { PageNumPaginatedActionState } from "types/data";
import PlacementType from "types/model/PlacementType";

export type ActionStateType = {
  data: PageNumPaginatedActionState<PlacementType>;
  loading: boolean;
  error: Record<string, any> | null;
};

const getPlacementTypesSlice = createSlice({
  name: "getPlacementTypes",
  initialState: pageNumItemsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlacementTypes.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        getPlacementTypes.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getPlacementTypes.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getPlacementTypesSlice.reducer;
