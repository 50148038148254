import { Link, useNavigate } from "react-router-dom";
import EmailMembers from "./EmailMembers";
import EmailSettings from "./EmailSettings";
import TabLayout from "components/common/TabLayout";
import { MARKETING_APP } from "constants/smaAppNames";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import EmailCampaignSummary from "./EmailCampaignSummary";

const EmailHome = () => {
  const navigate = useNavigate();
  const { user } = useLoadAuth();
  const hasMarketingAccess = user?.user_apps
    ?.map((it) => it.name.toLowerCase())
    ?.includes(MARKETING_APP.toLowerCase());

  const hasPermissions =
    user?.user_role == USER_ROLE_ADMIN || hasMarketingAccess || false;

  const options = [
    {
      text: "Email Campaigns",
      onClick: () => {
        navigate("/marketing/email?tab=1");
      },

      content: <EmailCampaignSummary />,
    },

    {
      text: "Subscribers",
      onClick: () => {
        navigate("/marketing/email?tab=2");
      },

      content: <EmailMembers />,
    },
    {
      text: "Settings",
      onClick: () => {
        navigate("/marketing/email?tab=3");
      },

      content: <EmailSettings />,
    },
  ];

  return (
    <TabLayout
      hasPermissions={hasPermissions}
      options={options}
      rightElement={
        <div className="w-full md:w-auto flex flex-col md:flex-row mb-3 mr-4 md:mb-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
          <Link
            to={"/marketing/email/create-campaign"}
            type="button"
            className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            <svg
              className="h-3.5 w-3.5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              />
            </svg>
            Create Campaign
          </Link>
        </div>
      }
    />
  );
};
export default EmailHome;
