import Input from "components/common/Input";
import Modal from "components/common/Modal";
import React, { useEffect, useState } from "react";
import { Proposal } from "types/model/sales/Proposal";
import dateformat from "dateformat";
import formatNumber from "utils/strings/formatNumber";
import { BACKEND_BASE_URL } from "config/env";
import { Link } from "react-router-dom";
import { currencyNameSymbolMap } from "constants/storeCurrencies";
import { PencilIcon } from "@heroicons/react/20/solid";
import { ActionState } from "types/data";
import { useAppSelector } from "hooks/useRedux";

type Props = {
  proposal: Proposal;
  proposalActionData: ActionState;
  handleSetSearchRevenue: (proposal: Proposal, revenue: string) => void;
};

const IORevenueRow = ({
  proposal,
  handleSetSearchRevenue,
  proposalActionData,
}: Props) => {
  const getAllUsers = useAppSelector((state) => state.getAllUsers);
  const [manageOpen, setManageOpen] = useState(false);
  const [form, setForm] = useState<Record<string, any>>({
    revenue: proposal.search_revenue || "",
  });

  useEffect(() => {
    if (proposalActionData?.data?.id) {
      setManageOpen(false);
    }
  }, [proposalActionData?.data]);

  return (
    <React.Fragment>
      <Modal
        doneText="Save"
        doneBtnClass="bg-blue-500 hover:bg-blue-600 sm"
        onConfirm={() => {
          if (form.revenue) {
            handleSetSearchRevenue(proposal, form.revenue);
          }
        }}
        loading={proposalActionData.loading}
        open={manageOpen}
        setOpen={setManageOpen}
        variant="sm"
      >
        <div>
          <Input
            label="Enter Amount (usd)"
            value={form.revenue}
            onChange={(e) => {
              setForm({ revenue: e.target.value });
            }}
          />
        </div>
      </Modal>
      <tr key={proposal.id}>
        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
          {dateformat(proposal.created_at, "mm/dd/yyyy")}
        </td>{" "}
        <td className="whitespace-nowrap py-2  pl-4 text-xs text-blue-600 font-medium">
          <Link
            target="_blank"
            to={`${BACKEND_BASE_URL}/admin/sales/proposals/pdf-download?id=${proposal.id}&resource=io`}
          >
            {proposal.io_id}
          </Link>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
          {proposal.created_by.first_name + " " + proposal.created_by.last_name}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.client_merchant_config?.[0].program_name}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.locations.map((el) => el.toUpperCase()).join(",")}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.payment_status}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.currency_symbol}
          {formatNumber(proposal.final_amount, { decimals: 2 })}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {currencyNameSymbolMap[proposal.payment_currency] ||
            proposal.currency_symbol}
          {formatNumber(proposal.amount_paid, { decimals: 2 })}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {proposal.currency_symbol}
          {formatNumber(proposal.remaining_balance, { decimals: 2 })}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {}
          <span>
            {proposal.search_revenue
              ? formatNumber(proposal.search_revenue, {
                  prefix: "$",
                  decimals: 2,
                })
              : "_"}
          </span>

          <PencilIcon
            height={12}
            className="inline px-2 cursor-pointer"
            onClick={() => {
              setManageOpen(true);
            }}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default IORevenueRow;
