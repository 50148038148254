import DateRangeSelector from "components/common/DateRange";
import Dropdown from "components/common/Dropdown";
import SearchForm from "components/common/SearchForm";
import countries from "constants/operationCountries";
import dayjs from "dayjs";
import SalesTeamFilter from "layout/sales_crm/components/SalesTeamFilter";
import { ChangeEventHandler } from "react";
import { PaginatedActionState } from "types/data";
import { ProposalType } from "types/model/sales/Proposal";
import { User } from "types/model/User";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  debouncedOnSearchChange: ChangeEventHandler<HTMLInputElement>;
  filterValues: Record<string, any>;
  users: User[];
  isIO?: boolean;
  searchText: string;
  proposalTypes: PaginatedActionState<ProposalType>;
};

const ProposalFilters = ({
  onChange,
  filterValues,
  users,
  isIO,
  searchText,
  proposalTypes,
}: Props) => {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3 my-4 mx-2">
      <div className="w-full flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
        <div className="flex items-center space-x-4 gap-4">
          <SearchForm
            placeholder="Search..."
            onChange={onChange}
            onSearchSubmit={() => {
              //
            }}
            wrapperClassName="w-56"
            searchText={searchText}
          />

          <SalesTeamFilter
            onChange={onChange}
            name="created_by"
            value={filterValues.created_by}
          />

          <Dropdown
            options={proposalTypes.data.items.map((el) => ({
              label: el.name,
              value: el.id,
            }))}
            name="proposal_type"
            value={filterValues.proposal_type}
            placeholder="Type"
            onChange={onChange}
            wrapperClassName="w-56"
          />
          {!isIO && (
            <Dropdown
              options={["Pending", "Approved", "Declined"].map((el) => ({
                label: el,
                value: el,
              }))}
              name="proposal_status"
              value={filterValues.proposal_status}
              placeholder="Proposal Status"
              onChange={onChange}
            />
          )}

          {isIO && (
            <Dropdown
              options={["Waived", "Pending", "Paid"].map((el) => ({
                label: el,
                value: el,
              }))}
              name="payment_status"
              value={filterValues.payment_status}
              placeholder="Payment Status"
              onChange={onChange}
            />
          )}

          <Dropdown
            options={countries.map((el) => ({
              label: el,
              value: el,
            }))}
            value={filterValues.location}
            name="location"
            placeholder="Location"
            onChange={onChange}
          />

          <DateRangeSelector
            form={{
              startDate: filterValues.start_date
                ? dayjs(filterValues.start_date).toDate()
                : null,
              endDate: filterValues.end_date
                ? dayjs(filterValues.end_date).toDate()
                : null,
            }}
            onChange={onChange}
            showClear
          />
        </div>
      </div>
    </div>
  );
};

export default ProposalFilters;
