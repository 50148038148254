import { Column } from "types/column";

const EMAIL_CAMPAIGN_SUMMARY_COLUMNS = [
  "Date",
  "Type",
  "Site",
  "Commission Value (USD)",
  "#Receivers",
  "Opens",
  "Opens(Unverified)",
  "OpenRate",
  "Clicks",
  "Clicks(Unverified)",
  "ClickRate",
  "#Unsubscribes",
  "Action",
].map((el) => ({
  key: el,
  label: el,
})) as Column[];

export default EMAIL_CAMPAIGN_SUMMARY_COLUMNS;
