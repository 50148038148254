import axios from "helpers/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { uploadFile } from "helpers/cloudinary";
import getActivePrograms from "./getActivePrograms";

type Params = {
  data: Record<string, any>;
};

export default createAsyncThunk(
  "api-clients/createUpdateStore",
  async ({ data }: Params, ThunkAPI) => {
    const payload = { ...data };
    try {
      if (data.rawLogo) {
        const { url = null } = await uploadFile(data.rawLogo);
        payload["logo_url"] = url;
      }

      if (data.rawCoverPhoto) {
        const { url = null } = await uploadFile(data.rawCoverPhoto);
        payload["cover_picture"] = url;
      }

      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );

      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }

      const clientWebsite = new ClientAPI();

      const response = await clientWebsite.createUpdateProgram({
        ...payload,
        client: selectedClientId,
      });

      if (payload.activateNew) {
        const prev =
          localStorage.getItem("recently_activated") || JSON.stringify([]);

        const items = [
          ...JSON.parse(prev),
          {
            network: payload.primary_network,
            network_mid: payload.network_mid,
            created: new Date(),
          },
        ];

        localStorage.setItem("recently_activated", JSON.stringify(items));
        axios.post(
          `/admin/advertisers/links/refresh-pending-programs?client_id=${selectedClientId}`,
          { resource: "programs" }
        );
      }

      ThunkAPI.dispatch(getActivePrograms({ page: 1 }));

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          }
      );
    }
  }
);
