import React, { useState } from "react";
import { TransactionReportActionStateType } from "redux/slices/reporting/revenue/getTransactionReport";
import formatDate from "utils/dates/formatDate";
import {
  REPORT_TYPE_CLIENT_ACQUISITION_DAILY,
  REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY,
  REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY,
} from "constants/revenueReportTypes";
import Layout from "components/layout/Layout";
import BiBarChart from "layout/marketing/search/BiBarChart";
import CustomizedLabelLineChart from "./charts/CustomizedLabelLineChart";
import { BsFileBarGraph } from "react-icons/bs";
import { VscGraphLine } from "react-icons/vsc";

type Props = {
  filterValues: Record<string, any>;
  getTransactionReport: TransactionReportActionStateType;
  showPerfView: (report_type: string) => void;
  hasPermissions: boolean;
  TableHeader: JSX.Element;
};

const AcquisitionReport = ({
  filterValues,
  getTransactionReport,
  showPerfView,
  hasPermissions,
  TableHeader,
}: Props) => {
  const [activeType, setActiveType] = useState<"bar" | "line">("bar");
  const acquisitionData = Array.isArray(getTransactionReport.data.report)
    ? (getTransactionReport.data.report as unknown as Record<string, any>[])
    : [];

  const getNameKey = (key: string) => {
    if (!key) {
      return "";
    }
    if (filterValues.report_type === REPORT_TYPE_CLIENT_ACQUISITION_DAILY) {
      return formatDate(key);
    }

    const startingDate = key.split("_")[0];
    const endingDate = key.split("_")[1];

    return `${formatDate(startingDate)}-${formatDate(endingDate)}`;
  };

  const rangeOptions = [
    {
      label: "Day",
      onClick: () => {
        showPerfView(REPORT_TYPE_CLIENT_ACQUISITION_DAILY);
      },
      active: filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION_DAILY,
    },
    {
      label: "Week",
      active: filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY,
      onClick: () => {
        showPerfView(REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY);
      },
    },

    {
      label: "Month",
      active:
        filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY,
      onClick: () => {
        showPerfView(REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY);
      },
    },
  ];
  return (
    <Layout hasPermissions={hasPermissions}>
      {TableHeader}
      <div style={{ width: "100%", height: 500 }}>
        <div className="flex items-center gap-4">
          <button
            onClick={() => {
              setActiveType("bar");
            }}
          >
            <BsFileBarGraph size={18} />
          </button>
          <button
            onClick={() => {
              setActiveType("line");
            }}
          >
            <VscGraphLine size={18} />
          </button>
        </div>
        {activeType === "bar" && (
          <BiBarChart
            data={acquisitionData.map((el) => ({
              name: getNameKey(el.data.date),
              PPC: el.data.ppc / 1000,
              Organic: el.data.organic / 1000,
              Email: el.data.email / 100,
            }))}
            rangeOptions={rangeOptions}
            title="Revenue By Source"
            xDataKey="name"
            bars={[
              {
                activeBar: {
                  fill: "pink",
                  stroke: "blue",
                },
                dataKey: "PPC",
                fill: "#8884d8",
              },

              {
                activeBar: {
                  fill: "gold",
                  stroke: "purple",
                },
                dataKey: "Organic",
                fill: "#82ca9d",
              },

              {
                activeBar: {
                  fill: "#FFBB28",
                  stroke: "blue",
                },
                dataKey: "Email",
                fill: "#FF8042",
              },
            ]}
          />
        )}

        {activeType === "line" && (
          <CustomizedLabelLineChart
            title="Revenue By Source"
            data={acquisitionData.map((el) => ({
              name: getNameKey(el.data.date),
              PPC: el.data.ppc / 1000,
              Organic: el.data.organic / 1000,
              Email: el.data.email / 100,
            }))}
            rangeOptions={rangeOptions}
          />
        )}
      </div>
    </Layout>
  );
};

export default AcquisitionReport;
