import formatDate from "utils/dates/formatDate";

type Props = {
  row: Record<string, any>;
};

const ExtUserAccountRow = ({ row }: Props) => {
  const url = row.extension_user_data?.["url"]
    ? new URL(row.extension_user_data["url"])
    : null;
  const store = url?.searchParams?.get("utm_medium") || "";
  const source = url?.searchParams?.get("utm_source") || "";

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatDate(row.user.date_joined)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.user.id}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.extension_user_data["user_id"]}
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.providers.map((el: Record<string, any>) => (
          <span key={String(el)}>{el.name || el}</span>
        ))}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.user.email}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.email_verified ? "YES" : "NO"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {source}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {store}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatDate(row.extension_user_data["installationTime"])}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.extension_user_data["ipAddress"]}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.extension_user_data["city"]}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.extension_user_data["region"]}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.extension_user_data["country"]}
      </td>
    </tr>
  );
};

export default ExtUserAccountRow;
