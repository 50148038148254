import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCouponSpotlights from "redux/thunks/app/placements/campaigns/getCouponSpotlights";

export type ActionStateType = {
  data: Record<string, any>[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getCouponSpotlightsSlice = createSlice({
  name: "getCouponSpot-lights",
  initialState,
  reducers: {
    clearSpotlightResults: () => {
      return initialState;
    },

    updateCouponSpotlight: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((el) => (el.id === payload.id ? payload : el)),
      };
    },

    removeCouponSpotlight: (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter((el) => el.coupon.id !== payload.coupon.id),
      };
    },

    setCouponSpotlights: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },

    addToCouponSpotlights: (state, { payload }) => {
      return {
        ...state,
        data: [payload, ...state.data],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCouponSpotlights.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        getCouponSpotlights.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getCouponSpotlights.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const {
  clearSpotlightResults,
  updateCouponSpotlight,
  addToCouponSpotlights,
  setCouponSpotlights,
  removeCouponSpotlight,
} = getCouponSpotlightsSlice.actions;
export default getCouponSpotlightsSlice.reducer;
