import { Client } from "./Client";

export interface EmailSubscriber {
  email: string;
  client: Client;
  id: string;
  from_route: string;
  phone_number: string | null;
  device: string | null;
  ip_address: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  country: string | null;
  program_slug: string | null;
  verified: boolean;
  verified_at: string | null;
  created_at: string;
  updated_at: string;
}

export interface EmailCampaignSetting {
  store_location: string;
  starter_line: string;
  id: string;
  client: Client;
  template_type: string;
  from_date: string;
  to_date: string;
  created_at: string;
  updated_at: string;
}

export interface EmailCampaignStat {
  opens: number;
  unsubs: number;
  clicks: number;
  open_rate: number;
  clicks_rate: number;
  pending_email_opens: number;
  pending_email_clicks: number;
}

export interface EmailCampaignSummary {
  client: Client;
  campaign_type: string;
  email_drop_id: string;
  total_receivers: number;
  tags: Array<string>;
  created_at: string;
  // updated_at: string;
  stats?: EmailCampaignStat;
}
export type EmailCampaignStatusType = "Draft" | "Published" | "Executed";
export interface EmailCampaign {
  uuid: string;
  id?: string;
  client: Client;
  campaign_type: string;
  starter_line: string;
  program_slug: string | null;
  receiver_count: number;
  email_drop_id: string;
  tags: Array<string>;
  coupon_id: string;
  coupon_info: Record<string, any>;
  created_at: string;
  updated_at: string;
  stats?: EmailCampaignStat;
  email_subject?: string | null;
  email_preview_text?: string | null;
  custom_coupon_headline?: string | null;
  custom_coupon_description?: string | null;
  program_info?: Record<string, any>;
  coupons?: Record<string, unknown>[];
  date?: string;
  location?: string;
  coupon_stats?: Record<string, any>;
  status: EmailCampaignStatusType;
  scheduled_for?: string;
  send_status?: string;
  commission_value_usd: string;
}

export const EmailCampaignStatus = {
  DRAFT: "Draft",
  PUBLISHED: "Published",
  EXECUTED: "Executed",
};
