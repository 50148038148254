import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";
import { BACKEND_BASE_URL } from "config/env";

export default createAsyncThunk(
  "clients/marketing/emails/program-email-subscribers",
  async (filters: Record<string, string | number>, ThunkAPI) => {
    try {
      const url = new URL(
        `${BACKEND_BASE_URL}/admin/emails/program-emails/?offset=${getOffsetForPage(
          Number(filters.page || 1)
        )}`
      );

      Object.entries(filters).forEach(([k, v]) => {
        if (v) {
          url.searchParams.set(k, String(v));
        }
      });

      const response = await axios.get(url.href);

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
