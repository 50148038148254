import React, { useState, useEffect } from "react";
import instance from "helpers/axios";
import RevenueBarChat from "./RevenueBarChat";
import SalesTeamFilter from "./SalesTeamFilter";
import useLoadAuth from "hooks/useLoadAuth";
import { USER_ROLE_ADMIN } from "constants/user_roles";

const RevenueChart: React.FC = () => {
  const proposalTypes = [
    "Paid Placements",
    "Search Rights",
    "CPA Increase",
    "Exclusive Codes",
  ];
  const [checkedType, setCheckedType] = useState<string>("Paid Placements");
  const { user } = useLoadAuth();
  const [userId, setUserId] = useState<number>();
  const [loading, setLoading] = useState<boolean>();

  const isAdmin = user?.user_role === USER_ROLE_ADMIN;

  const [data, _setChartData] = useState<Record<string, any>[]>([]);
  const getData = () => {
    setLoading(true);
    instance
      .get(
        `admin/sales/proposals/proposal-type-performance/?user_id=${
          userId || ""
        }`
      )
      .then((res) => {
        _setChartData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [userId]);

  const currentTypeName = checkedType;

  const chartData = data.map((el) => ({
    name: el.name,
    [currentTypeName]: el[currentTypeName],
  }));

  return (
    <div className="border border-gray-200 p-4">
      <div className="flex justify-between items-center">
        <div>
          <div className="flex justify-between items-center mb-4">
            <p className="text-lg font-semibold text-gray-500 mb-4">
              Score Chart
            </p>
            {isAdmin && (
              <SalesTeamFilter
                loading={loading}
                onChange={({ target: { value } }) => {
                  setUserId(Number(value));
                }}
              />
            )}
          </div>

          <div className="flex gap-4 mt-2 flex-wrap">
            {proposalTypes.map((type) => (
              <label key={type}>
                <input
                  type="checkbox"
                  checked={checkedType === type}
                  onChange={() => {
                    setCheckedType(type);
                  }}
                />{" "}
                {type}
              </label>
            ))}
          </div>
          <div style={{ height: 600, width: 1000, marginTop: 40 }}>
            <RevenueBarChat data={chartData} checkedType={checkedType} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueChart;
