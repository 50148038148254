import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "emails/create-campaign-settings",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      if (payload.id) {
        if (payload.delete) {
          const response = await axios.delete(
            `/admin/emails/email-campaign-settings/${payload.id}`
          );
          return response.data;
        } else {
          const response = await axios.patch(
            `/admin/emails/email-campaign-settings/${payload.id}`,
            payload
          );
          return response.data;
        }
      } else {
        const response = await axios.post(
          `/admin/emails/email-campaign-settings/`,
          payload
        );
        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
