import React from "react";
import { HiOutlineExclamation } from "react-icons/hi";
import Button from "./Button";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";

type Props = {
  title?: string;
  icon?: boolean;
  subTitle?: string;
  description: string;
  onActionClick?: () => void;
  actionText?: string;
  actionLoading?: boolean;
  success?: boolean;
  info?: boolean;
  error?: boolean;
  warn?: boolean;
  wrapperClassName?: string;
  textClassName?: string;
  onClose?: () => void;
};

function Message({
  title,
  subTitle,
  description,
  onActionClick,
  actionText,
  actionLoading,
  success,
  info,
  error,
  warn,
  wrapperClassName,
  textClassName,
  icon,
  onClose,
}: Props) {
  const wrapperClass = twMerge(
    "w-full rounded-md justify-between  p-4",
    error && "bg-red-200 ",
    success && "bg-green-50",
    warn && "bg-yellow-50",
    wrapperClassName
  );

  const getTextClass = () => {
    const defaultClass = "mt-2 text-sm text-yellow-700";

    return twMerge(
      defaultClass,
      info && "text-yellow-700",
      success && "text-green-700",
      warn && "text-yellow-700",
      error && "text-red-700",
      textClassName
    );
  };

  const getIcon = () => {
    if (info || warn) {
      return (
        <HiOutlineExclamation
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      );
    }

    return success ? (
      <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
    ) : (
      <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
    );
  };

  return (
    <div className={wrapperClass}>
      <div className="flex justify-between">
        {icon && <div className="flex-shrink-0">{getIcon()}</div>}
        <div className={twMerge("", icon && "ml-3")}>
          {title && (
            <h3 className="text-sm font-semibold text-yellow-800">{title}</h3>
          )}
          <div className={getTextClass()}>
            {subTitle && <p>{subTitle}</p>}
            <p>{description}</p>
          </div>
        </div>
        {typeof onClose === "function" && <button onClick={onClose}>X</button>}
      </div>

      {typeof onActionClick === "function" && (
        <div className="mt-3 ml-3 px-4">
          <Button
            text={actionText}
            className="w-[200px]"
            onClick={onActionClick}
            loading={actionLoading}
          />
        </div>
      )}
    </div>
  );
}

export default Message;
