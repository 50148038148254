import { ChangeEventHandler } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  label?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  name?: string;
  wrapperClassName?: string;
};

function Checkbox({ label, checked, onChange, name, wrapperClassName }: Props) {
  return (
    <div className={twMerge("flex items-center  mr-4", wrapperClassName)}>
      <input
        type="checkbox"
        onChange={onChange}
        name={name}
        id={name}
        // defaultChecked={checked}
        checked={checked}
        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
        // className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      {label && (
        <label
          htmlFor={name}
          className="ml-2 text-sm  font-medium text-gray-700 "
        >
          {label}
        </label>
      )}
    </div>
  );
}

export default Checkbox;
