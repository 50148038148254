import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EditDevicesComponent from "layout/marketing/search/EditDevices";
import { capitalize } from "lodash";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import updateAdGroup from "redux/thunks/marketing/sem/updateCampaign";

const EditDevices = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [params] = useSearchParams();
  const {
    getAdCampaigns: { data: getAdCampaignsData },
    updateAdGroup: { loading: updateAdGroupLoading },
  } = useAppSelector(({ getAdCampaigns, updateAdGroup }) => ({
    getAdCampaigns,
    updateAdGroup,
  }));

  const initialForm = {
    ...state,
    ad_group_name: "",
    default_bid: "",
    ad_group_status: "",
    campaign_id: "",
    action: "update-ad-group",
    search_engine: params.get("search_engine"),
    ad_group_id: params.get("ad_group_id"),
    bidAdjustments: {
      google: [
        {
          label: "Mobile",
          name: "mobile",
          value: "0",
        },
        {
          label: "Desktop",
          name: "desktop",
          value: "0",
        },
        {
          label: "Tablet",
          value: "0",
          name: "tablet",
        },
      ],

      bing: [
        {
          label: "Mobile",
          name: "mobile",
          value: "0",
        },
        {
          label: "Desktop",
          name: "desktop",
          value: "0",
        },
        {
          label: "Tablet",
          value: "0",
          name: "tablet",
        },
      ],
    },
  };

  const [form, setForm] = useState<Record<string, any>>(initialForm);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = () => {
    dispatch(
      updateAdGroup({
        ...form,
        ad_group_id: params.get("ad_group_id"),
        campaign_id: params.get("campaign_id"),
        action: "update-device-bid_multipliers",
      })
    );
  };

  useEffect(() => {
    const campaign = getAdCampaignsData.find(
      (it) => String(it.id) === params.get("campaign_id")
    );

    if (campaign) {
      setForm((f) => ({
        ...f,
        ad_group_name: campaign.ad_group_name,
        default_bid: campaign.ad_group_default_cpc,
        ad_group_status: campaign.ad_group_status,
        ad_group_id: campaign.ad_group_id,
        campaign_id: campaign.id,
        search_engine: campaign.search_engine,
        bidAdjustments: {
          ...f.bidAdjustments,
          [campaign.search_engine]: campaign.devices.map(
            (el: Record<string, any>) => ({
              label: capitalize(el.device_name),
              name: el.device_name,
              value: el.bid_multiplier,
              id: el.id,
              resource_name: el.resource_name,
            })
          ),
        },
      }));
    }
  }, [getAdCampaignsData]);

  return (
    <EditDevicesComponent
      updateAdGroupLoading={updateAdGroupLoading}
      onSubmit={onSubmit}
      form={form}
      onChange={onChange}
    />
  );
};

export default EditDevices;
