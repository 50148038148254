import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EmailProgramsReportComponent from "layout/marketing/email/EmailProgramsReport";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getProgramEmailSubscribers from "redux/thunks/marketing/emails/getProgramEmailSubscribers";

type Props = {
  filterValues: Record<string, any>;
};
function EmailProgramsView({ filterValues }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { page = 1 } = Object.fromEntries([...searchParams]);

  const {
    search,
    verified,
    program_location,
    is_home_page,
    report_view,
    dateFilterDone,
  } = filterValues;

  const groupedProgramEmails = useAppSelector(
    (state) => state.getProgramEmailSubscribers
  );

  const getData = () => {
    dispatch(getProgramEmailSubscribers({ ...filterValues, page }));
  };

  useEffect(() => {
    getData();
  }, [page, search, verified, program_location, is_home_page, report_view]);

  useEffect(() => {
    if (dateFilterDone) {
      getData();
    }
  }, [dateFilterDone]);

  const onPageChange = (page: Number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  return (
    <EmailProgramsReportComponent
      groupedProgramEmailsData={groupedProgramEmails}
      filterValues={{ ...filterValues, page: page }}
      onPageChange={onPageChange}
    />
  );
}

export default EmailProgramsView;
