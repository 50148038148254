import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import BrowserExtStoreExclusionsLayout from "layout/marketing/extension/BrowserExtStoreExclusionsLayout";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import updateStoreExtSettings from "redux/thunks/extension/updateStoreExtSettings";
import createStoreExtSetting from "redux/thunks/extension/createStoreExtSetting";
import getStoreExtSettings from "redux/thunks/extension/getStoreExtSettings";
import { ExcludedStore } from "types/model/ExcludedStore";
import { Store } from "types/model/oma/Store";

const BrowserExtStoreExclusions = () => {
  const [initialSearchTxt, setInitialSearchTxt] = useState("");
  const dispatch = useAppDispatch();

  const {
    getStoreExtSettings: getExcludedStoresData,
    createStoreExtSetting: excludeStoreData,
    updateStoreExtSettings: deleteExcludedStoreData,
  } = useAppSelector(
    ({
      getStoreExtSettings,
      createStoreExtSetting,
      updateStoreExtSettings,
    }) => ({
      getStoreExtSettings,
      createStoreExtSetting,
      updateStoreExtSettings,
    })
  );
  useEffect(() => {
    dispatch(getStoreExtSettings({ excluded: "1" }));
  }, []);

  const handleAddExclusion = (store: Store) => {
    dispatch(clearProgramSearchResults());
    dispatch(
      createStoreExtSetting({
        store,
        excluded: true,
        auto_open: null,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
        setInitialSearchTxt("   ");
        setTimeout(() => {
          setInitialSearchTxt("");
        }, 10);
      }
    });
  };

  const handleDeleteExclusion = (createStoreExtSetting: ExcludedStore) => {
    dispatch(
      updateStoreExtSettings({ ...createStoreExtSetting, excluded: false })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  return (
    <BrowserExtStoreExclusionsLayout
      getExcludedStoresData={getExcludedStoresData}
      handleAddExclusion={handleAddExclusion}
      handleDeleteExclusion={handleDeleteExclusion}
      excludeStoreData={excludeStoreData}
      deleteExcludedStoreData={deleteExcludedStoreData}
      initialSearchTxt={initialSearchTxt}
    />
  );
};

export default BrowserExtStoreExclusions;
