import classNames from "classnames";
import React from "react";

type Props = {
  status: string;
};

function Status({ status }: Props) {
  const spanClass = classNames({
    "text-xs font-medium px-2.5 py-0.5 rounded": true,
    " border border-green-300 bg-green-50 text-green-400": status === "JOINED",
    "border border-red-300 bg-red-50 text-red-800": status !== "JOINED",
  });

  return <span className={spanClass}>{status}</span>;
}

export default Status;
