import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
  useEffect,
  useCallback,
  ChangeEventHandler,
  useState,
  FormEventHandler,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getLinks from "redux/thunks/links/getLinks";
import OMADirectStoreNewslettersComponent from "layout/oma/OMADirectStoreNewslettersComponent";
import toast from "react-hot-toast";
import { Option } from "types/option";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";
import getNewLetterFeed from "redux/thunks/links/newsletters/getStoreNewLetterFeed";
import completeNewsletter from "redux/thunks/links/newsletters/completeStoreNewsletter";
import generateCouponInfo from "redux/thunks/links/newsletters/generateCouponInfo";
import { resetGenerateCouponInfo } from "redux/slices/links/newsletter-feed/generateCouponInfo";

const OMADirectStoreNewsletters = () => {
  const [searchStr, setSearchStr] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [networkOptions, setNetworkOptions] = useState<Option[]>([]);

  const {
    page,
    network_id,
    rank,
    search_text,
    store_location,
    has_un_matched_emails,
  } = Object.fromEntries([...searchParams]);

  const {
    getNewsletterFeed: getNewsletterFeedState,
    completeNewsLetter: completeNewsLetterData,
    affiliateNetworks,
    createUpdateCoupon: createCouponData,
    generateCouponInfo: generateCouponInfoData,
  } = useAppSelector(
    ({
      getNewsletterFeed,
      completeNewsLetter,
      affiliateNetworks,
      createUpdateCoupon,
      generateCouponInfo,
    }) => ({
      getNewsletterFeed,
      completeNewsLetter,
      affiliateNetworks,
      createUpdateCoupon,
      generateCouponInfo,
    })
  );

  const getData = useCallback(
    (page = 1) => {
      dispatch(getLinks({ page }));
    },
    [dispatch]
  );

  const handleGenerateCouponInfo = (link: Record<string, any>) => {
    dispatch(generateCouponInfo(link)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (Array.isArray(res.payload) && res.payload.length > 1) {
          toast.success(`${res.payload.length} Offers Found`);
        }
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(resetGenerateCouponInfo());
    };
  }, []);

  const onActivatePressed = (link: Record<string, any>) => {
    navigate(
      `/oma/program?&mid=${link?.network_advertiser?.external_id}&program_id=${link?.network_advertiser?.id}&network=${link?.network_advertiser?.network}&link=${link.id}&tab=create-coupon`,

      {
        state: link,
      }
    );
  };

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const onFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.name === "search_text") {
      setSearchStr(e.target.value);
      return;
    }
    if (e.target.checked) {
      const url = new URL(window.location.href);
      url.searchParams.set("page", "1");
      url.searchParams.set(e.target.name, "1");
      navigate(url.search);
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set("page", "1");
      url.searchParams.set(e.target.name, "0");
      navigate(url.search);
    }
  };

  const onSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const url = new URL(window.location.href);
    url.searchParams.set("page", "1");
    url.searchParams.set("search_text", searchStr);
    navigate(url.search);
  };

  useEffect(() => {
    if (affiliateNetworks.data.items.length) {
      const allAffiliateNetworks = affiliateNetworks.data.items.map((item) => ({
        label: item.name,
        value: item.network_id,
        selected: network_id === item.network_id,
      }));
      setNetworkOptions(allAffiliateNetworks);
    } else {
      dispatch(getAffiliateNetworks({ page: 1 }));
    }
  }, [affiliateNetworks.data.items.length]);

  useEffect(() => {
    dispatch(
      getNewLetterFeed({
        page: Number(page) || 1,
        search_text,
        network_id,
        store_location,
        rank,
        has_un_matched_emails,
      })
    );
  }, [getData, searchParams]);

  const handleCompleteNewsLetter = (link: Record<string, any>) => {
    dispatch(
      completeNewsletter({
        email_id: link.email_id,
        id: link.id,
      })
    )
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(
            getNewLetterFeed({
              page: Number(page) || 1,
              search_text,
              network_id,
              store_location,
              rank,
              skipCache: true,
            })
          );
          toast.success("Newsletter marked as complete");
        }
      })
      .catch((err) => {
        //
      });
  };

  const onSelectedNetworkChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const isSelected = e.currentTarget.checked;
    setNetworkOptions((opts) =>
      opts.map((v) =>
        v.value === e.currentTarget?.name ? { ...v, selected: isSelected } : v
      )
    );

    const url = new URL(window.location.href);
    url.searchParams.set("network_id", e.target.value);

    navigate(url.search);
  };

  const onSelectedRankChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNetworkOptions((opts) =>
      opts.map((v) => (v.value === e.currentTarget?.name ? { ...v } : v))
    );

    const url = new URL(window.location.href);
    url.searchParams.set(e.target.name, e.target.value);

    navigate(url.search);
  };

  return (
    <OMADirectStoreNewslettersComponent
      onPageChange={getNewPage}
      createCouponData={createCouponData}
      network={network_id}
      currentPage={Number(page) || 1}
      onActivatePressed={onActivatePressed}
      getAllLinksState={getNewsletterFeedState}
      onFilterChange={onFilterChange}
      onSearchSubmit={onSearchSubmit}
      completeNewsletter={handleCompleteNewsLetter}
      completeNewsLetterData={completeNewsLetterData}
      networkOptions={networkOptions}
      onSelectedNetworkChange={onSelectedNetworkChange}
      onSelectedRankChange={onSelectedRankChange}
      store_location={store_location}
      rank={rank}
      has_un_matched_emails={has_un_matched_emails}
      generateCouponInfoData={generateCouponInfoData}
      generateCouponInfo={handleGenerateCouponInfo}
    />
  );
};

export default OMADirectStoreNewsletters;
