function getDistinctObjects<T>(arr: T[]): T[] {
  if (!Array.isArray(arr)) {
    throw new Error("Input is not an array.");
  }

  const distinctArray: T[] = [];

  for (const obj of arr) {
    if (!distinctArray.some((item) => isEqual(item, obj))) {
      distinctArray.push(obj);
    }
  }

  return distinctArray;
}

// Custom equality check
function isEqual(objA: any, objB: any): boolean {
  return JSON.stringify(objA) === JSON.stringify(objB);
}

//
export default getDistinctObjects;
