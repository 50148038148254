import React, { ChangeEvent, ChangeEventHandler, useState } from "react";
import TextArea from "./TextArea";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  fieldsForm: Record<string, any>;
  index: number;
  onFormSubmit?: () => void;
  saveBtnLoading?: boolean;
};

const FaqTextArea = ({
  onChange,
  fieldsForm,
  index,
  onFormSubmit,
  saveBtnLoading,
}: Props) => {
  const [valueChanged, setValueChange] = useState(false);
  return (
    <TextArea
      allowSave={valueChanged && typeof saveBtnLoading === "boolean"}
      saveBtnLoading={saveBtnLoading}
      onChange={(e) => {
        onChange({
          ...e,
          target: {
            ...e.target,
            name: `Answer ${index + 1}`,
            value: e.target.value,
          },
        } as unknown as ChangeEvent<HTMLInputElement>);
        setValueChange(true);
      }}
      // error={errors.description}
      label={`Answer ${index + 1}`}
      name={`Answer ${index + 1}`}
      onSave={onFormSubmit}
      value={fieldsForm.form[`Answer ${index + 1}`]}
    />
  );
};

export default FaqTextArea;
