import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import getADeactivatedAdvertisers from "redux/thunks/getDeactivatedAdvertisers";
import DeactivatedProgramsComponent from "layout/bma/DeactivatedPrograms";

const DeactivatedPrograms = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { page } = Object.fromEntries([...searchParams]);

  const { getADeactivatedAdvertisers: getADeactivatedAdvertisersData } =
    useAppSelector(({ getADeactivatedAdvertisers }) => ({
      getADeactivatedAdvertisers,
    }));

  const getData = useCallback(
    (page = 1) => {
      dispatch(getADeactivatedAdvertisers({ page }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getADeactivatedAdvertisers({ page: Number(page) || 1 }));
  }, [getData, searchParams]);

  return (
    <DeactivatedProgramsComponent
      getADeactivatedAdvertisersData={getADeactivatedAdvertisersData}
    />
  );
};

export default DeactivatedPrograms;
