import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import { Column } from "types/column";
type Props = {
  column: Column;
  sortTable?: (col: Column) => void;
};

function TableColumnHeader({ column, sortTable }: Props) {
  const [params] = useSearchParams();

  const isOrderingActive = params.get("ordering")?.includes(column.key);
  return (
    <th scope="col" className=" py-3  px-4 bg-gray-50">
      <span className="flex gap-2">
        {column.label}

        {column.isSortable && (
          <button
            onClick={() => {
              sortTable?.(column);
            }}
          >
            {isOrderingActive ? <BiUpArrow /> : <BiDownArrow />}
          </button>
        )}
      </span>
    </th>
  );
}

export default TableColumnHeader;
