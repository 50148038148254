import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getActiveProgramsAction from "redux/thunks/app/api-clients/getActivePrograms";
import BMACRMMappingAllComponent from "layout/bma/BMACRMMappingAll";
import axios from "axios";
import { DISCOUNT_CODES_API } from "constants/api-clients";

const BMACRMMappingAll = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getActivePrograms } = useAppSelector(
    ({ getAdvertisers, getActivePrograms }) => ({
      getAdvertisers,
      getActivePrograms,
    })
  );

  const goToItem = (row: Record<string, any>) => {
    navigate(`/bma/mapping/${row.slug}?slug=${row.slug}`, {
      state: row,
    });
  };

  useEffect(() => {
    dispatch(getActiveProgramsAction({ page: 1 }));
  }, []);

  return (
    <BMACRMMappingAllComponent
      getActivePrograms={getActivePrograms}
      onRowClicked={goToItem}
    />
  );
};

export default BMACRMMappingAll;
