import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCouponPolicies from "redux/thunks/app/api-clients/getCouponPolicies";
import { ActionState } from "types/data";
import { CouponPolicy } from "types/model/oma/CouponPolicy";

const initialState: ActionState<CouponPolicy[]> = {
  data: [],
  loading: false,
  error: null,
};

const getCouponPoliciesSlice = createSlice({
  name: "CouponPolicies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCouponPolicies.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getCouponPolicies.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getCouponPolicies.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getCouponPoliciesSlice.reducer;
