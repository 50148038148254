import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import toast from "react-hot-toast";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";

export default createAsyncThunk(
  "sales_crm/editDeleteAgency",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      if (payload.delete) {
        const response = await axios.delete(
          `/admin/sales-crm/agencies/${payload.id}/`
        );
        toast.success("Agency deleted successfully");

        return response.data;
      } else {

         if (payload.file) {
           const s3 = new S3({
             ...defaultS3Config,
             dirName: `img/agencies/${payload.id}`,
           });
           const result = await s3.uploadFile(payload.file);
           const { location } = result;
           payload["logo_url"] = location;
         }

        const response = await axios.put(
          `/admin/sales-crm/agencies/${payload.id}/`,
          payload
        );
        toast.success("Changes saved");

        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
