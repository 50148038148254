import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "components/layout/Layout";
import { twMerge } from "tailwind-merge";
import SEMProgramHome from "containers/marketing/search/SEMProgramCampaigns";
import ProgramHeader from "containers/bma/ProgramHeader";
import SEMProgramNotes from "containers/marketing/search/SEMProgramNotes";
import Button from "components/common/Button";
import React, { ChangeEventHandler, useRef, useState } from "react";
import Modal from "components/common/Modal";
import Input from "components/common/Input";
import TextArea from "components/common/TextArea";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import { GrAttachment } from "react-icons/gr";
import Label from "components/common/Label";
import formatDate from "utils/dates/formatDate";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import createCampaignNote from "redux/thunks/marketing/sem/createCampaignNote";
import toast from "react-hot-toast";
import getCampaignNotes from "redux/thunks/marketing/sem/getCampaignNotes";
import { today } from "utils/dates/today";

const SEMProgramTabs = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const createNoteData = useAppSelector((state) => state.createCampaignNote);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const pickFileRef = useRef<HTMLInputElement>(null);

  const [newNoteOpen, setNewNoteOpen] = useState(false);
  const [params] = useSearchParams();
  const selectedTab = params.get("tab") || "campaigns";

  const { client_id, slug } = Object.fromEntries(params);

  const renderContent = () => {
    if (selectedTab === "campaigns") {
      return <SEMProgramHome />;
    }

    if (selectedTab === "notes") {
      return <SEMProgramNotes />;
    }
  };

  const ad_platforms = ["Google", "Bing"];

  const initialState = {
    date: today,
    details: "",
    ad_platforms: ad_platforms.map((el) => ({
      label: el,
      value: el,
      selected: true,
    })),
  };
  const [form, setForm] = useState(initialState);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  const onSubmit = () => {
    if (form.date && form.details.trim().length) {
      const payload = {
        program_slug: slug,
        client: client_id,
        details: form.details,
        date: form.date,
        files: selectedFiles,
        search_engines: form.ad_platforms
          .filter((el) => el.selected)
          .map((el) => el.value),
      };

      dispatch(createCampaignNote(payload)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Note added successfully");
          setForm(initialState);
          setSelectedFiles([]);
          dispatch(getCampaignNotes({ program_slug: slug }));
          setNewNoteOpen(false);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        variant="lg"
        loading={createNoteData.loading}
        doneOnClick={() => {
          onSubmit();
        }}
        doneText="Create Note"
        onConfirm={() => {}}
        title="Create Note"
        open={newNoteOpen}
        setOpen={setNewNoteOpen}
      >
        <div className="flex-col">
          <div className="flex gap-2">
            <Input
              type="date"
              onChange={onChange}
              label="Date"
              value={form.date}
              wrapperClassName="my-2 w-1/2"
              name={"date"}
            />

            <MultipleSelectionInput
              label="Ad Platforms"
              onChange={(e) => {
                if (!e.target.checked) {
                  setForm((f) => ({
                    ...f,
                    ad_platforms: form.ad_platforms.map((el) =>
                      el.value == e.target.value
                        ? { ...el, selected: false }
                        : el
                    ),
                  }));
                } else {
                  setForm((f) => ({
                    ...f,
                    ad_platforms: form.ad_platforms.map((el) =>
                      el.value == e.target.value
                        ? { ...el, selected: true }
                        : el
                    ),
                  }));
                }
              }}
              renderAll
              name="ad_platforms"
              wrapperClassName="my-2"
              options={form.ad_platforms}
            />
          </div>

          <TextArea
            name="details"
            onChange={(e) => {
              setForm((f) => ({ ...f, details: e.target.value }));
            }}
            id="details"
            label="Details"
            wrapperClassName="my-2"
            value={form.details}
            required
          />

          <div
            className="flex my-4 cursor-pointer gap-2"
            onClick={() => {
              pickFileRef.current?.click();
            }}
          >
            <GrAttachment /> <Label label="Attach Files"></Label>
          </div>
          <div>
            {selectedFiles.length > 0 && (
              <ul className="list-disc pl-5 space-y-1 border border-gray-300 rounded-lg p-2">
                {selectedFiles.map((file, index) => (
                  <li
                    key={index}
                    className="text-gray-700 border-b flex text-sm justify-between border-gray-200 last:border-none pb-1"
                  >
                    <span className="block">{file.name}</span>
                    <span
                      className="cursor-pointer block font-medium mx-4"
                      onClick={() => {
                        setSelectedFiles((f) =>
                          f.filter((el) => el.name !== file.name)
                        );
                      }}
                    >
                      X
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <input
            type="file"
            ref={pickFileRef}
            hidden
            onChange={(e) => {
              if (e.target.files) {
                const filesArray = Array.from(e.target.files);
                setSelectedFiles((current) => [...filesArray, ...current]);
              }
            }}
          />
        </div>
      </Modal>
      <Layout>
        <ProgramHeader />
        <div className="inline-flex flex-col w-full md:flex-row mt-12 justify-between">
          <div className="flex items-center space-x-6">
            <button
              type="button"
              onClick={() => {
                const url = new URL(window.location.href);
                url.searchParams.set("tab", "campaigns");
                navigate(url.search);
              }}
              className={twMerge(
                "px-4 py-2 mr-4 text-sm font-medium  border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg bg-gray-100  focus:z-10 focus:ring-2 ",
                selectedTab === "campaigns" &&
                  "ring-primary-700 text-primary-700"
              )}
            >
              Campaigns
            </button>

            <button
              type="button"
              onClick={() => {
                const url = new URL(window.location.href);
                url.searchParams.set("tab", "notes");
                navigate(url.search);
              }}
              className={twMerge(
                "px-4 py-2 mx-4 text-sm font-medium   border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg bg-gray-100  focus:z-10 focus:ring-2 ",
                selectedTab === "notes" && "ring-primary-700 text-primary-700"
              )}
            >
              Notes
            </button>
          </div>
          <Button
            text="New Note"
            className="mr-4"
            onClick={() => {
              setNewNoteOpen(true);
            }}
          />
        </div>

        <div className="my-5">{renderContent()}</div>
      </Layout>
    </React.Fragment>
  );
};

export default SEMProgramTabs;
