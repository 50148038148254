import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import searchPrograms from "redux/thunks/app/placements/campaigns/searchPrograms";
import { Store } from "types/model/oma/Store";

export type ActionStateType = {
  data: Store[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const searchProgramsSlice = createSlice({
  name: "searchPrograms",
  initialState,
  reducers: {
    clearProgramSearchResults: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPrograms.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        searchPrograms.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        searchPrograms.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const { clearProgramSearchResults } = searchProgramsSlice.actions;
export default searchProgramsSlice.reducer;
