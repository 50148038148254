import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createUpdateDeleteProposal from "redux/thunks/sales/createUpdateDeleteProposal";
import getProposals from "redux/thunks/sales/getProposals";
import sendSigningRequest from "redux/thunks/sales/sendSigningRequest";
import { PaginatedData } from "types/data";
import { Proposal } from "types/model/sales/Proposal";

export type PagingMeta = {
  count: number;
  limit: number;
  offset: number;
};

export type ActionStateType = {
  data: PaginatedData<Proposal>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getProposalsSlice = createSlice({
  name: "Proposals",
  initialState,
  reducers: {
    resetProposals: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProposals.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getProposals.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        createUpdateDeleteProposal.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { payload, meta } = action as any;

          if (meta.arg.deleted) {
            return {
              ...state,
              data: {
                ...state.data,
                items: state.data.items.filter((el) => el.id !== payload.id),
              },
            };
          }
          return state;
        }
      )

      .addCase(
        sendSigningRequest.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                items: state.data.items.map((el) =>
                  el.id === payload.proposal_id
                    ? { ...el, signing_status: payload.status }
                    : el
                ),
              },
              loading: false,
            };
          }
        }
      )

      .addCase(
        getProposals.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetProposals } = getProposalsSlice.actions;
export default getProposalsSlice.reducer;
