import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch } from "hooks/useRedux";
import DealPipeline from "layout/sales_crm/DealPipeline";
import { useEffect, useState } from "react";
import { PagingMeta } from "redux/slices/sales/getProposals";
import getProposals from "redux/thunks/sales/getProposals";
import getLeads from "redux/thunks/sales_crm/getLeads";
import inCommunication from "redux/thunks/sales_crm/inCommunication";
import { Proposal } from "types/model/sales/Proposal";
import { Event } from "types/model/sales_crm/Event";

const initialPageMeta = {
  count: 0,
  limit: 50,
  offset: 0,
};

const Pipeline = () => {
  const { user } = useLoadAuth();
  const [userId, setUserId] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useAppDispatch();
  const [closedDeals, setClosedDeals] = useState<Proposal[]>([]);
  const [closedDealsMeta, setClosedDealsMeta] =
    useState<PagingMeta>(initialPageMeta);
  const [pendingProposalsMeta, setPendingProposalsMeta] =
    useState<PagingMeta>(initialPageMeta);
  const [in_communication, setInCommunication] = useState<Event[]>([]);
  const [pendingProposals, setPendingProposals] = useState<Proposal[]>([]);
  const [pendingLeads, setPendingLeads] = useState([]);
  const isAdmin = user?.user_role === USER_ROLE_ADMIN;

  const handleLoadMore = (currentTab = "closedDeals") => {
    setLoadingMore(true);

    if (currentTab === "Closed Deals") {
      dispatch(
        getProposals({
          proposal_status: "Approved",
          created_by: userId || user?.id || "",
          offset: closedDealsMeta.offset + closedDealsMeta.limit,
        })
      )
        .then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setClosedDeals((current) => [...current, ...res.payload.items]);
            setClosedDealsMeta(res.payload.meta);
          }
        })
        .finally(() => {
          setLoadingMore(false);
        });
    }

    if (currentTab === "Pending Proposals") {
      dispatch(
        getProposals({
          proposal_status: "Pending",
          created_by: userId || user?.id || "",
          offset: pendingProposalsMeta.offset + pendingProposalsMeta.limit,
        })
      )
        .then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setPendingProposals((current) => [
              ...current,
              ...res.payload.items,
            ]);
            setPendingProposalsMeta(res.payload.meta);
          }
        })
        .finally(() => {
          setLoadingMore(false);
        });
    }
  };

  const getData = () => {
    setClosedDeals([]);
    setClosedDealsMeta(initialPageMeta);
    setPendingProposalsMeta(initialPageMeta);
    setPendingProposals([]);

    dispatch(
      getProposals({
        proposal_status: "Approved",
        created_by: userId || user?.id || "",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setClosedDeals(res.payload.items);
        setClosedDealsMeta(res.payload.meta);
      }
    });

    dispatch(
      getProposals({
        proposal_status: "Pending",
        created_by: userId || user?.id || "",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPendingProposals(res.payload.items);
        setPendingProposalsMeta(res.payload.meta);
      }
    });

    dispatch(
      getLeads({
        assigned_to: userId || user?.id || "",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPendingLeads(res.payload.items);
      }
    });

    dispatch(inCommunication({ user_id: userId })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setInCommunication(res.payload.items);
      }
    });
  };

  useEffect(() => {
    getData();
  }, [userId]);

  return (
    <DealPipeline
      handleLoadMore={handleLoadMore}
      closedDealsMeta={closedDealsMeta}
      pendingProposalsMeta={pendingProposalsMeta}
      pendingProposals={pendingProposals}
      closedDeals={closedDeals}
      pendingLeads={pendingLeads}
      in_communication={in_communication}
      isAdmin={isAdmin}
      setUserId={setUserId}
      loadingMore={loadingMore}
    />
  );
};

export default Pipeline;
