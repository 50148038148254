import { Column } from "types/column";

const AGENCIES_COLUMNS = [
  "Date of Creation",
  "Agency Name",
  "Agency URL",
  "# of Programs Managed",
  ""
].map((it) => ({ isSortable: false, key: it, label: it })) as Column[];

export default AGENCIES_COLUMNS;
