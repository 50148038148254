import useIsFirstRender from "hooks/useIsFirstRender";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import Input from "./Input";
import { twMerge } from "tailwind-merge";

type Props = {
  label?: string;
  headerText?: string;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  values?: string[];
  urlPrefix?: string;
  id: string;
  wrapperClassName?: string;
};
function DynamicInput({
  label = "Item ",
  name,
  values,
  headerText,
  onChange: onMainFormChange,
  urlPrefix,
  id,
  wrapperClassName,
}: Props) {
  const inputLabel = urlPrefix || label;
  const inputName = name;
  const isFirsRender = useIsFirstRender();
  const initialValuesState: Record<string, any> = {};

  if (values?.length) {
    values.forEach((it, idx) => {
      initialValuesState[idx] = it;
    });
  }

  const [form, setForm] = useState<Record<string, any>>(initialValuesState);
  const inputInitialState = {
    name: "0",
    value: "",
  };
  const [inputs, setInputs] = useState([inputInitialState]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setForm((form) => ({ ...form, [name]: value }));
    setInputs((inputs) =>
      inputs.map((input) => {
        if (String(name) === String(input.name)) {
          return {
            ...input,
            value,
          };
        }
        return input;
      })
    );
  };

  const handleAddInput = () => {
    setInputs((inputs) => [...inputs, { name: `${inputs.length}`, value: "" }]);
  };

  const handleRemoveInput = (idx: number, name: string) => {
    const { [name]: matched, ...rest } = form;
    setForm(rest);

    setInputs((inputs) => inputs.filter((input, index) => index !== idx));
  };

  const getAllValues = () => {
    return Object.values(form);
  };

  const notifyChange = () => {
    onMainFormChange?.({
      target: {
        name: inputName,
        value: { [name]: getAllValues() },
      },
    } as unknown as ChangeEvent<HTMLInputElement>);
  };

  useEffect(() => {
    if (values?.length) {
      setInputs((inputs) =>
        values.map((value, index) => ({
          name: String(index),
          value,
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (!isFirsRender) {
      notifyChange();
    }
  }, [form]);

  return (
    <div>
      {headerText && <p>{headerText}</p>}
      <div className={wrapperClassName}>
        {inputs.map((input, index) => (
          <div key={index} className={twMerge("my-2")}>
            <Input
              name={String(index)}
              label={`${inputLabel} ${Number(index + 1)}`}
              id={id}
              onChange={onChange}
              value={input.value}
            />
            {inputs.length > 1 && (
              <small
                className="cursor-pointer"
                onClick={() => handleRemoveInput(index, input.name)}
              >
                Remove
              </small>
            )}
          </div>
        ))}

        {
          <small className="cursor-pointer" onClick={handleAddInput}>
            add another
          </small>
        }
      </div>
    </div>
  );
}

export default DynamicInput;
