const getFirstInt = (inputString: string) => {
  const numberPattern = /\d+/g;
  const matchedParts = inputString.match(numberPattern);

  return matchedParts?.join("") || null;
};
export default getFirstInt;

export const capitalizeKeys = (data: Array<Record<string, any>>) => {
  return data.map((obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key.charAt(0).toUpperCase() + key.slice(1),
        value,
      ])
    )
  );
};
