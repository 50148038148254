import AIFaqReviewRow from "components/advertisers/FaqReviewRow";
import DataTable from "components/common/layout/DataTable";
import SearchForm from "components/common/SearchForm";
import { AI_FAQ_REVIEW_COLUMNS } from "constants/tables/headers/bma/activePrograms";
import { ChangeEventHandler, useState } from "react";
import { ActionState, PageNumPaginatedActionState } from "types/data";
import { StoreFaqType } from "types/model/oma/Store";
import getHostName from "utils/urls/getHostName";

type Props = {
  aiFaqs: PageNumPaginatedActionState<StoreFaqType>;
  saveStoreFaqsData: ActionState;
  refreshStoreFaq: (storeId: string) => void;
  refreshStoreFaqData: ActionState;
  onPageChange: (page: number) => void;
  page: number;
  debouncedOnChange: ChangeEventHandler<HTMLInputElement>;
  searchText: string;
};

const AIFaqsLayout = ({
  aiFaqs,
  saveStoreFaqsData,
  refreshStoreFaq,
  refreshStoreFaqData,
  debouncedOnChange,
  onPageChange,
  page,
}: Props) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  return (
    <div className="flow-root">
      <div className="-mx-12 overflow-x-auto -mt-12">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="border-b dark:border-gray-700 mt-4">
            <div className="flex  justify-end py-3">
              <SearchForm
                wrapperClassName="w-56"
                placeholder="Search Stores..."
                onChange={debouncedOnChange}
              />
            </div>
          </div>
          <DataTable
            columns={AI_FAQ_REVIEW_COLUMNS}
            rows={aiFaqs.data.results?.map((el: Record<string, any>) => ({
              ...el,
              website: el.store.website_url,
              name: el.store.name,
              website_base: getHostName(el.store.website_url),
              network: el.store.primary_network?.name || "",
            }))}
            onShowAll={() => null}
            rowRenderer={(row) => (
              <>
                <AIFaqReviewRow
                  item={row}
                  key={row.id}
                  activeId={activeId}
                  setActiveId={setActiveId}
                  saveStoreFaqsData={saveStoreFaqsData}
                  refreshStoreFaqData={refreshStoreFaqData}
                  refreshStoreFaq={() => {
                    refreshStoreFaq(row.store.id);
                  }}
                />
              </>
            )}
            loading={aiFaqs.loading}
            currentPage={page}
            onPageChange={onPageChange}
            totalCount={aiFaqs.data.count}
          />
        </div>
      </div>
    </div>
  );
};

export default AIFaqsLayout;
