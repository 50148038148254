import dateFormat from "dateformat";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import TabCreateCoupon from "containers/oma/CreateUpdateCoupon";
import formatDate from "utils/dates/formatDate";

type Props = {
  item: Record<string, any>;
  onItemSelectChange: (item: Record<string, any>) => void;
  selectedItems: Array<Record<string, any>>;
  isAutoCoupons: boolean;
};

const ActiveCouponRow = ({
  item,
  selectedItems,
  onItemSelectChange,
  isAutoCoupons,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 "
  );

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        {!isAutoCoupons && (
          <td className="pl-4 py-2 w-4">
            <div className="flex items-center">
              <input
                id="checkbox-table-search-1"
                type="checkbox"
                checked={selectedItems.map((it) => it.id).includes(item.id)}
                onChange={() => onItemSelectChange(item)}
                className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500  focus:ring-2"
              />
              <label htmlFor="checkbox-table-search-1" className="sr-only">
                checkbox
              </label>
            </div>
          </td>
        )}

        {isAutoCoupons && (
          <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
            {dateFormat(new Date(item.date_created), "d/mm/yyyy, h:MM TT")}
          </td>
        )}

        <th
          scope="row"
          className="py-2 pl-4  font-medium text-gray-900 whitespace-nowrap max-w-xs"
        >
          {
            <Link
              to={`/oma/program?&slug=${item.store?.slug}&tab=offers`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.store?.name?.substring(0, 30)}
              {item.store?.name?.length > 30 ? "..." : ""}
            </Link>
          }
        </th>

        {!isAutoCoupons && (
          <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs">
            {item?.id || ""}
          </td>
        )}

        {!isAutoCoupons && (
          <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs">
            {item?.created_by.split("@")[0] || item.created_by_username || ""}
          </td>
        )}
        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          {item.offer_type}
        </th>
        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-sm
        "
        >
          <span className="relative block">
            {item.discount_code}
            {[...item.tags, (item.is_auto_coupon && "AI") || ""]
              .filter(Boolean)
              .map((el: string) => (
                <span
                  key={el}
                  className="bg-green-100 
                text-xs -top-2 mx-1 
                 text-green-800
               
                 p-0.5
                rounded
                 dark:bg-green-900
                dark:text-green-300"
                >
                  {el}
                </span>
              ))}
          </span>
        </th>
        <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
          <p
            className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
            style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
          >
            {item.title}
          </p>
        </td>

        {isAutoCoupons && (
          <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
            <p className="max-w-xs truncate whitespace-normal break-words">
              {item.original_description}
            </p>
          </td>
        )}

        <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
          <p className="max-w-xs truncate whitespace-normal break-words">
            {item.description}
          </p>
        </td>
        {!isAutoCoupons && (
          <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
            {item.start_date
              ? dateFormat(new Date(item.start_date), "d/mm/yyyy, h:MM TT")
              : ""}
          </td>
        )}
        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.expiration_date ? formatDate(item.expiration_date) : ""}
        </td>

        {!isAutoCoupons && (
          <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
            {formatDate(item.date_created)}
          </td>
        )}
        <td>
          <button
            type="button"
            onClick={() => {
              setIsEditing((isEditing) => !isEditing);
            }}
            className="inline-flex mr-4 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          >
            {isEditing ? "Cancel" : "Edit"}
          </button>
        </td>
      </tr>

      {isEditing && (
        <tr>
          <td colSpan={8}>
            <TabCreateCoupon
              initialForm={{ ...item, cancelFn: setIsEditing }}
            />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default ActiveCouponRow;
