import { currencyNameSymbolMap } from "constants/storeCurrencies";
import React from "react";
import { twMerge } from "tailwind-merge";
import formatDate from "utils/dates/formatDate";
import formatNumber from "utils/strings/formatNumber";
import { LOCAL_KEY_CLIENT_WEBSITES } from "constants/storageKeys";

type Props = {
  item: Record<string, any>;
  index: number;
  totals: Record<string, any>;
};

function AdCenterReportRow({
  item,
  index,
  totals,
}: Props) {
  const clients = JSON.parse(localStorage.getItem(LOCAL_KEY_CLIENT_WEBSITES)||"[]");

  const client = clients.items.find((cl:Record<string,any>) => String(cl.id) === String(item.program?.client||""))||{};
  
  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 cursor-pointer",
    index === 0 && "bg-green-100 font-bold"
  );

  const date = (item.transaction_created * 1000) as unknown as string;

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          
          {index === 0?"Total":formatDate(date)}
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.program?.name || ""}
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {client.name || ""}
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.billing_details?.email}
        </td>
        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.customer_id}
        </th>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals?.amount / 100, {
                decimals: 2,
                prefix: currencyNameSymbolMap["USD"],
              })
            : formatNumber(item?.amount_captured / 100, {
                decimals: 2,
                prefix: currencyNameSymbolMap[item.currency.toUpperCase()],
              })}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          <span
            className={twMerge(
              " p-1 rounded-md text-white",
              item.status === "failed" && "bg-red-600 ",
              item.status === "succeeded" && "bg-green-600 "
            )}
          >
            {item.status}
          </span>
        </td>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          <span className={twMerge("")}>{item.failure_code}</span>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default AdCenterReportRow;
