import dateFormat from "dateformat";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  item: Record<string, any>;
};

const CompetitorProgramRow = ({ item }: Props) => {
  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 "
  );

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {dateFormat(new Date(item.updated_at), "d/mm/yyyy, h:MM TT")}
        </td>
        <th
          scope="row"
          className="py-2 pl-4  font-medium text-gray-900 whitespace-nowrap max-w-xs"
        >
          {item.program_name}
        </th>
        <th
          scope="row"
          className="py-2 pl-4  font-medium text-gray-900 whitespace-nowrap max-w-xs"
        >
          {item.competitor.name}
        </th>
        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          {item.in_house_program?.name || "N/A"}
        </th>
        <th
          scope="row"
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap text-xs
        truncate max-w-xs
        "
        >
          {item.has_campaign ? "YES" : "NO"}
        </th>
      </tr>
    </React.Fragment>
  );
};

export default CompetitorProgramRow;
