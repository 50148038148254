import formatDate from "utils/dates/formatDate";

type Props = {
  row: Record<string, any>;
};

const ExtStandDownRow = ({ row }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatDate(row.time)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.websiteUrl}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.network}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.lastInitiator}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.userId}
      </td>
    </tr>
  );
};

export default ExtStandDownRow;
