import getEmailSubscribers from "redux/slices/marketing/email/getSubscribers";
import getCampaignSettings from "redux/slices/marketing/email/getCampaignSettings";
import createEmailCampaignSetting from "redux/slices/marketing/email/createEmailCampaignSetting";
import deleteEmailSubscriber from "redux/slices/marketing/email/deleteEmailSubscriber";
import getEmailCampaignSetting from "redux/slices/marketing/email/getEmailCampaignSetting";
import getEmailCampaigns from "redux/slices/marketing/email/getEmailCampaigns";
import getEmailCampaign from "redux/slices/marketing/email/getEmailCampaign";
import createEmailCampaign from "redux/slices/marketing/email/createEmailCampaign";
import sendEmailCampaign from "redux/slices/marketing/email/sendEmailCampaign";
import getEmailSubsReport from "redux/slices/marketing/email/getEmailSubsReport";
import getProgramEmailSubscribers from "redux/slices/marketing/email/getProgramSubscribers";
import getDeviceTypes from "redux/slices/marketing/sem/getDeviceTypes";
import getCompetitorPrograms from "redux/slices/marketing/sem/getCompetitorPrograms";
import getCompetitors from "redux/slices/marketing/sem/getCompetitors";
import getAIFaqs from "redux/slices/marketing/content/getAIFaqs";
import saveStoreFaqs from "redux/slices/marketing/content/saveStoreFaqs";
import regenerateStoreAIFaqs from "redux/slices/marketing/content/regenerateStoreAIFaqs";

const marketingReducers = {
  getEmailSubscribers,
  getCampaignSettings,
  createEmailCampaignSetting,
  getEmailCampaignSetting,
  getEmailCampaigns,
  getEmailCampaign,
  deleteEmailSubscriber,
  createEmailCampaign,
  sendEmailCampaign,
  getEmailSubsReport,
  getProgramEmailSubscribers,
  getDeviceTypes,
  getCompetitorPrograms,
  getCompetitors,
  getAIFaqs,
  saveStoreFaqs,
  regenerateStoreAIFaqs,
};

export default marketingReducers;
