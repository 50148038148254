import { Fragment, useState } from "react";
import Modal from "./Modal";
import TextArea from "./TextArea";
import { twMerge } from "tailwind-merge";

type Props = {
  onClick: (reason?: string) => void;
  text?: string;
  deleteLoading: boolean;
  allowReason?: boolean;
  btnClassName?: string;
};

const DeleteButton = ({
  onClick,
  deleteLoading,
  text,
  btnClassName,
  allowReason,
}: Props) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirm_reason, setConfirmReason] = useState("");
  const [error, setError] = useState("");
  return (
    <Fragment>
      <Modal
        wrapperClassName={twMerge(!allowReason && "w-72 h-52 min-h-52")}
        onConfirm={() => {
          if (allowReason) {
            if (confirm_reason.trim().length > 0) {
              onClick(confirm_reason);
              setError("");
            } else {
              setError("This field is required");
            }
          } else {
            onClick();
          }
        }}
        open={confirmOpen}
        loading={deleteLoading}
        setOpen={setConfirmOpen}
        variant={"sm"}
        doneText="Proceed"
        doneBtnClass="bg-red-400 hover:bg-red-700"
      >
        <p>Are you sure you want to proceed?</p>

        {allowReason && (
          <div className="mt-2">
            <TextArea
              placeholder="Enter a reason for the deletion"
              rows={7}
              error={error}
              onChange={(e) => {
                setConfirmReason(e.target.value);
              }}
              wrapperClassName="w-full my-4"
            />
          </div>
        )}
      </Modal>
      <button
        type="button"
        onClick={() => {
          setConfirmOpen(true);
        }}
        className={twMerge(
          "inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50",
          btnClassName
        )}
      >
        {text || "X"}
      </button>
    </Fragment>
  );
};

export default DeleteButton;
