function getRandomItem() {
  const arr = [
    "#FFC312",
    "#ED4C67",
    "#009432",
    "#1289A7",
    "#C4E538",
    "#FDA7DF",
    "#9980FA",
    "#F79F1F",
    "#12CBC4",
  ];
  // get random index value
  const randomIndex = Math.floor(Math.random() * arr.length);

  // get random item
  const item = arr[randomIndex];

  return item;
}

export default getRandomItem;
