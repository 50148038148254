import MappMerchantCard from "components/advertisers/MappMerchantCard";
import EmptyState from "components/common/EmptyState";
import Spinner from "components/common/layout/Spinner";
import SearchBar from "components/program/SearchBar";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { ChangeEventHandler, useState } from "react";
import { PaginatedActionState } from "types/data";
import { Advertiser } from "types/model/Advertiser";

type Props = {
  merchant: Record<string, any>;
  loadingCardId: null | string | number;
  onBackPressed: () => void;
  getAdvertisersData: PaginatedActionState<Advertiser>;
  onMakePrimary: (adv: Advertiser) => void;
  setFree: (adv: Record<string, any>) => void;
  handleIgnoreAdvertiser: (adv: Advertiser) => void;
  removePrimaryNetwork: (adv: Advertiser) => void;
  onLinkNetwork: (adv: Advertiser, args: Record<string, any>) => void;
  isProgramsPage: boolean;
  loading?: boolean;
  onLookupResultClick: (res: Record<string, any>) => void;
  onLookupResultClickOutside: () => void;
  lookUpResults: Array<Advertiser>;
  onLookupChange: ChangeEventHandler<HTMLInputElement>;
  onLookupSubmit: () => void;
  searchLoading: boolean;
};

function BMAMerchantMappingComponent({
  merchant,
  onMakePrimary,
  getAdvertisersData: { data },
  onLinkNetwork,
  onBackPressed,
  handleIgnoreAdvertiser,
  removePrimaryNetwork,
  loadingCardId,
  isProgramsPage,
  setFree,
  loading,
  onLookupResultClick,
  onLookupResultClickOutside,
  lookUpResults,
  onLookupChange,
  onLookupSubmit,
  searchLoading,
}: Props) {
  const [isIgnoredVisible, setIsIgnoredVisible] = useState(false);
  const clientId =
    Number(localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)) || 0;

  const filteredItems = data.items
    .filter((item) => {
      const isPrimary =
        (item.external_id === merchant.primary_network?.network_mid &&
          item.network === merchant.primary_network?.name) ||
        false;

      const isIgnored = item.mapping_ignored_for?.includes(clientId);

      if (isPrimary) {
        return true;
      }

      return !isIgnored;
    })
    .sort((a, b) => {
      const isPrimary =
        (a.external_id === merchant.primary_network?.network_mid &&
          a.network === merchant.primary_network?.name) ||
        false;

      if (isPrimary) {
        return -1;
      }

      return 1;
    });

  const ignoredItems = data.items.filter((item) => {
    const isPrimary =
      (item.external_id === merchant.primary_network?.network_mid &&
        item.network === merchant.primary_network?.name) ||
      false;

    const isIgnored = item.mapping_ignored_for?.includes(clientId);

    if (isPrimary) {
      return false;
    }

    return isIgnored;
  });

  const isFree = merchant.primary_network?.name === "Free";

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      {!isProgramsPage && (
        <button
          type="button"
          className="px-4 py-2 text-sm font-medium  border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg bg-gray-100 text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 mt-4 mb-8"
          onClick={onBackPressed}
        >
          Back to List
        </button>
      )}

      {loading && <Spinner />}
      <div>
        <small className="mx-4 block">{merchant.name}</small>
        <small className="mx-4 block">{merchant.website_url}</small>
        <small className="mx-4 block">{merchant.affiliate_website_url}</small>
      </div>

      {data.items.length === 0 && !loading && (
        <EmptyState description="No Programs found" />
      )}

      <div className="flex items-center justify-between my-4">
        <div className="flex items-center gap-2 my-4">
          {isFree && <p className="text-sm">Network status is set to Free</p>}
          <h3
            onClick={() => setFree(merchant)}
            className="underline cursor-pointer text-indigo-600"
          >
            {isFree ? "" : "Set to free"}
          </h3>
        </div>
        <div>
          <SearchBar
            onResultClick={onLookupResultClick}
            wrapperClass="flex-0 min-w-[350px]"
            onResultsOutsideClick={onLookupResultClickOutside}
            results={lookUpResults}
            onChange={onLookupChange}
            onSearchSubmit={onLookupSubmit}
            placeholder="Custom Look up"
            renderNetwork
            searchLoading={searchLoading}
          />
        </div>
      </div>

      {!loading && (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {filteredItems.map((el) => (
            <MappMerchantCard
              store={merchant}
              removePrimaryNetwork={removePrimaryNetwork}
              loadingCardId={loadingCardId}
              onMakePrimary={onMakePrimary}
              onLinkNetwork={onLinkNetwork}
              advertiser={el}
              key={el.id}
              handleIgnoreAdvertiser={handleIgnoreAdvertiser}
            />
          ))}
        </ul>
      )}

      {!loading && ignoredItems.length > 0 && (
        <div className="mt-8">
          <h1
            onClick={() => {
              setIsIgnoredVisible((val) => !val);
            }}
            className="text-sm cursor-pointer underline font-semibold"
          >
            Ignored Suggestions
          </h1>

          {isIgnoredVisible && (
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {ignoredItems.map((el) => (
                <MappMerchantCard
                  store={merchant}
                  key={el.id}
                  ignored
                  removePrimaryNetwork={removePrimaryNetwork}
                  loadingCardId={loadingCardId}
                  onMakePrimary={onMakePrimary}
                  onLinkNetwork={onLinkNetwork}
                  advertiser={el}
                  handleIgnoreAdvertiser={handleIgnoreAdvertiser}
                />
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

export default BMAMerchantMappingComponent;
