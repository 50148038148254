import AdvertiserFilters from "components/advertisers/AdvertiserStatusFilters";
import AdvertiserRow from "components/advertisers/AdvertiserRow";
import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  Fragment,
  SetStateAction,
} from "react";
import { Option } from "types/option";
import DataTable from "components/common/layout/DataTable";
import ADVERTISER_COLUMNS from "constants/tables/headers/bma/advertisers";
import SearchInput from "components/common/SearchInput";
import Dropdown from "components/common/Dropdown";
import { PaginatedData } from "types/data";
import { Advertiser } from "types/model/Advertiser";

type Props = {
  data: PaginatedData<Advertiser>;
  onPageChange: (page: number) => void;
  error?: Record<string, any> | null;
  loading: boolean;
  goToAdd: () => void;
  networkOptions: Option[];
  onSelectedNetworkChange: ChangeEventHandler<HTMLSelectElement>;
  onStatusChange: ChangeEventHandler<HTMLInputElement>;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  network_status: string;
  currentPage: number;
  searchTxtValue?: string;
  onActivatePressed: (adv: any) => void;
  selectedItems: Advertiser[];
  onItemSelectChange: (adv: Advertiser) => void;
  handleIgnoreAdvertisers: (item?: Advertiser) => void;
  ignoreLoading: boolean;
  setAllSelected: Dispatch<SetStateAction<boolean>>;
  allSelected: boolean;
  queryParamValues: Record<string, any>;
  has_open_ppc_policy: string;
  has_no_faqs: string;
  is_free: string;
  is_ad_center: string;
  is_deactivated: string;
};

const BMAHome = ({
  data,
  loading,
  onSearchSubmit,
  onSearchChange,
  onPageChange,
  goToAdd,
  onStatusChange,
  onSelectedNetworkChange,
  network_status,
  networkOptions,
  onActivatePressed,
  searchTxtValue,
  currentPage,
  onItemSelectChange,
  selectedItems,
  ignoreLoading,
  handleIgnoreAdvertisers,
  error,
  setAllSelected,
  allSelected,
  has_open_ppc_policy,
  has_no_faqs,
  is_free,
  is_deactivated,
  is_ad_center,
  queryParamValues,
}: Props) => {
  if (error?.detail) {
    return <p>{error?.detail}</p>;
  }
  return (
    <DataTable
      currentPage={currentPage}
      rows={data.items}
      onPageChange={onPageChange}
      loading={loading}
      totalCount={data.meta.count}
      onEmptyActionClick={goToAdd}
      header={
        <Fragment>
          <div className="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3">
            <div className="w-full lg:w-2/3 flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
              <SearchInput
                onSubmit={onSearchSubmit}
                onChange={onSearchChange}
                value={searchTxtValue}
              />
              <div className="flex items-center space-x-4">
                <div>
                  <Dropdown
                    options={networkOptions}
                    name="search"
                    value={queryParamValues.network_id}
                    placeholder="Select networks"
                    onChange={onSelectedNetworkChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <AdvertiserFilters
            is_ad_center={is_ad_center}
            is_deactivated={is_deactivated}
            exportResourceData={{
              loading: false,
              data: "",
              error: null,
            }}
            has_open_ppc_policy={has_open_ppc_policy}
            has_no_faqs={has_no_faqs}
            has_ai_faqs={queryParamValues.has_ai_faqs}
            is_free={is_free}
            activeTab="0"
            onChange={onStatusChange}
            network_status={network_status}
            selectedItems={selectedItems}
            programType="Pending"
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            handleIgnoreAdvertisers={handleIgnoreAdvertisers}
          />
        </Fragment>
      }
      columns={ADVERTISER_COLUMNS}
      rowRenderer={(row: any) => (
        <AdvertiserRow
          onActivatePressed={onActivatePressed}
          item={row}
          handleIgnoreAdvertisers={handleIgnoreAdvertisers}
          key={row.id}
          ignoreLoading={ignoreLoading}
          checked={selectedItems.map((el) => el.id).includes(row.id)}
          onItemSelectChange={() => {
            onItemSelectChange(row);
          }}
        />
      )}
    />
  );
};

export default BMAHome;
