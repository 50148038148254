/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createUpdateCoupon from "redux/thunks/app/api-clients/createUpdateCoupon";
import createUpdateStore from "redux/thunks/app/api-clients/createUpdateStore";
import getProgram from "redux/thunks/app/api-clients/getProgram";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const getProgramSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    resetProgram: () => initialState,
    setProgramDetails: (state, { payload }) => ({ ...state, data: payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProgram.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getProgram.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload.count === 1 ? payload.results?.[0] : payload,
              loading: false,
            };
          }
        }
      )

      .addCase(createUpdateCoupon.fulfilled, (state, action: any) => {
        const { payload } = action;

        if ({ payload }) {
          return {
            ...state,
            data: action.meta?.arg?.data?.activateNew
              ? {
                  ...state.data,
                  number_of_coupons: state.data.number_of_coupons + 1,
                }
              : payload.deactivated
              ? {
                  ...state.data,
                  number_of_coupons: state.data.number_of_coupons - 1,
                }
              : state.data,
          };
        }
      })

      .addCase(
        createUpdateStore.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getProgram.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetProgram, setProgramDetails } = getProgramSlice.actions;
export default getProgramSlice.reducer;
