import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getHomeCoupons from "redux/thunks/app/placements/campaigns/getHomeCoupons";

export type ActionStateType = {
  data: Record<string, any>[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getHomeCouponsSlice = createSlice({
  name: "getHomeCoupons-",
  initialState,
  reducers: {
    clearSpotlightResults: () => {
      return initialState;
    },

    updateHomeCoupons: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((el) => (el.id === payload.id ? payload : el)),
      };
    },

    removeHomeCoupons: (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter((el) => el.coupon.id !== payload.coupon.id),
      };
    },

    setHomeCoupons: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },

    addToHomeCoupons: (state, { payload }) => {
      return {
        ...state,
        data: [payload, ...state.data],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeCoupons.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        getHomeCoupons.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getHomeCoupons.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const {
  clearSpotlightResults,
  updateHomeCoupons,
  addToHomeCoupons,
  setHomeCoupons,
  removeHomeCoupons,
} = getHomeCouponsSlice.actions;
export default getHomeCouponsSlice.reducer;
