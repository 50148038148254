import addHttp from "utils/urls/addHttp";

type Props = {
  row: Record<string, any>;
  onDetailsClick: () => void;
};

const ExtApiStoreRow = ({ row, onDetailsClick }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.date}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <a
          href={addHttp(row.domain)}
          target="_blank"
          rel="noreferrer"
          className="font-medium text-primary-600 hover:underline inline-flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
          </svg>
          {row.domain}
        </a>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.visits}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.unique_visits}
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.ext_trigger_counts || 0}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.unique_ext_trigger_counts || 0}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.copy_count || 0}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.unique_copy_count || 0}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.unique_ext_trigger_counts == 0
          ? "100%"
          : (row.unique_copy_count / row.unique_ext_trigger_counts) * 100 + "%"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.coupon_sum > 0 ? "YES" : "NO"}
      </td>
      <td>
        <button
          onClick={onDetailsClick}
          className="rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-sm"
        >
          View
        </button>
      </td>
    </tr>
  );
};

export default ExtApiStoreRow;
