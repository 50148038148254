import CommissionRateHistory from "layout/bma/CommissionRateHistory";
import { useLocation } from "react-router-dom";

const CommissionRateChangeHistory = () => {
  const { state } = useLocation();

  return <CommissionRateHistory state={state || {}} />;
};

export default CommissionRateChangeHistory;
