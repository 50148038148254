import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import Thunk from "../Thunk";

export default Thunk("delete-coupons", async (payload: Record<string, any>) => {
  return instance.post(
    DISCOUNT_CODES_API + `/coupons/bulk-deactivate/`,
    payload
  );
});
