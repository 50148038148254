import getAllUsers from "redux/slices/users/getAllUsers";
import getApps from "redux/slices/users/getApps";
import createUser from "redux/slices/users/createUser";
import loginUser from "redux/slices/users/loginUser";
import getAuthUser from "redux/slices/users/getAuthUser";

const userReducers = {
  getAllUsers,
  user: getAuthUser,
  createUser,
  loginUser,
  getApps,
};
export default userReducers;
