import Input from "components/common/Input";
import React, { ChangeEventHandler } from "react";

type Props = {
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const Contact = ({ form, onChange }: Props) => {
  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      <div className="flex items-center gap-4 ">
        <div className="flex-1">
          <Input
            label="Twitter"
            onChange={onChange}
            name="twitter"
            value={form?.twitter || ""}
          />
        </div>

        <div className="flex-1">
          <Input
            onChange={onChange}
            // error={errors.description}
            label="Facebook"
            name="fb"
            value={form?.fb || ""}
          />
        </div>
      </div>

      <div className="flex items-center gap-4 my-4 ">
        <div className="flex-1">
          <Input
            label="Instagram"
            onChange={onChange}
            name="instagram"
            value={form?.instagram || ""}
          />
        </div>

        <div className="flex-1">
          <Input
            onChange={onChange}
            // error={errors.description}
            label="Pinterest"
            name="pinterest"
            value={form?.pinterest || ""}
          />
        </div>
      </div>

      <div className="flex items-center gap-4 my-4 ">
        <div className="flex-1">
          <Input
            label="Wikipedia"
            onChange={onChange}
            name="wikipedia"
            value={form?.wikipedia || ""}
          />
        </div>

        <div className="flex-1">
          <Input
            onChange={onChange}
            // error={errors.description}
            label="Snapchat"
            name="snapchat"
            value={form?.snapchat || ""}
          />
        </div>
      </div>

      <div className="flex items-center gap-4 my-4 ">
        <div className="flex-1">
          <Input
            label="Tiktok"
            onChange={onChange}
            name="tiktok"
            value={form?.tiktok || ""}
          />
        </div>

        <div className="flex-1">
          <Input
            onChange={onChange}
            // error={errors.description}
            label="Youtube"
            name="youtube"
            value={form?.youtube || ""}
          />
        </div>
      </div>

      <div className="flex items-center gap-4 my-4 ">
        <div className="flex-1">
          <Input
            label="Phone Number"
            onChange={onChange}
            name="phone_number"
            value={form?.phone_number || ""}
          />
        </div>

        <div className="flex-1">
          <Input
            onChange={onChange}
            // error={errors.description}
            label="Email Address"
            name="email_address"
            value={form?.email_address || ""}
          />
        </div>
      </div>

      <div className="flex items-center gap-4 my-4 ">
        <div className="flex-1">
          <Input
            label="Blog URL"
            onChange={onChange}
            name="blog_url"
            value={form?.blog_url || ""}
          />
        </div>

        <div className="flex-1">
          <Input
            label="Tumblr URL"
            onChange={onChange}
            name="tumblr_url"
            value={form?.tumblr_url || ""}
          />
        </div>
      </div>
    </section>
  );
};

export default Contact;
