import { SALES_APP } from "constants/smaAppNames";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import LeadManagement from "layout/sales_crm/components/LeadManagement";
import { ChangeEventHandler, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import editDeleteLead from "redux/thunks/sales_crm/editDeleteLead";
import getLeadTopics from "redux/thunks/sales_crm/getLeadTopics";
import getLeads from "redux/thunks/sales_crm/getLeads";
import getUsers from "redux/thunks/users/getUsers";
import { User } from "types/model/User";
import { Lead } from "types/model/sales_crm/Lead";

const Leads = () => {
  const { user } = useLoadAuth();
  const navigate = useNavigate();
  const leads = useAppSelector((state) => state.getLeads);

  const [salesUsers, setUsers] = useState<User[]>([]);
  const [params] = useSearchParams();
  const getAllUsers = useAppSelector((state) => state.getAllUsers);
  const dispatch = useAppDispatch();

  const {
    assigned_to = null,
    page = 1,
    status = "Pending",
  } = Object.fromEntries([...params]);

  const filterValues = { assigned_to, page, status };

  useEffect(() => {
    dispatch(getLeads({ page: 1, status: status || "" }));
    dispatch(getLeadTopics());
  }, []);

  useEffect(() => {
    if (getAllUsers.data.meta.count === 0) {
      dispatch(getUsers({ page: 1 }));
    }
  }, [getAllUsers.data.meta.count]);

  const updateUsers = () => {
    const filteredUsers: User[] = [];

    getAllUsers.data.items.forEach((user) => {
      if (user.user_role === USER_ROLE_ADMIN.toUpperCase()) {
        filteredUsers.push(user);
      } else if (
        user.user_apps
          ?.map((el) => el.name.toLowerCase())
          ?.includes(SALES_APP.toLowerCase())
      ) {
        filteredUsers.push(user);
      }
    });

    setUsers(filteredUsers);
  };

  useEffect(() => {
    if (getAllUsers.data.meta.count > 0) {
      updateUsers();
    }
  }, [getAllUsers.data.meta.count]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    const url = new URL(window.location.href);
    url.searchParams.set(name, String(value));
    navigate(url.search);
  };

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  useEffect(() => {
    if (page) {
      dispatch(
        getLeads({
          page,
          status: status || "",
        })
      );
    }
  }, [page]);

  useEffect(() => {
    dispatch(
      getLeads({
        status: status || "",
        assigned_to: status === "Accepted" ? user?.id : "",
        page: 1,
      })
    );
  }, [assigned_to, status]);

  const onAssign = (lead: Lead) => {
    const toastId = toast.loading("Saving...");
    if (user) {
      dispatch(
        editDeleteLead({
          id: lead.id,
          assigned_to: user.id,
          partialEdit: true,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss(toastId);
          toast.success("Lead assigned successfully");
          dispatch(getLeads({ page: 1 }));
        }
      });
    }
  };

  const isAdmin = user?.user_role === USER_ROLE_ADMIN;

  return (
    <LeadManagement
      isAdmin={isAdmin}
      filterValues={filterValues}
      onChange={onChange}
      salesUsers={salesUsers}
      leads={leads}
      onAssign={onAssign}
      onPageChange={onPageChange}
    />
  );
};

export default Leads;
