import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import Spinner from "components/common/layout/Spinner";
import ExcludedStoreRow from "components/extension/ExcludedStoreRow";
import EXCLUDED_STORE_COLUMNS from "constants/tables/headers/extension/excludedStores";
import SearchPrograms from "containers/oma/SearchPrograms";
import { ActionState, PageNumPaginatedActionState } from "types/data";
import { ExcludedStore } from "types/model/ExcludedStore";
import { Store } from "types/model/oma/Store";

type Props = {
  getExcludedStoresData: PageNumPaginatedActionState<ExcludedStore>;
  handleAddExclusion: (store: Store) => void;
  handleDeleteExclusion: (store: ExcludedStore) => void;
  deleteExcludedStoreData: ActionState;
  excludeStoreData: ActionState;
  initialSearchTxt: string;
};

const BrowserExtStoreExclusionsLayout = ({
  getExcludedStoresData,
  handleAddExclusion,
  handleDeleteExclusion,
  deleteExcludedStoreData,
  excludeStoreData,
  initialSearchTxt,
}: Props) => {
  const renderRow: RowRenderer<ExcludedStore> = (row) => {
    return (
      <ExcludedStoreRow
        handleDeleteExclusion={handleDeleteExclusion}
        item={row}
        key={row.id}
        deleteExcludedStoreData={deleteExcludedStoreData}
      />
    );
  };

  return (
    <div className="my-4">
      <SearchPrograms
        wrapperClassName="w-56 mb-4"
        onResultClicked={handleAddExclusion}
        value={initialSearchTxt}
      />
      {excludeStoreData.loading && <Spinner />}
      <DataTable
        columns={EXCLUDED_STORE_COLUMNS}
        rows={getExcludedStoresData.data.results}
        rowRenderer={renderRow as RowRenderer<Record<string, any>>}
        loading={getExcludedStoresData.loading}
        currentPage={1}
        totalCount={getExcludedStoresData.data.count}
      />
    </div>
  );
};

export default BrowserExtStoreExclusionsLayout;
