import { websitesNameIdMap } from "constants/clientWebsitesIds";
import dayjs from "dayjs";

const getAvailablePlacementTypes = (
  placementTypeOptions: Array<string>,
  placements: Array<Record<string, any>>,
  form: Record<string, any>,
  formPage: string
) => {
  const start_date = dayjs(form.start_date).format("YYYY-MM-DD");
  const end_date = dayjs(form.end_date).format("YYYY-MM-DD");

  return placementTypeOptions

    .filter((it) => {
      if (
        placements.map((el) => el.page).includes(formPage) &&
        placements.map((el) => el.start_date).includes(start_date) &&
        placements.map((el) => el.end_date).includes(end_date) &&
        placements.map((el) => String(el.client.id)).includes(form.client)
      ) {
        if (placements.map((el) => el.placement_type).includes(it)) {
          return false;
        }
      }
      if (form.client == websitesNameIdMap["promo-codes.com"]) {
        if (formPage === "Homepage") {
          return !["Featured Coupon"].includes(it);
        }
      }

      if (
        form.client != websitesNameIdMap["promo-codes.com"] &&
        form.client != websitesNameIdMap["discount-codes.com"]
      ) {
        if (formPage === "Homepage") {
          return [
            "Navigation Bar - Featured Store Listing",
            "Hero Coupons",
            "Coupon Listings",
            "Top Stores - Logo Placement",
          ].includes(it);
        }
      }

      if (formPage === "Newsletters") {
        return ["Coupon Listings", "Featured Coupon"].includes(it);
      }

      if (
        form.page === "Custom" &&
        form.client !== websitesNameIdMap["discount-codes.com"]
      ) {
        return ["Coupon Listings", "Top Stores - Logo Placement"].includes(it);
      }

      if (form.page === "Custom") {
        return it === "Coupon Listings";
      }

      return it;
    })
    .map((el) => ({ label: el, value: el }));
};
export default getAvailablePlacementTypes;
