import {
  OFFER_TYPE_SITE_WIDE_AMOUNT,
  OFFER_TYPE_SITE_WIDE_PERCENT,
  OFFER_TYPE_FREE_SHIPPING,
  OFFER_TYPE_GENERIC_FREE_SHIPPING,
  OFFER_TYPE_SALES_DEALS_CLEARANCE,
  OFFER_TYPE_BOGO,
  OFFER_TYPE_NEWS_LETTER_SIGNUP,
  OFFER_TYPE_FREE_PRODUCT,
  OFFER_TYPE_REFER_A_FRIEND,
  OFFER_TYPE_OTHER,
} from "./offerTypes";

export const offerFeatures = [
  "mobile only",
  "students only",
  "new customers only",
  "military discount",
];

export const offerSources = [
  "affiliate_newsletter",
  "affiliate_network",
  "store_website_directly",
  "store_email_newsletter",
  "competitor_website",
  "social_media",
  "users_added",
  "other",
];

export const offerTypes = [
  OFFER_TYPE_SITE_WIDE_AMOUNT,
  OFFER_TYPE_SITE_WIDE_PERCENT,
  OFFER_TYPE_SALES_DEALS_CLEARANCE,
  OFFER_TYPE_FREE_SHIPPING,
  OFFER_TYPE_GENERIC_FREE_SHIPPING,
  OFFER_TYPE_BOGO,
  OFFER_TYPE_NEWS_LETTER_SIGNUP,
  OFFER_TYPE_FREE_PRODUCT,
  OFFER_TYPE_REFER_A_FRIEND,
  OFFER_TYPE_OTHER,
];
