import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getStoreExtSettings from "redux/thunks/extension/getStoreExtSettings";
import { PageNumPaginatedActionState } from "types/data";
import { ExcludedStore } from "types/model/ExcludedStore";
import updateStoreExtSettings from "redux/thunks/extension/updateStoreExtSettings";
import createStoreExtSetting from "redux/thunks/extension/createStoreExtSetting";

const initialState: PageNumPaginatedActionState<ExcludedStore> = {
  data: {
    results: [],
    count: 0,
    page: 1,
    next: null,
    previous: null,
  },
  loading: false,
  error: null,
};

const getStoreExtSettingsSlice = createSlice({
  name: "getStoreExtSettingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoreExtSettings.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
        data: initialState.data,
      }))

      .addCase(getStoreExtSettings.fulfilled, (state, action: any) => {
        const { payload } = action;
        if ({ payload }) {
          return {
            ...state,
            data: payload,

            loading: false,
          };
        }
      })

      .addCase(updateStoreExtSettings.fulfilled, (state, action: any) => {
        return {
          ...state,
          data: {
            ...state.data,
            results: state.data.results.filter(
              (el) => el.id != action.meta?.arg.id
            ),
          },
        };
      })

      .addCase(createStoreExtSetting.fulfilled, (state, action: any) => {
        const { payload } = action;
        if ({ payload }) {
          return {
            ...state,
            data: {
              ...state.data,
              results: [
                { ...payload, store: action.meta.arg.store },
                ...state.data.results,
              ],
            },

            loading: false,
          };
        }
      })

      .addCase(
        getStoreExtSettings.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getStoreExtSettingsSlice.reducer;
