import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import RequestedLeadsLayout from "layout/sales_crm/components/RequestedLeadsLayout";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import approveRequest from "redux/thunks/sales_crm/approveRequest";
import getOwnerShipRequests from "redux/thunks/sales_crm/getOwnerShipRequests";

const RequestedLeads = () => {
  const { user } = useLoadAuth();
  const leads = useAppSelector((state) => state.getOwnerShipRequests);
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();

  const { status = "pending" } = Object.fromEntries([...params]);

  const filterValues = { status };

  useEffect(() => {
    dispatch(
      getOwnerShipRequests({
        status: status || "",
      })
    );
  }, [status]);

  const changeStatus = (id: string, status: string) => {
    const toastId = toast.loading("Saving...");
    if (user) {
      dispatch(
        approveRequest({
          id: id,
          status: status,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss(toastId);
          toast.success(`Request ${status} successfully`);
          dispatch(getOwnerShipRequests({ status: "pending" }));
        }
      });
    }
  };

  const isAdmin = user?.user_role === USER_ROLE_ADMIN;

  return (
    <RequestedLeadsLayout
      isAdmin={isAdmin}
      filterValues={filterValues}
      leadRequests={leads}
      changeStatus={changeStatus}
    />
  );
};

export default RequestedLeads;
