import { twMerge } from "tailwind-merge";
import getRandomItem from "utils/strings/getRandomColor";

type Props = {
  first_name: string;
  last_name: string;
  className?: string;
};

const Initial = ({ first_name, last_name, className }: Props) => {
  const initials = first_name[0] + last_name[0];

  const bgColor = getRandomItem();

  const cls = twMerge(
    "w-8 h-8 text-white flex text-2.5xl font-inter font-bold items-center justify-center rounded-full uppercase",
    className,
  );

  return (
    <div className={cls} style={{ background: bgColor }}>
      <p>{initials}</p>
    </div>
  );
};

export default Initial;
