import { useEffect, useState } from "react";
import OMACategories from "layout/oma/OMACategories";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProgramCategory } from "types/model/Category";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

const Categories = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [filteredByLevelData, setFilteredByLevelData] = useState<
    ProgramCategory[]
  >([]);
  const { data: clientsList } = useAppSelector((state) => state.getClients);
  const { loading, data } = useAppSelector((state) => state.getCategories);

  useEffect(() => {
    dispatch(getCategories({}));
  }, [localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)]);

  const navigateToLevel = (level: string) => {
    navigate(`/oma/categories?level=${level}`);
  };

  const navigateToCreate = () => {
    navigate(`/oma/categories/category`);
  };

  const navigateToClientCategory = (slug: string) => {
    const client = clientsList.items.find(
      (it) =>
        String(it.id) === localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)
    );
    if (client?.website_url) {
      window?.open(`${client.website_url}/explore/${slug}`, "_blank")?.focus();
    }
  };

  const navigateToDetail = (id: string) => {
    navigate(`/oma/categories/${id}`);
  };

  const navigateToEdit = (id: string) => {
    navigate(`/oma/categories/category/?id=${id}`);
  };

  const level = params.get("level") || "0";

  useEffect(() => {
    if (data) {
      if (level === "3") {
        setFilteredByLevelData(data.filter((it) => it.is_seasonal));
      } else if (level === "4") {
        setFilteredByLevelData(data.filter((it) => it.is_homepage));
      } else if (level == "0") {
        setFilteredByLevelData(
          data.filter(
            (it) => !it.is_homepage && !it.is_seasonal && it.level == 0
          )
        );
      } else {
        setFilteredByLevelData(data.filter((it) => String(it.level) === level));
      }
    }
  }, [params, level, data]);

  return (
    <OMACategories
      navigateToLevel={navigateToLevel}
      loading={loading}
      level={level}
      navigateToClientCategory={navigateToClientCategory}
      data={filteredByLevelData}
      navigateToCreate={navigateToCreate}
      navigateToDetail={navigateToDetail}
      navigateToEdit={navigateToEdit}
    />
  );
};

export default Categories;
