import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "emails/send-email-campaign",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      if (payload.preview) {
        const response = await axios.post(
          `/admin/emails/send-test-mail`,
          payload
        );
        return response.data;
      } else {
        const response = await axios.post(
          `/admin/emails/email-campaign/send-now`,
          payload
        );
        return response.data;
      }
      
      
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
