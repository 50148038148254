const CATEGORY_COUPONS = [
  {
    key: "Logo",
    label: "Logo",
    isSortable: true,
  },

  {
    key: "Program Name",
    label: "Program Name",
    isSortable: true,
  },

  {
    key: "Affiliate Network",
    label: "Affiliate Network",
    isSortable: true,
  },

  {
    key: "Coupon ID",
    label: "Coupon ID",
    isSortable: true,
  },

  {
    key: "Coupon Title",
    label: "Coupon Title",
    isSortable: true,
  },

  {
    key: "Coupon Description",
    label: "Coupon Description",
    isSortable: true,
  },

  {
    key: "Code",
    label: "Code",
    isSortable: true,
  },

  {
    key: "Expiration Date",
    label: "Expiration Date",
    isSortable: true,
  },
];
export default CATEGORY_COUPONS;
