import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import Thunk from "../app/Thunk";

export default Thunk(
  "updateNetworkExtSettings",
  async (payload: Record<string, any>) => {
    const requestPayload = {
      ...payload,
      network: payload.network,
    };
    delete requestPayload.network;

    return instance.patch(
      DISCOUNT_CODES_API +
        `/extension/network-extension-settings/${payload.id}`,
      requestPayload
    );
  }
);
