import classNames from "classnames";
import SearchInputComponent from "components/common/SearchInputComponent";
import { BACKEND_BASE_URL } from "config/env";
import instance from "helpers/axios";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { twMerge } from "tailwind-merge";

type Props = {
  onResultClick: (result: Record<string, any>) => void;
  placeholder: string;
  value: string;
};

const SearchAgencies = ({ onResultClick, placeholder, value }: Props) => {
  const [results, setResults] = useState<any[]>([]);
  const endpoint = `${BACKEND_BASE_URL}/admin/sales-crm/agencies/?offset=0`;

  const handleResults = (data: any) => {
    setResults(data.items);
  };

  return (
    <>
      <SearchInputComponent
        placeholder={placeholder}
        endpoint={endpoint}
        onResults={handleResults}
        client={instance}
        label="Agency Name"
        value={value}
      />
      <div className="relative mt-4">
        <OutsideClickHandler
          onOutsideClick={() => {
            setResults([]);
          }}
        >
          {results?.length > 0 && (
            <ul
              className={twMerge(
                "absolute z-10 mt-1 max-h-60  w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              )}
            >
              {results.map((result) => (
                <li
                  key={result.id}
                  onClick={() => {
                    onResultClick(result);
                    setResults([]);
                  }}
                  className={classNames(
                    "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 hover:font-semibold",
                    false ? "bg-indigo-600 text-white" : "text-gray-900"
                  )}
                >
                  <span className={classNames("block truncate")}>
                    {result.name}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </OutsideClickHandler>

        {/* {results?.length > 0 && (
          <div className="absolute bg-white w-full h-96 overflow-scroll">
            <ul>
              {results.map((result, index) => (
                <li
                  key={index}
                  className="p-2 border border-gray-200 rounded text-sm mb-2 cursor-pointer"
                  onClick={() => {
                    onResultClick(result);
                    setResults([]);
                  }}
                >
                  {result.name}
                </li>
              ))}
            </ul>
          </div>
        )} */}
      </div>
    </>
  );
};

export default SearchAgencies;
