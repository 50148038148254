import { Column } from "types/column";

const USER_ADDED_COUPON_COLS = [
  {
    key: "",
    label: "",
  },
  {
    key: "Upload Date",
    label: "Upload Date",
    isSortable: true,
  },

  {
    key: "Source",
    label: "Source",
    isSortable: false,
  },
  {
    key: "Program",
    label: "Program",
    isSortable: false,
  },

  { key: "Network", label: "Network", isSortable: false },

  { key: "Description", label: "Description", isSortable: false },
  { key: "Code", label: "Code", isSortable: false },
  { key: "Expiration Date", label: "Expiration Date", isSortable: false },
  { key: "Install ID", label: "Install ID", isSortable: false },

  { key: "", label: "", isSortable: false },
  { key: "", label: "", isSortable: false },
] as Column[];

export default USER_ADDED_COUPON_COLS;
