import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { Client } from "types/model/Client";

function addHttp(url: string): string {
  if (!url) {
    return url;
  }
  if (!/^https?:\/\//i.test(url)) {
    url = "http://" + url;
  } else {
    return url;
  }
  return url;
}
export default addHttp;

export const navigateToMerchantPage = (
  clients: Client[],
  slug: string,
  client: null | string = null
) => {
  const website = clients.find((it) => {
    if (String(it.id) === String(client)) {
      return it;
    } else {
      if (
        String(it.id) === localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)
      ) {
        return it;
      }
    }
  });

  if (website?.website_url) {
    const fullURL = `${website?.website_url}/${slug}`;
    window?.open(fullURL, "_blank")?.focus();
  }
};
