import axios from "axios";
import { BACKEND_BASE_URL } from "config/env";
import { ACCESS_TOKEN } from "constants/storageKeys";
import axiosRetry from "axios-retry";

const instance = axios.create({
  baseURL: BACKEND_BASE_URL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    // config.params = {
    //   ...config.params,
    //   client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
    // };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRetry(instance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    return !error.response || error.response?.status > 500;
  },
});

export default instance;
