import paginatedItemsInitialState from "redux/initial-states/paginatedItemsInitialState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";
import { PaginatedActionState } from "types/data";
import { AffiliateNetwork } from "types/model/AffiliateNetwork";

const initialState: PaginatedActionState<AffiliateNetwork> =
  paginatedItemsInitialState;

const getAffiliateNetworksSlice = createSlice({
  name: "AffiliateNetworks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAffiliateNetworks.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getAffiliateNetworks.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )
      .addCase(
        getAffiliateNetworks.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});

export default getAffiliateNetworksSlice.reducer;
