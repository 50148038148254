import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sales_crm/program-owner-requests",
  async (params: Record<string, any>, ThunkAPI) => {
    try {
      const url = new URL(
        `${BACKEND_BASE_URL}/admin/sales-crm/program-owner-requests/`
      );

      Object.entries(params).map(([key, value]) => {
        // if (value) {
        url.searchParams.set(key, value);
        // }
      });
      const response = await axios.get(url.href);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
