import { useEffect, useState } from "react";
import { Event } from "types/model/sales_crm/Event";
import formatDate from "utils/dates/formatDate";
import { useAppDispatch } from "hooks/useRedux";
import getProgramById from "redux/thunks/app/api-clients/getProgramById";
import { isString } from "lodash";
import { twMerge } from "tailwind-merge";

interface StoreCardInCommunicationProps {
  event: Event;
  wrapperClass: string;
}

export default function StoreCardInCommunication({
  event,
  wrapperClass,
}: StoreCardInCommunicationProps) {
  const dispatch = useAppDispatch();
  const [store, setStore] = useState<Record<string, any>>({});

  useEffect(() => {
    if (event.program.id) {
      dispatch(
        getProgramById({
          client_id: event.program.client,
          id: isString(event.program.slug)
            ? event.program.slug
            : (event.program.id as string),
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setStore(res.payload);
        }
      });
    }
  }, []);

  return (
    <div
      className={twMerge(
        "overflow-hidden my-4 border border-gray-200 rounded-xl",
        wrapperClass
      )}
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 p-6">
        <img
          src={store.logo_url}
          alt={event?.program?.name}
          className="h-12 w-12 flex-none rounded-lg  object-cover ring-1 ring-gray-900/10"
        />
        <div className="text-sm font-medium leading-6 text-gray-900">
          {event?.program?.name}
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="py-3 grid grid-cols-2 gap-x-4">
          <dt className="text-gray-500">Latest Communication</dt>
          <dd className="text-gray-900">{formatDate(event.event_date)}</dd>
          <dt className="text-gray-500">Spoke with</dt>
          <dd className="text-gray-900">
            {event.contact.first_name} {event.contact.last_name}
          </dd>
          <dt className="text-gray-500">Network</dt>
          <dd className="text-gray-900">{event?.program?.network}</dd>
          <dt className="text-gray-500">Tier Group</dt>
          <dd className="text-gray-900">{store?.rank}</dd>
          <dt className="text-gray-500">Primary Action Requested</dt>

          {event.topics.map((el) => (
            <dd className="text-gray-900" key={el.id}>
              {el.name}
            </dd>
          ))}
        </div>
      </dl>
    </div>
  );
}
