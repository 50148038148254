const webgainsNetworks = [
  { id: 1, name: "Webgains UK" },
  { id: 2, name: "Webgains France" },
  { id: 3, name: "Webgains Deutschland" },
  { id: 4, name: "Webgains BeNeLux" },
  { id: 5, name: "Webgains Denmark" },
  { id: 6, name: "Webgains Sweden" },
  { id: 7, name: "Webgains Spain" },
  { id: 8, name: "Webgains USA" },
  { id: 9, name: "Webgains Ireland" },
  { id: 10, name: "Webgains Poland" },
  { id: 11, name: "Webgains Italy" },
  { id: 12, name: "Webgains Australia" },
  { id: 13, name: "Webgains Portugal" },
];

export default webgainsNetworks;
