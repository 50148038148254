import { PaginatedActionState } from "types/data";
import Spinner from "components/common/layout/Spinner";
import Pagination from "components/common/layout/Pagination";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  filterValues: Record<string, any>;
  groupedProgramEmailsData: PaginatedActionState<Record<string, any>>;
  onPageChange: (num: Number) => void;
};

function EmailProgramsReportComponent({
  filterValues,
  groupedProgramEmailsData,
  onPageChange,
}: Props) {
  return (
    <div>
      {groupedProgramEmailsData.loading ? (
        <div className="m-4">
          <Spinner />
        </div>
      ) : (
        <div className="flex items-center gap-4 flex-wrap">
          {groupedProgramEmailsData.data.items.map(
            (el: Record<string, any>) => (
              <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                <div className="flex items-center justify-between space-x-4">
                  <h3>{el.program_slug}</h3>
                  <h3>{formatNumber(el.count, {})}</h3>
                </div>
              </div>
            )
          )}
        </div>
      )}

      {!groupedProgramEmailsData.loading && (
        <div className="my-4">
          <Pagination
            siblingCount={3}
            currentPageCount={groupedProgramEmailsData.data.items.length}
            className="pagination-bar"
            currentPage={Number(filterValues.page)}
            totalCount={groupedProgramEmailsData.data.meta.count}
            pageSize={50}
            enableShowAll={false}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default EmailProgramsReportComponent;
