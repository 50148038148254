import {
  formatTooltip,
  formatXAxis,
} from "components/charts/Bar/SimpleBarChart";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { twMerge } from "tailwind-merge";

type DataPoint = {
  name: string;
  PPC: number;
  Organic: number;
  amt: number;
};

const data: DataPoint[] = [
  { name: "Page A", PPC: 4000, Organic: 2400, amt: 2400 },
  { name: "Page B", PPC: 3000, Organic: 1398, amt: 2210 },
  { name: "Page C", PPC: 2000, Organic: 9800, amt: 2290 },
  { name: "Page D", PPC: 2780, Organic: 3908, amt: 2000 },
  { name: "Page E", PPC: 1890, Organic: 4800, amt: 2181 },
  { name: "Page F", PPC: 2390, Organic: 3800, amt: 2500 },
  { name: "Page G", PPC: 3490, Organic: 4300, amt: 2100 },
];

type CustomizedLabelProps = {
  x?: number;
  y?: number;
  stroke?: string;
  value?: number;
};

const CustomizedLabel: React.FC<CustomizedLabelProps> = ({
  x,
  y,
  stroke,
  value,
}) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    {value}
  </text>
);

type CustomizedAxisTickProps = {
  x?: number;
  y?: number;
  stroke?: string;
  payload?: { value: string };
};

const CustomizedAxisTick: React.FC<CustomizedAxisTickProps> = ({
  x,
  y,
  payload,
}) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-35)"
    >
      {payload?.value}
    </text>
  </g>
);

type Props = {
  title: string;
  rangeOptions: Record<string, any>[];
  data: Array<Record<string, any>>;
};

const CustomizedLabelLineChart = ({ title, rangeOptions, data }: Props) => {
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h1>{title} </h1>
        <div>
          {rangeOptions.map((el, idx) => (
            <button
              key={el.label}
              onClick={el.onClick}
              className={twMerge(
                "border border-gray-200 my-2 inline-flex w-full rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm  mr-2 sm:w-auto",
                el.active && "bg-primary-700 text-white"
              )}
            >
              {el.label}
            </button>
          ))}
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" height={60} />
          <YAxis tickFormatter={formatXAxis} />
          <Tooltip formatter={formatTooltip} />
          <Legend />
          <Line
            type="monotone"
            dataKey="Organic"
            stroke="#82ca9d"
            // label={<CustomizedLabel />}
          />
          <Line type="monotone" dataKey="PPC" stroke="#8884d8" />
          <Line type="monotone" dataKey="Email" stroke="#FF8042" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default CustomizedLabelLineChart;
