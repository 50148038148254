import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import saveTopCategories from "redux/thunks/app/placements/campaigns/saveTopCategories";
import updateTopCategory from "redux/thunks/app/placements/campaigns/updateTopCategory";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const saveTopCategoriesSlice = createSlice({
  name: "saveTopCategories",
  initialState,
  reducers: {
    clearSaveTopCategories: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveTopCategories.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(updateTopCategory.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        saveTopCategories.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(updateTopCategory.fulfilled, (state, { payload }) => ({
        ...state,
        data: payload,
        loading: false,
      }))
      .addCase(
        saveTopCategories.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      )
      .addCase(
        updateTopCategory.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { clearSaveTopCategories } = saveTopCategoriesSlice.actions;
export default saveTopCategoriesSlice.reducer;
