import Layout from "components/layout/Layout";
import NetworkForm from "components/networks/NetworkForm";
import { ChangeEventHandler, FormEventHandler } from "react";
import { ActionStateType } from "redux/slices/app/createNetwork";
import { AffiliateNetwork } from "types/model/AffiliateNetwork";
import { Option } from "types/option";

type Props = {
  clients: Option[];
  affiliateNetworks: AffiliateNetwork[];
  onSelectedClientChange: ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  >;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  requiredFields: string[];
  createNetworkState: ActionStateType;
  form: Record<string, any>;
};

const NetworkDetail = ({
  clients,
  affiliateNetworks,
  requiredFields,
  createNetworkState,
  onChange,
  form,
  onSubmit,
  onSelectedClientChange,
}: Props) => {
  return (
    <Layout>
      <div className="relative p-4 w-full max-w-3xl h-full md:h-auto">
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Network Details
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="createProductModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <NetworkForm
            clients={clients}
            affiliateNetworks={affiliateNetworks}
            requiredFields={requiredFields}
            createNetworkState={createNetworkState}
            onChange={onChange}
            onSelectedClientChange={onSelectedClientChange}
            form={form}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </Layout>
  );
};

export default NetworkDetail;
