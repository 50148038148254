import { Column } from "types/column";

const IO_COLUMNS = [
  "Created At",
  "Insertion Order ID",
  "Account Manager",
  "Brand",
  "Location",
  "Payment Status",
  "Total Amount",
  "Amount Paid",
  "Bal, Remaining",
  "Signature",
  "",
  "",
  "",
  "",
  "",
].map((el) => ({
  key: el,
  label: el,
  isSortable: false,
})) as Column[];

export default IO_COLUMNS;
