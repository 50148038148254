import Input from "components/common/Input";
import Modal from "components/common/Modal";
import SearchInputComponent from "components/common/SearchInputComponent";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { useAppSelector } from "hooks/useRedux";
import { clientName } from "components/program/SearchResults";

type Props = {
  selectedPrograms: Record<string, any>[];
  wrapperClassName?: string;
  onDone: (selectedPrograms: Record<string, any>[]) => void;
  label?: string;
  rightLabel?: JSX.Element;
};

const SelectManagedPrograms = ({
  selectedPrograms,
  wrapperClassName,
  onDone,
  label,
  rightLabel,
}: Props) => {
  const clients = useAppSelector((state) => state.getClients);

  const [results, setResults] = useState<any[]>([]);

  const endpoint = `${DISCOUNT_CODES_API}/stores/search-stores/`;

  const handleResults = (data: any[]) => {
    setResults(data);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const [_selectedPrograms, _setSelectedPrograms] = useState<
    Record<string, any>[]
  >((Array.isArray(selectedPrograms) && selectedPrograms) || []);

  const removeProgram = (programToRemove: Record<string, any>) => {
    _setSelectedPrograms(
      _selectedPrograms.filter((program) => program.id !== programToRemove.id)
    );
  };

  return (
    <>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        doneText="Done"
        showCancel={false}
        onConfirm={() => {
          onDone(_selectedPrograms);
          setModalOpen(false);
        }}
        variant="default"
      >
        <SearchInputComponent
          placeholder="Search Program..."
          endpoint={endpoint}
          client={instance}
          wrapperClassName={wrapperClassName}
          onResults={handleResults}
        />

        <OutsideClickHandler
          onOutsideClick={() => {
            setResults([]);
          }}
        >
          {/* <small>{loading && "Loading..."}</small> */}
          {results.length > 0 && (
            <ul
              className={twMerge(
                "absolute z-10 mt-1 max-h-60  w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                wrapperClassName
              )}
            >
              {results.map((result) => (
                <li
                  key={result.id}
                  onClick={() => {
                    _setSelectedPrograms((all) => [...all, result]);
                    setResults([]);
                  }}
                  className={classNames(
                    "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 hover:font-semibold",
                    false ? "bg-indigo-600 text-white" : "text-gray-900"
                  )}
                >
                  <span className={classNames("block truncate")}>
                    {result.name}
                  </span>

                  <p className="text-xs">{result.primary_network?.name}</p>
                  <p className="text-xs uppercase  mt-2">
                    {result.location}{" "}
                    {clientName(clients.data.items, result.client) || ""}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </OutsideClickHandler>

        {/* <div className="relative mt-4">
          {results.length > 0 && (
            <div className="absolute bg-white w-full h-96 overflow-scroll">
              <ul>
                {results.map((result, index) => (
                  <li
                    key={index}
                    className="p-2 border border-gray-200 rounded text-sm mb-2 cursor-pointer"
                    onClick={() => {}}
                  >
                    {result.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div> */}

        <div className="col-span-2 space-y-4 mt-4">
          <ul className="flex flex-wrap gap-4">
            {_selectedPrograms.map((program) => (
              <li
                key={program.id || program.slug}
                className="bg-gray-200 rounded-md p-2 text-xs flex justify-between items-center w-32 overflow-hidden"
              >
                {program.name}
                <button
                  onClick={() => removeProgram(program)}
                  className="text-gray-600 hover:text-gray-800"
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Modal>

      <div className="flex items-center gap-4">
        <Input
          onClick={() => setModalOpen(true)}
          label={label || "Managed Programs"}
          rightLabel={rightLabel}
          wrapperClassName={twMerge("w-1/2", wrapperClassName)}
          placeholder={
            !selectedPrograms.length
              ? "Search program"
              : `${selectedPrograms.map((el) => el.name).join(", ")}`
          }
        />
      </div>
    </>
  );
};

export default SelectManagedPrograms;
