import StoreCard from "components/common/StoreCard";
import Spinner from "components/common/layout/Spinner";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getSubBrands from "redux/thunks/app/api-clients/getSubBrands";
import { twMerge } from "tailwind-merge";

type Props = {
  onItemClick?: (res: Record<string, any>) => void;
};

const SubBrands = ({ onItemClick }: Props) => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { data, loading } = useAppSelector((state) => state.getSubBrands);
  const { data: storeData } = useAppSelector((state) => state.getProgram);

  useEffect(() => {
    if (
      !storeData.primary_network &&
      params.get("slug") &&
      !(params.get("network") && params.get("slug"))
    ) {
      dispatch(
        getSubBrands({
          query: {
            slug: params.get("slug") || "",
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (storeData.primary_network) {
      dispatch(
        getSubBrands({
          query: {
            mid: storeData.primary_network.network_mid || "",
            network: storeData.primary_network.name,
          },
        })
      );
    }
  }, [storeData.primary_network]);

  useEffect(() => {
    if (
      !storeData.primary_network &&
      params.get("network") &&
      params.get("mid")
    ) {
      dispatch(
        getSubBrands({
          query: {
            mid: params.get("mid") || "",
            network: params.get("network"),
          },
        })
      );
    }
  }, []);

  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      {loading && <Spinner />}
      {data.results.length > 0 && (
        <ul
          className={twMerge(
            " z-10 mt-4  w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm "
          )}
        >
          {data.results.map((result) => (
            <StoreCard
              store={result}
              onStoreClicked={() => {
                if (typeof onItemClick === "function") {
                  onItemClick(result);
                } else {
                  navigate(`/bma/programs?slug=${result.slug}`);
                }
              }}
            />
          ))}
        </ul>
      )}
    </section>
  );
};

export default SubBrands;
