import { twMerge } from "tailwind-merge";
import { Lead } from "types/model/sales_crm/Lead";
import formatDate from "utils/dates/formatDate";

type Props = {
  lead: Lead;
  leadIdx: number;
  onRowClick: () => void;
  onAssign: () => void;
};

const LeadRow = ({ lead, leadIdx, onRowClick, onAssign }: Props) => {
  return (
    <tr
      key={lead.program?.name}
      onClick={(e) => {
        e.stopPropagation();
        onRowClick();
      }}
    >
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {formatDate(lead.created_at)}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.assigned_at ? formatDate(lead.assigned_at) : null}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.program?.name}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        <a
          href={lead.program?.website_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          {lead.program?.website_url}
        </a>
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.program?.primary_network}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.program?.tier}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.topics?.[0]?.name}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {(lead.created_by?.first_name || "") +
          " " +
          (lead.created_by?.last_name || "")}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {(lead.assigned_to?.first_name || "") +
          " " +
          (lead.assigned_to?.last_name || "")}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.notes}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-transparent",
          "relative px-3 py-3.5 text-right text-sm font-medium sm:pr-6"
        )}
      >
        {!lead.assigned_to && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onAssign();
            }}
            className="rounded-md bg-white px-4 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
          >
            Get'it
          </button>
        )}
        {leadIdx !== 0 ? (
          <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
        ) : null}
      </td>
    </tr>
  );
};

export default LeadRow;
