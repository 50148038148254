import Button from "components/common/Button";
import DeleteButton from "components/common/DeleteButton";
import Dropdown from "components/common/Dropdown";
import Modal from "components/common/Modal";
import TextArea from "components/common/TextArea";
import SearchPrograms from "containers/oma/SearchPrograms";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import createLead from "redux/thunks/sales_crm/createLead";
import editDeleteLead from "redux/thunks/sales_crm/editDeleteLead";
import getLeads from "redux/thunks/sales_crm/getLeads";
import { twMerge } from "tailwind-merge";
import { User } from "types/model/User";
import { Lead } from "types/model/sales_crm/Lead";

interface CreateLeadModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  salesUsers: User[];
  selectedRow: Lead | null;
}

const CreateLeadModal: React.FC<CreateLeadModalProps> = ({
  isOpen,
  toggleModal,
  salesUsers,
  selectedRow = null,
}) => {
  const dispatch = useAppDispatch();
  const editDeleteLeadData = useAppSelector((state) => state.editDeleteLead);
  const createLeadData = useAppSelector((state) => state.createLead);
  const salesTopics = useAppSelector((state) => state.getLeadTopics);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [form, setForm] = useState<Record<string, any>>({ ...selectedRow });

  const toggleTopic = (topic: string) => {
    setSelectedTopics(
      selectedTopics.includes(topic)
        ? selectedTopics.filter((t) => t !== topic)
        : [...selectedTopics, topic]
    );
  };

  useEffect(() => {
    if (selectedRow) {
      setForm((f) => ({ ...f, ...selectedRow }));

      if (selectedRow && selectedRow?.topics.length) {
        setSelectedTopics(selectedRow?.topics.map((el) => String(el.id)));
      }
    } else {
      setForm({});
      setSelectedTopics([]);
    }
  }, [selectedRow]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (form.id) {
      dispatch(
        editDeleteLead({
          ...form,
          topics: selectedTopics,
          assigned_to: form.assigned_to?.id,
          assigned_by: form.assigned_by?.id,
          program: {
            name: form.program.name,
            client: form.program.client,
            id: form.program.id,
            slug: form.program.slug,
            network: form.program.primary_network?.name,
            tier: form.program.rank,
            website_url: form.program.website_url,
          },
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Changes saved");

          dispatch(getLeads({ page: 1 }));
          toggleModal();
        }
      });
      return;
    }

    dispatch(
      createLead({
        ...form,
        topics: selectedTopics,
        program: {
          name: form.program.name,
          client: form.program.client,
          id: form.program.id,
          slug: form.program.slug,
          network: form.program.primary_network?.name,
          tier: form.program.rank,
          website_url: form.program.website_url,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Lead created successfully");
        dispatch(getLeads({ page: 1 }));
        toggleModal();
      }
    });
  };

  return (
    <Modal
      showCancel={false}
      showDone={false}
      open={isOpen}
      setOpen={toggleModal}
      variant="lg"
    >
      <div className="flex justify-between items-start rounded-t border-b">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          {form.id ? "Lead Details" : "Create Lead"}
        </h3>
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={toggleModal}
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <form action="#" className="mt-5 space-y-4" onSubmit={handleSubmit}>
        <div className="flex items-center gap-8">
          <SearchPrograms
            label="Search Program"
            inputClassName="p-0"
            inputContainerClass=" h-11"
            wrapperClassName="w-64 mx-0"
            closeOnSelect
            value={form.program?.name}
            onResultClicked={(result) => {
              setForm((f) => ({ ...f, program: result }));
            }}
          />
          <Dropdown
            name="assigned_to"
            label="Assign to"
            value={form.assigned_to?.id}
            onChange={(e) => {
              setForm((f) => ({ ...f, assigned_to: e.target.value }));
            }}
            wrapperClassName="w-64"
            options={salesUsers.map((el) => ({
              label: el.first_name + " " + el.last_name,
              value: el.id,
            }))}
          />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            What&apos;s the goal?
          </label>
          <ul className="grid grid-cols-2 gap-3 md:grid-cols-3">
            {salesTopics.data.map((topic) => (
              <li key={topic.id}>
                <input
                  type="checkbox"
                  id={String(topic.id)}
                  value={topic.name}
                  className="hidden peer"
                  name="topic"
                  checked={selectedTopics.includes(String(topic.id))}
                  onChange={() => toggleTopic(String(topic.id))}
                />
                <label
                  htmlFor={String(topic.id)}
                  className={twMerge(
                    "inline-flex items-center justify-center w-full p-2 text-sm text-center bg-white border rounded-lg cursor-pointer text-blue-700 border-blue-700 ",
                    selectedTopics.includes(String(topic.id)) &&
                      "bg-blue-700 text-white"
                  )}
                >
                  {topic.name}
                </label>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Details
          </label>
          <TextArea
            id="notes"
            name="notes"
            rows={3}
            onChange={(e) => {
              setForm((f) => ({ ...f, notes: e.target.value }));
            }}
            placeholder="Event description"
            value={form.notes}
          />
        </div>

        <div className="flex justify-end">
          {form.id ? (
            <div className=" gap-4">
              <DeleteButton
                deleteLoading={editDeleteLeadData.loading}
                onClick={() => {
                  dispatch(editDeleteLead({ id: form.id, delete: true })).then(
                    (res) => {
                      if (res.meta.requestStatus == "fulfilled") {
                        dispatch(getLeads({ page: 1 }));
                        toggleModal();
                      }
                    }
                  );
                }}
              />
              <Button
                className="ml-4"
                onClick={handleSubmit}
                text="Save"
                loading={editDeleteLeadData.loading}
              />
            </div>
          ) : (
            <Button
              type="submit"
              text="Create Lead"
              onClick={handleSubmit}
              loading={createLeadData.loading}
            />
          )}
        </div>
      </form>
    </Modal>
  );
};

export default CreateLeadModal;
