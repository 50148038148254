import ClientAPI from "client-apis";
import updateOperationThunk from "../../Thunk";

export default updateOperationThunk(
  "clients/categories",
  async (payload: Record<string, any>) => {
    const clientWebsite = new ClientAPI();
    return await clientWebsite.getCategories(payload);
  }
);
