import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";

type Params = {
  data: Record<string, any>;
};

export default createAsyncThunk(
  "users/create-user",
  async ({ data }: Params, ThunkAPI) => {
    const payload = { ...data };
    try {
      if (data.file) {
        const s3 = new S3({
          ...defaultS3Config,
          dirName: `img/users/${payload.email || payload.id}`,
        });
        const result = await s3.uploadFile(data.file);
        const { location } = result;
        payload["image_url"] = location;
      }
      const response = payload.id
        ? await axios.patch(`/admin/users/admin/${payload.id}/`, payload)
        : await axios.post(`/admin/users/admin/`, payload);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          }
      );
    }
  }
);
