import { useState } from "react";
import NetworkEntryCard from "./components/NetworkEntryCard";
import { ActionState } from "types/data";
import { CRMNetwork } from "types/model/sales_crm/Network";
import formatDate from "utils/dates/formatDate";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
  setShowNetworkModal,
  toggleNetworkModal,
} from "redux/slices/sales_crm/modals";
import { BiPencil } from "react-icons/bi";

type Props = {
  networks: ActionState<CRMNetwork[]>;
};

export default function CRMNetworksLayout({
  networks: networksData,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();

  const { networkModalOpen } = useAppSelector((state) => state.crmModals);
  const [activeNetwork, setActiveNetwork] = useState<CRMNetwork>();

  const setModalOpen = () => {
    dispatch(toggleNetworkModal());
  };

  return (
    <div>
      {networkModalOpen && (
        <NetworkEntryCard
          setShowNetworkModal={setModalOpen}
          showNetworkModal={networkModalOpen}
          activeNetwork={activeNetwork}
        />
      )}

      <div className="">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Date of Creation
              </th>
              <th
                scope="col"
                className="py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Network Name
              </th>
              <th
                scope="col"
                className="py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Network Type
              </th>
              <th
                scope="col"
                className="py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Network URL
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {networksData?.data?.map((network) => (
              <tr key={network.id}>
                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                  {formatDate(network.created_at)}
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-900">
                  <button>
                    {network.name}
                  </button>
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-900">
                  <button>{network.network_type?.name}</button>
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                  <a
                    href={network.website_url}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    {network.website_url}
                  </a>
                </td>

                <td>
                  <button
                    onClick={() => {
                      dispatch(setShowNetworkModal(true));
                      setActiveNetwork(network);
                    }}
                  >
                    <BiPencil />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
