import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getContacts from "redux/thunks/sales_crm/getContacts";
import { Contact } from "types/model/sales_crm/Contact";

export interface PaginatedData {
  meta: {
    count: number;
    limit: number;
    offset: number;
  };
  items: Array<Contact>;
}

export type ActionStateType = {
  data: PaginatedData;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getContactsSlice = createSlice({
  name: "Contacts",
  initialState: initialState,
  reducers: {
    resetContacts: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        getContacts.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getContacts.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetContacts } = getContactsSlice.actions;
export default getContactsSlice.reducer;
