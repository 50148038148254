import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getCampaignSettings from "redux/thunks/marketing/emails/getCampaignSettings";
import CampaignSettingLayout from "layout/marketing/email/CampaignSettings";
import { EmailCampaignSetting } from "types/model/NewsletterSubscriber";

const EmailSettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { page } = Object.fromEntries([...searchParams]);

  const getCampaignSettingsData = useAppSelector(
    (state) => state.getCampaignSettings
  );

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  useEffect(() => {
    dispatch(getCampaignSettings({ page: Number(page) || 1 }));
  }, [searchParams]);

  const navigateToCreateSetting = () => {
    navigate("/marketing/email/campaign-settings/add");
  };
  const navigateToSetting = (item: EmailCampaignSetting) => {
    navigate(`/marketing/email/campaign-settings/add?setting=${item.id}`, {
      state: {
        setting: item,
      },
    });
  };

  return (
    <CampaignSettingLayout
      onPageChange={getNewPage}
      navigateToCreateSetting={navigateToCreateSetting}
      currentPage={Number(page) || 1}
      navigateToSetting={navigateToSetting}
      getCampaignSettingsData={getCampaignSettingsData}
    />
  );
};
export default EmailSettings;
