import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sem/getPPCMarginRevenueTrend",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const reportUrl = new URL(
        `${BACKEND_BASE_URL}/admin/sem/margin-revenue-trend/`
      );

      Object.entries(payload).forEach(([k, v]) => {
        reportUrl.searchParams.set(k, v);
      });

      const res = await axios.get(reportUrl.href);

      return res.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
