import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { GrClose } from "react-icons/gr";
import { Proposal } from "types/model/sales/Proposal";

type Props = {
  proposal: Proposal;
  onRequest: () => void;
};

const DocumentStatus = ({ proposal, onRequest }: Props) => {
  const docStatus =
    (proposal.manual_signed_io_file?.length || 0) > 0
      ? "executed"
      : proposal.signing_status;

  if (
    proposal.signing_status ||
    (proposal.manual_signed_io_file?.length || 0) > 0
  ) {
    return (
      <div className="flex px-2 py-2">
        <div className="flex flex-col items-center">
          <div className="flex items-center text-xs font-medium text-primary-600">
            <CheckCircleIcon className="text-xs h-6 w-6" />
            <p>{docStatus}</p>
          </div>
          {docStatus !== "executed" && (
            <button
              className=" text-xs font-medium text-primary-600 underline"
              onClick={onRequest}
            >
              resend
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-2 items-center px-2 py-2 text-xs font-medium text-primary-600">
      <GrClose color="red" />
      <button onClick={onRequest}>Request</button>
    </div>
  );
};

export default DocumentStatus;
