import { BACKEND_BASE_URL } from "config/env";
import axios from "axios";
import { IClient } from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

class PromoCodes implements IClient {
  getAllCategories(payload?: Record<string, any>) {
    const url = new URL(
      `${BACKEND_BASE_URL}/admin/website-clients/categories/`
    );
    if (payload) {
      Object.entries(payload).forEach(([k, v]) => {
        url.searchParams.set(k, v);
      });
    }

    return axios.get(url.href);
  }

  async getActivePrograms() {
    return axios.get(
      `${BACKEND_BASE_URL}/admin/website-clients/search-programs/?client_id=8&search_text=${""}&client_site_id=${localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      )}`
    );
  }

  searchPrograms(q: string, client_site_id?: string) {
    return axios.get(
      `${BACKEND_BASE_URL}/admin/website-clients/search-programs/?client_id=8&search_text=${encodeURIComponent(
        q
      )}&client_site_id=${
        client_site_id || localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)
      }`
    );
  }

  getProgramDetails(slug: string) {
    return axios.get(
      `${BACKEND_BASE_URL}/admin/website-clients/program/?client_id=8&slug=${slug}`
    );
  }
}

export default PromoCodes;
