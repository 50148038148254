import DataTable from "components/common/layout/DataTable";
import CategoryCouponRow from "components/coupons/CategoryCouponRow";
import Layout from "components/layout/Layout";
import CATEGORY_COUPONS from "constants/tables/headers/oma/categoryCoupons";
import { ActionState, PageNumPaginatedActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";

type Props = {
  categoryData: ActionState<ProgramCategory>;
  couponsData: PageNumPaginatedActionState<Record<string, any>>;
};

export default function OMACategoryDetail({
  categoryData: { data, loading },
  couponsData,
}: Props) {
  return (
    <Layout loading={!data.id && loading}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              {data.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              These coupons can be viewed and sorted on this category page.
            </p>
          </div>

          <div className="flex items-center gap-5 justify-between">
            <p className="text-base font-semibold  leading-6 text-gray-900">
              {data.id}
            </p>
            <p className="text-sm text-gray-700">{data.number_of_coupons}</p>
          </div>
        </div>

        <DataTable
          columns={CATEGORY_COUPONS}
          rows={couponsData.data.results}
          currentPage={1}
          rowRenderer={(row) => <CategoryCouponRow key={row.id} row={row} />}
        />
      </div>
    </Layout>
  );
}
