import { ChangeEventHandler, FormEventHandler } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  value?: string;
  name?: string;
  enableSubmit?: boolean;
  inputClassName?: string;
  placeholder?: string;
};

function SearchInput({
  onChange,
  onSubmit,
  value,
  name,
  enableSubmit,
  inputClassName,
  placeholder,
}: Props) {
  return (
    <form className="w-full md:max-w-sm flex-1 md:mr-4" onSubmit={onSubmit}>
      <label
        htmlFor="default-search"
        className="text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <input
          type="search"
          name={name || "search_text"}
          id="default-search"
          className={twMerge(
            "block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500",
            inputClassName
          )}
          placeholder={placeholder || "Search..."}
          onChange={onChange}
          value={value}
        />

        {enableSubmit && (
          <button
            type="submit"
            className="text-white absolute right-0 bottom-0 top-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-r-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Search
          </button>
        )}
      </div>
    </form>
  );
}

export default SearchInput;
