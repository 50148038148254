import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import ProposalLayout from "layout/sales/ProposalLayout";
import { useParams } from "react-router-dom";
import getProposal from "redux/thunks/sales/getProposal";
import { useEffect } from "react";

const Proposal = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { getProposal: getProposalData } = useAppSelector(
    ({ getProposal }) => ({ getProposal })
  );

  useEffect(() => {
    if (params["id"]) {
      dispatch(getProposal(params["id"] || ""));
    }
  }, []);
  return <ProposalLayout getProposalData={getProposalData} />;
};
export default Proposal;
