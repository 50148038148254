import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getEvent from "redux/thunks/sales_crm/getEvent";
import { Event } from "types/model/sales_crm/Event";

export type ActionStateType = {
  data: Event | Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const getEventSlice = createSlice({
  name: "Event",
  initialState: initialState,
  reducers: {
    resetEvent: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvent.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(getEvent.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if ({ payload }) {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      })

      .addCase(getEvent.rejected, (state, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: false,
        error: payload,
      }));
  },
});
export const { resetEvent } = getEventSlice.actions;
export default getEventSlice.reducer;
