import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import AutoPausedCampaignsLayout from "layout/marketing/search/AutoPausedCampaignsLayout";
import { useEffect } from "react";
import getAutoPausedCampaigns from "redux/thunks/marketing/sem/getAutoPausedCampaigns";

const PausedCampaigns = () => {
  const dispatch = useAppDispatch();

  const getAutoPausedCampaignsState = useAppSelector(
    (state) => state.getAutoPausedCampaigns
  );

  useEffect(() => {
    dispatch(getAutoPausedCampaigns());
  }, []);

  return (
    <AutoPausedCampaignsLayout
      getCampaignReportsState={getAutoPausedCampaignsState}
    />
  );
};

export default PausedCampaigns;
