import { ChangeEvent, ChangeEventHandler } from "react";
import { discountNotesOptions } from "constants/proposal/discountNotes";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import formatNumber from "utils/strings/formatNumber";
import DiscountInput from "components/common/DiscountInput";
import storeCurrencyMap from "constants/storeCurrencies";
import PlacementsListItem from "./PlacementsListItem";
import { AddLineItemsProps } from "./ProposalDetails";

type Props = {
  placements: Array<Record<string, any>>;
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  finalAmount: string | number;
  totalAmount: string | number;
  removePaidPlacements: (item: Record<string, any>) => void;
  duplicateItem: (item: Record<string, any>, idx: number) => void;
  addLineItemsProps: AddLineItemsProps;
};

const PlacementsList = ({
  placements,
  removePaidPlacements,
  totalAmount,
  finalAmount,
  form,
  onChange,
  duplicateItem,
  addLineItemsProps,
}: Props) => {
  const currency_symbol = storeCurrencyMap[
    form.location?.toLowerCase()
  ] as string;

  return (
    <table className="w-full text-sm text-left text-gray-500 mt-8">
      <caption className="block text-left ">Added Items </caption>
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
          <th scope="col" className="px-2 py-4">
            Campaign Run Dates
          </th>
          <th scope="col" className="px-2 py-4">
            Client
          </th>
          <th scope="col" className="px-2 py-3">
            Campaign Page
          </th>

          <th scope="col" className="px-2 py-3">
            Ad Placement Type
          </th>
          <th scope="col" className="px-2 py-3">
            Position
          </th>

          <th scope="col" className="px-2 py-3">
            Amount
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {placements.map((el, idx) => (
          <PlacementsListItem
            key={el.id}
            idx={idx}
            duplicateItem={duplicateItem}
            removePaidPlacements={removePaidPlacements}
            item={el}
            addLineItemsProps={addLineItemsProps}
            currency_symbol={currency_symbol}
          />
        ))}
        <tr>
          <td colSpan={3}></td>
          <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
            Total
          </td>
          <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
            {currency_symbol}
            {formatNumber(totalAmount, {})}
          </td>
        </tr>

        <tr>
          <td colSpan={3}></td>
          <td>
            <DiscountInput
              onChange={onChange}
              name="offer_discount"
              label="Offer Discount"
              discount_type={form.discount_type}
              value={form.offer_discount}
              onTypeChange={(e) => {
                onChange?.({
                  ...e,
                  target: {
                    ...e.target,
                    name: "discount_type",
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
            />
          </td>
        </tr>

        <tr>
          <td colSpan={3}></td>
          <td>
            <MultipleSelectionInput
              label="Discount Notes"
              renderAll
              wrapperClassName="my-4"
              value={form.discount_notes}
              name="discount_notes"
              onChange={(e) => {
                const exists = form.discount_notes?.includes(e.target.value);
                const selected = exists
                  ? form.discount_notes?.filter(
                      (it: string) => it !== e.target.value
                    )
                  : [...(form.discount_notes || []), e.target.value];

                onChange({
                  ...e,
                  target: {
                    ...e.target,
                    name: "discount_notes",
                    value: selected,
                  },
                });
              }}
              options={discountNotesOptions.map((el) => ({
                label: el,
                value: el,
                selected: form.discount_notes?.includes(el) || false,
              }))}
            />
          </td>
        </tr>

        <tr>
          <td colSpan={3}></td>
          <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
            Final Sales Amount
          </td>
          <td className="px-2 py-2 text-xs font-medium text-gray-900 ">
            {currency_symbol}
            {formatNumber(finalAmount, {})}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PlacementsList;
