import { Key } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { twMerge } from "tailwind-merge";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  title: string;
  rangeOptions: Record<string, any>[];
  data: Record<string, any>[];
  barDataKey: string;
  xDataKey: string;
  bar2DataKey: string;
};

const SimpleBarChart = ({
  title,
  rangeOptions,
  data,
  barDataKey,
  xDataKey,
  bar2DataKey,
}: Props) => {
  const formatXAxis = (tickItem: number): string => {
    // Add your custom formatting logic here
    return String(
      formatNumber(Math.round(tickItem / 1000) * 1000, { prefix: "$" })
    );
  };

  const formatTooltip = (value: any, name: string) => {
    return String(formatNumber(value, { prefix: "$" }));
  };

  const renderToolTip = (props: Record<string, any>) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      const total = payload.reduce(
        (acc: any, cur: { value: any }) => acc + cur.value,
        0
      );
      return (
        <div className="bg-white py-2 px-4">
          <p className="pb-2">{`${label}`}</p>
          <p className="total">{`Total: ${formatNumber(total, {
            prefix: "$",
            decimals: 2,
          })}`}</p>
          {payload.map(
            (bar: {
              dataKey: Key | null | undefined;
              color: any;
              name: any;
              value: any;
            }) => (
              <p key={bar.dataKey} style={{ color: bar.color }}>
                {`${bar.name}: ${formatNumber(bar.value, {
                  decimals: 2,
                  prefix: "$",
                })}`}
              </p>
            )
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h1>{title} </h1>
        <div>
          {rangeOptions.map((el, idx) => (
            <button
              key={el.label}
              onClick={el.onClick}
              className={twMerge(
                "border border-gray-200 my-2 inline-flex w-full rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm  mr-2 sm:w-auto",
                el.active && "bg-primary-700 text-white"
              )}
            >
              {el.label}
            </button>
          ))}
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xDataKey} />
          <YAxis tickFormatter={formatXAxis} />
          <Tooltip formatter={formatTooltip} content={renderToolTip} />
          <Legend />
          <Bar dataKey={barDataKey} fill={"#346888"} stackId="a" />
          <Bar dataKey={bar2DataKey} stackId="a" fill={"#FF7C43"}>
            <LabelList
              formatter={(value: number) => {
                return String(
                  formatNumber(value, { prefix: "$", decimals: 0 })
                );
              }}
              position={"top"}
              dataKey={({ total }: Record<string, any>) => {
                return total;
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default SimpleBarChart;
