import axios from "axios";
import classNames from "classnames";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Label from "components/common/Label";
import Modal from "components/common/Modal";
import SearchForm from "components/common/SearchForm";
import Pagination from "components/common/layout/Pagination";
import Spinner from "components/common/layout/Spinner";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import SearchPrograms from "containers/oma/SearchPrograms";
import { useAppSelector } from "hooks/useRedux";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";

const MappedCRMPrograms = () => {
  const [open, setOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { data: clients } = useAppSelector((state) => state.getClients);
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState([]);
  const [currentStore, setCurrentStore] = useState<null | Record<string, any>>(
    null
  );
  const [loading, setLoading] = useState(false);

  const [selectedStore, setSelectedStore] = useState<null | Record<
    string,
    any
  >>(null);
  const [suggestions, setSuggestions] = useState([]);
  const [pageMeta, setPageMeta] = useState({ count: 0 });
  const [selectedClient, setSelectedClient] = useState("8");

  const fetchData = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${DISCOUNT_CODES_API}/stores/?arm_mapped=1&page=${page}&client=${selectedClient}&sort_by=-updated`
      );
      setData(response.data.results);
      setPageMeta({
        count: response.data.count,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedClient]);

  const fetchSuggestions = async (name: string) => {
    setSuggestionsLoading(true);

    try {
      const res = await axios.get(
        `${DISCOUNT_CODES_API}/stores/search-stores/?search_text=${name}&client=10`
      );
      setSuggestions(res.data);
      setSuggestionsLoading(false);
    } catch (error) {
      setSuggestionsLoading(false);
    }
  };

  const saveChanges = async (to_id: string) => {
    if (currentStore) {
      setSaving(true);
      const toastId = toast.loading("");
      try {
        await axios.patch(
          `${DISCOUNT_CODES_API}/stores/${currentStore.slug}?client=${selectedClient}`,
          {
            arm_mapped_to: to_id,
            arm_mapping_pending: false,
          }
        );
        toast.dismiss(toastId);
        toast.success("Changes saved");
        setOpen(false);
        setDeleteOpen(false);
        setSaving(false);
        fetchData();
      } catch (error) {
        setSaving(false);
        toast.dismiss(toastId);
      }
    }
  };

  useEffect(() => {
    if (typeof searchText === "string") {
      setSearchLoading(true);
      axios
        .get(
          `${DISCOUNT_CODES_API}/stores/?arm_mapped=1&client=${selectedClient}&sort_by=-updated&search=${searchText.trim()}`
        )
        .then((res) => {
          setData(res.data.results);
          setPageMeta({
            count: res.data.count,
          });
        })
        .finally(() => {
          setSearchLoading(false);
        });
    }
  }, [searchText]);

  const debouncedOnChange = debounce((e) => {
    setSearchText(String(e.target.value));
  }, 1000);

  const paginate = (page: number) => {
    fetchData(page);
  };

  const Card = ({ item, mapAgain }: any) => {
    if (!item) {
      return null;
    }
    return (
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
        <div className="flex items-center space-x-4">
          <img
            src={item.logo_url}
            alt={`${item.name} logo`}
            className="w-16 h-16 object-cover rounded-md"
          />
          <div>
            <h2 className="text-xl font-bold">{item.name}</h2>
            <a
              href={item.website_url}
              className="text-blue-500 hover:underline"
            >
              {item.website_url}
            </a>
            <div className=" flex justify-between items-center">
              <p className="text-gray-700">
                {item.primary_network?.name || "_"}
              </p>
              {mapAgain && (
                <Button
                  onClick={() => {
                    setCurrentStore(item);
                    fetchSuggestions(item.name);
                    setOpen(true);
                  }}
                  text="Map Again"
                ></Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        loading={saving}
        onConfirm={() => {
          if (selectedStore) {
            saveChanges(selectedStore?.id);
          }
        }}
        variant="sm"
        open={deleteOpen}
        setOpen={setDeleteOpen}
      >
        <h3>
          Are you you want to map {currentStore?.name} to {selectedStore?.name}?
        </h3>
      </Modal>
      <Modal variant="default" open={open} setOpen={setOpen}>
        <SearchPrograms
          client_id="10"
          label="Search Program"
          onResultClicked={(result) => {
            setDeleteOpen(true);
            setSelectedStore(result);
          }}
        />
        <div className="m-4">
          <h3>
            <Label label="Suggested" />
            {suggestionsLoading && <Spinner />}

            {suggestions.length > 0 && (
              <ul
                className={twMerge(
                  "mt-1 max-h-60  w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                )}
              >
                {suggestions.map((result: Record<string, any>) => (
                  <li
                    key={result.id}
                    onClick={() => {
                      // onResultClick(result);
                    }}
                    className={classNames(
                      "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 hover:font-semibold",
                      false ? "bg-indigo-600 text-white" : "text-gray-900"
                    )}
                  >
                    <span className={classNames("block truncate")}>
                      {result.name}
                    </span>

                    <p className="text-xs">
                      {result.primary_network?.name}{" "}
                      <span className="text-xs uppercase  mt-2">
                        {result.location}
                      </span>
                    </p>

                    <Button text="SET" onClick={() => saveChanges(result.id)} />
                  </li>
                ))}
              </ul>
            )}
          </h3>
        </div>
      </Modal>
      <div>
        <div className="flex justify-between items-center my-8">
          <Dropdown
            placeholder="Select client"
            value={selectedClient}
            onChange={(e) => {
              setSelectedClient(String(e.target.value));
            }}
            options={clients.items
              .filter((el) => [7, 8, 6, 3].includes(Number(el.id)))
              .map((it) => ({
                label: it.name,
                value: it.id,
              }))}
          />
          <SearchForm
            wrapperClassName="w-56"
            placeholder="Search Program..."
            onChange={debouncedOnChange}
            loading={searchLoading}
          />
        </div>

        {loading ? (
          <div className="my-4 mx-4">
            <Spinner />
          </div>
        ) : (
          <>
            {data.map((item: any) => (
              <div key={item.id} className="flex gap-8 mb-16">
                <Card item={item} mapAgain />
                <Card item={item.arm_mapped_to} />
              </div>
            ))}
          </>
        )}
      </div>

      <div className="mb-8">
        <Pagination
          siblingCount={3}
          // onShowAll={onShowAll}
          currentPageCount={data.length}
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={pageMeta.count || 1}
          pageSize={50}
          enableShowAll={false}
          onPageChange={(page) => {
            setCurrentPage(page);
            paginate(page);
          }}
        />
      </div>
    </div>
  );
};

export default MappedCRMPrograms;
