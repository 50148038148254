import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { GetStoreByNetworkPayload } from "client-apis/discountcodes";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

type Params = {
  query?: string | GetStoreByNetworkPayload;
  id?: string;
  client_id?: string | null;
  useDis?: string;
};

export default createAsyncThunk(
  "clients/active-program",
  async ({ query, useDis, client_id }: Params, ThunkAPI) => {
    const migratedClients = [9, 10];
    const clientId =
      useDis == "1"
        ? "10"
        : migratedClients.includes(
            Number(localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID))
          )
        ? "10"
        : String(localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID));

    try {
      const clientWebsite = new ClientAPI(clientId);

      if (typeof query == "string") {
        const response = await clientWebsite.getProgramDetails(
          query,
          client_id || clientId
        );

        return response.data;
      } else if (query) {
        const response = await clientWebsite.getProgramByNetworkId(query);

        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
