import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginUser from "redux/thunks/users/loginUser";
import LoginComponent from "layout/auth/Login";
import { appRoutes } from "constants/appRouteIds";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clearAuthStorages } = useLoadAuth();
  const loginState = useAppSelector((state) => state.loginUser);
  const [form, setForm] = useState({ email: "", password: "" });

  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const { name, value } = target;

    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    dispatch(loginUser({ data: form })).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        const routePath = appRoutes.find(
          (el) => el.pk === result.payload.user.default_app
        )?.path;

        if (routePath) {
          navigate(routePath);
        } else {
          navigate("/");
        }
      }
    });
  };

  useEffect(() => {
    clearAuthStorages();
  }, []);

  return (
    <LoginComponent
      loginState={loginState}
      onSubmit={onSubmit}
      onChange={onChange}
    />
  );
};

export default Login;
