import { useNavigate } from "react-router-dom";
import TabLayout from "components/common/TabLayout";
import { MARKETING_APP } from "constants/smaAppNames";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import AIFaqs from "containers/marketing/content/AIFaqs";

function ContentMarketingTabs() {
  const navigate = useNavigate();
  const { user } = useLoadAuth();
  const hasAppAccess = user?.user_apps
    ?.map((it) => it.name.toLowerCase())
    ?.includes(MARKETING_APP.toLowerCase());

  const hasPermissions =
    user?.user_role == USER_ROLE_ADMIN || hasAppAccess || false;

  const options = [
    {
      text: "Store FAQs",
      onClick: () => {
        navigate("/marketing/content/?tab=1");
      },

      content: <AIFaqs />,
    },
  ];

  return <TabLayout hasPermissions={hasPermissions} options={options} />;
}

export default ContentMarketingTabs;
