import Layout from "components/layout/Layout";
import { useEffect, useState } from "react";
import Spinner from "components/common/layout/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import PendingCRMMerchants from "./PendingCRMMerchants";
import MappedCRMPrograms from "./MappedCRMPrograms";

import TabLayout from "components/common/TabLayout";

export type CSVData = { headerKeys: string[]; data: any };

type Props = {
  onRowClicked: (row: Record<string, any>) => void;

  getActivePrograms: any;
};

const BMACRMMappingAll = ({ getActivePrograms, onRowClicked }: Props) => {
  const options = [
    {
      content: <PendingCRMMerchants />,
      text: "Pending Merchants",
      oClick: () => {
        //
      },
    },

    {
      content: <MappedCRMPrograms />,
      text: "Mapped Merchants",
      oClick: () => {
        //
      },
    },
  ];

  return <TabLayout options={options} />;
};

export default BMACRMMappingAll;
