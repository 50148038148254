import Spinner from "components/common/layout/Spinner";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import React, { useEffect, useState } from "react";
import getActivityReport from "redux/thunks/sales_crm/getActivityReport";
import formatNumber from "utils/strings/formatNumber";
import SalesTeamFilter from "./SalesTeamFilter";
import useLoadAuth from "hooks/useLoadAuth";
import { USER_ROLE_ADMIN } from "constants/user_roles";

const ActivitySummary: React.FC = () => {
  const { user } = useLoadAuth();
  const dispatch = useAppDispatch();
  const [selectedPeriod, setSelectedPeriod] = useState<string>("this-week");

  const [userId, setUserId] = useState<string>("");

  const { data: activityReportData, loading } = useAppSelector(
    (state) => state.getActivityReport
  );

  const data = [
    [
      { label: "# of Outreach", value: activityReportData.out_reach_count },
      {
        label: "# of New Client First-time Outreach",
        value: activityReportData.new_out_reach_count,
      },
    ],
    [
      {
        label: "Proposals Created",
        value: activityReportData.created_proposals_count,
      },
      {
        label: "Created Proposal $ Value",
        value: formatNumber(activityReportData.proposal_value, {
          prefix: "$",
          decimals: 2,
        }),
      },
    ],
    [
      {
        label: "# of Search Deals Closed",
        value: activityReportData.closed_search_deals_count,
      },
      {
        label: "",
        value: activityReportData.search_deals_closed_names?.join(", ") || "",
      },
    ],
    [
      {
        label: "# of Closed Deals",
        value: activityReportData.closed_deals_count,
      },
      {
        label: "Closed Deals $ Value",
        value: formatNumber(activityReportData.closed_deals_value, {
          prefix: "$",
          decimals: 2,
        }),
      },
    ],
    [
      {
        label: "# of Exclusive Codes received",
        value: activityReportData.exclusive_codes_count,
      },
      {
        label: "",
        value: activityReportData.exclusive_codes_names?.join(", ") || "",
      },
    ],
    ,
    [
      {
        label: "# of Commission Increases",
        value: activityReportData.commission_increases_count,
      },
    ],
  ];

  useEffect(() => {
    dispatch(getActivityReport({ period: selectedPeriod, user_id: userId }));
  }, [selectedPeriod, userId]);

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
  };

  const isAdmin = user?.user_role === USER_ROLE_ADMIN;

  return (
    <div className="hidden w-96 overflow-y-auto border border-gray-200 bg-white lg:block rounded-md p-4 shadow">
      <div className="space-y-6">
        <div>
          <div className="flex justify-between items-center">
            <h3 className="font-medium text-gray-900">Activity Summary</h3>
            {isAdmin && (
              <SalesTeamFilter
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
              />
            )}
          </div>
          <div className="flex items-center justify-between text-xs font-medium rounded-md py-3 px-1 text-gray-900 gap-2 sm:flex-1">
            {[
              { name: "This Week", slug: "this-week" },
              { name: "Last Week", slug: "last-week" },
              { name: "This Month", slug: "this-month" },
              { name: "Last Month", slug: "last-month" },
              { name: "This quarter", slug: "this-quarter" },
            ].map((period) => (
              <button
                key={period.slug}
                onClick={() => handlePeriodChange(period.slug)}
                className={`px-3 py-1 rounded-md ${
                  selectedPeriod === period.slug
                    ? "bg-blue-700 text-white"
                    : "bg-white text-gray-700 border border-gray-300"
                }`}
              >
                {period.name}
              </button>
            ))}
          </div>

          <dl>
            {loading ? (
              <Spinner />
            ) : (
              data.map((el, idx) => (
                <dt key={idx} className="border-b border-t border-gray-200">
                  {el?.map((el) => (
                    <div
                      key={el.label}
                      className="flex flex-col justify-between py-3 text-sm font-medium"
                    >
                      <div className="flex justify-between">
                        <dt className="text-gray-500">{el.label}</dt>
                        <dd className=" text-gray-900">{el.value}</dd>
                      </div>
                    </div>
                  ))}
                </dt>
              ))
            )}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ActivitySummary;
