import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getPaidPlacements from "redux/thunks/app/placements/campaigns/getPaidPlacements";

export type ActionStateType = {
  data: Record<string, any>[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getPaidPlacementsSlice = createSlice({
  name: "getPaidPlacements",
  initialState,
  reducers: {
    clearPaidPlacementResults: () => {
      return initialState;
    },

    updatePaidPlacements: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((el) => (el.id === payload.id ? payload : el)),
      };
    },

    removePaidPlacements: (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter((el) => el.coupon.id !== payload.coupon.id),
      };
    },

    setPaidPlacements: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },

    addToPaidPlacements: (state, { payload }) => {
      return {
        ...state,
        data: [payload, ...state.data],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaidPlacements.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        getPaidPlacements.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getPaidPlacements.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const {
  clearPaidPlacementResults,
  updatePaidPlacements,
  addToPaidPlacements,
  setPaidPlacements,
  removePaidPlacements,
} = getPaidPlacementsSlice.actions;
export default getPaidPlacementsSlice.reducer;
