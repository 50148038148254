import KeywordInput from "components/common/KeywordInput";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useState } from "react";
import { useSearchParams } from "react-router-dom";
import getKeywordsByAdgroup from "redux/thunks/marketing/sem/getKeywordsByAdgroup";
import updateAdgroup from "redux/thunks/marketing/sem/updateCampaign";
import { AdKeyword } from "types/data";

type Props = {
  item: AdKeyword;
};

const AdKeywordContainer = ({ item }: Props) => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const {
    updateAdGroup: { loading },
  } = useAppSelector(({ updateAdGroup }) => ({
    updateAdGroup,
  }));
  const [form, setForm] = useState({ bid_amount: item.bid_amount });

  const updateKeyword = () => {
    dispatch(
      updateAdgroup({
        action: "update-ad",
        resource: "keywords",
        search_engine: params.get("search_engine"),
        campaign_id: params.get("campaign_id"),
        ad_group_id: params.get("ad_group_id"),
        ...item,
        ...form,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(
          getKeywordsByAdgroup({
            ad_group_id: params.get("ad_group_id"),
            search_engine: params.get("search_engine"),
          })
        );
      }
    });
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  return (
    <KeywordInput
      onChange={onChange}
      item={item}
      form={form}
      updateKeyword={updateKeyword}
      loading={loading}
    />
  );
};

export default AdKeywordContainer;
