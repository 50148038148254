/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "redux/initial-states/paginatedItemsInitialState";
import deleteCoupons from "redux/thunks/app/api-clients/deleteCoupons";

const deleteCouponsSlice = createSlice({
  name: "deleteCoupons",
  initialState: initialState,
  reducers: {
    resetDeleteCoupons: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCoupons.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
        data: initialState.data,
      }))
      .addCase(
        deleteCoupons.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        deleteCoupons.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const { resetDeleteCoupons } = deleteCouponsSlice.actions;

export default deleteCouponsSlice.reducer;
