import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import createUser from "redux/thunks/users/createUser";
import getClients from "redux/thunks/app/getClients";
import { Client } from "types/model/Client";
import UsersCreateComponent from "layout/users/UsersCreate";
import countries from "constants/operationCountries";
import { Option } from "types/option";
import getUsers from "redux/thunks/users/getUsers";
import { toast } from "react-hot-toast";
import getApps from "redux/thunks/users/getApps";

const UsersCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userApps = useAppSelector((state) => state.getApps);

  const actionState = useAppSelector((state) => state.createUser);
  const [form, setForm] = useState<Record<string, any>>({
    is_active: true,
    user_apps: userApps.data.items,
    sub_apps: [],
    clients: [],
    image_url: null,
    slack_member_id: "",
    locations: countries.map((el) => ({
      label: el,
      value: el,
      selected: true,
    })),
  });

  const onChange = ({ target }: any) => {
    const { name, value } = target;

    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      createUser({
        data: {
          ...form,
          clients: form.clients
            .filter((client: Option) => client.selected)
            .map((client: Option) => client.value),
          locations: form.locations
            .filter((location: Option) => location.selected)
            .map((location: Option) => location.value),

          user_apps: form.user_apps
            .filter((app: Option) => app.selected)
            .map((app: Record<string, any>) => app.id),

          user_sub_apps: form.sub_apps
            .filter((app: Record<string, any>) => app.selected)
            .map((app: Record<string, any>) => app.id),
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("User Created!");
        dispatch(getUsers({ page: 1 }));
        navigate(`/settings/users`);
      }
    });
  };

  useEffect(() => {
    dispatch(getClients({ page: 1 })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setForm((form) => ({
          ...form,
          clients: res.payload.items.map((item: Client) => ({
            label: item.name,
            value: item.id,
            selected: true,
          })),
        }));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getApps({}));
  }, []);

  useEffect(() => {
    const selectedApps = form.user_apps.filter(
      (it: Record<string, any>) => it.selected
    );

    const subAppOptions: Record<string, any>[] = [];

    selectedApps.forEach((element: Record<string, any>) => {
      element.sub_apps.forEach((subApp: Record<string, any>) => {
        subAppOptions.push(subApp);
      });
    });

    setForm((f) => ({ ...f, sub_apps: subAppOptions }));
  }, [form.user_apps]);

  return (
    <UsersCreateComponent
      actionState={actionState}
      onSubmit={onSubmit}
      onChange={onChange}
      setForm={setForm}
      form={form}
    />
  );
};

export default UsersCreate;
