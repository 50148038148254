type FormatNumberOptions = {
  locales?: string | string[];
  currency?: string;
  style?: string;
  prefix?: string;
  decimals?: number;
  withCurrency?: boolean;
};

/**
 * @param {int|string} num
 * @param {object} options
 * @returns {string} a formatted number with grouped thousands separated by commas
 */
const formatNumber = (
  num: number | string,
  options?: FormatNumberOptions
): number | string => {
  try {
    let number = num;
    let currency = options?.currency;
    const isNegative = Number(num) < 0;

    if (options?.withCurrency) {
      const [a, b] = String(num).split(" ");
      number = a;
      currency = b;
    }

    number = Number(String(number || "").replace(/[^0-9.]/g, ""));

    const { locales, decimals = 0 } = (options || {}) as FormatNumberOptions;

    number = Number.isNaN(number) ? 0 : number;

    let formattedNumber: number | string = number;

    const separators = {
      en: ",",
      fr: " ",
    } as { [key: string]: string };

    formattedNumber = !options
      ? number
      : number
          .toFixed(!isNaN(decimals) ? decimals : 2)
          .replace(
            /(\d)(?=(\d{3})+(?!\d))/g,
            `$1${separators[String(locales)] || ","}`
          );

    return currency
      ? `${formattedNumber} ${String(currency).toUpperCase()}`
      : `${isNegative ? "-" : ""}${options?.prefix || ""}${formattedNumber}`;
  } catch (error) {
    return num;
  }
};

export const formatNumberWithK = (num: any): string => {
  if (typeof num !== "number" || isNaN(num) || num < 0) {
    return num.toString(); // Return input number if it's not valid
  }

  if (num >= 1000 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
};

export default formatNumber;
