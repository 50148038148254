import getAdvertisers from "redux/slices/advertisers/getAdvertisers";
import loopUpAdvertisers from "redux/slices/advertisers/lookUpAdvertisers";
import getADeactivatedAdvertisers from "redux/slices/advertisers/getADeactivatedAdvertisers";
import getCommissionChanges from "redux/slices/advertisers/getCommissionChanges";
import ignoreAdvertisers from "redux/slices/advertisers/ignoreAdvertisers";

const reducers = {
  getAdvertisers: getAdvertisers,
  loopUpAdvertisers,
  getADeactivatedAdvertisers,
  getCommissionChanges,
  ignoreAdvertisers,
};

export default reducers;
