import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createUpdateCoupon from "redux/thunks/app/api-clients/createUpdateCoupon";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";
import { PageNumPaginatedActionState } from "types/data";
import dayjs from "dayjs";

const isFutureStartDate = (el: Record<string, any>) => {
  if (!el.start_date) {
    return false;
  }

  if (dayjs(el.start_date).toDate() > dayjs().toDate()) {
    return true;
  }
  return false;
};

const initialState: PageNumPaginatedActionState<Record<string, any>> = {
  data: {
    results: [],
    count: 0,
    page: 1,
    next: null,
    previous: null,
  },
  loading: false,
  error: null,
};

const getActiveCouponsSlice = createSlice({
  name: "ActiveCoupons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveCoupons.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
        data: initialState.data,
      }))

      .addCase(getActiveCoupons.fulfilled, (state, action: any) => {
        const { payload } = action;
        if ({ payload }) {
          return {
            ...state,
            data: {
              ...payload,
              results: action.meta?.arg?.allCoupons
                ? payload.results.map((el: Record<string, any>) => ({
                    ...el,
                    tags: [
                      ...el.tags,
                      ...(isFutureStartDate(el) ? ["scheduled"] : []),
                      ...(el.link_id ? ["API"] : []),
                    ],
                  }))
                : payload.results
                    .map((el: Record<string, any>) => ({
                      ...el,
                      sorting_for_store: el.sorting_for_store || "1000",
                      tags: [
                        ...el.tags,
                        ...(isFutureStartDate(el) ? ["scheduled"] : []),
                        ...(el.link_id ? ["API"] : []),
                      ],
                    }))
                    .sort((a: Record<string, any>, b: Record<string, any>) => {
                      if (
                        Number(b.sorting_for_store) >
                        Number(a.sorting_for_store)
                      ) {
                        return -1;
                      } else if (
                        Number(a.sorting_for_store) >
                        Number(b.sorting_for_store)
                      ) {
                        return 1;
                      } else {
                        return 0;
                      }
                    }),
            },
            loading: false,
          };
        }
      })

      .addCase(
        createUpdateCoupon.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                results: payload.deactivated
                  ? state.data.results
                      .filter((it: Record<string, any>) => it.id !== payload.id)
                      .map((el: Record<string, any>) => ({
                        ...el,
                        sorting_for_store: el.sorting_for_store || "1000",
                      }))
                      .sort(
                        (a: Record<string, any>, b: Record<string, any>) => {
                          if (
                            String(b.sorting_for_store).localeCompare(
                              String(a.sorting_for_store)
                            )
                          ) {
                            return 1;
                          } else if (
                            a.sorting_for_store > String(b.sorting_for_store)
                          ) {
                            return -1;
                          } else {
                            return 0;
                          }
                        }
                      )
                  : state.data.results
                      .map((it: Record<string, any>) => it.id)
                      .includes(payload.id)
                  ? state.data.results.map((it: Record<string, any>) =>
                      it.id === payload.id ? payload : it
                    )
                  : [...state.data.results, payload]
                      .map((el: Record<string, any>) => ({
                        ...el,
                        sorting_for_store: el.sorting_for_store || "1000",
                      }))
                      .sort(
                        (a: Record<string, any>, b: Record<string, any>) => {
                          if (
                            String(b.sorting_for_store).localeCompare(
                              String(a.sorting_for_store)
                            )
                          ) {
                            return 1;
                          } else if (
                            a.sorting_for_store > String(b.sorting_for_store)
                          ) {
                            return -1;
                          } else {
                            return 0;
                          }
                        }
                      ),
              },
            };
          }
        }
      )

      .addCase(
        getActiveCoupons.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getActiveCouponsSlice.reducer;
