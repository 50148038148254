import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommissionRateChangesComponent from "layout/bma/CommissionRateChanges";
import getCommissionChanges from "redux/thunks/advertisers/getCommissionChanges";

const CommissionRateChanges = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { getCommissionChanges: getCommissionChangesData } = useAppSelector(
    ({ getCommissionChanges }) => ({
      getCommissionChanges,
    }),
  );

  const getData = useCallback(() => {
    dispatch(getCommissionChanges());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData, searchParams]);

  const onItemClick = (item: Record<string, any>) => {
    navigate(`/bma/commission-rate-changes/${item.advertiser.id}`, {
      state: item,
    });
  };

  return (
    <CommissionRateChangesComponent
      onItemClick={onItemClick}
      getCommissionChanges={getCommissionChangesData}
    />
  );
};

export default CommissionRateChanges;
