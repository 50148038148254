import { User } from "types/model/User";
import CardResultSection from "./CardResultSection";

type Props = {
  user?: User;
  counts: Record<string, any>;
};
const ContributorResult = ({ counts }: Props) => {
  return (
    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
      {counts.map((count: Record<string, any>) => (
        <li
          key={JSON.stringify(count)}
          className="overflow-hidden rounded-xl border border-gray-200"
        >
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <div className="text-sm font-medium leading-6 text-gray-900">
              {count?.user?.first_name} {count?.user?.last_name}
            </div>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 pt-4 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Total Coupons</dt>
              {count.no_of_coupons}
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Coupons with Code</dt>
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900">
                  {count.coupons_with_code}
                </div>
              </dd>
            </div>
          </dl>

          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Daily Average</dt>
              {count.daily_average}
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Codes Ratio</dt>
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900">
                  {count.codes_ratio}%
                </div>
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Stores</dt>
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900">
                  {count.store_count}
                </div>
              </dd>
            </div>
          </dl>

          <CardResultSection title="Affiliate Networks" rows={count.networks} />
          <CardResultSection title="Offer Sources" rows={count.offer_sources} />
          <CardResultSection title="Store Locations" rows={count.locations} />
          <CardResultSection title="Store Ranks" rows={count.store_ranks} />
        </li>
      ))}
    </ul>
  );
};
export default ContributorResult;
