import CategoryStoreSpotlightRow from "components/campaigns/CategoryStoreSpotlightRow";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Message from "components/common/Message";
import Layout from "components/layout/Layout";
import SearchBar from "components/program/SearchBar";
import countries from "constants/operationCountries";
import { ChangeEventHandler } from "react";
import { ProgramCategory } from "types/model/Category";

type Props = {
  loading: boolean;
  data: Array<Record<string, any>>;
  onSearchCategoriesChange: ChangeEventHandler<HTMLInputElement>;
  categoryResults: Array<ProgramCategory>;
  onResultClicked: (result: Record<string, any>) => void;
  closeSearch: () => void;
  saving: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  handlePersistUpdates: () => void;
  handleRemoveCategory: (item: Record<string, any>) => void;
  saveSelectedStores: (
    row: Record<string, any>,
    item: Record<string, any>
  ) => void;
};

export default function OMACampaignsHomeCategorySpotlight({
  data,
  onSearchCategoriesChange,
  categoryResults,
  onResultClicked,
  closeSearch,
  handlePersistUpdates,
  saving,
  handleRemoveCategory,
  saveSelectedStores,
  onChange,
}: Props) {
  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Paid Campaigns
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage the category exposures on the homepage. There are currently
              2 category exposure placements with 3 stores each can be
              highlighted.
            </p>
            <div className="flex items-center gap-4 mt-8">
              <Dropdown
                placeholder="Location"
                name="location"
                onChange={onChange}
                options={countries.map((el) => ({
                  label: el,
                  value: el,
                }))}
              />

              <SearchBar
                placeholder="Choose a Category"
                onChange={onSearchCategoriesChange}
                wrapperClass="my-4"
                onResultClick={onResultClicked}
                onResultsOutsideClick={closeSearch}
                onSearchSubmit={() => {
                  //
                }}
                results={categoryResults}
              />
            </div>
          </div>
        </div>

        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Category Name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Slogan
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Program Name(s)
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Coupon ID(s)
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Coupon Title(s)
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Run Dates
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Manage</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((el) => (
                      <CategoryStoreSpotlightRow
                        saveSelectedStores={saveSelectedStores}
                        row={el}
                        key={el.id}
                        handleRemoveCategory={handleRemoveCategory}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full flex items-end justify-end mt-4">
                <Button
                  text="Save changes"
                  loading={saving}
                  className="bg-indigo-600 text-white"
                  onClick={handlePersistUpdates}
                />
              </div>
            </div>
          </div>
        </div>

        {data.length === 0 && (
          <Message icon={false} title="No ongoing campaigns" description={""} />
        )}
      </div>
    </Layout>
  );
}
