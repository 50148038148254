import Dropdown from "components/common/Dropdown";
import DynamicInput from "components/common/DynamicInput";
import Input from "components/common/Input";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import TextArea from "components/common/TextArea";
import { ChangeEvent, ChangeEventHandler, FormEventHandler } from "react";
import { ActionStateType } from "redux/slices/app/createNetwork";
import { AffiliateNetwork } from "types/model/AffiliateNetwork";
import { Client } from "types/model/Client";
import { Option } from "types/option";

type Props = {
  clients: Option[];
  affiliateNetworks: AffiliateNetwork[];
  onSelectedClientChange: ChangeEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  requiredFields: string[];
  createNetworkState: ActionStateType;
  form: Record<string, any>;
};

function NetworkForm({
  clients,
  affiliateNetworks,
  requiredFields,
  createNetworkState: { loading },
  onSelectedClientChange,
  onChange,
  form,
  onSubmit,
}: Props) {
  const getButtonText = () => {
    if (loading) {
      return "Please wait...";
    }
    if (form.id) {
      return "Save";
    }

    return "Add Network";
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="grid gap-4 mb-4 sm:grid-cols-2">
        <MultipleSelectionInput
          label="Client(s)"
          renderAll
          options={clients}
          name="clients"
          onChange={onSelectedClientChange}
        />

        <Dropdown
          label="Affiliate Network"
          onChange={onChange}
          options={affiliateNetworks.map((it) => ({
            value: it.network_id,
            label: it.network_id,
          }))}
          name="network"
          value={form.network}
        />
        {requiredFields.includes("token") && (
          <Input
            label={`${
              form.network === "rakuten" ? "Security token" : "Token"
            } ${requiredFields.includes("token") ? "(required)" : ""}`}
            onChange={onChange}
            name="token"
            id="token"
            placeholder="Add Network Token"
            value={form.token}
          />
        )}

        {requiredFields.includes("username") && (
          <Input
            label={`${form.network === "impact" ? "Account sid" : "Username"} ${
              requiredFields.includes("username") ? "(required)" : ""
            }`}
            name="username"
            onChange={onChange}
            id="price"
            value={form.username}
          />
        )}

        {requiredFields.includes("email") && (
          <Input
            label={`Email Address ${
              requiredFields.includes("email") ? "(required)" : ""
            }`}
            onChange={onChange}
            type="email"
            name="email"
            id="email"
            value={form.email}
          />
        )}

        {requiredFields.includes("password") && (
          <Input
            onChange={onChange}
            label={`${
              affiliateNetworks.find((it) => it.id === form.network)
                ?.network_id === "impact"
                ? "Auth Token"
                : "Password"
            } ${requiredFields.includes("password") ? "(required)" : ""}`}
            name="password"
            id="password"
            value={form.password}
          />
        )}

        {requiredFields.includes("cid") && (
          <Input
            label={`CID ${requiredFields.includes("cid") ? "(required)" : ""}`}
            name="cid"
            id="cid"
            onChange={onChange}
            value={form.cid}
          />
        )}

        {requiredFields.includes("pids") && form.clients?.length > 0
          ? form.clients?.map((client: Client, idx: number) => (
              <DynamicInput
                key={client?.id}
                name={client?.id}
                id="pid"
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "pids",
                      value: e.target.value,
                    },
                  } as ChangeEvent<HTMLInputElement>);
                }}
                headerText={`Enter a pid for ${client?.name}`}
                label={`PID`}
                values={form.id && client?.pids[form.network]}
              />
            ))
          : null}
        {requiredFields.includes("secret_key") && (
          <div className="sm:col-span-2">
            <TextArea
              id="secret_key"
              name="secret_key"
              rows={4}
              onChange={onChange as any}
              label={
                form.network === "commissionfactory" ||
                form.network.includes("tradetracker")
                  ? "API Key"
                  : "Secret key"
              }
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder={`Enter ${
                form.network === "commissionfactory"
                  ? "an API Key"
                  : "a Secret key"
              } here`}
              value={form.secret_key}
            ></TextArea>
          </div>
        )}

        <div className="sm:col-span-2">
          <TextArea
            id="internal_note"
            name="internal_note"
            rows={4}
            onChange={onChange as any}
            label="Internal Note"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder="Write an internal note here"
            value={form.internal_note}
          ></TextArea>
        </div>
      </div>

      <div className="items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
        <button
          disabled={loading}
          type="submit"
          className="w-full sm:w-auto justify-center text-white inline-flex bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        >
          {getButtonText()}
        </button>
        {/*<button className="w-full sm:w-auto text-white justify-center inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">*/}
        {/*    <svg className="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>*/}
        {/*    Schedule*/}
        {/*</button>*/}
        <button
          data-modal-toggle="createProductModal"
          type="button"
          className="w-full justify-center sm:w-auto text-gray-500 inline-flex items-center bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
        >
          <svg
            className="mr-1 -ml-1 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Discard
        </button>
      </div>
    </form>
  );
}

export default NetworkForm;
