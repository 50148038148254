/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import { ActionState } from "types/data";
import { ProgramCategory } from "types/model/Category";

const initialState: ActionState<ProgramCategory[]> = {
  data: [],
  loading: false,
  error: null,
};

const getCategoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getCategories.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getCategories.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getCategoriesSlice.reducer;
