import Layout from "components/layout/Layout";
import {
  PLACEMENT_DEST_HOME_NAVIGATION,
  PLACEMENT_DEST_HOME_PAGE,
  PLACEMENT_DEST_STORE_SIDE_BAR,
} from "constants/placementDestinations";
import { PLACEMENT_TYPE_HERO_COUPON } from "constants/placementTypeIds";
import { Link } from "react-router-dom";

export default function Campaigns() {
  const campaignOptions = [
    {
      adLocation: "Homepage",
      region: "US",
      placement: "Category Spotlight",
      href: "/oma/campaigns/home-category-spotlight",
    },
    {
      adLocation: "Global",
      region: "Global",
      placement: "Header Seasonal Links",
      href: "/oma/campaigns/header-seasonal-links",
    },
    {
      adLocation: "Homepage",
      region: "ALL",
      placement: "Paid Placements",
      href: `/oma/campaigns/coupon-placements?placement_destination=${PLACEMENT_DEST_HOME_PAGE}&location=US&placement_type=${PLACEMENT_TYPE_HERO_COUPON}`,
    },
    {
      adLocation: "Stores",
      region: "US",
      placement: "Trending Stores",
      href: `/oma/campaigns/trending-stores?placement_destination=${PLACEMENT_DEST_STORE_SIDE_BAR}`,
    },

    {
      adLocation: "Navigation Bar",
      region: "US",
      placement: "Trending Stores",
      href: `/oma/campaigns/trending-stores?placement_destination=${PLACEMENT_DEST_HOME_NAVIGATION}`,
    },

    {
      adLocation: "Navigation Bar",
      region: "US",
      placement: "Categories Dropdown",
      href: "/oma/campaigns/top-categories",
    },
  ];
  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Paid Campaigns
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage the paid placement campaigns across all available Ad
              Placements.
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Ad Location
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Region
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Placement
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Manage</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {campaignOptions.map((el) => (
                      <tr key={el?.href}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {el?.adLocation}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {el?.region}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {el?.placement}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link
                            to={el?.href || "#"}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Manage<span className="sr-only"></span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
