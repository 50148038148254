type Props = {
  label?: string;
  text?: string;
  name?: string;
  onChange?: (event: any) => void;
  checked?: boolean;
};

function Switch({ label, text, name, onChange, checked }: Props) {
  return (
    <div>
      <div className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </div>
      <label
        htmlFor="status"
        className="inline-flex relative items-center cursor-pointer"
      >
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={(e) => {
            onChange?.({
              ...e,
              target: { name, value: e.target.checked },
            });
          }}
          id="status"
          className="sr-only peer"
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
        <span className="ml-3 text-sm font-medium text-gray-500 dark:text-gray-300">
          {text}
        </span>
      </label>
    </div>
  );
}

export default Switch;
