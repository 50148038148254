import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getActiveProgramsAction from "redux/thunks/app/api-clients/getActivePrograms";
import BMAMappingAllComponent from "layout/bma/BMAMappingAll";

const BMAMappingAll = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getActivePrograms } = useAppSelector(
    ({ getAdvertisers, getActivePrograms }) => ({
      getAdvertisers,
      getActivePrograms,
    }),
  );

  const goToItem = (row: Record<string, any>) => {
    navigate(`/bma/mapping/${row.slug}?slug=${row.slug}`, {
      state: row,
    });
  };

  useEffect(() => {
    dispatch(getActiveProgramsAction({ page: 1 }));
  }, []);

  return (
    <BMAMappingAllComponent
      onRowClicked={goToItem}
      getActivePrograms={{
        ...getActivePrograms,
        data: getActivePrograms.data.results,
      }}
    />
  );
};

export default BMAMappingAll;
