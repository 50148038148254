import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import Thunk from "../app/Thunk";

export default Thunk(
  "createStoreExtSetting",
  async (payload: Record<string, any>) => {
    return instance.post(
      DISCOUNT_CODES_API + "/extension/store-extension-settings/",
      {
        store: payload.store.id,
        excluded: payload.excluded,
        auto_open: payload.auto_open,
      }
    );
  }
);
