import { timeAgo } from "index";
import React from "react";

type Props = {
  item: Record<string, any>;
  showCommissionRate?: boolean;
  onViewPage: (item: Record<string, any>) => void;
  onItemSelectChange: (item: Record<string, any>) => void;
  selectedItems: Record<string, any>[];
  renderCouponCodeCount?: boolean;
  renderCouponCount?: boolean;
  hideCreationDate?: boolean
};

function AllAdvertiserRow({
  item,
  onViewPage,
  showCommissionRate = true,
  onItemSelectChange,
  selectedItems,
  renderCouponCodeCount,
  renderCouponCount,
  hideCreationDate=true,
}: Props) {
  return (
    <React.Fragment>
      <tr className="border-b  hover:bg-gray-100 ">
        <td className="pl-4 py-2 w-4">
          <div className="flex items-center">
            <input
              id="checkbox-table-search-1"
              type="checkbox"
              checked={selectedItems.map((it) => it.id).includes(item.id)}
              onChange={() => onItemSelectChange(item)}
              className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500  focus:ring-2"
            />
            <label htmlFor="checkbox-table-search-1" className="sr-only">
              checkbox
            </label>
          </div>
        </td>
        <th
          scope="row"
          className="px-4 py-2 text-xs font-medium text-gray-900 "
        >
          {item.name}
        </th>

        <td className="px-4 py-2 font-medium text-xs text-gray-900 whitespace-nowrap ">
          <a
            href={item.website}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-primary-600 hover:underline inline-flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
            {item.website_base}
          </a>
        </td>

        <th
          scope="row"
          className="px-4 py-2 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.network}
        </th>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.external_id}
        </td>

        {showCommissionRate && (
          <td className="px-4 py-2 font-medium whitespace-nowrap">
            <div className="flex justify-end mb-1">
              <span className="text-xs font-medium w-40 overflow-auto text-gray-500 dark:text-gray-400">
                {JSON.stringify(item.commission, null, 2)}
              </span>
            </div>
          </td>
        )}

        {!hideCreationDate && (
          <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
            {item.created_at ? timeAgo.format(new Date(item.created_at)) : null}
          </td>
        )}

        {renderCouponCount && (
          <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
            {item.number_of_coupons}
          </td>
        )}
        {renderCouponCodeCount && (
          <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
            {item.number_of_codes}
          </td>
        )}

        <td className="p-2">
          <div className="">
            <button
              type="button"
              onClick={() => {
                onViewPage(item);
              }}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              {/*<LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg>
              View Page
            </button>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default AllAdvertiserRow;
