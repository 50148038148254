import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pageNumItemsInitialState from "redux/initial-states/pageNumItemsInitialState";
import searchActiveCoupons from "redux/thunks/app/api-clients/coupons/searchActiveCoupons";
import { PageNumPaginatedData } from "types/data";

export type ActionStateType = {
  data: PageNumPaginatedData<Record<string, any>[]>;
  loading: boolean;
  error: Record<string, any> | null;
};

const searchActiveCouponsSlice = createSlice({
  name: "searchActiveCoupons",
  initialState: pageNumItemsInitialState,
  reducers: {
    clearSearchCouponResults: () => pageNumItemsInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchActiveCoupons.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        searchActiveCoupons.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )

      .addCase(
        searchActiveCoupons.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});
export const { clearSearchCouponResults } = searchActiveCouponsSlice.actions;
export default searchActiveCouponsSlice.reducer;
