import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_BASE_URL } from "config/env";
import {
  REPORT_TYPE_AD_CENTER_REVENUE,
  REPORT_TYPE_PPC,
} from "constants/revenueReportTypes";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";

export default createAsyncThunk(
  "reporting/-reporting-transactions-report",
  async (params: Record<string, any>, ThunkAPI) => {

    const path =
      params.report_type === REPORT_TYPE_AD_CENTER_REVENUE
        ? "/ad-center/"
        : "/transactions-report";

    const endpoint =
      params.report_type === REPORT_TYPE_PPC
        ? "/admin/sem/perf-txn-report/"
        : "/admin/transactions" + path;

    const url = new URL(`${BACKEND_BASE_URL}${endpoint}`);

    Object.entries(params).forEach(([k, v]) => {
      if (v) {
        url.searchParams.set(k, v);
      }
    });

    if (params.page) {
      url.searchParams.set(
        "offset",
        String(getOffsetForPage(Number(params.page)))
      );
    }

    const promise1 = axios.get(
      url.pathname
        .replace("/api", "")
        .replace("/production", "")
        .replace("/dev", "") + url.search
    );
    const promise2 = axios.get(
      url.pathname
        .replace("/api", "")
        .replace("/production", "")
        .replace("/dev", "")
        .replace(path, "/transactions-report/totals") + url.search
    );

    try {
      const response = await Promise.all([promise1, promise2]);

      return {
        report: response[0].data,
        totals: response[1].data,
      };
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
