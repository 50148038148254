import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import getNetworks from "redux/thunks/networks/getNetworks";
import NetworksComponent from "layout/networks/Networks";

const Networks = () => {
  const dispatch = useAppDispatch();
  const { networks: networksData } = useAppSelector(({ networks }) => ({
    networks,
  }));

  useEffect(() => {
    dispatch(getNetworks({ page: 1 }));
  }, []);
  return <NetworksComponent networksData={networksData} />;
};

export default Networks;
