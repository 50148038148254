import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import CreateEmailCampaignComponent from "layout/marketing/email/CreateEmailCampaign";
import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useCallback,
  useState,
} from "react";
import toast from "react-hot-toast";
import update from "immutability-helper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import { clearStoreSearchResults } from "redux/slices/clients/searchActivePrograms";
import getClients from "redux/thunks/app/getClients";
import createEmailCampaign from "redux/thunks/marketing/emails/createEmailCampaign";
import getEmailCampaign from "redux/thunks/marketing/emails/getEmailCampaign";

const CreateEmailCampaign = () => {
  const [params] = useSearchParams();
  const [sortedItems, setSortedItems] = useState<Record<string, any>[]>([]);
  const [form, setForm] = useState<Record<string, any>>({
    campaign_type: "Generic",
    status: "Draft",
    coupons: [],
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    getClients: getClientsData,
    createEmailCampaign: createEmailCampaignData,
    getEmailCampaign: getEmailCampaignData,
  } = useAppSelector(
    ({ getClients, createEmailCampaign, getEmailCampaign }) => ({
      getClients,
      createEmailCampaign,
      getEmailCampaign,
    })
  );

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (sortedItems.length !== 10) {
      toast.error("Please add 10 coupons to your campaign");
      return;
    }

    if (!form.coupon_info.id) {
      toast.error("Please add a featured coupon to your campaign");
      return;
    }

    dispatch(createEmailCampaign({ ...form, coupons: sortedItems })).then(
      (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          if (form.id) {
            toast.success("Email campaign updated successfully");
          } else {
            toast.success("Email campaign created successfully");
          }

          navigate("/marketing/email?tab=1");
        }
      }
    );
  };
  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setForm((f) => ({ ...f, [name]: value }));
  };

  useEffect(() => {
    if (!getClientsData.data.items.length) {
      dispatch(getClients({ page: 1 }));
    }
  }, [getClientsData.data.items.length]);

  const onFeaturedStoreResultClicked = (store: Record<string, any>) => {
    setForm((f) => ({ ...f, featured_store: store }));
    dispatch(clearStoreSearchResults());
    dispatch(clearProgramSearchResults());
  };

  const onFeatureCouponResultClicked = (coupon: Record<string, any>) => {
    setForm((f) => ({ ...f, coupon_info: coupon, featured_store: null }));
  };

  const addCoupon = (coupon: Record<string, any>) => {
    setSortedItems((coupons) => [coupon, ...coupons]);
    setForm((f) => ({ ...f, featured_store: null }));
  };

  useEffect(() => {
    if (params.has("id")) {
      dispatch(getEmailCampaign(params.get("id") || ""));
    }
  }, []);

  useEffect(() => {
    if (params.has("id") && getEmailCampaignData.data?.uuid) {
      setForm((f) => ({
        ...f,
        ...getEmailCampaignData.data,
        client: String(getEmailCampaignData.data?.client.id),
      }));
    }
  }, [getEmailCampaignData.data?.uuid]);

  useEffect(() => {
    if (form.coupons.length > 0) {
      setSortedItems(form.coupons);
    }
  }, [form.coupons.length]);

  const removeCoupon = (key: string, item: Record<string, any>) => {
    if (key == "coupons") {
      setSortedItems((coupons) =>
        coupons.filter((it: Record<string, any>) => it.id !== item.id)
      );
      return;
    }

    if (key == "coupon_info") {
      setForm((f) => ({ ...f, coupon_info: "" }));
    }

    return;
  };

  const updateCoupon = (coupon: Record<string, any>) => {
    setSortedItems((coupons) =>
      coupons.map((it: Record<string, any>) =>
        it.id !== coupon.id ? it : coupon
      )
    );
  };

  const moveDraggableRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSortedItems((order) =>
        update(order, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, order[dragIndex]],
          ],
        })
      );
    },
    []
  );

  return (
    <CreateEmailCampaignComponent
      onChange={onChange}
      form={form}
      handleFormSubmit={handleFormSubmit}
      error={{}}
      updateCoupon={updateCoupon}
      removeCoupon={removeCoupon}
      addCoupon={addCoupon}
      clients={getClientsData.data.items}
      onFeatureCouponResultClicked={onFeatureCouponResultClicked}
      onFeaturedStoreResultClicked={onFeaturedStoreResultClicked}
      createEmailCampaignData={createEmailCampaignData}
      sortedItems={sortedItems}
      moveDraggableRow={moveDraggableRow}
    />
  );
};

export default CreateEmailCampaign;
