import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { debounce } from "lodash";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { resetLocationSuggestions } from "redux/slices/marketing/sem/getLocationSuggestions";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import getLocationSuggestions from "redux/thunks/marketing/sem/getLocationSuggestions";
import getClients from "redux/thunks/app/getClients";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import AddSearchCampaignComponent from "layout/marketing/search/AddSearchCampaign";
import createCampaign from "redux/thunks/marketing/sem/createCampaign";
import { toast } from "react-hot-toast";
import useHeadlines from "./useHeadlines";
import useDescriptions from "./useDescriptions";
import useSiteLinks from "./useSiteLinks";

const SearchMarketingAdd = () => {
  const [params] = useSearchParams();

  const headLines = useHeadlines({ slug: params.get("slug") || "" });
  const descriptions = useDescriptions({ slug: params.get("slug") || "" });
  const siteLinks = useSiteLinks({ slug: params.get("slug") || "" });
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [submitted, setSubmitted] = useState<boolean>(false);

  const {
    getLocationSuggestions: { data: searchResults, loading: searchLoading },
    getProgram: getProgramData,
    getClients: {
      data: { items: clientList },
    },

    createSearchCampaign: createSearchCampaignData,
  } = useAppSelector(
    ({
      getLocationSuggestions,
      getProgram,
      getClients,
      createSearchCampaign,
    }) => ({
      getLocationSuggestions,
      getProgram,
      getClients,
      createSearchCampaign,
    })
  );

  const initialForm = {
    name: getProgramData.data.name,

    client_id: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
    locations: {
      google: [
        {
          id: new Date().getTime(),
          mode: "include",
          location: {
            country: "US",
            id: 2840,
            name: "United States",
            reach: 318000000,
            type: "Country",
          },
        },

        {
          id: new Date().getTime(),
          mode: "include",
          location: {
            country: "GB",
            id: 2826,
            name: "United Kingdom",
            reach: 75300000,
            type: "Country",
          },
        },

        {
          id: new Date().getTime(),
          mode: "include",
          location: {
            country: "CA",
            id: 2124,
            name: "Canada",
            reach: 38900000,
            type: "Country",
          },
        },

        {
          id: new Date().getTime(),
          mode: "include",
          location: {
            country: "AU",
            id: 2036,
            name: "Australia",
            reach: 23700000,
            type: "Country",
          },
        },
      ],

      bing: [
        {
          id: new Date().getTime(),
          mode: "include",
          location: {
            country: "US",
            id: 190,
            name: "United States",
            reach: 318000000,
            type: "Country",
          },
        },

        {
          id: new Date().getTime(),
          mode: "include",
          location: {
            country: "GB",
            id: 188,
            name: "United Kingdom",
            reach: 75300000,
            type: "Country",
          },
        },

        {
          id: new Date().getTime(),
          mode: "include",
          location: {
            country: "CA",
            id: 32,
            name: "Canada",
            reach: 38900000,
            type: "Country",
          },
        },

        {
          id: new Date().getTime(),
          mode: "include",
          location: {
            country: "AU",
            id: 9,
            name: "Australia",
            reach: 23700000,
            type: "Country",
          },
        },
      ],
    },
    display_path1: "",
    display_path2: "",
    store_location: getProgramData.data.location,
    campaign_type: "",
    daily_budget: 50,
    program_slug: getProgramData.data.slug,
    start_date: "",
    end_date: "",
    ad_group_name:
      getProgramData.data.name + getProgramData.data.location === "uk"
        ? "Vouchers"
        : "Discount codes",
    default_bid: {
      bing: 0.45,
      google: 0.45,
    },
    final_url: "",

    head_line1: "",
    head_line2: "",
    head_line3: "",
    head_line4: "",
    head_line5: "",
    head_line6: "",
    head_line7: "",
    head_line8: "",
    head_line9: "",
    head_line10: "",

    description1: "",
    description2: "",
    description3: "",
    description4: "",

    site_link_1_text: "",
    site_link_2_text: "",
    site_link_3_text: "",
    site_link_4_text: "",

    site_link_1_description1: "",
    site_link_2_description1: "",
    site_link_3_description1: "",
    site_link_4_description1: "",

    site_link_1_description2: "",
    site_link_2_description2: "",
    site_link_3_description2: "",
    site_link_4_description2: "",

    site_link_1_final_url: "",
    site_link_2_final_url: "",
    site_link_3_final_url: "",
    site_link_4_final_url: "",

    search_engines: ["Google", "Bing"],

    bidAdjustments: {
      google: [
        {
          label: "Mobile",
          name: "mobile",
          value: "0",
        },
        {
          label: "Desktop",
          name: "desktop",
          value: "0",
        },
        {
          label: "Tablet",
          value: "0",
          name: "tablet",
        },
      ],

      bing: [
        {
          label: "Mobile",
          name: "mobile",
          value: "0",
        },
        {
          label: "Desktop",
          name: "desktop",
          value: "0",
        },
        {
          label: "Tablet",
          value: "0",
          name: "tablet",
        },
      ],
    },
  };

  const [form, setForm] = useState<Record<string, any>>(initialForm);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (params.get("slug")) {
      setForm(() => initialForm);
    }
  }, [params.get("slug")]);

  useEffect(() => {
    if (!clientList?.length && getProgramData.data.slug) {
      dispatch(getClients({ page: 1 }));
    } else {
      const client = clientList.find(
        (it) =>
          String(it.id) === localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)
      );

      if (client && getProgramData.data) {
        setForm((f) => ({
          ...f,
          name: getProgramData.data.name,
          program_slug: getProgramData.data.slug,
          final_url: client.website_url + "/" + getProgramData.data.slug,
          ad_group_name:
            getProgramData.data.name +
            (getProgramData.data.location === "uk"
              ? " Vouchers"
              : " Discount codes"),
          store_location: getProgramData.data.location,
          display_path1:
            getProgramData.data.name?.length <= 15
              ? getProgramData.data.name
              : "discount-codes",

          display_path2:
            getProgramData.data.location === "uk" ? "vouchers" : "coupons",
          locations: {
            google: getDefaultSelectedLocations(f.locations, "google"),
            bing: getDefaultSelectedLocations(f.locations, "bing"),
          },
        }));
      }
    }
  }, [getProgramData.data.slug, clientList]);

  const getDefaultSelectedLocations = (
    locations: Record<string, any>,
    key: string
  ) => {
    return locations[key]
      .map((it: { location: { name: string } }) => {
        if (it.location.name.toLowerCase() === getProgramData.data.location) {
          return { ...it, mode: "include" };
        }

        if (
          it.location.name === "United Kingdom" &&
          getProgramData.data.location === "uk"
        ) {
          return { ...it, mode: "include" };
        }

        if (
          it.location.name === "United States" &&
          getProgramData.data.location === "us"
        ) {
          return { ...it, mode: "include" };
        }

        return { ...it, mode: "exclude" };
      })
      .sort((a: { mode: string }, b: any) => (b.mode === "exclude" ? -1 : 1));
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    if (submitted) {
      setSubmitted(false);
    }
    if (name === "bingSearchText") {
      setSearchText(value);
      debounceFn(value, "bing");

      return;
    }

    if (name === "googleSearchText") {
      setSearchText(value);
      debounceFn(value, "google");

      return;
    }

    setForm((form) => ({ ...form, [name]: value }));
  };

  const dispatch = useAppDispatch();
  const debounceFn = useCallback(
    debounce((value, formKey) => {
      if (value) {
        dispatch(
          getLocationSuggestions({
            search_text: value,
            search_engine: formKey,
          })
        );
      } else {
        dispatch(resetLocationSuggestions());
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (params.has("slug")) {
      dispatch(
        getProgram({
          query: params.get("slug") || "",
        })
      );
    }
  }, [params.get("slug")]);

  const onLocationSearchSubmit = (formKey: string) => {
    dispatch(
      getLocationSuggestions({
        search_text: searchText,
        search_engine: formKey,
      })
    );
  };

  const resetResults = () => {
    dispatch(resetLocationSuggestions());
  };

  const onResultSearchResultsClick = (
    formKey: string,
    res: Record<string, any>
  ) => {
    setForm((f) => ({
      ...f,
      locations: {
        ...f.locations,
        [formKey]: [
          {
            id: new Date().getTime(),
            location: res,
            mode: "include",
          },
          ...f.locations[formKey],
        ],
      },
    }));

    dispatch(resetLocationSuggestions());
  };

  const removeSelectedLocation = (
    formKey: string,
    result: Record<string, any>
  ) => {
    const latestFormKeyLocations = form.locations[formKey].filter(
      (el: Record<string, any>) => el.location.id !== result.location.id
    );

    setForm((f) => ({
      ...f,
      locations: {
        ...f.locations,
        [formKey]: latestFormKeyLocations,
      },
    }));
  };

  const updateResultLocationMode = (
    formKey: string,
    result: Record<string, any>,
    mode: string
  ) => {
    setForm((f) => ({
      ...f,
      locations: {
        ...f.locations,

        [formKey]: f.locations[formKey].map((el: Record<string, any>) =>
          el.location.id === result.location.id
            ? {
                ...el,
                mode: mode,
              }
            : el
        ),
      },
    }));
  };

  const isDuplicated = (val: string, key: string): boolean => {
    const valList: string[] = [];

    Object.entries(form).forEach(([k, v]) => {
      if (k.startsWith(key)) {
        valList.push(v);
      }
    });

    return valList.filter((it) => it === val).length > 1;
  };

  const handleErrors = () => {
    Object.entries(form).forEach(([k, v]) => {
      if (k.startsWith("description")) {
        if (v.length > 90) {
          setErrors((err) => ({
            ...err,
            [k]: "This field requires max 90 characters",
          }));
        } else if (isDuplicated(v, "description")) {
          setErrors((err) => ({
            ...err,
            [k]: "This field value should be unique",
          }));
        } else {
          setErrors((err) => ({
            ...err,
            [k]: null,
          }));
        }
      }

      if (k.startsWith("head_line")) {
        if (v.length > 30) {
          setErrors((err) => ({
            ...err,
            [k]: "This field requires max 30 characters",
          }));
        } else if (isDuplicated(v, "head_line")) {
          setErrors((err) => ({
            ...err,
            [k]: "This field value should be unique",
          }));
        } else {
          setErrors((err) => ({
            ...err,
            [k]: null,
          }));
        }
      }

      if (k.startsWith("site_link")) {
        if (k.includes("text")) {
          if (v.length > 25) {
            setErrors((err) => ({
              ...err,
              [k]: "This field requires max 25 characters",
            }));
          } else {
            setErrors((err) => ({
              ...err,
              [k]: null,
            }));
          }
        }

        if (k.includes("description")) {
          if (v.length > 35) {
            setErrors((err) => ({
              ...err,
              [k]: "This field requires max 35 characters",
            }));
          } else {
            setErrors((err) => ({
              ...err,
              [k]: null,
            }));
          }
        }
      }
    });

    if (form.locations.length === 0) {
      setErrors((err) => ({
        ...err,
        locations: "This field is required",
      }));
    }
  };

  useEffect(() => {
    const hasErrors = Object.values(errors).filter((it) => it).length > 0;
    if (hasErrors) {
      return;
    }
    if (!hasErrors && submitted) {
      dispatch(createCampaign(form)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          res.payload.forEach((el: Record<string, any>) => {
            const errorText = Array.isArray(el.error)
              ? el.error[0]?.error
              : el.error;
            if (el.search_engine === "google") {
              if (el.status === 201) {
                toast.success("Google Campaign has been created!");
              } else {
                toast.error(errorText || "Failed to created Google Campaign");
              }
            }

            if (el.search_engine === "bing") {
              if (el.status === 201) {
                toast.success("Bing Campaign has been created!");
              } else {
                toast.error(errorText || "Failed to created Bing Campaign");
              }
            }
          });

          if (
            res.payload
              .map((el: Record<string, any>) => el.status)
              .filter((status: number) => status === 201).length === 2
          ) {
            setForm(initialForm);
            setSubmitted(false);
          }
        }
      });
    }
  }, [submitted, errors]);

  useEffect(() => {
    if (headLines) {
      setForm((f) => ({
        ...f,
        head_line1: headLines[0],
        head_line2: headLines[1],
        head_line3: headLines[2],
        head_line4: headLines[3],
        head_line5: headLines[4],
        head_line6: headLines[5],
        head_line7: headLines[6],
        head_line8: headLines[7],
        head_line9: headLines[8],
        head_line10: headLines[9],
      }));
    }
  }, [headLines]);

  useEffect(() => {
    if (descriptions) {
      setForm((f) => ({
        ...f,
        description1: descriptions[0],
        description2: descriptions[1],
        description3: descriptions[2],
        description4: descriptions[3],
      }));
    }
  }, [descriptions]);

  useEffect(() => {
    if (siteLinks) {
      const { siteLink1, siteLink2, siteLink3, siteLink4 } = siteLinks;

      setForm((f) => ({
        ...f,
        site_link_1_text: siteLink1.text,
        site_link_1_description1: siteLink1.description1,
        site_link_1_description2: siteLink1.description2,
        site_link_1_final_url: siteLink1.finalURL,
        site_link_2_text: siteLink2.text,
        site_link_2_description1: siteLink2.description1,
        site_link_2_description2: siteLink2.description2,
        site_link_2_final_url: siteLink2.finalURL,

        site_link_3_text: siteLink3.text,
        site_link_3_description1: siteLink3.description1,
        site_link_3_description2: siteLink3.description2,
        site_link_3_final_url: siteLink3.finalURL,

        site_link_4_text: siteLink4.text,
        site_link_4_description1: siteLink4.description1,
        site_link_4_description2: siteLink4.description2,
        site_link_4_final_url: siteLink4.finalURL,
      }));
    }
  }, [siteLinks]);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setSubmitted(true);

    handleErrors();
  };

  return (
    <AddSearchCampaignComponent
      handleSubmit={handleSubmit}
      onChange={onChange}
      form={form}
      error={errors}
      searchLoading={searchLoading}
      updateResultLocationMode={updateResultLocationMode}
      removeSelectedLocation={removeSelectedLocation}
      onLocationSearchSubmit={onLocationSearchSubmit}
      onResultSearchResultsClick={onResultSearchResultsClick}
      resetSearchResults={resetResults}
      searchResults={searchResults}
      createSearchCampaignData={createSearchCampaignData}
    />
  );
};

export default SearchMarketingAdd;
