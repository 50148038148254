import TabLayout from "components/common/TabLayout";
import { LOCAL_KEY_USER_PENDING_FOLLOW_UPS } from "constants/storageKeys";
import Feed from "containers/sales/crm/Feed";
import Followups from "containers/sales/crm/Followups";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch } from "hooks/useRedux";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getEvents from "redux/thunks/sales_crm/getEvents";
import getFollowups from "redux/thunks/sales_crm/getFollowups";

const FeedSubTabs = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const { user } = useLoadAuth();
  const { subTab } = Object.fromEntries(params);

  const navigate = useNavigate();
  const tabs = [
    {
      text: "Activity Feed",
      onClick: () => {
        const url = new URL(window.location.href);
        url.searchParams.set("subTab", "events");
        navigate(url.search);
      },
      url: "/sales/crm/?tab=2&tab-id=feed",
      active: subTab !== "follow-ups",
      content: <Feed />,
    },

    {
      text: "Follow ups",
      active: subTab === "follow-ups",
      onClick: () => {
        const url = new URL(window.location.href);
        url.searchParams.set("subTab", "follow-ups");
        navigate(url.search);
      },
      url: "/sales/crm/?tab=1&tab-id=home",
      content: <h5></h5>,
      label: Number(
        localStorage.getItem(LOCAL_KEY_USER_PENDING_FOLLOW_UPS) || "0"
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getFollowups({
        has_pending_followup: "1",
        user_id: user?.id || "",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        localStorage.setItem(
          LOCAL_KEY_USER_PENDING_FOLLOW_UPS,
          res.payload.meta.count
        );
      }
    });
  }, []);

  return (
    <div className="my-4">
      <TabLayout variant="simple" options={tabs} globalLayout={false} />

      {subTab === "follow-ups" && <Followups />}
      {subTab !== "follow-ups" && <Feed />}
    </div>
  );
};

export default FeedSubTabs;
