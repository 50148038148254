import { Column } from "types/column";

const ADVERTISER_COLUMNS = [
  {
    key: "",
    label: "",
    component: "components/common/Checkbox",
  },
  {
    key: "name",
    label: "PROGRAM NAME",
    isSortable: false,
    classNames: {
      head: "text-green-400",
      body: "text-blue-700 cursor-pointer",
    },
  },
  {
    key: "website_base",
    label: "HOMEPAGE WEBSITE URL",
    isSortable: false,
  },

  {
    key: "network",
    label: "NETWORK",
    isSortable: false,
  },

  { key: "external_id", label: "NETWORK ID", isSortable: false },
  { key: "commission_summary", label: "COMMISSION RATE", isSortable: false },

  { key: "", label: "PARTNERSHIP STATUS", isSortable: false },

  { key: "updated_at", label: "LAST SYNC", isSortable: false },

  { key: "Actions", label: "Action", isSortable: false },
] as Column[];

export default ADVERTISER_COLUMNS;
