import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getNetworkTypes from "redux/thunks/sales_crm/getNetworkTypes";
import { CRMNetworkType } from "types/model/sales_crm/NetworkType";

export type ActionStateType = {
  data: CRMNetworkType[];
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getNetworkTypesSlice = createSlice({
  name: "NetworkTypes",
  initialState,
  reducers: {
    resetNetworkTypes: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNetworkTypes.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getNetworkTypes.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        getNetworkTypes.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetNetworkTypes } = getNetworkTypesSlice.actions;
export default getNetworkTypesSlice.reducer;
