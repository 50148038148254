import { currencyNameSymbolMap } from "constants/storeCurrencies";
import { capitalize, get } from "lodash";
import React from "react";
import { twMerge } from "tailwind-merge";
import formatDate from "utils/dates/formatDate";
import formatNumber from "utils/strings/formatNumber";
import TotalSaleAmounts from "./TotalSaleAmounts";
import TotalCommissionAmounts from "./TotalCommissionAmounts";
import { Column } from "types/column";

type Props = {
  item: Record<string, any>;
  index: number;
  totals: Record<string, any>;
  additionalColumns: Column[];
};

function AdvertiserTransactionsReportRow({
  item,
  index,
  totals,
  additionalColumns,
}: Props) {
  const wrapperCls = twMerge(
    "border-b  opacity-100 transition-all hover:bg-gray-100 cursor-pointer",
    index === 0 && "bg-green-100 font-bold"
  );

  return (
    <React.Fragment>
      <tr className={wrapperCls}>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.client?.name || item.website_name}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {formatDate(item.action_date)}
        </td>

        <th
          scope="row"
          className="px-2 py-4 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {formatDate(item.sale_date)}
        </th>

        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {item.network_advertiser?.name}{" "}
        </th>

        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {item.network_advertiser?.network}{" "}
        </th>

        <th
          scope="row"
          className="px-2 py-4 text-xs  font-medium text-gray-900 "
        >
          {capitalize(item.status)}{" "}
        </th>
        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0 ? (
            <TotalSaleAmounts totals={totals} />
          ) : (
            formatNumber(item?.sale_amount / 1000, {
              decimals: 2,
              prefix: currencyNameSymbolMap[item.sale_currency],
            })
          )}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals?.total_sale_amount_usd / 1000, {
                decimals: 2,
                prefix: "$",
              })
            : formatNumber(item?.sale_amount_usd / 1000, {
                decimals: 2,
                prefix: "$",
              })}
        </td>

        <td className="px-4 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0 ? (
            <TotalCommissionAmounts totals={totals} />
          ) : (
            formatNumber(item?.commission_amount / 1000, {
              decimals: 2,
              prefix: currencyNameSymbolMap[item.sale_currency],
            })
          )}
        </td>

        <td className="px-4 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {index === 0
            ? formatNumber(totals?.total_commission_usd / 1000, {
                decimals: 2,
                prefix: "$",
              })
            : formatNumber(item?.commission_amount_usd / 1000, {
                decimals: 2,
                prefix: "$",
              })}
        </td>

        <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.sid}
        </td>

        {additionalColumns.map((col, index) => (
          <td
            key={`additional-data-${index}`}
            className={twMerge(
              "px-2 py-4 whitespace-nowrap font-medium text-gray-900 text-xs",
              col.key == "client_location" && "uppercase"
            )}
          >
            {get(item, col.key)}
          </td>
        ))}
      </tr>
    </React.Fragment>
  );
}

export default AdvertiserTransactionsReportRow;
