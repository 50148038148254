import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import EditLocationTargetsComponent from "layout/marketing/search/EditLocationTargets";
import { debounce } from "lodash";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { resetLocationSuggestions } from "redux/slices/marketing/sem/getLocationSuggestions";
import getLocationSuggestions from "redux/thunks/marketing/sem/getLocationSuggestions";
import updateCampaign from "redux/thunks/marketing/sem/updateCampaign";

const EditLocationTargets = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const [search_engine, set_search_engine] = useState(
    params.get("search_engine") || "bing",
  );

  const [deletedNames, setDeletedResourceNames] = useState<string[]>([]);

  const {
    getAdCampaigns: { data: getAdCampaignsData, loading: campaignLoading },
    updateAdGroup: { loading: submitting },
    getLocationSuggestions: { data: searchResults, loading: searchLoading },
  } = useAppSelector(
    ({ updateAdGroup, getLocationSuggestions, getAdCampaigns }) => ({
      getLocationSuggestions,
      getAdCampaigns,
      updateAdGroup,
    }),
  );

  const initialForm = {
    action: "update-location-targets",
    search_engine: params.get("search_engine"),
  };

  const [form, setForm] = useState<Record<string, any>>(initialForm);
  const [searchText, setSearchText] = useState<string>("");
  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  const debounceFn = useCallback(
    debounce((value, formKey) => {
      if (value) {
        dispatch(
          getLocationSuggestions({
            search_text: value,
            search_engine: formKey,
          }),
        );
      } else {
        dispatch(resetLocationSuggestions());
      }
    }, 1000),
    [],
  );

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    if (name === "bingSearchText") {
      setSearchText(value);
      debounceFn(value, "bing");

      return;
    }

    if (name === "googleSearchText") {
      setSearchText(value);
      debounceFn(value, "google");

      return;
    }
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = () => {
    dispatch(
      updateCampaign({
        ...form,
        deleted_campaign_criterion_ids: deletedIds,
        deleted_resource_names: deletedNames,
        newly_added_locations: form.locations[search_engine].filter(
          (it: Record<string, any>) => it.newlyAdded,
        ),
      }),
    );
  };

  const resetResults = () => {
    dispatch(resetLocationSuggestions());
  };

  const onResultSearchResultsClick = (
    formKey: string,
    res: Record<string, any>,
  ) => {
    setForm((f) => ({
      ...f,
      locations: {
        ...f.locations,
        [formKey]: [
          {
            id: new Date().getTime(),
            location: res,
            mode: "include",
            newlyAdded: true,
          },
          ...f.locations[formKey],
        ],
      },
    }));

    dispatch(resetLocationSuggestions());
  };

  const onLocationSearchSubmit = (formKey: string) => {
    dispatch(
      getLocationSuggestions({
        search_text: searchText,
        search_engine: formKey,
      }),
    );
  };

  const removeSelectedLocation = (
    formKey: string,
    result: Record<string, any>,
  ) => {
    const latestFormKeyLocations = form.locations[formKey].filter(
      (el: Record<string, any>) => el.location.id !== result.location.id,
    );

    setForm((f) => ({
      ...f,
      locations: {
        ...f.locations,
        [formKey]: latestFormKeyLocations,
      },
    }));

    setDeletedIds((ids) => [...ids, result.location.criterion_id]);
    setDeletedResourceNames((names) => [
      ...names,
      result.location.resource_name,
    ]);
  };

  const updateResultLocationMode = (
    formKey: string,
    result: Record<string, any>,
    mode: string,
  ) => {
    setForm((f) => ({
      ...f,
      locations: {
        ...f.locations,
        [formKey]: f.locations[formKey].map((el: Record<string, any>) =>
          el.location.id === result.location.id
            ? {
                ...el,
                mode: mode,
              }
            : el,
        ),
      },
    }));
  };

  useEffect(() => {
    const campaign = getAdCampaignsData.find(
      (it) => String(it.id) === params.get("campaign_id"),
    );

    if (campaign) {
      set_search_engine(campaign.search_engine);
      setForm((f) => ({
        ...f,
        ad_group_name: campaign.ad_group_name,
        default_bid: campaign.ad_group_default_cpc,
        ad_group_status: campaign.ad_group_status,
        campaign_id: campaign.id,
        search_engine: campaign.search_engine,

        locations: {
          [campaign.search_engine]: campaign.locations.map(
            (it: Record<string, any>) => ({
              id: it.id,
              mode: it.mode === "excluded" ? "exclude" : "include",
              location: {
                ...it,
                country: "",
                id: it.id,
                name: it.text,
                reach: null,
                type: it.type,
              },
            }),
          ),
        },
      }));
    }
  }, [getAdCampaignsData]);

  return (
    <EditLocationTargetsComponent
      onSubmit={onSubmit}
      form={form}
      loading={submitting}
      campaignLoading={campaignLoading}
      onChange={onChange}
      searchLoading={searchLoading}
      updateResultLocationMode={updateResultLocationMode}
      removeSelectedLocation={removeSelectedLocation}
      onLocationSearchSubmit={onLocationSearchSubmit}
      onResultSearchResultsClick={onResultSearchResultsClick}
      resetSearchResults={resetResults}
      searchResults={searchResults}
    />
  );
};

export default EditLocationTargets;
