import { Column } from "types/column";

const ALL_CAMPAIGN_COLUMNS = [
  {
    key: "created_at",
    label: "Created At",
    isSortable: false,
  },
  {
    key: "client",
    label: "Website",
    isSortable: false,
  },
  {
    key: "Search Engine",
    label: "Search Engine",
    isSortable: false,
  },
  {
    key: "campaign_name",
    label: "Campaign Name",
    isSortable: false,
  },

  {
    key: "campaign_location",
    label: "Campaign Location",
    isSortable: false,
  },
] as Column[];

export default ALL_CAMPAIGN_COLUMNS;
