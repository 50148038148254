import { useEffect, useState } from "react";
import formatDate from "utils/dates/formatDate";
import formatNumber from "utils/strings/formatNumber";
import { useAppDispatch } from "hooks/useRedux";
import getProgramById from "redux/thunks/app/api-clients/getProgramById";
import { isString } from "lodash";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const statuses: Record<string, string> = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Pending: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Waived: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

type Props = {
  proposal: Record<string, any>;
  wrapperClass: string;
};

export default function ProposalCard({ proposal, wrapperClass }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const program = proposal.client_merchant_config?.[0] || {};
  const [store, setStore] = useState<Record<string, any>>({
    name: program.program_name,
    slug: program.program_slug,
    client: program.client,
    id: program.program_id,
    logo_url: "",
    primary_network: {},
  });

  useEffect(() => {
    if (program.program_id) {
      dispatch(
        getProgramById({
          client_id: program.client,
          id: isString(program.program_slug)
            ? program.program_slug
            : (program.program_id as string),
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setStore(res.payload);
        }
      });
    }
  }, []);

  const isWaived = Number(proposal.final_amount) === 0;

  const paymentStatus = isWaived
    ? "Waived"
    : Number(proposal.amount_paid) === Number(proposal.final_amount)
    ? "Paid"
    : "Pending";

  return (
    <div
      className={twMerge(
        "my-4  rounded-xl border border-gray-200",
        wrapperClass
      )}
    >
      <div
        onClick={() => {
          navigate(`/sales/crm/store/${store.slug}`);
        }}
        className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6"
      >
        {store.logo_url && (
          <img
            src={store.logo_url}
            alt={store.name}
            className="h-12 rounded-full w-12 object-cover"
          />
        )}
        <div className="text-sm font-medium leading-6 text-gray-900">
          {store.name}
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="py-3 grid grid-cols-2 gap-x-4">
          <dt className="text-gray-500">
            {proposal.proposal_status === "Approved"
              ? "Insertion Order"
              : "Proposal"}{" "}
            #
          </dt>
          <dd className="text-gray-900">
            {proposal.proposal_status === "Approved"
              ? proposal.io_id
              : proposal.proposal_id}
          </dd>
          <dt className="text-gray-500">
            {proposal.proposal_status === "Approved"
              ? "IO Approved"
              : "Proposal Created"}{" "}
          </dt>
          <dd className="text-gray-700">
            {proposal.approved_at ? (
              <time dateTime={proposal.approved_at}>
                {formatDate(proposal.approved_at)}
              </time>
            ) : (
              <time dateTime={proposal.created_at}>
                {formatDate(proposal.created_at)}
              </time>
            )}
          </dd>
          <dt className="text-gray-500">Manager</dt>
          <dd className="text-gray-900">
            {proposal.created_by?.first_name ||
              "" + " " + proposal.created_by?.last_name}
          </dd>
          <dt className="text-gray-500">Amount</dt>
          <dd className="text-gray-900 flex items-center">
            {formatNumber(proposal.final_amount, {
              prefix: proposal.currency_symbol,
            })}
            <span
              className={twMerge(
                "ml-2 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
                statuses[paymentStatus],
                isWaived && statuses["Pending"]
              )}
            >
              {isWaived ? "Waived" : paymentStatus}
            </span>
          </dd>
          <dt className="text-gray-500">Network</dt>
          <dd className="text-gray-900">{store.primary_network?.name}</dd>
          <dt className="text-gray-500 whitespace-nowrap">
            Commission Increase
          </dt>
          <dd className="text-gray-900">
            {proposal.commission_increase ? "+" : ""}
            {proposal.commission_increase_type === "Amount"
              ? proposal.currency_symbol
              : ""}
            {proposal.commission_increase}
            {proposal.commission_increase_type === "Percent" ? "%" : ""}
          </dd>
          <dt className="text-gray-500">Proposal Type</dt>
          <dd className="text-gray-900 ">{proposal.proposal_type?.name}</dd>
        </div>
      </dl>
    </div>
  );
}
