import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getAffiliateEmailAccounts from "redux/thunks/links/newsletters/getAffiliateEmailAccounts";
import { PaginatedData } from "types/data";
import { Link } from "types/model/Link";

export type ActionStateType = {
  data: PaginatedData<Link>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
      extra: {},
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getAffiliateEmailAccountsSlice = createSlice({
  name: "getAffiliateEmailAccounts",
  initialState,
  reducers: {
    resetAffiliateNewsletterFeed: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAffiliateEmailAccounts.pending, (state) => {
        return {
          ...state,
          error: null,
          loading: true,
        };
      })
      .addCase(
        getAffiliateEmailAccounts.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...payload,
                items: payload.items,
              },
              loading: false,
            };
          }
        }
      )

      .addCase(
        getAffiliateEmailAccounts.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetAffiliateNewsletterFeed } =
  getAffiliateEmailAccountsSlice.actions;
export default getAffiliateEmailAccountsSlice.reducer;
