import { useEffect } from "react";
import StoreCoupons from "layout/oma/StoreCoupons";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";
import { useNavigate, useSearchParams } from "react-router-dom";

const StoreCoupon = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { loading, data } = useAppSelector((state) => state.getActiveCoupons);
  const { getProgram: getProgramData } = useAppSelector((state) => state);
  const page = params.has("page") ? Number(params.get("page")) : 1;
  const slug = params.get("slug");

  useEffect(() => {
    if (slug) {
      dispatch(
        getActiveCoupons({
          filter: "all",
          page: 1,
          store_slug: slug,
        })
      );
    }
  }, [slug]);

  useEffect(() => {
    dispatch(
      getActiveCoupons({
        filter: "all",
        page: 1,
        store_slug: getProgramData.data.slug,
      })
    );
  }, [getProgramData.data.slug]);

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);

    url.searchParams.set("page", String(page));

    navigate(url.search);
  };

  return (
    <StoreCoupons
      onPageChange={onPageChange}
      page={page}
      loading={loading}
      data={data}
    />
  );
};

export default StoreCoupon;
