import Thunk from "../Thunk";
import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";

export default Thunk(
  "clients/createAutoCouponTemplate",
  async (payload: Record<string, any>) => {
    const url = `${DISCOUNT_CODES_API}/coupons/auto-coupon-templates/${payload.id}/`;

    if (payload.isEditing) {
      return await instance.patch(url, payload);
    } else {
      return await instance.delete(url);
    }
  }
);
