import Input from "components/common/Input";
import Modal from "components/common/Modal";
import React, { ChangeEventHandler, useEffect, useState } from "react";

type Props = {
  coupon: Record<string, any>;
  removeItemHandler?: (item: Record<string, any>) => void;
  allowEdit?: boolean;
  updateCoupon?: (coupon: Record<string, any>) => void;
  shoulDrag?:boolean
};

const SimpleCouponRow = ({
  coupon,
  updateCoupon,
  removeItemHandler,
  allowEdit,
  shoulDrag,
}: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [form, setForm] = useState(coupon);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    setForm(coupon);
  }, [coupon]);

  const tableData = (
    <>
      <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs flex items-center gap-2">
        <img src={coupon.store?.logo_url} width={60} height={60} />
        {coupon.store?.name}
      </td>
      <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
        <p
          className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
          style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
        >
          {coupon.title}
        </p>
      </td>
      <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
        <p
          className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
          style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
        >
          {coupon.description}
        </p>
      </td>

      <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
        {" "}
        {coupon.click_count}
      </td>

      <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
        {allowEdit && (
          <button
            className="px-4"
            onClick={() => {
              setEditModalOpen(true);
            }}
          >
            Edit
          </button>
        )}
        {typeof removeItemHandler === "function" && (
          <button
            onClick={() => {
              removeItemHandler?.(coupon);
            }}
          >
            X
          </button>
        )}
      </td>
    </>
  );

  return (
    <React.Fragment>
      <Modal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        onConfirm={() => {
          updateCoupon?.(form);
          setEditModalOpen(false);
        }}
      >
        <h4 className="my-2 font-medium">
          Customize headline and description{" "}
        </h4>

        <div>
          <Input
            label="Headline"
            name="title"
            onChange={onChange}
            required
            wrapperClassName="mb-4"
            value={form.title}
          />

          <Input
            label="Description"
            name="description"
            onChange={onChange}
            required
            value={form.description}
          />
        </div>
      </Modal>
      {shoulDrag ? (
        tableData
      ) : (
        <tr className="border-b opacity-100 transition-all hover:bg-gray-100">
          {tableData}
          {/* <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs flex items-center gap-2">
          <img src={coupon.store?.logo_url} width={60} height={60} />
          {coupon.store?.name}
        </td>
        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
          <p
            className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
            style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
          >
            {coupon.title}
          </p>
        </td>
        <td className="px-4 py-2 max-w-sm font-medium text-gray-900 text-xs">
          <p
            className=" whitespace-pre-line text-overflow-ellipsis overflow-hidden max-h-16 truncate"
            style={{ lineHeight: "1.2em", maxHeight: "4.8em" }}
          >
            {coupon.description}
          </p>
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
          {" "}
          {coupon.click_count}
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
          {allowEdit && (
            <button
              className="px-4"
              onClick={() => {
                setEditModalOpen(true);
              }}
            >
              Edit
            </button>
          )}
          {typeof removeItemHandler === "function" && (
            <button
              onClick={() => {
                removeItemHandler?.(coupon);
              }}
            >
              X
            </button>
          )}
        </td> */}
        </tr>
      )}
    </React.Fragment>
  );
};

export default SimpleCouponRow;
