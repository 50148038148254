import ClientAPI from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";
import updateOperationThunk from "../../Thunk";

interface FilePayload {
  [key: string]: File;
}

export default updateOperationThunk(
  "api-clients/createUpdateCategory",
  async ({ data }: Record<string, any>) => {
    const payload = { ...data };

    for (const [k, v] of Object.entries<FilePayload>(payload.files || {})) {
      if (v.file) {
        const s3 = new S3({
          ...defaultS3Config,
          dirName: `img/${localStorage.getItem(
            LOCAL_KEY_SELECTED_CLIENT_ID
          )}/categories/${payload.id}/${k.split("_")[0]}`,
        });
        const result = await s3.uploadFile(v.file);
        const { location } = result;
        payload[k] = location;
      }
    }

    const clientWebsite = new ClientAPI();
    return await clientWebsite.createUpdateCategory({
      ...payload,
      client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
    });
  }
);
