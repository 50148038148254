import { ActionState } from "types/data";
import formatDate from "utils/dates/formatDate";
import BiBarChart from "./BiBarChart";
import { capitalizeKeys } from "utils/strings/getFirstInt";

type Props = {
  getPPCMarginRevenueTrendData: ActionState<Record<string, any>[]>;
  setTrendType: (type: string) => void;
  filterValues: Record<string, any>;
};

const PPCMarginTrendComponent = ({
  getPPCMarginRevenueTrendData,
  setTrendType,
  filterValues,
}: Props) => {
  const getYAxisKeyName = (k: string) => {
    const [start, end] = k.split("_");
    if (
      filterValues.trend_type === "daily" &&
      !getPPCMarginRevenueTrendData.loading
    ) {
      return formatDate(start);
    }
    return `${formatDate(start)}-${formatDate(end)}`;
  };

  return (
    <div>
      <div style={{ width: "100%", height: 500 }}>
        <BiBarChart
          loading={getPPCMarginRevenueTrendData.loading}
          rangeOptions={[
            {
              label: "Day",
              onClick: () => {
                setTrendType("daily");
              },
              active: filterValues.trend_type === "daily",
            },
            {
              label: "Week",
              active: filterValues.trend_type === "weekly",
              onClick: () => {
                setTrendType("weekly");
              },
            },

            {
              label: "Month",
              active: filterValues.trend_type === "monthly",
              onClick: () => {
                setTrendType("monthly");
              },
            },
          ]}
          title="Margin"
          data={capitalizeKeys(getPPCMarginRevenueTrendData.data).map((el) => ({
            ...el,
            Name: getYAxisKeyName(el.Name),
          }))}
          bars={[
            {
              activeBar: {
                fill: "pink",
                stroke: "blue",
              },
              dataKey: "Commission",
              fill: "#8884d8",
            },

            {
              activeBar: {
                fill: "gold",
                stroke: "purple",
              },
              dataKey: "Margin",
              fill: "#82ca9d",
            },
          ]}
          xDataKey="Name"
        />
      </div>
    </div>
  );
};
export default PPCMarginTrendComponent;
