import { ChangeEventHandler, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import OMAAddCategory from "layout/oma/OMAAddCategory";
import createUpdateCategory from "redux/thunks/app/api-clients/categories/createUpdateCategory";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import getCategory from "redux/thunks/app/api-clients/categories/getCategory";
import getImageSize from "utils/img/getImageSize";

const AddCategory = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const [form, setForm] = useState<Record<string, any>>({
    is_active: true,
    name: "",
    description: "",
    description2: "",
    slug: "",
    type: "main",
    icon_image_url: "",
    thumb_image_url: "",
    hero_image_url: "",
    long_form_image_url: "",
  });
  const [files, setFiles] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, any>>({});

  const { loading, data } = useAppSelector((state) => state.getCategories);
  const getCategoryData = useAppSelector((state) => state.getCategory);

  const { error, loading: createUpdateCategoryLoading } = useAppSelector(
    (state) => state.createUpdateCategory
  );

  useEffect(() => {
    dispatch(getCategories({}));
  }, []);

  useEffect(() => {
    if (params.has("id")) {
      dispatch(getCategory({ id: params.get("id") }));
    }
  }, [params]);

  useEffect(() => {
    if (getCategoryData.data?.slug) {
      setForm((f) => ({ ...f, ...getCategoryData.data }));
    }
  }, [getCategoryData.data]);

  const onChange = ({ target }: any) => {
    const { name, value } = target;
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      createUpdateCategory({
        data: {
          ...form,
          old_slug: params.get("slug"),
          is_seasonal: form.type === "Seasonal",
          is_homepage: form.type === "Homepage",
          files,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (params.get("id")) {
          toast.success("Category updated");
        } else {
          toast.success("Category created");
        }
        dispatch(getCategories({ page: 1, created: form.name }));
      }
    });
  };

  const onFileSelected = async (selectedFile: Record<string, any>) => {
    const { width, height } = await getImageSize(
      URL.createObjectURL(selectedFile.files[0])
    );
    const imageType = selectedFile.name.split("_")[0];

    if (imageType === "hero") {
      if (width === 1044 || height === 696) {
        setErrors((errors) => ({
          ...errors,
          [selectedFile.name]: null,
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          [selectedFile.name]: "Dimensions need to be 1044 * 696",
        }));
        return;
      }
    }

    if (imageType === "icon") {
      if (width === 24 || height === 24) {
        setErrors((errors) => ({
          ...errors,
          [selectedFile.name]: null,
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          [selectedFile.name]: "Dimensions need to be 24 * 24",
        }));
        return;
      }
    }

    if (imageType === "thumb") {
      if (width === 235 || height === 114) {
        setErrors((errors) => ({
          ...errors,
          [selectedFile.name]: null,
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          [selectedFile.name]: "Dimensions need to be 235 * 114",
        }));
        return;
      }
    }

    if (imageType === "long") {
      if (width === 380 || height === 220) {
        setErrors((errors) => ({
          ...errors,
          [selectedFile.name]: null,
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          [selectedFile.name]: "Dimensions need to be 380 * 220",
        }));

        return;
      }
    }

    setFiles((files) => ({
      ...files,
      [selectedFile.name]: {
        file: selectedFile.files[0],
        temp_url: URL.createObjectURL(selectedFile.files[0]),
      },
    }));
  };

  return (
    <OMAAddCategory
      loading={loading}
      data={data}
      createUpdateCategoryLoading={createUpdateCategoryLoading}
      form={form}
      error={error}
      onChange={onChange}
      onSubmit={onSubmit}
      getCategoryData={getCategoryData}
      onFileSelected={onFileSelected}
      files={files}
      errors={errors}
    />
  );
};

export default AddCategory;
