import Input from "components/common/Input";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import Dropdown from "components/common/Dropdown";
import Checkbox from "components/common/Checkbox";
import { offerFeatures, offerSources } from "constants/offer";
import CouponMainForm from "./CouponMainForm";
import OfferTypeSelector from "./OfferTypeSelector";
import { ChangeEventHandler } from "react";
import { User } from "types/model/User";

type Props = {
  state: Record<string, any>;
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  handleSubmit: () => void;
  deactivateCoupon: () => void;
  handleUpdateClicked: () => void;
  updateSubmitted: boolean;
  error: Record<string, any> | null;
  submitting: boolean;
  user: User | null;
  hiddenFormFields?: Array<string>;
};

const TabCreateCoupon = ({
  error,
  form,
  onChange,
  submitting,
  state,
  handleUpdateClicked,
  updateSubmitted,
  handleSubmit,
  deactivateCoupon,
  user,
  hiddenFormFields,
}: Props) => {
  return (
    <div className="flex flex-col md:flex-row gap-2 justify-between">
      <div className="flex flex-col shadow-md w-full sm:w-full md:w-1/2 lg:w-1/3 gap-4 p-4 sm:px-2">
        <OfferTypeSelector error={error} form={form} onChange={onChange} />

        <div className="flex items-center  mr-4">
          <Checkbox
            checked={form.is_exclusive}
            onChange={(e) => {
              onChange({
                ...e,
                target: {
                  name: "is_exclusive",
                  value: e.target.checked,
                },
              } as any);
            }}
            name="is_exclusive"
            label="Is Exclusive"
          />
        </div>

        <MultipleSelectionInput
          name="offer_features"
          options={offerFeatures.map((el) => ({
            label: el,
            value: el,
            selected: form.offer_features?.includes(el),
          }))}
          label="Offer Features"
          renderAll
          className="bg-white border-gray-200"
          onChange={(e) => {
            const exists = form.offer_features.includes(e.target.value);
            const selected = exists
              ? form.offer_features.filter(
                  (it: string) => it !== e.target.value
                )
              : [...form.offer_features, e.target.value];

            onChange({
              ...e,
              target: {
                ...e.target,
                name: "offer_features",
                value: selected,
              },
            });
          }}
        />

        {!hiddenFormFields?.includes("offer_source") && (
          <Dropdown
            name="offer_source"
            label="Offer source"
            value={form.offer_source || ""}
            className="bg-white border-gray-200"
            onChange={(e) => {
              onChange({
                ...e,
                target: {
                  ...e.target,
                  name: "offer_source",
                  value: e.target.value,
                },
              } as any);
            }}
            options={offerSources.map((el) => ({
              label: el,
              value: el,
            }))}
          />
        )}

        <div>
          <Input
            name={"discount_code"}
            label="Promo Code"
            value={form.discount_code || ""}
            inputContainerClass="bg-white border-gray-200"
            onChange={onChange}
            error={error?.discount_code || error?.message}
          />
        </div>

        <div>
          <Input
            type="date"
            label="Start Date"
            value={form.start_date || ""}
            inputContainerClass="bg-white border-gray-200"
            name="start_date"
            onChange={onChange}
            error={error?.start_date}
          />
        </div>

        <div>
          <Input
            label="Expiration Date"
            type="date"
            name="expiration_date"
            value={form.expiration_date || ""}
            onChange={onChange}
            error={error?.expiration_date}
            placeholder=""
            inputContainerClass="bg-white border-gray-200"
          />
        </div>

        {!form.expiration_date && (
          <div className="flex items-center  mr-4">
            <Checkbox
              onChange={() => {
                //
              }}
              label="Unknown Expiration Date"
            />
          </div>
        )}

        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleUpdateClicked}
            className="inline-flex  rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Update Coupon
          </button>
        </div>
      </div>

      <CouponMainForm
        submitting={submitting}
        error={error}
        disabled={!updateSubmitted}
        handleSubmit={handleSubmit}
        onChange={onChange}
        state={state}
        form={form}
        user={user}
        deactivateCoupon={deactivateCoupon}
      />
    </div>
  );
};

export default TabCreateCoupon;
