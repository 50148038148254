import Button from "components/common/Button";
import Spinner from "components/common/layout/Spinner";
import AddLocationsForm from "components/sem/AddLocationsForm";
import { ChangeEventHandler } from "react";
import { SEMLocationSuggestion } from "types/data";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  onSubmit: () => void;
  updateResultLocationMode: (
    formKey: string,
    it: Record<string, any>,
    mode: "include" | "exclude",
  ) => void;

  removeSelectedLocation: (formKey: string, it: Record<string, any>) => void;

  onLocationSearchSubmit: (formKey: string) => void;
  onResultSearchResultsClick: (
    formKey: string,
    it: Record<string, any>,
  ) => void;
  loading: boolean;
  resetSearchResults: () => void;
  searchLoading: boolean;
  campaignLoading: boolean;
  searchResults: {
    [key: string]: Array<SEMLocationSuggestion>;
  };
};

const EditLocationTargetsComponent = ({
  onChange,
  onSubmit,
  form,
  updateResultLocationMode,
  removeSelectedLocation,
  onLocationSearchSubmit,
  searchLoading,
  onResultSearchResultsClick,
  resetSearchResults,
  searchResults,
  loading,
  campaignLoading,
}: Props) => {
  return (
    <div className="flex">
      {campaignLoading ? (
        <Spinner />
      ) : (
        <div className="w-1/2">
          <div className="mb-4">
            <h2>Edit Location Targeting</h2>
          </div>
          <AddLocationsForm
            searchResults={searchResults[form.search_engine]}
            form={form}
            resetResults={resetSearchResults}
            onResultClick={onResultSearchResultsClick}
            updateResultLocationMode={updateResultLocationMode}
            removeSelectedLocation={removeSelectedLocation}
            onSearchSubmit={onLocationSearchSubmit}
            onChange={onChange}
            searchLoading={searchLoading}
            // errors={error}
            formKey={form.search_engine}
            errors={{}}
          />

          <Button
            loading={loading}
            text=" Save changes"
            type="button"
            onClick={onSubmit}
            className=""
          />
        </div>
      )}
      <div className="extra"></div>
    </div>
  );
};

export default EditLocationTargetsComponent;
