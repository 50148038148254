import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import createUser from "redux/thunks/users/createUser";
import getClients from "redux/thunks/app/getClients";
import { Client } from "types/model/Client";
import countries from "constants/operationCountries";
import { Option } from "types/option";
import UserCreateUpdateComponent from "layout/users/UserCreateUpdateComponent";
import { toast } from "react-hot-toast";
import getApps from "redux/thunks/users/getApps";

type Props = {
  user?: Record<string, any>;
};

const UserCreateUpdate = ({ user }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const actionState = useAppSelector((state) => state.createUser);
  const userApps = useAppSelector((state) => state.getApps);

  const [form, setForm] = useState<Record<string, any>>({
    is_active: true,
    user_apps: userApps.data.items,
    sub_apps: [],
    clients: [],
    image_url: null,
    locations: countries.map((el) => ({
      label: el,
      value: el,
      selected: false,
    })),
  });

  const onChange = ({ target }: any) => {
    const { name, value } = target;
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      createUser({
        data: {
          ...form,
          clients: form.clients
            .filter((client: Option) => client.selected)
            .map((client: Option) => client.value),
          locations: form.locations
            .filter((location: Option) => location.selected)
            .map((location: Option) => location.value),
          user_apps: form.user_apps
            .filter((app: Option) => app.selected)
            .map((app: Record<string, any>) => app.id),

          user_sub_apps: form.sub_apps
            .filter((app: Record<string, any>) => app.selected)
            .map((app: Record<string, any>) => app.id),
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (form.id) {
          toast.success("Changes saved");
        } else {
          navigate("/settings/users");
        }
      }
    });
  };

  useEffect(() => {
    dispatch(getClients({ page: 1 })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setForm((form) => ({
          ...form,
          clients: res.payload.items.map((item: Client) => ({
            label: item.name,
            value: item.id,
            selected: user?.clients.map((el: Client) => el.id).includes(item.id)
              ? true
              : false,
          })),
        }));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getApps({}));
  }, []);

  useEffect(() => {
    if (user) {
      const initialLocations = form.locations.map((el: Option) => ({
        ...el,
        selected: user.locations.includes(el.value) ? true : false,
      }));

      const initialUserApps = form.user_apps.map((el: Record<string, any>) => ({
        ...el,
        selected: user.user_apps
          .map((el: Record<string, any>) => el.id)
          .includes(el.id)
          ? true
          : false,
      }));

      setForm((f) => ({
        ...f,
        ...user,
        user_apps: initialUserApps,
        locations: initialLocations,
        password: "_",
      }));
    }
  }, []);

  useEffect(() => {
    const selectedApps = form.user_apps.filter(
      (it: Record<string, any>) => it.selected
    );

    const subAppOptions: Record<string, any>[] = [];

    selectedApps.forEach((element: Record<string, any>) => {
      element.sub_apps.forEach((subApp: Record<string, any>) => {
        subAppOptions.push(subApp);
      });
    });

    setForm((f) => ({ ...f, sub_apps: subAppOptions }));
  }, [form.user_apps]);

  useEffect(() => {
    if (user) {
      const currentAppIds = user.user_apps.map(
        (el: Record<string, any>) => el.id
      );

      const initialUserApps = userApps.data.items.map(
        (el: Record<string, any>) => ({
          ...el,
          selected: currentAppIds.includes(el.id) ? true : false,
        })
      );

      setForm((f) => ({
        ...f,
        user_apps: initialUserApps,
      }));
    }
  }, []);

  useEffect(() => {
    if (user && form.sub_apps.length) {
      const currentSubAppIds = user.user_sub_apps.map(
        (el: Record<string, any>) => el.id
      );
      const initialUserSubApps = form.sub_apps.map(
        (el: Record<string, any>) => ({
          ...el,
          selected: currentSubAppIds.includes(el.id) ? true : false,
        })
      );
      setForm((f) => ({
        ...f,
        sub_apps: initialUserSubApps,
      }));
    }
  }, [form.sub_apps.length]);

  return (
    <UserCreateUpdateComponent
      actionState={actionState}
      onSubmit={onSubmit}
      onChange={onChange}
      setForm={setForm}
      form={form}
    />
  );
};

export default UserCreateUpdate;
