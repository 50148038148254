import { ChangeEventHandler, FormEventHandler } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSearchSubmit?: FormEventHandler<HTMLFormElement>;
  placeholder?: string;
  wrapperClassName?: string;
  searchText?: string;
  loading?: boolean;
};

const SearchForm = ({
  onSearchSubmit,
  onChange,
  placeholder,
  wrapperClassName,
  searchText,
  loading,
}: Props) => {
  return (
    <form
      className={twMerge("w-96", wrapperClassName)}
      onSubmit={onSearchSubmit}
    >
      <label
        htmlFor="default-search"
        className="text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <input
          type="search"
          id="search_text"
          name="search_text"
          onChange={onChange}
          value={searchText}
          className="block w-full p-2 py-2.5 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder={placeholder || "Program..."}
        />
      </div>
      {loading && <span className="text-xs text-gray-500">Please wait...</span>}
    </form>
  );
};

export default SearchForm;
