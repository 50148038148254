import Layout from "components/layout/Layout";
import { ActionState, PaginatedActionState } from "types/data";
import { Client } from "types/model/Client";
import { AffiliateNetwork } from "types/model/AffiliateNetwork";
import { ChangeEventHandler, useEffect, useState } from "react";
import Message from "components/common/Message";
import DataTable from "components/common/layout/DataTable";
import BIG_PICTURE_REPORT_COLUMNS, {
  AD_CENTER_REPORT_COLUMNS,
  ADVERTISER_TRANSACTION_DETAIL_COLUMNS,
  CLIENT_ACQUISITION_REPORT_COLUMNS,
  CLIENT_REVENUE_REPORT_COLUMNS,
  PPC_REPORT_COLUMNS,
  TRANSACTION_SUMMARY_REPORT_COLUMNS,
} from "constants/tables/headers/reporting/merchantReport";
import TransactionReportRow from "./BigPictureReportRow";
import { TransactionReportActionStateType } from "redux/slices/reporting/revenue/getTransactionReport";
import AdvertiserTransactionsReportRow from "./AdvertiserTransactionsReportRow";
import getRowsByReportId from "./getRowsByReportId";
import ReportTableHeader from "./ReportTableHeader";
import {
  REPORT_TYPE_AD_CENTER_REVENUE,
  REPORT_TYPE_BIG_PICTURE,
  REPORT_TYPE_CLIENT_ACQUISITION,
  REPORT_TYPE_CLIENT_ACQUISITION_DAILY,
  REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY,
  REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY,
  REPORT_TYPE_CLIENT_REVENUE,
  REPORT_TYPE_DAILY_REVENUE,
  REPORT_TYPE_MONTHLY_REVENUE,
  REPORT_TYPE_PPC,
  REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER,
  REPORT_TYPE_TX_SUMMARY,
  REPORT_TYPE_WEEKLY_REVENUE,
  ReportTypes,
} from "constants/revenueReportTypes";
import { Column } from "types/column";
import Modal from "components/common/Modal";
import { twMerge } from "tailwind-merge";
import { getPeriodChartData } from "./getPeriodChartData";
import StackedBarChart from "components/charts/Bar/StackedBarChart";
import TransactionSummaryReportRow from "./TransactionSummaryReportRow";
import ClientRevenueRow from "./ClientRevenueRow";
import AdCenterReportRow from "./AdcenterTransactionsReportRow";
import PPCReportRow from "./PPCReportRow";
import ClientAcquisitionReportRow from "./ClientAquisitionReportRow";
import AcquisitionReport from "./AcquisitionReport";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  hasPermissions: boolean;
  getClientsData: PaginatedActionState<Client>;
  affiliateNetworks: PaginatedActionState<AffiliateNetwork>;
  filterValues: Record<string, string>;
  getTransactionReport: TransactionReportActionStateType;
  getReport: () => void;
  onPageChange: (page: number) => void;
  pids: Record<string, string>[];
  showPerfView: (report_type: string) => void;
  getProposalPeriodReportData: ActionState;
};

function ReportingHomeComponent({
  hasPermissions,
  filterValues,
  getTransactionReport,
  onChange,
  getReport,
  onSearchChange,
  getClientsData,
  affiliateNetworks,
  onPageChange,
  pids,
  showPerfView,
  getProposalPeriodReportData,
}: Props) {
  const REPORT_COLUMN_MAP = {
    [REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER]:
      ADVERTISER_TRANSACTION_DETAIL_COLUMNS,
    [REPORT_TYPE_BIG_PICTURE]: BIG_PICTURE_REPORT_COLUMNS,
    [REPORT_TYPE_CLIENT_ACQUISITION]: CLIENT_ACQUISITION_REPORT_COLUMNS,
    [REPORT_TYPE_TX_SUMMARY]: TRANSACTION_SUMMARY_REPORT_COLUMNS,
    [REPORT_TYPE_CLIENT_REVENUE]: CLIENT_REVENUE_REPORT_COLUMNS,
    [REPORT_TYPE_DAILY_REVENUE]: [],
    [REPORT_TYPE_WEEKLY_REVENUE]: [],
    [REPORT_TYPE_MONTHLY_REVENUE]: [],
    [REPORT_TYPE_PPC]: PPC_REPORT_COLUMNS,
    [REPORT_TYPE_AD_CENTER_REVENUE]: AD_CENTER_REPORT_COLUMNS,
  } as Record<keyof ReportTypes, Column[]>;

  const [openAddColumns, setOpenAddColumns] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<Column[]>([]);
  const [additionalColumns, setAdditionalColumns] = useState<Column[]>([]);
  const rows = getRowsByReportId(filterValues, getTransactionReport);

  const invisibleColumns =
    REPORT_COLUMN_MAP[filterValues.report_type]?.filter(
      (it) => !it.defaultVisible
    ) || [];

  useEffect(() => {
    if (filterValues.report_type) {
      setVisibleColumns(
        REPORT_COLUMN_MAP[filterValues.report_type]?.filter(
          (it) => it.defaultVisible
        )
      );
    }
  }, [filterValues.report_type]);

  const dailyRevenueData = getTransactionReport.data
    .report as unknown as Record<string, any>[];

  const dailyRevenue = getPeriodChartData(
    dailyRevenueData,
    getProposalPeriodReportData.data,
    filterValues.report_type
  );

  const TableHeader = (
    <ReportTableHeader
      onChange={onChange}
      showPerfView={() => {
        if (filterValues.report_type === REPORT_TYPE_CLIENT_ACQUISITION) {
          showPerfView(REPORT_TYPE_CLIENT_ACQUISITION_DAILY);
        } else {
          showPerfView(REPORT_TYPE_DAILY_REVENUE);
        }
      }}
      getReport={getReport}
      onSearchChange={onSearchChange}
      getClientsData={getClientsData}
      affiliateNetworks={affiliateNetworks}
      filterValues={filterValues}
      pids={pids}
      showAddColumns={!!invisibleColumns.length}
      getTransactionReport={getTransactionReport}
      openAddColumns={() => {
        setOpenAddColumns(true);
      }}
    />
  );

  if (
    filterValues.report_type === REPORT_TYPE_CLIENT_ACQUISITION_DAILY ||
    filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION_WEEKLY ||
    filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION_MONTHLY
  ) {
    return (
      <AcquisitionReport
        TableHeader={TableHeader}
        hasPermissions={hasPermissions}
        filterValues={filterValues}
        getTransactionReport={getTransactionReport}
        showPerfView={showPerfView}
      />
    );
  }

  if (
    filterValues.report_type === REPORT_TYPE_DAILY_REVENUE ||
    filterValues.report_type == REPORT_TYPE_WEEKLY_REVENUE ||
    filterValues.report_type == REPORT_TYPE_MONTHLY_REVENUE
  ) {
    return (
      <Layout hasPermissions={hasPermissions}>
        {TableHeader}

        <div style={{ width: "100%", height: 500 }}>
          <StackedBarChart
            data={dailyRevenue || []}
            rangeOptions={[
              {
                label: "Day",
                onClick: () => {
                  showPerfView(REPORT_TYPE_DAILY_REVENUE);
                },
                active: filterValues.report_type == REPORT_TYPE_DAILY_REVENUE,
              },
              {
                label: "Week",
                active: filterValues.report_type == REPORT_TYPE_WEEKLY_REVENUE,
                onClick: () => {
                  showPerfView(REPORT_TYPE_WEEKLY_REVENUE);
                },
              },

              {
                label: "Month",
                active: filterValues.report_type == REPORT_TYPE_MONTHLY_REVENUE,
                onClick: () => {
                  showPerfView(REPORT_TYPE_MONTHLY_REVENUE);
                },
              },
            ]}
            title="Total Revenue"
            xDataKey="Name"
            bar2DataKey="IO Revenue"
            barDataKey="Commission"
          />
        </div>
      </Layout>
    );
  }

  return (
    <Layout hasPermissions={hasPermissions}>
      <Modal
        doneText="Done"
        variant="sm"
        doneBtnClass="bg-blue-500 hover:bg-blue-600 mr-2"
        onConfirm={() => {
          setOpenAddColumns(false);
        }}
        open={openAddColumns}
        setOpen={setOpenAddColumns}
        wrapperClassName="w-[500px]"
      >
        <p className="mb-2 text-base">Choose visible columns</p>

        {invisibleColumns.map((el) => (
          <button
            className={twMerge(
              "border border-gray-200 my-2 inline-flex w-full rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm  mr-2 sm:w-auto",
              additionalColumns.map((it) => it.key).includes(el.key) &&
                "bg-primary-700 text-white"
            )}
            key={el.key}
            onClick={() => {
              setVisibleColumns((columns) => [
                ...columns,
                { ...el, defaultVisible: true },
              ]);
              setAdditionalColumns((columns) => [
                ...columns,
                { ...el, defaultVisible: true },
              ]);

              if (additionalColumns.map((it) => it.key).includes(el.key)) {
                setAdditionalColumns((columns) =>
                  columns.filter((it) => it.key !== el.key)
                );
              }
              if (visibleColumns.map((it) => it.key).includes(el.key)) {
                setVisibleColumns((columns) =>
                  columns.filter((it) => it.key !== el.key)
                );
              }
            }}
          >
            {el.label}
          </button>
        ))}
      </Modal>
      <DataTable
        header={TableHeader}
        loading={getTransactionReport.loading}
        columns={visibleColumns}
        rowRenderer={(row, index) => {
          if (
            filterValues.report_type ===
            REPORT_TYPE_TRANSACTION_DETAIL_BY_ADVERTISER
          ) {
            return (
              <AdvertiserTransactionsReportRow
                item={row}
                index={index || 0}
                key={row.id || index}
                totals={getTransactionReport.data.totals}
                additionalColumns={additionalColumns}
              />
            );
          }
          if (filterValues.report_type === REPORT_TYPE_TX_SUMMARY) {
            return (
              <TransactionSummaryReportRow
                item={row}
                index={index || 0}
                key={row.id || index}
                totals={getTransactionReport.data.totals}
                visibleColumns={visibleColumns}
                additionalColumns={additionalColumns}
              />
            );
          }

          if (filterValues.report_type == REPORT_TYPE_CLIENT_ACQUISITION) {
            return (
              <ClientAcquisitionReportRow
                item={row}
                index={index || 0}
                key={row.id || index}
                totals={getTransactionReport.data.totals}
              />
            );
          }

          if (filterValues.report_type === REPORT_TYPE_PPC) {
            return (
              <PPCReportRow
                item={row}
                index={index || 0}
                key={row.id || index}
                totals={getTransactionReport.data.totals}
              />
            );
          }
          if (filterValues.report_type === REPORT_TYPE_CLIENT_REVENUE) {
            return (
              <ClientRevenueRow
                totals={getTransactionReport.data.totals}
                item={row}
                key={row.id || index}
                index={index || 0}
                getProposalPeriodReportData={getProposalPeriodReportData}
              />
            );
          }

          if (filterValues.report_type === REPORT_TYPE_AD_CENTER_REVENUE) {
            return (
              <AdCenterReportRow
                totals={getTransactionReport.data.totals}
                item={row}
                key={row.id || index}
                index={index || 0}
              />
            );
          }
          return (
            <TransactionReportRow
              totals={getTransactionReport.data.totals}
              item={row}
              key={row.id || index}
              index={index || 0}
            />
          );
        }}
        currentPage={filterValues.page ? Number(filterValues.page) : 1}
        rows={rows}
        currentPageCount={rows.length - 1}
        totalCount={getTransactionReport.data?.report?.meta?.count || 0}
        onPageChange={onPageChange}
      />
      {getTransactionReport.error && (
        <Message
          error
          description={
            getTransactionReport.error?.detail ||
            getTransactionReport.error?.message ||
            "something went wrong"
          }
        />
      )}
    </Layout>
  );
}

export default ReportingHomeComponent;
