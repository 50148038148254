import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import EmailCampaignSummaryRow from "components/marketing/EmailCampaignSummaryRow";
import EMAIL_CAMPAIGN_COLUMNS from "constants/tables/headers/marketing/emailCampaignSummary";

import { PaginatedActionState } from "types/data";
import { EmailCampaign } from "types/model/NewsletterSubscriber";

type Props = {
  getEmailCampaignsData: PaginatedActionState<EmailCampaign>;
  onPageChange: (page: number) => void;
  currentPage: number;
  onReviewClicked: (item: EmailCampaign) => void;
};

const EmailCampaignsSummaryComponent = ({
  getEmailCampaignsData: { loading, data },
  onPageChange,
  currentPage,
  onReviewClicked,
}: Props) => {
  const renderRow: RowRenderer<EmailCampaign> = (row) => {
    return (
      <EmailCampaignSummaryRow row={row} onReviewClicked={onReviewClicked} />
    );
  };

  return (
    <DataTable
      header={
        <div className="flex justify-end my-4  gap-4 py-2">
          <div className="mx-16 ">
            <h5>
              <span className="text-gray-500 text-xs">Total Items:</span>
              <span className="text-xs"> {data.meta.count}</span>
            </h5>
          </div>
        </div>
      }
      columns={EMAIL_CAMPAIGN_COLUMNS}
      rows={data.items}
      currentPage={currentPage}
      loading={loading}
      totalCount={data.meta.count}
      onPageChange={onPageChange}
      rowRenderer={renderRow as RowRenderer<Record<string, any>>}
    />
  );
};

export default EmailCampaignsSummaryComponent;
