import { useNavigate } from "react-router-dom";
import TabLayout from "../../components/common/TabLayout";
import OMAHome from "containers/oma/OMAHome";
import ActiveCoupons from "containers/oma/ActiveCoupons";
import ActivePrograms from "containers/bma/ActivePrograms";
import OMADirectStoreNewsletters from "containers/oma/OMADirectStoreNewsletters";
import OMAAffiliateNewsletters from "containers/oma/OMAAffiliateNewsletters";
import { COUPONS_APP } from "constants/smaAppNames";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import useLoadAuth from "hooks/useLoadAuth";
import OMAUserAddedCoupons from "containers/oma/OMAUserAddedCoupons";
import TodoItems from "containers/todos/TodoItems";
import { useAppSelector } from "hooks/useRedux";
import { SEND_NEW_COUPONS } from "constants/todoTypes";

function OMATabs() {
  const navigate = useNavigate();

  const { user } = useLoadAuth();
  const hasProgramsAccess = user?.user_apps
    ?.map((it) => it.name.toLowerCase())
    ?.includes(COUPONS_APP.toLowerCase());
  const {
    data: { items: pendingTodos },
  } = useAppSelector((state) => state.getTodoStats);

  const hasPermissions =
    user?.user_role == USER_ROLE_ADMIN || hasProgramsAccess || false;

  const pendingCouponRequests = pendingTodos.filter(
    (el) => el.todo_type?.id === SEND_NEW_COUPONS
  );

  const options = [
    {
      text: "API Coupons",
      onClick: () => {
        navigate("/oma?tab=1");
      },

      content: <OMAHome />,
    },
    {
      text: "Direct Store Letters",
      onClick: () => {
        navigate("/oma?tab=2");
      },

      content: <OMADirectStoreNewsletters />,
    },

    {
      text: "Affiliate Letters",
      onClick: () => {
        navigate("/oma?tab=3");
      },

      content: <OMAAffiliateNewsletters />,
    },

    {
      text: "User Coupons",
      onClick: () => {
        navigate("/oma?tab=4");
      },

      content: <OMAUserAddedCoupons />,
    },

    {
      text: "Manually Published Coupons",
      onClick: () => {
        navigate("/oma?tab=5");
      },

      content: <ActiveCoupons />,
    },

    {
      text: "AI Published Coupons",
      onClick: () => {
        navigate("/oma?tab=6");
      },

      content: <ActiveCoupons autoCoupons />,
    },

    {
      text: "Page Optimization",
      onClick: () => {
        navigate("/oma?tab=7");
      },
      content: <ActivePrograms />,
    },

    {
      label: pendingCouponRequests.length,
      text: "Optimization Requests",
      onClick: () => {
        navigate("/oma?tab=8");
      },

      content: <TodoItems todoType={SEND_NEW_COUPONS} />,
    },
  ];

  return <TabLayout hasPermissions={hasPermissions} options={options} />;
}

export default OMATabs;
