import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import getEvents from "redux/thunks/sales_crm/getEvents";
import ActivityFeed from "./ActivityFeed";
import { useParams } from "react-router-dom";

type Props = {
  programOwner?: Record<string, any>;
};

const Feed = ({ programOwner }: Props) => {
  const { slug } = useParams();
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentMeta, setCurrentMeta] = useState<Record<string, any>>({});
  const eventsData = useAppSelector((state) => state.getEvents);

  const [currentItems, setCurrentItems] = useState<Array<any>>(
    eventsData.data.items
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getEvents({ store: slug || "", user_id: userId }))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setCurrentItems(res.payload.items);
          setCurrentMeta(res.payload.meta);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug, userId]);

  const loadMoreEvents = () => {
    dispatch(
      getEvents({
        offset: currentMeta.offset + 50,
        user_id: userId,
        store: slug,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setCurrentItems((curr) => [...curr, ...res.payload.items]);
        setCurrentMeta(res.payload.meta);
      }
    });
  };

  return (
    <ActivityFeed
      loading={loading}
      loadMoreEvents={loadMoreEvents}
      currentItems={currentItems}
      eventsData={eventsData}
      setUserId={setUserId}
      programOwner={programOwner}
    />
  );
};

export default Feed;
