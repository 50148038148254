import React, { ChangeEventHandler, useState } from "react";
import dateFormat from "dateformat";
import Modal from "components/common/Modal";
import Input from "components/common/Input";

type Props = {
  row: Record<string, any>;
  handleRemoveSpotlight: (it: Record<string, any>) => void;
  handleUpdateItem: (
    row: Record<string, any>,
    form: Record<string, any>
  ) => void;
};

const CouponSpotlightsRow = ({
  row,
  handleRemoveSpotlight,
  handleUpdateItem,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form, setForm] = useState<Record<string, any>>(row);
  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setForm((f) => ({ ...f, [name]: value }));
  };

  return (
    <React.Fragment>
      <Modal
        onConfirm={() => {
          handleUpdateItem(row, form);
          setModalOpen(false);
        }}
        open={modalOpen}
        setOpen={setModalOpen}
      >
        <div className="grid gap-4 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="file_input"
            >
              Upload avatar
            </label>
            <div className="items-center w-full sm:flex">
              {(form.tempImage || form.image_url) && (
                <img
                  className="mb-4 w-80 h-60 rounded-md sm:mr-4 sm:mb-0 object-cover"
                  src={form.tempImage || form.image_url}
                  alt="Helene avatar"
                />
              )}
              <div className="w-full">
                <input
                  className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="file_input_help"
                  id="file_input"
                  type="file"
                  name="file"
                  accept="image/png,image/jpg,image/jpeg"
                  onChange={(e) => {
                    setForm((pre) => ({
                      ...pre,
                      file: e.target.files?.[0] as any,
                      tempImage: URL.createObjectURL(
                        e.target.files?.[0] as any
                      ),
                    }));
                  }}
                />
                <p
                  className="mt-1 mb-3 text-xs font-normal text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  PNG, JPG (MAX. 800x400px).
                </p>
              </div>
            </div>
          </div>
          <Input
            name="slogan"
            onChange={onChange}
            id="slogan"
            label="slogan"
            value={form.slogan}
            required
          />

          <Input
            type="date"
            onChange={onChange}
            label="From date"
            value={form.from_date}
            name={"from_date"}
          />
          <Input
            type="date"
            name="to_date"
            label="To date"
            onChange={onChange}
            value={form.to_date}
          />
        </div>
      </Modal>
      <tr key={row.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {row.store.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {row.store.primary_network?.name || "_"}
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {row.coupon.id}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <img
            alt=""
            src={row.tempImage || row.image_url}
            width={200}
            height={100}
          />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {row.coupon.title}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {row.slogan}
        </td>
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {row.from_date ? dateFormat(row.from_date, "dd/mm/yyyy") : "Not set"}{" "}
          - {row.to_date ? dateFormat(row.to_date, "dd/mm/yyyy") : "Not set"}
        </th>

        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <button
            onClick={() => {
              setModalOpen(true);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Manage<span className="sr-only"></span>
          </button>

          <button
            className="mx-4"
            onClick={() => {
              handleRemoveSpotlight(row);
            }}
          >
            X
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default CouponSpotlightsRow;
