import dateFormat from "dateformat";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import PPCMarginTrendComponent from "layout/marketing/search/PPCMarginTrendComponent";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getPPCMarginRevenueTrend from "redux/thunks/marketing/sem/getPPCMarginRevenueTrend";
import getLastWeekDateRanges from "utils/dates/dateRanges";

const PPCMarginTrend = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const { lastWeekStart, lastWeekEnd } = getLastWeekDateRanges();
  const getPPCMarginRevenueTrendData = useAppSelector(
    (state) => state.getPPCMarginRevenueTrend
  );
  const DATE_FMT = "yyyy-mm-dd";

  const {
    page = 1,
    devices = "",
    search_engines = "google,bing",
    startDate = new Date(lastWeekStart),
    program_slug = "",
    location = "",
    endDate = new Date(lastWeekEnd),
    client_id = "",
    ordering = "",
    client_view = "1",
    trend_view = "0",
    trend_type = "weekly",
  } = Object.fromEntries(params);

  const start_date = startDate ? dateFormat(new Date(startDate), DATE_FMT) : "";
  const end_date = endDate ? dateFormat(new Date(endDate), DATE_FMT) : "";

  const filterValues = {
    search_engines: search_engines,
    devices: devices,
    start_date: start_date,
    end_date: end_date,
    page: page,
    program_slug: program_slug || "",
    startDate,
    client_id,
    endDate,
    ordering,
    location,
    client_view,
    trend_view,
    trend_type,
  };

  useEffect(() => {
    dispatch(getPPCMarginRevenueTrend(filterValues));
  }, [
    search_engines,
    devices,
    start_date,
    end_date,
    page,
    program_slug,
    client_id,
    location,
    trend_type,
  ]);

  const setTrendType = (key: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("trend_type", key);
    navigate(url.search);
  };

  return (
    <PPCMarginTrendComponent
      setTrendType={setTrendType}
      filterValues={filterValues}
      getPPCMarginRevenueTrendData={getPPCMarginRevenueTrendData}
    />
  );
};

export default PPCMarginTrend;
