import { ChangeEventHandler, useState } from "react";
import ContactEntryCard from "./components/ContactEntryCard";
import { PaginatedActionState } from "types/data";
import { Contact } from "types/model/sales_crm/Contact";
import formatDate from "utils/dates/formatDate";
import { useAppDispatch } from "hooks/useRedux";
import { setShowContactModal } from "redux/slices/sales_crm/modals";
import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import CONTACTS_COLUMNS from "constants/tables/headers/sales_crm/contacts";
import SearchForm from "components/common/SearchForm";
import { BiPencil } from "react-icons/bi";

type Props = {
  contactsData: PaginatedActionState<Contact>;
  onPageChange: (page: number) => void;
  page: number;
  debouncedOnChange: ChangeEventHandler<HTMLInputElement>;
  searchText: string;
};

const Contacts = ({
  contactsData,
  onPageChange,
  page,
  debouncedOnChange,
}: Props) => {
  const dispatch = useAppDispatch();
  const [currentContact, setCurrentContact] = useState<Contact>();

  const renderRow: RowRenderer<Contact> = (el) => {
    return (
      <tr key={el.id} className="border-t border-gray-200 cursor-pointer">
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {formatDate(el.updated_at)}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {el.first_name} {el.last_name}
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {el.entity.name}
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {el.entity_type?.name}
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {el.email_address}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {el.title}
        </td>

        <td>
          <button
            onClick={() => {
              setCurrentContact(el);
              dispatch(setShowContactModal(true));
            }}
          >
            <BiPencil />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <ContactEntryCard
        currentContact={currentContact}
        setCurrentContact={setCurrentContact}
      />

      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="border-b dark:border-gray-700 mt-4">
              <div className="flex  justify-end py-3">
                <SearchForm
                  wrapperClassName="w-56"
                  placeholder="Search Contacts..."
                  onChange={debouncedOnChange}
                />
              </div>
            </div>
            <DataTable
              columns={CONTACTS_COLUMNS}
              rows={contactsData.data.items}
              onPageChange={onPageChange}
              loading={contactsData.loading}
              totalCount={contactsData.data.meta.count}
              currentPage={page}
              rowRenderer={renderRow as RowRenderer<Record<string, any>>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
