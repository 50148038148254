import getCRMNetworkTypes from "redux/slices/sales_crm/getNetworkTypes";
import getCRMNetworks from "redux/slices/sales_crm/getNetworks";
import getAgencies from "redux/slices/sales_crm/getAgencies";
import getLeads from "redux/slices/sales_crm/getLeads";
import getLeadTopics from "redux/slices/sales_crm/getLeadTopics";
import createLead from "redux/slices/sales_crm/createLead";
import createEvent from "redux/slices/sales_crm/createEvent";
import getContacts from "redux/slices/sales_crm/getContacts";
import getEvents from "redux/slices/sales_crm/getEvents";
import getTodos from "redux/slices/todos/getTodos";
import getTodoStats from "redux/slices/todos/getTodoStats";
import setTodoComplete from "redux/slices/todos/setTodoComplete";
import createTodo from "redux/slices/todos/createTodo";
import getEvent from "redux/slices/sales_crm/getEvent";
import getEntityTypes from "redux/slices/sales_crm/getEntityTypes";
import getEventActionItems from "redux/slices/sales_crm/getEventActionItems";
import getEventTypes from "redux/slices/sales_crm/getEventTypes";
import getContactMediums from "redux/slices/sales_crm/getContactMediums";
import createCRMNetwork from "redux/slices/sales_crm/createCRMNetwork";
import createAgency from "redux/slices/sales_crm/createAgency";
import createContact from "redux/slices/sales_crm/createContact";
import editDeleteRMNetwork from "redux/slices/sales_crm/editDeleteCRMNetwork";
import editDeleteLead from "redux/slices/sales_crm/editDeleteLead";
import editDeleteEvent from "redux/slices/sales_crm/editDeleteEvent";
import editDeleteAgency from "redux/slices/sales_crm/editDeleteAgency";
import editDeleteContact from "redux/slices/sales_crm/editDeleteContact";
import crmModals from "redux/slices/sales_crm/modals";
import getActivityReport from "redux/slices/sales_crm/getActivityReport";
import getOwnerShipRequests from "redux/slices/sales_crm/getOwnerShipRequests";
import requestProgramOwnerShip from "redux/slices/sales_crm/requestProgramOwnerShip";

const salesCRMReducers = {
  setTodoComplete,
  crmModals,
  getCRMNetworkTypes,
  createCRMNetwork,
  getCRMNetworks,
  editDeleteRMNetwork,
  getAgencies,
  createAgency,
  editDeleteAgency,
  getContacts,
  getEntityTypes,
  createContact,
  editDeleteContact,
  createLead,
  getLeadTopics,
  getLeads,
  editDeleteLead,
  getEventActionItems,
  getContactMediums,
  getEventTypes,
  createEvent,
  getEvents,
  getEvent,
  editDeleteEvent,
  getActivityReport,
  getTodos,
  getTodoStats,
  createTodo,
  getOwnerShipRequests,
  requestProgramOwnerShip,
};
export default salesCRMReducers;
