import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import VisitsDetailReport from "layout/reporting/extension/VisitsDetailReport";
import getVisitsDetailReport from "redux/thunks/reporting/extension/getVisitsDetailReport";

function ExtendedVisitsReport() {
  const dispatch = useAppDispatch();
  const getExtReportData = useAppSelector(
    (state) => state.getDetailedVisitsDetailReport
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(
      getVisitsDetailReport({
        start_date: searchParams.get("start_date"),
        end_date: searchParams.get("end_date"),
        page: searchParams.get("page") || 1,
        user_id: searchParams.get("user_id"),
        domain: searchParams.get("domain"),
        is_api_store: searchParams.get("is_api_store"),
      })
    );
  }, []);

  return <VisitsDetailReport getExtReportData={getExtReportData} />;
}

export default ExtendedVisitsReport;
