import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pageNumItemsInitialState from "redux/initial-states/pageNumItemsInitialState";
import updateNetworkExtSettings from "redux/thunks/extension/updateNetworkExtSettings";
import createNetworkExtSetting from "redux/thunks/extension/createNetworkExtSetting";
import getNetworkExtSettings from "redux/thunks/extension/getNetworkExtSettings";
import { PageNumPaginatedActionState } from "types/data";
import { ExcludedNetwork } from "types/model/ExcludedNetwork";

const initialState: PageNumPaginatedActionState<ExcludedNetwork> =
  pageNumItemsInitialState;

const getNetworkExtSettingsSlice = createSlice({
  name: "getNetworkExtSettingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNetworkExtSettings.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
        data: initialState.data,
      }))

      .addCase(getNetworkExtSettings.fulfilled, (state, action: any) => {
        const { payload } = action;
        if ({ payload }) {
          return {
            ...state,
            data: payload,

            loading: false,
          };
        }
      })
      .addCase(updateNetworkExtSettings.fulfilled, (state, action: any) => {
        return {
          ...state,
          data: {
            ...state.data,
            results: action.meta.arg.changeAutoOpen
              ? state.data.results.map((el) => {
                  if (el.id === action.meta?.arg.id) {
                    return {
                      ...el,
                      auto_open: action.payload.auto_open,
                    };
                  }
                  return el;
                })
              : state.data.results.filter((el) => el.id != action.meta?.arg.id),
          },
        };
      })

      .addCase(createNetworkExtSetting.fulfilled, (state, action: any) => {
        const { payload } = action;
        if ({ payload }) {
          return {
            ...state,
            data: {
              ...state.data,
              results: [payload, ...state.data.results],
            },

            loading: false,
          };
        }
      })

      .addCase(
        getNetworkExtSettings.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getNetworkExtSettingsSlice.reducer;
