import Spinner from "components/common/layout/Spinner";
import NoteItem from "containers/marketing/search/NoteItem";
import { ActionState, PaginatedData } from "types/data";

type Props = {
  getAdCampaignNotesState: ActionState<PaginatedData<Record<string, any>>>;
};

const SEMProgramNotesComponent = ({ getAdCampaignNotesState }: Props) => {
  if (getAdCampaignNotesState.loading) {
    return <Spinner />;
  }

  return (
    <ul className="space-y-6">
      {getAdCampaignNotesState.data.items.map((activityItem) => (
        <NoteItem note={activityItem} />
      ))}
    </ul>
  );
};

export default SEMProgramNotesComponent;
