import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sales_crm/get-action-items",
  async (_, ThunkAPI) => {
    try {
      const response = await axios.get(`/admin/sales-crm/event-action-items/`);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
