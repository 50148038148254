import Input from "components/common/Input";
import Modal from "components/common/Modal";
import { useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useEffect, useState } from "react";
import { ActionState } from "types/data";
import { SeasonalLink } from "types/model/SeasonalLink";

type Props = {
  row: SeasonalLink;
  updateSeasonalLink: (row: SeasonalLink) => void;
  updateSeasonalLinksData: ActionState;
};

function SeasonalLinkRow({
  row,
  updateSeasonalLink,
  updateSeasonalLinksData,
}: Props) {
  const [manageOpen, setManageOpen] = useState(false);

  const updateData = useAppSelector((state) => state.saveTopCategories);

  const [form, setForm] = useState<Record<string, any>>(row);
  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setForm((f) => ({ ...f, [name]: value }));
  };

  useEffect(() => {
    if (updateSeasonalLinksData.data) {
      setManageOpen(false);
    }
  }, [updateSeasonalLinksData.data]);

  return (
    <>
      <Modal
        doneText="Save"
        open={manageOpen}
        setOpen={setManageOpen}
        variant="lg"
        loading={updateSeasonalLinksData.loading}
        onConfirm={() => {
          updateSeasonalLink({
            ...row,
            ...form,
          });
        }}
      >
        <div className="grid gap-4 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label
              className="block mb-4 text-lg font-medium text-gray-900 dark:text-white"
              htmlFor="file_input"
            >
              Manage {row.category?.name}
            </label>
            <div className="items-center w-full sm:flex">
              {(form.tempImage || form.image_url) && (
                <img
                  className="mb-4 w-80 h-60 rounded-md sm:mr-4 sm:mb-0 object-cover"
                  src={form.tempImage || form.image_url}
                  alt="Helene avatar"
                />
              )}
              <div className="w-full">
                <input
                  className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="file_input_help"
                  id="file_input"
                  type="file"
                  name="file"
                  accept="image/png,image/jpg,image/jpeg"
                  onChange={(e) => {
                    setForm((pre) => ({
                      ...pre,
                      file: e.target.files?.[0] as any,
                      tempImage: URL.createObjectURL(
                        e.target.files?.[0] as any
                      ),
                    }));
                  }}
                />
                <p
                  className="mt-1 mb-3 text-xs font-normal text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  PNG, JPG 24 x 24
                </p>
              </div>
            </div>
          </div>

          <Input
            type="date"
            onChange={onChange}
            label="From date"
            value={form.from_date}
            name={"from_date"}
          />
          <Input
            type="date"
            name="to_date"
            label="To date"
            onChange={onChange}
            value={form.to_date}
          />
        </div>
      </Modal>

      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.category.name}
      </td>

      <td></td>
      <td></td>

      <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm">
        <button className="mx-4 text-indigo-900" onClick={() => {}}>
          x
        </button>

        <button
          className="mx-4 text-indigo-900"
          onClick={() => setManageOpen(true)}
        >
          Manage
        </button>
      </td>
    </>
  );
}

export default SeasonalLinkRow;
