import Dropdown from "components/common/Dropdown";
import Label from "components/common/Label";
import newsLetterPageTypeOptions from "constants/pricing-plans/newsLetterPageTypeOptions";
import React, { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";
import { Option } from "types/option";
import formatNumber from "utils/strings/formatNumber";
import getFirstInt from "utils/strings/getFirstInt";

type Props = {
  priceItem: Record<string, any>;
  setPrices?: Dispatch<SetStateAction<Record<string, any>[]>>;
  editPriceItems?: (id: string, updatedObject: Record<string, any>) => void;
  editEnabled?: boolean;
};

const PriceItem = ({
  priceItem,
  setPrices,
  editPriceItems,
  editEnabled = true,
}: Props) => {
  const [params] = useSearchParams();

  return (
    <div className="text-sm my-4 flex" key={priceItem.page}>
      <div className="flex gap-8 ">
        <div className="w-44">
          <Label label="Page" />
          <small>{priceItem.page}</small>

          <div>
            {priceItem.page === "Newsletters" && (
              <>
                {params.has("default-index") ? (
                  <Dropdown
                    label="Page Type"
                    placeholder="Select Page Type"
                    name="page_type"
                    value={priceItem.page_type}
                    wrapperClassName="w-44"
                    className="text-xs"
                    onChange={(event) => {
                      editPriceItems?.(priceItem.id, {
                        ...priceItem,
                        page_type: event.target.value,
                      });
                    }}
                    options={newsLetterPageTypeOptions.map((el) => ({
                      label: el,
                      value: el,
                    }))}
                  />
                ) : (
                  <div className="my-2">
                    <Label label="Page Type" />
                    <small>{priceItem.page_type}</small>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {priceItem.page === "Custom" && (
          <div>
            <Label label="Categories" />
            <small>
              {priceItem.categories
                .map((it: Option) => it.label)
                .map((el: string) => (
                  <p className="px-1" key={el}>
                    {el}
                  </p>
                ))}
            </small>
          </div>
        )}
        <div>
          <Label label="Placement Type" />
          <small>{priceItem.placement_type}</small>
        </div>

        <div>
          <Label label="Positions" />
          {Object.entries(priceItem.positions).map(([k, v]) => (
            <div className="flex items-center gap-4" key={k}>
              <small>#{getFirstInt(k)}</small>

              <tbody>
                <tr>
                  <span className="p-2">{formatNumber(String(v), {})}</span>
                </tr>
              </tbody>
            </div>
          ))}
        </div>

        {editEnabled && (
          <button
            className="text-primary-500 text-xs"
            onClick={() => {
              setPrices?.((prices) =>
                prices.filter((it) => priceItem.id !== it.id)
              );
            }}
          >
            Remove
          </button>
        )}
      </div>
      <hr />
    </div>
  );
};

export default PriceItem;
