import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import ProposalEvaluationLayout from "layout/marketing/search/ProposalEvaluationLayout";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import createUpdateDeleteProposal from "redux/thunks/sales/createUpdateDeleteProposal";
import getIos from "redux/thunks/sales/getIos";
import { Proposal } from "types/model/sales/Proposal";

const ProposalEvaluation = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { page = 1, has_earned_revenue = "" } = Object.fromEntries(params);

  const { data, loading } = useAppSelector((state) => state.getIos);
  const proposalActionData = useAppSelector(
    (state) => state.createUpdateDeleteProposal
  );

  const filters = {
    search_only: "1",
    page: has_earned_revenue ? 1 : page,
    has_earned_revenue:
      has_earned_revenue === "0" ? "1" : has_earned_revenue === "1" ? "0" : "",
  };

  useEffect(() => {
    if (page) {
      dispatch(getIos(filters));
    }
  }, [page, has_earned_revenue]);

  useEffect(() => {
    if (typeof searchText === "string") {
      dispatch(
        getIos({
          ...filters,
          page: 1,
          search_text: searchText.replace("IO-", "").trim(),
        })
      );
    }
  }, [searchText]);

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const debouncedOnChange = debounce((e) => {
    setSearchText(String(e.target.value));
  }, 1000);

  const handleSetSearchRevenue = (proposal: Proposal, revenue: string) => {
    dispatch(
      createUpdateDeleteProposal({
        ...proposal,
        proposal_type: proposal.proposal_type.id,
        search_rights_type: proposal.search_rights_type?.id || null,
        search_revenue: revenue,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  const onFiltersChange = (has_earned_revenue: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("has_earned_revenue", has_earned_revenue);
    navigate(url.search);
  };

  const onReset = () => {
    navigate("/marketing/search/?tab=4");
    setSearchText("");
  };

  return (
    <ProposalEvaluationLayout
      proposalActionData={proposalActionData}
      handleSetSearchRevenue={handleSetSearchRevenue}
      debouncedOnChange={debouncedOnChange}
      data={data}
      onFiltersChange={onFiltersChange}
      onPageChange={onPageChange}
      filters={filters}
      has_earned_revenue={has_earned_revenue}
      onReset={onReset}
      loading={loading}
    />
  );
};

export default ProposalEvaluation;
