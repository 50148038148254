const OMA_ACTIVE_PROGRAM_COLUMNS = [
  {
    key: "",
    isSortable: false,
    label: "",
    component: "components/common/Checkbox",
  },

  {
    key: "",
    isSortable: false,
    label: "Program Name",
  },
  {
    key: "",
    isSortable: false,
    label: "Homepage Website URL",
  },
  {
    key: "",
    isSortable: false,
    label: "Network",
  },
  {
    key: "",
    isSortable: false,
    label: "Merchant ID (MID)",
  },

  {
    key: "",
    isSortable: false,
    isOMA: true,
    label: "No of Coupons",
  },
  {
    key: "NUMBER_OF_CODES",
    isOMA: true,
    isSortable: false,
    label: "No of Codes",
  },

  {
    key: "",
    isSortable: false,
    label: "Action",
  },
];


export default OMA_ACTIVE_PROGRAM_COLUMNS;
