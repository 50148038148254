import DateRangeSelector from "components/common/DateRange";
import Dropdown from "components/common/Dropdown";
import SearchInput from "components/common/SearchInput";
import countries from "constants/operationCountries";
import rakutenNetworks from "constants/rakutenNetworks";
import {
  REPORT_TYPE_AD_CENTER_REVENUE,
  REPORT_TYPE_CLIENT_REVENUE,
  reportFilterKeys,
  reportTypeOptions,
} from "constants/revenueReportTypes";
import webgainsNetworks from "constants/webgainsNetworks";
import { ChangeEvent, ChangeEventHandler } from "react";
import { Link } from "react-router-dom";
import { PaginatedActionState } from "types/data";
import { AffiliateNetwork } from "types/model/AffiliateNetwork";
import { Client } from "types/model/Client";
import startOfISOWeek from "date-fns/startOfISOWeek";
import endOfISOWeek from "date-fns/endOfISOWeek";
import subDays from "date-fns/subDays";

type Props = {
  getClientsData: PaginatedActionState<Client>;
  affiliateNetworks: PaginatedActionState<AffiliateNetwork>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  filterValues: Record<string, string>;
  pids: Record<string, string>[];
  isChartView?: boolean;
};

const ReportFilters = ({
  getClientsData,
  affiliateNetworks,
  onChange,
  filterValues,
  onSearchChange,
  pids,
  isChartView,
}: Props) => {
  const reportStatuses =
    filterValues.report_type === REPORT_TYPE_AD_CENTER_REVENUE
      ? {
          Success: "succeeded",
          Failed: "failed",
        }
      : {
          New: "Pending",
          Extended: "Extended",
          Locked: "Locked",
          Approved: "Approved",
          "Pending return": "Returned",
          Declined: "Declined",
        };
  return (
    <div className="px-4">
      {!isChartView && (
        <Dropdown
          wrapperClassName="w-64 mb-4"
          placeholder="Report type"
          name="report_type"
          value={filterValues.report_type}
          onChange={(e) => {
            if (e.target.value === REPORT_TYPE_CLIENT_REVENUE) {
              const startDate = startOfISOWeek(subDays(new Date(), 7));
              const endDate = endOfISOWeek(subDays(new Date(), 7));
              onChange({
                target: {
                  name: "dates",
                  value: JSON.stringify([startDate, endDate]),
                },
              } as ChangeEvent<HTMLInputElement>);
            } else {
              onChange({
                target: {
                  name: "dates",
                  value: JSON.stringify([null, null]),
                },
              } as ChangeEvent<HTMLInputElement>);
            }
            onChange(e as ChangeEvent<HTMLInputElement>);
          }}
          options={reportTypeOptions}
        />
      )}
      {filterValues.report_type && (
        <div title="Report types" className="flex gap-2 flex-wrap items-center">
          {isChartView && (
            <Dropdown
              wrapperClassName="w-36"
              placeholder="Report type"
              name="report_type"
              value={filterValues.report_type}
              onChange={(e) => {
                if (e.target.value === REPORT_TYPE_CLIENT_REVENUE) {
                  const startDate = startOfISOWeek(subDays(new Date(), 7));
                  const endDate = endOfISOWeek(subDays(new Date(), 7));
                  onChange({
                    target: {
                      name: "dates",
                      value: JSON.stringify([startDate, endDate]),
                    },
                  } as ChangeEvent<HTMLInputElement>);
                } else {
                  onChange(e as ChangeEvent<HTMLInputElement>);
                }
              }}
              options={reportTypeOptions}
            />
          )}
          {reportFilterKeys[filterValues.report_type]?.includes(
            "search_text"
          ) && (
            <div className="w-36">
              <SearchInput
                placeholder="Program"
                inputClassName=" py-3"
                name="search_text"
                onChange={onSearchChange}
                onSubmit={() => {
                  //
                }}
              />
            </div>
          )}
          {reportFilterKeys[filterValues.report_type]?.includes("client") && (
            <Dropdown
              placeholder="Client"
              options={getClientsData.data.items.map((el) => ({
                label: el.name,
                value: el.id,
              }))}
              name="client"
              onChange={onChange}
              value={filterValues.client}
              wrapperClassName="w-36"
            />
          )}
          {reportFilterKeys[filterValues.report_type]?.includes(
            "website_id"
          ) && (
            <Dropdown
              placeholder="Property"
              options={pids.map((el) => ({
                label: `${el.pid}` + ` (${el.alias_name || ""})`,
                value: el.pid,
              }))}
              name="website_id"
              onChange={onChange}
              value={filterValues.website_id}
              wrapperClassName="w-36"
            />
          )}
          {reportFilterKeys[filterValues.report_type]?.includes(
            "filter_date_type"
          ) && (
            <Dropdown
              placeholder="Date Type"
              wrapperClassName="w-36"
              options={[
                {
                  label: "Click Date",
                  value: "click_date",
                },
                {
                  label: "Posting Date",
                  value: "posting_date",
                },
              ].map((el) => ({
                label: el.label,
                value: el.value,
              }))}
              name="filter_date_type"
              onChange={onChange}
              value={filterValues.filter_date_type}
            />
          )}

          {reportFilterKeys[filterValues.report_type]?.includes("tz_str") && (
            <Dropdown
              placeholder="Timezone"
              wrapperClassName="w-36"
              options={[
                {
                  label: "UTC",
                  value: "utc",
                },
                {
                  label: "US  - Pacific Time Zone",
                  value: "pst",
                },
                {
                  label: "US - Eastern Time Zone",
                  value: "est",
                },
                {
                  label: "US - Central Time Zone",
                  value: "cst",
                },
                {
                  label: "Europe - European Time Zone",
                  value: "cet",
                },
                {
                  label: "Europe - UK Time Zone",
                  value: "gmt",
                },
                {
                  label: "Australia - Australian Time Zone",
                  value: "aest",
                },
                {
                  label: "Africa - East African Time Zone",
                  value: "eat",
                },
              ].map((el) => ({
                label: el.label,
                value: el.value,
              }))}
              name="tz_str"
              onChange={onChange}
              value={filterValues.tz_str}
            />
          )}

          {reportFilterKeys[filterValues.report_type]?.includes(
            "start_date"
          ) && (
            <DateRangeSelector
              inputWrapperClassName="w-36"
              form={{
                startDate: filterValues.start_date
                  ? filterValues.start_date
                  : null,
                endDate: filterValues.end_date ? filterValues.end_date : null,
              }}
              onChange={onChange}
              showClear
            />
          )}
          {reportFilterKeys[filterValues.report_type]?.includes("network") && (
            <Dropdown
              wrapperClassName="w-36"
              options={affiliateNetworks.data.items

                .map((it) => ({
                  label:
                    it.network_id == "webgains-global"
                      ? "webgains"
                      : it.network_id,
                  value:
                    it.network_id == "webgains-global"
                      ? "webgains"
                      : it.network_id,
                }))
                .sort((a, b) => {
                  if (b.label > a.label) {
                    return -1;
                  }
                  return 1;
                })
                .filter(
                  (it) =>
                    !(
                      it.label.startsWith("webgains") &&
                      !it.label.endsWith("webgains")
                    )
                )}
              loading={affiliateNetworks.loading}
              placeholder="Network"
              name="network"
              onChange={onChange}
              value={filterValues.network}
            />
          )}

          {reportFilterKeys[filterValues.report_type]?.includes("network_id") &&
            filterValues.network?.includes("rakuten") && (
              <Dropdown
                wrapperClassName="w-36"
                options={rakutenNetworks.map((it) => ({
                  label: it.name,
                  value: it.id,
                }))}
                loading={affiliateNetworks.loading}
                placeholder="All Rakuten Networks"
                name="network_id"
                onChange={onChange}
                value={filterValues.network_id}
              />
            )}
          {filterValues.network?.includes("webgains") && (
            <Dropdown
              wrapperClassName="w-36"
              options={webgainsNetworks.map((it) => ({
                label: it.name,
                value: it.id,
              }))}
              loading={affiliateNetworks.loading}
              placeholder="All webgains Networks"
              name="network_id"
              onChange={onChange}
              value={filterValues.network_id}
            />
          )}

          {reportFilterKeys[filterValues.report_type]?.includes(
            "client_location"
          ) && (
            <Dropdown
              wrapperClassName="w-36"
              options={countries.map((el) => ({
                label: el,
                value: el,
              }))}
              placeholder="Location"
              name="client_location"
              onChange={onChange}
              value={filterValues.client_location}
            />
          )}
          {reportFilterKeys[filterValues.report_type]?.includes(
            "commission_type"
          ) && (
            <Dropdown
              placeholder="Type"
              wrapperClassName="w-36"
              options={[
                "Bonus",
                "Sale",
                "Click",
                "Lead",
                "Flat",
                "Performance Incentives",
              ].map((el) => ({
                label: el,
                value: el,
              }))}
              name="commission_type"
              onChange={onChange}
              value={filterValues.commission_type}
            />
          )}
          {reportFilterKeys[filterValues.report_type]?.includes(
            "txn_status"
          ) && (
            <Dropdown
              placeholder="Status"
              options={Object.entries(reportStatuses).map(([key, value]) => ({
                label: key,
                value,
              }))}
              name="txn_status"
              onChange={onChange}
              value={filterValues.txn_status}
            />
          )}

          {reportFilterKeys[filterValues.report_type]?.includes(
            "acquisition_type"
          ) && (
            <Dropdown
              placeholder="Acquisition"
              value={filterValues.acquisition_type}
              onChange={onChange}
              name="acquisition_type"
              options={[
                {
                  key: "PPC",
                  value: "PPC",
                },
                { key: "Email", value: "Email" },
                { key: "Toolbar", value: "Toolbar" },
                { key: "Organic", value: "Organic" },
                { key: "Extension", value: "Ext" },
              ].map((el) => ({
                label: el.key,
                value: el.value,
              }))}
            />
          )}

          <Link
            to={`/reporting/revenue?report_type=${filterValues.report_type}`}
            className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
          >
            Reset
          </Link>
        </div>
      )}
    </div>
  );
};

export default ReportFilters;
