import { getInstallSource } from "layout/reporting/extension/InstallReportRow";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formatDate from "utils/dates/formatDate";

type Props = {
  user: Record<string, any>;
  onClose?: () => void;
};

export default function InstallsCard({ user, onClose }: Props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { source, store } = getInstallSource(user);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  useEffect(() => {
    setModalOpen(!!user?._id);
  }, [user]);

  useEffect(() => {
    if (!isModalOpen) {
      onClose?.();
    }
  }, [isModalOpen]);

  if (!user) return null;

  // Function placeholders for opening additional report modals
  const openUserAPIStoresVisitReport = () =>
    alert("Opening User API Stores Visit Report");
  const openDetailedVisitReport = () => {
    navigate(
      `/reporting/browser-extension/visits?user_id=${
        user.userID || ""
      }&is_api_store=1`
    );
  };
  const openNonAPIStoresVisitReport = () => {};
  const openDetailedNonAPIVisitReport = () => {
    navigate(
      `/reporting/browser-extension/visits?user_id=${
        user.userID || ""
      }&is_api_store=0`
    );
  };
  const openSavedCoupons = () => alert("Opening Saved Coupons");
  const openStoresFollowing = () => alert("Opening Stores Following");
  const openTransactions = () => alert("Opening Transactions");

  return (
    <div className="flex justify-center m-5">
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Extension Install Details
                    </h3>
                    <div className="mt-2 mb-4 border-b border-gray-200">
                      <button
                        onClick={toggleModal}
                        className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
                      >
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="flex justify-between space-x-8">
                      <div className="space-y-2">
                        <p>
                          <strong>Extension/Install ID:</strong> {user.userID}
                        </p>
                        {user.installationTime && (
                          <p>
                            <strong>Install Date:</strong>{" "}
                            {formatDate(
                              new Date(Number(user.installationTime)).toString()
                            )}
                          </p>
                        )}

                        <p>
                          <strong>Install Source URL:</strong> {source}
                        </p>
                        <p>
                          <strong>Install Source Store:</strong> {store}
                        </p>
                        <p>
                          <strong>Account ID:</strong> {user.id}
                        </p>
                        <p>
                          <strong>Email:</strong> {user.email}
                        </p>
                      </div>
                      <div className="space-y-2">
                        <p>
                          <strong>Name:</strong> {user.first_name}
                        </p>
                        <p>
                          <strong>Last Name:</strong> {user.last_name}
                        </p>

                        <p>
                          <strong>IP Address:</strong> {user.ipAddress}
                        </p>
                        <p>
                          <strong>City:</strong> {user.city}
                        </p>
                        <p>
                          <strong>State:</strong> {user.region}
                        </p>
                        <p>
                          <strong>Country:</strong> {user.country}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 justify-center px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <div className="mt-3 space-y-2 flex flex-col">
                  <button
                    onClick={openUserAPIStoresVisitReport}
                    className="mt-2  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-sm"
                  >
                    User API Stores Visit Report
                  </button>
                  <button
                    onClick={openDetailedVisitReport}
                    className="mt-2  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-sm"
                  >
                    Detailed Visit Report
                  </button>
                  <button
                    onClick={openNonAPIStoresVisitReport}
                    className="mt-2  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-sm"
                  >
                    Non-API Stores Visit Report
                  </button>
                  <button
                    onClick={openDetailedNonAPIVisitReport}
                    className="mt-2  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  >
                    Detailed Non-API Stores Visit Report
                  </button>
                  <button
                    onClick={openSavedCoupons}
                    className="mt-2  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-sm"
                  >
                    Saved Coupons
                  </button>
                  <button
                    onClick={openStoresFollowing}
                    className="mt-2 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-sm"
                  >
                    Stores Following
                  </button>
                  <button
                    onClick={openTransactions}
                    className="mt-2  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-sm"
                  >
                    Transactions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
