import { ChangeEvent, ChangeEventHandler, useState } from "react";
import {
  DateRangePicker,
  InputRange,
  createStaticRanges,
  defaultStaticRanges,
} from "react-date-range";
import OutsideClickHandler from "react-outside-click-handler";
import Button from "./Button";
import Input from "./Input";
import dateFormat from "dateformat";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";
import enGB from "date-fns/locale/en-GB";
import startOfISOWeek from "date-fns/startOfISOWeek";
import endOfISOWeek from "date-fns/endOfISOWeek";
import subDays from "date-fns/subDays";
import isSameDay from "date-fns/isSameDay";

type Props = {
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disableConfirm?: boolean;
  staticRanges?: StaticRange[] | undefined;
  inputRanges?: InputRange[];
  inputWrapperClassName?: string;
  wrapperClassName?: string;
  showClear?: boolean;
  onConfirm?: () => void;
};

const DateRangeSelector = ({
  form,
  onChange,
  disableConfirm,
  inputWrapperClassName,
  wrapperClassName,
  inputRanges,
  showClear,
  onConfirm,
}: Props) => {
  const [dateSelectorFocused, setDateSelectorFocused] = useState(false);

  const value =
    form.startDate && form.endDate
      ? `${dateFormat(
          dayjs(form.startDate).toDate(),
          "mm/dd/yyyy"
        )} - ${dateFormat(dayjs(form.endDate).toDate(), "mm/dd/yyyy")}`
      : "";

  const staticRanges = createStaticRanges([
    {
      label: "Last week",
      range: () => ({
        startDate: startOfISOWeek(subDays(new Date(), 7)),
        endDate: endOfISOWeek(subDays(new Date(), 7)),
        key: "selection",
      }),
      isSelected: (range) => {
        if (range.startDate && range.endDate) {
          return (
            isSameDay(
              range.startDate,
              startOfISOWeek(subDays(new Date(), 7))
            ) && isSameDay(range.endDate, endOfISOWeek(subDays(new Date(), 7)))
          );
        }
        return false;
      },
    },
    {
      label: "This week",
      range: () => ({
        startDate: startOfISOWeek(new Date()),
        endDate: endOfISOWeek(new Date()),
        key: "selection",
      }),
      isSelected: (range) => {
        if (range.startDate && range.endDate) {
          return (
            isSameDay(range?.startDate, startOfISOWeek(new Date())) &&
            isSameDay(range?.endDate, endOfISOWeek(new Date()))
          );
        }
        return false;
      },
    },
  ]);

  return (
    <div>
      {dateSelectorFocused && (
        <div>
          <div
            className={twMerge(
              "absolute bg-white shadow-lg top-24 right-24 z-50",
              wrapperClassName
            )}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setDateSelectorFocused(false);
              }}
            >
              <div className="flex flex-col">
                <DateRangePicker
                  // weekStartsOn={1}
                  staticRanges={[
                    ...defaultStaticRanges.filter(
                      (el) => !el.label?.toLowerCase()?.includes("week")
                    ),
                    ...staticRanges,
                  ]}
                  // locale={enGb}
                  weekStartsOn={1}
                  locale={enGB}
                  shownDate={dayjs().toDate()}
                  inputRanges={inputRanges}
                  ranges={[
                    {
                      startDate: !form.startDate
                        ? dayjs().toDate()
                        : dayjs(form.startDate).toDate(),
                      endDate: !form.endDate
                        ? dayjs().toDate()
                        : dayjs(form.endDate).toDate(),
                      key: "selection",
                    },
                  ]}
                  onChange={({ selection: { startDate, endDate } }) => {
                    if (startDate) {
                      onChange({
                        target: {
                          name: "dates",
                          value: JSON.stringify([startDate, endDate]),
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }
                  }}
                />
                <div className="flex justify-end mx-6 py-4">
                  {showClear && (
                    <button
                      onClick={() => {
                        onChange({
                          target: {
                            name: "dates",
                            value: JSON.stringify([null, null]),
                          },
                        } as ChangeEvent<HTMLInputElement>);
                        setDateSelectorFocused(false);
                        setTimeout(() => {
                          onConfirm?.();
                        }, 2000);
                      }}
                      className=" mx-2 text-sm"
                    >
                      Reset
                    </button>
                  )}
                  <button
                    onClick={() => {
                      setDateSelectorFocused(false);
                      onConfirm?.();
                    }}
                    className=" mx-2 text-sm"
                  >
                    Close
                  </button>
                  {!disableConfirm && (
                    <Button
                      className="p-2 "
                      text="Confirm"
                      onClick={() => {
                        setDateSelectorFocused(false);
                        onConfirm?.();
                      }}
                    />
                  )}
                </div>
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      )}
      <Input
        // className={"py-1"}
        onFocus={() => {
          setDateSelectorFocused(true);
        }}
        name="search"
        inputContainerClass="p-1"
        value={value}
        placeholder="Choose period"
        onChange={onChange}
        wrapperClassName={twMerge("w-56", inputWrapperClassName)}
      />
    </div>
  );
};
export default DateRangeSelector;
