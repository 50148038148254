import countries from "constants/operationCountries";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import CreateUpdateDeletePricingPlanLayout from "layout/sales/CreateUpdateDeletePricingPlan";
import { ChangeEventHandler, useState, useEffect } from "react";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import { ProgramCategory } from "types/model/Category";
import { Option } from "types/option";
import createPricingPlan from "redux/thunks/sales/createUpdateDeletePricingPlan";
import { useNavigate } from "react-router-dom";
import getPricingPlan from "redux/thunks/sales/getPricingPlan";
import { useSearchParams } from "react-router-dom";
import clientPagesMap from "constants/pricing-plans/pageOptions";
import clientPlacementTypeMap from "constants/pricing-plans/placementTypeOptions";
import clientPlacementPositionCountMap from "constants/pricing-plans/pagePositionCounts";
import { resetPricingPlan } from "redux/slices/sales/getPricingPlan";
import storeLocationNameMap from "utils/objects/storeLocationNameMap";

const CreateUpdateDeletePricingPlan = () => {
  const [params] = useSearchParams();
  const isDeleting = params.has("deleting");
  const dispatch = useAppDispatch();
  const {
    getCategories: getCategoriesData,
    createPricingPlan: createPricingPlanData,
    getPricingPlan: getPricingPlanData,
    getClients: getClientsData,
  } = useAppSelector(
    ({ getCategories, getPricingPlan, createPricingPlan, getClients }) => ({
      getCategories,
      getPricingPlan,
      createPricingPlan,
      getClients,
    })
  );

  const navigate = useNavigate();

  const [prices, setPrices] = useState<Record<string, any>[]>([]);

  const [form, setForm] = useState<Record<string, any>>({
    name: `Default ${new Date().getFullYear()}`,
    clients: [],
    locations: countries.map((el) => ({
      label: el,
      value: el,
      selected: false,
    })),

    placement_type: null,
    categories: [],
  });

  const pageOptions = clientPagesMap[String(form.client)] || [];

  const placementTypeOptions =
    clientPlacementTypeMap[String(form.client)] || [];

  const clientPlacementPositionCount =
    clientPlacementPositionCountMap[String(form.client)]?.[
      form.placement_type
    ] || 0;

  useEffect(() => {
    if (params.has("id")) {
      dispatch(getPricingPlan(params.get("id") || ""));
    } else {
      dispatch(resetPricingPlan());
    }
  }, []);

  useEffect(() => {
    if (getClientsData.data.items.length) {
      setForm((f) => ({
        ...f,
        clients: getClientsData.data.items.map((el) => ({
          label: el.name,
          value: el.id,
          selected: false,
        })),
      }));
    }
  }, [getClientsData.data.items.length]);

  useEffect(() => {
    if (form.client) {
      dispatch(getCategories({ client: form.client }));
    }
  }, [form.client]);

  useEffect(() => {
    if (getCategoriesData.data.length) {
      setForm((f) => ({
        ...f,
        categories: getCategoriesData.data.map((el: ProgramCategory) => ({
          label: el.name,
          value: el.slug,
          selected: false,
        })),
      }));
    }
  }, [getCategoriesData.data.length]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setForm((f) => ({ ...f, [name]: value }));
  };

  const handleFormSubmit = () => {
    const payload = {
      client: form.client,
      id: form.id,
      location: form.location?.toLowerCase(),
      name: form.name,
      prices: prices,
    };

    if (isDeleting) {
      dispatch(createPricingPlan({ ...payload, delete: true })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(`/sales`);
        }
      });
      return;
    }

    dispatch(createPricingPlan(payload)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate(`/sales/pricing-plans/${res.payload.id}`);
      }
    });
  };
  const handleSetPrices = () => {
    const selectedCategories = form.categories.filter(
      (it: Option) => it.selected
    );

    if (selectedCategories.length) {
      const categories = selectedCategories.map((el: Option) => {
        return {
          id: `${el.value}_${new Date().getTime()}`,
          page: el.label,
          placement_type: form.placement_type,
          positions: form.positions,
          categories: selectedCategories,
        };
      });

      setPrices((prices) => [...prices, ...categories]);
    } else {
      setPrices((prices) => [
        ...prices,
        {
          id: new Date().getTime(),
          page: form.page,
          placement_type: form.placement_type,
          page_type: form.page_type,
          positions: form.positions,
          categories: selectedCategories,
        },
      ]);
    }

    setForm((f) => ({
      ...f,
      page: null,
      placement_type: null,
      positions: [],
      categories: form.categories.map((it: Option) => ({
        ...it,
        selected: false,
      })),
    }));
  };

  useEffect(() => {
    if (getPricingPlanData.data.client?.id && params.has("id")) {
      setPrices(getPricingPlanData.data.prices);
      setForm((f) => ({
        ...f,
        ...getPricingPlanData.data,
        client: getPricingPlanData.data.client.id,
        location:
          storeLocationNameMap[getPricingPlanData.data.location.toLowerCase()],
      }));
    }
  }, [getPricingPlanData.data.client?.id]);

  const editPriceItems = (id: string, updatedObject: Record<string, any>) => {
    setPrices((prices) =>
      prices.map((it) => (it.id === id ? updatedObject : it))
    );
  };

  return (
    <CreateUpdateDeletePricingPlanLayout
      handleFormSubmit={handleFormSubmit}
      form={form}
      isDeleting={isDeleting}
      setForm={setForm}
      setPrices={setPrices}
      onChange={onChange}
      getCategoriesData={getCategoriesData}
      pageOptions={pageOptions}
      placementTypeOptions={placementTypeOptions}
      prices={prices}
      handleSetPrices={handleSetPrices}
      createPricingPlanData={createPricingPlanData}
      clientPlacementPositionCount={clientPlacementPositionCount}
      editPriceItems={editPriceItems}
    />
  );
};
export default CreateUpdateDeletePricingPlan;
