import SearchBar from "components/program/SearchBar";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import React, { FocusEventHandler, useEffect, useState } from "react";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import { ProgramCategory } from "types/model/Category";

type Props = {
  onResultClick: (item: Record<string, any>) => void;
  onFocus?: FocusEventHandler;
  value?: string;
  className?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
};

const CategorySelector = ({
  onResultClick,
  onFocus,
  value,
  label,
  className,
  required,
  placeholder,
  loading,
}: Props) => {
  const [innerValue, setInnerValue] = useState(value);
  const {
    getCategories: { data: categoryList },
  } = useAppSelector(({ getCategories }) => ({
    getCategories,
  }));

  const dispatch = useAppDispatch();

  const [categorySearchResults, setCategorySearchResults] = useState<
    ProgramCategory[]
  >([]);

  useEffect(() => {
    if (!categoryList.length) {
      dispatch(getCategories({}));
    }
  }, []);

  // useEffect(())=>{}

  return (
    <SearchBar
      wrapperClass={className}
      placeholder={placeholder || "Search..."}
      onChange={(e) => {
        const items = categoryList.filter((el: ProgramCategory) => {
          return el.name
            .toLowerCase()
            ?.startsWith(e.target.value?.toLowerCase());
        });
        setCategorySearchResults(items);
        setInnerValue(e.target.value);
      }}
      onResultClick={(result) => {
        setCategorySearchResults([]);

        onResultClick(result);
        setInnerValue(result.slug);
      }}
      onResultsOutsideClick={() => {
        // if (form.primary_category) {
        //   setCategorySearchResults([]);
        // }
      }}
      onFocus={(e) => {
        onFocus?.(e);
        setCategorySearchResults(categoryList);
      }}
      value={innerValue}
      onSearchSubmit={() => {
        //
      }}
      results={categorySearchResults}
      label={label || "Primary Category"}
      required={required}
      searchLoading={loading}
    />
  );
};

export default CategorySelector;
