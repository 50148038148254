import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

export default createAsyncThunk(
  "clients/campaigns/getCouponSpotlights",
  async (_, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }
      const clientWebsite = new ClientAPI();

      const response = await clientWebsite.getCouponSpotlights();

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
