import { ChangeEventHandler, useState } from "react";
import AgencyEntryCard from "./components/AgencyEntryCard";
import { PaginatedActionState } from "types/data";
import { Agency } from "types/model/sales_crm/Agency";
import formatDate from "utils/dates/formatDate";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
  setShowAgencyModal,
  toggleAgencyModal,
} from "redux/slices/sales_crm/modals";
import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import AGENCIES_COLUMNS from "constants/tables/headers/sales_crm/agencies";
import SearchForm from "components/common/SearchForm";
import { BiPencil } from "react-icons/bi";

type Props = {
  agenciesData: PaginatedActionState<Agency>;
  onPageChange: (page: number) => void;
  currentPage: number;
  debouncedOnChange: ChangeEventHandler<HTMLInputElement>;
};

const Agencies = ({
  agenciesData,
  onPageChange,
  currentPage,
  debouncedOnChange,
}: Props) => {
  const dispatch = useAppDispatch();
  const [selectedAgency, setSelectedAgencies] = useState<Agency>();

  const { agencyModalOpen } = useAppSelector((state) => state.crmModals);

  const setModalOpen = () => {
    dispatch(toggleAgencyModal());
  };

  const renderRow: RowRenderer<Agency> = (agency) => {
    return (
      <tr
        className="border-t border-gray-200 cursor-pointer py-4"
        key={agency.name}
      >
        <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500">
          {formatDate(agency.created_at)}
        </td>
        <td className="whitespace-nowrap  py-4 text-sm text-gray-900">
          {agency.name}
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500">
          <a
            href={agency.website_url}
            className="text-blue-600 hover:text-blue-800"
          >
            {agency.website_url}
          </a>
        </td>
        <td className="whitespace-nowrap  py-4 text-sm text-gray-900">
          {agency.programs_count}
        </td>

        <td>
          <button
            onClick={() => {
              dispatch(setShowAgencyModal(true));
              setSelectedAgencies(agency);
            }}
          >
            <BiPencil />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div>
      {agencyModalOpen && (
        <AgencyEntryCard
          selectedAgency={selectedAgency}
          showAgencyModal={agencyModalOpen}
          setShowAgencyModal={setModalOpen}
        />
      )}

      <div className="align-middle">
        <div className="border-b dark:border-gray-700 mt-4">
          <div className="flex  justify-end py-3">
            <SearchForm
              wrapperClassName="w-56"
              placeholder="Search Agencies..."
              onChange={debouncedOnChange}
            />
          </div>
        </div>
        <DataTable
          columns={AGENCIES_COLUMNS}
          rows={agenciesData.data.items}
          totalCount={agenciesData.data.meta.count}
          currentPage={currentPage}
          onPageChange={onPageChange}
          loading={agenciesData.loading}
          rowRenderer={renderRow as RowRenderer<Record<string, any>>}
        />
      </div>
    </div>
  );
};

export default Agencies;
