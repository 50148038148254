import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createCampaignNote from "redux/thunks/marketing/sem/createCampaignNote";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const createCampaignNoteSlice = createSlice({
  name: "createCampaignNote",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCampaignNote.pending, (state) => {
        return {
          ...state,
          error: null,
          data: [],
          loading: true,
        };
      })
      .addCase(
        createCampaignNote.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        createCampaignNote.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export default createCampaignNoteSlice.reducer;
