import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import getOffsetForPage from "utils/arrays/getOffset";

export default createAsyncThunk(
  "sales_crm/get-contacts",
  async (params: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/sales-crm/contacts/?offset=${getOffsetForPage(
          params.page
        )}&search=${params.search || ""}&program=${
          params.program || ""
        }&agency_id=${params.agency_id || ""}&network_id=${
          params.network_id || ""
        }&entity_type_id=${params.entity_type_id || ""}`
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
