import DataTable from "components/common/layout/DataTable";
import TableHeader from "components/links/TableHeader";
import { Option } from "types/option";
import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { ActionState, PaginatedActionState } from "types/data";
import { Link } from "types/model/Link";
import Message from "components/common/Message";
import NEWSLETTER_COLUMNS from "constants/tables/headers/oma/storeNewsletterFeed";
import NewsLetterFeedRow from "components/links/NewsLetterFeedRow";
import Modal from "components/common/Modal";
import toast from "react-hot-toast";
import Button from "components/common/Button";
import { resetGenerateCouponInfo } from "redux/slices/links/newsletter-feed/generateCouponInfo";
import { useAppDispatch } from "hooks/useRedux";

type Props = {
  getAllLinksState: PaginatedActionState<Link>;
  onActivatePressed: (arg: Record<string, any>) => void;
  completeNewsletter: (item: Record<string, any>) => void;
  completeNewsLetterData: ActionState;
  onPageChange: (page: number) => void;
  currentPage: number;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  onFilterChange: ChangeEventHandler<HTMLInputElement>;
  networkOptions: Option[];
  onSelectedNetworkChange: ChangeEventHandler<HTMLInputElement>;
  onSelectedRankChange: ChangeEventHandler<HTMLInputElement>;
  rank?: string;
  network: string;
  store_location?: string;
  createCouponData: ActionState;
  has_un_matched_emails: string;
  generateCouponInfoData: ActionState;
  generateCouponInfo: (item: Record<string, any>) => void;
};

const OMADirectStoreNewslettersComponent = ({
  onActivatePressed,
  getAllLinksState: { loading, data, error },
  onPageChange,
  currentPage,
  onSearchSubmit,
  onFilterChange,
  completeNewsLetterData,
  completeNewsletter,
  networkOptions,
  onSelectedNetworkChange,
  onSelectedRankChange,
  rank,
  network,
  createCouponData,
  has_un_matched_emails,
  generateCouponInfoData,
  generateCouponInfo,
}: Props) => {
  const dispatch = useAppDispatch();
  const [multiOfferMatchOpen, setMultiOfferMatchOpen] = useState(false);
  const [pendingOffers, setPendingOffers] = useState<Record<string, any>[]>([]);
  const [currentOffer, setCurrentOffer] = useState<Record<string, any>>({});

  useEffect(() => {
    if (generateCouponInfoData.data.length == 1) {
      setCurrentOffer(generateCouponInfoData.data[0]);
    } else if (
      generateCouponInfoData.data.length > 1 &&
      Array.isArray(generateCouponInfoData.data)
    ) {
      setPendingOffers(generateCouponInfoData.data);
      setMultiOfferMatchOpen(true);
    }
  }, [generateCouponInfoData.data]);

  useEffect(() => {
    if (generateCouponInfoData.data?.title) {
      setCurrentOffer(generateCouponInfoData.data);
    }
  }, [generateCouponInfoData.data?.title]);

  if (error?.detail) {
    return <Message title="Error!" info description={error?.detail} />;
  }

  if (error?.detail) {
    return <Message title="Error!" info description={error?.detail} />;
  }

  return (
    <div>
      <Modal
        showDone={false}
        onOpen={() => {}}
        onClose={() => {
          setMultiOfferMatchOpen(false);
          dispatch(resetGenerateCouponInfo());
        }}
        open={multiOfferMatchOpen}
        setOpen={setMultiOfferMatchOpen}
        // wrapperClassName="w-"
        // variant="sm"
      >
        <h1 className="text-2xl font-bold text-center mb-4">
          Multiple offers found
        </h1>
        <div className="w-[500px] h-[500px] overflow-scroll  rounded-lg p-4">
          {pendingOffers?.map((el: Record<string, any>) => {
            return (
              <div
                key={el.description}
                className="my-4 p-4 border border-gray-200 shadow-lg rounded-md"
              >
                <div>
                  <p className="text-xl font-semibold mb-1">{el.title}</p>
                  <p className="text-gray-600 mb-2">
                    {el.offer_type} / Ends: {el.expiration_date}
                  </p>

                  <p className="text-gray-800 font-mono mb-4">
                    Code: <span className="font-bold">{el.discount_code}</span>
                  </p>

                  <div className="flex gap-4 items-center">
                    <button
                      className="py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                      onClick={() => {
                        setPendingOffers((offers) =>
                          offers.filter(
                            (it) => it.description !== el.description
                          )
                        );
                        toast.success("Item removed");
                      }}
                    >
                      Dismiss
                    </button>
                    <Button
                      text="Activate"
                      onClick={() => {
                        setCurrentOffer(el);
                        setMultiOfferMatchOpen(false);
                        setPendingOffers((offers) =>
                          offers.filter(
                            (it) => it.description !== el.description
                          )
                        );
                      }}
                      className="py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <DataTable
        totalCount={data.meta.count}
        onPageChange={onPageChange}
        columns={NEWSLETTER_COLUMNS}
        rows={data.items}
        currentPage={currentPage}
        rowRenderer={(row: any) => (
          <NewsLetterFeedRow
            item={row}
            onReviewPressed={onActivatePressed}
            completeNewsletter={completeNewsletter}
            completeNewsLetterData={completeNewsLetterData}
            createCouponData={createCouponData}
            generateCouponInfo={generateCouponInfo}
            generateCouponInfoData={generateCouponInfoData}
            currentOffer={currentOffer}
            setCurrentOffer={setCurrentOffer}
            setMultiOfferMatchOpen={setMultiOfferMatchOpen}
            pendingOffers={pendingOffers}
          />
        )}
        header={
          <TableHeader
            updateDate={data.items?.[0]?.updated_at}
            total_items={data.meta.count}
            onSearchSubmit={onSearchSubmit}
            onFilterChange={onFilterChange}
            advertiser_count={data.meta.extra?.advertiser_count || 0}
            networkOptions={networkOptions}
            onSelectedNetworkChange={onSelectedNetworkChange}
            onSelectedRankChange={onSelectedRankChange}
            rank={rank}
            network={network}
            title="Direct Store Newsletters"
            isNewsletter
            has_un_matched_emails={has_un_matched_emails}
          />
        }
        loading={loading}
      />
    </div>
  );
};

export default OMADirectStoreNewslettersComponent;
