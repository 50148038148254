import ProgramCampaignsLabel from "components/program/ProgramCampaignsLabel";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { isInteger } from "lodash";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import { navigateToMerchantPage } from "utils/urls/addHttp";
import getLastUpdatedDate from "./getLastUpdatedDate";
import formatDate from "utils/dates/formatDate";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import useLoadAuth from "hooks/useLoadAuth";
import { USER_ROLE_ADMIN, USER_ROLE_STANDARD } from "constants/user_roles";
import SalesTeamFilter from "./SalesTeamFilter";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import TextArea from "components/common/TextArea";
import requestProgramOwnership from "redux/thunks/sales_crm/requestProgramOwnership";
import getEmailSubscribers from "redux/thunks/marketing/emails/getEmailSubscribers";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  saveProgramOwner: (userId: string) => void;
  programOwner: Record<string, any>;
  saveOwnerLoading: boolean;
  requests: Record<string, any>[];
};

const StoreSummary = ({
  programOwner,
  saveProgramOwner,
  saveOwnerLoading,
  requests,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("");
  const [innerRequested, setInnerRequested] = useState(false);
  const [requestModalOpen, setOpenRequestModalOpen] = useState(false);
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const { client_id } = Object.fromEntries([...searchParams]);
  const { user } = useLoadAuth();
  const isAdmin = user?.user_role === USER_ROLE_ADMIN;
  const isStandardUser = user?.user_role === USER_ROLE_STANDARD;
  const dispatch = useAppDispatch();
  const [program, setProgram] = useState<Record<string, any>>({});
  const {
    data: { items: clientsList },
  } = useAppSelector((state) => state.getClients);
  const { data: getEvents } = useAppSelector((state) => state.getEvents);
  const requestProgramOwnerShipData = useAppSelector(
    (state) => state.requestProgramOwnerShip
  );

  const emailSubData = useAppSelector((state) => state.getEmailSubscribers);

  useEffect(() => {
    dispatch(
      getProgram({
        query: slug,
        client_id: client_id,
        useDis: "1",
      })
    ).then((res) => {
      setInnerRequested(false);
      if (res.meta.requestStatus === "fulfilled") {
        setProgram(res.payload);
      }
    });
  }, [slug]);

  useEffect(() => {
    if (slug) {
      dispatch(
        getEmailSubscribers({
          client: client_id,
          program_slug: slug,
          page: 1,
        })
      );
    }
  }, [program]);

  if (!program?.id) return null;

  const programManager = programOwner?.user
    ? (programOwner?.user?.first_name || "") +
      " " +
      (programOwner?.user?.last_name || "")
    : null;

  const currentStore: Record<string, any> = {
    name: program.name,
    size: program.website_url,
    source: program.logo_url,
    information: {
      "Account Owner": programManager || "",
      Network: program.primary_network?.name,
      "Tier Group": program.rank,
      "Last Conversation": formatDate(getLastUpdatedDate(getEvents.items)),
      "Email Subscribers": emailSubData.loading
        ? ""
        : formatNumber(emailSubData.data.meta.count, {}),
    },
  };

  return (
    <div className="hidden w-96 overflow-y-auto border border-gray-200 bg-white  lg:block rounded-md p-4 shadow">
      <Modal
        variant="md"
        doneText="Submit"
        open={requestModalOpen}
        loading={requestProgramOwnerShipData.loading}
        setOpen={setOpenRequestModalOpen}
        doneOnClick={() => {
          if (text) {
            dispatch(
              requestProgramOwnership({
                program: {
                  url: program.website_url,
                  rank: program.rank,
                  network: program.primary_network.name || "",
                  slug: program.slug,
                  name: program.name,
                  client: program.client,
                },
                notes: text,
              })
            ).then((res) => {
              if (res.meta.requestStatus === "fulfilled") {
                setOpenRequestModalOpen(false);
                setInnerRequested(true);
              }
            });
          }
        }}
      >
        <h3 className="py-2 text-base ">Request to own this program</h3>
        <TextArea
          rows={6}
          wrapperClassName="my-2"
          onChange={(e) => {
            setText(e.target.value);
          }}
          placeholder="Enter Notes about why you want to take over this account."
        />
      </Modal>
      <div className="space-y-6 ">
        <div className="relative">
          <div className="h-24 w-24">
            <img
              src={currentStore.source}
              alt=""
              className="object-cover h-24 w-24 rounded-full"
            />
          </div>

          <div className="mt-4 flex items-start justify-between">
            <div>
              <h2 className="text-lg font-medium text-gray-900">
                <span className="sr-only">Details for </span>
                {currentStore.name}
              </h2>
            </div>
          </div>
        </div>
        <>
          <h3 className="font-medium text-gray-900">Summary</h3>

          <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
            {Object.keys(currentStore.information).map((key: any) => (
              <div
                key={key}
                className="flex justify-between py-3 text-sm font-medium items-center"
              >
                <dt className="text-gray-500">{key}</dt>
                <dd className="whitespace-nowrap text-gray-900">
                  {key !== "Account Owner" && currentStore.information[key]}{" "}
                  {key === "Account Owner" &&
                    isStandardUser &&
                    !currentStore.information[key] && (
                      <Button
                        disabled={innerRequested || requests.length > 0}
                        text={
                          innerRequested || requests.length > 0
                            ? "Requested"
                            : "Request ownership"
                        }
                        onClick={() => {
                          setOpenRequestModalOpen(true);
                        }}
                      />
                    )}
                  {key === "Account Owner" &&
                    isStandardUser &&
                    currentStore.information[key]}
                  {key === "Account Owner" && isAdmin && (
                    <div className="inline">
                      {isEditing ? (
                        <div>
                          <SalesTeamFilter
                            placeholder="Choose account owner"
                            loading={saveOwnerLoading}
                            onChange={(e) => {
                              saveProgramOwner(e.target.value);
                            }}
                          />
                          <button
                            className="text-xs text-primary-500"
                            onClick={() => {
                              setIsEditing(false);
                            }}
                          >
                            close
                          </button>
                        </div>
                      ) : (
                        currentStore.information[key]
                      )}
                      {!isEditing && (
                        <button
                          type="button"
                          onClick={() => {
                            setIsEditing(true);
                          }}
                          className="inline-flex items-center text-sm font-semibold leading-6 text-gray-900 mx-2"
                        >
                          <PencilSquareIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </button>
                      )}
                    </div>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </>
        <dl className="text-sm  divide-gray-200 font-medium">
          <div className="flex justify-between">
            <dt className="text-gray-500">Search Campaign</dt>
            <dd className="whitespace-nowrap text-gray-900">
              <ProgramCampaignsLabel />
            </dd>
          </div>
        </dl>
        <div className="flex justify-end">
          <button
            disabled={isInteger(slug)} // disabled for sites other than discountcodes.com
            type="button"
            onClick={() => {
              navigateToMerchantPage(
                clientsList,
                getEvents.items[0]?.program?.slug || slug,
                getEvents.items[0]?.program?.client || null
              );
            }}
            className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Check Store Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreSummary;
