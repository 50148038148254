import DataTable from "components/common/layout/DataTable";
import ActiveCouponsHeader from "components/coupons/ActiveCouponsHeader";
import UserAddedCouponRow from "components/coupons/UserAddedCouponRow";
import USER_ADDED_COUPON_COLS from "constants/tables/headers/oma/userAddedCoupons";
import { ChangeEventHandler, FormEventHandler } from "react";
import { ActionState, PageNumPaginatedData } from "types/data";
import { User } from "types/model/User";
import { Option } from "types/option";

type Props = {
  loading: boolean;
  data: PageNumPaginatedData<Record<string, any>>;
  onPageChange: (p: number) => void;
  page: number;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  onItemSelectChange: (item: Record<string, any>) => void;
  selectedItems: Array<Record<string, any>>;
  deleteCouponsData: ActionState;
  deleteAll: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  userOptions: User[];
  networkOptions: Option[];
  couponFilters: Record<string, any>;
  deactivateCoupon: (coupon: Record<string, any>) => void;
};

const OMAUserAddedComponent = ({
  data,
  loading,
  page,
  onPageChange,
  onSearchChange,
  onSearchSubmit,
  onItemSelectChange,
  selectedItems,
  deactivateCoupon,
  deleteCouponsData,
  deleteAll,
  networkOptions,
  onChange,
  userOptions,
  couponFilters,
}: Props) => {
  return (
    <div className="w-full mt-4">
      <DataTable
        loading={loading}
        columns={USER_ADDED_COUPON_COLS}
        rows={data.results}
        rowRenderer={(row) => (
          <UserAddedCouponRow
            selectedItems={selectedItems}
            onItemSelectChange={onItemSelectChange}
            deactivateCoupon={() => {
              deactivateCoupon(row);
            }}
            item={row}
          />
        )}
        currentPage={page}
        onPageChange={onPageChange}
        enableShowAll
        totalCount={data.count}
        header={
          <ActiveCouponsHeader
            title="User Added Coupons"
            total_items={data.count}
            userOptions={userOptions}
            onSearchSubmit={onSearchSubmit}
            onFilterChange={onSearchChange}
            selectedItems={selectedItems}
            deleteAll={deleteAll}
            onChange={onChange}
            networkOptions={networkOptions}
            couponFilters={couponFilters}
            userAdded
            deleteLoading={deleteCouponsData.loading}
          />
        }
      />
    </div>
  );
};

export default OMAUserAddedComponent;
