import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getUsers from "redux/thunks/users/getUsers";
import UsersComponent from "layout/users/Users";
import { User } from "types/model/User";

const Users = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data, loading } = useAppSelector((state) => state.getAllUsers);

  const getData = useCallback(
    (page = 1) => {
      dispatch(getUsers({ page }));
    },
    [dispatch],
  );

  const getNewPage = (page: number) => {
    navigate(`/settings/users/?page=${page}`);
  };

  useEffect(() => {
    const { page } = Object.fromEntries([...searchParams]);

    if (page) {
      getData(Number(page));
    } else {
      getData(1);
    }
  }, [getData, searchParams]);

  const navigateToAddUser = () => {
    navigate("/settings/users/create");
  };

  const navigateToUser = (user: User) => {
    navigate(`/settings/users/${user.id}`, { state: { user } });
  };

  return (
    <UsersComponent
      loading={loading}
      data={data}
      onPageChange={getNewPage}
      navigateToAddUser={navigateToAddUser}
      navigateToUser={navigateToUser}
    />
  );
};

export default Users;
