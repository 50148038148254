import {
  BriefcaseIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  LinkIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import { ActionStateType } from "redux/slices/clients/getProgram";
import dateFormat from "dateformat";
import PopUnder, { PopUnderOption } from "components/common/PopUnder";
import { UpdateStoreOptions } from "layout/Program";
import { ActionState, PaginatedData } from "types/data";
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { capitalize } from "lodash";
import { twMerge } from "tailwind-merge";
import SubBrandsDropDown from "components/SubBrandsDropDown";
import { STORE_TYPE_AD_CENTER } from "constants/storeTypeIds";
import Message from "components/common/Message";
import { CouponPolicy } from "types/model/oma/CouponPolicy";
import LabelBadge from "./LabelBadge";

type Props = {
  programDetails: ActionStateType;
  handleViewPage: () => void;
  handleUpdateStore?: (options: UpdateStoreOptions) => void;
  onBackClicked: () => void;
  searchAdCampaignsData: ActionState<PaginatedData<Record<string, any>>>;
  wrapperClassName?: string;
};

const ProgramHeader = ({
  programDetails: { data },
  handleUpdateStore,
  handleViewPage,
  onBackClicked,
  wrapperClassName,
  searchAdCampaignsData: { data: searchAdCampaignResult },
}: Props) => {
  const [options, set_] = useState<PopUnderOption[]>([]);

  useEffect(() => {
    set_([
      {
        title: data.is_active ? "De activate" : "Activate",
        current: false,
        visible: true,
        onClick: () => {
          handleUpdateStore?.({ toggleIsActive: true });
        },
      },
    ]);
  }, [data]);

  const googleAdCampaign = searchAdCampaignResult.items
    .filter((el) => el.campaign.status)
    .find((el) => el.search_engine === "google");

  const bingAdCampaign = searchAdCampaignResult.items
    .filter((el) => el.campaign.status)
    .find((el) => el.search_engine === "bing");

  if (!data.name) {
    return null;
  }
  return (
    <header className={twMerge("bg-[#e5flff] pb-4 shadow", wrapperClassName)}>
      <div className="flex items-center">
        <button
          className="bg-secondary text-secondary-foreground hover:bg-secondary-hover active:bg-secondary-active focus:bg-secondary-focus disabled:bg-secondary-disabled disabled:text-secondary-foreground rounded-full p-2"
          onClick={onBackClicked}
        >
          <span className="flex items-center justify-center h-9 w-9 rounded-full bg-gray-200">
            <BiArrowBack className="h-5 w-5 text-black" />
          </span>
        </button>
        <span className="font-semibold">Back</span>
      </div>

      <div className="flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap  sm:items-center justify-between max-w-7xl px-4 sm:px-6 md:px-12">
        <div className="flex flex-col gap-5 sm:4 sm:flex-row items-center">
          <img src={data?.logo_url || ""} alt="" width={120} height={120} />

          <div className="flex items-center gap-2 ">
            <div>
              <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight text-center">
                {data.name}{" "}
              </h1>
              {data.coupon_policies.length > 0 && (
                <div>
                  {data.coupon_policies.map((el: CouponPolicy) => (
                    <Message
                      textClassName="p-0 mt-0 ml-0"
                      wrapperClassName="p-2 ml-0 mt-2"
                      key={el.id}
                      description={el.name}
                      warn
                    />
                  ))}
                </div>
              )}
            </div>

            {data.related_mapping_count > 1 && (
              <SubBrandsDropDown data={data} />
            )}
            {/* 
            {data.is_ai_enabled && (
              <div className="flex gap-4">
                <LabelBadge text="AI Enabled" />
              </div>
            )} */}

            {searchAdCampaignResult.items.length > 0 && (
              <div className="flex gap-4">
                {googleAdCampaign && (
                  <LabelBadge
                    text={`${capitalize(
                      googleAdCampaign.search_engine
                    )} Search ${googleAdCampaign.campaign.status}`}
                    key={googleAdCampaign.id}
                    className={twMerge(
                      "",
                      googleAdCampaign.campaign.status !== "Active" &&
                        "bg-yellow-300"
                    )}
                  />
                )}

                {bingAdCampaign && (
                  <LabelBadge
                    text={`${capitalize(
                      bingAdCampaign.search_engine
                    )} Search  ${bingAdCampaign.campaign.status}`}
                    key={bingAdCampaign.id}
                    className={twMerge(
                      "",
                      bingAdCampaign.campaign.status !== "Active" &&
                        "bg-yellow-300"
                    )}
                  />
                )}
              </div>
            )}
            {data.ppc_policy === "Open" && (
              <LabelBadge text="PPC Open Policy" />
            )}
          </div>
        </div>

        <div className="flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {data.is_active ? "Active" : "Inactive"}
          </div>

          <div className="mt-2 flex uppercase items-center text-sm text-gray-500">
            <MapPinIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            Location: {data.location?.toUpperCase()}
          </div>

          <div className="mt-2 flex uppercase items-center text-sm text-gray-500">
            <MapPinIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <>
              {data.store_type === STORE_TYPE_AD_CENTER
                ? "AdCenter Store"
                : `Network: ${data.primary_network?.name}`}
            </>
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyDollarIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            Active {data.number_of_coupons} Coupons
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {dateFormat(data.created, "mmmm dd, yyyy")}
          </div>

          <span className="ml-3 sm:block">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              onClick={handleViewPage}
            >
              <LinkIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              View Page
            </button>
          </span>

          {typeof handleUpdateStore === "function" && (
            <PopUnder publishingOptions={options} />
          )}
        </div>
      </div>
    </header>
  );
};

export default ProgramHeader;
