const pageNumItemsInitialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    results: [],
  },
  loading: false,
  error: null,
};

export default pageNumItemsInitialState;
