import { timeAgo } from "index";
import React from "react";
import Status from "./Status";
import { useNavigate } from "react-router-dom";

type Props = {
  item: Record<string, any>;
};

function NetworkStatusRow({ item }: Props) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <tr className="border-b  hover:bg-gray-100">
        <th
          scope="row"
          className="px-2 py-2 text-xs font-medium text-gray-900 "
        >
          <button
            onClick={() => {
              navigate(
                `/oma/program?network=${item.network}&mid=${item.external_id}`
              );
            }}
          >
            {item.name}
          </button>
          <span></span>
        </th>

        <th
          scope="row"
          className="px-2 py-2 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.network}/ {item.external_id}
        </th>

        <td className="px-4 py-2 whitespace-nowrap text-xs">
          <Status status={item.network_status} />
        </td>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {/* {timeAgo.format(new Date(item.created_at))} */}
          {timeAgo.format(new Date(item.updated_at))}
        </td>
      </tr>
    </React.Fragment>
  );
}

export default NetworkStatusRow;
