import Spinner from "components/common/layout/Spinner";
import Faqs from "components/program/Faqs";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import React, {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";
import { BiDownArrow, BiRefresh, BiUpArrow } from "react-icons/bi";
import saveStoreFaqs from "redux/thunks/marketing/content/saveStoreFaqs";
import { instance } from "client-apis/discountcodes";
import { ActionState } from "types/data";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import formatDate from "utils/dates/formatDate";
import { LOCAL_KEY_CLIENT_WEBSITES } from "constants/storageKeys";

type Props = {
  item: Record<string, any>;
  refreshStoreFaq: (item: Record<string, any>) => void;
  saveStoreFaqsData: ActionState;
  refreshStoreFaqData: ActionState;
  setActiveId: Dispatch<SetStateAction<string | null>>;
  activeId: string | null;
};

function FaqReviewRow({
  item,
  refreshStoreFaq,
  saveStoreFaqsData,
  refreshStoreFaqData,
  activeId,
  setActiveId,
}: Props) {
  const dispatch = useAppDispatch();
  const [saveInitiator, setSaveInitiator] = useState("button");
  const { loading: saveBtnLoading } = useAppSelector(
    (state) => state.saveStoreFaqs
  );
  const [isToggled, setIsToggled] = useState(false);
  const [regenerating, setRegenerating] = useState(false);
  const [regenerationFor, setRegenerationFor] = useState("0");
  const [regeneratedAnswer, setRegeneratedAnswer] = useState<
    Record<string, any>
  >({});
  const [form, setForm] = useState<Record<string, any>>({
    faqs: item.faqs,
    faqsForm: {},
  });

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const name = e.target.name;

    setForm((f) => ({ ...f, [name]: e.target.value }));
  };

  useEffect(() => {
    if (refreshStoreFaqData.data.length > 0) {
      const newQSet: Record<string, any> = {};
      const recentQNumber = Object.entries(form.faqsForm)
        .map(([k, v]) => k)
        .filter((el) => el.includes("Question"))
        .map((it) => it.replace("Question ", ""))
        .sort((a, b) => Number(b) - Number(a))[0];

      refreshStoreFaqData.data.forEach(
        ({ question, answer }: Record<string, any>, idx: number) => {
          newQSet[`Question ${idx + 1 + Number(recentQNumber)}`] = question;
          newQSet[`Answer ${idx + 1 + Number(recentQNumber)}`] = answer;
        }
      );

      setForm((f) => ({ ...f, faqsForm: { ...f.faqsForm, ...newQSet } }));
    }
  }, [refreshStoreFaqData.data]);

  const onFormSubmit = () => {
    const faqs: Record<string, any>[] = [];

    Object.entries(form.faqsForm || {}).forEach((el, idx) => {
      const item = {
        question: form.faqsForm[`Question ${idx + 1}`],
        answer: form.faqsForm[`Answer ${idx + 1}`],
        id: form.faqsForm[`id ${idx + 1}`],
        is_ai_generated: form.faqsForm[`is_ai_generated ${idx + 1}`],
      };

      faqs.push(item);
    });

    dispatch(
      saveStoreFaqs({
        faqs: faqs.filter((el) => el.question && el.answer),
        store_id: item.store.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        const errors: Array<Array<string>> = Object.values(
          res.payload.error || res.payload
        );

        toast.error(
          "An error occurred \n" + errors?.[0]?.[0] ||
            res.payload.message ||
            res.payload.detail ||
            ""
        );
      } else {
        toast.success("Changes saved");
      }
    });
  };

  const handleGenerateAnswer = (form: Record<string, any>, input: any) => {
    setRegenerating(true);
    setRegenerationFor(input.id);
    const url = new URL(DISCOUNT_CODES_API + "/stores/generate-store-faq-ans/");
    const payload = {
      store_id: item.store.id,
      question: form.form[`Question ${input.index}`],
    };
    instance
      .post(url.href, payload)
      .then((res) => {
        setRegeneratedAnswer({ [input.index]: res.data.answer });
        const keyToReplace = `Answer ${input.index}`;
        setForm((f) => ({
          ...f,
          faqsForm: { ...f.faqsForm, [keyToReplace]: res.data.answer },
        }));
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setRegenerating(false);
      });
  };

  const createdAt = item.faqs.find(
    (el: Record<string, any>) => el.is_ai_generated
  ).created_at;
  const clients = JSON.parse(
    localStorage.getItem(LOCAL_KEY_CLIENT_WEBSITES) || "[]"
  );

  const client =
    clients.items.find(
      (cl: Record<string, any>) =>
        String(cl.id) === String(item.store?.client || "")
    ) || {};

  const visitPage = () => {
    if (item?.store.slug) {
      window.open(client.website_url + "/" + item.store?.slug);
    }
  };
  return (
    <React.Fragment>
      <tr className="border-b  hover:bg-gray-100 ">
        <th
          scope="row"
          className="px-4 py-2 text-xs font-medium text-gray-900  "
        >
          {formatDate(createdAt)}
        </th>
        <th
          scope="row"
          onClick={visitPage}
          className="px-4 py-2 text-xs font-medium text-gray-900 cursor-pointer"
        >
          {item.name}
        </th>

        <td className="px-4 py-2 font-medium text-xs text-gray-900 whitespace-nowrap ">
          <a
            href={item.website}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-primary-600 hover:underline inline-flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
            {item.website_base}
          </a>
        </td>

        <th
          scope="row"
          className="px-4 py-2 font-medium text-xs text-gray-900 whitespace-nowrap"
        >
          {item.network}
        </th>

        <td className="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs">
          {item.store.id}
        </td>

        <td className="p-2">
          <div className="flex gap-4 items-center">
            <button
              className="p-4"
              onClick={() => {
                setActiveId(item.store.id);
                setIsToggled((val) => !val);
              }}
            >
              {isToggled && <BiUpArrow size={16} />}
              {!isToggled && <BiDownArrow size={16} />}
            </button>
            <div className="flex gap-6">
              <button
                onClick={() => {
                  setActiveId(item.store.id);
                  refreshStoreFaq(item);
                }}
              >
                {refreshStoreFaqData.loading && activeId === item.store.id ? (
                  <div className="inline-block animate-spin">
                    <BiRefresh size={24} />
                  </div>
                ) : (
                  <BiRefresh size={24} />
                )}
              </button>
              {activeId === item.store.id && (
                <button
                  type="button"
                  onClick={() => {
                    onFormSubmit();
                  }}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>

                  {saveStoreFaqsData.loading && saveInitiator === "button" ? (
                    <Spinner className="w-4 h-4" />
                  ) : (
                    "Save Changes"
                  )}
                </button>
              )}
            </div>
          </div>
        </td>
      </tr>

      <tr
        hidden={!isToggled}
        className="flex-1 overflow-x-auto w-full"
        id="table-column-body-0"
        aria-labelledby="table-column-header-0"
      >
        <td className="p-4 border-b dark:border-gray-700" colSpan={9}>
          <Faqs
            faqs={form.faqs}
            faqsForm={form.faqsForm}
            onChange={onChange}
            onFormSubmit={onFormSubmit}
            saveBtnLoading={saveBtnLoading}
            saveInitiator={saveInitiator}
            setSaveInitiator={setSaveInitiator}
            regeneratedAnswer={regeneratedAnswer}
            renderInputOptions={(form, input) => (
              <div className="text-xs mx-4">
                <button
                  className="text-primary-700"
                  onClick={() => {
                    handleGenerateAnswer(form, input);
                  }}
                >
                  {regenerating && regenerationFor == input.id
                    ? "Please wait..."
                    : "Regenerate Answer"}
                </button>
              </div>
            )}
          />
        </td>
      </tr>
    </React.Fragment>
  );
}

export default FaqReviewRow;
