import { ChangeEventHandler, Dispatch, SetStateAction, useState } from "react";
import { Contact, EntityType } from "types/model/sales_crm/Contact";
import Layout from "components/layout/Layout";
import { ActionState } from "types/data";
import {
  ContactMedium,
  EventActionItem,
  EventType,
} from "types/model/sales_crm/Event";
import Input from "components/common/Input";
import { LeadTopic } from "types/model/sales_crm/Lead";
import Button from "components/common/Button";
import TextArea from "components/common/TextArea";
import ContactEntryCard from "./components/ContactEntryCard";
import { twMerge } from "tailwind-merge";
import { useNavigate } from "react-router-dom";
import SearchPrograms from "containers/oma/SearchPrograms";
import { resetEvent } from "redux/slices/sales_crm/getEvent";
import { useAppDispatch } from "hooks/useRedux";
import Dropdown from "components/common/Dropdown";
import Checkbox from "components/common/Checkbox";
import Label from "components/common/Label";
import SelectManagedPrograms from "./components/SelectManagedPrograms";
import SearchAgencies from "./components/SearchAgencies";
import { Network } from "types/model/Network";
import { CRMNetwork } from "types/model/sales_crm/Network";

interface Props {
  getContactMediumsData: ActionState<ContactMedium[]>;
  getEventTypesData: ActionState<EventType[]>;
  getEventActionItemsData: ActionState<EventActionItem[]>;
  getLeadTopicsData: ActionState<LeadTopic[]>;
  programContacts: Contact[];
  handleShowContactsModal: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  handleFormSubmit: () => void;
  handleItemChange: (name: string, eventId: number) => void;
  createEventData: ActionState;
  handleDeleteEvent: () => void;
  editDeleteEventData: ActionState;
  handleProgramSelected: (result: Record<string, any>) => void;
  isSubUpdate: boolean;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  todoForm: Record<string, any>;
  setTodoForm: Dispatch<SetStateAction<Record<string, any>>>;
  getEntityTypesData: ActionState<EntityType[]>;
  getCRMNetworksData: ActionState<CRMNetwork[]>;
  programContactsLoading: boolean;
  setMultiplePrograms: Dispatch<SetStateAction<boolean>>;
  multiplePrograms: boolean;
  isSub: boolean
}

const NewEventLayout = ({
  getContactMediumsData,
  getEventTypesData,
  getEventActionItemsData,
  getLeadTopicsData,
  programContacts,
  handleShowContactsModal,
  form,
  onChange,
  handleFormSubmit,
  handleItemChange,
  createEventData,
  editDeleteEventData,
  handleProgramSelected,
  isSubUpdate,
  setForm,
  setTodoForm,
  getEntityTypesData,
  getCRMNetworksData,
  programContactsLoading,
  multiplePrograms,
  setMultiplePrograms,
  isSub,
}: Props) => {
  const dispatch = useAppDispatch();
  const [program, setProgram] = useState({});
  const navigate = useNavigate();

  return (
    <Layout>
      <ContactEntryCard
        currentContact={
          {
            entity: program,
            entity_type: {
              description: "an In-House Program",
              id: 206,
              name: "In House Program",
            },
          } as Contact
        }
      />

      <div className="relative p-4 w-full max-w-5xl h-full md:h-auto ">
        {/* Modal content */}
        <div className="relative p-4 bg-white rounded-lg shadow-2xl sm:p-5">
          <div className="flex justify-between   items-start  rounded-t border-b">
            <h3 className="text-lg mb-4 font-semibold text-gray-900 dark:text-white">
              {isSubUpdate
                ? "Add Sub-Updates to this Event"
                : form.id
                ? "Event Details"
                : "Add New Event"}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => {
                navigate(-1);
                dispatch(resetEvent());
              }}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>

          <div className="grid grid-cols-2 mt-5 gap-4 divide-x">
            <div className="flex flex-col">
              {!multiplePrograms ? (
                <SearchPrograms
                  label="Search Program"
                  inputClassName="p-0"
                  inputContainerClass=" h-11"
                  wrapperClassName="mx-0 mb-4"
                  value={form.program?.name}
                  closeOnSelect
                  isCRM
                  onResultClicked={(result) => {
                    handleProgramSelected(result);
                  }}
                  rightLabel={
                    <Label
                      className=" text-sm px-2 cursor-pointer"
                      label={`Adding for ${
                        !multiplePrograms
                          ? "Multiple Programs?"
                          : "a single Program?"
                      }`}
                      onClick={() => {
                        setMultiplePrograms((c) => !c);
                      }}
                    />
                  }
                />
              ) : (
                <div className="mb-4">
                  <SelectManagedPrograms
                    wrapperClassName="w-full"
                    label="Select Programs"
                    rightLabel={
                      <Label
                        className=" text-sm px-2 cursor-pointer"
                        label={`Adding for a single Program?`}
                        onClick={() => {
                          setMultiplePrograms((c) => !c);
                        }}
                      />
                    }
                    selectedPrograms={form.linked_programs || []}
                    onDone={(selectedPrograms) => {
                      setForm((f) => ({
                        ...f,
                        linked_programs: selectedPrograms,
                      }));
                    }}
                  />
                </div>
              )}

              <Input
                label="Event Date"
                type="date"
                id="event-date"
                required
                value={form.event_date}
                name="event_date"
                onChange={onChange}
                error={createEventData.error?.event_date}
              />

              <>
                {multiplePrograms && (
                  <div>
                    <fieldset className="my-4">
                      <legend className="text-sm font-medium text-gray-900">
                        Contact Works at
                      </legend>

                      {getEntityTypesData.data
                        .filter(
                          (el) =>
                            !el.description?.toLowerCase().includes("program")
                        )
                        .map((el) => (
                          <>
                            <div>
                              <div
                                key={el.id}
                                className="flex items-center mt-4"
                              >
                                <input
                                  id={el.id.toString()}
                                  name={"entity_type"}
                                  type="radio"
                                  checked={el.id === form.entity_type}
                                  onChange={() =>
                                    setForm((f) => ({
                                      ...f,
                                      entity_type: el.id,
                                    }))
                                  }
                                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                                />
                                <label
                                  htmlFor={String(el.id)}
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  {el.description}
                                </label>
                              </div>
                            </div>
                          </>
                        ))}
                    </fieldset>

                    {getEntityTypesData.data
                      .find((el) => el.id === form.entity_type)
                      ?.description?.toLowerCase()
                      .includes("agen") && (
                      <SearchAgencies
                        value={form.entity?.name}
                        placeholder={"Search Agencies"}
                        onResultClick={(res) => {
                          setForm((f) => ({
                            ...f,
                            entity: res,
                            entity_id: res.id,
                          }));
                        }}
                      />
                    )}

                    {getEntityTypesData.data
                      .find((el) => el.id === form.entity_type)
                      ?.description?.toLowerCase()
                      .includes("netw") && (
                      <Dropdown
                        options={getCRMNetworksData.data.map((el) => ({
                          label: el.name,
                          value: el.id,
                        }))}
                        placeholder="Select Network"
                        name="entity"
                        onChange={({ target: { value } }) => {
                          setForm((f) => ({
                            ...f,
                            entity_id: value,
                            entity:
                              getCRMNetworksData.data.find(
                                (ut) => String(ut.id) === String(value)
                              ) || {},
                          }));
                        }}
                        value={form.entity_id}
                        label={"Select Network"}
                        // error={errors?.entity}
                      />
                    )}
                  </div>
                )}
              </>

              <div className="flex items-end justify-between mt-2">
                <div className="w-2/3">
                  <div className="relative ">
                    <Dropdown
                      options={programContacts.map((el) => ({
                        label: el.first_name + " " + el.last_name,
                        value: String(el.id),
                        selected: form.contacts?.includes(String(el.id)),
                      }))}
                      placeholder="Select a contact"
                      label="Who did you talk to?"
                      name="contacts"
                      value={form.contact}
                      onChange={(e) => {
                        setForm((f) => ({ ...f, contact: e.target.value }));
                      }}
                      error={
                        createEventData.error?.contact ||
                        editDeleteEventData.error?.contact
                      }
                      loading={programContactsLoading}
                    />
                  </div>
                </div>
                <Button
                  text="+ New contact"
                  onClick={() => {
                    setProgram(form.program);
                    handleShowContactsModal();
                  }}
                />
              </div>

              <div className="border-b border-gray-200 py-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  This was via
                </label>
                <div className="space-y-2">
                  {getContactMediumsData.data
                    .map((el) => ({
                      id: String(el.id),
                      label: el.name,
                    }))
                    .map((event) => (
                      <div key={event.id} className="flex items-center">
                        <input
                          id={`medium-${event.id}`}
                          type="radio"
                          name="medium"
                          value={form.medium}
                          checked={form.medium == event.id}
                          onChange={() => {
                            setForm((f) => ({ ...f, medium: event.id }));
                          }}
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor={`medium-${event.id}`}
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          {event.label}
                        </label>
                      </div>
                    ))}
                </div>
              </div>

              <div className="border-b border-gray-200 py-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Event type
                </label>
                <div className="space-y-2">
                  {getEventTypesData.data
                    .map((el) => ({
                      id: String(el.id),
                      label: el.name,
                      checked: false,
                    }))
                    .map((event) => (
                      <div key={event.id} className="flex items-center">
                        <input
                          id={event.id}
                          type="checkbox"
                          value={event.id}
                          name="event_type"
                          onChange={(e) => {
                            handleItemChange("event_types", Number(event.id));
                          }}
                          checked={form.event_types?.includes(Number(event.id))}
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor={event.id}
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          {event.label}
                        </label>
                      </div>
                    ))}
                  {createEventData.error?.event_types && (
                    <span className="text-red-700 text-xs py-4">
                      {createEventData.error?.event_types}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="px-4 space-y-2">
              <div className="py-4  bg-white sm:p-5">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    What&apos;s discussed?
                  </label>
                  <ul className="grid w-full grid-cols-2 gap-3 md:grid-cols-3">
                    {getLeadTopicsData.data
                      .map((el) => ({
                        id: String(el.id),
                        label: el.name,
                        checked: true,
                      }))
                      .map((item) => (
                        <li key={item.id}>
                          <button
                            onClick={(e) => {
                              handleItemChange("topics", Number(item.id));
                            }}
                            className={twMerge(
                              "inline-flex items-center justify-center w-full p-2 text-sm text-center bg-white border rounded-lg cursor-pointer text-blue-700 border-blue-700 ",
                              form.topics
                                ?.map((el: string) => Number(el))
                                .includes(Number(item.id)) &&
                                "bg-blue-700 text-white"
                            )}
                          >
                            {item.label}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              {!isSubUpdate && !isSub && (
                <div className="mt-2">
                  <Input
                    label="Headline"
                    name="headline"
                    value={form.headline}
                    error={createEventData.error?.headline}
                    onChange={onChange}
                    placeholder=""
                  />
                </div>
              )}

              <div>
                <TextArea
                  label="Details"
                  id="notes"
                  name="notes"
                  value={form.notes}
                  error={createEventData.error?.notes}
                  onChange={onChange as any}
                  placeholder="Event description"
                >
                  {form.notes}
                </TextArea>
              </div>

              <div className="p-4 space-y-2">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  To-Do List
                </h3>
                {getEventActionItemsData.data
                  .map((el) => ({
                    ...el,
                    inputType: "text",
                    placeholder: "",
                    selected: false,
                  }))
                  .map((task) => (
                    <div key={task.id}>
                      <div className="flex items-center space-x-3">
                        <Checkbox
                          label={task.name}
                          name={task.name}
                          onChange={(e) => {
                            handleItemChange("action_items", Number(task.id));
                          }}
                          checked={form.action_items
                            ?.map((el: string) => Number(el))
                            .includes(Number(task.id))}
                        />
                      </div>
                      {form.action_items.includes(Number(task.id)) && (
                        <div className="mt-2 ml-7">
                          <TextArea
                            onChange={(e) => {
                              setTodoForm((f) => ({
                                ...f,
                                [`${task.id}Note`]: e.target.value,
                              }));
                            }}
                            placeholder={task.placeholder}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>

              <div>
                <Label label="Follow up reminder" />
                <Checkbox
                  label="Needs follow up"
                  onChange={(event) => {
                    setForm((f) => ({
                      ...f,
                      has_pending_followup: event.target.checked,
                    }));
                  }}
                  name="has_pending_followup"
                  checked={form.has_pending_followup}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-4 items-center">
            {form.id ? (
              <>
                {isSubUpdate ? (
                  <Button
                    text="Create Sub-Update"
                    type="button"
                    loading={editDeleteEventData.loading}
                    onClick={handleFormSubmit}
                  />
                ) : (
                  <Button
                    text="Update"
                    type="button"
                    loading={editDeleteEventData.loading}
                    onClick={handleFormSubmit}
                  />
                )}
              </>
            ) : (
              <Button
                text="Add Event"
                type="button"
                loading={createEventData.loading}
                onClick={handleFormSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewEventLayout;
