import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { debounce } from "lodash";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { clearStoreSearchResults } from "redux/slices/clients/searchActivePrograms";
import searchActivePrograms from "redux/thunks/app/api-clients/searchActivePrograms";
import getAdvertisers from "redux/thunks/advertisers/getAdvertisers";
import getClients from "redux/thunks/app/getClients";
import Dropdown from "../common/Dropdown";
import SearchBar from "../program/SearchBar";
import HeaderNavLinks from "./HeaderNavLinks";
import SettingsDropDown from "components/SettingsDropDown";
import { appRoutes } from "constants/appRouteIds";
import useLoadAuth from "hooks/useLoadAuth";

export default function Header() {
  const { user } = useLoadAuth();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const [selectedClient, setSelectedClient] = useState(
    String(localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)) || ""
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { network_id, network_status } = Object.fromEntries([...searchParams]);

  const clients = useAppSelector(({ getClients: { data } }) => data);

  const [searchText, setSearchText] = useState("");
  const { data } = useAppSelector((state) => state.searchActivePrograms);

  const isCRM = location.pathname.includes("crm");
  const isBma = location.pathname.includes("bma") || location.pathname == "/";

  const debounceFn = useCallback(
    debounce((e) => {
      if (e.target.value.length) {
        dispatch(
          searchActivePrograms({
            query: e.target.value,
            isCRM: isCRM ? "1" : "0",
            useDis: isBma ? "1" : "0",
          })
        );
      } else {
        dispatch(clearStoreSearchResults());
      }
    }, 1000),
    []
  );

  const onResultClick = (res: Record<string, any>) => {
    if (location.pathname.includes("/oma")) {
      navigate(
        `/oma/program?slug=${res.slug}&client_id=${
          res.client
        }&tab=create-coupon&network=${res.primary_network?.name || ""}&mid=${
          res.primary_network?.network_mid || ""
        }`,
        {
          state: res,
        }
      );
    } else if (location.pathname.includes("marketing")) {
      navigate(
        `/marketing/search/program/?slug=${res.slug}&client_id=${res.client}`
      );
    } else if (location.pathname.includes("crm")) {
      navigate(`/sales/crm/store/${res.slug}?client_id=${res.client}`);
    } else if (location.pathname.includes("sales")) {
      navigate(`/sales/proposals/?slug=${res.slug}&client_id=${res.client}`);
    } else {
      navigate(
        `/bma/programs?slug=${res.slug}&client_id=${res.client}&tab=${
          params.get("tab") || 0
        }`
      );
    }
  };

  const onResultsOutsideClick = () => {
    dispatch(clearStoreSearchResults());
  };

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
    debounceFn(e);
  };
  const onSearchSubmit = () => {
    dispatch(
      searchActivePrograms({ query: searchText, isCRM: isCRM ? "1" : "0" })
    );
  };

  useEffect(() => {
    if (!clients.items.length) {
      dispatch(getClients({ page: 1 }));
    }
  }, []);

  const routePath = appRoutes.find((el) => el.pk === user?.default_app)?.path;

  const defaultURL = routePath || "/";

  return (
    <nav className="bg-white shadow  py-3 sm:py-5 px-40 sticky top-0 z-10 mx-auto">
      <div className="flex items-center justify-between ">
        <div className="flex items-center">
          <Link to={defaultURL} className="flex items-center">
            <img
              src="/sma-logo-icon-operations-hub.svg"
              className="mr-2 h-6 sm:h-9"
              alt="surfmyads logo"
            />
          </Link>

          {/* {(location.pathname === "/" || location.pathname === "/oma") && ( */}
          <SearchBar
            onResultClick={onResultClick}
            onResultsOutsideClick={onResultsOutsideClick}
            results={data}
            onChange={onSearchChange}
            wrapperClass="mx-4"
            onSearchSubmit={onSearchSubmit}
            renderLocation
            renderClientName={isCRM}
          />

          <Dropdown
            value={selectedClient}
            onChange={(e) => {
              localStorage.setItem(
                LOCAL_KEY_SELECTED_CLIENT_ID,
                e.target.value
              );
              setSelectedClient(String(e.target.value));
              dispatch(getAdvertisers({ page: 1, network_id, network_status }));
              const url = new URL(window.location.href);
              url.searchParams.delete("id");
              url.searchParams.set("client_id", e.target.value);
              navigate(url.search);
            }}
            options={clients.items.map((it) => ({
              label: it.name,
              value: it.id,
            }))}
          />
        </div>

        <div className="flex items-center">
          <HeaderNavLinks />
          <div
            className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <SettingsDropDown />
          </div>
        </div>
      </div>
    </nav>
  );
}
