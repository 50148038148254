import ExportButton from "components/common/ExportButton";
import networkStatuses from "constants/networkStatuses";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { ExportResourceAction } from "redux/slices/clients/export-resource";
import toPascalCase from "utils/strings/toPascalCase";
import { CSVDownload } from "react-csv";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import { twMerge } from "tailwind-merge";
import { sortedUniq } from "lodash";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  network_status: string;
  totalCount?: number;
  selectedItems: Record<string, any>[];
  handleIgnoreAdvertisers?: () => void;
  programType: string;
  setAllSelected: Dispatch<SetStateAction<boolean>>;
  allSelected: boolean;
  exportResourceData: ExportResourceAction;
  onExportAll?: (fields: string[]) => void;
  has_open_ppc_policy: string;
  has_no_faqs: string;
  is_free?: string;
  is_deactivated: string;
  is_ad_center: string;
  activeTab: string;
  has_ai_faqs?: string;
};

function AdvertiserStatusFilters({
  onChange,
  network_status,
  totalCount,
  selectedItems,
  handleIgnoreAdvertisers,
  programType,
  allSelected,
  setAllSelected,
  exportResourceData: { data, loading },
  onExportAll,
  has_open_ppc_policy,
  has_no_faqs,
  is_free,
  activeTab,
  is_ad_center,
  is_deactivated,
  has_ai_faqs,
}: Props) {
  const [openAddColumns, setOpenAddColumns] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([
    "id",
    "name",
    "website_url",
    "rank",
    "slug",
    "affiliate_website_url",
    "network_mid",
  ]);

  const [invisibleColumns, setInVisibleColumns] = useState([
    "client",
    "ppc_policy",
    "additional_domains",
    "cover_picture",
    "description",
    "logo_360_image_url",
    "logo_url",
    "page_headline",
    "primary_network",
    "linked_networks",
    "arm_mapping_pending",
    "arm_mapped_to",
    "network_status",
    "primary_category",
    "similar_categories",
    "similar_stores",
    "created",
    "store_type",
    "created_by",
    "updated",
    "last_updated_by",
    "average_order_size",
    "average_savings_type",
    "location",
    "average_savings",
    "average_rating",
    "voters_count",
    "real_average_rating",
    "real_voters_count",
    "most_recent_coupon_update",
    "promote_extension",
    "info",
    "extra",
    "fb",
    "instagram",
    "twitter",
    "pinterest",
    "wikipedia",
    "youtube",
    "snapchat",
    "tiktok",
    "blog_url",
    "linkedin_url",
    "tumblr_url",
    "slideshare_url",
    "email_address",
    "phone_number",
    "meta_title",
    "meta_description",
    "followers",
  ]);
  return (
    <div className="flex justify-between px-2">
      <Modal
        doneText="Done"
        variant="lg"
        doneBtnClass="bg-blue-500 hover:bg-blue-600 mr-2"
        onConfirm={() => {
          setOpenAddColumns(false);
          onExportAll?.(visibleColumns);
        }}
        open={openAddColumns}
        setOpen={setOpenAddColumns}
      >
        <div className="h-[630px] overflow-scroll">
          <p className="mb-2 text-base">Choose columns to export</p>

          {[...visibleColumns, ...invisibleColumns]
            .sort((a, b) => (a > b ? 1 : -1))
            .map((el) => (
              <button
                className={twMerge(
                  "border border-gray-200 my-2 inline-flex w-full rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm  mr-2 sm:w-auto",
                  visibleColumns.map((it) => it).includes(el) &&
                    "bg-primary-700 text-white"
                )}
                key={el}
                onClick={() => {
                  if (visibleColumns.map((it) => it).includes(el)) {
                    setInVisibleColumns((columns) => [...columns, el]);
                    setVisibleColumns((columns) =>
                      columns.filter((item) => item !== el)
                    );
                  } else {
                    setVisibleColumns((columns) => [...columns, el]);

                    setInVisibleColumns((columns) =>
                      columns.filter((it) => it !== el)
                    );
                  }
                }}
              >
                {el}
              </button>
            ))}
        </div>
      </Modal>
      <div className="mx-4 pb-3 flex flex-wrap justify-between flex-1">
        <div className="flex items-center">
          <div className="md:flex items-center text-sm font-medium text-gray-900  mr-4 mt-3">
            Show only:
          </div>
          {
            <div className="flex flex-wrap justify-between">
              {activeTab !== "2" &&
                networkStatuses.map((it) => (
                  <div className="flex items-center mt-3 mr-4" key={it}>
                    <input
                      id="all-tasks"
                      type="radio"
                      value={it}
                      defaultChecked={it === network_status}
                      onChange={onChange}
                      name="network_status"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="all-tasks"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {toPascalCase(it)}
                    </label>
                  </div>
                ))}
              {activeTab === "2" && (
                <div className="flex items-center mr-4 mt-3">
                  <input
                    checked={has_open_ppc_policy === "1"}
                    name="has_open_ppc_policy"
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      onChange({
                        target: {
                          name: "has_open_ppc_policy",
                          value: checked ? "1" : "0",
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="completed"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Has ppc open policy
                  </label>
                </div>
              )}

              {activeTab === "2" && (
                <div className="flex items-center mr-4 mt-3">
                  <input
                    checked={is_free === "1"}
                    name="is_free"
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      onChange({
                        target: {
                          name: "is_free",
                          value: checked ? "1" : "0",
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="completed"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Is Free
                  </label>
                </div>
              )}

              {activeTab === "2" && (
                <div className="flex items-center mr-4 mt-3">
                  <input
                    checked={is_ad_center === "1"}
                    name="is_ad_center"
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      onChange({
                        target: {
                          name: "is_ad_center",
                          value: checked ? "1" : "0",
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="completed"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Is AdCenter
                  </label>
                </div>
              )}

              {activeTab === "2" && (
                <div className="flex items-center mr-4 mt-3">
                  <input
                    checked={has_no_faqs === "1"}
                    name="has_no_faqs"
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      onChange({
                        target: {
                          name: "has_no_faqs",
                          value: checked ? "1" : "0",
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="completed"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Has No FAQs
                  </label>
                </div>
              )}

              {activeTab === "2" && (
                <div className="flex items-center mr-4 mt-3">
                  <input
                    checked={has_ai_faqs === "1"}
                    name="has_ai_faqs"
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      onChange({
                        target: {
                          name: "has_ai_faqs",
                          value: checked ? "1" : "0",
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="completed"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Has AI FAQs
                  </label>
                </div>
              )}

              {activeTab === "2" && (
                <div className="flex items-center mr-4 mt-3">
                  <input
                    checked={is_deactivated === "1"}
                    name="is_deactivated"
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      onChange({
                        target: {
                          name: "is_deactivated",
                          value: checked ? "1" : "0",
                        },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="completed"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Deactivated
                  </label>
                </div>
              )}
            </div>
          }
        </div>

        {programType === "Active" && (
          <div className="flex items-center gap-4 py-2">
            <div className="flex">
              {selectedItems.length > 0 && (
                <div className="flex items-center gap-4 mx-4">
                  <p className="font-medium text-gray-900 text-xs">
                    {" "}
                    {allSelected ? totalCount : selectedItems.length} Selected
                    out of {totalCount}
                  </p>
                  <button
                    className="font-medium text-primary-700 text-xs underline"
                    onClick={() => setAllSelected((value) => !value)}
                  >
                    {allSelected ? (
                      <span>Cancel</span>
                    ) : (
                      <span>Select all {totalCount}</span>
                    )}
                  </button>
                </div>
              )}

              {!!data ? (
                <CSVDownload data={data} target="_blank" />
              ) : (
                <div>
                  {allSelected ? (
                    <Button
                      loading={loading}
                      onClick={() => {
                        setOpenAddColumns(true);
                      }}
                      text="Export All"
                    />
                  ) : (
                    <ExportButton
                      fileNamePrefix={`${
                        programType?.toLowerCase()?.replace(" ", "") || ""
                      }_programs`}
                      data={
                        selectedItems?.map((el) => ({
                          ...el,
                          primary_network: el.primary_network?.name || "",
                          primary_network_mid:
                            el.primary_network?.network_mid || "",
                          primary_category: el.primary_category?.name || "",
                          linked_networks: JSON.stringify(
                            el.linked_networks,
                            null,
                            2
                          ),
                          faq: JSON.stringify(el.faq, null, 2),
                          similar_stores: JSON.stringify(
                            el.similar_stores,
                            null,
                            2
                          ),
                        })) || []
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        {!!selectedItems?.length && programType === "Pending" && (
          <div className="flex h-12 items-center space-x-3 bg-white sm:left-12">
            <button
              type="button"
              className="inline-flex items-center
                px-2 py-1 text-sm font-medium
               text-blue-700  ring-inset underline
                ring-gray-300  disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
              onClick={handleIgnoreAdvertisers}
            >
              Bulk Ignore ({selectedItems.length})
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdvertiserStatusFilters;
