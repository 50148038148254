import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import Thunk from "redux/thunks/app/Thunk";

export default Thunk("getAIFaqs", async (payload: Record<string, string>) => {
  const url = new URL(DISCOUNT_CODES_API + "/stores/store-faq-list/");
  Object.entries(payload).map(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value);
    }
  });
  return instance.get(url.href);
});
