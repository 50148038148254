import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import getOffsetForPage from "utils/arrays/getOffset";

type Params = {
  page: number;
  show_summary: string;
  email_drop_id?: string;
};

export default createAsyncThunk(
  "clients/marketing/emails/email-campaigns",
  async ({ page = 1, show_summary = "0", email_drop_id }: Params, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }
      const response = await axios.get(
        `/admin/emails/email-campaigns/?client=${selectedClientId}&email_drop_id=${
          email_drop_id || ""
        }&show_summary=${show_summary}&offset=${getOffsetForPage(page)}`
      );

      if (show_summary === "1") {
        const result = {
          ...response,
          data: {
            ...response.data,
            items: response.data.items.map((el: Record<string, any>) => ({
              ...el.data,
              email_drop_id: el.data.email_drop_id,
            })),
          },
        };
        return result.data;
      } else {
        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
