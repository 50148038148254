/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createUser from "redux/thunks/users/createUser";

export type ActionStateType = {
  data: object;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const createUserSlice = createSlice({
  name: "create-user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
        data: initialState.data,
      }))
      .addCase(
        createUser.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )
      .addCase(
        createUser.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});

export default createUserSlice.reducer;
