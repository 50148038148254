import InstallReportRow from "layout/reporting/extension/InstallReportRow";
import DataTable from "components/common/layout/DataTable";
import DateRangeSelector from "components/common/DateRange";
import UnInstallReportRow from "layout/reporting/extension/UnInstallReportRow";
import {
  EXT_TYPE_CLOSING,
  EXT_TYPE_INSTALLS,
  EXT_TYPE_NON_API,
  EXT_TYPE_STAND_DOWN,
  EXT_TYPE_STORE_URLS,
  EXT_TYPE_UNINSTALLS,
  EXT_TYPE_USER_ACCOUNTS,
} from "constants/extensionReportTypes";
import ExtStandDownRow from "layout/reporting/extension/ExtStandDownRow";
import { ActionState } from "types/data";
import { ChangeEventHandler } from "react";
import ExtUserAccountRow from "./ExtUserAccountRow";
import ExtApiStoreRow from "./ExtApiStoreRow";
import NonExtApiStoreRow from "./NonExtApiStoreRow";
import Input from "components/common/Input";
import Button from "components/common/Button";
import ExtClosingReportRow from "./ExtClosingReportRow";

type Props = {
  reportTypeConfig: Record<string, any>;
  reportType: string;
  getExtReportData: ActionState;
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  onPageChange: (page: number) => void;
  runReport: () => void;
  onDetailsClick: (row: Record<string, any>) => void;
};

const BrowserExtensionReports = ({
  reportTypeConfig,
  reportType,
  getExtReportData: { data, loading },
  onChange,
  form,
  onPageChange,
  onDetailsClick,
  runReport,
}: Props) => {
  const rowRenderer = (row: Record<string, any>) => {
    if (reportType === EXT_TYPE_UNINSTALLS) {
      return <UnInstallReportRow row={row} />;
    }

    if (reportType === EXT_TYPE_CLOSING) {
      return <ExtClosingReportRow row={row} />;
    }

    if (reportType === EXT_TYPE_INSTALLS) {
      return <InstallReportRow row={row} />;
    }

    if (reportType === EXT_TYPE_STAND_DOWN) {
      return <ExtStandDownRow row={row} />;
    }

    if (reportType === EXT_TYPE_USER_ACCOUNTS) {
      return <ExtUserAccountRow row={row} />;
    }

    if (EXT_TYPE_STORE_URLS == reportType) {
      return (
        <ExtApiStoreRow
          row={row}
          onDetailsClick={() => {
            onDetailsClick(row);
          }}
        />
      );
    }

    if (EXT_TYPE_NON_API === reportType) {
      return (
        <NonExtApiStoreRow
          row={row}
          onDetailsClick={() => {
            onDetailsClick(row);
          }}
        />
      );
    }

    return <InstallReportRow row={row} />;
  };

  return (
    <div className="">
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-2 items-center">
          <DateRangeSelector onChange={onChange} form={form} />
          {[EXT_TYPE_NON_API, EXT_TYPE_STORE_URLS].includes(reportType) && (
            <>
              <Input placeholder="User ID" name="user_id" onChange={onChange} />
              <Button
                text="Run Report"
                className=""
                spinnerClassName="h-4 w-4 mr-0"
                onClick={runReport}
              />
            </>
          )}
        </div>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex justify-between items-center p-6">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            {reportTypeConfig.title}
          </h1>
          <div>
            <p className="text-sm font-medium text-gray-500">
              {/* Total # of {reportType === EXT_TYPE_UNINSTALLS?'Removals':'Installs'} = */}
              {data.count} Items
            </p>
          </div>
        </div>
        <div className="border-t border-gray-200">
          <DataTable
            columns={reportTypeConfig.columns}
            rows={data.results}
            rowRenderer={rowRenderer}
            loading={loading}
            totalCount={data.count}
            currentPage={data.current_page}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BrowserExtensionReports;
