import Dropdown from "components/common/Dropdown";
import Layout from "components/layout/Layout";
import extReportTypes, {
  extReportTypeConfig,
} from "constants/extensionReportTypes";
import ExtensionReport from "containers/reporting/ExtensionReport";
import { useNavigate, useSearchParams } from "react-router-dom";

const BrowserExtensionReports = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const currReportId = searchParams.get("report_type") || "2";

  const selectedReport = Object.entries(extReportTypeConfig).find(
    ([_, value]) => {
      return String(value.id) === String(currReportId);
    }
  );

  const reportOptions = extReportTypes.map((it: string) => {
    const report_type = extReportTypeConfig[it];

    return {
      id: report_type.id,
      title: report_type.title,
    };
  });

  return (
    <Layout>
      <div className="px-4">
        <Dropdown
          wrapperClassName="w-64 mb-4"
          placeholder="Report type"
          name="report_type"
          value={currReportId}
          onChange={(e) => {
            // setSelectedReport(e.target.value);
            const url = new URL(window.location.href);
            url.searchParams.set("report_type", e.target.value);

            navigate(url.search);
          }}
          options={reportOptions.map((el) => ({
            label: el.title,
            value: el.id,
          }))}
        />

        {selectedReport?.[0] && (
          <ExtensionReport reportType={selectedReport?.[0]} />
        )}
      </div>
    </Layout>
  );
};

export default BrowserExtensionReports;
