import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import editDeleteCrmNetwork from "redux/thunks/sales_crm/editDeleteCrmNetwork";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const editDeleteCrmNetworksSlice = createSlice({
  name: "editDeleteCrmNetwork",
  initialState,
  reducers: {
    resetEditDeleteCrmNetwork: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(editDeleteCrmNetwork.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        editDeleteCrmNetwork.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        editDeleteCrmNetwork.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetEditDeleteCrmNetwork } = editDeleteCrmNetworksSlice.actions;
export default editDeleteCrmNetworksSlice.reducer;
