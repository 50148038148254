import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import SEMProgramNotesComponent from "layout/marketing/search/SEMProgramNotesComponent";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import getCampaignNotes from "redux/thunks/marketing/sem/getCampaignNotes";

const SEMProgramNotes = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();

  const getAdCampaignNotesState = useAppSelector(
    (state) => state.getAdCampaignNotes,
  );

  useEffect(() => {
    dispatch(getCampaignNotes({ program_slug: params.get("slug") }));
  }, [params.get("slug")]);

  return (
    <SEMProgramNotesComponent
      getAdCampaignNotesState={getAdCampaignNotesState}
    />
  );
};

export default SEMProgramNotes;
