import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import saveStoreFaq from "redux/thunks/marketing/content/saveStoreFaqs";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const saveStoreFaqSlice = createSlice({
  name: "saveStoreFaqs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveStoreFaq.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(
        saveStoreFaq.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      )

      .addCase(
        saveStoreFaq.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default saveStoreFaqSlice.reducer;
