import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getPricingPlans from "redux/thunks/sales/getPricingPlans";
import { PaginatedData } from "types/data";
import { PricingPlan } from "types/model/PricingPlan";

export type ActionStateType = {
  data: PaginatedData<PricingPlan>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getPricingPlansSlice = createSlice({
  name: "PricingPlans",
  initialState,
  reducers: {
    resetPricingPlans: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPricingPlans.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getPricingPlans.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        getPricingPlans.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetPricingPlans } = getPricingPlansSlice.actions;
export default getPricingPlansSlice.reducer;
