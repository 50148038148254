import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import addToPaidPlacements from "redux/thunks/app/placements/campaigns/addToPaidPlacements";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {},
  loading: false,
  error: null,
};

const addToPaidPlacementsSlice = createSlice({
  name: "addToPaidPlacements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addToPaidPlacements.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))

      .addCase(
        addToPaidPlacements.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        addToPaidPlacements.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export const {} = addToPaidPlacementsSlice.actions;
export default addToPaidPlacementsSlice.reducer;
