import getNetworkExtSettings from "redux/slices/extension/getNetworkExtSettings";
import getStoreExtSettings from "redux/slices/extension/getStoreExtSettings";
import updateStoreExtSettings from "redux/slices/extension/updateStoreExtSettings";
import updateNetworkExtSettings from "redux/slices/extension/updateNetworkExtSettings";
import createStoreExtSetting from "redux/slices/extension/createStoreExtSetting";
import createNetworkExtSetting from "redux/slices/extension/createNetworkExtSetting";

const extensionReducers = {
  getNetworkExtSettings,
  updateNetworkExtSettings,
  getStoreExtSettings,
  updateStoreExtSettings,
  createStoreExtSetting,
  createNetworkExtSetting,
};

export default extensionReducers;
