import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { toast } from "react-hot-toast";

export default createAsyncThunk(
  "sales/create-update-delete-pricing-plan",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = payload.id
        ? payload.delete
          ? await axios.delete(`/admin/sales/pricing-plans/${payload.id}`)
          : await axios.put(`/admin/sales/pricing-plans/${payload.id}`, payload)
        : await axios.post(`/admin/sales/pricing-plans`, payload);

        if (payload.delete){
          toast.success("Pricing plan was deleted successfully!")

        } else
        {
          toast.success(
            payload.id
              ? "Changes Saved!"
              : "Pricing plan was created successfully!"
          );
        }

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
