import dateFormat from "dateformat";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import SEMHomeComponent from "layout/marketing/search/SEMHomeComponent";
import { ChangeEventHandler, useEffect, useState } from "react";
import getCampaignReports from "redux/thunks/marketing/sem/getCampaignReports";
import getDeviceTypes from "redux/thunks/marketing/sem/getDeviceTypes";
import { useNavigate, useSearchParams } from "react-router-dom";
import formatDate from "utils/dates/formatDate";
import getLastWeekDateRanges from "utils/dates/dateRanges";
import exportCampaignReport from "redux/thunks/marketing/sem/exportCampaignReport";

const SEMReport = () => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { lastWeekStart, lastWeekEnd } = getLastWeekDateRanges();

  const {
    page = 1,
    devices = "",
    search_engines = "google,bing",
    startDate = new Date(lastWeekStart),
    program_slug = "",
    location = "",
    endDate = new Date(lastWeekEnd),
    client_id = "",
    ordering = "",
    client_view = "1",
    trend_view = "0",
  } = Object.fromEntries(params);
  const dispatch = useAppDispatch();

  const getDeviceTypesData = useAppSelector((state) => state.getDeviceTypes);
  const getClientsData = useAppSelector((state) => state.getClients);
  const getCampaignReportsState = useAppSelector(
    (state) => state.getCampaignReports
  );

  const DATE_FMT = "yyyy-mm-dd";

  const start_date = startDate ? dateFormat(new Date(startDate), DATE_FMT) : "";
  const end_date = endDate ? dateFormat(new Date(endDate), DATE_FMT) : "";

  const filterValues = {
    search_engines: search_engines,
    devices: devices,
    start_date: start_date,
    end_date: end_date,
    page: page,
    program_slug: program_slug || "",
    startDate,
    client_id,
    endDate,
    ordering,
    location,
    client_view,
    trend_view,
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value = "" },
  }) => {
    const url = new URL(window.location.href);
    if (name === "dates") {
      url.searchParams.set(
        "startDate",
        String(
          value.includes("null")
            ? ""
            : formatDate(new Date(JSON.parse(value)[0]).toString(), DATE_FMT)
        )
      );

      url.searchParams.set(
        "endDate",
        String(
          value.includes("null")
            ? ""
            : formatDate(new Date(JSON.parse(value)[1]).toString(), DATE_FMT)
        )
      );
      navigate(url.search);

      return;
    }
    if (name === "campaign") {
      url.searchParams.set("program_slug", JSON.parse(value).program_slug);
    }

    if (name === "search_engines") {
      const current = search_engines.split(",").filter(Boolean);
      const updated = current.includes(value)
        ? current.filter((it) => it !== value)
        : [...current, value];
      url.searchParams.set("search_engines", updated.join(","));
      navigate(url.search);
      return;
    }
    if (name === "devices") {
      const current = devices.split(",").filter(Boolean);
      const updated = current.includes(value)
        ? current.filter((it) => it !== value)
        : [...current, value];
      url.searchParams.set("devices", updated.join(","));
      navigate(url.search);

      return;
    } else {
      url.searchParams.set(name, value);
    }

    navigate(url.search);
  };

  const onCampaignClicked = (res: Record<string, any>) => {
    window.open(`/bma/programs?slug=${res.program_slug}`, "_blank");
  };

  const onSubmit = () => {
    dispatch(
      getCampaignReports({
        ...filterValues,
        page: 1,
      })
    );
  };

  const resetFilters = () => {
    navigate("/marketing/search?tab=5");
  };

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  useEffect(() => {
    dispatch(getDeviceTypes());
  }, []);

  useEffect(() => {
    dispatch(
      getCampaignReports({
        ...filterValues,
      })
    );
  }, [page]);

  useEffect(() => {
    if (
      filterValues.client_id ||
      filterValues.ordering ||
      filterValues.client_view
    ) {
      onSubmit();
    }
  }, [
    filterValues.client_id,
    filterValues.ordering,
    filterValues.location,
    filterValues.client_view,
  ]);

  const handleExport = () => {
    setDownloadLoading(true);

    dispatch(exportCampaignReport(filterValues)).finally(() => {
      setDownloadLoading(false);
    });
  };
  return (
    <SEMHomeComponent
      handleExport={handleExport}
      getClientsData={getClientsData}
      filterValues={filterValues}
      onPageChange={onPageChange}
      resetFilters={resetFilters}
      onChange={onChange}
      onSubmit={onSubmit}
      onCampaignClicked={onCampaignClicked}
      getCampaignReportsState={getCampaignReportsState}
      getDeviceTypesData={getDeviceTypesData}
      downloadLoading={downloadLoading}
    />
  );
};

export default SEMReport;
