import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createPricingPlan from "redux/thunks/sales/createUpdateDeletePricingPlan";

export type ActionStateType = {
  data: Record<string, any>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const createPricingPlanSlice = createSlice({
  name: "createPricingPlan",
  initialState,
  reducers: {
    resetCreatePricingPlan: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPricingPlan.pending, (state) => {
        return {
          ...state,
          error: null,
          data: {},
          loading: true,
        };
      })
      .addCase(
        createPricingPlan.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )

      .addCase(
        createPricingPlan.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetCreatePricingPlan } = createPricingPlanSlice.actions;
export default createPricingPlanSlice.reducer;
