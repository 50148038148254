import Label from "components/common/Label";
import Layout from "components/layout/Layout";
import { ActionState } from "types/data";
import PriceItem from "./PriceItem";

type Props = {
  getPricingPlanData: ActionState;
};

const PricingPlanLayout = ({
  getPricingPlanData: { loading, data },
}: Props) => {
  return (
    <Layout loading={loading}>
      <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
        <div>
          <Label label="Pricing Plan Name" />
          <small>{data.name}</small>
        </div>
        <div>
          <Label label="Client" />
          <small>{data.client?.name}</small>
        </div>
        <div>
          <Label label="Location" />
          <small>{data.location?.toUpperCase()}</small>
        </div>
        <hr className="my-4" />
        <div>Added Page Prices </div>
        {data.prices?.map((el: Record<string, any>) => (
          <PriceItem priceItem={el} key={el.id} editEnabled={false} />
        ))}{" "}
      </div>
    </Layout>
  );
};

export default PricingPlanLayout;
