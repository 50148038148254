import DeleteButton from "components/common/DeleteButton";
import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import Modal from "components/common/Modal";
import SearchPrograms from "containers/oma/SearchPrograms";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import React, {
  useState,
  ChangeEventHandler,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import toast from "react-hot-toast";
import createContact from "redux/thunks/sales_crm/createContact";
import editDeleteContact from "redux/thunks/sales_crm/editDeleteContact";
import getContacts from "redux/thunks/sales_crm/getContacts";
import { Contact } from "types/model/sales_crm/Contact";
import SelectManagedPrograms from "./SelectManagedPrograms";
import {
  setShowContactModal,
  toggleContactModal,
} from "redux/slices/sales_crm/modals";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import getAgencies from "redux/thunks/sales_crm/getAgencies";
import SearchAgencies from "./SearchAgencies";
import getCRMNetworks from "redux/thunks/sales_crm/getCRMNetworks";

interface ContactEntryCardProps {
  currentContact?: Contact;
  setCurrentContact?: Dispatch<SetStateAction<Contact | undefined>>;
}

const ContactEntryCard: React.FC<ContactEntryCardProps> = ({
  currentContact,
  setCurrentContact,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { slug } = useParams();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { slug: storeSlug, client_id } = Object.fromEntries([...params]);
  const formSlug = currentContact?.entity?.slug || storeSlug || "";

  const createContactData = useAppSelector((state) => state.createContact);
  const { contactModalOpen: modalOpen } = useAppSelector(
    (state) => state.crmModals
  );
  const getCRMNetworksData = useAppSelector((state) => state.getCRMNetworks);
  const { data: program } = useAppSelector((state) => state.getProgram);

  const getAgenciesData = useAppSelector((state) => state.getAgencies);
  const editDeleteContactData = useAppSelector(
    (state) => state.editDeleteContact
  );
  const [form, setForm] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, any>>({});
  const entityTypes = useAppSelector((state) => state.getEntityTypes);
  const [selectedPrograms, setSelectedPrograms] = useState<
    Record<string, any>[]
  >([]);

  useEffect(() => {
    if (getAgenciesData.data.items.length === 0) {
      dispatch(getAgencies({}));
    }
  }, [getAgenciesData.data.items.length]);

  useEffect(() => {
    if (getCRMNetworksData.data.length === 0) {
      dispatch(getCRMNetworks());
    }
  }, [getCRMNetworksData.data.length]);

  useEffect(() => {
    return () => {
      setCurrentContact?.(undefined);
      setForm({});
    };
  }, []);

  useEffect(() => {
    if (!form.entity_type && currentContact) {
      setForm((f) => ({
        ...f,
        ...currentContact,
        entity_type: currentContact?.entity_type.id,
      }));
    }
  }, [currentContact, form.entity_type]);

  useEffect(() => {
    if (currentContact && Array.isArray(currentContact?.programs_managed)) {
      setSelectedPrograms(currentContact.programs_managed);
    }
  }, [currentContact?.programs_managed]);

  useEffect(() => {
    if (!currentContact?.id && formSlug) {
      setSelectedPrograms([program]);
    }
  }, [currentContact && formSlug]);

  useEffect(() => {
    if (currentContact) {
      setForm((f) => ({
        ...f,
        ...currentContact,
        entity_type: currentContact?.entity_type.id,
      }));
    } else {
      setCurrentContact?.(undefined);
    }
  }, [currentContact]);

  const setModalOpen = () => {
    dispatch(toggleContactModal());
  };
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    if (!form.entity.name) {
      setErrors((all) => ({ ...all, entity: "This field is Required" }));
      return;
    } else {
      setErrors((all) => ({ ...all, entity: null }));
    }
    if (form.id) {
      dispatch(
        editDeleteContact({
          ...form,
          entity: {
            name: form.entity.name,
            logo_url: form.entity.logo_url,
            entity_type: form.entity_id,
            id: form.entity.id,
            client: form.entity.client,
            slug: form.entity.slug,
          },
          programs_managed: selectedPrograms.map((el) => ({
            name: el.name,
            id: el.id,
            slug: el.slug,
            client: el.client,
          })),

          program: formSlug
            ? {
                name: program.name,
                id: program.id,
                slug: program.slug,
                client: program.client,
              }
            : {},
        })
      ).then(() => {
        setCurrentContact?.(undefined);
        dispatch(getContacts({ page: 1, program: formSlug || "" }));
        dispatch(setShowContactModal(false));
      });
    } else {
      dispatch(
        createContact({
          ...form,
          entity: {
            name: form.entity.name,
            logo_url: form.entity.logo_url,
            entity_type: form.entity_id,
            id: form.entity.id,
            client: form.entity.client,
            slug: form.entity.slug,
          },
          programs_managed: selectedPrograms.map((el) => ({
            name: el.name,
            id: el.id,
            slug: el.slug,
            client: el.client,
          })),

          program: formSlug
            ? {
                name: program.name,
                id: program.id,
                slug: program.slug,
                client: program.client,
              }
            : {},
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setCurrentContact?.(undefined);
          toast.success("Contact created successfully");
          if (location.pathname.includes("new-event")) {
            const url = new URL(window.location.href);
            url.searchParams.set("createdContact", res.payload.id);
            navigate(url.search);
          }
          dispatch(setShowContactModal(false));
          dispatch(getContacts({ page: 1, program: slug || formSlug || "" }));
        }
      });
    }
  };

  const entityLabel =
    (entityTypes.data.find((el) => el.id === form.entity_type)?.name || "") +
    ` Name`;

  const networkId =
    getCRMNetworksData.data.find(
      (ut) => String(ut.name) === String(form.entity)
    )?.id || form.entity?.id;

  return (
    <Modal
      showCancel={false}
      open={modalOpen}
      setOpen={setModalOpen}
      variant="lg"
      cancelElement={
        form.id ? (
          <>
            <div className="mr-4">
              <DeleteButton
                text="Delete"
                deleteLoading={editDeleteContactData.loading}
                onClick={() => {
                  dispatch(
                    editDeleteContact({ delete: true, id: form.id })
                  ).then(() => {
                    dispatch(
                      getContacts({ page: 1, program: slug || formSlug || "" })
                    );
                    dispatch(setShowContactModal(false));
                  });
                }}
              />
            </div>
          </>
        ) : null
      }
      doneLoading={createContactData.loading || editDeleteContactData.loading}
      doneOnClick={handleSubmit}
      doneBtnClass="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      doneText={form.id ? "Save" : "Add Contact"}
    >
      <div className="h-[650px] overflow-scroll">
        <div className="flex items-center justify-between px-5 py-3 border-b dark:border-gray-600">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            {form.id ? "Contact Details" : "Add New Program Contact"}
          </h3>
          <button
            type="button"
            onClick={() => {
              dispatch(setShowContactModal(false));
            }}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div className="p-6 space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <fieldset>
              <legend className="text-sm font-medium text-gray-900">
                Employment Type
              </legend>

              {entityTypes.data.map((el) => (
                <>
                  <div>
                    <div key={el.id} className="flex items-center mt-4">
                      <input
                        id={el.name?.toString()}
                        name={"entity_type"}
                        type="radio"
                        checked={el.id === form.entity_type}
                        onChange={() =>
                          setForm((f) => ({
                            ...f,
                            entity_type: el.id,
                            entity: {},
                          }))
                        }
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                      />
                      <label
                        htmlFor={String(el.name || "")}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Works at {el.description}
                      </label>
                    </div>
                  </div>
                </>
              ))}
            </fieldset>

            <div>
              {entityLabel.includes("Network") && (
                <Dropdown
                  options={getCRMNetworksData.data.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  placeholder="Select Network"
                  name="entity"
                  loading={getCRMNetworksData.loading}
                  onChange={({ target: { value } }) => {
                    setForm((f) => ({
                      ...f,
                      entity_id: value,
                      entity:
                        getCRMNetworksData.data.find(
                          (ut) => String(ut.id) === String(value)
                        ) || {},
                    }));
                  }}
                  value={networkId}
                  label={entityLabel}
                  error={errors?.entity}
                />
              )}

              {entityLabel.includes("Program") && (
                <SearchPrograms
                  wrapperClassName="mx-0"
                  value={form.entity?.name}
                  label={entityLabel}
                  closeOnSelect
                  onResultClicked={(result) => {
                    setForm((f) => ({ ...f, entity: result }));
                  }}
                  key={form.entity?.id}
                  error={errors?.entity}
                  isCRM
                />
              )}

              {entityLabel.includes("Agenc") && (
                <SearchAgencies
                  value={form.entity?.name}
                  placeholder={"Search Agencies"}
                  onResultClick={(res) => {
                    setForm((f) => ({ ...f, entity: res }));
                  }}
                />
              )}
            </div>

            <div>
              <Input
                label="First Name"
                type="text"
                name="first_name"
                id="first_name"
                onChange={onChange}
                value={form.first_name}
                error={createContactData.error?.first_name}
              />
            </div>
            <div>
              <Input
                label="Last Name"
                type="text"
                name="last_name"
                id="last_name"
                placeholder=""
                onChange={onChange}
                value={form.last_name}
                error={createContactData.error?.last_name}
              />
            </div>
            <div>
              <Input
                label="Title"
                type="text"
                name="title"
                id="title"
                onChange={onChange}
                value={form.title}
                error={createContactData.error?.title}
              />
            </div>
            <div>
              <Input
                label="Email Address"
                type="email"
                name="email_address"
                onChange={onChange}
                value={form.email_address}
                error={createContactData.error?.email_address}
              />
            </div>
            <div>
              <Input
                label="Phone #"
                type="tel"
                name="phone_number"
                id="phone_number"
                onChange={onChange}
                value={form.phone_number}
                placeholder="123-456-7890"
                error={createContactData.error?.phone_number}
              />
            </div>
            <div>
              <Input
                label="LinkedIn URL"
                type="url"
                name="linkedin_url"
                id="linkedin_url"
                placeholder="http://linkedin.com/in/username"
                onChange={onChange}
                value={form.linkedin_url}
                error={createContactData.error?.linkedin_url}
              />
            </div>
          </div>

          <SelectManagedPrograms
            selectedPrograms={selectedPrograms}
            onDone={(selectedPrograms) => {
              setSelectedPrograms(selectedPrograms);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContactEntryCard;
