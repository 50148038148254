import { Fragment } from "react";
import DataTable from "components/common/layout/DataTable";
import Layout from "components/layout/Layout";
import NetworkRow from "components/networks/NetworkRow";
import NETWORK_BY_CLIENTS_COLUMNS from "constants/tables/headers/bma/networkByClient";
import { Link } from "react-router-dom";
import { PaginatedActionState } from "types/data";
import { Network } from "types/model/Network";

type Props = {
  networksData: PaginatedActionState<Network>;
  filterByMapStatus: (onlyUnmapped: boolean) => void;
  onItemClick: (network: Record<string, any>) => void;
  onItemCheckChanged: (item: Record<string, any>) => void;
  isChecked: (item: Record<string, any>) => void;
  selectedRows: Record<string, any>[];
  handleSubmit: (resource: "offers" | "programs" | "transactions") => void;
};

const NetworksByClient = ({
  networksData: { loading, data },
  filterByMapStatus,
  onItemClick,
  onItemCheckChanged,
  isChecked,
  handleSubmit,
  selectedRows,
}: Props) => {
  return (
    <Layout>
      <DataTable
        totalCount={data.meta.count}
        currentPage={1}
        loading={loading}
        header={
          <Fragment>
            <div className="border-b dark:border-gray-700 mx-4">
              <div className="flex items-center justify-between space-x-4 pt-3">
                <div className="flex-1 flex items-center space-x-3">
                  <h5 className="dark:text-white font-semibold">
                    Networks Mapping Status by Client Website
                  </h5>
                </div>
              </div>
              <div className="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3">
                <div className="w-full lg:w-2/3 flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
                  <form className="w-full md:max-w-sm flex-1 md:mr-4">
                    <label
                      htmlFor="default-search"
                      className="text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Search..."
                      />
                      <button
                        type="submit"
                        className="text-white absolute right-0 bottom-0 top-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-r-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >
                        Search
                      </button>
                    </div>
                  </form>
                  <div className="flex items-center space-x-4">
                    <div>
                      <button
                        id="filterDropdownButton"
                        data-dropdown-toggle="filterDropdown"
                        type="button"
                        className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          className="h-4 w-4 mr-2 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                          />
                        </svg>
                        Client Websites
                        <svg
                          className="-mr-1 ml-1.5 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          />
                        </svg>
                      </button>
                      <div
                        id="filterDropdown"
                        className="z-10 hidden p-3 bg-white rounded-lg shadow w-56 dark:bg-gray-700"
                      >
                        <h6 className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          By status
                        </h6>
                        <ul
                          className="space-y-2 text-sm"
                          aria-labelledby="filterDropdownButton"
                        >
                          <li>
                            <label className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md px-1.5 py-1 w-full">
                              <input
                                type="checkbox"
                                value=""
                                className="w-4 h-4 mr-2 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              In progress
                            </label>
                          </li>
                          <li>
                            <label className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md px-1.5 py-1 w-full">
                              In review
                            </label>
                          </li>
                          <li>
                            <label className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md px-1.5 py-1 w-full">
                              <input
                                type="checkbox"
                                value=""
                                className="w-4 h-4 mr-2 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              Completed
                            </label>
                          </li>
                          <li>
                            <label className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md px-1.5 py-1 w-full">
                              <input
                                type="checkbox"
                                value=""
                                className="w-4 h-4 mr-2 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              Canceled
                            </label>
                          </li>
                        </ul>
                        <h6 className="mt-4 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          By number of users
                        </h6>
                        <ul
                          className="space-y-2 text-sm"
                          aria-labelledby="dropdownDefault"
                        >
                          <li>
                            <label className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md px-1.5 py-1 w-full">
                              5-10 peoples
                            </label>
                          </li>
                          <li>
                            <label className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md px-1.5 py-1 w-full">
                              <input
                                type="checkbox"
                                value=""
                                className="w-4 h-4 mr-2 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              10+ peoples
                            </label>
                          </li>
                          <li>
                            <label className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md px-1.5 py-1 w-full">
                              <input
                                type="checkbox"
                                value=""
                                className="w-4 h-4 mr-2 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              More than 10 peoples
                            </label>
                          </li>
                        </ul>
                        <a
                          href={"/"}
                          className="flex items-center text-sm font-medium text-primary-600 dark:text-primary-500 hover:underline mt-4 ml-1.5"
                        >
                          Apply to all projects
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row mb-3 md:mb-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                  <Link
                    to="/settings/networks/add"
                    className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    Add New Networks
                  </Link>
                </div>
              </div>
            </div>
            <div className="mx-4 pb-3 flex flex-wrap">
              <div className="hidden md:flex items-center text-sm font-medium text-gray-900 dark:text-white mr-4 mt-3">
                Show only:
              </div>
              <div className="flex flex-wrap">
                <div className="flex items-center mt-3 mr-4">
                  <input
                    id="all-tasks"
                    type="radio"
                    value=""
                    onFocus={() => filterByMapStatus(false)}
                    name="show-only"
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="all-tasks"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    All
                  </label>
                </div>
                <div className="flex items-center mr-4 mt-3">
                  <input
                    id="completed"
                    type="radio"
                    onFocus={() => filterByMapStatus(true)}
                    value=""
                    name="show-only"
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="completed"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Not Mapped
                  </label>

                  {selectedRows.length > 0 && (
                    <div className="ml-10">
                      <label
                        htmlFor="completed"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {selectedRows.length} Selected
                      </label>

                      <button
                        type="button"
                        onClick={() => handleSubmit("programs")}
                        className="px-4 ml-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg md:rounded-bl-none md:rounded-r-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white"
                      >
                        Sync programs
                      </button>

                      <button
                        type="button"
                        onClick={() => handleSubmit("offers")}
                        className="px-4 ml-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg md:rounded-bl-none md:rounded-r-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white"
                      >
                        Sync Offers
                      </button>

                      <button
                        type="button"
                        onClick={() => handleSubmit("transactions")}
                        className="px-4 ml-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg md:rounded-bl-none md:rounded-r-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white"
                      >
                        Sync Transactions
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        }
        columns={NETWORK_BY_CLIENTS_COLUMNS}
        rows={data.items}
        rowRenderer={(row: Record<string, any>) => (
          <NetworkRow
            item={row}
            onItemClick={onItemClick}
            onItemCheckChanged={onItemCheckChanged}
            isChecked={isChecked}
          />
        )}
      />
    </Layout>
  );
};

export default NetworksByClient;
