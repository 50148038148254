/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import deleteEmailSubscriber from "redux/thunks/marketing/emails/deleteEmailSubscriber";
import getEmailSubscribers from "redux/thunks/marketing/emails/getEmailSubscribers";
import { PaginatedData } from "types/data";
import { EmailSubscriber } from "types/model/NewsletterSubscriber";

export type ActionStateType = {
  data: PaginatedData<EmailSubscriber>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getEmailSubscribersSlice = createSlice({
  name: "getEmailSubscribers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmailSubscribers.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        getEmailSubscribers.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        }
      )
      .addCase(
        deleteEmailSubscriber.fulfilled,
        (state, { payload, meta: { arg } }: any) => {
          if ({ payload }) {
            return {
              ...state,
              data: {
                ...state.data,
                meta: { ...state.data.meta, count: state.data.meta.count - 1 },
                items: state.data.items.filter((it) => it.id !== arg.id),
              },
              loading: false,
            };
          }
        }
      )
      .addCase(
        getEmailSubscribers.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default getEmailSubscribersSlice.reducer;
