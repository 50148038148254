import EmptyState from "components/common/EmptyState";
import Initial from "components/common/Initial";
import MoreFlat from "components/common/icons/MoreFlat";
import Layout from "components/layout/Layout";
import { timeAgo } from "index";
import { truncate } from "lodash";
import { PaginatedData } from "redux/slices/users/getAllUsers";
import { User } from "types/model/User";

type Props = {
  navigateToAddUser: () => void;
  navigateToUser: (item: User) => void;
  data: PaginatedData;
  onPageChange: (page: number) => void;
  loading: boolean;
};

const UsersComponent = ({
  navigateToAddUser,
  data,
  loading,
  navigateToUser,
}: Props) => {
  return (
    <Layout>
      {data.items.length === 0 && !loading && (
        <EmptyState
          title="No users"
          actionText="Add a user"
          onActionClick={navigateToAddUser}
        />
      )}
      <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        <div className="px-4 divide-y dark:divide-gray-700">
          <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
            <div className="flex items-center flex-1 space-x-4 justify-between">
              <h5>
                <span className="text-gray-500">All Users:</span>
                <span className="text-sm font-medium">{data.meta.count}</span>
              </h5>
              <button
                type="button"
                onClick={navigateToAddUser}
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              >
                <svg
                  className="h-3.5 w-3.5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Add new user
              </button>
            </div>
          </div>
          <div className="flex  justify-between py-4 space-y-3 md:flex-row md:items-center md:space-y-0">
            <div
              className="inline-flex flex-col rounded-md shadow-sm md:flex-row"
              role="group"
            ></div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all"
                      type="checkbox"
                      className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th>
                <th scope="col" className="px-4 py-3">
                  User
                </th>
                <th scope="col" className="px-4 py-3">
                  User Role
                </th>
                <th scope="col" className="px-4 py-3">
                  Status
                </th>
                <th scope="col" className="px-4 py-3 whitespace-nowrap">
                  Client Websites
                </th>
                <th scope="col" className="px-4 py-3 whitespace-nowrap">
                  Apps
                </th>

                <th scope="col" className="px-4 py-3 whitespace-nowrap">
                  Last Login
                </th>
                <th scope="col" className="px-4 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.items.map((item) => (
                <tr
                  key={item.id}
                  className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <td className="w-4 px-4 py-3">
                    <div className="flex items-center">
                      <input
                        id="checkbox-table-search-1"
                        type="checkbox"
                        className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="checkbox-table-search-1"
                        className="sr-only"
                      >
                        checkbox
                      </label>
                    </div>
                  </td>
                  <th
                    scope="row"
                    className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="flex items-center ">
                      <div className="flex items-center">
                        {item.image_url ? (
                          <img
                            src={item.image_url}
                            alt="User profile"
                            height={32}
                            width={32}
                            className=" rounded-full"
                          />
                        ) : (
                          <Initial
                            first_name={item.first_name}
                            last_name={item.last_name}
                          />
                        )}
                        <div className="flex items-center ml-3">
                          {item.first_name}
                          {"  "}
                          {item.last_name}
                        </div>
                      </div>
                    </div>
                  </th>
                  <td className="px-4 py-2">
                    <div className="inline-flex items-center bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5 mr-1"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                        />
                      </svg>
                      {item.user_role}
                    </div>
                  </td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.is_active ? (
                      <div className="flex items-center">
                        <div className="w-3 h-3 mr-2 bg-green-500 border rounded-full"></div>
                        Active
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <div className="w-3 h-3 mr-2 bg-red-500 border rounded-full"></div>
                        Inactive
                      </div>
                    )}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    <div className="flex items-center space-x-1.5">
                      <p>
                        {truncate(
                          item.clients?.map((el) => el.name)?.join(", "),
                          { length: 50 }
                        )}
                      </p>
                    </div>
                  </td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        name="promote"
                      />

                      <span>
                        {item.user_apps?.map((el) => el.name).join(", ")}
                      </span>

                      {/* {truncate(item.apps?.join(","), { length: 50 })} */}
                      {/* {item.apps?.map((el) => (
                        <p key={el}>{el},</p>
                      ))} */}
                    </label>
                  </td>

                  <td className="px-4 py-2">
                    {item.last_login
                      ? timeAgo.format(new Date(item.last_login))
                      : "Never"}
                  </td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <MoreFlat
                      onClick={() => {
                        navigateToUser(item);
                      }}
                    />

                    <div
                      id="dropdown-0"
                      className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                    >
                      <ul
                        className="py-1 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdown-button-0"
                      >
                        <li>
                          <a
                            href={"/"}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Show
                          </a>
                        </li>
                        <li>
                          <a
                            href={"/"}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Edit
                          </a>
                        </li>
                      </ul>
                      <div className="py-1">
                        <a
                          href={"/"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default UsersComponent;
