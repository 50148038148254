import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useEffect, useState } from "react";
import getExtensionReport from "redux/thunks/reporting/extension/getExtensionReport";
import BrowserExtensionReports from "layout/reporting/extension/BrowserExtensionReports";
import { extReportTypeConfig } from "constants/extensionReportTypes";
import dayjs from "dayjs";
import monthsAgoDate from "utils/dates/monthsAgoDate";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {
  reportType: string;
};

function ExtensionReport({ reportType }: Props) {
  const reportTypeConfig = extReportTypeConfig[reportType] as Record<
    string,
    any
  >;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getExtReportData = useAppSelector((state) => state.getExtensionReport);
  const [searchParams] = useSearchParams();
  const [form, setForm] = useState<Record<string, any>>({
    startDate: monthsAgoDate(1),
    endDate: dayjs(new Date()),
  });

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };
  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    if (name === "dates") {
      const dates = JSON.parse(value);
      setForm((f) => ({
        ...f,
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
      }));
    }

    setForm((f) => ({ ...f, [name]: value }));
  };

  const runReport = () => {
    dispatch(
      getExtensionReport({
        start_date: dayjs(form.startDate)?.valueOf() || null,
        end_date: dayjs(form.endDate)?.valueOf() || null,
        reportTypeConfig: reportTypeConfig,
        page: searchParams.get("page") || 1,
        user_id: form.user_id,
      })
    );
  };

  useEffect(() => {
    if (form.startDate && form.endDate) {
      runReport();
    }
  }, [reportTypeConfig, form, searchParams.get("page")]);

  const onDetailsClick = (row: Record<string, any>) => {
    const reportConfigURL = new URL(reportTypeConfig.endpoint);
    navigate(
      `/reporting/browser-extension/visits?start_date=${dayjs(
        form.startDate
      )?.valueOf()}&end_date=${dayjs(form.endDate)?.valueOf()}&user_id=${
        form.user_id || ""
      }&domain=${row.domain}&is_api_store=${reportConfigURL.searchParams.get(
        "is_api_store"
      )}`
    );
  };

  return (
    <BrowserExtensionReports
      reportType={reportType}
      form={form}
      reportTypeConfig={reportTypeConfig}
      getExtReportData={getExtReportData}
      onChange={onChange}
      onPageChange={onPageChange}
      runReport={runReport}
      onDetailsClick={onDetailsClick}
    />
  );
}

export default ExtensionReport;
