import Modal from "components/common/Modal";
import FeaturedSubCategory from "containers/oma/campaigns/FeaturedSubCategory";
import TopStoresPerCategory from "containers/oma/campaigns/TopStoresPerCategory";
import { Card } from "flowbite-react";
import { useAppSelector } from "hooks/useRedux";
import React, { useEffect, useState } from "react";
import { ProgramCategory, TopProgramCategory } from "types/model/Category";
import { Store } from "types/model/oma/Store";

type Props = {
  row: TopProgramCategory;
  navigateToClientCategory: (slug: string) => void;
  handleRemoveCategory: (row: TopProgramCategory) => void;
};

function TopCategoryRow({ row }: Props) {
  const [topStoresOpen, setTopStoresOpen] = useState(false);
  const [featuredSubsOpen, setFeaturedSubsOpen] = useState(false);
  const updateData = useAppSelector((state) => state.saveTopCategories);

  useEffect(() => {
    if (updateData.data.saved) {
      setTopStoresOpen(false);
      setFeaturedSubsOpen(false);
    }
  }, [updateData.data.saved]);

  return (
    <>
      <Modal
        doneText="Save"
        open={topStoresOpen}
        setOpen={setTopStoresOpen}
        variant="lg"
        showDone={false}
      >
        <TopStoresPerCategory topCategory={row} />
      </Modal>

      <Modal
        doneText="Save"
        open={featuredSubsOpen}
        setOpen={setFeaturedSubsOpen}
        variant="lg"
        showDone={false}
      >
        <FeaturedSubCategory topCategory={row} />
      </Modal>

      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.category.name}
      </td>
      <td
        className="whitespace-nowrap py-4 pl-4 pr-3 
      text-sm font-medium text-gray-900 sm:pl-6 w-3"
      >
        <span>
          {row.featured_stores.map((el: Store) => (
            <p key={el.id}>{el.name}</p>
          ))}
        </span>
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.featured_subs.map((it: ProgramCategory) => (
          <p key={it.id}>{it.name}</p>
        ))}
      </td>
      <td></td>
      <td></td>

      <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm">
        <button
          className="mx-4 text-indigo-900"
          onClick={() => setTopStoresOpen(true)}
        >
          Top Stores
        </button>

        <button
          className="mx-4 text-indigo-900"
          onClick={() => setFeaturedSubsOpen(true)}
        >
          Featured Subs
        </button>
      </td>
    </>
  );
}

export default TopCategoryRow;
