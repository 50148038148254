import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import createUpdateNetwork from "redux/thunks/networks/createUpdateNetwork";
import NetworkDetailComponent from "layout/networks/NetworkDetail";
import { Option } from "types/option";
import { Client } from "types/model/Client";

const NetworkDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState<Record<string, any>>({ active: false });
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [clientOptions, setClientOptions] = useState<Option[]>([]);

  const {
    data: clients,
    affiliateNetworks,
    createNetwork: createNetworkState,
  } = useAppSelector(
    ({
      getClients: { data },
      affiliateNetworks: {
        data: { items: affiliateNetworks },
      },
      createNetwork,
    }) => ({
      data,
      affiliateNetworks,
      createNetwork,
    }),
  );

  const onSelectedClientChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked, name, value } = e.target;
    
    setClientOptions((opts) =>
      opts.map((v) => (v.value === value ? { ...v, selected: checked } : v))
    );

    const updatedClients = checked
      ? [
          ...form.clients,
          ...clients.items.filter(
            (client: Client) => String(client.id) === name,
          ),
        ]
      : form.clients.filter((client: Client) => client.id !== name);

    setForm((form) => ({ ...form, clients: updatedClients }));
  };

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = ({
    target,
  }: any) => {
    const { name, value } = target;

    if (name === "network") {
      const matchedNetwork = affiliateNetworks.find(
        (it) => it?.network_id === value,
      );

      if (matchedNetwork) {
        setRequiredFields(matchedNetwork?.required_fields);
      }
    }

    if (name === "pids") {
      return setForm((form) => ({ ...form, pids: { ...form.pids, ...value } }));
    }
    setForm((form) => ({ ...form, [name]: value }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    const selectedNetwork = affiliateNetworks.find(
      (network) => network?.network_id === form.network,
    );

    if (
      selectedNetwork?.required_fields.every(
        (it) => Object.hasOwn(form, it) && !!form[it],
      )
    ) {
      dispatch(
        createUpdateNetwork({
          data: {
            ...form,
            clients: form.clients.map((client: Client) => client.id),
          },
        }),
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          toast.success(
            form.id ? "Update successful" : "Network added successfully",
          );
          if (!form.id) navigate(`/settings/networks/clients`);
        }
      });
    }
  };

  useEffect(() => {
    if (clients.items) {
      onChange({
        target: {
          name: "clients",
          value: location.state.clients,
        },
      } as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      setForm({ ...location.state, clients: location.state.clients });
    }
  }, []);

  useEffect(() => {
    if (affiliateNetworks.length) {
      onChange({
        target: {
          name: "network",
          value:
            affiliateNetworks.find(
              (it) => it.network_id === location.state.network,
            )?.network_id || "",
        },
      } as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    }
  }, [affiliateNetworks.length]);

  useEffect(() => {
    if (clients.items.length) {
      const allClients = clients.items.map((item) => ({
        label: item.name,
        value: item.id,
        selected: false,
      }));
      setClientOptions(allClients);
    }
  }, [clients.items.length]);

  useEffect(() => {
    if (form.clients?.length && clientOptions.length) {
      const allClients = clientOptions.map((item: Option) => {
        const shouldSelect = form.clients
          .map((it: Client) => it.id)
          .includes(item.value);

        return {
          ...item,
          selected: shouldSelect,
        };
      });

      setClientOptions(allClients);
    }
  }, [clientOptions.length]);

  useEffect(() => {
    if (form.clients?.length && form.network) {
      const prevPids: Record<string, any> = {};

      form.clients.forEach((element: Client) => {
        prevPids[element.id] = element.pids[form.network];
      });

      setForm((form) => ({ ...form, pids: prevPids }));
    }
  }, [form.clients?.length]);

  return (
    <NetworkDetailComponent
      clients={clientOptions}
      affiliateNetworks={affiliateNetworks}
      onSubmit={onSubmit}
      onChange={onChange}
      requiredFields={requiredFields}
      createNetworkState={createNetworkState}
      form={form}
      onSelectedClientChange={onSelectedClientChange}
    />
  );
};

export default NetworkDetails;
