const rakutenNetworks = [
  { name: "US Network", id: "1" },
  { name: "UK Network", id: "3" },
  { name: "Germany Network", id: "9" },
  { name: "France Network", id: "7" },
  { name: "Canada Network", id: "5" },
  { name: "Brazil Network", id: "8" },
  { name: "Australia Network", id: "41" },
];

export default rakutenNetworks;
