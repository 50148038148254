import DeleteButton from "components/common/DeleteButton";
import { Checkbox } from "flowbite-react";
import { useState } from "react";
import { ActionState } from "types/data";
import { ExcludedNetwork } from "types/model/ExcludedNetwork";

type Props = {
  item: ExcludedNetwork;
  handleDeleteExclusion: (excludedNExcludedNetwork: ExcludedNetwork) => void;
  deleteExcludedNetworkData: ActionState;
  isAutoOpen?: boolean;
};

function ExcludedNetworkRow({
  item,
  handleDeleteExclusion,
  deleteExcludedNetworkData,
  isAutoOpen,
}: Props) {
  const [isChecked, setIsChecked] = useState(item.auto_open);
  return (
    <tr className="border-b  hover:bg-gray-100 ">
      <th scope="row" className="px-4 py-2 text-xs font-medium text-gray-900 ">
        {item.network?.name}
      </th>

      {isAutoOpen && (
        <th
          scope="row"
          className="px-4 py-4 text-xs font-medium text-gray-900 "
        >
          <Checkbox
            disabled={deleteExcludedNetworkData.loading}
            onChange={(e) => {
              setIsChecked((c) => !c);
              handleDeleteExclusion({
                ...item,
                auto_open: !isChecked,
                changeAutoOpen: true,
              });
            }}
            checked={item.auto_open}
          />
        </th>
      )}

      <td className="p-2">
        <div className={isAutoOpen ? "" : ""}>
          {!isAutoOpen && (
            <DeleteButton
              deleteLoading={deleteExcludedNetworkData.loading}
              onClick={() => {
                handleDeleteExclusion(item);
              }}
            />
          )}
        </div>
      </td>
    </tr>
  );
}

export default ExcludedNetworkRow;
