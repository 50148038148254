import formatNumber from "utils/strings/formatNumber";
import dayjs from "dayjs";
import PositionStatusIcon from "./PositionStatusIcon";
import { FcCheckmark } from "react-icons/fc";
import { useState } from "react";

type Props = {
  positions: Array<Record<string, any>>;
  placements: Array<Record<string, any>>;
  handleSelectPosition: (el: Record<string, any>) => void;
  form: Record<string, any>;
};

const PositionItems = ({
  positions,
  placements,
  form,
  handleSelectPosition,
}: Props) => {
  const [hasSelectedPos, setHasSelectedPosition] = useState(false);
  const start_date = dayjs(form.start_date).format("YYYY-MM-DD");
  const end_date = dayjs(form.end_date).format("YYYY-MM-DD");

  const isPositionSelected = (element: Record<string, any>) => {
    const justSelected =
      form.price_id ===
      `${start_date}_${end_date}_${element.page}_${element.price_id}`;

    if (hasSelectedPos) {
      return justSelected;
    }
    const alreadyPlaced = placements
      .map((item) => item.price_id)
      .includes(
        `${dayjs(start_date).format("YYYY-MM-DD")}_${dayjs(end_date).format(
          "YYYY-MM-DD"
        )}_${element.page}_${element.price_id}`
      );
    return alreadyPlaced;
  };

  return (
    <>
      {positions.map((element: Record<string, any>) => (
        <div className="flex items-center gap-4 my-2" key={element.position}>
          <div className="border flex  items-center gap-3 p-2">
            <p>
              #{element.position} ${formatNumber(element.price, {})}
            </p>{" "}
            <PositionStatusIcon status={element.status} />
          </div>
          <div className="text-lg w-10">
            {element.status !== "sold" && (
              <button
                className="text-sm font-medium text-primary-700"
                onClick={() => {
                  setHasSelectedPosition(true);
                  handleSelectPosition(element);
                  // setForm((f) => ({
                  //   ...f,
                  //   position: element.position,
                  //   amount: element.price,
                  //   price_id: `${start_date}_${end_date}_${element.page}_${element.price_id}`,
                  // }));
                }}
              >
                {isPositionSelected(element) ? (
                  <FcCheckmark size={20} />
                ) : (
                  "Select"
                )}
              </button>
            )}{" "}
          </div>
        </div>
      ))}
    </>
  );
};

export default PositionItems;
