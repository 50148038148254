import React, { useState } from "react";
import Modal from "components/common/Modal";
import { ActionState } from "types/data";

type Props = {
  el: Record<string, any>;
  handleRemoveTrendingStore: (el: Record<string, any>) => void;
  deleteTrendingStoreData: ActionState<Record<string, any> | null>;
};

const TrendingStoreRow = ({
  el,
  handleRemoveTrendingStore,
  deleteTrendingStoreData,
}: Props) => {
  const [manageOpen, setDeleteConfirm] = useState(false);

  return (
    <React.Fragment>
      <Modal
        wrapperClassName="w-72 h-52 min-h-52"
        onConfirm={() => {
          handleRemoveTrendingStore(el);
        }}
        variant="sm"
        open={manageOpen}
        setOpen={setDeleteConfirm}
        loading={deleteTrendingStoreData.loading}
        doneText="Proceed"
        doneBtnClass="bg-red-400 hover:bg-red-700"
      >
        Are you sure you want to remove this store listing?
      </Modal>
      <tr>
        <td className="flex items-center gap-4">
          <img
            src={el.store.logo_url}
            className="ml-2"
            alt="store logo"
            height={59}
            width={70}
          />
          {el.store.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {el.store.primary_network?.name}
        </td>

        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <button
            onClick={() => {
              setDeleteConfirm(true);
            }}
            className="mx-4"
          >
            X
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TrendingStoreRow;
