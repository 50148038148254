import Button from "components/common/Button";
import Checkbox from "components/common/Checkbox";
import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import TextArea from "components/common/TextArea";
import Layout from "components/layout/Layout";
import paymentOptions from "constants/paymentOptions";
import ProgramHeader from "containers/bma/ProgramHeader";
import { ChangeEventHandler, Dispatch, SetStateAction } from "react";
import { ActionState, PaginatedActionState } from "types/data";
import { Currency } from "types/model/Client";

type Props = {
  handleFormSubmit: () => void;
  form: Record<string, any>;
  setForm: Dispatch<SetStateAction<Record<string, any>>>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  createData: ActionState;
  getCurrenciesData: PaginatedActionState<Currency>;
};

const AddPaymentLayout = ({
  handleFormSubmit,
  form,
  setForm,
  onChange,
  getCurrenciesData,
  createData,
}: Props) => {
  return (
    <Layout>
      <ProgramHeader />
      <div className="relative mx-auto p-4 w-full max-w-3xl h-full md:h-auto">
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {form.id ? "Edit payment" : "Add a payment"}
            </h3>
          </div>

          <form onSubmit={handleFormSubmit}>
            <div className="grid gap-4 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="file_input"
                >
                  Upload attachment
                </label>
                <div className="items-center w-full sm:flex">
                  {(form.tempImage || form.attachment_url) && (
                    <img
                      className="mb-4 w-20 h-20 rounded-full sm:mr-4 sm:mb-0 object-cover"
                      src={form.tempImage || form.attachment_url}
                      alt="uploaded attachment"
                    />
                  )}
                  <div className="w-full">
                    <input
                      className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="file_input_help"
                      id="file_input"
                      type="file"
                      name="file"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={(e) => {
                        setForm((pre) => ({
                          ...pre,
                          file: e.target.files?.[0] as any,
                          tempImage: URL.createObjectURL(
                            e.target.files?.[0] as any
                          ),
                        }));
                      }}
                    />
                    <p
                      className="mt-1 mb-3 text-xs font-normal text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      PNG, JPG (MAX. 800x400px).
                    </p>
                  </div>
                </div>
                <div className="flex my-4 gap-4">
                  <Input
                    label="Amount"
                    name="amount"
                    onChange={onChange}
                    required
                    value={form.amount}
                    error={createData.error?.amount}
                  />
                  <div className="">
                    <Dropdown
                      label="Currency"
                      className="py-3"
                      // className="border-0 border-l-1 focus:border-l-1 ring-0 focus:ring-0 rounded-none focus:border-none"
                      options={[...(getCurrenciesData.data.items || [])]?.map(
                        (el) => ({
                          label: el.name,
                          value: el.code,
                        })
                      )}
                      onChange={onChange}
                      name="currency"
                      placeholder="Currency"
                      value={form.currency}
                      error={createData.error?.currency}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <Dropdown
                    label="Type"
                    wrapperClassName="my-4"
                    options={paymentOptions.map((el) => ({
                      label: el,
                      value: el,
                    }))}
                    onChange={onChange}
                    name="payment_type"
                    value={form.payment_type}
                    error={createData.error?.payment_type}
                  />
                  <Checkbox
                    checked={form.completes_required_payments}
                    wrapperClassName="my-4"
                    onChange={(e) => {
                      onChange({
                        ...e,
                        target: {
                          name: "completes_required_payments",
                          value: e.target.checked,
                        },
                      } as any);
                    }}
                    name="completes_required_payments"
                    label="Fully Paid"
                  />
                </div>

                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <Input
                    label="Date"
                    name="date"
                    onChange={onChange}
                    required
                    value={form.date}
                    type="date"
                    error={createData.error?.date}
                  />
                </div>
                <TextArea
                  label="Notes"
                  value={form.notes}
                  onChange={(e) => {
                    setForm((f) => ({ ...f, notes: e.target.value }));
                  }}
                />
              </div>
            </div>{" "}
          </form>
        </div>

        <div className="flex items-center space-x-4">
          <Button
            type="submit"
            text="Submit"
            onClick={handleFormSubmit}
            loading={createData.loading}
            className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          ></Button>
        </div>
      </div>
    </Layout>
  );
};

export default AddPaymentLayout;
