import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getLeads from "redux/thunks/sales_crm/getLeads";
import { Lead } from "types/model/sales_crm/Lead";

export interface PaginatedData {
  meta: {
    count: number;
    limit: number;
    offset: number;
  };
  items: Array<Lead>;
}

export type ActionStateType = {
  data: PaginatedData;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
    },
    items: [],
  },
  loading: false,
  error: null,
};

const getLeadsSlice = createSlice({
  name: "Agencies",
  initialState: initialState,
  reducers: {
    resetAgencies: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeads.pending, (state) => ({
        ...state,
        data: initialState.data,
        error: null,
        loading: true,
      }))
      .addCase(getLeads.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if ({ payload }) {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      })

      .addCase(getLeads.rejected, (state, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: false,
        error: payload,
      }));
  },
});
export const { resetAgencies } = getLeadsSlice.actions;
export default getLeadsSlice.reducer;
