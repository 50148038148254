import OMACampaignsComponent from "layout/oma/campaigns/OMACampaigns";
import CouponSpotlight from "containers/oma/campaigns/CouponSpotlights";
import TrendingStores from "containers/oma/campaigns/TrendingStores";
import TopCategories from "containers/oma/campaigns/TopCategories";
import TopCategoryStoreSpotlight from "containers/oma/campaigns/TopCategoryStoreSpotlight";
import PaidPlacements from "containers/oma/campaigns/PaidPlacements";
import OMACampaignsHeaderSeasonalLinks from "containers/oma/campaigns/SeasonalLinks";

const routes = [
  {
    path: "/oma/campaigns",
    element: <OMACampaignsComponent />,
  },
  {
    path: "/oma/campaigns/header-seasonal-links",
    element: <OMACampaignsHeaderSeasonalLinks />,
  },
  {
    path: "/oma/campaigns/home-heroes",
    element: <CouponSpotlight />,
  },

  {
    path: "/oma/campaigns/home-category-spotlight",
    element: <TopCategoryStoreSpotlight />,
  },

  {
    path: "/oma/campaigns/trending-stores",
    element: <TrendingStores />,
  },

  {
    path: "/oma/campaigns/top-categories",
    element: <TopCategories />,
  },

  {
    path: "/oma/campaigns/coupon-placements",
    element: <PaidPlacements />,
  },
];

export default routes;
