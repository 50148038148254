import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import Spinner from "components/common/layout/Spinner";
import ExcludedNetworkRow from "components/extension/ExcludedNetworkRow";
import EXCLUDED_NETWORK_COLUMNS from "constants/tables/headers/extension/excludedNetworks";
import {
  ActionState,
  PageNumPaginatedActionState,
  PaginatedActionState,
} from "types/data";
import { AffiliateNetwork } from "types/model/AffiliateNetwork";
import { ExcludedNetwork } from "types/model/ExcludedNetwork";

type Props = {
  getExcludedNetworksData: PageNumPaginatedActionState<ExcludedNetwork>;
  handleAddExclusion: (network: AffiliateNetwork) => void;
  handleDeleteExclusion: (store: ExcludedNetwork) => void;
  deleteExcludedNetworkData: ActionState;
  excludeNetworkData: ActionState;
  initialSearchTxt: string;
  affiliateNetworks: PaginatedActionState<AffiliateNetwork>;
};

const BrowserExtNetworkAutoOpenLayout = ({
  getExcludedNetworksData,
  handleAddExclusion,
  handleDeleteExclusion,
  deleteExcludedNetworkData,
  excludeNetworkData,
  affiliateNetworks,
}: Props) => {
  const renderRow: RowRenderer<ExcludedNetwork> = (row) => {
    return (
      <ExcludedNetworkRow
        handleDeleteExclusion={handleDeleteExclusion}
        isAutoOpen
        item={{
          ...row,
          network: {
            ...row.network,
            name:
              affiliateNetworks.data.items.find(
                (it) => it.network_id === row.network.name
              )?.name || row.network.name,
          },
        }}
        key={row.id}
        deleteExcludedNetworkData={deleteExcludedNetworkData}
      />
    );
  };

  return (
    <div className="my-4">
      {/* <Dropdown
        options={affiliateNetworks.data.items
          .filter(
            (it) =>
              !getExcludedNetworksData.data.results
                .map((el) => el.network.name)
                .includes(it.network_id)
          )
          .map((el) => ({
            label: el.name,
            value: el.network_id,
          }))}
        name="search"
        placeholder="Select network"
        onChange={(e) => {
          const selected = affiliateNetworks.data.items.find(
            (it) => it.network_id === e.target.value
          );

          if (selected) {
            handleAddExclusion(selected);
          }
        }}
        wrapperClassName="w-56 my-6"
      /> */}

      {excludeNetworkData.loading && <Spinner />}
      <DataTable
        columns={EXCLUDED_NETWORK_COLUMNS}
        rows={getExcludedNetworksData.data.results}
        rowRenderer={renderRow as RowRenderer<Record<string, any>>}
        loading={getExcludedNetworksData.loading}
        currentPage={1}
        totalCount={getExcludedNetworksData.data.count}
        pageSize={200}
      />
    </div>
  );
};

export default BrowserExtNetworkAutoOpenLayout;
