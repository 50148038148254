import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getCouponContributors from "redux/thunks/app/api-clients/performance/getCouponContributors";
import getUsers from "redux/thunks/users/getUsers";
import { User } from "types/model/User";
import CouponContributions from "layout/contributions/Coupons";
import getLastWeekDateRanges from "../../../utils/dates/dateRanges";
import { debounce } from "lodash";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import { COUPONS_APP } from "constants/smaAppNames";
import { DISCOUNT_CODES_COM } from "constants/clientWebsites";

const CouponContributors = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [userOptions, setUserOptions] = useState<User[]>([]);

  const { uploaders = [] } = Object.fromEntries([...searchParams]);

  const formUploaders =
    uploaders.length > 0
      ? (JSON.parse(String(uploaders || [])) as Array<string>)
      : [];

  const {
    getAllUsers: { data: getAllUserData, loading: getAllUsersLoading },
    getCouponContributors: getCouponContributorsData,
    getClients: getClientsData,
  } = useAppSelector(({ getAllUsers, getCouponContributors, getClients }) => ({
    getAllUsers,
    getCouponContributors,
    getClients,
  }));

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value, checked },
  }) => {
    const url = new URL(window.location.href);
    if (name === "dates") {
      url.searchParams.set(
        "start_date",
        dayjs(JSON.parse(value)[0]).format("YYYY-MM-DD")
      );
      url.searchParams.set(
        "end_date",
        dayjs(JSON.parse(value)[1]).format("YYYY-MM-DD")
      );

      navigate(url.search);

      return;
    }

    if (name === "uploaders") {
      if (checked) {
        url.searchParams.set(
          "uploaders",
          JSON.stringify([...formUploaders, value])
        );
      } else {
        url.searchParams.set(
          "uploaders",
          JSON.stringify(formUploaders.filter((it) => it !== value))
        );
      }

      navigate(url.search);

      return;
    }

    url.searchParams.set(name, value);
    navigate(url.search);
  };

  useEffect(() => {
    if (getAllUserData.meta.count > 0 && getClientsData.data.items.length > 0) {
      const filteredUsers: User[] = [];
      const discountCodes = getClientsData.data.items.find(
        (it) => it.name == DISCOUNT_CODES_COM
      );

      if (discountCodes?.api_username) {
        getAllUserData.items.forEach((user) => {
          if (user.user_role === USER_ROLE_ADMIN.toUpperCase()) {
            filteredUsers.push(user);
          } else if (
            user.user_apps
              ?.map((el) => el.name.toLowerCase())
              ?.includes(COUPONS_APP.toLowerCase())
          ) {
            filteredUsers.push(user);
          }
        });

        setUserOptions([
          ...filteredUsers,
          {
            ...filteredUsers[0],
            first_name: "DiscountCodes App",
            last_name: "",
            email: discountCodes.api_username,
          },
        ]);
      }
    } else {
      dispatch(getUsers({ page: 1 }));
    }
  }, [getAllUserData.meta.count, getClientsData.data.items]);

  const startDate = dayjs(
    searchParams.get("start_date") || dayjs().toDate()
  ).format("YYYY-MM-DD");
  const endDate = dayjs(
    searchParams.get("end_date") || dayjs().toDate()
  ).format("YYYY-MM-DD");

  const getData = debounce(() => {
    dispatch(
      getCouponContributors({
        start_date: startDate,
        end_date: endDate,
        uploaders,
      })
    );
  }, 2000);

  useEffect(() => {
    if (uploaders.length) {
      getData();
      return () => {
        getData.cancel();
      };
    }
  }, [searchParams]);

  const form = {
    start_date: startDate,
    end_date: endDate,
    uploaders,
  };

  useEffect(() => {
    if (userOptions.length) {
      const dates = getLastWeekDateRanges();
      const url = new URL(window.location.href);
      url.searchParams.set("start_date", dates.lastWeekStart);
      url.searchParams.set("end_date", dates.lastWeekEnd);

      const uploadersStr = JSON.stringify(userOptions.map((el) => el.email));
      url.searchParams.set("uploaders", uploadersStr);

      navigate(url.search);

      dispatch(
        getCouponContributors({
          start_date: dates.lastWeekStart,
          end_date: dates.lastWeekEnd,
          uploaders: uploadersStr,
        })
      );
    }
  }, [userOptions.length]);

  return (
    <CouponContributions
      getAllUsersLoading={getAllUsersLoading}
      getCouponContributorsData={getCouponContributorsData}
      onChange={onChange}
      userOptions={userOptions}
      form={form}
    />
  );
};

export default CouponContributors;
