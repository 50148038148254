import Layout from "components/layout/Layout";
import SearchBar from "components/program/SearchBar";
import { Store } from "types/model/oma/Store";
import InstallsCard from "./InstallCard";
import { useState } from "react";
import axios from "axios";
import { EXT_REPORTING_BASE_URL } from "config/env";
import { ExtensionUser, User } from "types/model/User";
import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import isValidEmail from "utils/strings/isValidEmail";
import Spinner from "components/common/layout/Spinner";
import Message from "components/common/Message";

const initialNotFound = { ext: false, account: false };

const CRMHome = () => {
  const [text, setText] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(initialNotFound);
  const [user, setUser] = useState<ExtensionUser | null>(null);
  const [userAccount, setUserAccount] = useState<User | null>(null);
  const [results, setResults] = useState<any>([]);

  const onSubmit = async () => {
    try {
      setLoading(true);

      if (isValidEmail(text)) {
        getUserAccount({ criteria: "email", text: text });
      } else if (
        text.trim().length !== 11 &&
        Number.isSafeInteger(Number(text))
      ) {
        getUserAccount({ criteria: "id", text: text });
      } else {
        const response = await axios.get(
          `${EXT_REPORTING_BASE_URL}/extension/extension-user?user_id=${text}`
        );
        setResults([response.data]);
        setLoading(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        setNotFound((all) => ({ ...all, ext: true }));
      }
      setLoading(false);
    }
  };

  const getUserAccount = async ({ criteria, text }: Record<string, string>) => {
    try {
      setLoading(true);
      const response = await instance.get(
        `${DISCOUNT_CODES_API}/auth/user-accounts/?${criteria}=${text}`
      );

      if (response.data.count === 0) {
        setNotFound((all) => ({ ext: true, account: true }));
      }
      const { extension_user_data, user } =
        response.data.results.find((el: Record<string, any>) => {
          if (criteria === "ext_user_id") {
            return el.extension_user_data?.userID === text;
          }
          if (criteria === "email") {
            return el.user?.email === text;
          }
          if (criteria === "id") {
            return String(el.user?.id) === text;
          }
        }) || {};

      if (user) {
        setUserAccount(user);
      }
      if (extension_user_data) {
        setUser(extension_user_data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="flex gap-x-4">
        {/* <Dropdown
          value={userReportTypes[0]}
          className="py-3"
          options={userReportTypes.map((it) => ({ label: it, value: it }))}
        /> */}
        <SearchBar
          wrapperClass="w-96"
          placeholder="Search user"
          results={results}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            //
          }}
          onSearchSubmit={onSubmit}
          submitButtonActive
          searchLoading={true}
          renderResults={(results) => {
            setUser(results[0] as ExtensionUser);
            getUserAccount({
              criteria: "ext_user_id",
              text: results[0].userID,
            });
            setResults([]);
          }}
          onResultsOutsideClick={() => {
            setResults([]);
          }}
          onResultClick={function (res: Store): void {
            // throw new Error("Function not implemented.");
          }}
        />

        <div>{loading && <Spinner />}</div>
      </div>
      {/* <UserReports /> */}

      {notFound.account && notFound.ext && (
        <Message
          title="Not found"
          error
          wrapperClassName="my-4 w-96"
          description="User not found"
          onClose={() => {
            setNotFound(initialNotFound);
          }}
        />
      )}

      <InstallsCard
        user={{ ...user, ...userAccount }}
        onClose={() => {
          setUser(null);
          setText("");
          setResults([]);
          setLoading(false);
        }}
      />
    </Layout>
  );
};

export default CRMHome;
