import Modal from "components/common/Modal";
import SearchCoupons from "containers/oma/SearchCoupons";
import { Card } from "flowbite-react";
import { ChangeEvent, ChangeEventHandler, useState } from "react";

type Props = {
  form: Record<string, any>;
  store: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const SpotlightStoreRow = ({ form, onChange, store }: Props) => {
  const [couponModalOpen, setCouponModalOpen] = useState(false);

  return (
    <div className="my-2">
      <Modal
        onConfirm={() => {
          setCouponModalOpen(false);
        }}
        open={couponModalOpen}
        setOpen={setCouponModalOpen}
      >
        <h2>Choose coupon</h2>

        <SearchCoupons
          store={store}
          onResultClicked={(result) => {
            onChange({
              target: {
                name: "top_coupons",
                value: [result, ...form.top_coupons],
              },
            } as unknown as ChangeEvent<HTMLInputElement>);

            setCouponModalOpen(false);
          }}
        />
      </Modal>
      <Card>
        <div className="flex justify-between">
          {" "}
          <div>
            <h3>{store.name}</h3>

            {/* <p>Highlighted coupon</p>

            <span>
              -{" "}
              {form.top_coupons.find(
                (it: Record<string, any>) => it.store.id === store.id,
              )?.title || "None"}{" "}
              <button
                className="text-blue-700 text-sm"
                onClick={() => {
                  setCouponModalOpen(true);
                }}
              >
                Change
              </button>
            </span> */}
          </div>
          <button
            onClick={(e) => {
              onChange({
                ...e,
                target: {
                  ...e.target,
                  name: "stores",
                  value: form.stores.filter(
                    (it: Record<string, any>) => it.id !== store.id
                  ),
                },
              } as unknown as ChangeEvent<HTMLInputElement>);
            }}
          >
            X
          </button>
        </div>
      </Card>
    </div>
  );
};

export default SpotlightStoreRow;
