import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";


export default createAsyncThunk(
  "sales_crm/editDeleteCrmNetwork",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      if (payload.delete) {
        const response = await axios.delete(
          `/admin/sales-crm/networks/${payload.id}/`
        );
        return response.data;
      } else {
              if (payload.file) {
                const s3 = new S3({
                  ...defaultS3Config,
                  dirName: `img/crm-networks/${payload.id}`,
                });
                const result = await s3.uploadFile(payload.file);
                const { location } = result;
                payload["logo_url"] = location;
              }
        const response = await axios.put(
          `/admin/sales-crm/networks/${payload.id}/`,
          payload
        );
        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
