import { ActionState } from "types/data";
import { Lead } from "types/model/sales_crm/Lead";
import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import { LEAD_REQUEST_COLUMNS } from "constants/tables/headers/sales_crm/leads";
import LeadRequestRow from "./LeadRequestRow";

interface RequestedLeadsLayoutProps {
  leadRequests: ActionState<Record<string, any>[]>;
  filterValues: Record<string, any>;
  isAdmin: boolean;
  changeStatus: (id: string, status: string) => void;
}

export default function RequestedLeadsLayout({
  leadRequests,
  changeStatus,
}: RequestedLeadsLayoutProps) {
  const renderRow: RowRenderer<Lead> = (lead, leadIdx) => {
    return (
      <LeadRequestRow
        key={lead.id}
        lead={lead}
        leadIdx={leadIdx || 0}
        changeStatus={changeStatus}
      />
    );
  };

  return (
    <>
      <div className="mt-8 flow-root">
        <div className=" sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <DataTable
              disableHScroll
              columns={LEAD_REQUEST_COLUMNS}
              rows={leadRequests.data}
              header={
                <div className="my-4 flex gap-4 justify-between items-center px-4">
                  <div className="flex gap-4 items-center"></div>

                  <span className="text-sm">
                    {leadRequests.data.length} Items
                  </span>
                </div>
              }
              rowRenderer={renderRow as RowRenderer<Record<string, any>>}
            />
          </div>
        </div>
      </div>
    </>
  );
}
