import {
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import Input from "components/common/Input";
import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import createAgency from "redux/thunks/sales_crm/createAgency";
import toast from "react-hot-toast";
import getAgencies from "redux/thunks/sales_crm/getAgencies";
import editDeleteAgency from "redux/thunks/sales_crm/editDeleteAgency";
import { Agency } from "types/model/sales_crm/Agency";
import Modal from "components/common/Modal";
import DeleteButton from "components/common/DeleteButton";
import SelectManagedPrograms from "./SelectManagedPrograms";
import Dropdown from "components/common/Dropdown";

interface AgencyEntryCardProps {
  selectedAgency?: Agency;
  showAgencyModal: boolean;
  setShowAgencyModal: Dispatch<SetStateAction<boolean>>;
}

interface FormState {
  id?: number;
  network_type?: any;
  name?: string;
  [key: string]: any;
}

const AgencyEntryCard = ({
  selectedAgency,
  showAgencyModal,
  setShowAgencyModal,
}: AgencyEntryCardProps) => {
  const dispatch = useAppDispatch();
  const addAgencyData = useAppSelector((state) => state.createAgency);
  const editDeleteAgencyData = useAppSelector(
    (state) => state.editDeleteAgency
  );
  const [form, setForm] = useState<FormState>(selectedAgency || {});
  const [selectedPrograms, setSelectedPrograms] = useState<
    Record<string, any>[]
  >(
    (Array.isArray(selectedAgency?.programs_managed) &&
      selectedAgency?.programs_managed) ||
      []
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  const successHandler = (res: any) => {
    if (res.meta.requestStatus === "fulfilled") {
      setForm({});
      setShowAgencyModal(false);
      dispatch(
        getAgencies({
          page: 1,
        })
      );
    }
  };

  const handleSubmit = () => {
    if (form.id && form.name) {
      dispatch(
        editDeleteAgency({
          ...form,
          programs_managed: selectedPrograms.map((el) => ({
            name: el.name,
            id: el.id,
            slug: el.slug,
            client: el.client,
          })),
        })
      ).then(successHandler);
    } else if (form.name) {
      dispatch(
        createAgency({
          ...form,
          programs_managed: selectedPrograms.map((el) => ({
            name: el.name,
            id: el.id,
            slug: el.slug,
            client: el.client,
          })),
        })
      ).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Agency created successfully");
          dispatch(
            getAgencies({
              page: 1,
            })
          );
          setForm({});
          setShowAgencyModal(false);
        }
      });
    }
  };

  useEffect(() => {
    if (!showAgencyModal) {
      setForm({});
    }
  }, [showAgencyModal]);

  return (
    <Modal
      doneText="New Agency"
      open={showAgencyModal}
      showCancel={false}
      showDone={false}
      setOpen={setShowAgencyModal}
      onConfirm={handleSubmit}
      loading={addAgencyData.loading}
      variant="default"
    >
      <div className="relative w-full max-w-2xl h-full md:h-auto">
        <div className="relative bg-white rounded-lg  dark:bg-gray-800">
          <div className="flex items-center justify-between p-5 border-b dark:border-gray-600">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
              {form.id ? "Agency Details" : "Add New Agency"}
            </h3>
            <button
              type="button"
              onClick={() => {
                setShowAgencyModal(false);
              }}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="p-6 space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                {form.id && form.logo_url && !form.tempImage && (
                  <img
                    className="mb-4 w-20 h-20 rounded-full sm:mr-4 sm:mb-0 object-cover"
                    src={form.logo_url}
                    alt="Helene avatar"
                  />
                )}
                <label
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="file_input"
                >
                  Upload Logo
                </label>
                <div className="items-center w-full sm:flex">
                  {form.tempImage && (
                    <img
                      className="mb-4 w-20 h-20 rounded-full sm:mr-4 sm:mb-0 object-cover"
                      src={form.tempImage || form.logo_url}
                      alt="Helene avatar"
                    />
                  )}
                  <div className="w-full">
                    <input
                      className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="file_input_help"
                      id="file_input"
                      type="file"
                      name="file"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={(e) => {
                        setForm((pre) => ({
                          ...pre,
                          file: e.target.files?.[0] as any,
                          tempImage: URL.createObjectURL(
                            e.target.files?.[0] as any
                          ),
                        }));
                      }}
                    />
                    <p
                      className="mt-1 mb-3 text-xs font-normal text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      PNG, JPG (MAX. 800x400px).
                    </p>
                  </div>
                </div>
              </div>
              <Input
                type="text"
                label="Agency Name"
                name="name"
                value={form.name}
                id="name"
                placeholder=""
                onChange={onChange}
              />
              <Input
                type="url"
                name="website_url"
                id="website"
                value={form.website_url}
                placeholder="http://"
                label="Corporate Website URL"
                onChange={onChange}
              />

              <div className="col-span-2 space-y-4">
                {/* <ul className="space-y-2">
                  {selectedPrograms.map((program) => (
                    <li
                      key={program.id || program.slug}
                      className="bg-gray-200 rounded-md p-2 flex justify-between items-center"
                    >
                      {program.name}
                      <button
                        onClick={() => removeProgram(program)}
                        className="text-gray-600 hover:text-gray-800"
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                    </li>
                  ))}
                </ul> */}
                <SelectManagedPrograms
                  selectedPrograms={selectedPrograms}
                  onDone={(selectedPrograms) => {
                    setSelectedPrograms(selectedPrograms);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 ">
            {form.id && (
              <>
                <DeleteButton
                  text="Delete"
                  deleteLoading={editDeleteAgencyData.loading}
                  onClick={() => {
                    dispatch(
                      editDeleteAgency({ delete: true, id: form.id })
                    ).then(successHandler);
                  }}
                />
              </>
            )}
            <Button
              loading={addAgencyData.loading || editDeleteAgencyData.loading}
              // type="submit"
              onClick={handleSubmit}
              text={form.id ? "Save" : "Add Agency"}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AgencyEntryCard;
