import Button from "components/common/Button";
import Input from "components/common/Input";
import Label from "components/common/Label";
import Spinner from "components/common/layout/Spinner";
import { ChangeEventHandler } from "react";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
  onSubmit: () => void;
  errors: Record<string, any>;
  loading: boolean;
  getAdsLoading: boolean;
};

const EditAdComponent = ({
  onChange,
  onSubmit,
  form,
  errors,
  getAdsLoading,
  loading,
}: Props) => {
  return (
    <div className="flex">
      {getAdsLoading ? (
        <Spinner />
      ) : (
        <div className="w-1/2">
          <div className="mb-4">
            <h2>Edit Ad</h2>
          </div>
          <Input
            name={"final_url"}
            value={form.final_url}
            label="Final URL"
            type="url"
            inputContainerClass="bg-white border-gray-200 "
            wrapperClassName="my-4"
            className="bg-white"
            onChange={onChange}
          />
          <div className="my-2">
            <Label label="Display Paths" />

            <div className="flex gap-4">
              <Input
                name={"display_path1"}
                value={form.display_path1}
                inputContainerClass="bg-white border-gray-200"
                className="bg-white"
                onChange={onChange}
                maxLength={15}
                required
              />

              <Input
                name={"display_path2"}
                value={form.display_path2}
                inputContainerClass="bg-white border-gray-200"
                className="bg-white"
                onChange={onChange}
                maxLength={15}
                required
              />
            </div>
          </div>
          <Input
            wrapperClassName="my-4"
            name={"head_line1"}
            label="Head lines (4)"
            value={form.head_line1}
            error={errors.head_line1}
            maxLength={30}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line2"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line2}
            error={errors.head_line2}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line3"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line3}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line4"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line4}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line5"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line5}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line6"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line6}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line7"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line7}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line8"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line8}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line9"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line9}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"head_line10"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={30}
            value={form.head_line10}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"description1"}
            label="Descriptions (4)"
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={90}
            value={form.description1}
            error={errors.description1}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"description2"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={90}
            value={form.description2}
            error={errors.description2}
          />
          <Input
            wrapperClassName="my-4"
            name={"description3"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            value={form.description3}
            error={errors.description3}
            maxLength={90}
            required
          />
          <Input
            wrapperClassName="my-4"
            name={"description4"}
            inputContainerClass="bg-white border-gray-200"
            className="bg-white"
            onChange={onChange}
            maxLength={90}
            value={form.description4}
            error={errors.description4}
            required
          />
          <div>
            <Button
              type="button"
              loading={loading}
              onClick={onSubmit}
              text="Save changes"
            />
          </div>
        </div>
      )}

      <div className="extra"></div>
    </div>
  );
};

export default EditAdComponent;
