function sumValues(data: { [key: string]: number }): number {
  let sum = 0;
  for (const key in data) {
    if (data.hasOwnProperty(key) && typeof data[key] === "number") {
      sum += data[key];
    }
  }
  return sum;
}

export default sumValues;
