import OMAProgram from "containers/oma/OMAProgram";
import OMATabs from "layout/oma/OMATabs";

const routes = [
  {
    path: "/oma",
    element: <OMATabs />,
  },

  {
    path: "/oma/program",
    element: <OMAProgram />,
  },
];
export default routes;
