import axios from "axios";
import classNames from "classnames";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Label from "components/common/Label";
import Modal from "components/common/Modal";
import SearchForm from "components/common/SearchForm";
import Pagination from "components/common/layout/Pagination";
import Spinner from "components/common/layout/Spinner";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import SearchPrograms from "containers/oma/SearchPrograms";
import { useAppSelector } from "hooks/useRedux";
import { debounce, truncate } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import formatDate from "utils/dates/formatDate";

const PendingCRMMerchants = () => {
  const [open, setOpen] = useState(false);
  const { data: clients } = useAppSelector((state) => state.getClients);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedClient, setSelectedClient] = useState("8");
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageMeta, setPageMeta] = useState({ count: 0 });
  const [currentStore, setCurrentStore] = useState<null | Record<string, any>>(
    null
  );
  const [selectedStore, setSelectedStore] = useState<null | Record<
    string,
    any
  >>(null);
  const [suggestions, setSuggestions] = useState([]);

  const fetchData = async (page = 1) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${DISCOUNT_CODES_API}/stores/?arm_mapping_pending=1&client=${selectedClient}&sort_by=-updated&page=${page}&search=${searchText.trim()}`
      );
      setData(response.data.results);
      setPageMeta({
        count: response.data.count,
      });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedClient]);

  const fetchSuggestions = async (name: string) => {
    setSuggestionsLoading(true);

    try {
      const res = await axios.get(
        `${DISCOUNT_CODES_API}/stores/search-stores/?search_text=${name}&client=10`
      );
      setSuggestions(res.data);
      setSuggestionsLoading(false);
    } catch (error) {
      setSuggestionsLoading(false);
    }
  };

  const saveChanges = async (to_id: string) => {
    if (currentStore) {
      setSaving(true);
      const toastId = toast.loading("");
      try {
        await axios.patch(
          `${DISCOUNT_CODES_API}/stores/${currentStore.slug}?client=${selectedClient}`,
          {
            arm_mapped_to: to_id,
            arm_mapping_pending: false,
          }
        );
        toast.dismiss(toastId);
        toast.success("Changes saved");
        setOpen(false);
        setDeleteOpen(false);
        setSaving(false);
        fetchData();
      } catch (error) {
        setSaving(false);
        toast.dismiss(toastId);
      }
    }
  };

  useEffect(() => {
    if (typeof searchText === "string") {
      setSearchLoading(true);
      axios
        .get(
          `${DISCOUNT_CODES_API}/stores/?arm_mapping_pending=1&client=${selectedClient}&sort_by=-updated&search=${searchText.trim()}`
        )
        .then((res) => {
          setData(res.data.results);
          setPageMeta({
            count: res.data.count,
          });
        })
        .finally(() => {
          setSearchLoading(false);
        });
    }
  }, [searchText]);

  const debouncedOnChange = debounce((e) => {
    setSearchText(String(e.target.value));
  }, 1000);

  const paginate = (page: number) => {
    fetchData(page);
  };

  return (
    <div>
      <Modal
        loading={saving}
        onConfirm={() => {
          if (selectedStore) {
            saveChanges(selectedStore?.id);
          }
        }}
        variant="sm"
        open={deleteOpen}
        setOpen={setDeleteOpen}
      >
        <h3>
          Are you you want to map {currentStore?.name} to {selectedStore?.name}?
        </h3>
      </Modal>
      <Modal variant="default" open={open} setOpen={setOpen}>
        <div className="flex items-center">
          <SearchPrograms
            client_id="10"
            label="Search Program"
            onResultClicked={(result) => {
              setDeleteOpen(true);
              setSelectedStore(result);
            }}
          />
        </div>
        <div className="m-4">
          <h3>
            <Label label="Suggested" />
            {suggestionsLoading && <Spinner />}

            {suggestions.length > 0 && (
              <ul
                className={twMerge(
                  "mt-1 max-h-60  w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                )}
              >
                {suggestions.map((result: Record<string, any>) => (
                  <li
                    key={result.id}
                    onClick={() => {
                      // onResultClick(result);
                    }}
                    className={classNames(
                      "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 hover:font-semibold",
                      false ? "bg-indigo-600 text-white" : "text-gray-900"
                    )}
                  >
                    <span className={classNames("block truncate")}>
                      {result.name}
                    </span>

                    <p className="text-xs">
                      {result.primary_network?.name}{" "}
                      <span className="text-xs uppercase  mt-2">
                        {result.location}
                      </span>
                    </p>

                    <Button
                      text="SET"
                      onClick={() => {
                        setDeleteOpen(true);
                        setSelectedStore(result);
                      }}
                    />
                  </li>
                ))}
              </ul>
            )}
          </h3>
        </div>
      </Modal>

      {loading ? (
        <div className="my-4 mx-4">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="border-b dark:border-gray-700 mt-4">
            <div className="flex justify-between items-center my-4">
              <Dropdown
                placeholder="Select client"
                value={selectedClient}
                onChange={(e) => {
                  setSelectedClient(String(e.target.value));
                }}
                options={clients.items
                  .filter((el) => [7, 8, 6, 3].includes(Number(el.id)))
                  .map((it) => ({
                    label: it.name,
                    value: it.id,
                  }))}
              />
              <SearchForm
                wrapperClassName="w-56"
                placeholder="Search Program..."
                onChange={debouncedOnChange}
                loading={searchLoading}
              />
            </div>

            <table className="w-3/4 mt-4 text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-2 py-3">
                    Program Name
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Homepage Website URL
                  </th>

                  <th scope="col" className="px-2 py-3">
                    Network
                  </th>
                  <th scope="col" className="px-2 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item: any) => (
                  <tr
                    key={item.id}
                    className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer py-2"
                  >
                    <td className="px-4 py-2 whitespace-nowrap">
                      <p>{item.name}</p>
                      <p className="text-xs">
                        Last mod:{formatDate(item.updated)}
                      </p>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {truncate(item.website_url, { length: 56 })}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {item.primary_network?.name || "_"}
                    </td>

                    <td>
                      <Button
                        text="Map"
                        onClick={() => {
                          setCurrentStore(item);
                          fetchSuggestions(item.name);
                          setOpen(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="my-2">
              <Pagination
                siblingCount={3}
                // onShowAll={onShowAll}
                currentPageCount={data.length}
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={pageMeta.count || 1}
                pageSize={50}
                enableShowAll={false}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  paginate(page);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingCRMMerchants;
