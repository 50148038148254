export const discountNotesOptions = [
  "Premium Partner Upgrade",
  "Premium Partner Discount",
  "Platinum Partner Upgrade",
  "Platinum Partner Discount",
  "Diamond Partner Upgrade",
  "Diamond Partner Discount",
  "First Time Advertiser Discount",
  "TM + Search Discount",
  "Exclusive Code",
  "CPAi Discount",
  "Makegood - Total Fee Waived",
  "ROI Guarantee",
  "Payment due Net55",
];
