import QuickAccess from "layout/sales_crm/components/QuickAccess";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
  CREATE_PAID_PLACEMENT_PROP,
  SEARCH_TAKE_DOWN,
  SEND_NEW_COUPONS,
  SEND_PERF_REVIEW_REPORT,
  SEND_SEARCH_PR_KEYWORDS,
} from "constants/todoTypes";
import getEventActionItems from "redux/thunks/sales_crm/getEventActionItems";
import {
  DocumentMagnifyingGlassIcon,
  ArrowTrendingUpIcon,
  WrenchScrewdriverIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import createTodo from "redux/thunks/todos/createTodo";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { RiCoupon2Line } from "react-icons/ri";

const StoreQuickAccess = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [requestDetails, setRequestDetails] = useState<Record<string, any>>({
    name: "",
    question: "",
    todoType: "",
  });
  const todoTypes = useAppSelector((state) => state.getEventActionItems);
  const createTodoState = useAppSelector((state) => state.createTodo);

  const { data: program } = useAppSelector((state) => state.getProgram);

  const todoTypeIconMap = {
    [String(SEARCH_TAKE_DOWN)]: MinusCircleIcon,
    [String(SEND_NEW_COUPONS)]: RiCoupon2Line,
    [String(SEND_PERF_REVIEW_REPORT)]: WrenchScrewdriverIcon,
    [String(CREATE_PAID_PLACEMENT_PROP)]: DocumentMagnifyingGlassIcon,
    [String(SEND_SEARCH_PR_KEYWORDS)]: ArrowTrendingUpIcon,
  };

  const todoTypeDeptMap = {
    [String(SEARCH_TAKE_DOWN)]: "Search Department",
    [String(SEND_PERF_REVIEW_REPORT)]: "Search Department",
    [String(SEND_NEW_COUPONS)]: "Coupon Team",
    [String(CREATE_PAID_PLACEMENT_PROP)]: "Search Department",
    [String(SEND_SEARCH_PR_KEYWORDS)]: "Reporting Department",
  };

  const todoTypeQuestionMap = {
    [String(SEARCH_TAKE_DOWN)]:
      "Do you want the Search Department to turn this campaign off?",
    [String(SEND_NEW_COUPONS)]:
      "Do you want the Coupon Team to optimize this store page?",
    [String(SEND_PERF_REVIEW_REPORT)]:
      "Do you want to submit this request to the Search Department?",

    [String(CREATE_PAID_PLACEMENT_PROP)]:
      "Do you want to submit this request to the Search Department?",
    [String(SEND_SEARCH_PR_KEYWORDS)]:
      "Do you want to request a performance report from the Reporting Department?",
  };

  const navigation: Record<string, any>[] = todoTypes.data.map((el) => ({
    id: el.id,
    name: el.name,
    icon: todoTypeIconMap[String(el.id)],
    question: todoTypeQuestionMap[String(el.id)],
    action: todoTypeDeptMap[String(el.id)],
  }));

  const handleOpenDialog = (item: Record<string, any>) => {
    setRequestDetails({
      name: `${item.name} Submission`,
      question: item.question,
      todoType: item.id,
    });
    setOpen(true);
  };

  const handleSubmit = () => {
    const app =
      todoTypes.data.find((it) => it.id === Number(requestDetails.todoType))
        ?.default_app || "";
    const payload = {
      todo_type: requestDetails.todoType,
      app: app,
      notes: requestDetails.notes,
      program: {
        name: program.name,
        slug: program.slug,
        id: program.id,
        client: program.client,
        network: program.network,
      },
    };

    dispatch(createTodo(payload)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setOpen(false);
        toast.success("Request sent successfully");
      }
    });
  };

  useEffect(() => {
    dispatch(getEventActionItems());
  }, []);
  return (
    <QuickAccess
      navigation={navigation}
      handleOpenDialog={handleOpenDialog}
      open={open}
      handleSubmit={handleSubmit}
      setOpen={setOpen}
      setRequestDetails={setRequestDetails}
      requestDetails={requestDetails}
      createTodoState={createTodoState}
    />
  );
};

export default StoreQuickAccess;
