import React from "react";
import formatDate from "utils/dates/formatDate";

type Step = {
  fullUrl: string;
  visitTime: string;
};

type Props = {
  steps: Array<Step>;
};

const Stepper = ({ steps }: Props) => {
  return (
    <div className="max-w-xl mx-auto space-y-8">
      {steps.map((step, index) => (
        <div key={index} className="flex items-start">
          <div className="flex-shrink-0">
            <div
              className={`w-10 h-10 flex items-center justify-center rounded-full ${"bg-gray-300 text-gray-600"}`}
            >
              {steps.length - index}
            </div>
            {index < steps.length - 1 && (
              <div className="flex flex-col items-center">
                <div className="h-4 w-4 mt-2 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-full h-full"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </div>
                <div className="h-full border-l-2 border-gray-300 mt-2"></div>
              </div>
            )}
          </div>
          <div className="ml-4">
            <p className="text-sm text-gray-500">{step.fullUrl}</p>
            <p className="text-xs">
              {step.visitTime &&
                formatDate(
                  new Date(step.visitTime).toString(),
                  "mmmm dS, yyyy, h:MM:ss TT"
                )}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
