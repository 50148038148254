import { DISCOUNT_CODES_API } from "constants/api-clients";
import { instance } from "client-apis/discountcodes";
import Thunk from "redux/thunks/app/Thunk";

export default Thunk(
  "admin/advertisers/affiliate-newsletters/generateCouponInfo",
  async (payload: Record<string, any>) => {
    return instance.post(DISCOUNT_CODES_API + `/coupons/gen-coupon-info/`, {
      html: payload.email_body,
      id: `ns${payload.id}`,
      store_id: payload.advertiser?.id || "",
    });
  }
);
