import CommissionRateChangeRow from "components/advertisers/CommissionRateChangeRow";
import Spinner from "components/common/layout/Spinner";
import Layout from "components/layout/Layout";
import { ActionState } from "types/data";

type Props = {
  getCommissionChanges: ActionState<Record<string, any>[]>;
  onItemClick: (element: Record<string, any>) => void;
};

const CommissionRateChanges = ({
  getCommissionChanges: { data, loading },
  onItemClick,
}: Props) => {
  return (
    <Layout>
      <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-2 py-4">
                  Program Name
                </th>
                <th scope="col" className="px-2 py-3">
                  Network
                </th>

                <th scope="col" className="px-2 py-3">
                  Old rate
                </th>
                <th scope="col" className="px-2 py-3">
                  New rate
                </th>

                <th scope="col" className="px-2 py-3">
                  Last change
                </th>
              </tr>
            </thead>
            {loading && <Spinner />}
            <tbody>
              {data.map((item: Record<string, any>) => (
                <CommissionRateChangeRow
                  item={item}
                  key={item.id}
                  onClick={() => {
                    onItemClick(item);
                  }}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default CommissionRateChanges;
