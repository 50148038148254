import formatDate from "utils/dates/formatDate";
import addHttp from "utils/urls/addHttp";

type Props = {
  row: Record<string, any>;
};

const UnInstallReportRow = ({ row }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatDate(row.removedAt)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.userID}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.last_visit?.domain ? (
          <a
            href={addHttp(row.last_visit?.domain)}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-primary-600 hover:underline inline-flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
            {row.last_visit?.domain}
          </a>
        ) : null}
      </td>
      {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td> */}
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {Number(row.last_visit?.couponCount || 0) > 0 ? "YES" : "NO"}
      </td>
    </tr>
  );
};

export default UnInstallReportRow;
