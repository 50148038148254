import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import SEMProgramHomeComponent from "layout/marketing/search/SEMProgramHomeComponent";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetCampaigns } from "redux/slices/marketing/sem/getCampaigns";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import getCampaigns from "redux/thunks/marketing/sem/getCampaigns";

const SEMProgramHome = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();

  const getAdCampaignsState = useAppSelector((state) => state.getAdCampaigns);

  useEffect(() => {
    if (getAdCampaignsState.data[1]?.program_slug) {
      if (getAdCampaignsState.data[1]?.program_slug !== params.get("slug")) {
        dispatch(resetCampaigns());
      }
    }
  }, [getAdCampaignsState.data[1], params.get("slug")]);

  useEffect(() => {
    dispatch(
      getProgram({
        query: params.get("slug") || "",
      }),
    );
  }, [params.get("slug")]);

  useEffect(() => {
    dispatch(getCampaigns({ program_slug: params.get("slug") }));
  }, [params.get("slug")]);

  const goToAddCampaign = () => {
    navigate(`/marketing/search/add?slug=${params.get("slug")}`);
  };

  const onEditPressed = (item: Record<string, any>) => {
    navigate(
      `/marketing/search/ads/?slug=${params.get("slug")}&ad_group_id=${
        item.ad_group_id
      }&tab=ad-group&campaign_id=${item.id}&search_engine=${
        item.search_engine
      }`,
      {
        state: item,
      },
    );
  };

  const onEditAdGroupPressed = (item: Record<string, any>) => {
    navigate(`/marketing/search/ad-group/${item.ad_group_id}/edit`, {
      state: item,
    });
  };

  const onEditLocationTargetsPressed = (item: Record<string, any>) => {
    navigate(`/marketing/search/ad-location-targets/${item.ad_group_id}/edit`, {
      state: item,
    });
  };

  return (
    <SEMProgramHomeComponent
      goToAddCampaign={goToAddCampaign}
      onEditPressed={onEditPressed}
      storeMidParam={params.get("slug")}
      getAdCampaignsState={getAdCampaignsState}
      onEditAdGroupPressed={onEditAdGroupPressed}
      onEditLocationTargetsPressed={onEditLocationTargetsPressed}
    />
  );
};

export default SEMProgramHome;
