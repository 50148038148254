import DataTable, { RowRenderer } from "components/common/layout/DataTable";
import NewsletterTableHeader from "components/emails/NewsletterTableHeader";
import EmailSubscriberRow from "components/marketing/EmailSubscriberRow";
import EMAIL_SUBSCRIBER_COLUMNS from "constants/tables/headers/marketing/emailSubscribers";
import EmailMembersReport from "containers/marketing/email/EmailMembersReport";
import EmailProgramsView from "containers/marketing/email/EmailProgramsView";
import { ChangeEventHandler } from "react";
import { ActionState, PaginatedActionState } from "types/data";
import { EmailSubscriber } from "types/model/NewsletterSubscriber";

type Props = {
  getEmailSubscribersData: PaginatedActionState<EmailSubscriber>;
  onPageChange: (page: number) => void;
  currentPage: number;
  filterValues: Record<string, any>;
  onFiltersChange: ChangeEventHandler<HTMLInputElement>;
  debouncedOnFiltersChange: ChangeEventHandler<HTMLInputElement>;
  handleDeleteSubscriber: (item: EmailSubscriber) => void;
  deleteEmailSubscriberData: ActionState<Record<string, any> | null>;
  onDateSelectConfirm: () => void;
};

const MembersComponent = ({
  getEmailSubscribersData: { loading, data },
  deleteEmailSubscriberData,
  onPageChange,
  currentPage,
  filterValues,
  onFiltersChange,
  debouncedOnFiltersChange,
  onDateSelectConfirm,
  handleDeleteSubscriber,
}: Props) => {
  if (filterValues.report_view === "1") {
    return (
      <div>
        <NewsletterTableHeader
          filterValues={filterValues}
          onFilterChange={onFiltersChange}
          debouncedOnFiltersChange={debouncedOnFiltersChange}
          total_items={data.meta.count}
          selectedItems={[]}
          updateDate={data.items?.[0]?.created_at}
          onDateSelectConfirm={onDateSelectConfirm}
        />

        <EmailMembersReport filterValues={filterValues} />
      </div>
    );
  }

  if (filterValues.program_view === "1") {
    return (
      <div>
        <NewsletterTableHeader
          filterValues={filterValues}
          onFilterChange={onFiltersChange}
          debouncedOnFiltersChange={debouncedOnFiltersChange}
          total_items={data.meta.count}
          selectedItems={[]}
          updateDate={data.items?.[0]?.created_at}
          onDateSelectConfirm={onDateSelectConfirm}
        />

        <EmailProgramsView filterValues={filterValues} />
      </div>
    );
  }

  const renderRow: RowRenderer<EmailSubscriber> = (row) => {
    return (
      <EmailSubscriberRow
        row={row}
        handleDeleteSubscriber={() => {
          handleDeleteSubscriber(row);
        }}
        deleteEmailSubscriberData={deleteEmailSubscriberData}
      />
    );
  };

  return (
    <DataTable
      header={
        <NewsletterTableHeader
          filterValues={filterValues}
          onFilterChange={onFiltersChange}
          debouncedOnFiltersChange={debouncedOnFiltersChange}
          total_items={data.meta.count}
          selectedItems={[]}
          onDateSelectConfirm={onDateSelectConfirm}
          updateDate={data.items?.[0]?.created_at}
        />
      }
      columns={EMAIL_SUBSCRIBER_COLUMNS}
      rows={data.items}
      currentPage={currentPage}
      loading={loading}
      totalCount={data.meta.count}
      onPageChange={onPageChange}
      rowRenderer={renderRow as RowRenderer<Record<string, any>>}
    />
  );
};

export default MembersComponent;
