import classNames from "classnames";
import { useAppSelector } from "hooks/useRedux";
import OutsideClickHandler from "react-outside-click-handler";
import { twMerge } from "tailwind-merge";
import { Client } from "types/model/Client";
import { Store } from "types/model/oma/Store";

export const clientName = (clients: Client[], id: number) => {
  const clientName = clients.find((el) => Number(el.id) === Number(id))?.name;

  return clientName;
};

type Props = {
  results: Store[];
  onResultClick: (res: Store) => void;
  onResultsOutsideClick: () => void;
  renderNetwork?: boolean;
  renderLocation?: boolean;
  wrapperClassName?: string;
  loading?: boolean;
  renderClientName?: boolean;
};

const SearchResults = ({
  onResultsOutsideClick,
  results,
  onResultClick,
  renderNetwork,
  renderLocation,
  wrapperClassName,
  loading,
  renderClientName,
}: Props) => {
  const clients = useAppSelector((state) => state.getClients);

  return (
    <OutsideClickHandler onOutsideClick={onResultsOutsideClick}>
      <small>{loading && "Loading..."}</small>
      {results.length > 0 && (
        <ul
          className={twMerge(
            "absolute z-10 mt-1 max-h-60  w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
            wrapperClassName
          )}
        >
          {results.map((result) => (
            <li
              key={result.id}
              onClick={() => {
                onResultClick(result);
              }}
              className={classNames(
                "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 hover:font-semibold",
                false ? "bg-indigo-600 text-white" : "text-gray-900"
              )}
            >
              <span className={classNames("block truncate")}>
                {result.name}
              </span>

              {renderNetwork && (
                <p className="text-xs">{result.primary_network?.name}</p>
              )}
              {renderLocation && (
                <p className="text-xs uppercase  mt-2">
                  {result.location}{" "}
                  {renderClientName
                    ? clientName(clients.data.items, result.client) || ""
                    : ""}
                </p>
              )}
            </li>
          ))}
        </ul>
      )}
    </OutsideClickHandler>
  );
};

export default SearchResults;
