import MoreFlat from "components/common/icons/MoreFlat";
import dateFormat from "dateformat";
import { truncate } from "lodash";
import { EmailCampaign } from "types/model/NewsletterSubscriber";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  row: EmailCampaign;
  onReviewClicked: (row: EmailCampaign) => void;
};

const EmailCampaignRow = ({ row, onReviewClicked }: Props) => {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {row.created_at
          ? dateFormat(new Date(row.created_at), "dd/mm/yyyy")
          : ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.campaign_type?.toUpperCase()}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {formatNumber(parseFloat(row.commission_value_usd) / 1000, {
          decimals: 2,
          prefix: "$",
        })}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.program_info?.name}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.receiver_count}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.opens}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.pending_email_opens}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.open_rate}%
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.clicks}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.pending_email_clicks}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.clicks_rate}%
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.stats?.unsubs}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row.coupon_info?.id}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {truncate(row.coupon_info?.title, { length: 30 })}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {row?.coupon_info?.discount_code ? "YES" : "NO"}
      </td>

      <td>
        <MoreFlat
          onClick={() => {
            onReviewClicked(row);
          }}
        />
      </td>
    </tr>
  );
};

export default EmailCampaignRow;
