import { useNavigate } from "react-router-dom";
import { Event } from "types/model/sales_crm/Event";

type Props = {
  update: Event;
};

const FeedProgramImage = ({ update }: Props) => {
  const navigate = useNavigate();
  const numberOfImages = update.linked_programs.length;
  const defaultProgram = update.program?.id
    ? update.program
    : update.linked_programs[0];

  return (
    <>
      {!update.entity?.logo_url && numberOfImages > 0 ? (
        <div
          className={`grid gap-1 ${
            numberOfImages === 1
              ? "grid-cols-1"
              : numberOfImages === 2
              ? "grid-cols-2"
              : "grid-cols-3"
          } w-24 h-24`}
        >
          {update.linked_programs.map((element: Record<string, any>) => (
            <img
              key={element.slug}
              title={element.name}
              onClick={() => {
                navigate(
                  `/sales/crm/store/${element.slug}?client_id=${element.client}`
                );
              }}
              src={element.logo_url}
              alt={element.name}
              className="w-full h-full object-cover cursor-pointer rounded-lg bg-white ring-1 ring-gray-900/10"
            />
          ))}
        </div>
      ) : (
        <>
          {defaultProgram?.name && (
            <img
              title={defaultProgram.name}
              onClick={() => {
                navigate(
                  `/sales/crm/store/${defaultProgram.slug}?client_id=${defaultProgram.client}`
                );
              }}
              src={update.entity?.logo_url || defaultProgram.logo_url}
              alt={update.entity?.logo_url || defaultProgram.name}
              className="w-24 h-24 cursor-pointer flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            />
          )}
        </>
      )}
    </>
  );
};

export default FeedProgramImage;
