import Layout from "components/layout/Layout";
import { useEffect, useState } from "react";
import Spinner from "components/common/layout/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type CSVData = { headerKeys: string[]; data: any };

type Props = {
  onRowClicked: (row: Record<string, any>) => void;

  getActivePrograms: any;
};

const BMAMappingAll = ({ getActivePrograms, onRowClicked }: Props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const pending = getActivePrograms.data.filter(
    (it: Record<string, any>) => !it.primary_network,
  );
  const mapped = getActivePrograms.data.filter(
    (it: Record<string, any>) => !!it.primary_network,
  );
  const [programs, setPrograms] = useState(pending);

  useEffect(() => {
    if (params.get("tab") === "2") {
      setPrograms(
        getActivePrograms.data.filter(
          (it: Record<string, any>) => !!it.primary_network,
        ),
      );
    } else {
      setPrograms(
        getActivePrograms.data.filter(
          (it: Record<string, any>) => !it.primary_network,
        ),
      );
    }
  }, [getActivePrograms.data?.length]);

  const btnCls = twMerge(
    `px-4 py-2 text-sm font-medium  border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg bg-white`,
    params.get("tab") !== "2" &&
      "text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700",
  );

  const mappedBtnCls = twMerge(
    `px-4 py-2 text-sm font-medium  border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg bg-white`,
    params.get("tab") === "2" &&
      "text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700",
  );

  return (
    <Layout>
      <div
        className="inline-flex flex-col w-full rounded-md shadow-sm md:w-auto md:flex-row mb-4"
        role="group"
      >
        <button
          type="button"
          onClick={() => {
            setPrograms(pending);
            navigate(`/bma/mapping?tab=1`);
          }}
          className={btnCls}
        >
          Pending Programs
        </button>

        <button
          type="button"
          onClick={() => {
            setPrograms(mapped);
            navigate(`/bma/mapping?tab=2`);
          }}
          className={mappedBtnCls}
        >
          Mapped
        </button>
      </div>

      {getActivePrograms.loading && !getActivePrograms.data?.length && (
        <Spinner />
      )}

      {!!getActivePrograms.data?.length && (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-2 py-3">
                Program Name
              </th>
              <th scope="col" className="px-2 py-3">
                Homepage Website URL
              </th>

              <th scope="col" className="px-2 py-3">
                Network
              </th>
            </tr>
          </thead>

          <tbody>
            {programs
              .sort((a: any, b: any) => {
                if (a.primary_network?.name === "shareasale") {
                  return -1;
                }
                return 1;
              })
              .map((item: any) => (
                <tr
                  key={item.id}
                  onClick={() => onRowClicked(item)}
                  className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer py-2"
                >
                  <td className="px-4 py-2 whitespace-nowrap">{item.name}</td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {item.website_url}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {item.primary_network?.name || "_"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </Layout>
  );
};

export default BMAMappingAll;
