import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import CompetitorProgramsLayout from "layout/marketing/search/CompetitorProgramsLayout";
import { debounce } from "lodash";
import { ChangeEventHandler, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getCompetitorPrograms from "redux/thunks/marketing/sem/getCompetitorPrograms";
import getCompetitors from "redux/thunks/marketing/sem/getCompetitors";

const CompetitorWebsitePrograms = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    page = 1,
    has_campaign = "0",
    search_text = "",
    competitor = null,
    search = "",
    program_match = "",
  } = Object.fromEntries([...searchParams]);

  const filterValues = {
    page,
    has_campaign,
    search_text,
    competitor,
    program_match,
  };

  const getCompetitorProgramsData = useAppSelector(
    (state) => state.getCompetitorPrograms
  );

  const getCompetitorData = useAppSelector((state) => state.getCompetitors);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCompetitors({}));
  }, []);

  useEffect(() => {
    dispatch(
      getCompetitorPrograms({
        page,
        has_campaign,
        competitor,
        search,
        program_match,
      })
    );
  }, [page, has_campaign, competitor, search, program_match]);

  const onPageChange = (page: Number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const onHasAdCampaignChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const url = new URL(window.location.href);
    url.searchParams.set(e.target.name, e.target.checked ? "1" : "0");
    navigate(url.search);
  };

  const handleCompetitorChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const url = new URL(window.location.href);
    url.searchParams.set("competitor", e.target.value);
    navigate(url.search);
  };

  const onSearchChange = debounce((e) => {
    const url = new URL(window.location.href);
    url.searchParams.set("search", String(e.target.value));
    navigate(url.search);
  }, 2000);

  return (
    <CompetitorProgramsLayout
      onPageChange={onPageChange}
      onSearchChange={onSearchChange}
      getCompetitorProgramsData={getCompetitorProgramsData}
      page={Number(page)}
      filterValues={filterValues}
      onHasAdCampaignChange={onHasAdCampaignChange}
      getCompetitorData={getCompetitorData}
      handleCompetitorChange={handleCompetitorChange}
    />
  );
};

export default CompetitorWebsitePrograms;
