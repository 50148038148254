import { Column } from "types/column";

const EXCLUDED_NETWORK_COLUMNS = [
  {
    key: "Network",
    isSortable: false,
    label: "Network",
  },
  {
    key: "Auto Open",
    isSortable: false,
    label: "Auto Open",
  },

  {
    key: "Action",
    isSortable: false,
    label: "",
  },
] as Column[];

export default EXCLUDED_NETWORK_COLUMNS;
