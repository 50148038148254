import S3 from "react-aws-s3-typescript";

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID as string;
const accessKeySecret = process.env.REACT_APP_AWS_ACCESS_KEY_SECRET as string;

export const defaultS3Config = {
  bucketName: "operationshubdev",
  dirName: "media",
  region: "us-west-2",
  accessKeyId: accessKeyId,
  secretAccessKey: accessKeySecret,
};

const ReactS3Client = new S3(defaultS3Config);
// const s3Categories = new S3({...config, dirName});

export default ReactS3Client;

/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

/**
 * {
 *   Response: {
 *     bucket: "myBucket",
 *     key: "image/test-image.jpg",
 *     location: "https://operationshubdev.s3.amazonaws.com/media/test-file.jpg"
 *   }
 * }
 */
// });÷
