import { PLACEMENT_DEST_STORE_SIDE_BAR } from "constants/placementDestinations";
import { PLACEMENT_TYPE_COUPON_LISTINGS } from "constants/placementTypeIds";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import dateformat from "dateformat";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import TrendingStoresComponent from "layout/oma/campaigns/TrendingStoresLayout";
import { ChangeEventHandler, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setPaidPlacements } from "redux/slices/clients/placements/campaigns/getPaidPlacements";
import { clearProgramSearchResults } from "redux/slices/clients/placements/campaigns/searchPrograms";
import addToPaidPlacements from "redux/thunks/app/placements/campaigns/addToPaidPlacements";
import deletePaidPlacement from "redux/thunks/app/placements/campaigns/deletePaidPlacement";
import getPaidPlacements from "redux/thunks/app/placements/campaigns/getPaidPlacements";

const TrendingStores = () => {
  const [storeName, setStoreName] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    getPaidPlacements: trendingResults,
    deletePaidPlacement: deletePaidPlacementData,
    addToPaidPlacements: addToPaidPlacementsData,
  } = useAppSelector(
    ({ getPaidPlacements, deletePaidPlacement, addToPaidPlacements }) => ({
      getPaidPlacements,
      deletePaidPlacement,
      addToPaidPlacements,
    })
  );

  const filterValues = {
    location: searchParams.get("location") || "US",
    placement_destination:
      searchParams.get("placement_destination") ||
      PLACEMENT_DEST_STORE_SIDE_BAR,
    placement_type: PLACEMENT_TYPE_COUPON_LISTINGS,
  };

  const persistTrendingStores = (result: Record<string, any>) => {
    setStoreName(result.name);
    const newItem = {
      from_date: dateformat(dayjs(new Date()).toDate(), "yyyy-mm-dd"),
      store: result.id,
      placement_destination: filterValues.placement_destination,
      client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
      location: filterValues.location?.toLowerCase(),
      placement_type: filterValues.placement_type,
    };

    dispatch(addToPaidPlacements(newItem)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setStoreName("");
        toast.success("Trending store added successfully");
        const updatedItems = [
          {
            ...newItem,
            store: result,
            id: res.payload.id,
          },
          ...trendingResults.data,
        ];
        dispatch(setPaidPlacements(updatedItems));
      }
    });
  };

  const onResultClick = (res: Record<string, any>) => {
    dispatch(clearProgramSearchResults());
    persistTrendingStores(res);
  };

  const handleRemoveTrendingStore = (result: Record<string, any>) => {
    dispatch(deletePaidPlacement(result.id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Store listing was deleted successfully");
        const updatedItems = trendingResults.data.filter(
          (it) => it.id !== result.id
        );
        dispatch(setPaidPlacements(updatedItems));
      }
    });
  };

  useEffect(() => {
    if (filterValues.location && filterValues.placement_destination) {
      dispatch(getPaidPlacements(filterValues));
    }
  }, [filterValues.location, filterValues.placement_destination]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    const url = new URL(window.location.href);

    url.searchParams.set(name, value);
    navigate(url.search);
  };

  return (
    <TrendingStoresComponent
      onResultClick={onResultClick}
      trendingResults={trendingResults}
      addToPaidPlacementsData={addToPaidPlacementsData}
      deleteTrendingStoreData={deletePaidPlacementData}
      handleRemoveTrendingStore={handleRemoveTrendingStore}
      filterValues={filterValues}
      onChange={onChange}
      storeName={storeName}
    />
  );
};

export default TrendingStores;
