interface Coupon {
  sorting_for_store?: number; // Optional property
  // Add other properties of the coupon if needed
}

const sortCoupons = (coupons: Coupon[] = []): Coupon[] => {
  // The coupons that have the lowest number on sorting to be on top
  // If there is no number, item should be returned last

  return coupons
    .map((el) => ({
      ...el,
      sorting_for_store: el.sorting_for_store ?? coupons.length,
    }))
    .sort((a, b) => {
      return Number(a.sorting_for_store) - Number(b.sorting_for_store);
    });
};

export default sortCoupons;
