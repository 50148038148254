import { USER_ROLE_ADMIN } from "constants/user_roles";
import TabCreateCoupon from "containers/oma/CreateUpdateCoupon";
import useLoadAuth from "hooks/useLoadAuth";
import React, { useState } from "react";

type Props = {
  row: Record<string, any>;
  skipFields: Array<string>;
};

const StoreCouponRow = ({ row, skipFields }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useLoadAuth();
  const isAdmin = user?.user_role === USER_ROLE_ADMIN;

  return (
    <React.Fragment>
      <tr>
        {!skipFields.includes("Program Name") && (
          <td className="border border-slate-300 p-2">{row.store?.name}</td>
        )}
        <td className="border border-slate-300 p-2">
          {row.id} {isAdmin ? row.sorting_for_store : ""}
        </td>
        <td className="border border-slate-300 p-2">
          {row.created_by.split("@")[0] || ""}
        </td>
        <td className="border border-slate-300 p-2">{row.offer_type}</td>
        <td className="border border-slate-300 p-2">
          {row.discount_code}
          {[...row.tags, (row.is_auto_coupon && "AI") || ""]
            .filter(Boolean)
            .map((el: string) => (
              <span
                key={el}
                className="bg-green-100 
                text-xs -top-2 mx-1 
                 text-green-800
               
                 p-0.5
                rounded
                 dark:bg-green-900
                dark:text-green-300"
              >
                {el}
              </span>
            ))}
        </td>
        <td className="border border-slate-300 p-2">
          <span className="relative block">{row.title}</span>
        </td>

        <td className="border border-slate-300 p-2">
          <span>{row.description}</span>
        </td>
        <td className="border border-slate-300 p-2">{row.start_date}</td>
        <td className="border border-slate-300 p-2">{row.expiration_date}</td>
        <td className="border border-slate-300 p-2">
          <button
            onClick={() => {
              setIsEditing((isEditing) => !isEditing);
            }}
          >
            {isEditing ? "Cancel" : "Edit"}
          </button>
        </td>
      </tr>

      {isEditing && (
        <tr>
          <td colSpan={8}>
            <TabCreateCoupon initialForm={{ ...row, cancelFn: setIsEditing }} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default StoreCouponRow;
