import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "sales_crm/createContact",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.post(`/admin/sales-crm/contacts/`, payload);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
