import CRMHome from "containers/crm/CRMHome";

const crmRoutes = [
  {
    path: "/crm/",
    element: <CRMHome />,
  },
];

export default crmRoutes;
