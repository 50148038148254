import { ChangeEventHandler, useEffect, useState } from "react";
import ProposalsLayout from "layout/sales/ProposalsLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import getPricingPlans from "redux/thunks/sales/getPricingPlans";
import getProposals from "redux/thunks/sales/getProposals";
import { resetProgram } from "redux/slices/clients/getProgram";
import { Proposal } from "types/model/sales/Proposal";
import createUpdateDeleteProposal from "redux/thunks/sales/createUpdateDeleteProposal";
import { toast } from "react-hot-toast";
import getUsers from "redux/thunks/users/getUsers";
import { User } from "types/model/User";
import { USER_ROLE_ADMIN } from "constants/user_roles";
import { SALES_APP } from "constants/smaAppNames";
import dayjs from "dayjs";
import { debounce } from "lodash";
import getProposalTypes from "redux/thunks/sales/getProposalTypes";

const Proposals = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const {
    page = "1",
    proposal_status = "Pending",
    proposal_type,
    location = "",
    search_text = "",
    created_by,
    slug: program_slug,
    start_date = null,
    end_date = null,
  } = Object.fromEntries([...params]);

  const filterValues = {
    proposal_status,
    location,
    proposal_type,
    created_by,
    page,
    program_slug,
    start_date: start_date || null,
    end_date: end_date || null,
    search_text,
  };

  const {
    getProposals: getProposalsData,
    getProgram: getProgramData,
    getAllUsers,
    getProposalStats: getProposalStatsData,
    getProposalTypes: proposalTypesData,
    createUpdateDeleteProposal: createUpdateDeleteProposalData,
  } = useAppSelector(
    ({
      getProposals,
      getProgram,
      getProposal,
      getAllUsers,
      getProposalStats,
      getProposalTypes,
      createUpdateDeleteProposal,
    }) => ({
      getProposals,
      getProgram,
      getAllUsers,
      getProposal,
      getProposalStats,
      getProposalTypes,
      createUpdateDeleteProposal,
    })
  );

  useEffect(() => {
    if (getAllUsers.data.meta.count === 0) {
      dispatch(getUsers({ page: 1 }));
    }
  }, [getAllUsers.data.meta.count]);

  const updateUsers = () => {
    const filteredUsers: User[] = [];

    getAllUsers.data.items.forEach((user) => {
      if (user.user_role === USER_ROLE_ADMIN.toUpperCase()) {
        filteredUsers.push(user);
      } else if (
        user.user_apps
          ?.map((el) => el.name.toLowerCase())
          ?.includes(SALES_APP.toLowerCase())
      ) {
        filteredUsers.push(user);
      }
    });

    setUsers(filteredUsers);
  };

  useEffect(() => {
    if (getAllUsers.data.meta.count > 0) {
      updateUsers();
    }
  }, [getAllUsers.data.meta.count]);

  useEffect(() => {
    if (params.has("slug")) {
      dispatch(getProgram({ query: params.get("slug") || "" }));
    } else {
      dispatch(resetProgram());
    }
  }, [params]);

  useEffect(() => {
    if (!filterValues.search_text) {
      dispatch(getProposals(filterValues));
    }
  }, [params]);

  useEffect(() => {
    dispatch(getPricingPlans({}));
  }, []);

  const updateStatus = (proposal: Proposal, newStatus: string) => {
    dispatch(
      createUpdateDeleteProposal({
        ...proposal,
        proposal_type: proposal.proposal_type.id,
        search_rights_type:
          proposal.search_rights_type?.id ||
          proposal.search_rights_type ||
          null,
        proposal_status: newStatus,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      } else {
        const payload = res.payload as Array<Record<string, any>>;
        toast.error(Object.values(payload)?.[0]?.[0] || "Something went wrong");
      }
    });
  };

  const deleteProposal = (proposal: Proposal, delete_reason: string) => {
    dispatch(
      createUpdateDeleteProposal({
        ...proposal,
        proposal_type: proposal.proposal_type.id,
        deleted: true,
        delete_reason: delete_reason,
        deleted_at: new Date(),
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Proposal removed successfully");
      } else {
        const payload = res.payload as Array<Record<string, any>>;
        toast.error(Object.values(payload)?.[0]?.[0] || "Something went wrong");
      }
    });
  };

  const debouncedOnSearchChange = debounce((value) => {
    dispatch(
      getProposals({
        ...filterValues,
        search_text: value.replace("IO", "").trim(),
      })
    );
  }, 2000);

  useEffect(() => {
    dispatch(getProposalTypes());
  }, []);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;

    if (name === "search_text") {
      setSearchText(value);
      debouncedOnSearchChange(value);
      return;
    }

    if (name === "dates") {
      const url = new URL(window.location.href);
      const start = JSON.parse(value)[0];
      url.searchParams.set(
        "start_date",
        start ? dayjs(start).format("YYYY-MM-DD") : ""
      );
      const end = JSON.parse(value)[1];
      url.searchParams.set(
        "end_date",
        end ? dayjs(end).format("YYYY-MM-DD") : ""
      );

      navigate(url.search);

      return;
    }

    const url = new URL(window.location.href);
    url.searchParams.set("page", "1");
    url.searchParams.set(e.target.name, e.target.value);
    navigate(url.search);
  };

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  return (
    <ProposalsLayout
      getProposalsData={getProposalsData}
      getProgramData={getProgramData}
      proposalTypes={proposalTypesData}
      updateStatus={updateStatus}
      filterValues={filterValues}
      onChange={onChange}
      searchText={searchText}
      users={users}
      onPageChange={onPageChange}
      deleteProposal={deleteProposal}
      getProposalStatsData={getProposalStatsData}
      createUpdateDeleteProposalData={createUpdateDeleteProposalData}
    />
  );
};
export default Proposals;
