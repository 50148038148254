import MyDropzone from "components/common/FileDropzone";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import ImageDropzone from "./ImageDropzone";

type Props = {
  form?: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  wrapperCls?: string;
};

const ProgramImages = ({ form, onChange, wrapperCls }: Props) => {
  const [logo, setLogo] = useState(form?.logo_url);
  const [coverPicture, setCoverPhoto] = useState(form?.cover_picture);

  useEffect(() => {
    setLogo(form?.logo_url);
  }, [form?.logo_url]);

  useEffect(() => {
    setLogo(form?.cover_picture);
  }, [form?.cover_picture]);

  const handleAddLogo = (files: File[]) => {
    const file = files[0];
    const tempUrl = URL.createObjectURL(file);
    setLogo(tempUrl);

    onChange({
      target: {
        name: "rawLogo",
        value: file,
      },
    } as ChangeEvent<any>);
  };

  const handleDeleteLogo = () => {
    setLogo(null);
    onChange({
      target: {
        name: "rawLogo",
        value: null,
      },
    } as ChangeEvent<any>);
  };

  const handleAddCoverPhoto = (files: File[]) => {
    const file = files[0];
    const tempUrl = URL.createObjectURL(file);
    setCoverPhoto(tempUrl);

    onChange({
      target: {
        name: "rawCoverPhoto",
        value: file,
      },
    } as ChangeEvent<any>);
  };

  const handleDeleteCoverPhoto = () => {
    setCoverPhoto(null);
    onChange({
      target: {
        name: "rawCoverPhoto",
        value: null,
      },
    } as ChangeEvent<any>);
  };

  const wrapperClass = twMerge(
    "mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8",
    wrapperCls
  );

  return (
    <div className={wrapperClass}>
      <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Images
      </span>
      <div className="grid grid-cols-3 gap-4 mb-4 sm:grid-cols-4">
        <div className="relative p-2 bg-gray-100 rounded-lg sm:w-36 sm:h-36 dark:bg-gray-700">
          {logo && <img src={logo} alt="iMac Side" height={24} />}
          <button
            type="button"
            onClick={handleDeleteLogo}
            className="absolute bottom-1 left-1 text-red-600 dark:text-red-500 hover:text-red-500 dark:hover:text-red-400"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Delete image</span>
          </button>
        </div>

        <div className="relative p-2 bg-gray-100 rounded-lg sm:w-36 sm:h-36 dark:bg-gray-700">
          {coverPicture && <img src={coverPicture} alt="Store cover" />}
          <button
            type="button"
            onClick={handleDeleteCoverPhoto}
            className="absolute bottom-1 left-1 text-red-600 dark:text-red-500 hover:text-red-500 dark:hover:text-red-400"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Delete image</span>
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center w-full gap-4">
        <ImageDropzone
          label="Add Business Logo"
          onFilesDropped={handleAddLogo}
          helperText="PNG, JPG or GIF (MAX. 120x120px)"
        />
        {/* <label
          htmlFor="dropzone-file"
          className="flex flex-col justify-center items-center w-full h-48 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <MyDropzone
            accept={{ "image/*": [".png", ".jpg", ".jpeg"] }}
            onFilesDropped={handleAddLogo}
          >
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                className="mb-3 w-10 h-10 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold"></span>
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400"></p>
            </div>
          </MyDropzone>
        </label> */}
        <ImageDropzone
          label="Click to upload Long Form Image or drag and drop"
          onFilesDropped={handleAddCoverPhoto}
          helperText="PNG or JPG (380 x 220)"
        />
        {/* <label
          htmlFor="dropzone-file"
          className="flex flex-col justify-center items-center w-full h-48 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          {" "}
          <MyDropzone
            accept={{ "image/*": [".png", ".jpg", ".jpeg"] }}
            onFilesDropped={handleAddCoverPhoto}
          >
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                className="mb-3 w-6 h-7 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p className="mb-2 text-sm text-gray-500 px-6 dark:text-gray-400">
                <span className="font-semibold">
                  Click to upload Long Form Image or drag and drop{" "}
                </span>{" "}
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400"></p>
            </div>
          </MyDropzone>
        </label> */}
      </div>
    </div>
  );
};

export default ProgramImages;
