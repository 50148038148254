import RemovedCampaignProgramRow from "components/advertisers/RemovedCampaignProgramRow";
import DataTable from "components/common/layout/DataTable";
import Spinner from "components/common/layout/Spinner";
import ALL_CAMPAIGN_COLUMNS from "constants/tables/headers/marketing/adCampaign";
import getHostName from "utils/urls/getHostName";

type Props = {
  getCampaignReportsState: any;
};

const RecentlyPausedCampaignPrograms = ({
  getCampaignReportsState: { loading, data, meta },
}: Props) => {
  return (
    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        <DataTable
          columns={ALL_CAMPAIGN_COLUMNS}
          loading={loading}
          rows={data.items}
          rowRenderer={(item) => {
            return <RemovedCampaignProgramRow item={item} key={item.id} />;
          }}
        />
      </div>
    </div>
  );
};

export default RecentlyPausedCampaignPrograms;
