import { ExtensionUser } from "types/model/User";
import formatDate from "utils/dates/formatDate";

type Props = {
  row: Record<string, any>;
};
export const getInstallSource = (
  row: Record<string, any> | ExtensionUser | null
) => {
  if (!row) return {};
  const url = row["url"] ? new URL(row["url"]) : null;
  const source = url?.searchParams?.get("utm_source") || "";
  const store = (url?.searchParams?.get("utm_medium") || "")?.replace(
    "store-",
    ""
  );

  return { url, source, store };
};

const InstallReportRow = ({ row }: Props) => {
  const { source, store } = getInstallSource(row);

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatDate(row.installationTime)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.userID}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.browserName}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {store?.replace("store-", "")}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {source?.replace("store-", "")}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.ipAddress}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.city}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.county}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {row.country}
      </td>
    </tr>
  );
};

export default InstallReportRow;
