import PopUnder from "components/common/PopUnder";
import { twMerge } from "tailwind-merge";
import formatDate from "utils/dates/formatDate";

type Props = {
  lead: Record<string, any>;
  leadIdx: number;
  changeStatus: (id: string, status: string) => void;
};

const LeadRequestRow = ({ lead, leadIdx, changeStatus }: Props) => {
  return (
    <tr key={lead.program?.name}>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {formatDate(lead.created_at)}
      </td>

      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.program?.name}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        <a
          href={lead.program?.website_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          {lead.program?.url}
        </a>
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.program?.network}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.program?.rank}
      </td>

      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {(lead.user?.first_name || "") + " " + (lead.user?.last_name || "")}
      </td>

      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-gray-200",
          "px-3 py-3.5 text-sm text-gray-900"
        )}
      >
        {lead.notes}
      </td>
      <td
        className={twMerge(
          leadIdx === 0 ? "" : "border-t border-transparent",
          "relative px-3 py-3.5 text-right text-sm font-medium sm:pr-6"
        )}
      >
        <PopUnder
          publishingOptions={[
            {
              title: "Approve",
              visible: true,
              current: true,
              onClick: () => {
                changeStatus(lead.id, "approved");
              },
            },

            {
              title: "Decline",
              visible: true,
              current: true,
              onClick: () => {
                changeStatus(lead.id, "declined");
              },
            },
          ]}
        />
        {leadIdx !== 0 ? (
          <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
        ) : null}
      </td>
    </tr>
  );
};

export default LeadRequestRow;
