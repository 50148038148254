import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";
import toast from "react-hot-toast";

export default createAsyncThunk(
  "sales_crm/editDeleteLead",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      if (payload.delete) {
        const response = await axios.delete(
          `/admin/sales-crm/leads/${payload.id}/`
        );
        toast.success("Lead deleted successfully");

        return response.data;
      } else if (payload.partialEdit) {
        delete payload.partialEdit;
        const response = await axios.patch(
          `/admin/sales-crm/leads/${payload.id}/`,
          payload
        );

        return response.data;
      } else {
        const response = await axios.put(
          `/admin/sales-crm/leads/${payload.id}/`,
          payload
        );
        toast.success("Changes saved");

        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
