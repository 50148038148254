const initialState = {
  data: {
    meta: {
      count: 0,
      limit: 0,
      offset: 0,
      extra: {
        advertiser_count: 0,
      },
    },
    items: [],
  },
  loading: false,
  error: null,
};

export default initialState;
