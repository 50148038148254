/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import exportResource from "redux/thunks/app/api-clients/exportCSVResource";

export type ExportResourceAction = {
  data: string;
  loading: boolean;
  error: null | string;
};

const initialState: ExportResourceAction = {
  data: "",
  loading: false,
  error: null,
};

const exportResourceSlice = createSlice({
  name: "export_resource",
  initialState,
  reducers: {
    resetExportResource: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(exportResource.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
        data: initialState.data,
      }))
      .addCase(
        exportResource.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if ({ payload }) {
            return {
              ...state,
              data: payload,
              loading: false,
            };
          }
        },
      )
      .addCase(
        exportResource.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});

export const { resetExportResource } = exportResourceSlice.actions;

export default exportResourceSlice.reducer;
