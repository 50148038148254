import Layout from "components/layout/Layout";
import { ActionState } from "types/data";
import { Proposal } from "types/model/sales/Proposal";

type Props = {
  getProposalData: ActionState<Proposal | null>;
};

const ProposalLayout = ({ getProposalData: { loading, data } }: Props) => {
  return <Layout loading={loading}>{JSON.stringify(data, null, 2)}</Layout>;
};

export default ProposalLayout;
