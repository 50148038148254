import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getAd from "redux/thunks/marketing/sem/getAdsByAdgroup";

export type ActionStateType = {
  data: Array<Record<string, any>>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: [],
  loading: false,
  error: null,
};

const getAdSlice = createSlice({
  name: "getAd",
  initialState,
  reducers: {
    resetAd: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAd.pending, (state) => {
        return {
          ...state,
          error: null,
          loading: true,
        };
      })
      .addCase(getAd.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if ({ payload }) {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      })

      .addCase(getAd.rejected, (state, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: false,
        error: payload,
      }));
  },
});
export const { resetAd } = getAdSlice.actions;
export default getAdSlice.reducer;
