import paginatedItemsInitialState from "redux/initial-states/paginatedItemsInitialState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getCampaignReports from "redux/thunks/marketing/sem/getCampaignReports";
import { PaginatedData } from "types/data";
import { AdCampaignPerformance } from "types/model/sem";

export interface CampaignReportActionState {
  data: {
    data: PaginatedData<AdCampaignPerformance>;
    totals: Record<string, any>;
  };
  loading: boolean;
  error: Record<string, any> | null;
}
const initialState = {
  ...paginatedItemsInitialState,
  data: {
    data: paginatedItemsInitialState.data,
    totals: {},
  },
} as CampaignReportActionState;

const getCampaignReportsSlice = createSlice({
  name: "getCampaignReports",
  initialState: initialState,
  reducers: {
    resetCampaigns: () => initialState,
    sortCampaigns(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignReports.pending, (state) => {
        return {
          ...state,
          data: initialState.data,
          error: null,
          loading: true,
        };
      })
      .addCase(
        getCampaignReports.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          return {
            ...state,
            data: {
              data: payload[0],
              totals: payload[1],
            },
            loading: false,
          };
        }
      )

      .addCase(
        getCampaignReports.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});
export const { resetCampaigns, sortCampaigns } =
  getCampaignReportsSlice.actions;
export default getCampaignReportsSlice.reducer;
